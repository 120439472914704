import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));
export default function Dashboard() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Toolbar />
        Depends on User DAshboard Content Goes here...
      </div>
    </div>
  );
}
