/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import PopularTimesChart from "../UserDashboard/Dashboard/populartimes";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../../views/Common";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import moment from "moment";

const CSTCCourtGraph = (props) => {
  const override = css`
  display: block;
  margin: 100px auto;
  border-color: #0076bb;
`;
  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();
  const [graphData, setGraphData] = useState([]);
  const [MAFCCourtData, setMAFCCourtData] = useState([]);
  const [loading, setLoading] = useState(true);
  const localTimeZoneOffset =
    (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;
  useEffect(() => {
    setLoading(true);
    var dates = props.selectedDateRange;
    const startDate = +new Date(moment().subtract(30,'d').format('YYYY-MM-DD'));
    const endDate = +new Date(moment().subtract(1, 'd').format('YYYY-MM-DD'));
    var url = `api/churchstreetcourts/graphdata?courtid=${props.value}&startDate=${startDate}&endDate=${endDate}`;
    smartParkService.get(url).then((res, err) => {
      if(res.data.status){
      setGraphData(res.data.resultArray);
      setLoading(false)
      }
    });
  }, [props.value,props.selectedDateRange.start._d, props.selectedDateRange.end._d]);

  return (
    <>
     {loading ? (
        <ClipLoader loading={loading} css={override} size={60} />
      ) : (
        <>
          {" "}
          <PopularTimesChart graphData={MAFCCourtData} />
        </>
      )}

    </>
  );
};

export default CSTCCourtGraph;
