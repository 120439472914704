import React, { useState, useEffect, useCallback } from "react";
import HighStock from "highcharts/highstock";
import { NoDataToDisplay } from "react-highcharts-no-data-to-display";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

NoDataToDisplay(HighStock);
const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

var labels = { 0: "Close", 5: "", 10: "Open" };
const localTimeZoneOffset =
    (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;

function prepareGraphPlot(data) {
    try {
        var graphs = [];
        if (data && data.length) {
            graphs.push({
                name: data[0].field + " moisture",
                type: "spline",
                data: (function () {
                    var ohlcData = [];
                    for (var i = 0; i < data.length; i++) {
                        ohlcData.push([
                            Number(data[i]["date"]) - localTimeZoneOffset, // the date
                            Number(data[i]["moisture"]), // moisture
                        ]);
                    }
                    return ohlcData;
                })(),
            });

            graphs.push({
                name: data[0].field + " status",
                type: "column",
                label: "Field Status",
                point: {
                    events: {
                        click: (e) => {
                            //console.log(e.point); console.log(e.point.y);
                        },
                    },
                },
                data: (function () {
                    var columnData = [];
                    for (var i = 0; i < data.length; i++) {
                        columnData.push([
                            Number(data[i]["date"]) - localTimeZoneOffset, // the date
                            Number(data[i]["status"]), // the volume
                        ]);
                    }
                    return columnData;
                })(),
                yAxis: 1,
            });
        }
        return graphs;
    } catch (error) {
        console.log("Error in prepareGraphPlot(). Exception Message: " + error);
    }
}

function FieldMoistureandStatusChart(props) {
    const [dataResults, setdataResults] = useState([]);
    const getConfig = (mockData) => ({
        credits: {
            enabled: false,
        },
        showInLegend: true,
        rangeSelector: {
            selected: 1,
            enabled: false, // To hide the zoom 1m 3m and Input fields
        },
        height: "100%",
        // title: {
        //     text: 'Field Status and Moisture'
        // },
        xAxis: {
            type: "datetime",
            labels: {
                format: "{value:%l:%M %p}",
            },
            events: {
                afterSetExtremes: function (event) {
                    let min = event.min;
                    let max = event.max;
                    let interval = max - min;
                    // console.log(interval);
                    this.update({
                        tickPositioner: function () {
                            if (interval <= 86400000) {
                                //debouncedSave({ event, selectedFields });
                            }
                        },
                    });
                },
            },
        },
        yAxis: [
            {
                labels: {
                    align: "left",
                    x: 3,
                },
                title: {
                    text: "Moisture",
                },
                height: "100%",
                lineWidth: 2,
                resize: {
                    enabled: true,
                },
                tickInterval: 2,
            },
            {
                alignTicks: true,
                labels: {
                    formatter: function () {
                        return ""; // Set the formatter to an empty string to hide the labels
                    },
                },
                tooltip: {
                    enabled: false, // Set tooltip.enabled to false to hide the tooltip for this yAxis
                },
                top: "100%",
                height: "0%",
                offset: 0,
                lineWidth: 2,
            },
        ],

        tooltip: {
            split: true,
            xDateFormat: "%A, %b %e, %I:%M %p",
            pointFormatter: function () {
                var point = this;
                var moistureTooltip = point.series.name;
                if (moistureTooltip.indexOf("status") > -1) {
                    return ""; // Do not display moisture information
                } else {
                    // Display the colored dot and moisture information
                    return (
                        '<span style="color:' +
                        point.color +
                        '">\u25CF</span> ' +
                        moistureTooltip + ": " + point.y.toFixed(4)
                    );
                }
            },
        },
        chart: {
            events: {
                load: function (event) {
                    event.target.reflow();
                },
            },
        },
        series: prepareGraphPlot(mockData),
        lang: {
            noData: "No Moisture Data Found",
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "15px",
                color: "#303030",
            },
        },
    });

    return (
        <div className="App">
            <>
                {" "}
                <HighchartsReact
                    highcharts={HighStock}
                    constructorType={"stockChart"}
                    options={getConfig(props.graphData.graphData)}
                />
            </>
        </div>
    );
}

export default FieldMoistureandStatusChart;
