import React, { useState, useEffect } from "react";

import WeatherAndForecast from "./components/WeatherAndForecast";
import Loader from "./components/Loader";
import Warning from "./components/Warning";
import { makeStyles } from "@material-ui/core/styles";
import getAddressOfCoordinates from "./api/reverseGeocoding";
import getCoordinatesOfAddress from "./api/forwardGeocoding";
import getWeatherAndForecast from "./api/weatherAndForecast";
import { Card, CardContent } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    background:
      "linear-gradient(180deg,#005889,#0072b1 56.77%,#6ccbff 141.56%)",
    "& .MuiCardContent-root": {
      padding: "8px !important",
    },
  },
});
function WeatherReportGolf() {
  const classes = useStyles();
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({});
  const [weatherAndForecastInfo, setWeatherAndForecastInfo] = useState({});
  const [locationInfo, setLocationInfo] = useState({});
  const [contentState, setContentState] = useState("blank");

  function showWarning() {
    setContentState("warning");
    setTimeout(() => setContentState("blank"), 3000);
  }

  useEffect(() => {
    function makeRequest(position) {
      setContentState("loading");
      getAddressOfCoordinates(
        position.coords.latitude,
        position.coords.longitude
      )
        .then((res) => {
          setLocationInfo({
            city: res.data.results[0].components.city,
            town: res.data.results[0].components.town,
            state: res.data.results[0].components.state_code,
            country: res.data.results[0].components.country_code,
          });
        })
        .then(() =>
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        )
        .catch((error) => showWarning());
    }

    function catchError(err) {
      alert("ERROR(" + err.code + "): " + err.message);
    }

    if (navigator.geolocation) {
      //navigator.geolocation.getCurrentPosition(makeRequest, catchError);
      makeRequest({ coords: { latitude: 35.4132787, longitude: -79.1039564 } });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (address === "") return;

    setContentState("loading");
    getCoordinatesOfAddress(address)
      .then((res) => {
        if (
          res.data.results.length === 0 ||
          (res.data.results[0].components.city === undefined &&
            res.data.results[0].components.town === undefined)
        ) {
          showWarning();
          return;
        }

        setCoordinates(res.data.results[0].geometry);
        setLocationInfo({
          city: res.data.results[0].components.city,
          town: res.data.results[0].components.town,
          state: res.data.results[0].components.state_code,
          country: res.data.results[0].components.country_code,
        });
      })
      .catch((error) => showWarning());
  }, [address]);

  useEffect(() => {
    if (Object.keys(coordinates).length === 0) return;

    getWeatherAndForecast(coordinates)
      .then((res) => {
        setWeatherAndForecastInfo(res.data);
        setContentState("weatherAndForecast");
      })
      .catch((error) => showWarning());
  }, [coordinates]);

  const Main = {
    blank: () => null,
    loading: () => <Loader />,
    warning: () => <Warning />,
    weatherAndForecast: () => (
      <WeatherAndForecast
        weatherInfo={weatherAndForecastInfo}
        location={locationInfo}
      />
    ),
  };

  return (
    <Card className={classes.root}>
      <CardContent>{Main[contentState]()}</CardContent>
    </Card>
  );
}

export default WeatherReportGolf;
