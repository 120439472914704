import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  Add,
  AddCircle,
  Cancel,
  Sms,
  Search,
  Close,
  KeyboardBackspace,
  ExpandMore,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import _ from "lodash";
import InputLabel from "@material-ui/core/InputLabel";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

import { useSelector, useDispatch } from "react-redux";
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
const useStyles = makeStyles(styles);

const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "red",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white,
    },
  },
}));
export default function AddUser() {
  const classes = useStyles();
  const history = useHistory();
  const helperTestClasses = helperTextStyles();
  const loginDetails = useSelector(login_);
  const ParkID = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [sensorname, setsensorname] = React.useState("");
  const [AzureDeviceID, SetAzureDeviceID] = React.useState("");
  const [AzureDeviceConnectionString, SetAzureDeviceConnectionString] =
    React.useState("");
  const [AzureHubID, setAzureHubID] = React.useState("");
  const [AzureHubConnectionString, SetAzureHubConnectionString] =
    React.useState("");
  const [LocationCoordinates, SetLocationCoordinates] = React.useState({});
  const [ManufacturerName, SetManufacturerName] = React.useState("");
  const [ManufacturerID, SetManufacturerID] = React.useState("");
  const [ManufacturerDate, setManufacturerDate] = React.useState("");
  const [ModelNo, SetModelNo] = React.useState("");
  const [latitude, setlatitude] = React.useState("");
  const [typeOfSensor, settypeOfSensor] = React.useState("");
  const [longitude, setlongitude] = React.useState("");
  const [BatteryLastUpdated, setBatteryLastUpdated] = React.useState("");
  const [sensorcollectionID, setsensorcollectionID] = React.useState("");
  const [fieldsList, setFieldsList] = React.useState([]);
  const [permission, setpermission] = React.useState({});
  const [fieldID,setFieldID]= React.useState("");
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  React.useEffect(() => {
    if (permissionsHasLoaded) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);
  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "Sensor";
    });
    setpermission(Dpermissions[0]);
  };

  React.useEffect(() => {
    var id = window.location.href.split("=")[1];
    var url = "api/park/" + ParkID + "/sensordata/";
    smartParkService.get(url + id).then((res) => {
      if (res.data.status == 200) {
        var data = res.data.data;
        console.log(data.FieldID)
        setsensorcollectionID(data.SensorCollectionID);
        setsensorname(data.SensorName);
        SetAzureDeviceID(data.AzureDeviceID);
        settypeOfSensor(data.typeofsensor);
        setFieldID(data.FieldID?data.FieldID:0);
        SetAzureDeviceConnectionString(data.AzureDeviceConnectionString);
        setAzureHubID(data.AzureHubID);
        SetAzureHubConnectionString(data.AzureHubConnectionString);
        SetManufacturerName(data.ManufacturerDetails.ManufacturerName);
        SetManufacturerID(data.ManufacturerDetails.ManufacturerID);
        setManufacturerDate(data.ManufacturerDetails.ManufacturerDate);
        SetManufacturerID(data.ManufacturerDetails.ManufacturerID);
        SetModelNo(data.ManufacturerDetails.ModelNo);
        setlatitude(data.LocationCoordinates.Latitude);
        setlongitude(data.LocationCoordinates.Lognitutude);
        setBatteryLastUpdated(data.BatteryLastUpdated);
      }
    });
  }, []);
  React.useEffect(()=>{
    var url = "api/park/" + ParkID + "/fields";
    smartParkService
      .get(url,{
        headers: {
          userid: userid,
        },
      })
      .then((res) => {
        setFieldsList(res.data.data)
      })
      .catch(() => {
        alert("Error occurred while fetching the sensors");
      });
  },[])
  const getFieldName = () => {
    return fieldsList.filter(field => {
      return field.id === fieldID
    })[0].FieldName
  }
  const populateDataFromAssetDetails = (assetItem) => { 
    setsensorname(assetItem.DeviceName);
    SetManufacturerName(assetItem.ManufacturerName);
    setManufacturerDate(assetItem.ManufacturerDate);
    SetModelNo(assetItem.ModelNo);
    settypeOfSensor(assetItem.DeviceType);
  }

  const fetchSensorsFromAssets=(assetNumber)=>{
    if (assetNumber) {
      var url = "api/park/" + ParkID + "/asset/query?assetNumber="+(assetNumber).toUpperCase();
      smartParkService
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            console.log("response :", res.data);  
            populateDataFromAssetDetails(res.data);
          }
        })
    }
  }
    
  const UpdateSensorClick = () => {
    var manfacturedetails = {};
    manfacturedetails.ManufacturerID = ManufacturerID;
    manfacturedetails.ManufacturerName = ManufacturerName;
    manfacturedetails.ManufacturerDate = ManufacturerDate;
    manfacturedetails.ModelNo = ModelNo;
    var locationcordinateobj = {};
    locationcordinateobj.Latitude = latitude;
    locationcordinateobj.Lognitutude = longitude;

    var dataobj = {};

    dataobj.FacilityID = "efb6f106-6f83-4060-b6b5-f61a30289ca5";
    dataobj.ManufacturerDetails = manfacturedetails;
    dataobj.LocationCoordinates = locationcordinateobj;
    dataobj.SensorCollectionID = sensorcollectionID;
    dataobj.SensorName = sensorname;
    dataobj.BatteryLastUpdated = BatteryLastUpdated;
    dataobj.AzureDeviceID = AzureDeviceID;
    dataobj.typeofsensor = typeOfSensor;
    dataobj.AzureDeviceConnectionString = AzureDeviceConnectionString;
    dataobj.AzureHubID = AzureHubID;
    dataobj.AzureHubConnectionString = AzureHubConnectionString;
    dataobj.FieldID=fieldID;
    dataobj.FieldName=getFieldName();
    var id = window.location.href.split("=")[1];
    var url = "api/park/" + ParkID + "/sensordata/";
    smartParkService
      .post(url + id, dataobj, {
        headers: {
          userid: userid,
        },
      })
      .then((res) => {
        if (res.data.status == 200) {
          history.push("/Sensor");
        }
        else if (res.status === 401 || res.status === 403) {
          alert("You do not have permission to update sensor");  
        }
      })
      .catch(() => {
        alert("Error occurred while updating the sensor");
      });
  };
  return (
    <div className={classes.root}>
      <div className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Sensor</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                  Sensor
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <div class="content-middle">
            <div class="content-sub-header">
              <div>
                <Link
                  component="button"
                  onClick={() => history.push("/Sensor")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
              <div>
                <h3>Edit Sensor</h3>
              </div>
              <div>
                <Link
                  style={{ visibility: "hidden" }}
                  component="button"
                  onClick={() => history.push("/Users")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
            </div>

            <Paper className={classes.paper + " " + classes.withStripe}>
              <div class="form-body">
                <Grid container spacing={3} className={classes.pad20}>
                  <Grid item xs={12} sm={12}>
                    <Select
                      fullWidth
                      value="Cedar Fork District Park"
                      label="Facility Name"
                    >
                      <MenuItem value="Cedar Fork District Park">
                        Cedar Fork District Park
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Select
                      fullWidth
                     // value="Cedar Fork District Park"
                      label="Field Name"
                      value={fieldID}
                      onChange={(e) => {
                        setFieldID(e.target.value);
                      }}
                    >
                      <MenuItem value="0">Select Field Name</MenuItem>
                      {fieldsList.length > 0
                          ? fieldsList.map((e) => {
                              return (
                                <MenuItem  value={e.id}>{e.FieldName}</MenuItem>
                              );
                            })
                          : null}
                    </Select>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="AssetNumber"
                      name="AssetNumber"
                      label="Enter Asset Number to Fetch from Sensor Details"
                      fullWidth
                      onChange={(e) => {
                        fetchSensorsFromAssets(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                  <FormControl className={classes.w100p} variant="outlined">
                    <Select
                      fullWidth
                      value="Cedar Fork District Park"
                      label="Type Of Sensor"
                      value={typeOfSensor}
                      onChange={(e) => {
                        settypeOfSensor(e.target.value);
                      }}
                    >
                      <MenuItem value="1">Type Of Sensor</MenuItem>
                      <MenuItem value="Moisture">Moisture</MenuItem>
                      <MenuItem value="Flood">Flood</MenuItem>
                      <MenuItem value="Gate">Gate</MenuItem>
                      </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="SensorName"
                      name="SensorName"
                      label="Sensor Name"
                      value={sensorname}
                      // helperText={}
                      FormHelperTextProps={{ classes: helperTestClasses }}
                      fullWidth
                      onChange={(e) => {
                        setsensorname(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="sensorcollectionID"
                      name="sensorcollectionID"
                      label="Sensor Collection ID"
                      value={sensorcollectionID}
                      // helperText={}
                      FormHelperTextProps={{ classes: helperTestClasses }}
                      fullWidth
                      onChange={(e) => {
                        setsensorcollectionID(e.target.value);
                      }}
                    />
                  </Grid>
                 
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="AzureDeviceID"
                      name="AzureDeviceID"
                      label="Azure Device ID"
                      value={AzureDeviceID}
                      fullWidth
                      onChange={(e) => {
                        SetAzureDeviceID(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="AzureDeviceConnectionString"
                      name="AzureDeviceConnectionString"
                      label="Azure Device Connection String"
                      value={AzureDeviceConnectionString}
                      onChange={(e) => {
                        SetAzureDeviceConnectionString(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="AzureHubID"
                      name="AzureHubID"
                      label="Azure Hub ID"
                      fullWidth
                      value={AzureHubID}
                      onChange={(e) => {
                        setAzureHubID(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="AzureHubConnectionString"
                      name="AzureHubConnectionString"
                      label="Azure Hub Connection String"
                      value={AzureHubConnectionString}
                      onChange={(e) => {
                        SetAzureHubConnectionString(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="BatteryLastUpdated "
                      name="BatteryLastUpdated "
                      fullWidth
                      label="Battery Last Updated "
                      value={BatteryLastUpdated}
                      onChange={(e) => {
                        setBatteryLastUpdated(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="Latitude"
                      name="Latitude"
                      fullWidth
                      label="Latitude"
                      value={latitude}
                      onChange={(e) => {
                        setlatitude(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="longitude"
                      name="longitude"
                      fullWidth
                      label="longitude"
                      value={longitude}
                      onChange={(e) => {
                        setlongitude(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        Manufacturer Details
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} className={classes.pad20}>
                          <Grid item xs={12} sm={6}>
                            <div class="d-flex alignCenter">
                              <TextField
                                variant="outlined"
                                size="small"
                                id="ManufacturerName"
                                value={ManufacturerName}
                                name="ManufacturerName"
                                label="Manufacturer Name"
                                fullWidth
                                onChange={(e) => {
                                  SetManufacturerName(e.target.value);
                                }}
                              />
                              &nbsp;
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              label="Manufacturer Date"
                              value={ManufacturerDate}
                              fullWidth
                              onChange={(e) => {
                                setManufacturerDate(e.target.value);
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={ModelNo}
                              fullWidth
                              label="Model Number"
                              onChange={(e) => {
                                SetModelNo(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={ModelNo}
                              fullWidth
                              label="Model Number"
                              onChange={(e) => {
                                SetModelNo(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                        </Grid>
                        <br />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </div>
              <div class="form-footer">
                {permission.update == 1 ? (
                  <Button
                    size="sm"
                    color="primary"
                    variant="contained"
                    className={classes.btnRound}
                    onClick={UpdateSensorClick}
                  >
                    Update
                  </Button>
                ) : (
                  ""
                )}
                &nbsp;&nbsp;
                <Button
                  size="sm"
                  variant="outlined"
                  className={classes.btnRound}
                  onClick={() => {
                    history.push("/Sensor");
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </Paper>
          </div>
        </section>
      </div>
    </div>
  );
}
//   LocationCoordinates: { type: String },
//   SensorName: { type: String },
//   AzureDeviceID: { type: String },
//   AzureDeviceConnectionString: { type: String },
//   AzureHubID: { type: String },
//   AzureHubConnectionString: { type: String },
