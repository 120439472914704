import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../components/components.js";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Search } from "@material-ui/icons";
import RichTextEditor from "react-rte";
import smartParkService from "../../axios";
import { string } from "prop-types";
import { RuleEngineNotificationTemplate } from "../../config";
const useStyles = makeStyles(styles);
export default function SelectEmailNotify(props) {
  const [emailBody, setEmailBody] = React.useState(
    RichTextEditor.createValueFromString(props.rules.EmailBody, "html")
  );
  const [EmailsList, setEmailsList] = React.useState([]);

  const prepareUserList = (value) => {
    if (value.length > 2) {
      smartParkService
        .get("api/UserName/" + value.toLowerCase())
        .then((res) => {
          if (res) {
            setEmailsList(res.data.data);
          } else {
          }
        });
    } else {
      setEmailsList([]);
    }
  };

  const onEmailChange = (value) => {
    props.updateRuleStateObj("Email", value);
  };
  const error = props.Errors;
  const onChange = (value) => {
    setEmailBody(value);
    const emailBodyString = value
      .toString("html")
      .replace(/<(?:.|\n)*?>/gm, "<br/>");
    props.updateRuleStateObj("EmailBody", emailBodyString);
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <div class="stepper-row-content1">
        <div>
          <label>Notify to following users</label>
          <div class="customInputBase">
            <IconButton className={classes.iconButton} aria-label="menu">
              <Search />
            </IconButton>
            <Autocomplete
              multiple
              id="EmailsList"
              label="Select Emails"
              options={EmailsList.map(
                (option) => option.FirstName + "  (" + option.EmailAddress + ")"
              )}
              // options={EmailsList.map((option) =>option.FirstName+"  ("+option.EmailAddress+")")}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder=""
                  onChange={(e) => {
                    prepareUserList(e.target.value);
                  }}
                />
              )}
              onChange={(event, value) => onEmailChange(value)}
              defaultValue={props.rules?.Email}
            />
          </div>{" "}
          <div style={{ color: "red", fontSize: "15px" }}>{error.Email}</div>
          &nbsp;
        </div>
        <br />
        <div>
          <label>Email message </label>
          <RichTextEditor value={emailBody} onChange={onChange} />
          <div style={{ color: "red", fontSize: "15px" }}>
            {error.Emailbody}
          </div>
          <br />
        </div>
      </div>
    </React.Fragment>
  );
}
