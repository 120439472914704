import {
  createSlice,
  PayloadAction,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import CSPTCImg from "../assets/img/ChurchStreetPark.jpg";
import MCPImg from "../assets/img/MorrisvilleCommunityPark.jpg";
import CFDPImg from "../assets/img/CFDP.jpg";
import MAFCImg from "../assets/img/MorrisvilleAquatics&FitnessCenter.jpg";
import shiloh from "../assets/img/shiloh.jpeg";
import crabtree from "../assets/img/crabtree.jpeg";
//import CFDPFullMap from "../assets/img/cfdp-map.png";
import CFDPFullMap from "../assets/img/CFDP.jpg";
import MCPFullMap from "../assets/img/MorrisvilleCommunityPark.jpg";

export const initialState = {
  data: {
    fieldSelections: [],
    fieldDate: "",
    details: {
      cfdp: {
        header: "Cedar Fork District Park",
        title: "FIELD STATUS",
        url: "api/citizen/fielddata3",
        imgURL: CFDPImg,
        fullImgURL: CFDPFullMap,
        cardColumns: ["FieldName", "FieldStatus"],
        id: "id",
        mapUrl:
          "https://www.google.com/maps/place/232+Aviation+Pkwy,+Morrisville,+NC+27560",
        graphs: ["Flood", "Historical", "Temperature"],
        parkid: "c7a1d85f-8168-4e64-8e38-116a0624bfea",
        key: "cfdp",
      },
      mafctenniscourts: {
        header: "Morrisville Aquatics & Fitness Center",
        title: "TENNIS COURT STATUS",
        url: "api/courts",
        imgURL: MAFCImg,
        fullImgURL: MAFCImg,
        cardColumns: ["court_name", "value"],
        id: "court_api_id",
        mapUrl:
          "https://www.google.com/maps/place/1301+Morrisville+Pkwy,+Morrisville,+NC+27560",
        graphs: ["popular"],
        parkid: "91474e36-76c5-4d59-a8da-2998ab14a4bf",
        key: "mafctenniscourts",
      },
      churchstreettenniscourts: {
        header: "Church Street Park",
        title: "TENNIS COURT STATUS",
        url: "api/churchstreetcourts",
        imgURL: CSPTCImg,
        fullImgURL: CSPTCImg,
        cardColumns: ["court_name", "value"],
        id: "court_api_id",
        mapUrl:
          "https://www.google.com/maps/place/5817+Cricket+Pitch+Wy,+Morrisville,+NC+27560/",
        graphs: ["popular", "Temperature"],
        parkid: "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944",
        key: "churchstreettenniscourts",
      },
      morrisvillecommunitypark: {
        header: "Morrisville Community Park",
        title: "FIELD STATUS",
        url: "api/citizen/fielddata2",
        imgURL: MCPImg,
        fullImgURL: MCPFullMap,
        cardColumns: ["FieldName", "FieldStatus"],
        id: "id",
        mapUrl:
          "https://www.google.com/maps/place/1520+Morrisville+Pkwy,+Morrisville,+NC+27560,+USA/",
        graphs: ["Flood", "Historical", "Temperature", "popular"],
        parkid: "c7a1d85f-8168-4e64-8e38-116a0624bfeb",
        key: "mcpfields",
      },
      shiloh: {
        header: "Shiloh Park",
        title: "FIELD STATUS",
        url: "api/citizen/fielddata4",
        imgURL: shiloh,
        fullImgURL: shiloh,
        cardColumns: ["FieldName", "FieldStatus"],
        id: "id",
        mapUrl: "https://maps.app.goo.gl/8G33te5GjfJWyZ2X9",
        graphs: [
          //'Flood',
          // "Historical",
          // "popular",
        ],
        parkid: "c7a1d85f-8168-4e64-8e38-116a0624bfec",
        key: "shiloh",
      },
      ccnp: {
        header: "Crabtree Creek Nature Park",
        title: "FIELD STATUS",
        url: "api/citizen/fielddata5",
        imgURL: crabtree,
        fullImgURL: crabtree,
        cardColumns: ["FieldName", "FieldStatus"],
        id: "id",
        mapUrl: "https://maps.app.goo.gl/1xkY7fKf3it7GaoL8",
        graphs: [
          //'Flood',
          // "Historical",
          // "popular",
        ],
        parkid: "c7a1d85f-8168-4e64-8e38-116a0624bfed",
        key: "ccnp",
      },
    },
    selectedField: {},
    fieldCourts: [],
    currentFieldStatus: null,
  },
};

const userDashboard = createSlice({
  name: "userDashboard",
  initialState,
  reducers: {
    getUserSelections: (state) => {
      return { ...state };
    },
    updateFieldSelections(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
    setSelectedField(state, action) {
      state.data.selectedField = action.payload;
    },
    setFieldCourts(state, action) {
      state.data.fieldCourts = action.payload;
    },
    updateCurrentFieldStatus(state, action) {
      state.data.currentFieldStatus = action.payload;
    },
  },
  extraReducers: (builder) => { },
});

export const stateSelector_ = ({ userDashboard }) => userDashboard;

export const userDashboard_ = createSelector(
  stateSelector_,
  (userDashboard) => userDashboard
);

export const fieldDetails_ = createSelector(
  stateSelector_,
  (userDashboard) => userDashboard.data.details
);

export const selectedFieldDetails_ = createSelector(
  stateSelector_,
  (userDashboard) => userDashboard.data.selectedField
);

export const currentFieldStatus_ = createSelector(
  stateSelector_,
  (userDashboard) => userDashboard.data.currentFieldStatus
);

export const selectedFieldCourts_ = createSelector(
  stateSelector_,
  (userDashboard) => userDashboard.data.fieldCourts
);

// export const getFirstCourt_ = createSelector(selectedFieldCourts_, fieldCourts => fieldCourts[0] || []);
export const getFirstCourt_ = createSelector(
  stateSelector_,
  (userDashboard) => userDashboard.data.fieldCourts[0] || []
);

export const fieldDetailsByType_ = createSelector(
  fieldDetails_,
  (_, cardType) => cardType,
  (details, cardType) => details[cardType] || {}
);

export const {
  updateFieldSelections,
  getUserSelections,
  setSelectedField,
  setFieldCourts,
  updateCurrentFieldStatus,
} = userDashboard.actions;

export default userDashboard.reducer;
