import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import smartParkService from "../../axios";
import { login_, token_ } from "../../reducers/login";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const localTimeZoneOffset = ((new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000);
const getConfig = floodData => ({
	credits: {
		enabled: false
	},
	chart: {
		type: "areaspline",
		events: {
			load: function (event) {
				event.target.reflow();
			}
		}
	},
	showInLegend: true,
	rangeSelector: {
		selected: 1,
		enabled: false,// To hide the zoom 1m 3m and Input fields 
	},
	height: "100%",
	xAxis: {
		type: 'datetime',
		labels: {
			format: '{value:%l:%M %p}'
		},
	},
	yAxis:
	{
		title: {
			text: "Water Level (ft)"
		},
		resize: {
			enabled: true
		}
		, tickInterval: 2,
		max: 290,
		min: 270
	},
	series: prepareGraphPlot(floodData),
	tooltip: {
		split: true,
		xDateFormat: "%A, %b %e, %I:%M %p",
		pointFormatter: function () {
			var point = this;
			return '<span style="color:' + point.color + '">\u25CF</span> Water Level <b>' + point.y.toFixed(2) + '</b>(ft)</b><br/>';
		}
	},
});

function prepareGraphPlot(data) {
	try {
		return {
			name: "Water Level",
			data: (function () {
				var ohlcData = [];

				let oldDate = Number(data[0]["date"]) - (localTimeZoneOffset);
				for (var j = 0; j < data.length; j++) {
					let currentDate = Number(data[j]["date"]) - (localTimeZoneOffset);
					var Difference_In_Time = currentDate - oldDate;
					var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));

					if (Difference_In_Days >= 1) {
						var oldTimeInDateFormat = new Date(oldDate);
						for (var i = 1; i < Difference_In_Days; i++) {
							oldTimeInDateFormat.setDate(oldTimeInDateFormat.getDate() + 1);
							var oldTimeInDateInTimeFormat = oldTimeInDateFormat.getTime();
							ohlcData.push([
								oldTimeInDateInTimeFormat - (localTimeZoneOffset), // date
								null
							])
						}
					}

					oldDate = Number(data[j]["date"]) - (localTimeZoneOffset);
					ohlcData.push([
						Number(data[j]["date"]) - (localTimeZoneOffset), // date
						data[j]["level"], // water level
					])

				}
				return ohlcData;
			})()
		}
	} catch (error) {
		console.log("Error in prepareGraphPlot(). Exception Message: " + error);
	}
}
function FloodChart(props) {
	const [loading, setLoading] = React.useState(true);
	const loginDetails = useSelector(login_);
	const parkid = _.get(loginDetails, ['parkID'], '');
	const token = useSelector(token_);
	smartParkService.defaults.headers.common['x-access-token'] = token;

	const [waterlevelData, setwaterlevelData] = useState([]);
	const [selectedDateRange, setselectedDateRange] = useState({});

	useEffect(() => {
		getData();
	}, [props.selectedDateRange.start, props.selectedDateRange.end])

	const getData = () => {
		let region = "Morrisville";

		let startDate = new Date("01/01/2020").toISOString();
		let endDate = new Date().toISOString();

		if (props.selectedDateRange) {
			var dates = props.selectedDateRange;
			startDate = dates.start;
			endDate = dates.end;
		}

		let url = "api/park/" + parkid + "/flood/region?startDate=" + (startDate) + "&endDate=" + (endDate);// + "&region=" + region;
		setLoading(true);
		smartParkService.get(url).then((res) => {
			if (res) {
				setLoading(false);
				setwaterlevelData(res.data.data);
			} else {
				console.log("FloodChart else error: ");
			}

		}).catch((error) => {
			console.error("FloodChart error: ", error);
			setLoading(false);
		});
	}

	return (
		<div className="App">
			{
				loading ? <ClipLoader loading={loading} css={override} size={60} /> : <>
					<HighchartsReact
						highcharts={HighStock}
						constructorType={"stockChart"}
						options={getConfig(waterlevelData)}
					/>
				</>
			}
		</div>
	);

}

export default FloodChart;