const notifyMe = theme =>({ 
  subcribed:{
      fontSize:'14px',
      color:'#0000008a',
      marginLeft: '10px!important',
      marginBottom: '10px!important'
    },
    select:{
      border:'1px solid #ccc',
      borderRadius:'5px',
    },
  subcribedol: {
    marginBottom:'10px',
    subcribedli:{
      marginBottom:'10px',
      width:'100%',
      display:'block',
    },
    '& ul':{
      marginTop:'10px',
      display:'block',
      width:'100%',
      float:'left',
      '& li':{
        marginBottom:'10px',
        listStyle:'none',
        float:'left',
        marginRight:'10px'
      }
    }
  },    
  actionButtons:{
    textAlign:'right',
    '& button':{
      margin:'10px'
    }
  }
});

export default notifyMe;