import React, { lazy } from "react";
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "./index.css";
import ForgotPassword from "./views/LoginPage/ForgotPassword.js";
import ReactGA from "react-ga";
import AdminDashboard from "./views/AdminDashboard/AdminDashboard.js";
import UserDashboard from "./views/UserDashboard/UserDashboard.js";
import AccessManagment from "./views/AccessManagement/AccessManagement.js";
import AddFeature from "./views/AccessManagement/AddFeature.js";
import AddRole from "./views/AccessManagement/AddRole.js";
import EditRole from "./views/Rules/AddRuleStepper";
import AddRolePermission from "./views/AccessManagement/AddRolePermission.js";
import Rules from "./views/Rules/Rules.js";
// import NewRules from "./views/Rules/NewRules";
import NewRule from "./views/Rules/NewRules";
import Users from "./views/Users/UsersInfo.js";
import AddUser from "./views/Users/AddUser.js";
import Dashboard from "./views/Dashboard.js";
import AddRule from "./views/Rules/AddRuleStepper.js";
import SignUp from "./views/LoginPage/SignUp.js";
import EditUser from "./views/Users/EditUser";
import UserProfile from "./views/Users/UserProfile";
import Fields from "./views/Fields/Fields.js";
import AddField from "./views/Fields/AddField.js";
import EditField from "./views/Fields/EditField";
import CreatenewRule from "./views/Rules/CreatenewRule";
import Editrulecreated from "./views/Rules/Editrulepage";
import Sensor from "./views/Fields/Sensor";
import AddSensor from "./views/Fields/AddSensor";
import UpdateSensor from "./views/Fields/UpdateSensor";
import Device from "./views/Device/Device";
import AddDevice from "./views/Device/AddDevice";
import EditDevice from "./views/Device/EditDevice";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MainSidebar from "./components/mainSidebar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Notification from "./views/Notification";
import NotifyMe from "./views/NotifyMe";
import Assets from "./views/AssetManagement/Assets.js";
import AddItem from "./views/AssetManagement/AddItem.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import EditItem from "./views/AssetManagement/EditItem";
import EditManufacturer from "./views/Manufacturer/EditManufacturer";
import AddManufacturer from "./views/Manufacturer/AddManufacturer";
import Manufacturer from "./views/Manufacturer/Manufacturer";
import Rulelistview from "./views/Rules/RulesListView";
import RelatedRulelist from "./views/Rules/RelatedRulelist";
import Tablepaginate from "./views/Rules/Tablepaginate";
import DemoEvent from "./components/Demo/Humsub";
import CustomHeader from "./components/CustomHeader";
import AccountDashboard from "./views/Users/Myaccount";
import RuleLogpage from "./views/Rules/RuleLogPage";
import AuditLogpage from "./views/Rules/AuditLogPage.js";
import AllRuleLogpage from "./views/Rules/AllRuleLogs.js";
import ViewLogDetails from "./views/Rules/ViewAllLog.js";
import TimeZone from "./views/Rules/TimeZone.js";
import ViewAllNotifications from "./views/UserDashboard/ViewAllNotification.js";
import AdminGolfDashboard from "./views/UserDashboard/Dashboard/AdminGolfDashboard.js";
import GolfMoreDetails from "./views/UserDashboard/Dashboard/Admin-golf-more-details.js";
import { useHistory } from "react-router-dom";
import { useAuthenticated } from "./views/CommanLoginauth.js";

const Reports = lazy(() => import("./views/Reports/Report.js"));

function ScrollToTopOnPageChange() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useAuthenticated();
  const history = useHistory();
  console.log("isauth =======> " + isAuthenticated);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          (() => {
            history.push("/login?sessionexpired=1");
            return null;
          })()
        )
      }
    />
  );
}

function Main() {
  const location = useLocation();

  return (
    <>
      <CssBaseline />
      {location.pathname === "/AdminGolfDashboard" ? "" : <Header />}
      <ToastContainer />
      {location.pathname === "/AdminGolfDashboard" ? "" : <MainSidebar />}
      <ScrollToTopOnPageChange />
      <Switch>
        <Route path="/Settings" component={AdminDashboard} />
        {/* <Route path="/Dashboard" component={Dashboard} /> */}
        <Route path="/overview" component={UserDashboard} />
        {/* <Route path="/tenniscourts" component={UserDashboard} /> */}
        <Route path="/MAFC" component={UserDashboard} />
        {/* <Route path="/churchstreettenniscourts" component={UserDashboard} /> */}
        <Route path="/ChurchStreetPark" component={UserDashboard} />
        {/* <Route path="/cfdppark" component={UserDashboard} /> */}
        <Route path="/CederForkDistrictPark" component={UserDashboard} />
        {/* <Route path="/mcppark" component={UserDashboard} /> */}
        <Route path="/MorrisvilleCommunityPark" component={UserDashboard} />
        <Route path="/shiloh" component={UserDashboard} />
        <Route path="/ccnp" component={UserDashboard} />
        <Route path="/AdminDashboard" component={UserDashboard} />
        <Route path="/AccessManagment" component={AccessManagment} />
        <Route path="/EditRole" component={EditRole} />


        <Route path="/AddRolePermission" component={AddRolePermission} />
        <Route path="/SignUp" component={SignUp} />
        <Route path="/ForgotPassword" component={ForgotPassword} />

        <Route path="/UserProfile" component={UserProfile} />
        <Route path="/MyAccount" component={AccountDashboard} />
        <Route path="/AddRule" component={AddRule} />
        <Route path="/EditUser" component={EditUser} />
        <Route path="/Users" component={Users} />
        <Route path="/field" component={Fields} />

        <Route path="/editfield" component={EditField} />
        <Route path="/AddUser" component={AddUser} />
        <Route path="/AddSensor" component={AddSensor} />
        <Route path="/EditSensor" component={UpdateSensor} />
        <Route path="/Device" component={Device} />

        <Route path="/EditDevice" component={EditDevice} />
        <Route path="/Sensor" component={Sensor} />
        <Route path="/notification" component={Notification} />
        <Route path="/notifyme" component={NotifyMe} />
        <Route path="/AssetManagement" component={Assets} />
        <Route path="/AddItem" component={AddItem} />
        <Route path="/EditItem" component={EditItem} />
        <Route path="/Manufacturer" component={Manufacturer} />

        <Route path="/EditManufacturer" component={EditManufacturer} />

        <Route path="/humsub" component={DemoEvent} />
        <Route exact path="/AdminGolfDashboard" component={AdminGolfDashboard} />
        {/* <Route path="/Golfoverview" component={GolfMoreDetails} />
        <Route path="/PracticeGreen" component={GolfMoreDetails} /> */}


        {/* Private Route */}
        <PrivateRoute path="/NewRule" component={NewRule} />
        <PrivateRoute path="/reports" component={Reports} />
        <PrivateRoute path="/Rules" component={Rules} />
        <PrivateRoute path="/createnewRule" component={CreatenewRule} />
        <PrivateRoute path="/rulelistview" component={Rulelistview} />
        <PrivateRoute path="/editrulecreate" component={Editrulecreated} />
        <PrivateRoute path="/relatedRulelist" component={RelatedRulelist} />
        <PrivateRoute path="/tablepaginate" component={Tablepaginate} />
        <PrivateRoute path="/RuleLogpage" component={RuleLogpage} />
        <PrivateRoute path="/TimeZone" component={TimeZone} />
        <PrivateRoute path="/AuditLogpage" component={AuditLogpage} />
        <PrivateRoute path="/allrulelogspage" component={AllRuleLogpage} />
        <PrivateRoute path="/ViewLogDetails" component={ViewLogDetails} />
        <PrivateRoute
          path="/allNotifications"
          component={ViewAllNotifications}
        />
        <PrivateRoute path="/AddFeature" component={AddFeature} />
        <PrivateRoute path="/AddRole" component={AddRole} />
        <PrivateRoute path="/AddField" component={AddField} />
        <PrivateRoute path="/AddDevice" component={AddDevice} />
        <PrivateRoute path="/AddManufacturer" component={AddManufacturer} />
        {/* Private Route */}
        <Redirect path="*" to="/" />
      </Switch>
      {/* <Footer /> */}
      {location.pathname === "/AdminGolfDashboard" ? "" : <Footer />}
    </>
  );
}

export default Main;
