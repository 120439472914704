import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { Link } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "./FieldModel";
import Grid from "@material-ui/core/Grid";
import smartParkService from "../../axios";
import parkMap from "../../assets/img/park-map.jpg";
import IconButton from "@material-ui/core/IconButton";
import { Clear, OpenInNewRounded } from "@material-ui/icons";
import { login_ } from "../../reducers/login";
import locationicon from "../../assets/img/google-maps.png";
import { GenerateEncryptedToken } from "../../views/Common";

import {
  sensors_,
  sensorsHasLoaded_,
  sensorServiceThunk,
} from "../../reducers/sensors";
import _ from "lodash";
import "../../assets/scss/App.scss";
import json5 from "json5";

const ButtonLink = withStyles((theme) => ({
  root: {
    display: "inline !important",
    color: "blue !important",
    marginRight: "10px !important",
    padding: "0 !important",
    textDecoration: "underline",
    border: "none !important",
    fontSize: "13px !important",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
  },
}))(Link);
const useStyles = makeStyles((theme) => ({
  footer: {
    textAlign: "center",
    paddingBottom: 10,
  },
  company: { fontWeight: "bold", fontSize: "1rem" },
}));

export default function GateStatus() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  //   const sensors = useSelector(sensors_);
  const sensorHasLoaded = useSelector(sensorsHasLoaded_);
  const [sensorstatus, setsensorstatus] = React.useState({});
  const [status, setstatus] = React.useState([]);
  const [open, setopen] = React.useState(false);
  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();

  const [Openorclose, setOpenorclose] = React.useState("");
  const [fieldNames, setFieldNames] = React.useState([]);
  const [Iselectall, setIselectall] = React.useState(false);
  const [allChecked, setallChecked] = React.useState(false);
  const [refresher, setRefresher] = React.useState(false);

  const getFields = (fields) => {
    var dataconst = fields.map((d) => {
      return {
        FieldID: d.id,
        isChecked: false,
        FieldName: d.FieldName,
        FieldStatus: d.FieldStatus,
        lastReportedDateTime: d.lastReportedDateTime,
      };
    });
    setFieldNames(dataconst);
  };

  React.useEffect(() => {
    var restoken = GenerateEncryptedToken();
    smartParkService
      .get("/api/citizen/fielddata", {
        headers: { "x-access-token": restoken },
      })
      .then((res, err) => {
        if (res && res.data && res.data.status == 200) {
          getFields(res.data.data);
        }
      });
  }, [refresher]);
  setTimeout(() => {
    setRefresher(!refresher);
  }, 5 * 60 * 1000);
  const handleClose = () => {
    setopen(false);
    setallChecked(false);
    getFields();
  };
  const handleFieldopen = () => {
    var isListChecked = false;
    if (
      fieldNames.filter(function (obj) {
        if (obj.isChecked) isListChecked = true;
      })
    );
    if (isListChecked) {
      setOpenorclose("Open");
      setopen(true);
    }
  };
  const handleFieldClose = () => {
    var isListChecked = false;
    if (
      fieldNames.filter(function (obj) {
        if (obj.isChecked) isListChecked = true;
      })
    );
    if (isListChecked) {
      setOpenorclose("Closed");
      setopen(true);
    }
  };
  const handleChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    let fieldNames1 = fieldNames;
    let allChecked1 = allChecked;
    if (itemName === "checkAll") {
      allChecked1 = checked;
      fieldNames1 = fieldNames.map((item) => ({
        ...item,
        isChecked: checked,
      }));
    } else {
      fieldNames1 = fieldNames.map((item) =>
        item.sensorID === itemName ? { ...item, isChecked: checked } : item
      );
      allChecked1 = fieldNames1.every((item) => item.isChecked);
    }
    setFieldNames(fieldNames1);
    setallChecked(allChecked1);
  };
  const updateStateList = (e, value) => {
    if (e.target.checked) {
      //append to array
      // this.setState({
      //   status: status.concat([value]),
      // });
      setstatus(status.concat([value]));
    } else {
      //remove from array

      setstatus(
        status.filter(function (val) {
          return val !== value;
        })
      );
    }
  };
  function padValue(value) {
    return value < 10 ? "0" + value : value;
  }
  function formatDate(dateVal) {
    if (!dateVal) return "";

    var d = new Date(dateVal);
    // debugger;
    var sMonth = padValue(d.getMonth() + 1);
    var sDay = padValue(d.getDate());
    var sYear = d.getFullYear();

    var hour =
      d.getHours() == 0
        ? 12
        : d.getHours() > 12
          ? d.getHours() - 12
          : d.getHours();
    var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
    var ampm = d.getHours() < 12 ? "AM" : "PM";
    var time = padValue(hour) + ":" + min + " " + ampm;

    return sMonth + "/" + sDay + "/" + sYear + " " + time;
  }
  return (
    <div>
      <Modal
        show={open}
        status={fieldNames}
        closePopup={handleClose}
        openclose={Openorclose}
      />
      <div class="box">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div class="box-header padB0">
              <span>
                {" "}
                <Link
                  className={classes.company}
                  onClick={() =>
                    window.open(
                      "https://www.google.com/maps/place/232+Aviation+Pkwy,+Morrisville,+NC+27560",
                      "_blank"
                    )
                  }
                >
                  <span
                    style={{ color: "#0076bb", textDecoration: "underline" }}
                  >
                    {" "}
                    <img
                      src={locationicon}
                      style={{
                        height: "25px",
                        backgroundSize: "cover",
                        marginLeft: "10px",
                      }}
                    />{" "}
                    232 Aviation Pkwy, Morrisville, NC 27560
                  </span>
                </Link>
              </span>
            </div>
            <div class="box-body">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div class="divGrid">
                    <div class="divGridHeading">
                      <div class="divGridRow">
                        <div class="divGridHead"> Field</div>
                        <div class="divGridHead">Status</div>
                      </div>
                    </div>
                    <div class="divGridBody">
                      {fieldNames.map((m) => (
                        <div class="divGridRow">
                          <div class="divGridCell">{m.FieldName}</div>
                          <div class="divGridCell">
                            <div class="d-flex">
                              <FiberManualRecordIcon
                                fontSize="small"
                                style={{
                                  color:
                                    m.FieldStatus === "Open"
                                      ? green[500]
                                      : red[500],
                                }}
                              />{" "}
                              {m.FieldStatus}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div class="box-header padB0">
              <h6 class="marB0 box-title">Current Field Status</h6>
              <span class="notify" style={{ marginLeft: "20px" }}>
                Last reported:{" "}
                {fieldNames &&
                  fieldNames.length > 0 &&
                  fieldNames[0].lastReportedDateTime
                  ? formatDate(new Date(fieldNames[0].lastReportedDateTime))
                  : ""}
              </span>
              <div class="float-right" style={{ marginBottom: "-10px" }}></div>
            </div>

            <div class="box-body">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <img
                    src={parkMap}
                    class="parkMap"
                    width="100%"
                    height="100%"
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
