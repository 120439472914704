import React from "react";
import MafcRacquetWidget from "../MafcRacquetWidget";
import { Box } from "@material-ui/core";

const MafcRacquetBall = () => {
  return (
    <Box display="flex" flexGrow={1}>
      <Box style={{ width: "50%" }}>
        <MafcRacquetWidget />
      </Box>
    </Box>
  );
};

export default MafcRacquetBall;
