import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // default
      sm: 600, // default
      md: 960, // default
      lg: 1280, // default
      xl: 1920,
      custom1: 500,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  equipmentList: {
    listStyleType: "none",
    paddingLeft: 10,
    paddingRight: 10,
  },
  equipmentItem: {
    width: "100%",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      width: "100%",
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("custom1")]: {
      flexWrap: "nowrap",
      "& > *": {
        width: "50%",
      },
    },
  },
}));

const OccupancyCardMobile = ({ heading, fieldList }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  if (!isMobile) return null;

  return (
    <Card elevation={8}>
      <CardContent>
        <Box>
          <Grid item xs={12}>
            <Typography align="center" variant="h5">
              {heading}
            </Typography>
          </Grid>
          <Box component="ul" className={classes.equipmentList}>
            {fieldList.map((equipment, index) => (
              <li key={index} className={classes.equipmentItem}>
                <Typography variant="h6">{equipment.name}</Typography>
                <Box className={classes.chipContainer}>
                  {equipment.chips.map((chip) => {
                    return (
                      <Chip
                        style={{
                          backgroundColor: chip.color || "#12d958de",
                          color: "#FFFFFF",
                        }} //
                        key={chip.value}
                        label={chip.name + chip.value}
                        size="small"
                      />
                    );
                  })}
                </Box>
              </li>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const App = ({ heading, fieldList }) => (
  <ThemeProvider theme={theme}>
    <OccupancyCardMobile fieldList={fieldList} heading={heading} />
  </ThemeProvider>
);
export default App;
