import React, { isValidElement } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import { useHistory } from "react-router-dom";
// core components
import styles from "../../components/components.js";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "@material-ui/core/Button";
import Logo from "./../../assets/img/Logo.png";
import { Person, Lock, TrendingFlat } from "@material-ui/icons";
import "../../assets/scss/App.scss";
import smartParkService from "../../axios";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(styles);
export default function SignInPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const [UserName, setUserName] = React.useState("");
  const [EmailString, setEmailString] = React.useState("");
  const [PasswordString, setPasswordString] = React.useState("");
  const [cPasswordString, setCPasswordString] = React.useState("");
  const [errUserName, seterrUserName] = React.useState("");
  const [errEmailString, seterrEmailString] = React.useState("");
  const [errPasswordString, seterrPasswordString] = React.useState("");
  const [errcPasswordString, errsetCPasswordString] = React.useState("");

  const [showStatus, setshowStatus] = React.useState(false);
  const [role, setRole] = React.useState({
    Citizen: true,
    Administrator: false,
    PWStaff: false,
    PWSupervisor: false,
  });
  const clickemail = () => {
    seterrEmailString("");
  };
  const onEmailhandleChange = (e) => {
    setEmailString(e.target.value);
  };
  const onUsernamechange = (e) => {
    setUserName(e.target.value);
  };
  const validatebeforesave = () => {
    var isvalid = true;
    if (UserName === "") {
      seterrUserName("Please enter Name");
      isvalid = false;
    } else {
      seterrUserName("");
    }
    if (PasswordString === "") {
      seterrPasswordString("Please enter Password");
      isvalid = false;
    } else {
      seterrPasswordString("");
    }
    if (cPasswordString === "") {
      errsetCPasswordString("Please enter Confirm Password");
      isvalid = false;
    } else {
      errsetCPasswordString("");
    }
    if (EmailString !== "") {
      var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!regexEmail.test(EmailString)) {
        seterrEmailString("Please enter valid EMAIL");
        isvalid = false;
      } else {
        seterrEmailString("");
      }
    } else {
      seterrEmailString("Please Enter Email");
    }
    if (PasswordString != "" && cPasswordString != "") {
      if (PasswordString != cPasswordString) {
        setCPasswordString("");
        errsetCPasswordString("Password confirmation and Password must match");
        isvalid = false;
      } else {
        errsetCPasswordString("");
      }
    }
    return isvalid;
  };
  const validateEmail = () => {
    if (EmailString === "" || EmailString === undefined) {
      seterrEmailString("Please Enter EmailAddress");
    }
    if (EmailString !== "") {
      var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!regexEmail.test(EmailString)) {
        seterrEmailString("Invalid email address");
      }
    }
  };
  const CheckEmailValidonblur = async () => {
    try {
      validateEmail();
      if (EmailString !== "") {
        await smartParkService
          .head("api/user?userEmail=" + EmailString.toLocaleLowerCase())
          .then((res) => {
            if (res.status === 200) {
              seterrEmailString("Email " + EmailString + " Already Exists");
              setEmailString("");
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const clearfields = () => {
    setEmailString("");
    setUserName("");
    setPasswordString("");
    setCPasswordString("");
  };

  const onPasswordhandleChange = (e) => {
    setPasswordString(e.target.value);
  };
  const oncPasswordhandleChange = (e) => {
    setCPasswordString(e.target.value);
  };
  const OnsignUp = async () => {
    try {
      if (validatebeforesave()) {
        var Dataobject = {
          FirstName: UserName,
          UserType: "Citizen",
          Password: PasswordString,
          EmailAddress: EmailString.toLowerCase(),
          Role: role,
          IsActive: "true",
          Authentication: "Smartpark",
        };
        await smartParkService
          .post("api/users", Dataobject)
          .then((res, err) => {
            if (res.data.status === "200") {
              setshowStatus(true);
              clearfields();
            } else {
              alert("InValid User Details");
            }
          })
          .catch(() => {
            alert("Email Already exixts");
          });
      }
    } catch (err) {
      console.log("Error in SignUp " + err);
    }
  };
  return (
    <div className={classes.section}>
      <Grid container justify="center" alignItems="center">
        <div className={classes.loginBox}>
          <Grid item xs={12}>
            <div className={classes.loginHeader}>
              <div className={classes.logo}>
                <img src={Logo} alt="Logo" />
              </div>
              <h6>Town of Morrisville Smart City Dashboard</h6>
            </div>
            <Card variant="outlined">
              <form className={classes.form}>
                <h4 className={classes.divider}>SignUp Now! </h4>
                <CardContent>
                  <Grid container item xs={12}>
                    <CustomInput
                      size="small"
                      labelText="Name"
                      id="Name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: UserName,
                        onChange: onUsernamechange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div style={{ color: "red" }}>{errUserName}</div>
                    <CustomInput
                      size="small"
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        value: EmailString,
                        onClick: clickemail,
                        onBlur: CheckEmailValidonblur,
                        onChange: onEmailhandleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div style={{ color: "red" }}>{errEmailString}</div>
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        value: PasswordString,
                        onChange: onPasswordhandleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div style={{ color: "red" }}>{errPasswordString}</div>
                    <CustomInput
                      labelText="Confirm Password"
                      id="confirmPassword"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        value: cPasswordString,
                        onChange: oncPasswordhandleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div style={{ color: "red" }}>{errcPasswordString}</div>
                  </Grid>
                  <Grid container item xs={12}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={12} className={classes.textCenter}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.btnRound}
                      onClick={OnsignUp}
                    >
                      SIGN UP &nbsp;&nbsp;{" "}
                      <TrendingFlat style={{ color: "#fff" }} />
                    </Button>
                  </Grid>
                  <Grid container item xs={12}>
                    &nbsp;
                  </Grid>
                  {/* <div class="LoginLink" style={{color:"green"}}>{statuslabel}</div> */}
                  <div class="LoginLink">
                    <span
                      style={{
                        display: showStatus ? "block" : "none",
                        color: "green",
                      }}
                    >
                      Registered Successfully.
                    </span>
                    <div style={{ display: showStatus ? "none" : "block" }}>
                      <span>Already have an account?</span>{" "}
                      <a href="#">Login Here</a>
                    </div>
                  </div>
                </CardContent>
              </form>
            </Card>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
