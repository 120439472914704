import { createSlice } from "@reduxjs/toolkit";
import produce from "immer";
import { ToastContainer, toast } from "react-toastify";
import { BiSolidXCircle } from "react-icons/bi";

const initialState = {
    basicSettings: [],
    validitySettings: [],
    operationalSettings: [], // Make sure this property is defined in your initial state
    conditionTrigerSettings: [],
    ConditionTrigerConditionSettings: [],
    actionSettings: [],
    emailnotificationSettings: [],
    smsnotificationSettings: [],
    blockDurationSettings: [],
};

const MAX_DUPLICATES = 1;
const MAX_DUPLICATESAction = 1;

const todoReducers = createSlice({
    name: "todo",
    initialState,
    reducers: {
        addBasicsSettings(state, action) {
            state.basicSettings = action.payload;
        },
        addValiditySettings(state, action) {
            state.validitySettings = action.payload;
        },
        addOperationalSettings(state, action) {
            return produce(state, (draft) => {
                action.payload.forEach((rule) => {
                    rule.OperationalTime.forEach((time) => {
                        time.DayofWeek.forEach((day) => {
                            const newEntry = {
                                DayofWeek: day,
                                StartTime: time.StartTime,
                                EndTime: time.EndTime,
                            };
                            // Check for duplicate entry before adding
                            const isDuplicate = draft.operationalSettings.some(
                                (existingEntry) =>
                                    existingEntry.DayofWeek === newEntry.DayofWeek &&
                                    existingEntry.StartTime === newEntry.StartTime &&
                                    existingEntry.EndTime === newEntry.EndTime
                            );
                            const isTimeDifference = (newEntry) => {
                                if (
                                    newEntry.EndTime.replace(/:/g, "") -
                                    newEntry.StartTime.replace(/:/g, "") >
                                    10
                                ) {
                                    return false;
                                } else {
                                    return true;
                                }
                            };
                            const isOverlap = draft.operationalSettings.some(
                                (existingEntry) =>
                                    existingEntry.DayofWeek === newEntry.DayofWeek &&
                                    // Check if the new entry's start time is within an existing entry's time range
                                    ((newEntry.StartTime >= existingEntry.StartTime &&
                                        newEntry.StartTime < existingEntry.EndTime) ||
                                        // Check if the new entry's end time is within an existing entry's time range
                                        (newEntry.EndTime > existingEntry.StartTime &&
                                            newEntry.EndTime <= existingEntry.EndTime) ||
                                        // Check if the new entry's time range fully contains an existing entry's time range
                                        (newEntry.StartTime <= existingEntry.StartTime &&
                                            newEntry.EndTime >= existingEntry.EndTime))
                            );
                            if (!isDuplicate && !isOverlap && !isTimeDifference(newEntry)) {
                                draft.operationalSettings.push(newEntry);
                            } else {
                                if (!isDuplicate && !isOverlap && isTimeDifference(newEntry)) {
                                    const mes = (
                                        <div className="toastsuccess">
                                            <div className="erroricon">
                                                <BiSolidXCircle />
                                            </div>
                                            <div className="toastmessage">
                                                <b>Error</b>
                                                <div>
                                                    Time difference will be greator than 10 minutes for{" "}
                                                    {newEntry.DayofWeek}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                    toast.error(mes, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                        className: "errorcontainer",
                                    });
                                } else {
                                    const mes = (
                                        <div className="toastsuccess">
                                            <div className="erroricon">
                                                <BiSolidXCircle />
                                            </div>
                                            <div className="toastmessage">
                                                <b>Error</b>
                                                <div>
                                                    Overlapping / duplicate time range error in
                                                    operational settings for {newEntry.DayofWeek}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                    toast.error(mes, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                        className: "errorcontainer",
                                    });
                                }
                            }
                        });
                    });
                });
            });
        },
        addConditionTrigerSettings(state, action) {
            state.conditionTrigerSettings = action.payload;
        },
        addConditionTrigerConditionSettings(state, action) {
            let TriggerStrategy = action.payload.data[0].TriggerStrategy;
            const newSetting =
                action.payload.data[0].Conditions[action.payload.index];
            console.log(newSetting);
            let getupdateID = localStorage.getItem("addupdateconditionID");
            function findvalue(obj) {
                for (let key in obj) {
                    if (!obj[key]) {
                        return false;
                    }
                }
                return true;
            }
            const checkvalue = findvalue(newSetting);
            if (getupdateID && getupdateID !== "" && checkvalue) {
                // console.log("sadsd=============");
                const duplicateCount = state.ConditionTrigerConditionSettings.filter(
                    (existingSetting) =>
                        // Compare based on your own logic
                        ((TriggerStrategy === "ALL" &&
                            newSetting.TriggerValue === "Open") ||
                            (TriggerStrategy === "ALL" &&
                                newSetting.TriggerValue === "Close")) &&
                        existingSetting._id !== getupdateID &&
                        existingSetting.ParkID === newSetting.ParkID &&
                        existingSetting.DeviceID === newSetting.DeviceID
                ).length;
                const isDuplicate = state.ConditionTrigerConditionSettings?.findIndex(
                    (existingCondition) =>
                        existingCondition.ParkID !== newSetting.ParkID ||
                        existingCondition.DeviceID !== newSetting.DeviceID ||
                        existingCondition.TriggerCriteria !== newSetting.TriggerCriteria ||
                        existingCondition.TriggerValue !== newSetting.TriggerValue ||
                        existingCondition.Tolerance !== newSetting.Tolerance
                );
                const isDuplicateAction = state.ConditionTrigerConditionSettings?.some(
                    (exitaction) =>
                        exitaction._id !== getupdateID &&
                        exitaction.ParkID === newSetting.ParkID &&
                        exitaction.DeviceID === newSetting.DeviceID &&
                        exitaction.TriggerCriteria === newSetting.TriggerCriteria &&
                        exitaction.TriggerValue === newSetting.TriggerValue
                );
                function randvalue(min, max) {
                    return Math.floor(Math.random() * (max - min + 1)) + min;
                }
                const randomNumber = randvalue(1, 100);
                if (
                    isDuplicate !== -1 &&
                    !isDuplicateAction &&
                    duplicateCount < MAX_DUPLICATES
                ) {
                    // console.log("enter sdfds ////////////");
                    return produce(state, (draft) => {
                        const conditionindex =
                            draft.ConditionTrigerConditionSettings.findIndex(
                                (item) => item._id === getupdateID
                            );
                        if (conditionindex !== -1) {
                            draft.ConditionTrigerConditionSettings[conditionindex] =
                                newSetting;
                            localStorage.removeItem("addupdateconditionID");
                            localStorage.setItem("removeupdate", randomNumber);
                        }
                    });
                } else {
                    console.log("isDuplicate ==> ", isDuplicate);
                    console.log("isDuplicateAction ==> ", isDuplicateAction);
                    console.log("duplicateCount ==> ", duplicateCount);
                    const mes = (
                        <div className="toastsuccess">
                            <div className="erroricon">
                                <BiSolidXCircle />
                            </div>
                            <div className="toastmessage">
                                <b>Error</b>
                                <div>Duplicate conditional settings.</div>
                            </div>
                        </div>
                    );
                    toast.error(mes, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: "errorcontainer",
                    });
                }
            } else {
                // Check for duplicate entry before adding
                const duplicateCount = state.ConditionTrigerConditionSettings.filter(
                    (existingSetting) =>
                        // Compare based on your own logic
                        (((TriggerStrategy === "ALL" &&
                            newSetting.TriggerValue === "Open") ||
                            (TriggerStrategy === "ALL" &&
                                newSetting.TriggerValue === "Close")) &&
                            existingSetting.ParkID === newSetting.ParkID &&
                            existingSetting.DeviceID === newSetting.DeviceID) ||
                        (!(
                            newSetting.TriggerValue === "Open" ||
                            newSetting.TriggerValue === "Close"
                        ) &&
                            existingSetting.ParkID === newSetting.ParkID &&
                            existingSetting.DeviceID === newSetting.DeviceID &&
                            existingSetting.TriggerCriteria === newSetting.TriggerCriteria) ||
                        (TriggerStrategy === "ANY" &&
                            existingSetting.ParkID === newSetting.ParkID &&
                            existingSetting.DeviceID === newSetting.DeviceID &&
                            existingSetting.TriggerCriteria === newSetting.TriggerCriteria &&
                            existingSetting.TriggerValue === newSetting.TriggerValue)
                ).length;

                if (duplicateCount < MAX_DUPLICATES) {
                    return produce(state, (draft) => {
                        draft.ConditionTrigerConditionSettings.push(newSetting);
                    });
                } else {
                    const mes = (
                        <div className="toastsuccess">
                            <div className="erroricon">
                                <BiSolidXCircle />
                            </div>
                            <div className="toastmessage">
                                <b>Error</b>
                                <div>Duplicate conditional settings.</div>
                            </div>
                        </div>
                    );
                    toast.error(mes, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: "errorcontainer",
                    });
                    return state;
                }
            }
        },
        addActionSettings(state, action) {
            const newActionSetting = action.payload[0].Action[0];
            const Timezone = action.payload[0].Timezone;
            let getupdateID = localStorage.getItem("addupdateID");
            newActionSetting.Timezone = Timezone;
            function findvalue(obj) {
                for (let key in obj) {
                    if (!obj[key]) {
                        return false;
                    }
                }
                return true;
            }
            const checkvalue = findvalue(newActionSetting);
            console.log("checkvalue ", checkvalue);
            if (getupdateID && getupdateID !== "" && checkvalue) {
                console.log("getupdateID ======= ", getupdateID);
                console.log("newActionSetting ======= ", newActionSetting);
                const isDuplicate = state.actionSettings?.findIndex(
                    (existingCondition) =>
                        existingCondition.ParkID !== newActionSetting.ParkID ||
                        existingCondition.Devicetype !== newActionSetting.Devicetype ||
                        existingCondition.DeviceID !== newActionSetting.DeviceID ||
                        existingCondition.Action !== newActionSetting.Action
                );
                const isDuplicateAction = state.actionSettings?.some(
                    (exitaction) =>
                        exitaction._id !== getupdateID &&
                        exitaction.ParkID === newActionSetting.ParkID &&
                        exitaction.Devicetype === newActionSetting.Devicetype &&
                        exitaction.DeviceID === newActionSetting.DeviceID
                );
                console.log("isDuplicateAction ------ ", isDuplicateAction);
                console.log("isDuplicate ------ ", isDuplicate);
                function randvalue(min, max) {
                    return Math.floor(Math.random() * (max - min + 1)) + min;
                }
                const randomNumber = randvalue(1, 100);
                if (isDuplicate !== -1 && !isDuplicateAction) {
                    return produce(state, (draft) => {
                        const existingIndex = draft.actionSettings.findIndex(
                            (item) => item._id === getupdateID
                        );
                        console.log("existingIndex ------ ", existingIndex);
                        // If the existing item is found, update it with new data
                        if (existingIndex !== -1) {
                            draft.actionSettings[existingIndex] = newActionSetting;
                            localStorage.removeItem("addupdateID");
                            localStorage.setItem("removeupdate", randomNumber);
                        }
                    });
                } else {
                    const mes = (
                        <div className="toastsuccess">
                            <div className="erroricon">
                                <BiSolidXCircle />
                            </div>
                            <div className="toastmessage">
                                <b>Error</b>
                                {isDuplicateAction ? (
                                    <div>Multiple actions against same settings.</div>
                                ) : (
                                    <div>Duplicate action settings.</div>
                                )}
                            </div>
                        </div>
                    );
                    toast.error(mes, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: "errorcontainer",
                    });
                }
            } else {
                // Check for duplicate entry before adding
                const isDuplicate = state.actionSettings?.some(
                    (existingCondition) =>
                        existingCondition.ParkID === newActionSetting.ParkID &&
                        existingCondition.ParkName === newActionSetting.ParkName &&
                        existingCondition.Devicetype === newActionSetting.Devicetype &&
                        existingCondition.DeviceID === newActionSetting.DeviceID &&
                        existingCondition.DeviceName === newActionSetting.DeviceName &&
                        existingCondition.Action === newActionSetting.Action
                );
                const isDuplicateAction = state.actionSettings?.some(
                    (exitaction) =>
                        exitaction.ParkID === newActionSetting.ParkID &&
                        exitaction.ParkName === newActionSetting.ParkName &&
                        exitaction.Devicetype === newActionSetting.Devicetype &&
                        exitaction.DeviceID === newActionSetting.DeviceID &&
                        exitaction.DeviceName === newActionSetting.DeviceName &&
                        exitaction.Action !== newActionSetting.Action
                );
                const isDuplicateTimezone = state.actionSettings?.some(
                    (existingCondition) =>
                        existingCondition.ParkID === newActionSetting.ParkID &&
                        existingCondition.ParkName === newActionSetting.ParkName &&
                        existingCondition.Devicetype === newActionSetting.Devicetype &&
                        existingCondition.DeviceID === newActionSetting.DeviceID &&
                        existingCondition.DeviceName === newActionSetting.DeviceName &&
                        existingCondition.Timezone === newActionSetting.Timezone
                );
                localStorage.setItem("Timezone", Timezone);
                if (!isDuplicate && !isDuplicateAction && !isDuplicateTimezone) {
                    return produce(state, (draft) => {
                        draft.actionSettings.push(newActionSetting);
                    });
                } else {
                    if (isDuplicateTimezone) {
                        const mes = (
                            <div className="toastsuccess">
                                <div className="erroricon">
                                    <BiSolidXCircle />
                                </div>
                                <div className="toastmessage">
                                    <b>Error</b>
                                    {isDuplicateAction ? (
                                        <div>Multiple actions against same settings.</div>
                                    ) : (
                                        <div>Duplicate action settings.</div>
                                    )}
                                </div>
                            </div>
                        );
                        toast.error(mes, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            className: "errorcontainer",
                        });
                        return state;
                    } else {
                        const mes = (
                            <div className="toastsuccess">
                                <div className="erroricon">
                                    <BiSolidXCircle />
                                </div>
                                <div className="toastmessage">
                                    <b>Error</b>
                                    <div>
                                        Remove the existing park action ; you are trying to add a
                                        park action for a different time zone.
                                    </div>
                                </div>
                            </div>
                        );
                        toast.error(mes, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            className: "errorcontainer",
                        });

                        return state;
                    }
                }
            }
        },

        addemailNotificationSettings(state, action) {
            state.emailnotificationSettings = action.payload;
        },
        addsmsNotificationSettings(state, action) {
            state.smsnotificationSettings = action.payload;
        },
        resetState(state, action) {
            state.basicSettings = [];
            state.validitySettings = [];
            state.operationalSettings = [];
            state.conditionTrigerSettings = [];
            state.ConditionTrigerConditionSettings = [];
            state.actionSettings = [];
            state.smsnotificationSettings = [];
            state.emailnotificationSettings = [];
            // state.blockDurationSettings = [];
        },
        resetBasicState(state, action) {
            state.basicSettings = [];
        },
        resetvalidityState(state, action) {
            state.validitySettings = [];
        },
        resetoperationalState(state, action) {
            state.operationalSettings = [];
        },
        resetTrigerState(state, action) {
            state.conditionTrigerSettings = [];
            state.ConditionTrigerConditionSettings = [];
        },
        resetTrigerConditionState(state, action) {
            state.ConditionTrigerConditionSettings = [];
        },
        resetactionState(state, action) {
            state.actionSettings = [];
        },
        resetnotificationState(state, action) {
            state.smsnotificationSettings = [];
            state.emailnotificationSettings = [];
        },
        resetemailnotificationState(state, action) {
            state.emailnotificationSettings = [];
        },
        resetsmsnotificationState(state, action) {
            state.smsnotificationSettings = [];
        },
        // resetblockDurationState(state, action) {
        //   state.blockDurationSettings = [];
        // },
        deleteparticularthis(state, action) {
            console.log("perticliure deletre");
            return produce(state, (draft) => {
                const indexToDelete = action.payload;
                if (
                    indexToDelete >= 0 &&
                    indexToDelete < draft.ConditionTrigerConditionSettings.length
                ) {
                    draft.ConditionTrigerConditionSettings.splice(indexToDelete, 1);
                }
            });
        },
        deleteactionsettingparticularthis(state, action) {
            console.log("perticliure deletre");
            return produce(state, (draft) => {
                const indexToDelete = action.payload;
                if (indexToDelete >= 0 && indexToDelete < draft.actionSettings.length) {
                    draft.actionSettings.splice(indexToDelete, 1);
                }
            });
        },
        deleteoperationalsettingparticularthis(state, action) {
            console.log("perticliure deletre");
            const dayOfWeekToDelete = action.payload;
            return produce(state, (draft) => {
                draft.operationalSettings = draft.operationalSettings.filter(
                    (setting) => setting.DayofWeek !== dayOfWeekToDelete
                );
            });
        },
        deleteOperationalSettingIndividual(state, action) {
            const { dayOfWeekToDelete, idxToDelete, daystarttime, dayendtime } =
                action.payload;
            return produce(state, (draft) => {
                const dayIndex = draft.operationalSettings.findIndex(
                    (item) =>
                        item.DayofWeek === dayOfWeekToDelete &&
                        item.StartTime === daystarttime &&
                        item.EndTime === dayendtime
                );
                if (dayIndex !== -1) {
                    console.log(dayIndex);
                    draft.operationalSettings.splice(dayIndex, 1);
                }
            });
        },
    },
});
export default todoReducers.reducer;
export const todosAction = todoReducers.actions;
