import React from "react";
import clsx from "clsx";
// nodejs library that concatenates classes
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { confirmAlert } from "react-confirm-alert";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "../../components/CustomButtons/Button.js";
import {
  Settings,
  Lock,
  DashboardOutlined,
  GavelOutlined,
  PeopleAltOutlined,
  LeakAdd,
  DeviceHub,
  Tune,
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import iconSunrise from "../../assets/img/icon-sunrise.png";
import iconSunset from "../../assets/img/icon-sunset.png";
import Chip from "@material-ui/core/Chip";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import "../../assets/scss/App.scss";
import styles from "../../components/components.js";
import smartParkService from "../../axios";
import moment from "moment-timezone";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { login_, token_ } from "../../reducers/login";
import permissions, {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import {
  sensors_,
  sensorsHasLoaded_,
  sensorServiceThunk,
} from "../../reducers/sensors";
import _ from "lodash";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;
const useStyles = makeStyles(styles);

export default function AdminDashboard(props) {
  const loginDetails = useSelector(login_);
  const history = useHistory();
  const parkid = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const username = _.get(loginDetails, ["FirstName"], "");
  const token = useSelector(token_);
  const sensors = useSelector(sensors_);
  const dispatch = useDispatch();
  const sensorHasLoaded = useSelector(sensorsHasLoaded_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [loading, setLoading] = React.useState(true);
  const [color, setColor] = React.useState("#ffffff");
  const [yes, setYes] = React.useState(false);
  const classes = useStyles();
  const [permission, setpermission] = React.useState({});
  const [ischecked, setischecked] = React.useState(false);

  const [values, setValues] = React.useState({
    sunriseTime: "0",
    sunsetTime: "0",
  });
  const [fields, setFields] = React.useState({
    field1: [],
    field2: [],
    field3: [],
    field4: [],
    field5: [],
  });
  const [rows, setrows] = React.useState([]);
  const [field1, setField1] = React.useState(0);
  const [field2, setField2] = React.useState(0);
  const [field3, setField3] = React.useState(0);
  const [field4, setField4] = React.useState(0);
  const [field5, setField5] = React.useState(0);

  const getfieldstatus = async () => {
    await smartParkService
      .get("api/park/" + parkid + "/fieldstatus")
      .then((res) => {
        setrows(res.data.data);
      });
  };
  const closeallsensors = (id) => {
    confirmAlert({
      message: "Are you sure you want to close all Fields?",
      buttons: [
        {
          label: "Yes",
          onClick: closeallfields(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  const openclosefieldconfirm = (id, status) => {
    var openclose = status == "Open" ? "Closed" : "Open";
    confirmAlert({
      message: "Are you sure you want to " + openclose + " Field?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            openclosefield(id, openclose);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  const closeallfields = () => {
    var sensoridarray = [];
    sensors.map((e) => {
      sensoridarray.push(e.SensorCollectionID);
    });

    var Dataobject = {};
    Dataobject.sensorID = sensoridarray;
    Dataobject.sensorStatus = "Closed";
    Dataobject.LatEditedBy = _.get(loginDetails, ["FirstName"], "");

    smartParkService
      .post("api/park/" + parkid + "/updatesensorstatus", Dataobject, {
        headers: {
          userid: userid,
        },
      })
      .then((res, error) => {
        if (res.status === 200) {
          const parkid = _.get(loginDetails, ["parkID"], "");
          parkid && dispatch(sensorServiceThunk(parkid));
          setYes(!yes);
        }
        else if (res.status === 401 || res.status === 403) {
          alert("You do not have permission to update Field(s) Status");  
        }
      })
      .catch((err) => {
        alert("Error occurred while updating the Field(s) Status");
      });
  };
  const loadSparkLineData = async () => {
    var range = { startDate: 1608308022012, endDate: 1608394424188 };

    fetch("DummyChart.json")
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          var fieldMoistureObject = {
            field1: [],
            field2: [],
            field3: [],
            field4: [],
            field5: [],
          };
          var field1 = 0,
            field2 = 0,
            field3 = 0,
            field4 = 0,
            field5 = 0;
          data.filter(function (obj) {
            if (obj.date >= range.startDate && obj.date <= range.endDate) {
              if (obj.field === 1) {
                fieldMoistureObject["field1"].push(obj.moisture);
                field1 += Number(obj.moisture);
              } else if (obj.field === 2) {
                fieldMoistureObject["field2"].push(obj.moisture);
                field2 += Number(obj.moisture);
              } else if (obj.field === 3) {
                fieldMoistureObject["field3"].push(obj.moisture);
                field3 += Number(obj.moisture);
              } else if (obj.field === 4) {
                fieldMoistureObject["field4"].push(obj.moisture);
                field4 += Number(obj.moisture);
              } else if (obj.field === 5) {
                fieldMoistureObject["field5"].push(obj.moisture);
                field5 += Number(obj.moisture);
              }
            }
          });
          setField1(field1 / fieldMoistureObject["field1"].length);
          setField2(field2 / fieldMoistureObject["field2"].length);
          setField3(field3 / fieldMoistureObject["field3"].length);
          setField4(field4 / fieldMoistureObject["field4"].length);
          setField5(field5 / fieldMoistureObject["field5"].length);
          setFields(fieldMoistureObject);
        }
      });
  };
  const Getautomaticfieldstatus = () => {
    smartParkService
      .get("api/park/" + parkid + "/fieldautomaticactions")
      .then((res) => {
        if (res.data.status == 200 && res.data.data != null) {
          setischecked(res.data.data.ExecuteAction);
        }
      });
  };
  const automaticactions = (e) => {
    setischecked(e.target.checked);
    var obj = {};
    obj.ParkID = parkid;
    obj.ActionName = "Test";
    obj.ExecuteAction = e.target.checked;
    obj.InsertedBy = username;
    smartParkService
      .post("api/park/" + parkid + "/fieldautomaticactions", obj, {
        headers: {
          userid: userid,
        },
      })
      .then((res, error) => {
        if (res.status === 200) {
          alert("Automatic actions on sunset saved");
        }
        else if (res.status === 401 || res.status === 403) {
          alert("You do not have permission to update Automatic actions");  
        }
      })
      .catch((err) => {
        alert("Error occurred while updating the Automatic actions");
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    { field: "fieldName", headerName: "Field", width: 250 },
    { field: "sensor", headerName: "Sensor", width: 250 },
    { field: "moistureLevel", headerName: "Moisture Level", width: 150 },
    {
      field: "trend",
      headerName: "Trend",
      width: 200,
      renderCell: (params) => (
        <svg style={{ width: 100, height: 20 }}>
          <Sparklines data={params.value.map((v) => parseFloat(v))}>
            <SparklinesLine style={{ fill: "none" }} color="blue" />
          </Sparklines>
        </svg>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <div>
          {permission.create == 1 ? (
            <div
              onClick={() => {
                openclosefieldconfirm(
                  params.row.sensor,
                  params.row.sensorstatus
                );
              }}
            >
              <Button>
                <LeakAdd
                  className={
                    params.row.sensorstatus == "Open"
                      ? classes.iconError
                      : classes.iconopen
                  }
                />
                {params.row.sensorstatus === "Open"
                  ? "Close Field"
                  : "Open Field"}
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];
  const openclosefield = (sensorid, status) => {
    var Dataobject = {};
    Dataobject.sensorID = [sensorid];
    Dataobject.sensorStatus = status;
    Dataobject.LastEditedBy = _.get(loginDetails, ["FirstName"], "");

    smartParkService
      .post("api/park/" + parkid + "/updatesensorstatus", Dataobject, {
        headers: {
          userid: userid,
        },
      })
      .then((res, error) => {
        if (res.status === 200) {
          const parkid = _.get(loginDetails, ["parkID"], "");
          parkid && dispatch(sensorServiceThunk(parkid));
          setYes(!yes);
        }
        else if (res.status === 401 || res.status === 403) {
          alert("You do not have permission to update Gate Status");
        }
      })
      .catch((err) => {
        alert("Error occurred while to changing the field status");
        console.log(err);
      });
  };
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  React.useEffect(() => {
    if (permissionsHasLoaded) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);

  useEffect(() => {
    getfieldstatus();
  }, [yes]);
  useEffect(() => {
    getfieldstatus();
    Getautomaticfieldstatus();
    smartParkService
      .get("api/park/" + parkid + "/getsunsetsunrise")
      .then((res) => {
        let sr = res.data.data[0].sunrise;
        let ss = res.data.data[0].sunset;
        if (res) {
          setValues({
            sunriseTime: new Date(sr)
              .toLocaleString([], {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
              .replace(",", ""),
            sunsetTime: new Date(ss)
              .toLocaleString([], {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
              .replace(",", ""),
          });
        } else {
        }
      });
  }, []);
  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "Administration";
    });

    setpermission(Dpermissions[0]);
  };
  return (
    <div className={classes.root}>
      {permission.read == 1 ? (
        <div className={classes.contentPage}>
          <section class="content-header">
            <h3 class="float-left">Dashboard</h3>
            <div class="float-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a class="active" href="#">
                    Dashboard
                  </a>
                </li>
              </ol>
            </div>
          </section>
          <section class="content">
            <div class="content-middle">
              <div class="content-sub-header">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <h6 class="float-left">
                      Current Status:{" "}
                      <span class="sucessColor">All fields open</span>
                    </h6>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {permission.create == 1 ? (
                      <div
                        class="f-right xs-floatNone"
                        onClick={closeallsensors}
                      >
                        <Chip
                          variant="outlined"
                          icon={<HighlightOffIcon style={{ color: "red" }} />}
                          label="Shutdown All Fields"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </div>
              <Paper className={classes.paper}>
                <Grid
                  container
                  xs={12}
                  md={10}
                  className={classes.twilightBlock}
                >
                  <Grid item xs={12} sm={6}>
                    <div class="twilightBox borderRight borderRightNone">
                      <Box
                        display="flex"
                        alignItems="center"
                        className={
                          classes.flexColMobile +
                          " " +
                          classes.alignItemsStartMobile
                        }
                      >
                        <Box display="flex" alignItems="center">
                          <img src={iconSunrise} alt="Sun rise" />
                          <h3>Sunrise </h3>
                        </Box>
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="sunrise-time"
                            value={values.sunriseTime}
                            aria-describedby="Sunrise Time"
                            inputProps={{
                              "aria-label": "Sunrise time",
                            }}
                            style={{ width: "200px" }}
                            labelWidth={0}
                          />
                        </FormControl>
                      </Box>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div class="twilightBox">
                      <Box
                        display="flex"
                        alignItems="center"
                        className={
                          classes.flexColMobile +
                          " " +
                          classes.alignItemsStartMobile
                        }
                      >
                        <Box display="flex" alignItems="center">
                          <img src={iconSunset} alt="Sunset" />
                          <h3>Sunset </h3>
                        </Box>
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            size="small"
                            id="sunset-time"
                            value={values.sunsetTime}
                            aria-describedby="Sunset Time"
                            inputProps={{
                              "aria-label": "Sunset Time",
                            }}
                            style={{ width: "200px" }}
                            labelWidth={0}
                          />
                        </FormControl>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <div class="form-body pad20">
                      {permission.create == 1 ? (
                        <div>
                          <label>
                            <Checkbox
                              color="primary"
                              checked={ischecked}
                              inputProps={{
                                "aria-label":
                                  "Automatically Close fields at Sunset",
                              }}
                              onChange={automaticactions}
                            />{" "}
                            Automatically Close fields at Sunset.
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      <div style={{ height: 400, width: "100%" }}>
                        <DataGrid
                          rowHeight={20}
                          rows={rows}
                          hideFooter="true"
                          columns={columns}
                        />
                      </div>
                    </div>
                    {permission.create == 1 ? (
                      <div
                        class="form-footer"
                        onClick={() => {
                          history.push("/Rules");
                        }}
                      >
                        (
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          className={classes.btnRound}
                          startIcon={<SettingsOutlinedIcon />}
                        >
                          Setup rules for automatic operations
                        </Button>
                        )
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </section>
        </div>
      ) : { loading } ? (
        <ClipLoader color={color} loading={loading} css={override} size={60} />
      ) : (
        <div
          style={{
            color: "red",
            position: "absolute",
            top: "50%",
            left: "40%",
            fontSize: "20px",
            transform: "translate(" - (50 % ", ") - (50 % ")"),
          }}
        >
          "You do not have read permissions. Please contact Admin"
        </div>
      )}
    </div>
  );
}
