import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import styles from "../../components/components.js";
import FormHelperText from "@material-ui/core/FormHelperText";
const useStyles = makeStyles(styles);
export default function SelectParks(props) {
  const classes = useStyles();
  const [parkName, setparkName] = React.useState(props.rules?.ParkName != "" ? props.rules?.ParkName : "CFDP");
  const handleChange = (event) => {
    setparkName(event.target.value);
    props.updateRuleStateObj("ParkName", event.target.value);
  };
  React.useEffect(() => {
    props.updateRuleStateObj("ParkName", "CFDP");
  }, [])
  const errors = props.Errors;
  return (
    <React.Fragment>
      <div class="stepper-row-content1">
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.w100p} variant="outlined">
              <Select
                size="small"
                value={parkName}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">Slect Park</MenuItem>
                <MenuItem value="CFDP">Cedar Fork District Park (CFDP)</MenuItem>
                <MenuItem value="CSP">Church Street Park (CSP)</MenuItem>
                <MenuItem value="MAFC">Morrisville Aquatics & Fitness Center (MAFC)</MenuItem>
                <MenuItem value="MCP">Morrisville Community Park (MCP)</MenuItem>
              </Select>
              <FormHelperText style={{ color: "red", fontSize: "15px" }}>
                {errors.ParkName}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <br />
    </React.Fragment>
  );
}
