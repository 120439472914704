import React, { useCallback} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import { DirectionsOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "context-menu"
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "-60px",
    position: "relative",
    padding: theme.spacing(0, 2),
    height:60
  },
  cardContent: {
    paddingTop:32,
  },
  h5: {
    color: "#fff",
    fontWeight: 600,
    width:'50%',
    marginBottom:0,
    [theme.breakpoints.down('sm')]: {
      fontSize:'1rem'
     },
  },
  media: {
    height: 200,
    cursor: 'default',
  },
  customButton: {
    textTransform: "capitalize",
    fontSize: "0.95em",
    fontWeight: 500,
    boxShadow: "none !important",
  },
  titleBtn: {
    background: "#b8f0d2",
    color: "#0b9444",
    fontWeight: "bold",
    borderRadius: 4,
    fontSize: 12,
  },
}));

export default function CustomCard(props) {
  const { children, title, actions, imgURL, allNotify,mapUrl } = props;
  const classes = useStyles();
  const openDirections = useCallback(() => {
    window.open(mapUrl, "_blank");
  }, []);
  return (
    <Card elevation={8} className={classes.card}>
      <CardActionArea>
        <CardMedia className={classes.media} image={imgURL} />
      </CardActionArea>
      <div className={classes.cardTitle}>
        <h5 className={classes.h5}>{title}</h5>
        {/* The status button in the picture of each park is not displayed */}
        {/* <Button variant="contained" className={classes.titleBtn}>
          Open
        </Button> */}
      </div>
      <CardContent>{children}</CardContent>
      <CardActions>
        {actions ? (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.customButton}
            >
              More Details
            </Button>
            <Button
              color="primary"
              startIcon={<DirectionsOutlined />}
              className={classes.customButton}
              onClick={openDirections}
            >
              Get Directions
            </Button>
          </>
        ) : (
          ""
        )}
        {allNotify ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.customButton}
          >
            {" "}
            All Notifications{" "}
          </Button>
        ) : (
          ""
        )}
      </CardActions>
    </Card>
  );
}

CustomCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  actions: PropTypes.bool,
  allNotify: PropTypes.bool,
};
