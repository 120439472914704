import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import { fieldMoistureService } from '../services/fieldMoisture'

export const initialState = {
	isLoading: false,
	hasLoaded: false,
	data: []
}

export const fieldmoisturethunk = createAsyncThunk(
	"get/moisture/",
	async (payloadObj) => {
		return await fieldMoistureService(payloadObj)
	}
);

const fieldMoisture = createSlice({
	name: 'fieldMoisture',
	initialState,
	reducers: {
		unmountLogin: () => {
			return { ...initialState }
		}
	},
	extraReducers: builder => {
		builder.addCase(fieldmoisturethunk.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(fieldmoisturethunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.hasLoaded = true
			state.data = action.payload.data;
		});
		builder.addCase(fieldmoisturethunk.rejected, (state, action) => {
			//TODO
		});
	}
})

export const stateSelector_ = ({ fieldMoisture }) => fieldMoisture;

export const fieldmoisture_ = createSelector(stateSelector_, (fieldMoisture) => fieldMoisture?.data)

export const hasLoaded_ = createSelector(stateSelector_, (fieldMoisture) => fieldMoisture?.hasLoaded)

export const { unmountLogin } = fieldMoisture.actions

export default fieldMoisture.reducer
