import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import smartParkService from "../../axios";
import { sensorServiceThunk } from "../../reducers/sensors";
import _ from "lodash";
import { withStyles } from "@material-ui/core";
import { login_, unmountLogin, token_ } from "../../reducers/login";

const ButtonSm = withStyles((theme) => ({
    root: {
        fontSize: 13,
        textTransform: "none",
        borderRadius: "100px",
    },
}))(Button);
const ModalDialog = withStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {
            padding: "25px",
        },
    },
}))(Dialog);

export default function Closeallpark(props) {
    // console.log(props);
    const dispatch = useDispatch();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState("sm");
    const [yes, setYes] = React.useState("");
    const allparkdata = props.pname;
    const parkname = props.parkname;
    const parokopenclosedta = props.parkopenclose;
    const parkrealname =
        parkname === "cfdp"
            ? "Cedar Fork District Park"
            : parkname === "churchstreettenniscourts"
                ? "Church Street Park"
                : parkname === "mafctenniscourts"
                    ? "Morrisville Aquatics & Fitness Center"
                    : "Morrisville Community Park";
    const loginDetails = useSelector(login_);
    const userid = _.get(loginDetails, ["UserID"], "");
    const [getParkNameStatus, setParkNameStatus] = React.useState("");

    React.useEffect(() => {
        let parkid;
        if (parkname == "cfdp") {
            parkid = "c7a1d85f-8168-4e64-8e38-116a0624bfea";
            getFields(parkid);
        } else if (parkname == "churchstreettenniscourts") {
            parkid = "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944";
            getFields(parkid);
        } else if (parkname == "mafctenniscourts") {
            parkid = "91474e36-76c5-4d59-a8da-2998ab14a4bf";
            getFields(parkid);
        } else {
            parkid = "c7a1d85f-8168-4e64-8e38-116a0624bfeb";
            getFields(parkid);
        }
    }, [parkname]);

    const getFields = (parkID) => {
        var url = "api/park/" + parkID + "/facilitiesmanagement";
        smartParkService
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    var data = res.data;
                    if (data.status == "200") {
                        console.log(data.message);
                        setParkNameStatus(data.message);
                    }
                }
            })
            .catch((error) => {
                console.log("park status error");
            });
    };

    const handleSubmit = async () => {
        if (yes.trim().toLowerCase() === "yes") {
            // console.log(allparkdata, props.fieldsByGroup, props.parkname);
            let parkid;
            if (parkname == "cfdp") {
                parkid = "c7a1d85f-8168-4e64-8e38-116a0624bfea";
                // console.log(allparkdata);
                const fieldsdata = allparkdata.filter(
                    (m) => m.fieldname.indexOf("Field") > -1
                );
                var dataobject = {};
                dataobject.fieldID = fieldsdata.map((m) => m.fieldid);
                dataobject.fieldName = fieldsdata.map((m) => m.fieldname);
                dataobject.fieldStatus = parokopenclosedta;
                dataobject.LastEditedBy = _.get(loginDetails, ["FirstName"], "");
                dataobject.disableNotification = true;
                let search = window.location.search;
                let params = new URLSearchParams(search);
                if (params.get("adminmode") === "1")
                    dataobject.disableNotification = false;
                // console.log(dataobject)
                await smartParkService
                    .post("api/park/" + parkid + "/updatefieldstatus", dataobject, {
                        headers: {
                            userid: userid,
                        },
                    })
                    .then((res, error) => {
                        if (res.status === 200) {
                            const respData = res.data;

                            const errResp =
                                respData &&
                                respData.length > 0 &&
                                respData.filter((obj) => {
                                    return obj.status !== 200;
                                });
                            let errFieldNames =
                                errResp &&
                                errResp.length > 0 &&
                                errResp.map((obj) => {
                                    return obj.sensorName;
                                });
                            if (errFieldNames && errFieldNames.length > 0) {
                                alert(
                                    "Error occurred while changing " +
                                    errFieldNames.join(", ") +
                                    " field(s) status"
                                );
                            }
                            const parkid = _.get(loginDetails, ["parkID"], "");
                            parkid && dispatch(sensorServiceThunk(parkid));
                        }
                        if (res.status === 401 || res.status === 403) {
                            alert("You do not have permission to update field(s) Status");
                        }
                        setYes("");
                        props.closePopup();
                    })
                    .catch((err) => {
                        setYes("");
                        alert("Error occurred while updating field(s) status");
                        console.log(err);
                        return;
                    });
            } else if (parkname == "churchstreettenniscourts") {
                parkid = "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944";

                const gatesdata = allparkdata.filter(
                    (m) => m.fieldname.indexOf("DEV") > -1
                );

                var dataobj = {};
                dataobj.gates = gatesdata.map((m) => m.fieldname);
                dataobj.deviceIDs = gatesdata.map((m) => m.fieldid);
                dataobj.status = parokopenclosedta;
                dataobj.LastEditedBy = _.get(loginDetails, ["FirstName"], "");

                dataobj.disableNotification = true;
                let search = window.location.search;
                let params = new URLSearchParams(search);

                if (params.get("adminmode") === "1")
                    dataobj.disableNotification = false;

                // var url = "api/park/" + parkid + "/updatedevice-csp";
                var url = "api/park/" + parkid + "/updategatestatus-arduinocloud";
                await smartParkService
                    .post(url, dataobj, {
                        headers: {
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            const respData = res.data;
                            const errResp =
                                respData &&
                                respData.length > 0 &&
                                respData.filter((obj) => {
                                    return obj.status !== 200;
                                });
                            let errFieldNames =
                                errResp &&
                                errResp.length > 0 &&
                                errResp.map((obj) => {
                                    return obj.gate;
                                });
                            if (errFieldNames && errFieldNames.length > 0) {
                                alert(
                                    "Error occurred while changing " +
                                    errFieldNames.join(", ") +
                                    " gate(s) status"
                                );
                            }
                        } else {
                        }
                        setYes("");
                        props.closePopup();
                    })
                    .catch((err) => {
                        setYes("");
                        alert("Error occurred while updating device status");
                        console.log(err);
                        return;
                    });
                // add api to close gate
            } else if (parkname == "mafctenniscourts") {
                parkid = "91474e36-76c5-4d59-a8da-2998ab14a4bf";
                // skip
            } else {
                parkid = "c7a1d85f-8168-4e64-8e38-116a0624bfeb";
                const fieldsdata = allparkdata.filter(
                    (m) => m.fieldname.indexOf("Field") > -1
                );
                var dataobject = {};
                dataobject.fieldID = fieldsdata.map((m) => m.fieldid);
                dataobject.fieldName = fieldsdata.map((m) => m.fieldname);
                dataobject.fieldStatus = parokopenclosedta;
                dataobject.LastEditedBy = _.get(loginDetails, ["FirstName"], "");
                dataobject.disableNotification = true;
                let search = window.location.search;
                let params = new URLSearchParams(search);
                if (params.get("adminmode") === "1")
                    dataobject.disableNotification = false;
                // console.log(dataobject)
                await smartParkService
                    .post("api/park/" + parkid + "/updatefieldstatusnoled", dataobject, {
                        headers: {
                            userid: userid,
                        },
                    })
                    .then((res, error) => {
                        if (res.status === 200) {
                            const respData = res.data;
                            const errResp =
                                respData &&
                                respData.length > 0 &&
                                respData.filter((obj) => {
                                    return obj.status !== 200;
                                });
                            let errFieldNames =
                                errResp &&
                                errResp.length > 0 &&
                                errResp.map((obj) => {
                                    return obj.sensorName;
                                });
                            if (errFieldNames && errFieldNames.length > 0) {
                                alert(
                                    "Error occurred while changing " +
                                    errFieldNames.join(", ") +
                                    " field(s) status"
                                );
                            }
                            const parkid = _.get(loginDetails, ["parkID"], "");
                            parkid && dispatch(sensorServiceThunk(parkid));
                        }
                        if (res.status === 401 || res.status === 403) {
                            alert("You do not have permission to update field(s) Status");
                        }
                        setYes("");
                        props.closePopup();
                    })
                    .catch((err) => {
                        setYes("");
                        alert("Error occurred while updating field(s) status");
                        console.log(err);
                        return;
                    });
                const gatesdata = allparkdata.filter(
                    (m) => m.fieldname.indexOf("DEV") > -1
                );
                var dataobj = {};
                dataobj.gates = gatesdata.map((m) => m.fieldname);
                dataobj.deviceIDs = gatesdata.map((m) => m.fieldid);
                dataobj.status = parokopenclosedta;
                dataobj.LastEditedBy = _.get(loginDetails, ["FirstName"], "");
                dataobj.disableNotification = true;
                if (params.get("adminmode") === "1")
                    dataobj.disableNotification = false;
                var url = "api/park/" + parkid + "/updategatestatus-arduinocloud";
                await smartParkService
                    .post(url, dataobj, {
                        headers: {
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            const respData = res.data;
                            const errResp =
                                respData &&
                                respData.length > 0 &&
                                respData.filter((obj) => {
                                    return obj.status !== 200;
                                });
                            let errFieldNames =
                                errResp &&
                                errResp.length > 0 &&
                                errResp.map((obj) => {
                                    return obj.gate;
                                });
                            if (errFieldNames && errFieldNames.length > 0) {
                                alert(
                                    "Error occurred while changing " +
                                    errFieldNames.join(", ") +
                                    " gate(s) status"
                                );
                            }
                        } else {
                        }
                        setYes("");
                        props.closePopup();
                    })
                    .catch((err) => {
                        setYes("");
                        alert("Error occurred while updating device status");
                        console.log(err);
                        return;
                    });
            }
            // call close park api here
            var url = "api/park/" + parkid + "/facilitiesmanagement";
            smartParkService
                .post(
                    url,
                    { status: parokopenclosedta },
                    {
                        headers: {
                            userid: userid,
                        },
                    }
                )
                .then((res, error) => {
                    if (res.status === 200) {
                        // console.log(res);
                    }
                    if (res.status === 401 || res.status === 403) {
                        alert("You do not have permission to update field(s) Status");
                    }
                    setYes("");
                    props.closePopup();
                });
        }
    };

    return (
        <div>
            <ModalDialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={props.show}
                aria-labelledby="field-model"
            >
                <DialogTitle id="field-model-title">
                    <strong>
                        <div>
                            {parokopenclosedta === "Open" ? "Open" : "Close"} all fields,
                            gates and {parkrealname}
                        </div>
                    </strong>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div class="plainGrid fc-width50">
                            <div class="plainGridHeader">
                                <div class="plainGridRow">
                                    <div class="plainGridHead">Field Name</div>
                                    <div class="plainGridHead">Current Status</div>
                                </div>
                            </div>
                            <div class="plainGridBody">
                                {getParkNameStatus === true ? (
                                    <div class="plainGridRow">
                                        <div class="plainGridCell">{parkrealname}</div>
                                        <div class="plainGridCell">Open</div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {getParkNameStatus === false ? (
                                    <div class="plainGridRow">
                                        <div class="plainGridCell">{parkrealname}</div>
                                        <div class="plainGridCell">Close</div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {props.pname.map((m) => (
                                    <div class="plainGridRow">
                                        <div class="plainGridCell">{m.fieldname}</div>
                                        <div class="plainGridCell">{m.fieldstatus}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <br />
                        <p style={{ fontSize: "0.9rem" }}>
                            Please type "YES" below to confirm the selected fields
                        </p>
                        <br />
                        <TextField
                            placeholder="Type YES for confirmation"
                            variant="outlined"
                            size="small"
                            color="primary"
                            value={yes}
                            onChange={(e) => setYes(e.target.value)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonSm
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {parokopenclosedta === "Open" ? "Open" : "Close"} All
                    </ButtonSm>
                    <ButtonSm
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={props.closePopup}
                    >
                        Cancel
                    </ButtonSm>
                </DialogActions>
            </ModalDialog>
        </div>
    );
}
