import React, { useEffect, useState } from "react";
import { HiChevronDoubleLeft } from "react-icons/hi";
import { HiChevronDoubleRight } from "react-icons/hi";
import { HiChevronRight } from "react-icons/hi";
import { HiChevronLeft } from "react-icons/hi";

export default function Tablepaginate(props) {
    let totalpage = props.totalpage;
    const [currentpage, setCurrentPage] = useState(props.currentPagevalue);
    const [getvalue, setvalue] = useState(false);
    const [perPage, setPerPage] = useState(props.totalrowperpage);

    useEffect(() => {
        setCurrentPage(props.currentPagevalue);
    }, [props.currentPagevalue]);
    var pageNumbers = 1;
    const rowvalue = (e) => {
        setPerPage(parseInt(e.target.value));
        props.tablerowvalue(e.target.value);
    };
    function pageincrement() {
        setvalue(false);
        setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
        setvalue(true);
    }
    function pagedecrement() {
        setvalue(false);
        setCurrentPage((nextCurrentPage) => nextCurrentPage - 1);
        setvalue(true);
    }
    function firstpagedecrement() {
        setvalue(false);
        setCurrentPage(pageNumbers);
        setvalue(true);
    }
    function lastpageincrement() {
        setvalue(false);
        setCurrentPage(totalpage);
        setvalue(true);
    }

    useEffect(() => {
        if (getvalue === true) {
            props.rulecurrentpage(currentpage);
        }
    }, [getvalue, currentpage]);

    return (
        <div className="row align-items-center mobiletablepagination">
            <div
                className={`col-12 ${props.pageleft == "pageleft" ? "col-md-6" : ""
                    } col-lg-6`}
            >
                <div
                    className={`rowperpage ${props.pageleft == "pageleft" ? "pageleft" : ""
                        }`}
                >
                    <div className="mr-2">Maximum Records Per Page</div>
                    <select
                        className="form-select selectpagerange"
                        aria-label="Default select example"
                        onChange={rowvalue}
                        value={perPage}
                    >
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={45}>45</option>
                        <option value={60}>60</option>
                        <option value={100}>100</option>
                    </select>
                </div>
            </div>
            <div
                className={`col-12 ${props.pageleft == "pageleft" ? "col-md-6" : ""
                    } col-lg-6`}
            >
                <div className="paginationright">
                    <div className="mr-5 totalrule">
                        Total Rules: {props.totalpagenum}
                    </div>
                    <div>
                        <nav aria-label="...">
                            <ul className="pagination justify-content-end mb-0">
                                <li
                                    className={`page-item ${currentpage <= 1 ? "disabled" : "activecolor"
                                        }`}
                                >
                                    <a className="page-link" onClick={firstpagedecrement}>
                                        <HiChevronDoubleLeft />
                                    </a>
                                </li>
                                <li
                                    className={`page-item ${currentpage <= 1 ? "disabled" : "activecolor"
                                        }`}
                                >
                                    <a className="page-link" onClick={pagedecrement}>
                                        <HiChevronLeft />
                                    </a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="#">
                                        {currentpage <= 9 && totalpage < 10 ? (
                                            <span className="paginum">
                                                {" "}
                                                {"0" + currentpage}/{"0" + totalpage}
                                            </span>
                                        ) : currentpage <= 9 ? (
                                            <span className="paginum">
                                                {" "}
                                                {"0" + currentpage}/{totalpage}
                                            </span>
                                        ) : (
                                            <span className="paginum">
                                                {currentpage}/{totalpage}
                                            </span>
                                        )}
                                    </a>
                                </li>
                                <li
                                    className={`page-item ${currentpage >= totalpage ? "disabled" : "activecolor"
                                        }`}
                                >
                                    <a className="page-link" onClick={pageincrement}>
                                        <HiChevronRight />
                                    </a>
                                </li>
                                <li
                                    className={`page-item ${currentpage >= totalpage ? "disabled" : "activecolor"
                                        }`}
                                >
                                    <a className="page-link" onClick={lastpageincrement}>
                                        <HiChevronDoubleRight />
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}
