import React from "react";

import "../styles/Weather.css";

export default function Current({ weatherInfo, location, date }) {
  return (
    <div className="Weather">
      <h2 className="other-info__city">
        {location.city || location.town},{" "}
        {location.state || location.country.toUpperCase()}
      </h2>
      <div className="Weather__info">
        <div>
          <img
            className="Weather__icon"
            src={
              "https://openweathermap.org/img/wn/" +
              weatherInfo.current.weather[0].icon +
              ".png"
            }
            alt={weatherInfo.current.weather[0].main}
          />
          <h3 className="other-info__clouds">
          <span className="today_style">{date} </span>, <br /> {weatherInfo.current.weather[0].description}
          </h3>
        </div>
        <ul className="Weather__list">
          <li className="list__temperature">
            {Math.round(weatherInfo.current.temp)}
            <sup className="list__temperature-symbol">°F</sup>
          </li>
          <li> Humidity: {weatherInfo.current.humidity}%</li>
          <li>
            Wind: {Math.round(weatherInfo.current.wind_speed)} mph{" "}
          </li>
        </ul>
      </div>
    </div>
  );
}
