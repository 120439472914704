import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardBackspace, ExpandMore } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Accordion from "@material-ui/core/Accordion";
import { States,Countries } from "../../config";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import _ from "lodash";
import InputLabel from "@material-ui/core/InputLabel";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputMask from "react-input-mask";

import { useSelector, useDispatch } from "react-redux";
import {
  sensors_,
  sensorsHasLoaded_,
  sensorServiceThunk,
} from "../../reducers/sensors";

const useStyles = makeStyles(styles);

const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "red",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white,
    },
  },
}));
export default function EditItem() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const helperTestClasses = helperTextStyles();
  const loginDetails = useSelector(login_);
  const ParkID = _.get(loginDetails, ["parkID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [assetNumber, setAssetNumber] = useState("");
  const [sensorname, setsensorname] = React.useState("");
  const [AzureDeviceID, SetAzureDeviceID] = React.useState("");
  const [AzureDeviceConnectionString, SetAzureDeviceConnectionString] =
    React.useState("");
  const [AzureHubID, setAzureHubID] = React.useState("");
  const [AzureHubConnectionString, SetAzureHubConnectionString] =
    React.useState("");
  const [LocationCoordinates, SetLocationCoordinates] = React.useState({});

  const [latitude, setlatitude] = React.useState("");
  const [longitude, setlongitude] = React.useState("");
  const [typeOfSensor, settypeOfSensor] = React.useState("1");
  const [BatteryLastUpdated, setBatteryLastUpdated] = React.useState("");
  const [sensorcollectionID, setsensorcollectionID] = React.useState("");
  const [error, seterror] = React.useState({});

  const [options, setOptions] = React.useState([]);
  const [installationDate, setInstallationDate] = React.useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [ManufacturerName, SetManufacturerName] = React.useState("");
  const [ManufacturerID, SetManufacturerID] = React.useState("");
  const [ManufacturerDate, setManufacturerDate] = React.useState("");
  const [ModelNo, SetModelNo] = React.useState("");
  const [deviceType, setDeviceType] = useState("");
  const [erremailaddress, seterremailaddress] = React.useState("");
  const [errphonenumber, seterrphonenumber] = React.useState("");
  const [errvendoremailaddress, seterrvendoremailaddress] = React.useState("");
  const [errvendorphonenumber, seterrvendorphonenumber] = React.useState("");
  const [deviceName, setDeviceName] = useState("");
  const [powerInput, setPowerInput] = useState("");
  const [powerOutput, setPowerOutput] = useState("");
  const [yearofMake, setYearofMake] = useState("");
  const [comments, setComments] = useState("");
  const [webSite, setWebSite] = useState("");
  const [emailID, setEmailID] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [deviceLocation, setDeviceLocation] = useState("");
  const [deviceOptions,setDeviceOptions]=useState([]);
  const userid = _.get(loginDetails, ["UserID"], "");
  const firstName = _.get(loginDetails, ["FirstName"], "");
  const [assetNumberErrorText, setAssetNumberErrorText] = useState("");
  const [validAssetNumber, setValidAssetNumber] = useState(false);
  const [VendorName, setVendorName] = useState("");
  const [VendorEmail, setVendorEmail] = useState("");
  const [VendorPhoneNumber, setVendorPhoneNumber] = useState("");
  const [VendorWebSite, setVendorWebSite] = useState("");
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const assetID = urlParams.get("id");
  React.useEffect(() => {
    var url = `api/park/${ParkID}/asset?assetID=${assetID}`;
    smartParkService
      .get(url, {
        headers: {
          userid: userid,
        },
      })
      .then(async (res, err) => {
        if (res.data.status == 200) {
          var {
            DeviceName,
            DeviceType,
            DeviceLocation,
            ManufacturerID,
            ManufacturerDate,
            Address1,
            Address2,
            City,
            State,
            Zip,
            Country,
            Comments,
            ManufacturerName,
            PhoneNumber,
            Email,
            WebSite,
            ModelNo,
            SerialNumber,
            YearOfMake,
            PowerOutput,
            PowerInput,
            InstallationDate,
            AssetNumber,
            Longitude,
            Latitude,
            VendorName,VendorEmail,VendorPhoneNumber,VendorWebSite
          } = res.data.data;
            console.log(VendorWebSite)
          setAssetNumber(AssetNumber);
          setDeviceName(DeviceName);
          setDeviceType(DeviceType);
          setDeviceLocation(DeviceLocation);
          setManufacturerDate(ManufacturerDate);
          SetManufacturerID(ManufacturerID);
          SetManufacturerName(ManufacturerName);
          setCountry(Country);
          setState(State);
          setZip(Zip);
          setCity(City);
          setSerialNumber(SerialNumber);
          setPhoneNumber(PhoneNumber);
          setAddress1(Address1);
          setAddress2(Address2);
          setComments(Comments);
          setEmailID(Email);
          setWebSite(WebSite);
          SetModelNo(ModelNo);
          setYearofMake(YearOfMake);
          setPowerInput(PowerInput);
          setPowerOutput(PowerOutput);
          setInstallationDate(InstallationDate);
          setlatitude(Latitude);
          setlongitude(Longitude);
          setVendorName(VendorName);
          setVendorEmail(VendorEmail);
          setVendorPhoneNumber(VendorPhoneNumber);
          setVendorWebSite(VendorWebSite);
        }
      });
  }, []);

  React.useEffect(() => {
    var url = "api/park/" + ParkID + "/manufacturers";
    smartParkService.get(url).then((res) => {
      if (res) {
        setOptions(res.data.data);
      } else {
      }
    });
  }, []);
  React.useEffect(() => {
    var url = "api/park/" + ParkID + "/alldevices";
    smartParkService.get(url).then((res) => {
      if (res) {
        setDeviceOptions(res.data.data);
      } else {
      }
    });
  }, []);
  const checkAssetItemNumber = (assetItemNumber) => {
    if (assetItemNumber) {
      var url = "api/park/" + ParkID + "/asset/exists?assetNumber=" + (assetNumber).toUpperCase();
      smartParkService
        .head(url)
        .then(res => {
          if (res && (res.status === 200 || res.status === 304))
            setValidAssetNumber(false);
          else
            setValidAssetNumber(true);
        });
    }
  };

  const Save = () => {
    if (validation()) {
    var dataObj = {};
    dataObj.AssetNumber = assetNumber?assetNumber.toUpperCase():"";
    dataObj.ManufacturerID = ManufacturerID;
    dataObj.ManufacturerName = ManufacturerName;
    dataObj.ManufacturerDate = ManufacturerDate;
    dataObj.Address1 = address1;
    dataObj.Address2 = address2;
    dataObj.City = city;
    dataObj.State = state;
    dataObj.Zip = zip;
    dataObj.Country = country;
    dataObj.PhoneNumber = phoneNumber;
    dataObj.Email = emailID;
    dataObj.WebSite = webSite;
    dataObj.DeviceType = deviceType;
    dataObj.DeviceName = deviceName;
    dataObj.ModelNo = ModelNo;
    dataObj.SerialNumber = serialNumber;
    dataObj.YearOfMake = yearofMake;
    dataObj.PowerInput = powerInput;
    dataObj.PowerOutput = powerOutput;
    dataObj.InstallationDate = installationDate;
    dataObj.DeviceLocation = deviceLocation;
    dataObj.Comments = comments;
    dataObj.InsertedBy = firstName;
    dataObj.ParkID = ParkID;
    dataObj.Latitude = latitude;
    dataObj.Longitude = longitude;
    dataObj.VendorName=VendorName;
    dataObj.VendorEmail=VendorEmail;
    dataObj.VendorPhoneNumber=VendorPhoneNumber;
    dataObj.VendorWebSite=VendorWebSite;
    // dataobj.InsertedUserID = userid;
    var url = "api/park/" + ParkID + "/updateasset?assetID="+assetID;
    smartParkService
      .post(url, dataObj, {
        headers: {
          userid: userid,
        },
      })
      .then((res) => {
        if (res.data.status == 200) {
          history.push("/AssetManagement");
        }
        else if (res.status === 401 || res.status === 403) {
          alert("You do not have permission to update asset details"); 
        }
      })
      .catch(() => {
        alert("Error occurred while updating asset details");  
      });
    }
  };
  const validation = () => {
    var requiredFields = true;
    if (!assetNumber) {
      setAssetNumberErrorText("Please Enter A Valid Asset Item Number");
      requiredFields = false;
    }

    if (emailID !== "") {
      var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!regexEmail.test(emailID)) {
        seterremailaddress("Invalid email address");
        requiredFields = false;
      }
    }
    if (
      phoneNumber !== "" &&
      phoneNumber.replaceAll("-", "").trim().length !== 10
    ) {
      seterrphonenumber("Please Enter 10 Digit PhoneNumber");
      requiredFields = false;
    }
    if (
      VendorPhoneNumber !== "" &&
      VendorPhoneNumber.replaceAll("-", "").trim().length !== 10
    ) {
      seterrvendorphonenumber("Please Enter 10 Digit PhoneNumber");
      requiredFields = false;
    }
    if (VendorEmail== "") {
      var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!regexEmail.test(VendorEmail)) {
        seterrvendoremailaddress("Invalid email address");
        requiredFields = false;
      }
    }
    return requiredFields;
  };

  const setDefaultManufacturerDetails = (value) => {
    if (value) {
      let {
        ManufacturerID,
        ManufacturerDate,
        ManufacturerName,
        Address1,
        Address2,
        City,
        State,
        Zip,
        Country,
        Comments,
        PhoneNumber,
        Email,
        WebSite,
        AssetNumber
      } = value;
      setAssetNumber(AssetNumber);
      setManufacturerDate(ManufacturerDate);
      SetManufacturerID(ManufacturerID);
      SetManufacturerName(ManufacturerName);
      setCountry(Country);
      setState(State);
      setZip(Zip);
      setCity(City)
      setPhoneNumber(PhoneNumber);
      setAddress1(Address1);
      setAddress2(Address2);
      setComments(Comments);
      setEmailID(Email);
      setWebSite(WebSite);
    }
  };
  const setDefaultDeviceDetails = (value) => {
    if (value) {
      var selectedOptions=deviceOptions.filter((option)=>{
        return option.DeviceName===value
      })[0]
      let {
        DeviceType,
        Model,
        YearofMake,
        PowerOutput,
        PowerInput,
        DeviceName,
      } = selectedOptions;
      setDeviceType(DeviceType);
      SetModelNo(Model);
      setDeviceName(DeviceName);
      setYearofMake(YearofMake);
      setPowerInput(PowerInput);
      setPowerOutput(PowerOutput);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Asset Management</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                Asset Management
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <div class="content-middle">
            <div class="content-sub-header">
              <div>
                <Link
                  component="button"
                  onClick={() => history.push("/AssetManagement")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
              <div>
                <h3>Edit Asset Item</h3>
              </div>
              <div class="display-xs-0">
                <Link
                  style={{ visibility: "hidden" }}
                  component="button"
                  onClick={() => history.push("/AssetManagement")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
            </div>
            <Paper className={classes.paper + " " + classes.withStripe}>
              <div class="form-body">
                <Grid container spacing={3} className={classes.pad20}>
                  <Grid item xs={12} sm={12}>
                    <Select
                      fullWidth
                      value="Cedar Fork District Park"
                      label="Facility Name"
                    >
                      <MenuItem value="Cedar Fork District Park">
                        Cedar Fork District Park
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={assetNumber}
                      fullWidth
                      label="Asset Number"
                      onChange={(e) => {
                        setAssetNumber(e.target.value);
                      }}
                      onBlur={(e) => {checkAssetItemNumber(e.target.value)}}
                      fullWidth
                    ></TextField>
                    <div style={{ color: "red" }}>{assetNumberErrorText}</div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        Device Details
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} className={classes.pad20}>
                        <Grid item xs={12} sm={6}>
                        <TextField
                              variant="outlined"
                              size="small"
                              value={deviceName}
                              fullWidth
                              label="Device Name"
                              onChange={(e) => {
                                setDeviceName(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl className={classes.w100p} variant="outlined">
                              <Select
                                size="small"
                                value={deviceType||"1"}
                                onChange={(e) => {
                                  setDeviceType(e.target.value);
                                }}
                                label={"Type of Device"}
                                className={classes.selectEmpty}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="1">Type Of Device</MenuItem>
                                <MenuItem value="Moisture">Moisture</MenuItem>
                                <MenuItem value="Flood">Flood</MenuItem>
                                <MenuItem value="Gate">Gate</MenuItem>
                              </Select>
                              <FormHelperText style={{ color: "red", fontSize: "15px" }}>
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={ModelNo}
                              fullWidth
                              label="Model Number"
                              onChange={(e) => {
                                SetModelNo(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={serialNumber}
                              fullWidth
                              label="Serial Number"
                              onChange={(e) => {
                                setSerialNumber(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={yearofMake}
                              fullWidth
                              label="Year of Make"
                              onChange={(e) => {
                                setYearofMake(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={powerInput}
                              fullWidth
                              label="Power Input"
                              onChange={(e) => {
                                setPowerInput(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={powerOutput}
                              fullWidth
                              label="Power Output"
                              onChange={(e) => {
                                setPowerOutput(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        Manufacturer Details
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} className={classes.pad20}>
                          <Grid item xs={12} sm={12}>
                            <div class="d-flex alignCenter">
                              <Autocomplete
                                size="small"
                                id="combo-box-demo"
                                  options={options.map(
                                    (option) => option.ManufacturerName
                                  )}
                                  // getOptionLabel={(option) =>
                                  //   option.ManufacturerName
                                  // }
                                  onChange={(event, value) =>{
                                    if(value){
                                    SetManufacturerName(value+"")
                                    setDefaultManufacturerDetails(value)
                                    }
                                  }
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Manufacturer Name"
                                      variant="outlined"
                                    />
                                  )}
                                  value={ManufacturerName+""}
                                />
                            
                              &nbsp;
                            </div>
                          </Grid>{" "}
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              label="Manufacturer Date"
                              value={ManufacturerDate}
                              fullWidth
                              onChange={(e) => {
                                setManufacturerDate(e.target.value);
                              }}
                            ></TextField>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={address1}
                              fullWidth
                              label="Address 1"
                              onChange={(e) => {
                                setAddress1(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={address2}
                              fullWidth
                              label="Address 2"
                              onChange={(e) => {
                                setAddress2(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={city}
                              fullWidth
                              label="City"
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                          <div class="d-flex alignCenter">     
                                <Autocomplete
                                  size="small"
                                  id="combo-box-demo"
                                  options={States.map(
                                    (option) => option.StateName
                                  )}
                                  onChange={(event, value) =>{
                                    if(value){
                                    setState(value+"")
                                    }
                                  }
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="State"
                                      variant="outlined"
                                      onChange={(e)=>{setState(e.target.value)}}
                                    />
                                  )}
                                  value={state+""}
                                />
                            
                              &nbsp;
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={zip}
                              fullWidth
                              label="Zip Code"
                              onChange={(e) => {
                                setZip(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                          <div class="d-flex alignCenter">     
                                <Autocomplete
                                  size="small"
                                  id="combo-box-demo"
                                  options={Countries}
                                  onChange={(event, value) =>{
                                    if(value){
                                    setCountry(value+"")
                                    }
                                  }
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country"
                                      variant="outlined"
                                    />
                                  )}
                                  value={country+""}
                                />
                            
                              &nbsp;
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputMask
                              mask="999-999-9999"
                              fullWidth
                              value={phoneNumber}
                              onChange={(e) => {
                                setPhoneNumber(e.target.value);
                              }}
                              maskChar=""
                            >
                              {() => (
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  required
                                  fullWidth
                                  id="username"
                                  name="username"
                                  helperText={errphonenumber}
                                  FormHelperTextProps={{
                                    classes: helperTestClasses,
                                  }}
                                  label="Phone"
                                  onClick={() => {
                                    seterrphonenumber("");
                                  }}
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={emailID}
                              fullWidth
                              label="Email"
                              helperText={erremailaddress}
                              FormHelperTextProps={{
                                classes: helperTestClasses,
                              }}
                              onChange={(e) => {
                                setEmailID(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={webSite}
                              fullWidth
                              label="Web Site"
                              onChange={(e) => {
                                setWebSite(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                        </Grid>
                        <br />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                      Vendor Details
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} className={classes.pad20}>
                          <Grid item xs={12} sm={6}>
                            <div class="d-flex alignCenter">
                              <TextField
                                variant="outlined"
                                size="small"
                                value={VendorName}
                                fullWidth
                                label="Vendor name"
                                onChange={(e) => {
                                  setVendorName(e.target.value);
                                }}
                                fullWidth
                              ></TextField>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div class="d-flex alignCenter">
                              <TextField
                                variant="outlined"
                                size="small"
                                value={VendorEmail}
                                fullWidth
                                label="Email"
                                helperText={errvendoremailaddress}
                                FormHelperTextProps={{
                                  classes: helperTestClasses,
                                }}
                                onChange={(e) => {
                                  setVendorEmail(e.target.value);
                                }}
                                fullWidth
                              ></TextField>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                          <InputMask
                              mask="999-999-9999"
                              fullWidth
                              value={VendorPhoneNumber}
                              onChange={(e) => {
                                setVendorPhoneNumber(e.target.value);
                              }}
                              maskChar=""
                            >
                              {() => (
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  id="username"
                                  name="username"
                                  helperText={errvendorphonenumber}
                                  FormHelperTextProps={{
                                    classes: helperTestClasses,
                                  }}
                                  label="Phone"
                                  onClick={() => {
                                    seterrphonenumber("");
                                  }}
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={VendorWebSite}
                              fullWidth
                              label="Web Site"
                              onChange={(e) => {
                                setVendorWebSite(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        Installation and Other Details
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} className={classes.pad20}>
                          <Grid item xs={12} sm={6}>
                            <div class="d-flex alignCenter">
                              <TextField
                                variant="outlined"
                                size="small"
                                value={installationDate}
                                fullWidth
                                label="Installation Date"
                                onChange={(e) => {
                                  setInstallationDate(e.target.value);
                                }}
                                fullWidth
                              ></TextField>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div class="d-flex alignCenter">
                              <TextField
                                variant="outlined"
                                size="small"
                                value={deviceLocation}
                                fullWidth
                                label="Location of Installed Device"
                                onChange={(e) => {
                                  setDeviceLocation(e.target.value);
                                }}
                                fullWidth
                              ></TextField>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div class="d-flex alignCenter">
                              <TextField
                                variant="outlined"
                                size="small"
                                value={latitude}
                                fullWidth
                                label="Latitude"
                                onChange={(e) => {
                                  setlatitude(e.target.value);
                                }}
                                fullWidth
                              ></TextField>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div class="d-flex alignCenter">
                              <TextField
                                variant="outlined"
                                size="small"
                                value={longitude}
                                fullWidth
                                label="Longitude"
                                onChange={(e) => {
                                  setlongitude(e.target.value);
                                }}
                                fullWidth
                              ></TextField>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <div class="d-flex alignCenter">
                              <TextField
                                multiline={true}
                                variant="outlined"
                                size="small"
                                value={comments}
                                fullWidth
                                label="Comments"
                                onChange={(e) => {
                                  setComments(e.target.value);
                                }}
                                fullWidth
                              ></TextField>
                            </div>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </div>
              <div class="form-footer">
                <Button
                  size="sm"
                  color="primary"
                  variant="contained"
                  className={classes.btnRound}
                  onClick={Save}
                >
                  SAVE
                </Button>
                &nbsp;&nbsp;
                <Button
                  size="sm"
                  variant="outlined"
                  className={classes.btnRound}
                  onClick={() => {
                    history.push("/AssetManagement");
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </Paper>
          </div>
        </section>
      </div>
    </div>
  );
}
