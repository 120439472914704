import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../assets/scss/App.scss";
import styles from "../components/components.js";
import Footer from "./../components/Footer";
import {
  AppBar,
  Toolbar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import smartParkService from "../axios";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import TennisFieldStatus from "../../src/views/UserDashboard/courtsstatus";
import Checkbox from "@material-ui/core/Checkbox";
import Logo from "../assets/img/Logo.png";
import Court1_Occupied from "../assets/img/TennisCourt/Desktop/Court1_Occupied.png";
import Court2_Available from "../assets/img/TennisCourt/Desktop/Court2_Available.png";
import Court1_Available from "../assets/img/TennisCourt/Desktop/Court1_Available.png";
import Court2_Occupied from "../assets/img/TennisCourt/Desktop/Court2_Occupied.png";
import Court3_Occupied from "../assets/img/TennisCourt/Desktop/Court3_Occupied.png";
import Mob_Court1_Occupied from "../assets/img/TennisCourt/Mobile/Court1_Occupied.png";
import Mob_Court1_Available from "../assets/img/TennisCourt/Mobile/Court1_Available.png";
import Mob_Court2_Available from "../assets/img/TennisCourt/Mobile/Court2_Available.png";
import Mob_Court2_Occupied from "../assets/img/TennisCourt/Mobile/Court2_Occupied.png";
import Mob_Court3_Occupied from "../assets/img/TennisCourt/Mobile/Court3_Occupied.png";

const useStyles = makeStyles(styles);

export default function CourtFieldStatus() {
  const classes = useStyles();
  const [courts, setcourts] = React.useState([]);
  function padValue(value) {
    return value < 10 ? "0" + value : value;
  }
  function formatDate(dateVal) {
    if (!dateVal) return "";

    var d = new Date(dateVal);
    var sMonth = padValue(d.getMonth() + 1);
    var sDay = padValue(d.getDate());
    var sYear = d.getFullYear();

    var hour =
      d.getHours() == 0
        ? 12
        : d.getHours() > 12
        ? d.getHours() - 12
        : d.getHours();
    var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
    var ampm = d.getHours() < 12 ? "AM" : "PM";
    var time = padValue(hour) + ":" + min + " " + ampm;

    return sMonth + "/" + sDay + "/" + sYear + " " + time;
  }

  const getFields = () => {
    var url = "api/courts";
    smartParkService
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.length > 0) {
            setcourts(res.data.data);
          }
        }
      })
      .catch((error) => {});
  };
  React.useEffect(() => {
    smartParkService.post("api/courts").then((res, err) => {
      if (res.status === 200) getFields();
    });
  }, []);

  const preparenewobject = (e, courtname) => {
    console.log(e.target.checked);
    for (var i = 0; i < courts.length; i++) {
      if (courts[i].court_name == courtname) {
        if (courts[i].value == 0) courts[i].value = 1;
        else courts[i].value = 0;
      }
    }
    console.log(courts);
    setcourts([...courts]);
  };
  // React.useEffect(() => {
  //   console.log(courts);
  // }, [courts]);

  const getsrctenniscourts = (m) => {
    var src;
    switch (m.court_name) {
      case "Court 1":
        src = m.value == 0 ? Court1_Available : Court1_Occupied;
        break;
      case "Court 2":
        src = m.value == 0 ? Court2_Available : Court2_Occupied;
        break;
    }
    return src;
  };
  const getsrctenniscourtsmobile = (m) => {
    var src;
    switch (m.court_name) {
      case "Court 1":
        src = m.value == 0 ? Mob_Court1_Available : Mob_Court1_Occupied;
        break;
      case "Court 2":
        src = m.value == 0 ? Mob_Court2_Available : Mob_Court2_Occupied;
        break;
    }
    return src;
  };

  return (
    <div>
      {/* <AppBar position="static" style={{ background: "none" }}>
        <Toolbar>
          <Box display="flex" alignItems="center" justifyContent="space-evenly">
            <div class="logoBlock">
              <img
                src={Logo}
                alt="Logo"
                className={classes.logo}
                style={{ padding: 2 }}
              />
            </div>
            <h3 class="title">Morrisville Smart City Dashaboard</h3>
            <input type="text" class="search display-xs-0" />
          </Box>
        </Toolbar>
      </AppBar> */}
      <div class="contentWrapper">
        <Grid container>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12}>
            <h3 class="subtitle">
              Morrisville Tennis Courts{" "}
              <small class="gray">
                25800 Cricket Pitch Way Morrisville, NC 27560
              </small>
            </h3>
          </Grid>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Grid container className={classes.sm_hidden}>
            <Grid item xs={12} md={3}>
              <h5 class="dbtitle">Current Court Status</h5>
              <List style={{ width: 150 }}>
                <ListItem>
                  <ListItemText primary="Available" />
                  <ListItemIcon>
                    <FiberManualRecordIcon className={classes.iconopen} />
                  </ListItemIcon>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Occupied" />
                  <ListItemIcon>
                    <FiberManualRecordIcon className={classes.iconError} />
                  </ListItemIcon>
                </ListItem>
              </List>
            </Grid>
            {courts || courts.length > 0
              ? courts.map((m) => (
                  <Grid item xs={12} md={3} className={classes.textCenter}>
                    <Box>
                      <img
                        src={getsrctenniscourts(m)}
                        alt={m.court_name}
                        width="200"
                        height="374"
                      />
                    </Box>
                  </Grid>
                ))
              : ""}
            {/* <Grid item xs={12} md={3}>
              <Box>
                <img
                  src={Court1_Occupied}
                  alt="Court1_Occupied"
                  width="200"
                  height="374"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box>
                <img
                  src={Court2_Available}
                  alt="Court2_Available"
                  width="200"
                  height="374"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box>
                <img
                  src={Court3_Occupied}
                  alt="Court3_Occupied"
                  width="200"
                  height="374"
                />
              </Box>
            </Grid> */}
          </Grid>
          <Grid container className={classes.sm_visible}>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.w100p}
              >
                <h6 class="subtitle1 blue1">
                  Current Court Status &nbsp; &nbsp;
                </h6>
                <h6 class="subtitle1">
                  Available{" "}
                  <FiberManualRecordIcon className={classes.iconopen} /> &nbsp;
                  &nbsp;
                </h6>
                <h6 class="subtitle1">
                  {" "}
                  Occupied{" "}
                  <FiberManualRecordIcon className={classes.iconError} />
                </h6>
              </Box>
            </Grid>
            <Grid container spacing={2} className={classes.textCenter}>
              {courts || courts.length > 0
                ? courts.map((m) => (
                    <Grid item xs={12} md={3}>
                      <Box>
                        <img
                          src={getsrctenniscourtsmobile(m)}
                          alt={m.court_name}
                          width="374"
                          height="200"
                        />
                      </Box>
                    </Grid>
                  ))
                : ""}
              {/* <Grid item xs={12} className={classes.textCenter}>
                <Box>
                  <img
                    src={Mob_Court1_Occupied}
                    alt="Court1_Occupied"
                    width="300"
                    height="160"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.textCenter}>
                <Box>
                  <img
                    src={Mob_Court2_Available}
                    alt="Court2_Available"
                    width="300"
                    height="160"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.textCenter}>
                <Box>
                  <img
                    src={Mob_Court3_Occupied}
                    alt="Court3_Occupied"
                    width="300"
                    height="160"
                  />
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          className={classes.textCenter}
          style={{ margin: "auto" }}
        >
          <div class="box-body">
            <div class="divGrid">
              <div class="divGridHeading">
                <div class="divGridRow">
                  <div class="divGridHead">Court</div>
                  <div class="divGridHead">Status</div>
                  {/* <div class="divGridHead">Last Reported DateTime</div> */}
                </div>
              </div>
              <div class="divGridBody">
                {courts || courts.length > 0
                  ? courts.map((m) => (
                      <div class="divGridRow">
                        <div class="divGridCell">
                          <Checkbox
                            color="primary"
                            name={m.court_name}
                            checked={m.isChecked}
                            style={{ padding: "2px" }}
                            key={m.court_name}
                            onChange={(e) => preparenewobject(e, m.court_name)}
                          />
                          {m.court_name}
                        </div>

                        <div class="divGridCell">
                          <div class="d-flex">
                            {m.value == 0 ? "Not Occupied" : "Occupied"}
                          </div>
                        </div>
                        {/* <div class="divGridCell">
                      <div class="d-flex">
                        {formatDate(m.lastReportedDateTime)}
                      </div>
                    </div> */}
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </Grid>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
