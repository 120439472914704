import React, { useCallback, useEffect, useState, useRef } from "react";
import { myArray, myfunction_v2 } from "../../Charts/GolfCourseChart";

import { makeStyles } from "@material-ui/core/styles";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Typography,
  ButtonGroup,
  MenuItem,
  Menu,
  ListItemIcon,
  Badge,
} from "@material-ui/core";
import {
  DateRange,
  ExpandMore,
  ArrowBackIos,
  HomeOutlined,
  NotificationsOutlined,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import {
  selectedFieldDetails_,
  selectedFieldCourts_,
  getFirstCourt_,
} from "../../../reducers/userDashboard";
import GolfCourseCard from "./../../../components/GolfCourseCard";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import GolfCourseChart from "../../Charts/GolfCourseChart";

import MultiSelect from "react-multi-select-component";

import smartParkService from "../../../axios";
import './widgetmoredetails.css';
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  AppTitle: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  appBar: {
    background: "#fff",
    padding: theme.spacing(1),
  },
  section1: {
    background: "#f8f8f8",
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  mainContent: {
    marginTop: 90,
  },
  pageHeader: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  refreshButton: {
    background: "#fff !important",
    fontWeight: 600,
  },
  customButton: {
    textTransform: "capitalize",
    fontSize: "0.95em",
    fontWeight: 500,
    boxShadow: "none !important",
  },
  page_title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  flexDirection: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));
const localTimeZoneOffset =
  (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;

const millisecond_in_a_day = 86400000;
export default function GolfMoreDetails(props) {
  const { cardType, setMoreDetails, getresval, courseData } = props;
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [selected, setSelected] = useState([]);
  const [refreshDateString, setRefreshDateString] = useState();
  const [NotifyPopUp, setNotifyPopUp] = useState(false);
  // new code for golf course
  const [CurrentHole, setCurrentHole] = React.useState([]);
  const [HoleDeviceDetails, setHoleDeviceDetails] = React.useState([]);
  const [DeviceDetails, setDeviceDetails] = React.useState([]);
  //const [SingleGolfCouseData, setSingleGolfCouseData] = React.useState([]);

  const getsetHoleDeviceDetails = () => {
    smartParkService.get("api/events/golf-demo/golf/" + props.courseData.golfID + "/holelist").then((res, err) => {

      let newArray = res.data.data.map((item, idx) => {

        item.deviceids = item.deviceids.map((device, i) => {

          let deviceData = {}
          deviceData.id = device;
          deviceData.value = device;
          deviceData.name = (device == '21735') ? 'Dry Spot - Practice Green' : 'Wet Spot - Practice Green';
          deviceData.label = (device == '21735') ? 'Dry Spot' : 'Wet Spot';
          if (i == 0 && idx == 0) {
            setSelected([deviceData])

          }
          return deviceData;
        })
        if (idx == 0) {
          setCurrentHole(item.HoleID);
          setDeviceDetails(item.deviceids)
        }
        return item
      })

      setHoleDeviceDetails(newArray);
    }).catch(() => {
      console.log("Error occurred while fetching citizen dashboard sensordata");
    });
  };
  // OnChange hole details get device details'
  const holeOnChange = (val) => {
    let currentHole = HoleDeviceDetails.filter((data) => {
      return data.HoleID == val
    })
    if (currentHole.length > 0) {
      setDeviceDetails(currentHole[0].deviceids)
      setSelected([currentHole[0].deviceids[0]])
      setCurrentHole(val);
    }
  }


  const [state, setState] = useState({
    start: moment().subtract(30, "days"),
    end: moment(),
  });
  const { start, end } = state;


  useEffect(() => {
    getsetHoleDeviceDetails();
  }, []);



  // console.log(getmcpcourtsdata);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => setNotifyPopUp(true)}>
        <ListItemIcon>&nbsp;</ListItemIcon>
        Notify Me
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 5 new notifications" color="inherit">
          <Badge badgeContent={5} color="error">
            <NotificationsOutlined />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
    </Menu>
  );

  const handleCallback = (start, end, rangeType) => {
    if (
      new Date(end._d).getTime() - new Date(start._d).getTime() <=
      millisecond_in_a_day
    ) {
      var endDate = new Date().getTime();
      end = moment(endDate);
      start = moment(endDate - millisecond_in_a_day);
    }
    setState({ start, end });

    // export data for cfdp 

    var Sdate = new Date(start._d).getTime();
    var Edate = new Date(end._d).getTime();





    // export data for cfdp 

  };


  const label =
    start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
  const getDateBasedOnRange = (rangeType) => {
    switch (rangeType) {
      case "Last 24 Hours":
        return [moment().subtract(1, "days").toDate(), moment().toDate()];
      case "Last Week":
        return [moment().subtract(6, "days").toDate(), moment().toDate()];
      case "Last Month":
        return [
          moment().subtract(1, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      case "Last 3 Month":
        return [
          moment().subtract(3, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      case "Last 6 Month":
        return [
          moment().subtract(6, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      case "Last Year":
        return [
          moment().subtract(12, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      default:
        return [];
    }
  };

  const refreshDate = useCallback(() => {
    const date = new Date();
    const time = date.toLocaleString("en-US", {
      hour: "numeric",
      hour12: true,
    });
    const month_short = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = month_short[date.getMonth()];
    const year = date.getFullYear();
    const str = `Updated at ${time} on ${month} ${year}`;
    setRefreshDateString(str);
  }, []);
  const refreshHandler = useCallback(() => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
      refreshDate();
    }, 1000);
  }, [refreshDate]);
  useEffect(() => {
    refreshDate();
  }, []);
  const [fieldStatus, setFieldStatus] = React.useState({});
  const updateFieldStatus = (fieldStatusData) => {
    setFieldStatus(JSON.stringify(fieldStatusData));
  };
  const getFields = (f) => {
    // setSelected([f[0]]);
    // setFields(f);
  };


  // Historical grapfh data 
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}${seconds !== '00' ? ':' + seconds : ':00'}`;
    return `${month}-${day}-${year} ${formattedTime}`;
  }
  function downloadCSV(data, fileType) {
    const sensorNames = [...new Set(data.map(sensor => sensor.name))];
    let csvContent = "data:text/csv;charset=utf-8,";
    const headerRow = `DateTime,${sensorNames.join(',')}\n`;
    csvContent += headerRow;
    const maxRows = Math.max(...data.map(sensor => sensor.data.length));
    const maxData = data.find(sensor => sensor.data.length === maxRows);
    for (let i = 0; i < maxRows; i++) {
      const rowData = sensorNames.map(name => {
        const sensorData = data.find(sensor => sensor.name === name);
        return i < sensorData.data.length ? sensorData.data[i][1] : '';
      }).join(',');
      const timestamp = formatDate(maxData.data[i][0]) + "\t";
      const row = `${timestamp},${rowData}\n`;
      csvContent += row;
    }
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    const file_name = fileType + "-data-" + moment().format('MMDDYYYY-HHmmss') + ".csv";
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  // Historical grapfh data 
  //Crabtree Creek Flood Data
  function ccfdCSV(data) {
    const csvRows = [];
    // Add headers
    csvRows.push(['date-time', 'water level']);
    // Add data rows
    data.forEach(([timestamp, value]) => {
      const formattedDate = formatDate(timestamp) + "\t";
      csvRows.push([formattedDate, value]);
    });
    // Convert rows to CSV content
    let csvContent = "data:text/csv;charset=utf-8,"
      + csvRows.map(row => row.join(',')).join('\n');
    // Create a download link and trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    const file_name = "flood-data-" + moment().format('MMDDYYYY-HHmmss') + ".csv";
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  //Crabtree Creek Flood Data
  // Temperature and Precipitation
  function tempandpre(graph) {
    const csvRows = [];
    // Add headers
    const temperatureData = graph.temperature; // Replace with your temperature data array
    const precipitationData = graph.precipitation; // Replace with your precipi
    csvRows.push(['date-time', 'temperatureData', 'precipitationData']);
    for (let i = 0; i < temperatureData.length; i++) {
      const temperatureEntry = temperatureData[i];
      const precipitationEntry = precipitationData[i];
      const datetime = formatDate(temperatureEntry[0]) + "\t";// Convert temperature timestamp to datetime string
      const temperature = temperatureEntry[1];
      const precipitation = precipitationEntry[1];
      csvRows.push([datetime, temperature, precipitation]);
    }
    // Convert rows to CSV content
    let csvContent = "data:text/csv;charset=utf-8," + csvRows.map(row => row.join(',')).join('\n');
    // Create a download link and trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    const file_name = "weather-data-" + moment().format('MMDDYYYY-HHmmss') + ".csv";
    link.setAttribute("download", file_name);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }



  return (
    <>
      <main className={`adminbg ${classes.mainContent}`}>
        <Container fixed className="golfmobilepadd">
          <section className={`adminbg ${classes.section1}`}>
            <Box
              display="flex"
              className={`golfpageheader ${classes.pageHeader}`}
              justifyContent={"space-between"}
              mb={4}
              mt={4}
            >
              <Box display="flex" alignItems={"center"}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => setMoreDetails(false)}
                >
                  <HomeOutlined className="HomeIcon" />
                </IconButton>{" "}
                &nbsp;&nbsp;
                <Typography variant="h4" className={classes.page_title}>
                  {courseData.name}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.flexDirection}
              >
                <ButtonGroup size="small" color="primary" aria-label="">
                  <Button>
                    <DateRangePicker
                      initialSettings={{
                        startDate: start.toDate(),
                        endDate: end.toDate(),
                        maxDate: new Date(),
                        ranges: {
                          "Last 24 Hours": getDateBasedOnRange("Last 24 Hours"),
                          "Last Week": getDateBasedOnRange("Last Week"),
                          "Last Month": getDateBasedOnRange("Last Month"),
                          "Last 3 Month": getDateBasedOnRange("Last 3 Month"),
                          "Last 6 Month": getDateBasedOnRange("Last 6 Month"),
                          "Last Year": getDateBasedOnRange("Last Year"),
                        },
                      }}
                      onCallback={handleCallback}
                    >
                      <div id="reportrange">
                        <DateRange />
                        <span>{label}</span> <ExpandMore />
                      </div>
                    </DateRangePicker>
                  </Button>
                </ButtonGroup>
                <Button
                  color="secondary"
                  className={classes.refreshButton}
                  onClick={refreshHandler}
                >
                  REFRESH DATA
                </Button>
              </Box>
            </Box>

            <Grid container spacing={4} className="allgraph">
              <GolfCourseCard
                cardType={cardType}
                cardStyle="seperate"
                actions
                getFields={getFields}
                refresh={refresh}
                getresval={getresval}
                dispatchEvent={true}
                courseData={courseData}
              />

              <Grid item xs={12}>
                <Card elevation={8} className="cardgraphset">
                  <CardHeader
                    title="Historical Moisture Data"
                    action={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="selectboxcss" >
                        <Select
                          style={{ width: "100px" }}
                          variant="outlined"
                          size="small"
                          labelId="label"
                          value={CurrentHole}
                          onChange={(e) => {
                            holeOnChange(e.target.value);
                          }}
                        >
                          {HoleDeviceDetails &&
                            HoleDeviceDetails.length > 0 &&
                            HoleDeviceDetails.map((obj, idx) => (
                              <MenuItem value={obj.HoleID} key={idx}>
                                {obj.HoleName}
                              </MenuItem>
                            ))}
                        </Select>
                        <MultiSelect
                          className={`${classes.Hgt30} multiselectbox`}
                          options={DeviceDetails}
                          value={selected}
                          onChange={setSelected}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          aria-label="ExportData"
                          onClick={() => downloadCSV(myfunction_v2('moisture'), 'Moisture')}//myArray)}
                        >
                          Export Data
                        </Button>
                      </Box>
                    }
                  ></CardHeader>
                  <CardContent>
                    {courseData.hole_data.length > 0 ? (
                      <GolfCourseChart
                        selectedFileds={selected}
                        selectedDateRange={{ start: start, end: end }}
                        selectionChanged={new Date().getTime()}
                        updateFieldStatus={updateFieldStatus}
                        courseData={courseData}
                        currentHole={CurrentHole}
                        graphTitle="Moisture (%) Practice Green"
                        currentField="moisture"
                        refresh={refresh}
                      />

                    ) : (
                      <div>Loading..</div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={8} className="cardgraphset">
                  <CardHeader
                    title="Historical Temperature Data"
                    action={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="selectboxcss" >

                        <Button
                          color="primary"
                          variant="contained"
                          aria-label="ExportData"
                          onClick={() => downloadCSV(myfunction_v2('temperature'), 'Temperature')}//myArray)}
                        >
                          Export Data
                        </Button>
                      </Box>
                    }
                  ></CardHeader>
                  <CardContent>
                    {courseData.hole_data.length > 0 ? (
                      <GolfCourseChart
                        selectedFileds={selected}
                        selectedDateRange={{ start: start, end: end }}
                        selectionChanged={new Date().getTime()}
                        updateFieldStatus={updateFieldStatus}
                        courseData={courseData}
                        currentHole={CurrentHole}
                        graphTitle="Temperature (&#x2109;) Practice Green"
                        currentField="temperature"
                      />

                    ) : (
                      <div>Loading..</div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={8} className="cardgraphset">
                  <CardHeader
                    title="Historical Salinity Data"
                    action={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="selectboxcss" >

                        <Button
                          color="primary"
                          variant="contained"
                          aria-label="ExportData"
                          onClick={() => downloadCSV(myfunction_v2('salinity'), 'Salinity')}//myArray)}
                        >
                          Export Data
                        </Button>
                      </Box>
                    }
                  ></CardHeader>
                  <CardContent>
                    {courseData.hole_data.length > 0 ? (
                      <GolfCourseChart
                        selectedFileds={selected}
                        selectedDateRange={{ start: start, end: end }}
                        selectionChanged={new Date().getTime()}
                        updateFieldStatus={updateFieldStatus}
                        courseData={courseData}
                        currentHole={CurrentHole}
                        graphTitle="Salinity (dS/m) Practice Green"
                        currentField="salinity"
                      />

                    ) : (
                      <div>Loading..</div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

          </section>
        </Container>
      </main>
    </>
  );
}
