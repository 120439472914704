import * as React from "react";
import _ from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../components/components.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import { Cancel, AddCircle } from "@material-ui/icons";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import { green } from "@material-ui/core/colors";
import InputLabel from "@material-ui/core/InputLabel";
import { useSelector, useDispatch } from "react-redux";
import smartParkService from "../../axios";

import { initialState, sensorsThunk, hasLoaded_ } from "../../reducers/rules";
import { sensors_, sensorsHasLoaded_ } from "../../reducers/sensors";
const useStyles = makeStyles(styles);

export default function SelectAutomaticActions(props) {
  const classes = useStyles();
  //const Fieldrows = useSelector(sensors_);
  const[Fieldrows,setFieldrows]=React.useState([]);
  const [devices, setdevices] = React.useState([]);
  const [fields, setFields] = React.useState(_.cloneDeep(props.rules?.Action));
  const [gotSensors, setGotSensors] = React.useState(false);
  const loginDetails = useSelector(login_);
  const ParkID = _.get(loginDetails, ["parkID"], "");

  const error = props.Errors;
  function handleChangeInput(i, event) {
    const values = _.cloneDeep(fields);
    const { name, value } = event.target;
    values[i][name] = value;
    setFields(values);
    props.updateRuleStateObj("Action", values);
  }

  // React.useEffect(() => {
  //   if (Fieldrows && Fieldrows.length > 0) {
  //     setGotSensors(true);
  //   }
  // }, [Fieldrows]);
  React.useEffect(() => {
    smartParkService
      .get("api/park/" + ParkID + "/fields")
      .then((res, err) => {
        setFieldrows(res.data.data);
      });
  }, []);

  function handleAddInput() {
    const values = _.cloneDeep(fields);
    values.push({
      fieldName: "",
      fieldStatus: "",
    });
    setFields(values);
    props.updateRuleStateObj("Action", values);
  }

  React.useEffect(() => {
    var url = "api/park/" + ParkID + "/device";
    smartParkService.get(url).then((res) => {
      if (res) {
        setdevices(res.data.data);
      } else {
      }
    });
  }, []);
  function handleRemoveInput(i) {
    const values = _.cloneDeep(fields);
    if (values.length > 1) {
      values.splice(i, 1);
    }
    setFields(values);
    props.updateRuleStateObj("Action", values);
  }

  return (
    <React.Fragment>
      {fields.map((field, idx) => {
        return (
          <div key={`${field}-${idx}`}>
            <Box display="flex" alignItems="center" className={classes.flexColMobile + " " + classes.alignItemsStartMobile}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Field Name</InputLabel>
                <Select
                  size="small"
                  name="fieldName"
                  style={{ width: "250px" }}
                  label="Field Name"
                  variant="outlined"
                  value={field.fieldName}
                  onChange={(e) => handleChangeInput(idx, e)}
                  className={classes.w150}
                  displayEmpty
                  inputProps={{ "aria-label": "Field Name" }}
                >
                  {Fieldrows.length > 0
                    ? Fieldrows.map((m) => (
                      <MenuItem value={m.FieldName}>{m.FieldName}</MenuItem>
                    ))
                    : null}
                  {devices.length > 0
                    ? devices.map((m) => (
                      <MenuItem value={m.devicename}>{m.devicename}</MenuItem>
                    ))
                    : null}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  Field Status
                </InputLabel>
                <Select
                  size="small"
                  name="fieldStatus"
                  variant="outlined"
                  value={field.fieldStatus}
                  label="Field Status"
                  onChange={(e) => handleChangeInput(idx, e)}
                  className={classes.w150}
                  displayEmpty
                  inputProps={{ "aria-label": "Field Status" }}
                >
                  <MenuItem value="C">Closed </MenuItem>
                  <MenuItem value="O">Opened</MenuItem>
                </Select>
              </FormControl>
              <Box display="flex" alignItems="center">
                {fields.length > 1 ? (
                  <div>
                    <Cancel
                      className={classes.iconError}
                      onClick={() => handleRemoveInput(idx)}
                    />
                  </div>
                ) : (
                  ""
                )}
                {idx == fields.length - 1 ? (
                  <div>
                    <AddCircle
                      style={{ color: green[500] }}
                      onClick={() => handleAddInput(idx)}
                    />{" "}
                  </div>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </div>
        );
      })}
      <span class="error">
        {error.AutomaticActions}
      </span>
    </React.Fragment>
  );
}
