import React, { useEffect, useState, useRef, Suspense } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { TiArrowBack } from "react-icons/ti";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import MaskedInput from "react-maskedinput";
import { BiSolidCheckCircle } from "react-icons/bi";
import { BiSolidXCircle } from "react-icons/bi";
import { Grid, Paper, ListItemText, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../components/components.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "../../components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import DatePicker from "react-datepicker";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Search } from "@material-ui/icons";
import RichTextEditor from "react-rte";
import smartParkService from "../../axios";
import moment from "moment";
import { HiMiniInformationCircle } from "react-icons/hi2";
import printer from "../../assets/img/printer-icn.png";
import plusiconone from "../../assets/img/plusicontwo.png";
import plusicontwo from "../../assets/img/whiteplus.png";
import { GenerateEncryptedToken } from "../../views/Common";
import { login_ } from "../../reducers/login";
import { ToastContainer, toast } from "react-toastify";
import Link from "@material-ui/core/Link";
import "react-toastify/dist/ReactToastify.css";
import {
    permissions_,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { todosAction } from "../../reducers/reducers.js";
import ClipLoader from "react-spinners/ClipLoader";
import { BsToggleOn } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiDeleteBinFill } from "react-icons/ri";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillEye } from "react-icons/ai";
import { BiSolidEditAlt } from "react-icons/bi";
import Table from "react-bootstrap/Table";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { css } from "@emotion/core";
import { v4 as uuid } from "uuid";
const NewSummary = React.lazy(() => import("./NewSummary.js"));

moment.tz.setDefault("America/New_York");

const useStyles = makeStyles(styles);
const weekdayclass = makeStyles((theme) => ({
    weekdayborder: {
        border: "1px solid #fff",
        borderRadius: "0.25rem",
    },
    samepadd: {
        "& .MuiSelect-select": {
            padding: "12px auto !important",
        },
    },
    outlinedayweek: {
        border: "1px solid #ced4da",
        borderRadius: "5px",
        padding: "4px",
        "&:before": {
            border: "none !important",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "1px",
        },
    },
    listItem: {
        "&.Mui-selected": {
            backgroundColor: "white !important",
            color: "black !important",
            border: "none !important",
        },
    },
    checkbox: {
        "&.Mui-checked": {
            color: "#007bff",
        },
    },

    dayscss: {
        fontSize: "13px",
        marginBottom: "2px",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        [theme.breakpoints.down("sm")]: {
            // Styles for screens smaller than or equal to 'sm' breakpoint
            fontSize: "11px",
            marginBottom: "2px",
            paddingLeft: "0.3rem",
            paddingRight: "0.3rem",
        },
    },
}));
const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        "&$checked": {
            transform: "translateX(12px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: "none",
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

const Accordion = withStyles({
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        // backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: 0,
        minHeight: 35,
        "&$expanded": {
            minHeight: 35,
        },
    },
    content: {
        margin: "20px 5px",
        "&$expanded": {
            margin: "20px 5px",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

// Multiple select ================

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Park = ["Gates", "Fields", "Moisture"];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function CreatenewRule() {
    const classes = useStyles();
    const theme = useTheme();
    const myclass = weekdayclass();
    const dispatch = useDispatch();
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");
    const [loading, setLoading] = React.useState(true);
    const [color, setColor] = React.useState("#ffffff");
    const [getListdata, setListdata] = React.useState([]);
    const [pending, setPending] = React.useState(true);
    const [getDeleterefresh, setDeleterefresh] = React.useState(false);
    const [getdisableRuleid, setdisableRuleid] = React.useState([]);
    const [getdisableRulestatus, setdisableRulestatus] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [getToggleconfirm, setToggleconfirm] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [geteditdata, seteditdata] = React.useState([]);
    const itemsPerPage = 10;
    const [rows, setRowsdata] = React.useState([]);
    const [getTimeZoneValue, setTimeZoneValue] = React.useState("");
    const [getRulename, setRuleName] = React.useState("");
    const [getonDescriptionMessage, setonDescriptionMessage] = React.useState("");
    const [priorityno, setPriorityno] = React.useState("");
    const [getSummaryhead, setSummaryhead] = React.useState(false);
    const [getOnOFFValue, setOnOFFValue] = React.useState(false);
    const [getemailOnOFFValue, setemailOnOFFValue] = React.useState(false);
    const [getsmsOnOFFValue, setsmsOnOFFValue] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(startDate);
    const [getDayOfWeek, setDayOfWeek] = React.useState([]);
    const [timevalue, onTimeChange] = React.useState("");
    const [endtimevalue, oneTimeendChange] = React.useState("");
    const [getTrigerstrategy, setTrigerstrategy] = React.useState("");
    const [getShowCondition, setShowCondition] = React.useState("");
    const [getparkDeviceType, setparkDeviceType] = React.useState("");
    const [getparkDeviceId, setparkDeviceId] = React.useState([]);
    const [getTriggervalue, setTriggervalue] = React.useState("");
    const [getTriggerCriteria, setTriggerCriteria] = React.useState("");
    const [getTolerance, setTolerance] = React.useState("");
    const [gethandleParkId, sethandleParkId] = React.useState("");
    const [gethandleOpenClosePark, sethandleOpenClosePark] = React.useState("");
    const [EmailsList, setEmailsList] = React.useState([]);
    const [dropEmailsList, setdropEmailsList] = React.useState([]);
    const [MobileNumbersList, setMobileNumbersList] = React.useState([]);
    const [dropMobileNumbersList, setdropMobileNumbersList] = React.useState([]);
    const [getSmsMessage, setSmsMessage] = React.useState("");
    const [permission, setpermission] = React.useState({});
    const [expanded, setExpanded] = React.useState("panel1");
    const [getparkName, setparkName] = React.useState("");
    const [personName, setPersonName] = React.useState([]);
    const [gethandleParkDeviceType, sethandleParkDeviceType] = React.useState("");
    const [gethandleParkDevice, sethandleParkDevice] = React.useState("");
    const [getRuleError, setRuleError] = React.useState(false);
    const [getUpdateActionID, setUpdateActionID] = React.useState("");
    const [getUpdateConditionID, setUpdateConditionID] = React.useState("");
    const [getMultiSMSError, setMultiSMSError] = useState(false);
    const history = useHistory();

    // error States ============---------------=======================

    const [ruleNameError, setRuleNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [priorityError, setPriorityError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [dayOfWeekError, setDayOfWeekError] = useState(false);
    const [startTimeError, setStartTimeError] = useState(false);
    const [endTimeError, setEndTimeError] = useState(false);
    const [getendTimeErrorText, setEndTimeErrorText] = useState("");
    const [getStartTimeErrorText, setStartTimeErrorText] = useState("");
    // State variables for error messages
    const [trigerstrategyError, setTrigerstrategyError] = useState(false);
    const [parkNameError, setParkNameError] = useState(false);
    const [parkDeviceTypeError, setParkDeviceTypeError] = useState(false);
    const [parkDeviceIdError, setParkDeviceIdError] = useState(false);
    const [triggervalueError, setTriggervalueError] = useState(false);
    const [triggerCriteriaError, setTriggerCriteriaError] = useState(false);
    const [toleranceError, setToleranceError] = useState(false);
    const [toleranceRangeError, setToleranceRangeError] = useState(false);
    const [ActionparkNameError, setActionParkNameError] = useState(false);
    const [ActiondeviceTypeError, setActionDeviceTypeError] = useState(false);
    const [ActiondeviceError, setActionDeviceError] = useState(false);
    const [actionError, setActionError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [smsError, setSmsError] = useState(false);
    const [getCleareditoverlay, setCleareditoverlay] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [getweekdays, setweekdays] = useState(false);
    const [getweekenddays, setweekenddays] = useState(false);
    const [getalldays, setalldays] = useState(false);
    // State variable to track TriggerStrategy selection
    const [isTriggerStrategySelected, setIsTriggerStrategySelected] =
        useState(false);
    // error States ========================---------------=======================

    // all field error =================
    const [getSummrydataError, setSummrydataError] = useState(false);
    const [getvaliditySettingError, setvaliditySettingError] = useState(false);
    const [getoperationalSettingError, setoperationalSettingError] =
        useState(false);
    const [getconditionTrigerSettingError, setconditionTrigerSettingError] =
        useState(false);
    const [
        getconditionTrigerConditionSettingError,
        setconditionTrigerConditionSettingError,
    ] = useState(false);
    const [getactionSettingError, setactionSettingError] = useState(false);
    const [getemailnotificationSettingError, setemailnotificationSettingError] =
        useState(false);
    const [getsmsnotificationSettingError, setsmsnotificationSettingError] =
        useState(false);
    const [getblockDurationSettingError, setblockDurationSettingError] =
        useState(false);

    var timezone = localStorage.getItem("timeZone");
    // all field error =================

    // form field store ----------------
    const [getParktype, setParktype] = React.useState([]);
    const [getActionParktype, setActionParktype] = React.useState([]);
    const [getParkDevicetype, setParkDevicetype] = React.useState([]);
    const [getActionParkDevicetype, setActionParkDevicetype] = React.useState([]);
    const [getActionpartkname, setActionpartkname] = React.useState([]);
    const [getActiondevicetypename, setActiondevicetypename] = React.useState([]);
    const [getConditionParkName, setConditionParkName] = React.useState([]);
    const [getConditonparktype, setConditonparktype] = React.useState([]);
    const [getTrigger, setTrigger] = React.useState(false);
    const [getMultipleActionCondition, setMultipleActionCondition] =
        React.useState(false);

    const summrydata = useSelector((state) => state.formdatareduce.basicSettings);
    const validitySetting = useSelector(
        (state) => state.formdatareduce.validitySettings
    );
    const operationalSetting = useSelector(
        (state) => state.formdatareduce.operationalSettings
    );
    const conditionTrigerSetting = useSelector(
        (state) => state.formdatareduce.conditionTrigerSettings
    );
    const conditionTrigerConditionSetting = useSelector(
        (state) => state.formdatareduce.ConditionTrigerConditionSettings
    );
    const actionSetting = useSelector(
        (state) => state.formdatareduce.actionSettings
    );
    const notificationSetting = useSelector(
        (state) => state.formdatareduce.notificationSettings
    );
    const emailnotificationSetting = useSelector(
        (state) => state.formdatareduce.emailnotificationSettings
    );
    const smsnotificationSetting = useSelector(
        (state) => state.formdatareduce.smsnotificationSettings
    );
    // const blockDurationSetting = useSelector(
    //   (state) => state.formdatareduce.blockDurationSettings
    // );

    // form field store ---------------

    // for related rule =================
    const [getvalidityRuleIsAlwaysOn, setvalidityRuleIsAlwaysOn] =
        React.useState("");
    const [getvalidityRuleStartDate, setvalidityRuleStartDate] =
        React.useState("");
    const [getvalidityRuleEndDate, setvalidityRuleEndDate] = React.useState("");
    const [getRelatedConditon, setRelatedConditon] = React.useState([]);
    const [getRelatedAction, setRelatedAction] = React.useState([]);
    const [getRelatedConditionActions, setRelatedConditionActions] =
        React.useState(false);
    const [getrefreshstorage, setrefreshstorage] = useState(false);
    // for related rule =================
    // form dropdown ===================
    const [getSearchfilter, setSearchfilter] = React.useState([]);
    const [getparks, setparks] = React.useState([]);
    const [getactionpark, setactionpark] = React.useState([]);
    const [getActionparkID, setActionparkID] = React.useState([]);
    const names = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    const location = useLocation();
    // form dropdown ===================
    const [emailBody, setEmailBody] = React.useState(
        RichTextEditor.createEmptyValue("")
    );
    const alldata = {
        getRulename,
        getonDescriptionMessage,
        priorityno,
        getOnOFFValue,
        startDate,
        endDate,
        getDayOfWeek,
        timevalue,
        endtimevalue,
        getTrigerstrategy,
        getparkDeviceType,
        getConditionParkName,
        getConditonparktype,
        getTriggervalue,
        getTriggerCriteria,
        getTolerance,
        gethandleParkId,
        gethandleOpenClosePark,
        getActionpartkname,
        gethandleParkDeviceType,
        gethandleParkDevice,
        getActiondevicetypename,
        getparkName,
        getsmsOnOFFValue,
        MobileNumbersList,
        getSmsMessage,
        getemailOnOFFValue,
        getparkDeviceId,
    };

    React.useEffect(() => {
        if (refresh) {
            setRefresh(!refresh);
        }
    }, [
        refresh,
        summrydata,
        validitySetting,
        operationalSetting,
        conditionTrigerSetting,
        conditionTrigerConditionSetting,
        actionSetting,
        emailnotificationSetting,
        smsnotificationSetting,
    ]);

    React.useEffect(() => {
        if (localStorage.getItem("removeupdate")) {
            console.log("sdsf77777777777777");
            setUpdateActionID("");
            setUpdateConditionID("");
            localStorage.removeItem("removeupdate");
        }
    }, [localStorage.getItem("removeupdate")]);
    React.useEffect(() => {
        if (getrefreshstorage === false) {
            if (localStorage.getItem("addfields")) {
                localStorage.removeItem("addfields");
                setrefreshstorage(true);
            }
        }
    }, [getrefreshstorage]);

    React.useEffect(() => {
        if (conditionTrigerConditionSetting?.length === 0) {
            setparkName("");
            setparkDeviceType("");
            setparkDeviceId([]);
            setTriggervalue("");
            setTriggerCriteria("");
            setTolerance("");
            setCleareditoverlay(true);
            setUpdateConditionID("");
        }
    }, [conditionTrigerConditionSetting]);

    React.useEffect(() => {
        if (actionSetting?.length === 0) {
            sethandleParkId("");
            sethandleParkDeviceType("");
            sethandleParkDevice("");
            sethandleOpenClosePark("");
            setCleareditoverlay(true);
            setUpdateActionID("");
        }
    }, [actionSetting]);

    React.useEffect(() => {
        if (getSmsMessage.trim() !== "" && MobileNumbersList?.length > 0) {
            setSmsError(false);
        }
    }, [MobileNumbersList, getSmsMessage]);
    React.useEffect(() => {
        const emailHtmlContent = emailBody.toString("html");
        if (
            EmailsList?.length === 0 &&
            (!emailHtmlContent ||
                emailHtmlContent.trim() === "" ||
                emailHtmlContent.trim() === "<p><br></p>")
        ) {
            console.log("Email body -----------==============");
        } else {
            setEmailError(false);
            console.log("Email body contains text.");
        }
    }, [EmailsList, emailBody]);

    React.useEffect(() => {
        if (operationalSetting?.length === 0) {
            setDayOfWeek([]);
            onTimeChange("");
            oneTimeendChange("");
        }
    }, [operationalSetting]);

    React.useEffect(() => {
        if (getListdata.length > 0) {
            const timeout = setTimeout(() => {
                setRowsdata(getListdata);
                setPending(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [getListdata, refresh]);
    React.useEffect(() => {
        if (
            startDate !== "" &&
            endDate !== "" &&
            startDate !== null &&
            endDate !== null
        ) {
            setEndDateError(false);
            setStartDateError(false);
        }
    }, [startDate, endDate]);

    function trivalue(data, TriggerCriteria) {
        let symbol;
        if (TriggerCriteria === "exactly" || TriggerCriteria === "Exactly") {
            symbol = "==";
        }
        if (TriggerCriteria === "above" || TriggerCriteria === "Above") {
            symbol = ">";
        }
        if (TriggerCriteria === "below" || TriggerCriteria === "Below") {
            symbol = "<";
        }

        if (
            data === "opened" ||
            data === "Opened" ||
            data === "closed" ||
            data === "Closed"
        ) {
            return data;
        } else {
            return `${symbol} ${data}`;
        }
    }

    React.useEffect(() => {
        if (getRelatedConditionActions === true) {
            var finaldata = [];
            let url = "api/park/ruleengine/relatedrules";
            var restoken = GenerateEncryptedToken();
            var requestBody = {
                Page: 1,
                Limit: 10,
                // "RuleIsAlwaysOn": getvalidityRuleIsAlwaysOn,
                // "RuleValidityStartDate": getvalidityRuleStartDate,
                // "RuleValidityEndDate": getvalidityRuleEndDate,
                Conditions: getRelatedConditon,
                Actions: getRelatedAction,
            };

            smartParkService
                .post(url, requestBody, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        var counter = 1;
                        res.data.data?.forEach((data) => {
                            seteditdata(data.OperationalTime);
                            const rulename = () => {
                                return (
                                    <div className="commonsize" key={data.RuleID}>
                                        {data.RuleName}
                                    </div>
                                );
                            };
                            const ruledes = () => {
                                return (
                                    <div className="commonsize" key={data.RuleID}>
                                        {data.RuleDescription}
                                    </div>
                                );
                            };
                            const rulePriority = () => {
                                return (
                                    <div className="commonsize" key={data.RuleID}>
                                        {data.PriorityValue}
                                    </div>
                                );
                            };

                            const combinedHeaders = () => {
                                return (
                                    <div className="w-100" key={data.RuleID}>
                                        {data.RuleIsAlwaysOn === true ? (
                                            <div className="sameh commonsize">Rule Always ON</div>
                                        ) : (
                                            <div className="sameh">
                                                <div className="commonsize mb-1">
                                                    Start Date: {simpledate(data.RuleValidityStartDate)}
                                                </div>
                                                <div className="commonsize">
                                                    End Date: {simpledate(data.RuleValidityEndDate)}
                                                </div>
                                            </div>
                                        )}
                                        <hr className="my-1" />
                                        <div className="container-fluid  py-1">
                                            <div className="row printpageview listrule">
                                                {groupByDayOfWeek(data.OperationalTime).map(
                                                    (group, index) => (
                                                        <div
                                                            className="col-6 col-md-6 col-lg-6 conditionlist"
                                                            key={index}
                                                        >
                                                            <div className="viewdayofweek h-100">
                                                                <Accordion className="viewaccordion drtrtr">
                                                                    <AccordionSummary
                                                                        expandIcon={<KeyboardArrowRightIcon />}
                                                                        aria-controls={`panel${index + 1}a-content`}
                                                                        id={`panel${index + 1}a-header`}
                                                                        className="accorhead"
                                                                    >
                                                                        <Typography className={classes.heading}>
                                                                            <span className="headname commonsize">
                                                                                {Dayweekconvert(group[0].DayofWeek)}{" "}
                                                                            </span>
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails className="d-block">
                                                                        {group.map((timeInterval, idx) => (
                                                                            <Typography key={idx}>
                                                                                {timeInterval.StartTime} To{" "}
                                                                                {timeInterval.EndTime}
                                                                            </Typography>
                                                                        ))}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            };

                            const CombineTriggerConditions = () => {
                                return (
                                    <div className="text-center w-100 " key={data.RuleID}>
                                        <div className="triger commonsize">
                                            {data.TriggerStrategy}
                                        </div>
                                        <hr className="my-1 mb-2" />
                                        {data.TriggerStrategy !== "TIME BASED RULE"
                                            ? data.Conditions?.map((condition, idx) => {
                                                const parkNameDisplay =
                                                    condition.ParkName === "Morrisville Community Park"
                                                        ? "MCP"
                                                        : condition.ParkName ===
                                                            "Morrisville Aquatic and Fitness Center"
                                                            ? "MAFC"
                                                            : condition.ParkName ===
                                                                "Cedar Fork District Park"
                                                                ? "CFDP"
                                                                : "CSP";
                                                return (
                                                    <Accordion
                                                        className="viewaccordion trigeracco"
                                                        key={idx}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<KeyboardArrowRightIcon />}
                                                            aria-controls={`panel${idx + 1}a-content`}
                                                            id={`panel${idx + 1}a-header`}
                                                            className="accorhead"
                                                        >
                                                            <Typography className={classes.heading}>
                                                                <span className="headname commonsize">
                                                                    {parkNameDisplay} , {condition.DeviceName} ,{" "}
                                                                    {trivalue(
                                                                        condition.TriggerValue,
                                                                        condition.TriggerCriteria
                                                                    )}
                                                                </span>
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails className="d-block">
                                                            <Table
                                                                size="sm"
                                                                className="text-left"
                                                                key={idx}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Park Name: {parkNameDisplay}</td>
                                                                        <td>
                                                                            Device Type: {condition.Devicetype}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Device Name: {condition.DeviceName}
                                                                        </td>
                                                                        <td>
                                                                            Trigger Value: {condition.TriggerValue}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Trigger Criteria:{" "}
                                                                            {condition.TriggerCriteria}
                                                                        </td>
                                                                        <td>Duration: {condition.Tolerance}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                );
                                            })
                                            : ""}
                                    </div>
                                );
                            };

                            const Actionsettings = () => {
                                return (
                                    <div key={data.RuleID}>
                                        {data.Actions?.map((data, idx) => {
                                            const parkNameDisplay =
                                                data.ParkName === "Morrisville Community Park"
                                                    ? "MCP"
                                                    : data.ParkName ===
                                                        "Morrisville Aquatic and Fitness Center"
                                                        ? "MAFC"
                                                        : data.ParkName === "Cedar Fork District Park"
                                                            ? "CFDP"
                                                            : "CSP";

                                            return (
                                                <Accordion
                                                    className="viewaccordion trigeracco"
                                                    key={idx}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<KeyboardArrowRightIcon />}
                                                        aria-controls={`panel${idx + 1}a-content`}
                                                        id={`panel${idx + 1}a-header`}
                                                        className="accorhead"
                                                    >
                                                        <Typography className={classes.heading}>
                                                            <span className="headname commonsize">
                                                                {parkNameDisplay},{data.DeviceName},
                                                                {data.Action}{" "}
                                                            </span>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="d-block">
                                                        <Table
                                                            striped
                                                            bordered
                                                            hover
                                                            size="sm"
                                                            className="text-left"
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <td>Park Name : {parkNameDisplay}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Device Type : {data.Devicetype}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Device : {data.DeviceName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Action : {data.Action}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        })}
                                    </div>
                                );
                            };

                            const viewdeletebutton = (
                                <Dropdown key={data.RuleID}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <MoreVertIcon />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="actionicons">
                                        <Dropdown.Item
                                            href="#"
                                            onClick={() => viewrule(data.RuleID)}
                                        >
                                            <span>
                                                {" "}
                                                <AiFillEye />{" "}
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            href="#"
                                            onClick={() => EditRule(data.RuleID)}
                                        >
                                            <span>
                                                <BiSolidEditAlt />
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            href="#"
                                            onClick={() => DeleteRule(data.RuleID)}
                                        >
                                            <span>
                                                <RiDeleteBinFill />
                                            </span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            );

                            const enabledisables = () => {
                                return (
                                    <div
                                        className="w-100 icontoggle"
                                        onClick={() =>
                                            enabledisablerule(data.RuleID, data.Disabled)
                                        }
                                        key={data.RuleID}
                                    >
                                        {data.Disabled === false ? (
                                            <BsToggleOn className="ablebtn" />
                                        ) : (
                                            <BsToggleOn className="disablebtn" />
                                        )}
                                    </div>
                                );
                            };

                            finaldata.push({
                                id: data.RuleID,
                                RuleName: rulename(),
                                Prioritynumber: rulePriority(),
                                Description: ruledes(),
                                combinedHeaders: combinedHeaders(),
                                Conditionalsetting: CombineTriggerConditions(),
                                action: Actionsettings(),
                                enabledisable: enabledisables(),
                                menu: viewdeletebutton,
                            });
                        });

                        setListdata(finaldata);
                        setDeleterefresh(false);
                    } else {
                        console.log("FloodChart else error: ");
                    }
                })
                .catch((error) => {
                    console.error("FloodChart error: ", error);
                });
        }
    }, [getRelatedConditionActions]);

    function convertToAMPM(timeStr) {
        // Check if the input has colons
        timeStr = timeStr.toString();
        // return timeStr;
        if (timeStr.includes(":")) {
            const [hours, minutes] = timeStr.split(":");
            let period = "AM";
            let parsedHours = parseInt(hours);
            if (parsedHours >= 12) {
                period = "PM";
                if (parsedHours > 12) {
                    parsedHours -= 12;
                }
            }
            return `${parsedHours !== "" ? parsedHours : "00"}:${minutes !== "" ? minutes : "00"
                } ${period}`;
        } else {
            // If no colons, assume it's in the format "HHMM"
            const hours = timeStr.substring(0, 2);
            const minutes = timeStr.substring(2);
            let period = "AM";
            let parsedHours = parseInt(hours);
            if (parsedHours >= 12) {
                period = "PM";
                if (parsedHours > 12) {
                    parsedHours -= 12;
                }
            }
            return `${parsedHours !== "" ? parsedHours : "00"}:${minutes !== "" ? minutes : "00"
                } ${period}`;
        }
    }

    React.useEffect(() => {
        var finaldata = [];
        let url = "api/park/ruleengine/listrules";
        var restoken = GenerateEncryptedToken();
        var requestBody = {
            Page: currentPage,
            Limit: itemsPerPage,
        };
        smartParkService
            .post(url, requestBody, {
                headers: {
                    "x-access-token": restoken,
                    userid: userid,
                },
            })
            .then((res) => {
                if (res) {
                    res.data.data?.forEach((data) => {
                        seteditdata(data.OperationalTime);
                        const rulename = () => {
                            return (
                                <div className="commonsize" key={data.RuleID}>
                                    {data.RuleName}
                                </div>
                            );
                        };
                        const ruledes = () => {
                            return (
                                <div className="commonsize" key={data.RuleID}>
                                    {data.RuleDescription}
                                </div>
                            );
                        };
                        const rulePriority = () => {
                            return (
                                <div className="commonsize" key={data.RuleID}>
                                    {data.PriorityValue}
                                </div>
                            );
                        };
                        const combinedHeaders = () => {
                            return (
                                <div className="w-100" key={data.RuleID}>
                                    {data.RuleIsAlwaysOn === true ? (
                                        <div className="sameh commonsize">Rule Always ON</div>
                                    ) : (
                                        <div className="sameh">
                                            <div className="commonsize mb-1">
                                                Start Date: {simpledate(data.RuleValidityStartDate)}
                                            </div>
                                            <div className="commonsize">
                                                End Date: {simpledate(data.RuleValidityEndDate)}
                                            </div>
                                        </div>
                                    )}
                                    <hr className="my-1" />
                                    <div className="container-fluid  py-1">
                                        <div className="row printpageview listrule">
                                            {groupByDayOfWeek(data.OperationalTime).map(
                                                (group, index) => (
                                                    <div
                                                        className="col-6 col-md-6 col-lg-6 conditionlist"
                                                        key={index}
                                                    >
                                                        <div className="viewdayofweek h-100">
                                                            <Accordion className="viewaccordion drtrtr">
                                                                <AccordionSummary
                                                                    expandIcon={<KeyboardArrowRightIcon />}
                                                                    aria-controls={`panel${index + 1}a-content`}
                                                                    id={`panel${index + 1}a-header`}
                                                                    className="accorhead"
                                                                >
                                                                    <Typography className={classes.heading}>
                                                                        <span className="headname commonsize">
                                                                            {Dayweekconvert(group[0].DayofWeek)}{" "}
                                                                        </span>
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails className="d-block timedetails">
                                                                    {group.map((timeInterval, idx) => (
                                                                        <Typography key={idx} className="timesize">
                                                                            {convertToAMPM(timeInterval.StartTime)} To{" "}
                                                                            {convertToAMPM(timeInterval.EndTime)}
                                                                        </Typography>
                                                                    ))}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        };
                        const CombineTriggerConditions = () => {
                            return (
                                <div className="text-center w-100 " key={data.RuleID}>
                                    <div className="triger commonsize">
                                        {data.TriggerStrategy}
                                    </div>
                                    <hr className="my-1 mb-2" />
                                    {data.TriggerStrategy !== "TIME BASED RULE"
                                        ? data.Conditions?.map((condition, idx) => {
                                            const parkNameDisplay =
                                                condition.ParkName === "Morrisville Community Park"
                                                    ? "MCP"
                                                    : condition.ParkName ===
                                                        "Morrisville Aquatic and Fitness Center"
                                                        ? "MAFC"
                                                        : condition.ParkName === "Cedar Fork District Park"
                                                            ? "CFDP"
                                                            : "CSP";
                                            return (
                                                <Accordion
                                                    className="viewaccordion trigeracco"
                                                    key={idx}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<KeyboardArrowRightIcon />}
                                                        aria-controls={`panel${idx + 1}a-content`}
                                                        id={`panel${idx + 1}a-header`}
                                                        className="accorhead"
                                                    >
                                                        <Typography className={classes.heading}>
                                                            <span className="headname commonsize">
                                                                {parkNameDisplay} , {condition.DeviceName} ,{" "}
                                                                {trivalue(
                                                                    condition.TriggerValue,
                                                                    condition.TriggerCriteria
                                                                )}
                                                            </span>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="d-block">
                                                        <Table size="sm" className="text-left" key={idx}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Park Name: {parkNameDisplay}</td>
                                                                    <td>Device Type: {condition.Devicetype}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Device Name: {condition.DeviceName}</td>
                                                                    <td>
                                                                        Trigger Value: {condition.TriggerValue}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Trigger Criteria:{" "}
                                                                        {condition.TriggerCriteria}
                                                                    </td>
                                                                    <td>Duration: {condition.Tolerance}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        })
                                        : ""}
                                </div>
                            );
                        };

                        const Actionsettings = () => {
                            return (
                                <div key={data.RuleID}>
                                    {data.Actions?.map((data, idx) => {
                                        const parkNameDisplay =
                                            data.ParkName === "Morrisville Community Park"
                                                ? "MCP"
                                                : data.ParkName ===
                                                    "Morrisville Aquatic and Fitness Center"
                                                    ? "MAFC"
                                                    : data.ParkName === "Cedar Fork District Park"
                                                        ? "CFDP"
                                                        : "CSP";
                                        return (
                                            <Accordion className="viewaccordion trigeracco" key={idx}>
                                                <AccordionSummary
                                                    expandIcon={<KeyboardArrowRightIcon />}
                                                    aria-controls={`panel${idx + 1}a-content`}
                                                    id={`panel${idx + 1}a-header`}
                                                    className="accorhead"
                                                >
                                                    <Typography className={classes.heading}>
                                                        <span className="headname commonsize">
                                                            {parkNameDisplay},{data.DeviceName},{data.Action}{" "}
                                                        </span>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className="d-block">
                                                    <Table
                                                        striped
                                                        bordered
                                                        hover
                                                        size="sm"
                                                        className="text-left"
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td>Park Name : {parkNameDisplay}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Device Type : {data.Devicetype}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Device : {data.DeviceName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Action : {data.Action}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </div>
                            );
                        };

                        const viewdeletebutton = (
                            <Dropdown key={data.RuleID}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <MoreVertIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="actionicons">
                                    <Dropdown.Item href="#" onClick={() => viewrule(data.RuleID)}>
                                        <span>
                                            {" "}
                                            <AiFillEye />{" "}
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={() => EditRule(data.RuleID)}>
                                        <span>
                                            <BiSolidEditAlt />
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        href="#"
                                        onClick={() => DeleteRule(data.RuleID)}
                                    >
                                        <span>
                                            <RiDeleteBinFill />
                                        </span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        );

                        const enabledisables = () => {
                            return (
                                <div
                                    className="w-100 icontoggle"
                                    onClick={() => enabledisablerule(data.RuleID, data.Disabled)}
                                    key={data.RuleID}
                                >
                                    {data.Disabled === false ? (
                                        <BsToggleOn className="ablebtn" />
                                    ) : (
                                        <BsToggleOn className="disablebtn" />
                                    )}
                                </div>
                            );
                        };

                        finaldata.push({
                            id: data.RuleID,
                            RuleName: rulename(),
                            Prioritynumber: rulePriority(),
                            Description: ruledes(),
                            combinedHeaders: combinedHeaders(),
                            Conditionalsetting: CombineTriggerConditions(),
                            action: Actionsettings(),
                            enabledisable: enabledisables(),
                            menu: viewdeletebutton,
                        });
                    });

                    setListdata(finaldata);
                    setDeleterefresh(false);
                } else {
                    console.log("FloodChart else error: ");
                }
            })
            .catch((error) => {
                console.error("FloodChart error: ", error);
            });
    }, [getDeleterefresh]);

    function timestampconvert(time) {
        const dateTimeObj = new Date(time);
        // Get the timestamp in milliseconds
        let unixTimestampInMilliseconds = dateTimeObj.getTime();
        // Get the time zone offset in minutes
        const timezoneOffsetInMinutes = dateTimeObj.getTimezoneOffset();
        // Adjust the timestamp to include the time zone offset
        unixTimestampInMilliseconds += timezoneOffsetInMinutes * 60 * 1000;
        // Return the timestamp
        return unixTimestampInMilliseconds;
    }

    // dropdown Api =====================

    function converthtmlcontent(test) {
        const parser = new DOMParser();
        const parsedHTML = parser.parseFromString(test, "text/html");
        const textContent = parsedHTML.body.textContent;
        return textContent;
    }

    function dayweeknum(dayweek) {
        const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        const dayIndex = days.findIndex(
            (day) => day.toLowerCase() === dayweek.toLowerCase()
        );
        if (dayIndex !== -1) {
            return dayIndex;
        } else {
            return -1; // Indicate that the input day is not valid
        }
    }

    React.useEffect(() => {
        let finalrule = {};
        let relatedConditions = [];
        let relatedActions = [];

        summrydata?.forEach((data) => {
            // Create a new object for each data item
            finalrule["RuleName"] = data.RuleName;
            finalrule["RuleDescription"] = data.Rule_Description;
            finalrule["PriorityValue"] = data.priorityno;
            // finalRules.push(finalrule); // Add the current finalrule object to the array
        });
        validitySetting?.forEach((data) => {
            finalrule["RuleIsAlwaysOn"] = data.RuleIsAlwaysOn;
            finalrule["RuleValidityStartDate"] = timestampconvert(
                data.RuleValidityStartDate
            );
            finalrule["RuleValidityEndDate"] = timestampconvert(
                data.RuleValidityEndDate
            );
            setvalidityRuleIsAlwaysOn(data.RuleIsAlwaysOn);
            // setvalidityRuleStartDate(timestampconvert(data.RuleValidityStartDate));
            // setvalidityRuleEndDate(timestampconvert(data.RuleValidityEndDate));
            setvalidityRuleStartDate(data.RuleIsAlwaysOn);
            setvalidityRuleEndDate(data.RuleIsAlwaysOn);
        });
        finalrule["OperationalTime"] = [];
        operationalSetting?.forEach((data) => {
            finalrule["OperationalTime"].push({
                DayofWeek: dayweeknum(data.DayofWeek),
                StartTime: timeStringToNumber(data.StartTime),
                EndTime: timeStringToNumber(data.EndTime),
            });
        });
        conditionTrigerSetting?.forEach((data) => {
            finalrule["TriggerStrategy"] = data.TriggerStrategy;
        });
        finalrule["Conditions"] = [];
        if (finalrule["TriggerStrategy"] == "TIME BASED RULE") {
            finalrule["Conditions"].push({
                ParkID: "",
                ParkName: "",
                Devicetype: "", //Gate, Field, Court, Park, Moisture, Precipitation, Temperature, Sunset, Sunrise
                DeviceID: "",
                DeviceName: "",
                TriggerValue: "",
                TriggerCriteria: "", //Below, Above, Exactly
                Tolerance: "", //In Minutes
            });
        } else {
            conditionTrigerConditionSetting?.forEach((data) => {
                finalrule["Conditions"].push({
                    ParkID: data.ParkID,
                    ParkName: data.ParkName,
                    Devicetype: data.Devicetype, //Gate, Field, Court, Park, Moisture, Precipitation, Temperature, Sunset, Sunrise
                    DeviceID: data.DeviceID,
                    DeviceName: data.DeviceName,
                    TriggerValue: data.TriggerValue,
                    TriggerCriteria: data.TriggerCriteria, //Below, Above, Exactly
                    Tolerance: data.Tolerance, //In Minutes
                });
                relatedConditions.push({
                    ParkID: data.ParkID,
                    ParkName: data.ParkName,
                    Devicetype: data.Devicetype, //Gate, Field, Court, Park, Moisture, Precipitation, Temperature, Sunset, Sunrise
                    DeviceID: data.DeviceID,
                    DeviceName: data.DeviceName,
                    TriggerValue: data.TriggerValue,
                    TriggerCriteria: data.TriggerCriteria, //Below, Above, Exactly
                    Tolerance: data.Tolerance, //In Minutes
                });
            });
        }
        setRelatedConditon(relatedConditions);
        finalrule["Actions"] = [];
        actionSetting?.forEach((data) => {
            finalrule["Actions"].push({
                ParkID: data.ParkID,
                ParkName: data.ParkName,
                Devicetype: data.Devicetype, //Gate, Field, Court, Park, Moisture, Precipitation, Temperature, Sunset, Sunrise
                DeviceID: data.DeviceID,
                DeviceName: data.DeviceName,
                Action: data.Action,
            });
            relatedActions.push({
                ParkID: data.ParkID,
                ParkName: data.ParkName,
                Devicetype: data.Devicetype, //Gate, Field, Court, Park, Moisture, Precipitation, Temperature, Sunset, Sunrise
                DeviceID: data.DeviceID,
                DeviceName: data.DeviceName,
                Action: data.Action,
            });
        });

        setRelatedAction(relatedActions);
        finalrule["EmailRecipients"] = [];
        finalrule["SMSRecipients"] = [];

        notificationSetting?.forEach((data) => {
            data.EmailRecipients.forEach((dataone) => {
                finalrule["EmailRecipients"].push(dataone.EmailAddress);
            });
            let htmltext = data.EmailBody._cache.html;
            finalrule["EmailBody"] = converthtmlcontent(htmltext);
            data.SMSRecipients?.forEach((dataone) => {
                finalrule["SMSRecipients"].push(dataone.MobilePhone);
            });
            finalrule["SMSBody"] = data.SMSBody;
        });

        // blockDurationSetting?.forEach((data) => {
        //   finalrule["BlockDuration"] = formatBlockDuration(
        //     data.BlockDurationhour,
        //     data.BlockDurationmin
        //   );
        // });
    }, [summrydata]);

    function gotoEditpage(editruleid) {
        let path = "editrulecreate";
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${editruleid}` }, // Adding state data
        });
    }

    function isObjectValid(obj) {
        setMultiSMSError(false);
        if (typeof obj === "object") {
            // Check specific keys
            const requiredKeys = [
                "RuleName",
                // "BlockDuration",
                "PriorityValue",
                "RuleDescription",
                "RuleIsAlwaysOn",
                "TriggerStrategy",
            ];
            for (const key of requiredKeys) {
                const value = obj[key];
                if (value === undefined || value === null || value === "") {
                    return false; // Validation failed for this key
                }
            }
            if (isNaN(obj.RuleValidityEndDate)) {
                console.log(obj.RuleValidityEndDate);
                return false;
            }
            if (isNaN(obj.RuleValidityStartDate)) {
                console.log(obj.RuleValidityStartDate);
                return false;
            }
            if (
                obj.RuleValidityEndDate == null &&
                obj.RuleValidityStartDate == null &&
                obj.RuleIsAlwaysOn == false
            ) {
                return false;
            }
            if (obj.TriggerStrategy !== "TIME BASED RULE") {
                if (!Array.isArray(obj.Conditions) || obj.Conditions.length === 0) {
                    return false; // Validation failed for Conditions
                }
            }
            if (
                !Array.isArray(obj.OperationalTime) ||
                obj.OperationalTime.length === 0
                //  ||
                // !Array.isArray(obj.Actions) ||
                // obj.Actions.length === 0
            ) {
                return false; // Validation failed for one of the arrays
            }
            if (
                !Array.isArray(obj.OperationalTime) ||
                obj.OperationalTime.length === 0
            ) {
                return false; // Validation failed for OperationalTime
            }

            if (
                emailnotificationSetting?.length < 1 &&
                smsnotificationSetting?.length < 1
            ) {
                return false;
            }
            // if (smsnotificationSetting?.length < 1) {
            //     return false;
            // }
            // All validation passed
            return true;
        }
        return false;
    }

    function validateField() {
        setSummrydataError(false);
        setvaliditySettingError(false);
        setoperationalSettingError(false);
        setconditionTrigerSettingError(false);
        setconditionTrigerConditionSettingError(false);
        setactionSettingError(false);
        setemailnotificationSettingError(false);
        setsmsnotificationSettingError(false);
        setblockDurationSettingError(false);
        if (summrydata?.length < 1) {
            setSummrydataError(true);
        }
        if (validitySetting?.length < 1) {
            setvaliditySettingError(true);
        }
        if (operationalSetting?.length < 1) {
            setoperationalSettingError(true);
        }
        if (
            conditionTrigerSetting?.length < 1 &&
            conditionTrigerConditionSetting?.length < 1
        ) {
            setconditionTrigerSettingError(true);
        }
        // if (conditionTrigerConditionSetting?.length < 1) {
        //     setconditionTrigerConditionSettingError(true);
        // }
        // if (actionSetting?.length < 1) {
        //   setactionSettingError(true);
        // }
        if (emailnotificationSetting?.length < 1) {
            setemailnotificationSettingError(true);
        }
        if (smsnotificationSetting?.length < 1) {
            setsmsnotificationSettingError(true);
        }
        // if (blockDurationSetting?.length < 1) {
        //   setblockDurationSettingError(true);
        // }
    }

    // Function to convert time string to number
    function timeStringToNumber(timeString) {
        if (typeof timeString === "number") {
            // If timeString is already a number, return it
            return timeString;
        } else if (typeof timeString === "string") {
            // If timeString is a string, split it into hours and minutes
            const [hours, minutes] = timeString.includes(":")
                ? timeString.split(":")
                : [timeString.substring(0, 2), timeString.substring(2)];
            // Return the parsed number after padding and concatenating
            return parseInt(hours.padStart(2, "0") + minutes.padStart(2, "0"), 10);
        } else {
            // Handle other cases or throw an error for unsupported types
            throw new Error("Unsupported type for timeString");
        }
    }

    function newrule() {
        setToggleconfirm(true);
        validateField();
        var finalrule = {};
        finalrule["RuleName"] = "";
        finalrule["RuleDescription"] = "";
        finalrule["PriorityValue"] = null;
        summrydata?.forEach((data) => {
            finalrule["RuleName"] = data.RuleName;
            finalrule["RuleDescription"] = data.Rule_Description;
            finalrule["PriorityValue"] = data.priorityno;
        });
        finalrule["RuleIsAlwaysOn"] = false;
        finalrule["RuleValidityStartDate"] = null;
        finalrule["RuleValidityEndDate"] = null;
        validitySetting?.forEach((data) => {
            const otherStartZone = moment.tz(
                data.RuleValidityStartDate,
                "America/New_York"
            );
            const dateWithoutZoneStart = moment(otherStartZone).utc().valueOf();
            const otherZone = moment.tz(data.RuleValidityEndDate, "America/New_York");
            const dateWithoutZoneEnd = moment(otherZone)
                .set({ hour: 23, minute: 59, second: 59 })
                .utc()
                .valueOf();
            finalrule["RuleIsAlwaysOn"] = data.RuleIsAlwaysOn;
            finalrule["RuleValidityStartDate"] = data.RuleIsAlwaysOn
                ? ""
                : dateWithoutZoneStart;
            finalrule["RuleValidityEndDate"] = data.RuleIsAlwaysOn
                ? ""
                : dateWithoutZoneEnd;
        });
        finalrule["OperationalTime"] = [];
        operationalSetting?.forEach((data) => {
            finalrule["OperationalTime"].push({
                DayofWeek: dayweeknum(data.DayofWeek),
                StartTime: timeStringToNumber(data.StartTime),
                EndTime: timeStringToNumber(data.EndTime),
            });
        });
        finalrule["TriggerStrategy"] = "";
        conditionTrigerSetting?.forEach((data) => {
            finalrule["TriggerStrategy"] = data.TriggerStrategy;
        });
        finalrule["Conditions"] = [];
        if (finalrule["TriggerStrategy"] == "TIME BASED RULE") {
            finalrule["Conditions"] = [];
        } else {
            conditionTrigerConditionSetting?.forEach((data) => {
                finalrule["Conditions"].push({
                    ParkID: data.ParkID,
                    ParkName: data.ParkName,
                    Devicetype: data.Devicetype, //Gate, Field, Court, Park, Moisture, Precipitation, Temperature, Sunset, Sunrise
                    DeviceID: data.DeviceID,
                    DeviceName: data.DeviceName,
                    TriggerValue: data.TriggerValue,
                    TriggerCriteria: data.TriggerCriteria, //Below, Above, Exactly
                    Tolerance: data.Tolerance, //In Minutes
                });
            });
        }
        finalrule["Timezone"] =
            timezone !== "undefined" && timezone !== "null" && timezone !== null
                ? timezone
                : "America/New_York";
        finalrule["Actions"] = [];
        actionSetting?.forEach((data) => {
            finalrule["Actions"].push({
                ParkID: data.ParkID,
                ParkName: data.ParkName,
                Devicetype: data.Devicetype, //Gate, Field, Court, Park, Moisture, Precipitation, Temperature, Sunset, Sunrise
                DeviceID: data.DeviceID,
                DeviceName: data.DeviceName,
                Action: data.Action,
            });
        });
        finalrule["EmailRecipients"] = [];
        finalrule["SMSRecipients"] = [];
        finalrule["EmailBody"] = "";
        finalrule["SMSBody"] = "";
        finalrule["sendSMSNotification"] = false;
        finalrule["sendEmailNotification"] = false;
        emailnotificationSetting?.forEach((data) => {
            if (data.sendEmailNotification === true) {
                finalrule["sendEmailNotification"] = data.sendEmailNotification;
                data.EmailRecipients?.forEach((dataone) => {
                    try {
                        const emailMatch = dataone?.match(
                            /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/
                        );
                        if (emailMatch) {
                            const email = emailMatch[0];
                            finalrule["EmailRecipients"].push(email);
                        }
                    } catch {
                        console.log("email recipient error");
                    }
                });
                let htmltext = data.EmailBody._cache.html;
                finalrule["EmailBody"] = converthtmlcontent(htmltext);
            }
            if (data.sendEmailNotification === false) {
                finalrule["sendEmailNotification"] = data.sendEmailNotification;
                finalrule["EmailRecipients"] = [];
                finalrule["EmailBody"] = "";
            }
        });
        smsnotificationSetting?.forEach((data) => {
            if (data.sendSMSNotification === true) {
                finalrule["sendSMSNotification"] = data.sendSMSNotification;
                data.SMSRecipients?.forEach((dataone) => {
                    try {
                        const phoneMatch = dataone?.match(/\(\d{3}-\d{3}-\d{4}\)/);
                        if (phoneMatch) {
                            const phoneNumberWithParentheses = phoneMatch[0];
                            // Remove the parentheses to get just the phone number
                            const phoneNumber = phoneNumberWithParentheses?.replace(
                                /\(|\)/g,
                                ""
                            );
                            finalrule["SMSRecipients"].push(phoneNumber);
                        }
                    } catch {
                        console.log("SMS Error");
                    }
                });
                finalrule["SMSBody"] = data.SMSBody;
            }
            if (data.sendSMSNotification === false) {
                finalrule["sendSMSNotification"] = data.sendSMSNotification;
                finalrule["SMSRecipients"] = [];
                finalrule["SMSBody"] = "";
            }
        });
        console.log(finalrule);
        const isValid = isObjectValid(finalrule);
        // return false;
        if (isValid === true) {
            let url = "api/park/ruleengine/saverule";
            var restoken = GenerateEncryptedToken();
            smartParkService
                .post(url, finalrule, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    // console.log(res);
                    if (res.status == 200) {
                        setSummaryhead(true);
                        if (res.data.status == 500) {
                            const mes = (
                                <div className="toastsuccess">
                                    <div className="erroricon">
                                        <BiSolidXCircle />
                                    </div>
                                    <div className="toastmessage">
                                        <b>Error</b>
                                        <div>{res.data.message}</div>
                                    </div>
                                </div>
                            );
                            toast.error(mes, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                                className: "errorcontainer",
                            });
                            console.log("rule duplicate");
                        } else {
                            const mes = (
                                <div className="toastsuccess">
                                    <div className="toasticon">
                                        <BiSolidCheckCircle />
                                    </div>
                                    <div className="toastmessage">
                                        <b>Success</b>
                                        <div>{res.data.message}</div>
                                    </div>
                                </div>
                            );
                            toast.success(mes, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                                className: "toastcontainer",
                            });

                            if (res.RuleID !== undefined || res.RuleID !== "") {
                                gotoEditpage(res.data.data.RuleID);
                            }
                            console.log("rule created");
                        }
                        setTimeout(() => {
                            setToggleconfirm(false);
                        }, 5000);
                    }
                })
                .catch((error) => {
                    console.error("Create Rule Error ", error);
                });
        } else {
            const mes = (
                <div className="toastsuccess">
                    <div className="erroricon">
                        <BiSolidXCircle />
                    </div>
                    <div className="toastmessage">
                        <b>Error</b>
                        <div>All required fields must be filled.</div>
                    </div>
                </div>
            );
            toast.error(mes, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: "errorcontainer",
            });
            setTimeout(() => {
                setToggleconfirm(false);
            }, 5000);
        }
        // setToggleconfirm(false);
    }

    React.useEffect(() => {
        let url = "api/park/ruleengine/dropdownoptions";
        var restoken = GenerateEncryptedToken();
        smartParkService
            .get(url, {
                headers: {
                    "x-access-token": restoken,
                    userid: userid,
                },
            })
            .then((res) => {
                setPriorityno(res.data.priority);
                const conditions = res.data.dropdown.conditions;
                var dataa = [];
                var devices = {};
                conditions?.forEach((data) => {
                    dataa.push({
                        id: data.ID,
                        name: data.Name,
                        shortname: data.ShortName,
                        device: data.Devices,
                    });
                    devices[data.ID] = data.Devices;
                });
                setparks(dataa);
                setSearchfilter(devices);

                const actionsdata = res.data.dropdown.actions;
                var actiondatta = [];
                var actiondevices = {};
                actionsdata?.forEach((data) => {
                    actiondatta.push({
                        id: data.ID,
                        name: data.Name,
                        shortname: data.ShortName,
                        device: data.Devices,
                    });
                    actiondevices[data.ID] = data.Devices;
                });
                setactionpark(actiondatta);
                setActionparkID(actiondevices);
            })
            .catch((error) => {
                console.error("FloodChart error: ", error);
            });
    }, []);

    const handleparkChange = (event) => {
        let storeParkname = [];
        setparkName(event.target.value);
        setparkDeviceType("");
        setparkDeviceId([]);
        setParkDevicetype([]);
        setConditonparktype("");
        setTriggervalue("");
        setTriggerCriteria("");
        setTolerance("");
        let alldevices = getSearchfilter;
        const selectedParkName = event.target.value;
        if (selectedParkName !== "") {
            setParkNameError(false);
            storeParkname = alldevices[selectedParkName];
        }

        setParktype(
            Object.keys(storeParkname).filter(
                (category) => category === "Park" || storeParkname[category].length > 0
            )
        );

        const selectedOption = getparks.find(
            (data) => data.id === selectedParkName
        );
        const getparkname = selectedOption ? selectedOption.name : "";
        setConditionParkName(getparkname);
    };

    const handleparkType = (event) => {
        setparkDeviceType(event.target.value);
        if (event.target.value !== "") {
            setParkDeviceTypeError(false);
        }
        setparkDeviceId([]);
        const seldevice = event.target.value;
        let Devicetype;
        const parkid = getparkName;
        const park = getparks;
        var parkobj = [];
        park?.forEach((data) => {
            if (data.id == parkid) {
                parkobj = data;
            }
        });
        var devices = parkobj["device"] ?? [];
        var devicetype = devices[seldevice] ?? [];
        if (!Array.isArray(devicetype)) {
            let devicetypeone = [devicetype];
            setParkDevicetype(devicetypeone);
        } else {
            setParkDevicetype(devicetype);
        }
        setTolerance("");
        setTriggerCriteria("");
        setTriggervalue("");
    };
    const handleparkDevices = (event) => {
        setparkDeviceId(event.target.value);
        if (event.target.value !== "") {
            setParkDeviceIdError(false);
        }
        const seldevice = event.target.value;
        const selectedOption = getParkDevicetype.find(
            (data) =>
                data.SensorID === seldevice ||
                data.FieldID === seldevice ||
                data.ID === seldevice
        );
        const getparkname = selectedOption
            ? selectedOption.FieldName
                ? selectedOption.FieldName
                : selectedOption.Name
                    ? selectedOption.Name
                    : selectedOption.SensorName
                        ? selectedOption.SensorName
                        : ""
            : "";
        setConditonparktype(getparkname);
    };

    useEffect(() => {
        if (gethandleParkId !== "") {
            try {
                let url = `api/park/timezone/${gethandleParkId}`;
                // console.log(url);
                var restoken = GenerateEncryptedToken();
                smartParkService
                    .get(url, {
                        headers: {
                            "x-access-token": restoken,
                        },
                    })
                    .then((res) => {
                        console.log(res.data.facilities.timezone);
                        if (res.data.status == "200") {
                            try {
                                setTimeZoneValue(res.data.facilities.timezone);
                                let timezone = res.data.facilities.timezone;
                                localStorage.setItem("timeZone", timezone);
                            } catch {
                                console.log("timezone facilities error");
                            }
                        }
                    });
            } catch {
                console.log("Timezone get error");
            }
        }
    }, [gethandleParkId]);

    // action dropdown============

    const handleParkId = (elem) => {
        sethandleParkId(elem.target.value);
        sethandleParkDeviceType("");
        sethandleParkDevice("");
        sethandleOpenClosePark("");
        let storeParkname = [];
        let alldevices = getActionparkID;
        const selectedParkName = elem.target.value;
        if (selectedParkName !== "") {
            setActionParkNameError(false);
            storeParkname = alldevices[selectedParkName];
        }

        setActionParktype(
            Object.keys(storeParkname).filter(
                (category) => category === "Park" || storeParkname[category].length > 0
            )
        );
        const selectedOption = getactionpark.find(
            (data) => data.id === selectedParkName
        );
        const getparkname = selectedOption ? selectedOption.name : "";
        setActionpartkname(getparkname);
    };
    const handledeviceType = (event) => {
        sethandleParkDeviceType(event.target.value);
        if (event.target.value !== "") {
            setActionDeviceTypeError(false);
        }
        sethandleParkDevice("");
        const seldevice = event.target.value;
        let Devicetype;
        const actionparkid = gethandleParkId;
        const actionpark = getactionpark;
        var actionparkobj = [];
        actionpark?.forEach((data) => {
            if (data.id == actionparkid) {
                actionparkobj = data;
            }
        });
        var actiondevices = actionparkobj["device"] ?? [];
        var actiondevicetype = actiondevices[seldevice] ?? [];
        if (!Array.isArray(actiondevicetype)) {
            let actiondevicetypeone = [actiondevicetype];
            setActionParkDevicetype(actiondevicetypeone);
        } else {
            setActionParkDevicetype(actiondevicetype);
        }
    };

    const handledevice = (elem) => {
        sethandleParkDevice(elem.target.value);
        if (elem.target.value !== "") {
            setActionDeviceError(false);
        }
        const seldevice = elem.target.value;
        const selectedOption = getActionParkDevicetype.find((data) => {
            if (data.FieldID === seldevice) {
                return data.FieldID;
            } else if (data.ID === seldevice) {
                return data.ID;
            } else {
                return "";
            }
        });
        const getparkname = selectedOption
            ? selectedOption.FieldName
                ? selectedOption.FieldName
                : selectedOption.Name
            : "";
        setActiondevicetypename(getparkname);
    };

    const findDeviceName = (id) => {
        let deviceName;
        getParkDevicetype.forEach((device) => {
            if (device.ID === id || device.FieldID === id || device.SensorID === id) {
                deviceName = device.Name || device.FieldName || device.SensorName;
            }
        });
        return deviceName;
    };

    function funcRulename(e) {
        if (e.target.value !== "") {
            setRuleNameError(false);
        }
        setRuleName(e.target.value);
    }
    function funcRuleDes(e) {
        if (e.target.value !== "") {
            setDescriptionError(false);
        }
        setonDescriptionMessage(e.target.value);
    }
    function funcRulePriority(e) {
        if (e.target.value !== "") {
            setPriorityError(false);
        }
        setPriorityno(e.target.value.trim());
    }

    function funcStartdate(e) {
        if (e !== "" && e !== null) {
            setStartDateError(false);
            // let time = timezoneformat(e, "America/New_York");
            setStartDate(new Date(e));
            setEndDate(new Date(e));
        }
    }

    function funcEnddate(e) {
        if (e !== "" && e !== null) {
            setEndDateError(false);
            // const time = timezoneformat(e, "America/New_York");
            setEndDate(new Date(e));
        }
    }
    function funcDayofweek(e) {
        setweekenddays(false);
        setalldays(false);
        setweekdays(false);
        if (e.target.value.length > 0) {
            setDayOfWeekError(false);
        }
        setDayOfWeek(e.target.value);
    }

    function convertTo24HourFormat(time12) {
        const [time, period] = time12.split(" ");
        let [hours, minutes] = time.split(":");
        if (period === "PM" && hours !== "12") {
            hours = String(Number(hours) + 12);
        } else if (period === "AM" && hours === "12") {
            hours = "00";
        }
        return `${hours}:${minutes}`;
    }

    function FuncTimeChange(e) {
        onTimeChange(e.target.value);
        const selectedTime = e.target.value;
        const currentTime = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
        setStartTimeError(false);
        if (selectedTime === "") {
            setStartTimeError(true);
            setStartTimeErrorText("Please Enter Start Time");
        } else {
            const selectedTime24 = convertTo24HourFormat(selectedTime);
            const currentTime24 = convertTo24HourFormat(currentTime);
            const endTime24 = convertTo24HourFormat(endtimevalue);
            if (selectedTime24 > endTime24) {
                setStartTimeError(true);
                console.error("Start Time cannot be greator than End Time.");
                setStartTimeErrorText("Start Time cannot be greator than End Time.");
            } else if (selectedTime24 == endTime24) {
                setStartTimeError(true);
                setStartTimeErrorText("Start Time and End Time cannot be same.");
            } else {
                onTimeChange(selectedTime24);
                setStartTimeError(false);
                setStartTimeErrorText("");
                setEndTimeError(false);
                setEndTimeErrorText("");
                // Do something with valid start time
            }
        }
    }

    function FuncTimeendChange(e) {
        oneTimeendChange(e.target.value);
        const selectedEndTime = e.target.value;
        const currentTime = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
        setEndTimeError(false);
        if (selectedEndTime === "") {
            setEndTimeError(true);
            setEndTimeErrorText("Please Enter the End Time");
        } else {
            const selectedEndTime24 = convertTo24HourFormat(selectedEndTime);
            const currentTime24 = convertTo24HourFormat(currentTime);
            const startTime24 = convertTo24HourFormat(timevalue);
            if (selectedEndTime24 < startTime24) {
                setEndTimeError(true);
                console.error("End time cannot be before the start time.");
                setEndTimeErrorText("End time cannot be before the start time.");
            } else if (selectedEndTime24 > "23:59") {
                setEndTimeError(true);
                setEndTimeErrorText("End time cannot be after 11:59 PM.");
            } else if (selectedEndTime24 == startTime24) {
                setEndTimeError(true);
                setEndTimeErrorText("End Time and Start Time cannot be same.");
            } else {
                oneTimeendChange(selectedEndTime24);
                setEndTimeError(false);
                setEndTimeErrorText("");
                setStartTimeError(false);
                setStartTimeErrorText("");
                // Do something with valid end time
            }
        }
    }

    // action dropdown============
    // dropdown Api =====================
    const handleOnOffChange = (event) => {
        setOnOFFValue(event.target.checked);
        if (event.target.checked === true) {
            setStartDate("");
            setEndDate("");
        } else {
            setStartDate(new Date());
            setEndDate(new Date());
        }
        setOnOffState({ ...onoffstate, [event.target.name]: event.target.checked });
    };
    const handleemailOnOffChange = (event) => {
        if (event.target.checked === false) {
            dispatch(todosAction.resetemailnotificationState());
        }
        if (getsmsOnOFFValue === false) {
            dispatch(todosAction.resetsmsnotificationState());
        }
        setEmailError(false);
        setEmailBody(RichTextEditor.createEmptyValue(""));
        setemailOnOFFValue(event.target.checked);
        setOnOffState({ ...onoffstate, [event.target.name]: event.target.checked });
    };
    const handlesmsOnOffChange = (event) => {
        if (event.target.checked === false) {
            dispatch(todosAction.resetsmsnotificationState());
        }
        if (getemailOnOFFValue === false) {
            dispatch(todosAction.resetemailnotificationState());
        }
        setSmsError(false);
        setSmsMessage("");
        setsmsOnOFFValue(event.target.checked);
        setOnOffState({ ...onoffstate, [event.target.name]: event.target.checked });
    };
    const handleChange = (panel) => (event, isExpanded) => {
        event.preventDefault();
        event.stopPropagation();
        setExpanded(isExpanded ? panel : false);
    };
    const [onoffstate, setOnOffState] = React.useState({
        checkedC: true,
    });

    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);

    const handleTriggerstrategyChange = (e) => {
        if (e.target.value !== "") {
            setTrigerstrategyError(false);
        }
        setTrigerstrategy(e.target.value);
        if (e.target.value === "TIME BASED RULE") {
            dispatch(todosAction.resetTrigerConditionState());
        }
    };
    // action setting fields -------------==================
    const handleTriggervalue = (event) => {
        setTriggervalue(event.target.value);
        if (event.target.value !== "") {
            setTriggervalueError(false);
        }
    };
    const handleTriggerCriteria = (event) => {
        setTriggerCriteria(event.target.value);
        if (event.target.value !== "") {
            setTriggerCriteriaError(false);
        }
    };
    function funcTolerance(event) {
        if (event.target.value !== "") {
            setToleranceError(false);
        }
        if (event.target.value < 5 || event.target.value > 480) {
            setToleranceRangeError(true);
        } else {
            setToleranceRangeError(false);
        }
        setTolerance(event.target.value);
    }

    const handleOpenClosepark = (elem) => {
        sethandleOpenClosePark(elem.target.value);
        if (elem.target.value !== "") {
            setActionError(false);
        }
    };

    const summaryRef = useRef(null);

    const handleOnChangeSmsMessage = (elem) => {
        setSmsMessage(elem.target.value);
    };

    const prepareUserNameList = (value) => {
        if (value.length > 2) {
            smartParkService.get("api/UserName/" + value).then((res) => {
                if (res) {
                    setdropMobileNumbersList(res.data.data);
                } else {
                }
            });
        } else {
            setdropMobileNumbersList([]);
        }
    };
    const prepareUserEmailList = (value) => {
        if (value.length > 2) {
            smartParkService
                .get("api/UserName/" + value.toLowerCase())
                .then((res) => {
                    if (res) {
                        setdropEmailsList(res.data.data);
                    } else {
                    }
                });
        } else {
            setdropEmailsList([]);
        }
    };

    const onChange = (value) => {
        setEmailBody(value);
        const emailBodyString = value
            .toString("html")
            .replace(/<(?:.|\n)*?>/gm, "<br/>");
    };

    function randvalue(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const randomNumber = randvalue(1, 100);

    // datainput ------=======================================================
    const [getBasicSetting, sendBasicSetting] = React.useState([]);

    // validation==========================

    function validation(eid) {
        if (eid === "1") {
            setRuleNameError(false);
            setDescriptionError(false);
            setPriorityError(false);
            // Validate Rule Name
            if (getRulename === "" || getRulename.trim() === "") {
                setRuleNameError(true);
                setRuleName("");
            }
            // Validate Rule Description
            if (
                getonDescriptionMessage === "" ||
                getonDescriptionMessage.trim() === ""
            ) {
                setDescriptionError(true);
                setonDescriptionMessage("");
            }

            // Validate Priority Number
            if (priorityno === "") {
                setPriorityError(true);
            }

            // Check if any errors exist
            if (
                getRulename === "" ||
                getonDescriptionMessage === "" ||
                priorityno === ""
            ) {
                return; // Do not proceed if there are validation errors
            }
        }
        if (eid === "2") {
            setStartDateError(false);
            setEndDateError(false);

            if ((getOnOFFValue === false && startDate === "") || startDate === null) {
                setStartDateError(true);
            }
            // Validate End Date (only if Off)
            if ((getOnOFFValue === false && endDate === "") || endDate === null) {
                setEndDateError(true);
            }
            // Check if any errors exist
            if (
                getOnOFFValue === false &&
                (startDate === "" || endDate === "" || endDate === null)
            ) {
                return; // Do not proceed if there are validation errors
            }
        }
        if (eid === "3") {
            // Validate Days of Week
            if (getDayOfWeek.length === 0) {
                setDayOfWeekError(true);
            }
            // Validate Start Time
            if (timevalue === "") {
                setStartTimeError(true);
                setStartTimeErrorText("Please Enter Start Time");
            }

            // Validate End Time
            if (endtimevalue === "") {
                setEndTimeError(true);
                setEndTimeErrorText("Please Enter End Time");
            }

            // Check if any errors exist
            if (
                getDayOfWeek.length === 0 ||
                timevalue === "" ||
                endtimevalue === ""
            ) {
                return; // Do not proceed if there are validation errors
            }
        }
        if (eid === "4") {
            setTrigerstrategyError(false);
            setIsTriggerStrategySelected(false);

            if (getTrigerstrategy === "") {
                // Reset TriggerStrategy error when a new option is selected
                setTrigerstrategyError(true);
            }
        }
        if (eid === "5") {
            // Reset all error flags
            setTrigerstrategyError(false);
            setParkNameError(false);
            setParkDeviceTypeError(false);
            setParkDeviceIdError(false);
            setTriggervalueError(false);
            setTriggerCriteriaError(false);
            setToleranceError(false);
            // Validate TriggerStrategy
            if (!getTrigerstrategy) {
                setTrigerstrategyError(true);
            }

            // Validate other fields as needed...

            // Validate Park Name
            if (!getparkName) {
                setParkNameError(true);
            }

            // Validate Park Device Type
            if (!getparkDeviceType) {
                setParkDeviceTypeError(true);
            }

            // Validate Park Device Id
            if (!getparkDeviceId) {
                setParkDeviceIdError(true);
            }

            // Validate Triggervalue
            if (!getTriggervalue) {
                setTriggervalueError(true);
            }

            // Validate Trigger Criteria
            if (!getTriggerCriteria) {
                setTriggerCriteriaError(true);
            }

            // Validate Tolerance
            if (getTolerance === "") {
                setToleranceError(true);
            }

            // Check if any errors exist
            if (
                trigerstrategyError ||
                parkNameError ||
                parkDeviceTypeError ||
                parkDeviceIdError ||
                triggervalueError ||
                triggerCriteriaError ||
                toleranceError
            ) {
                return; // Do not proceed if there are validation errors
            }
        }
        if (eid === "6") {
            // Reset error flags
            setActionParkNameError(false);
            setActionDeviceTypeError(false);
            setActionDeviceError(false);
            setActionError(false);

            // Validate Park Name
            if (gethandleParkId === "") {
                setActionParkNameError(true);
            }

            // Validate Device Type
            if (gethandleParkDeviceType === "") {
                setActionDeviceTypeError(true);
            }

            // Validate Device
            if (gethandleParkDevice === "") {
                setActionDeviceError(true);
            }

            // Validate Action
            if (gethandleOpenClosePark === "") {
                setActionError(true);
            }

            // Check if any errors exist
            if (
                gethandleParkId === "" ||
                gethandleParkDeviceType === "" ||
                gethandleParkDevice === "" ||
                gethandleOpenClosePark === ""
            ) {
                return; // Do not proceed if there are validation errors
            }
        }
        if (eid === "email") {
            const emailHtmlContent = emailBody.toString("html");
            const emptyParagraphRegex = /^<p>(&nbsp;|\s)*<\/p>$/;
            if (
                EmailsList?.length === 0 ||
                !emailHtmlContent ||
                emailHtmlContent.trim() === "" ||
                emailHtmlContent.trim() === "<p><br></p>" ||
                emptyParagraphRegex.test(emailHtmlContent.trim())
            ) {
                setEmailError(true);
                console.log("Email body");
            } else {
                setEmailError(false);
                console.log("Email body contains text.");
            }
        }
        if (eid === "sms") {
            setSmsError(false);
            if (MobileNumbersList?.length === 0 || getSmsMessage.trim() === "") {
                // SMS validation: Check if recipients and SMS message are filled
                setSmsError(true);
            }
        }
        if (eid === "smsemail") {
            setSmsError(false);
            setEmailError(false);
            const emailHtmlContent = emailBody.toString("html");
            const emptyParagraphRegex = /^<p>(&nbsp;|\s)*<\/p>$/;
            if (
                EmailsList?.length === 0 ||
                !emailHtmlContent ||
                emailHtmlContent.trim() === "" ||
                emailHtmlContent.trim() === "<p><br></p>" ||
                emptyParagraphRegex.test(emailHtmlContent.trim())
            ) {
                // Email validation: Check if recipients and email body are filled
                setEmailError(true);
            }
            if (MobileNumbersList?.length === 0 || getSmsMessage.trim() === "") {
                // SMS validation: Check if recipients and SMS message are filled
                setSmsError(true);
            }
        }
    }

    // validation==========================

    const handleAdddata = async (e, expandeddata, btnaction) => {
        validation(e);
        const uniqueid = uuid();
        const uniqueconditionid = uuid();
        const data = [
            {
                RuleName: getRulename,
                Rule_Description: getonDescriptionMessage,
                priorityno: priorityno,
                RuleIsAlwaysOn: getOnOFFValue,
                RuleValidityStartDate: startDate,
                RuleValidityEndDate: endDate,
                OperationalTime: [
                    {
                        DayofWeek: getDayOfWeek,
                        StartTime: timevalue,
                        EndTime: endtimevalue,
                    },
                ],
                TriggerStrategy: getTrigerstrategy,
                Conditions: getparkDeviceId.map((id) => {
                    return {
                        _id: uuid(),
                        ParkID: getparkName,
                        ParkName: getConditionParkName,
                        Devicetype: getparkDeviceType,
                        DeviceID: id,
                        DeviceName: findDeviceName(id),
                        TriggerValue: getTriggervalue,
                        TriggerCriteria: getTriggerCriteria,
                        Tolerance: getTolerance,
                    };
                }),
                Timezone: getTimeZoneValue,
                Action: [
                    {
                        _id: uniqueid,
                        ParkID: gethandleParkId,
                        ParkName: getActionpartkname,
                        Devicetype: gethandleParkDeviceType,
                        DeviceID: gethandleParkDevice,
                        DeviceName: getActiondevicetypename,
                        Action: gethandleOpenClosePark,
                    },
                ],
                sendSMSNotification: getsmsOnOFFValue,
                SMSRecipients: MobileNumbersList,
                SMSBody: getSmsMessage,
                sendEmailNotification: getemailOnOFFValue,
                EmailRecipients: EmailsList,
                EmailBody: emailBody,
            },
        ];

        sendBasicSetting(data);
        if (e === "1" && expandeddata) {
            if (
                getRulename !== "" &&
                getonDescriptionMessage !== "" &&
                priorityno !== ""
            ) {
                setExpanded(expandeddata.replace(e, parseInt(e) + 1));
                dispatch(todosAction.addBasicsSettings(data));
            }
        }
        if (e === "2" && expandeddata) {
            if (
                getOnOFFValue === false &&
                startDateError === false &&
                endDateError === false &&
                startDate !== null &&
                endDate !== null
            ) {
                setExpanded(expandeddata.replace(e, parseInt(e) + 1));
                dispatch(todosAction.addValiditySettings(data));
                localStorage.setItem("addfields", e + "~" + randomNumber);
            } else if (getOnOFFValue === true) {
                setExpanded(expandeddata.replace(e, parseInt(e) + 1));
                dispatch(todosAction.addValiditySettings(data));
                localStorage.setItem("addfields", e + "~" + randomNumber);
            }
        }
        if (e === "3" && expandeddata) {
            if (
                dayOfWeekError === false &&
                getStartTimeErrorText == "" &&
                getendTimeErrorText == "" &&
                timevalue !== "" &&
                endtimevalue !== "" &&
                getDayOfWeek.length > 0
            ) {
                setExpanded(expandeddata.replace(e, parseInt(e) + 1));
                dispatch(todosAction.addOperationalSettings(data));
            }
        }
        if (e === "4" && expandeddata) {
            setTrigger(false);
            try {
                if (trigerstrategyError === false && getTrigerstrategy !== "") {
                    setShowCondition(getTrigerstrategy);
                    let strategy = getTrigerstrategy && getTrigerstrategy;
                    const newArray =
                        conditionTrigerConditionSetting.length > 0 &&
                        conditionTrigerConditionSetting.map((item) => {
                            console.log(item);
                            return {
                                ParkID: item.ParkID,
                                DeviceID: item.DeviceID,
                                TriggerCriteria: item.TriggerCriteria,
                                TriggerValue: item.TriggerValue,
                            };
                        });

                    const isMatchFound =
                        conditionTrigerConditionSetting.length > 1 &&
                        conditionTrigerConditionSetting.some((item) => {
                            if (
                                (strategy === "ALL" &&
                                    item.TriggerValue.toLowerCase() === "open") ||
                                (strategy === "ALL" &&
                                    item.TriggerValue.toLowerCase() === "close")
                            ) {
                                return newArray.some((comparisonItem) => {
                                    return (
                                        item.ParkID === comparisonItem.ParkID &&
                                        item.DeviceID === comparisonItem.DeviceID &&
                                        item.TriggerCriteria === comparisonItem.TriggerCriteria &&
                                        item.TriggerValue !== comparisonItem.TriggerValue
                                    );
                                });
                            } else {
                                return false;
                            }
                        });
                    console.log(isMatchFound);
                    if (!isMatchFound) {
                        if (getTrigerstrategy === "TIME BASED RULE") {
                            setExpanded(expandeddata.replace(e, parseInt(e) + 1));
                        }
                        dispatch(todosAction.addConditionTrigerSettings(data));
                    } else {
                        const mes = (
                            <div className="toastsuccess">
                                <div className="erroricon">
                                    <BiSolidXCircle />
                                </div>
                                <div className="toastmessage">
                                    <b>Error</b>
                                    <div>
                                        The currently selected condition(s) are not compatible with
                                        'All' trigger strategy, multiple condition(s) on park,
                                        field, gate and courts are not supported with 'All' strategy
                                        type.
                                    </div>
                                </div>
                            </div>
                        );
                        toast.error(mes, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            className: "errorcontainer",
                        });
                    }
                    setTrigger(true);
                }
            } catch (error) {
                console.log("error in adding to condition ", error);
            }
        }
        if (e === "5" && expandeddata) {
            setRelatedConditionActions(false);
            if (
                parkNameError === false &&
                parkDeviceTypeError === false &&
                parkDeviceIdError === false &&
                triggervalueError === false &&
                triggerCriteriaError === false &&
                toleranceError === false &&
                trigerstrategyError === false &&
                toleranceRangeError === false &&
                getTrigerstrategy !== "" &&
                getTolerance !== "" &&
                getTriggervalue !== "" &&
                getTrigger === true
            ) {
                localStorage.setItem("addupdateconditionID", getUpdateConditionID);
                getparkDeviceId.forEach((id, index) => {
                    dispatch(
                        todosAction.addConditionTrigerConditionSettings({
                            data: data,
                            index: index,
                        })
                    );
                });
                deletethisfields("conditionmore");

                setRelatedConditionActions(true);
                localStorage.setItem("addfields", e + "~" + randomNumber);
                if (
                    getTrigerstrategy !== "TIME BASED RULE" &&
                    (!btnaction || btnaction === "")
                ) {
                    console.log("<============== ffdsfdf============ > ");
                    setExpanded(expandeddata.replace(e - 1, parseInt(e)));
                    setCleareditoverlay(true);
                    setUpdateConditionID("");
                } else {
                    console.log("ffdsfdf============ > ");
                    deletethisfields(btnaction);
                }
            }
        }
        if (e === "6" && expandeddata) {
            setRelatedConditionActions(false);
            if (
                ActionparkNameError === false &&
                ActiondeviceTypeError === false &&
                ActiondeviceError === false &&
                actionError === false &&
                gethandleParkId !== "" &&
                gethandleParkDeviceType !== "" &&
                gethandleParkDevice !== "" &&
                gethandleOpenClosePark !== ""
            ) {
                localStorage.setItem("addupdateID", getUpdateActionID);
                dispatch(todosAction.addActionSettings(data));
                setRelatedConditionActions(true);
                localStorage.setItem("addfields", e + "~" + randomNumber);
                if (!btnaction || btnaction === "") {
                    setExpanded(expandeddata.replace(e - 1, parseInt(e)));
                    setCleareditoverlay(true);
                    setUpdateActionID("");
                    deletethisfields("5");
                } else {
                    deletethisfields("5");
                }
            }
        }
        if (e === "sms") {
            if (smsError === false) {
                dispatch(todosAction.addsmsNotificationSettings(data));
            }
        }
        if (e === "email") {
            console.log("emailBody ", emailBody);
            const emailHtmlContent = emailBody.toString("html");
            console.log("emailHtmlContent ", emailHtmlContent);
            const emptyParagraphRegex = /^<p>(&nbsp;|\s)*<\/p>$/;
            if (
                emailError === false &&
                EmailsList?.length > 0 &&
                emailHtmlContent &&
                emailHtmlContent.trim() !== "" &&
                emailHtmlContent.trim() !== "<p><br></p>" &&
                !emptyParagraphRegex.test(emailHtmlContent.trim())
            ) {
                dispatch(todosAction.addemailNotificationSettings(data));
            }
        }
        if (e === "smsemail") {
            const emailHtmlContent = emailBody.toString("html");
            const emptyParagraphRegex = /^<p>(&nbsp;|\s)*<\/p>$/;
            if (
                emailError === false &&
                smsError === false &&
                EmailsList?.length > 0 &&
                emailHtmlContent &&
                emailHtmlContent.trim() !== "" &&
                emailHtmlContent.trim() !== "<p><br></p>" &&
                !emptyParagraphRegex.test(emailHtmlContent.trim()) &&
                MobileNumbersList?.length > 0 &&
                getSmsMessage.trim() !== ""
            ) {
                dispatch(todosAction.addsmsNotificationSettings(data));
                dispatch(todosAction.addemailNotificationSettings(data));
            }
        }
        if (e === "disablesmsemail") {
            // if (emailError === false && smsError === false) {
            dispatch(todosAction.addsmsNotificationSettings(data));
            dispatch(todosAction.addemailNotificationSettings(data));
            // }
        }
        if (e === "canceledit") {
            setUpdateActionID("");
            setActionParktype([]);
            setActionParkDevicetype([]);
            sethandleParkDevice("");
            sethandleOpenClosePark("");
            setActiondevicetypename([]);
            sethandleParkDeviceType("");
            sethandleParkId("");
            setActionpartkname([]);
            setUpdateConditionID("");
            setParkDevicetype([]);
            setParktype([]);
            setparkName("");
            setConditionParkName([]);
            setparkDeviceId([]);
            setTriggervalue("");
            setTriggerCriteria("");
            setTolerance("");
            setparkDeviceType("");
            setCleareditoverlay(true);
        }
        if (e === "empty") {
            setUpdateActionID("");
            setUpdateConditionID("");
            dispatch(todosAction.resetState());
            setsmsOnOFFValue(false);
            setemailOnOFFValue(false);
            setOnOFFValue(false);
            setonDescriptionMessage("");
            setRuleName("");
            setSummaryhead(false);
            setStartDate(new Date());
            setEndDate(new Date());
            setDayOfWeek([]);
            onTimeChange("");
            oneTimeendChange("");
            setTrigerstrategy("");
            setShowCondition("");
            setparkDeviceType("");
            setparkDeviceId([]);
            setTriggervalue("");
            setTriggerCriteria("");
            setTolerance("");
            sethandleParkId("");
            sethandleOpenClosePark("");
            setEmailsList([]);
            setMobileNumbersList([]);
            setSmsMessage("");
            // setruleBlockhour("12");
            // setruleBlockmin("0");
            setparkName("");
            setPersonName([]);
            sethandleParkDeviceType("");
            sethandleParkDevice("");
            setRuleError(false);
            // setIsConditionsMet(false);
            // setIsActionsMet(false);
            setSummrydataError(false);
            setoperationalSettingError(false);
            setactionSettingError(false);
            setconditionTrigerConditionSettingError(false);
            setconditionTrigerSettingError(false);
            setemailnotificationSettingError(false);
            setsmsnotificationSettingError(false);
            setStartTimeError(false);
            setStartTimeErrorText("");
            setEndTimeError(false);
            setEndTimeErrorText("");
            setblockDurationSettingError(false);
            setvaliditySettingError(false);
            setRefresh(!refresh);
        }
    };

    const deletethisfields = (e) => {
        if (e === "1") {
            setonDescriptionMessage("");
            setRuleName("");
        }
        if (e === "2") {
            setOnOFFValue(false);
            setStartDate(new Date());
            setEndDate(new Date());
        }
        if (e === "3") {
            setStartTimeError(false);
            setEndTimeError(false);
            setStartTimeErrorText("");
            setEndTimeErrorText("");
            setDayOfWeek([]);
            onTimeChange("");
            oneTimeendChange("");
        }
        if (e === "4") {
            setTrigerstrategy("");
            setparkDeviceType("");
            setparkDeviceId([]);
            setTriggervalue("");
            setTriggerCriteria("");
            setTolerance("");
            setparkName("");
            setShowCondition("");
        }
        if (e === "5") {
            sethandleParkId("");
            sethandleOpenClosePark("");
            setPersonName([]);
            sethandleParkDeviceType("");
            sethandleParkDevice("");
        }
        if (e === "smsemailnotify") {
            setsmsOnOFFValue(false);
            setemailOnOFFValue(false);
            setMobileNumbersList([]);
            setSmsMessage("");
            setEmailsList([]);
            setEmailBody("");
        }
        if (e === "smsnotify") {
            setsmsOnOFFValue(false);
            setMobileNumbersList([]);
            setSmsMessage("");
        }
        if (e === "emailnotify") {
            setemailOnOFFValue(false);
            setEmailsList([]);
            setEmailBody("");
        }
        if (e === "clearoverlay") {
            setCleareditoverlay(false);
        }
        if (e === "conditionmore") {
            setparkDeviceType("");
            setparkDeviceId([]);
            setTriggervalue("");
            setTriggerCriteria("");
            setTolerance("");
            setparkName("");
        }
    };

    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);
    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "RuleEngine";
        });
        setpermission(Dpermissions[0]);
    };

    const routeAddRule = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (permission.create == 1) {
            let path = "createnewRule";
            // setnewrule(true);
            history.push(path);
        } else {
            alert("You do not have permission to create rule");
        }
    };
    const rulepage = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (permission.create == 1) {
            let path = "Rules";
            // setnewrule(true);
            history.push(path);
        } else {
            alert("You do not have permission to create rule");
        }
    };

    const handlePrintClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const printStyles = `
        <style>
        @media print {
            .samebg img {
                display: none;
            }

            .deleteicon img {
                display: none;
            }

            .printpageview {
                flex-direction: column;
            }

            .printpageview .col-md-6 {
                margin: 0 !important;
            }
            .indiviualdeleteone{
                display:none;
            }
        }
        </style>
    `;
        const printContents = `
        <h5>Rule Preview</h5>
        ${summaryRef.current.innerHTML}
    `;

        // Create a new window for printing
        const printWindow = window.open("", "_blank");

        // Write the print styles and contents to the new window
        printWindow.document.write(printStyles);
        printWindow.document.write(printContents);

        // Close the document writing to ensure everything is loaded
        printWindow.document.close();

        // Wait for the content to be loaded before triggering print
        printWindow.onload = () => {
            printWindow.print();
            printWindow.close();
        };
    };

    // new rule create api =========================

    // related rule list  =========================

    const DeleteRule = (deleteruleid) => {
        var deleteruleIDs = [];
        deleteruleIDs.push(deleteruleid);
        let url = "api/park/ruleengine/deleterules";
        var restoken = GenerateEncryptedToken();
        var requestBody = {
            RuleIDs: deleteruleIDs,
        };
        smartParkService
            .post(url, requestBody, {
                headers: {
                    "x-access-token": restoken,
                    userid: userid,
                },
            })
            .then((res) => {
                if (res) {
                    if (res.data.status == 200) {
                        const mes = (
                            <div className="toastsuccess">
                                <div className="toasticon">
                                    <BiSolidCheckCircle />
                                </div>
                                <div className="toastmessage">
                                    <b>Success</b>
                                    <div>{res.data.message}</div>
                                </div>
                            </div>
                        );
                        toast.success(mes, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            className: "toastcontainer",
                        });

                        setDeleterefresh(true);
                        // searchnewrule();
                    }
                }
            });
    };

    function enabledisablerule(toggleruleid, togglestatus) {
        var statusvalue;
        if (togglestatus === false) {
            statusvalue = 0;
        } else {
            statusvalue = 1;
        }
        var enabledisableRuleIds = [];
        enabledisableRuleIds.push(toggleruleid);
        setdisableRuleid(enabledisableRuleIds);
        setdisableRulestatus(statusvalue);
        setOpen(true);
    }

    function viewrule(ruledataid) {
        // if (permission.create == 1) {
        let path = "rulelistview";
        // setnewrule(true);
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${ruledataid}` }, // Adding state data
        });
        // } else {
        //   alert("You do not have permission to create rule");
        // }
    }
    function EditRule(ruledataid) {
        // if (permission.create == 1) {
        let path = "editrulecreate";
        // setnewrule(true);
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${ruledataid}` }, // Adding state data
        });
        // } else {
        //   alert("You do not have permission to create rule");
        // }
    }
    function groupByDayOfWeek(operationalTime) {
        const groupedData = {};
        operationalTime.forEach((dayData) => {
            const dayOfWeek = dayData.DayofWeek;
            if (!groupedData[dayOfWeek]) {
                groupedData[dayOfWeek] = [];
            }
            groupedData[dayOfWeek].push(dayData);
        });
        return Object.values(groupedData);
    }

    function Dayweekconvert(dayweek) {
        if (dayweek === 0) {
            return "Sun";
        }
        if (dayweek === 1) {
            return "Mon";
        }
        if (dayweek === 2) {
            return "Tue";
        }
        if (dayweek === 3) {
            return "Wed";
        }
        if (dayweek === 4) {
            return "Thu";
        }
        if (dayweek === 5) {
            return "Fri";
        }
        if (dayweek === 6) {
            return "Sat";
        }
    }
    function simpledate(date) {
        // console.log(date);
        try {
            const dateObject = new Date(date);
            // Format the date as "month-day-year" string
            const formattedString = `${padZero(dateObject.getMonth() + 1)}-${padZero(
                dateObject.getDate()
            )}-${dateObject.getFullYear()}`;
            // Helper function to pad single digits with leading zeros
            function padZero(number) {
                return number.toString().padStart(2, "0");
            }
            // console.log(formattedString);
            return formattedString;
        } catch {
            return date + " (invalid)";
        }
    }

    const funclistpage = (event) => {
        event.preventDefault();
        event.stopPropagation();
        let path = "/Rules";
        history.push(path);
    };

    const selectAllDays = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setStartTimeError(false);
        setEndTimeError(false);
        setStartTimeErrorText("");
        setEndTimeErrorText("");
        setDayOfWeekError(false);
        setweekenddays(false);
        setweekdays(false);
        setalldays(true);
        setDayOfWeek(names);
        onTimeChange("00:00");
        oneTimeendChange("23:59");
    };
    // Function to select weekdays (Monday to Friday)
    const selectWeekdays = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setStartTimeError(false);
        setEndTimeError(false);
        setStartTimeErrorText("");
        setEndTimeErrorText("");
        setDayOfWeekError(false);
        setweekenddays(false);
        setalldays(false);
        setweekdays(true);
        setDayOfWeek(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]);
        onTimeChange("00:00");
        oneTimeendChange("23:59");
    };
    // Function to select weekend days (Saturday and Sunday)
    const selectWeekendDays = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setStartTimeError(false);
        setEndTimeError(false);
        setStartTimeErrorText("");
        setEndTimeErrorText("");
        setDayOfWeekError(false);
        setalldays(false);
        setweekdays(false);
        setweekenddays(true);
        setDayOfWeek(["Saturday", "Sunday"]);
        onTimeChange("00:00");
        oneTimeendChange("23:59");
    };

    const BasicTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Rule Name :-</strong>{" "}
                <span className="tooltiptext">
                    "Rule Name" is where you give your rule a unique title, so that you
                    can easily identify it and keep your rules organized.
                </span>
            </div>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Rule Description :-</strong>{" "}
                <span className="tooltiptext">
                    "Rule Description" is where you briefly explain what your rule does;
                    making it easier for you and others to understand the rule.
                </span>
            </div>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Rule Priority :-</strong>{" "}
                <span className="tooltiptext">
                    "Rule Priority" is a number that tells the system which rule should be
                    followed first when you have multiple rules impacting an action. A
                    higher number means higher the priority.
                </span>
            </div>
        </Tooltip>
    );
    const ValidityTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Rule always on :-</strong>{" "}
                <span className="tooltiptext">
                    The "Rule Always On" feature employs a toggle button. By default, it's
                    off, limiting the rule's validity to a specific date range. Users can
                    activate it to apply the rule to all dates without restrictions,
                    providing flexibility in rule execution based on their needs.
                </span>
            </div>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Select Start and End Date :-</strong>{" "}
                <span className="tooltiptext">
                    Set a start date and an end date to define a specific period during
                    which the rule will be in effect. Once the end date is crossed, the
                    rule will no longer be active.
                </span>
            </div>
        </Tooltip>
    );
    const OperationalTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Select Days of Week :-</strong>{" "}
                <span className="tooltiptext">
                    Selecting one or more days on which you want rule to operate .
                </span>
            </div>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Select Start and End Time :-</strong>{" "}
                <span className="tooltiptext">
                    Define the time when the rule will take effect on the selected day,
                    specifying both the start and end times.
                </span>
            </div>
        </Tooltip>
    );
    const triggerTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Trigger Strategy :-</strong>{" "}
                <span className="tooltiptext">
                    The "Trigger Strategy" is used to specify rule trigger logic. Users
                    can choose from three options:
                    <ul>
                        <li>
                            <strong>All:</strong> The rule triggers only when all specified
                            conditions are met. All conditions must be true for the rule to
                            activate.
                        </li>
                        <li>
                            <strong>Any:</strong> The rule triggers if any of the specified
                            conditions is true.
                        </li>
                        <li>
                            <strong>Based On Time:</strong> The rule triggers solely based on
                            a set window of time. No other specific criteria is specified,
                            making it time-dependent.
                        </li>
                    </ul>
                </span>
            </div>
        </Tooltip>
    );
    const conditionTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Condition settings :-</strong>{" "}
                <span className="tooltiptext">
                    For setting rule conditions, there are several fields to set:
                    <ul>
                        <li>
                            <strong>Park Name :</strong> Select the park for the rule
                            condition.
                        </li>
                        <li>
                            <strong>Device Type : </strong> Select the park's device type.
                        </li>
                        <li>
                            <strong>Device : </strong> Select a specific device within the
                            selected type.
                        </li>
                        <li>
                            <strong>Trigger State/Value :</strong> It depends on the device
                            type. For gates and such, it's open or close state. For sensors,
                            it's a value (number) that you need to type in.
                        </li>
                        <li>
                            <strong>Trigger Criteria : </strong> Decide if it should be
                            ‘above’, ‘below’, or ‘exactly’ the same as the set trigger value
                            if numeric or ‘exactly’ if trigger state was like ‘close’ or
                            ‘open’.
                        </li>
                        <li>
                            <strong>Duration : </strong> Threshold condition has to be true
                            for the duration to trigger the rule action. The threshold
                            duration range is between 5 minutes to 480 minutes.
                        </li>
                    </ul>
                </span>
            </div>
        </Tooltip>
    );
    const ActionTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Action settings :-</strong>{" "}
                <span className="tooltiptext">
                    Configure actions using these fields:
                    <ul>
                        <li>
                            <strong>Park Name :</strong> Select the park where you want to
                            apply the action.
                        </li>
                        <li>
                            <strong>Device Type : </strong> Select the type of device in the
                            selected park, such as Park, Gates, or Fields.
                        </li>
                        <li>
                            <strong>Device : </strong> Pick a specific device within the
                            selected device type.
                        </li>
                        <li>
                            <strong>Action : </strong> Define the action to be performed for
                            the selected park, device type, and device. The action is usually
                            ‘open’ or ‘close’.
                        </li>
                    </ul>
                </span>
            </div>
        </Tooltip>
    );
    const NotificationTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Notification Settings :-</strong>{" "}
                <span className="tooltiptext">
                    Configure notifications based on rule triggers.
                    <ul>
                        <li>
                            <strong>Notification Toggle :</strong> Enable or disable
                            notifications using a toggle button, independently for Email and
                            SMS.
                        </li>
                        <li>
                            <strong>Email addresses :</strong> Specify email addresses to
                            notify to when the rule triggers.
                        </li>
                        <li>
                            <strong>Email Message :</strong> Type in the custom email message.
                        </li>
                        <li>
                            <strong>Phone Numbers for SMS Notification :</strong> Specify
                            phone numbers to notify to via SMS when the rule triggers.
                        </li>
                        <li>
                            <strong>SMS Message :</strong> Type in the custom SMS message.
                        </li>
                    </ul>
                </span>
            </div>
        </Tooltip>
    );

    /* editable action and condition   */
    const handleActionConditionfunc = (e, settingname) => {
        try {
            setUpdateActionID("");
            setUpdateConditionID("");
            let data = e[0];
            if (settingname === "action") {
                setActionParkNameError(false);
                setActionDeviceTypeError(false);
                setActionDeviceError(false);
                setActionError(false);
                setExpanded("panel5");
                setUpdateConditionID("");
                setparkName("");
                setparkDeviceId([]);
                setparkDeviceType("");
                setTolerance("");
                setTriggerCriteria("");
                setTriggervalue("");
                console.log(settingname);
                setUpdateActionID(data._id);
                sethandleParkId(data.ParkID);
                setActionpartkname(data.ParkName);
                let alldevices = getActionparkID;
                let storeParkname = {};
                if (data) {
                    storeParkname = alldevices[data.ParkID];
                }
                setActionParktype(
                    Object.keys(storeParkname).filter(
                        (category) =>
                            category === "Park" || storeParkname[category].length > 0
                    )
                );
                sethandleParkDeviceType(data.Devicetype);
                const seldevice = data.Devicetype;
                setActiondevicetypename(data.DeviceName);
                const filterdata =
                    storeParkname &&
                    Object.keys(storeParkname).filter(
                        (category) =>
                            category === "Park" || storeParkname[category].length > 0
                    );
                let dataitem = [];
                filterdata &&
                    filterdata.forEach((item) => {
                        console.log(item);
                        if (item === seldevice) {
                            if (Array.isArray(storeParkname[item])) {
                                dataitem = storeParkname[item];
                            } else {
                                dataitem = [storeParkname[item]];
                            }
                        }
                    });
                setActionParkDevicetype(dataitem);
                sethandleParkDevice(data.DeviceID);
                sethandleOpenClosePark(data.Action);
            }
            if (settingname === "condition") {
                setParkNameError(false);
                setTrigerstrategyError(false);
                setParkDeviceTypeError(false);
                setParkDeviceIdError(false);
                setTriggervalueError(false);
                setToleranceError(false);
                setTriggerCriteriaError(false);
                setToleranceRangeError(false);
                setExpanded("panel4");
                setUpdateActionID("");
                sethandleParkId("");
                sethandleParkDeviceType("");
                sethandleParkDevice("");
                sethandleOpenClosePark("");
                console.log("condtions ////////// ", e);
                console.log(
                    "conditionTrigerSetting ////////// ",
                    conditionTrigerSetting[0].TriggerStrategy
                );
                setUpdateConditionID(data._id);
                setTrigerstrategy(conditionTrigerSetting[0]?.TriggerStrategy);
                setShowCondition(conditionTrigerSetting[0]?.TriggerStrategy);
                setparkName(data.ParkID);
                setConditionParkName(data.ParkName);
                setTrigger(true);
                let alldevices = getSearchfilter;
                let storeParkname = {};
                const selectedParkName = data.ParkID;
                if (selectedParkName !== "") {
                    storeParkname = alldevices[selectedParkName];
                }
                setParktype(
                    Object.keys(storeParkname).filter(
                        (category) =>
                            category === "Park" || storeParkname[category].length > 0
                    )
                );
                setparkDeviceType(data.Devicetype);
                const seldevice = data.Devicetype;
                const park = getparks;
                let parkobj = [];
                park?.forEach((item) => {
                    if (item.id === data.ParkID) {
                        console.log(data.ParkID);
                        parkobj = item;
                    }
                });
                let devices = parkobj["device"] ?? [];
                let devicetype = devices[seldevice] ?? [];
                console.log("devicetype ", devicetype);
                if (!Array.isArray(devicetype)) {
                    let devicetypeone = [devicetype];
                    setParkDevicetype(devicetypeone);
                } else {
                    setParkDevicetype(devicetype);
                }
                setparkDeviceId([data.DeviceID]);
                setConditonparktype(data.DeviceName);
                setTriggervalue(data.TriggerValue);
                setTriggerCriteria(data.TriggerCriteria);
                setTolerance(data.Tolerance);
            }
        } catch (error) {
            setCleareditoverlay(true);
            setUpdateConditionID("");
            setUpdateActionID("");
            console.error("handlefunc ===> ", error);
        }
    };

    // related rule list  =========================
    return (
        <div className={classes.contentPage}>
            <section className="content-header">
                <h3 className="float-left">Create Rule</h3>
                <div className="float-right">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="#" onClick={rulepage}>
                                Home
                            </a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="#" className="active" onClick={routeAddRule}>
                                Rules
                            </a>
                        </li>
                    </ol>
                </div>
            </section>
            <section className="content py-2 backlistbtn">
                <button
                    className="btn-color btn-sm btn badge-pill backicon "
                    onClick={funclistpage}
                >
                    <TiArrowBack />
                    <span className="ml-2">Back To List Page </span>
                </button>
            </section>
            <section className="content py-1 editrightpadd">
                <div className="content-middle rules-middle w-100 hidebackground">
                    <Paper className={`accoclass ${classes.paper}`}>
                        <div className="form-body">
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12} className="mobilepadzero">
                                    <Accordion
                                        expanded={expanded === "panel1"}
                                        onChange={handleChange("panel1")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Basic Settings{" "}
                                                {getSummrydataError ? (
                                                    <span className="headerror">*</span>
                                                ) : (
                                                    <span
                                                        className={`${summrydata?.length > 0
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}{" "}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cardbox">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={BasicTooltip}
                                                className="tooltipoverlay"
                                            >
                                                <Button className="p-0 tooltipicon">
                                                    <HiMiniInformationCircle />
                                                </Button>
                                            </OverlayTrigger>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Rule Name
                                                        </span>
                                                        <input
                                                            type="text"
                                                            min={"1"}
                                                            value={getRulename}
                                                            onChange={(e) => {
                                                                funcRulename(e);
                                                            }}
                                                            className="form-control"
                                                            required
                                                        />
                                                        {ruleNameError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Enter The Rule Name
                                                            </p>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Rule Description
                                                        </span>
                                                        <textarea
                                                            className="fc-width100 p-2"
                                                            rows="3"
                                                            value={getonDescriptionMessage}
                                                            onChange={(e) => {
                                                                funcRuleDes(e);
                                                            }}
                                                            required
                                                        ></textarea>
                                                        {descriptionError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Enter Rule Description
                                                            </p>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Priority Number
                                                        </span>
                                                        <input
                                                            type="number"
                                                            min={"1"}
                                                            value={priorityno}
                                                            onChange={(e) => {
                                                                funcRulePriority(e);
                                                            }}
                                                            className="form-control"
                                                            required
                                                        />
                                                        {priorityError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Enter Priority Number
                                                            </p>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        className="ruleaddbtn"
                                                        variant="contained"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleAdddata("1", expanded);
                                                        }}
                                                    >
                                                        ADD
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel2"}
                                        onChange={handleChange("panel2")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Validity Settings{" "}
                                                {getvaliditySettingError ? (
                                                    <span className="headerror">*</span>
                                                ) : (
                                                    <span
                                                        className={`${validitySetting?.length > 0
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cardbox">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={ValidityTooltip}
                                                className="tooltipoverlay"
                                            >
                                                <Button className="p-0 tooltipicon">
                                                    <HiMiniInformationCircle />
                                                </Button>
                                            </OverlayTrigger>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <span className={`ruletime  ${classes.rulelabel}`}>
                                                        {" "}
                                                        Rule Always On{" "}
                                                    </span>{" "}
                                                    &nbsp; &nbsp;&nbsp;&nbsp;
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid
                                                        component="label"
                                                        container
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Grid item className="ruletime">
                                                            Off
                                                        </Grid>
                                                        <Grid item>
                                                            <AntSwitch
                                                                checked={getOnOFFValue}
                                                                value={getOnOFFValue}
                                                                onChange={handleOnOffChange}
                                                                name="checkedC"
                                                            />
                                                        </Grid>
                                                        <Grid item className="ruletime">
                                                            On
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {getOnOFFValue === false ? (
                                                    // <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <div className={classes.startdatecss}>
                                                            <span
                                                                className={`ruletime  ${classes.rulelabel}`}
                                                            >
                                                                Select Start Date
                                                            </span>
                                                            <DatePicker
                                                                // showTimeSelect
                                                                // dateFormat="MMMM d, yyyy "
                                                                selected={startDate}
                                                                selectsStart
                                                                startDate={startDate}
                                                                minDate={new Date()}
                                                                showYearDropdown
                                                                scrollableMonthYearDropdown
                                                                onChange={(date) => funcStartdate(date)}
                                                                className="form-control ruletime mobiledatepadd"
                                                                placeholderText="mm-day-yyyy"
                                                                customInput={<MaskedInput mask="11/11/1111" />}
                                                            />
                                                            {startDateError && (
                                                                <p style={{ color: "red" }}>
                                                                    * Please Enter Start Date
                                                                </p>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                ) : (
                                                    <></>
                                                )}
                                                {getOnOFFValue === false ? (
                                                    <Grid item xs={12} sm={6}>
                                                        <div className={classes.startdatecss}>
                                                            <span
                                                                className={`ruletime  ${classes.rulelabel}`}
                                                            >
                                                                Select End Date
                                                            </span>
                                                            <DatePicker
                                                                // showTimeSelect
                                                                // dateFormat="MMMM/d/yyyy"
                                                                selected={endDate}
                                                                selectsEnd
                                                                showYearDropdown
                                                                scrollableMonthYearDropdown
                                                                endDate={endDate}
                                                                minDate={
                                                                    startDate === "" || startDate === null
                                                                        ? new Date()
                                                                        : startDate
                                                                }
                                                                onChange={(date) => funcEnddate(date)}
                                                                className="form-control ruletime mobiledatepadd"
                                                                placeholderText="mm-day-yyyy"
                                                                customInput={<MaskedInput mask="11/11/1111" />}
                                                            />
                                                            {endDateError && (
                                                                <p style={{ color: "red" }}>
                                                                    * Please Enter End Date
                                                                </p>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                ) : (
                                                    <></>
                                                )}

                                                <Grid item xs={12}>
                                                    <Button
                                                        className="ruleaddbtn"
                                                        variant="contained"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleAdddata("2", expanded);
                                                        }}
                                                    >
                                                        ADD
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel3"}
                                        onChange={handleChange("panel3")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Operational Settings{" "}
                                                {getoperationalSettingError ? (
                                                    <span className="headerror">*</span>
                                                ) : (
                                                    <span
                                                        className={`${operationalSetting?.length > 0
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cardbox">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={OperationalTooltip}
                                                className="tooltipoverlay"
                                            >
                                                <Button className="p-0 tooltipicon">
                                                    <HiMiniInformationCircle />
                                                </Button>
                                            </OverlayTrigger>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <div className="weekday">
                                                        <span
                                                            id="demo-simple-select-outlined-label"
                                                            className={`ruletime `}
                                                        >
                                                            Days Of Week :
                                                        </span>
                                                        <Link
                                                            component="button"
                                                            variant="body1"
                                                            className={myclass.dayscss}
                                                            onClick={selectWeekdays}
                                                        >
                                                            <span>Select Weekdays Only</span>{" "}
                                                        </Link>
                                                        <Link
                                                            component="button"
                                                            variant="body1"
                                                            className={myclass.dayscss}
                                                            onClick={selectWeekendDays}
                                                        >
                                                            <span>Select Weekend Only</span>
                                                        </Link>
                                                        <Link
                                                            component="button"
                                                            variant="body1"
                                                            className={myclass.dayscss}
                                                            onClick={selectAllDays}
                                                        >
                                                            <span>Select All Days</span>{" "}
                                                        </Link>
                                                        <div className={myclass.weekdayborder}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ width: "100%" }}
                                                                className={classes.formControl}
                                                            >
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    multiple
                                                                    value={getDayOfWeek}
                                                                    onChange={(e) => funcDayofweek(e)}
                                                                    input={<Input />}
                                                                    MenuProps={MenuProps}
                                                                    className={myclass.outlinedayweek}
                                                                >
                                                                    {names?.map((name, idx) => (
                                                                        <MenuItem key={idx} value={name}>
                                                                            {name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        {dayOfWeekError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Select Days Of Week
                                                            </p>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <span className={`ruletime  ${classes.rulelabel}`}>
                                                        Select Start Time
                                                    </span>
                                                    <input
                                                        type="time"
                                                        value={timevalue}
                                                        onChange={FuncTimeChange}
                                                        className="form-control timer"
                                                        aria-label="Select Start Time"
                                                    />
                                                    {startTimeError && (
                                                        <p style={{ color: "red" }}>
                                                            * {getStartTimeErrorText}
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <span className={`ruletime  ${classes.rulelabel}`}>
                                                        Select End Time
                                                    </span>
                                                    <input
                                                        type="time"
                                                        value={endtimevalue}
                                                        onChange={FuncTimeendChange}
                                                        className="form-control timer"
                                                        aria-label="Select End Time"
                                                    />
                                                    {endTimeError && (
                                                        <p style={{ color: "red" }}>
                                                            * {getendTimeErrorText}
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        className="ruleaddbtn"
                                                        variant="contained"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleAdddata("3", expanded);
                                                        }}
                                                    >
                                                        ADD
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel4"}
                                        onChange={handleChange("panel4")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Conditional Settings{" "}
                                                {getconditionTrigerSettingError ||
                                                    (getTrigerstrategy !== "TIME BASED RULE" &&
                                                        getconditionTrigerConditionSettingError) ? (
                                                    <span className="headerror">*</span>
                                                ) : (
                                                    <span
                                                        className={`${(getTrigerstrategy !== "TIME BASED RULE" &&
                                                                conditionTrigerConditionSetting?.length > 0) ||
                                                                (getTrigerstrategy == "TIME BASED RULE" &&
                                                                    conditionTrigerSetting?.length > 0)
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="flex-column cardbox mobilemultiselect">
                                            <Grid container spacing={2}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={triggerTooltip}
                                                    className="tooltipoverlay"
                                                >
                                                    <Button className="p-0 tooltipicon">
                                                        <HiMiniInformationCircle />
                                                    </Button>
                                                </OverlayTrigger>
                                                <Grid item xs={12} md={12}>
                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        className={classes.formControl}
                                                    >
                                                        <div className="d-flex align-items-center ">
                                                            <span
                                                                id="demo-simple-select-outlined-label"
                                                                className={`ruletime  ${classes.rulelabel}`}
                                                            >
                                                                Trigger Strategy
                                                            </span>{" "}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                        </div>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={getTrigerstrategy}
                                                            onChange={(e) => {
                                                                handleTriggerstrategyChange(e);
                                                            }}
                                                        >
                                                            <MenuItem value="" disabled>
                                                                Select the TriggerStrategy{" "}
                                                            </MenuItem>
                                                            <MenuItem value="ALL">All</MenuItem>
                                                            <MenuItem value="ANY">Any</MenuItem>
                                                            <MenuItem value="TIME BASED RULE">
                                                                Time Based Rule
                                                            </MenuItem>
                                                        </Select>
                                                        {trigerstrategyError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Select a Trigger Strategy
                                                            </p>
                                                        )}
                                                    </FormControl>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className="ruleaddbtn"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("4", expanded);
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    </Grid>
                                                    <hr />
                                                </Grid>
                                            </Grid>
                                            {getShowCondition &&
                                                getShowCondition !== "TIME BASED RULE" ? (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className="position-relative"
                                                >
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={conditionTooltip}
                                                        className="tooltipoverlay"
                                                    >
                                                        <Button className="p-0 tooltipicon conditiontool">
                                                            <HiMiniInformationCircle />
                                                        </Button>
                                                    </OverlayTrigger>
                                                    <Grid item xs={12} md={6}>
                                                        <span
                                                            id="demo-simple-select-outlined-label"
                                                            className={`ruletime  ${classes.rulelabel}`}
                                                        >
                                                            Park Name
                                                        </span>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div className={myclass.weekdayborder}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ width: "100%" }}
                                                                className={classes.formControl}
                                                            >
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="parkiddemo"
                                                                    value={getparkName}
                                                                    onChange={handleparkChange}
                                                                    className={myclass.samepadd}
                                                                >
                                                                    {getparks?.map((data, idx) => (
                                                                        <MenuItem
                                                                            key={idx}
                                                                            value={data.id}
                                                                            style={getStyles(
                                                                                data.name,
                                                                                personName,
                                                                                theme
                                                                            )}
                                                                        >
                                                                            {data.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            {parkNameError && (
                                                                <p style={{ color: "red" }}>
                                                                    * Please Select a Park Name
                                                                </p>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <span
                                                            id="demo-simple-select-outlined-label"
                                                            className={`ruletime  ${classes.rulelabel}`}
                                                        >
                                                            Select the Device Types
                                                        </span>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div className={myclass.weekdayborder}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ width: "100%" }}
                                                                className={classes.formControl}
                                                            >
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="parkDevicetypedemo"
                                                                    value={getparkDeviceType}
                                                                    onChange={(e) => {
                                                                        handleparkType(e);
                                                                    }}
                                                                    className={myclass.samepadd}
                                                                >
                                                                    {getParktype?.map((name, idx) => (
                                                                        <MenuItem
                                                                            key={idx}
                                                                            value={name}
                                                                            style={getStyles(name, personName, theme)}
                                                                        >
                                                                            {name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            {parkDeviceTypeError && (
                                                                <p style={{ color: "red" }}>
                                                                    * Please Select a Device Type
                                                                </p>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                    {getparkDeviceType !== "Moisture" &&
                                                        getparkDeviceType !== "Rain" &&
                                                        getparkDeviceType !== "Flood" &&
                                                        getparkDeviceType !== "Counters" &&
                                                        getparkDeviceType !== "Batteries" ? (
                                                        <React.Fragment>
                                                            <Grid item xs={12} md={6}>
                                                                <span
                                                                    id="demo-simple-select-outlined-label"
                                                                    className={`ruletime  ${classes.rulelabel}`}
                                                                >
                                                                    Select one or more device
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <div className={myclass.weekdayborder}>
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        style={{ width: "100%" }}
                                                                        className={classes.formControl}
                                                                    >
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            multiple
                                                                            id="parkDevicetypedemo"
                                                                            value={getparkDeviceId}
                                                                            onChange={(e) => {
                                                                                handleparkDevices(e);
                                                                            }}
                                                                            className={myclass.samepadd}
                                                                            renderValue={(selected) =>
                                                                                selected
                                                                                    .map((id) => {
                                                                                        const selectedItem =
                                                                                            getParkDevicetype.find(
                                                                                                (item) =>
                                                                                                    item.FieldID === id ||
                                                                                                    item.ID === id
                                                                                            );
                                                                                        return selectedItem
                                                                                            ? selectedItem.FieldName ||
                                                                                            selectedItem.Name
                                                                                            : id;
                                                                                    })
                                                                                    .join(", ")
                                                                            }
                                                                        >
                                                                            {getParkDevicetype?.map((data, idx) => (
                                                                                <MenuItem
                                                                                    key={idx}
                                                                                    className={myclass.listItem}
                                                                                    value={
                                                                                        data.FieldID
                                                                                            ? data.FieldID
                                                                                            : data.ID
                                                                                    }
                                                                                >
                                                                                    <Checkbox
                                                                                        className={myclass.checkbox}
                                                                                        checked={
                                                                                            getparkDeviceId.indexOf(
                                                                                                data.FieldID
                                                                                                    ? data.FieldID
                                                                                                    : data.ID
                                                                                            ) > -1
                                                                                        }
                                                                                    />
                                                                                    <ListItemText
                                                                                        primary={
                                                                                            data.FieldName
                                                                                                ? data.FieldName
                                                                                                : data.Name
                                                                                        }
                                                                                    />
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    {parkDeviceIdError && (
                                                                        <p style={{ color: "red" }}>
                                                                            * Please Select a Device
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ width: "100%" }}
                                                                    className={classes.formControl}
                                                                >
                                                                    <div className="d-flex align-items-center ">
                                                                        <span
                                                                            id="demo-simple-select-outlined-label"
                                                                            className={`ruletime  ${classes.rulelabel}`}
                                                                        >
                                                                            Trigger Criteria
                                                                        </span>
                                                                    </div>
                                                                    <Select
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={getTriggerCriteria}
                                                                        onChange={(e) => {
                                                                            handleTriggerCriteria(e);
                                                                        }}
                                                                        label="TriggerCriteria"
                                                                    >
                                                                        <MenuItem value="">
                                                                            Select the Condition{" "}
                                                                        </MenuItem>
                                                                        <MenuItem value="exactly">Exactly</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                {triggerCriteriaError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please Select the Trigger Criteria
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ width: "100%" }}
                                                                    className={classes.formControl}
                                                                >
                                                                    <div className="d-flex align-items-center ">
                                                                        <span
                                                                            id="demo-simple-select-outlined-label"
                                                                            className={`ruletime  ${classes.rulelabel}`}
                                                                        >
                                                                            Trigger State
                                                                        </span>
                                                                    </div>
                                                                    <Select
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={getTriggervalue}
                                                                        onChange={(e) => {
                                                                            handleTriggervalue(e);
                                                                        }}
                                                                        label="TriggerValue"
                                                                    >
                                                                        <MenuItem value="Open">
                                                                            {getparkDeviceType.toLowerCase() !==
                                                                                "court"
                                                                                ? "Opened"
                                                                                : "Unoccupied"}
                                                                        </MenuItem>
                                                                        <MenuItem value="Close">
                                                                            {getparkDeviceType.toLowerCase() !==
                                                                                "court"
                                                                                ? "Closed"
                                                                                : "Occupied"}
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                {triggervalueError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please Select Trigger State
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                        </React.Fragment>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {getparkDeviceType === "Moisture" ||
                                                        getparkDeviceType === "Rain" ||
                                                        getparkDeviceType === "Flood" ||
                                                        getparkDeviceType === "Counters" ||
                                                        getparkDeviceType === "Batteries" ? (
                                                        <React.Fragment>
                                                            <Grid item xs={12} md={6}>
                                                                <span
                                                                    id="demo-simple-select-outlined-label"
                                                                    className={`ruletime  ${classes.rulelabel}`}
                                                                >
                                                                    Select one or more device
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <div className={myclass.weekdayborder}>
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        style={{ width: "100%" }}
                                                                        className={classes.formControl}
                                                                    >
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="parkDevicetypedemo"
                                                                            multiple
                                                                            value={getparkDeviceId}
                                                                            onChange={(e) => {
                                                                                handleparkDevices(e);
                                                                            }}
                                                                            className={myclass.samepadd}
                                                                            renderValue={(selected) =>
                                                                                selected
                                                                                    .map((id) => {
                                                                                        const selectedItem =
                                                                                            getParkDevicetype.find(
                                                                                                (item) => item.SensorID === id
                                                                                            );
                                                                                        return selectedItem
                                                                                            ? selectedItem.SensorName
                                                                                            : id;
                                                                                    })
                                                                                    .join(", ")
                                                                            }
                                                                        >
                                                                            {getParkDevicetype?.map((data, idx) => (
                                                                                <MenuItem
                                                                                    key={idx}
                                                                                    value={data.SensorID}
                                                                                    className={myclass.listItem}
                                                                                    style={getStyles(
                                                                                        data.SensorName,
                                                                                        personName,
                                                                                        theme
                                                                                    )}
                                                                                >
                                                                                    <Checkbox
                                                                                        className={myclass.checkbox}
                                                                                        checked={
                                                                                            getparkDeviceId.indexOf(
                                                                                                data.SensorID
                                                                                            ) > -1
                                                                                        }
                                                                                    />
                                                                                    <ListItemText
                                                                                        primary={data.SensorName}
                                                                                    />
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>

                                                                    {parkDeviceIdError && (
                                                                        <p style={{ color: "red" }}>
                                                                            * Please Select a Device
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ width: "100%" }}
                                                                    className={classes.formControl}
                                                                >
                                                                    <div className="d-flex align-items-center ">
                                                                        <span
                                                                            id="demo-simple-select-outlined-label"
                                                                            className={`ruletime  ${classes.rulelabel}`}
                                                                        >
                                                                            Trigger Criteria
                                                                        </span>
                                                                    </div>
                                                                    <Select
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={getTriggerCriteria}
                                                                        onChange={(e) => {
                                                                            handleTriggerCriteria(e);
                                                                        }}
                                                                        label="TriggerCriteria"
                                                                    >
                                                                        <MenuItem value="">
                                                                            Select the Condition{" "}
                                                                        </MenuItem>
                                                                        <MenuItem value="below">Below</MenuItem>
                                                                        <MenuItem value="above">Above</MenuItem>
                                                                        <MenuItem value="exactly">Exactly</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                {triggerCriteriaError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please Select the Trigger Criteria
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ width: "100%" }}
                                                                    className={classes.formControl}
                                                                >
                                                                    <div className="d-flex align-items-center ">
                                                                        <span
                                                                            id="demo-simple-select-outlined-label"
                                                                            className={`ruletime  ${classes.rulelabel}`}
                                                                        >
                                                                            Trigger Value
                                                                        </span>
                                                                    </div>
                                                                    <input
                                                                        type="number"
                                                                        min={"1"}
                                                                        value={getTriggervalue}
                                                                        className="form-control"
                                                                        onChange={(e) => {
                                                                            handleTriggervalue(e);
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                {triggervalueError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please Enter the Trigger Value
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                        </React.Fragment>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            style={{ width: "100%" }}
                                                            className={classes.formControl}
                                                        >
                                                            <div className="d-flex align-items-center ">
                                                                <span
                                                                    id="demo-simple-select-outlined-label"
                                                                    className={`ruletime  ${classes.rulelabel}`}
                                                                >
                                                                    Duration (<strong> in minutes </strong> )
                                                                </span>
                                                            </div>
                                                            <input
                                                                type="number"
                                                                min={"1"}
                                                                value={getTolerance}
                                                                onChange={(e) => {
                                                                    funcTolerance(e);
                                                                }}
                                                                className="form-control"
                                                            />
                                                        </FormControl>
                                                        {toleranceError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Enter Duration Time
                                                            </p>
                                                        )}
                                                        {toleranceRangeError && (
                                                            <p style={{ color: "red" }}>
                                                                * The threshold duration range is between 5
                                                                minutes to 480 minutes.
                                                            </p>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Button
                                                            className="ruleaddbtn"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("5", expanded);
                                                            }}
                                                        >
                                                            {getUpdateConditionID !== "" ? "Update" : "ADD"}
                                                        </Button>
                                                        {getUpdateConditionID !== "" ? (
                                                            <Button
                                                                className="ruleaddbtncancel ml-2"
                                                                variant="contained"
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    event.stopPropagation();
                                                                    handleAdddata("canceledit");
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={6} className="text-right">
                                                        <Button
                                                            className="ruleaddbtncancel"
                                                            variant="contained"
                                                            disabled={getUpdateConditionID}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("5", expanded, "conditionmore");
                                                            }}
                                                        >
                                                            MORE CONDITION
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <></>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel5"}
                                        onChange={handleChange("panel5")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel5bh-content"
                                            id="panel5bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Action Settings{" "}
                                                {getactionSettingError ? (
                                                    <span className="headerror"> * </span>
                                                ) : (
                                                    <span
                                                        className={`${actionSetting?.length > 0
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cardbox mobilemultiselect">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={ActionTooltip}
                                                className="tooltipoverlay"
                                            >
                                                <Button className="p-0 tooltipicon">
                                                    <HiMiniInformationCircle />
                                                </Button>
                                            </OverlayTrigger>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        className={`m-0  ${classes.formControl}`}
                                                    >
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Park Name
                                                        </span>
                                                        <Select
                                                            size="small"
                                                            name="fieldName"
                                                            label="Field Name"
                                                            variant="outlined"
                                                            value={gethandleParkId}
                                                            onChange={(e) => {
                                                                handleParkId(e);
                                                            }}
                                                            inputProps={{ "aria-label": "Field Name" }}
                                                            className="ruletime"
                                                        >
                                                            {getactionpark?.map((data, idx) => (
                                                                <MenuItem
                                                                    key={idx}
                                                                    value={data.id}
                                                                    park-name={data.name}
                                                                    style={getStyles(
                                                                        data.name,
                                                                        personName,
                                                                        theme
                                                                    )}
                                                                >
                                                                    {data.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {ActionparkNameError && (
                                                        <p style={{ color: "red" }}>
                                                            * Please Select Park Name
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        className={`m-0  ${classes.formControl}`}
                                                    >
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Device Type
                                                        </span>
                                                        <Select
                                                            size="small"
                                                            name="fieldName"
                                                            label="Field Name"
                                                            variant="outlined"
                                                            value={gethandleParkDeviceType}
                                                            onChange={(e) => {
                                                                handledeviceType(e);
                                                            }}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Field Name" }}
                                                            className="ruletime"
                                                        >
                                                            {getActionParktype?.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                    style={getStyles(name, personName, theme)}
                                                                >
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {ActiondeviceTypeError && (
                                                        <p style={{ color: "red" }}>
                                                            * Please Select Device Type
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        className={`m-0  ${classes.formControl}`}
                                                    >
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Device
                                                        </span>
                                                        <Select
                                                            size="small"
                                                            name="fieldName"
                                                            label="Field Name"
                                                            variant="outlined"
                                                            value={gethandleParkDevice}
                                                            onChange={(e) => {
                                                                handledevice(e);
                                                            }}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Field Name" }}
                                                            className="ruletime"
                                                        >
                                                            {getActionParkDevicetype?.map((data, idx) => (
                                                                <MenuItem
                                                                    key={idx}
                                                                    value={data.FieldID ? data.FieldID : data.ID}
                                                                    style={getStyles(
                                                                        data.name,
                                                                        personName,
                                                                        theme
                                                                    )}
                                                                >
                                                                    {data.FieldName ? data.FieldName : data.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {ActiondeviceError && (
                                                        <p style={{ color: "red" }}>
                                                            * Please Select Device{" "}
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        className={`m-0  ${classes.formControl}`}
                                                    >
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Action
                                                        </span>
                                                        <Select
                                                            size="small"
                                                            name="fieldStatus"
                                                            variant="outlined"
                                                            value={gethandleOpenClosePark}
                                                            onChange={(e) => {
                                                                handleOpenClosepark(e);
                                                            }}
                                                            label="Field Status"
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Field Status" }}
                                                            className="ruletime"
                                                        >
                                                            <MenuItem value="Open">Open</MenuItem>
                                                            <MenuItem value="Close">Close </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {actionError && (
                                                        <p style={{ color: "red" }}>
                                                            * Please Select Action
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Button
                                                        className="ruleaddbtn"
                                                        variant="contained"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleAdddata("6", expanded);
                                                        }}
                                                    >
                                                        {getUpdateActionID !== "" ? "Update" : "ADD"}
                                                    </Button>
                                                    {getUpdateActionID !== "" ? (
                                                        <Button
                                                            className="ruleaddbtncancel ml-2"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("canceledit");
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={6} className="text-right">
                                                    <Button
                                                        className="ruleaddbtncancel"
                                                        variant="contained"
                                                        disabled={getUpdateActionID}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleAdddata("6", expanded, "actionmore");
                                                        }}
                                                    >
                                                        MORE ACTION
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel6"}
                                        onChange={handleChange("panel6")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel6bh-content"
                                            id="panel6bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Notification Settings{" "}
                                                {getsmsnotificationSettingError &&
                                                    getemailnotificationSettingError ? (
                                                    <span className="headerror">*</span>
                                                ) : (
                                                    <span
                                                        className={`${emailnotificationSetting?.length > 0 ||
                                                                smsnotificationSetting?.length > 0
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cardbox">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={NotificationTooltip}
                                                className="tooltipoverlay"
                                            >
                                                <Button className="p-0 tooltipicon">
                                                    <HiMiniInformationCircle />
                                                </Button>
                                            </OverlayTrigger>
                                            <Grid container spacing={2} className="mt-2">
                                                <Grid item xs={12} md={12}>
                                                    <div className="stepper-row-content1">
                                                        <div className="Notificationonoff d-flex justify-content-between">
                                                            <div
                                                                className={`ruletime notifyleft  ${classes.rulelabel}`}
                                                            >
                                                                Email Notification
                                                            </div>
                                                            <div className="notifyright">
                                                                <AntSwitch
                                                                    checked={getemailOnOFFValue}
                                                                    value={getemailOnOFFValue}
                                                                    onChange={handleemailOnOffChange}
                                                                    name="checkedC"
                                                                />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        {getemailOnOFFValue === true ? (
                                                            <div>
                                                                <label
                                                                    className={`ruletime  ${classes.rulelabel}`}
                                                                >
                                                                    Notify to following users email
                                                                </label>
                                                                <div className="customInputBase w-100">
                                                                    <IconButton
                                                                        className={classes.iconButton}
                                                                        aria-label="menu"
                                                                    >
                                                                        <Search />
                                                                    </IconButton>
                                                                    <Autocomplete
                                                                        multiple
                                                                        id="EmailsList"
                                                                        label="Select Emails"
                                                                        className="notifyemailsmsbox"
                                                                        options={dropEmailsList?.map(
                                                                            (option) =>
                                                                                option.FirstName +
                                                                                "  (" +
                                                                                option.EmailAddress +
                                                                                ")"
                                                                        )}
                                                                        option={dropEmailsList?.map(
                                                                            (option) =>
                                                                                option.FirstName +
                                                                                "  (" +
                                                                                option.EmailAddress +
                                                                                ")"
                                                                        )}
                                                                        freeSolo
                                                                        renderTags={(value, getTagProps) =>
                                                                            value?.map((option, index) => (
                                                                                <Chip
                                                                                    variant="outlined"
                                                                                    label={option}
                                                                                    {...getTagProps({ index })}
                                                                                // onDelete={() => handleEmailRemove(index, option)}
                                                                                />
                                                                            ))
                                                                        }
                                                                        onChange={(event, newValue) => {
                                                                            setEmailsList(newValue);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                placeholder=""
                                                                                onChange={(e) => {
                                                                                    prepareUserEmailList(e.target.value);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                {getRuleError === true &&
                                                                    EmailsList.length <= 0 && (
                                                                        <p style={{ color: "red" }}>
                                                                            * Please Fill Email
                                                                        </p>
                                                                    )}

                                                                <br />
                                                                <div>
                                                                    <label
                                                                        className={`ruletime  ${classes.rulelabel}`}
                                                                    >
                                                                        Email message{" "}
                                                                    </label>
                                                                    <RichTextEditor
                                                                        value={emailBody}
                                                                        onChange={onChange}
                                                                    />
                                                                    <br />
                                                                </div>
                                                                {emailError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please fill in recipients and email
                                                                        message
                                                                    </p>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="stepper-row-content1">
                                                        <div className="Notificationonoff d-flex justify-content-between">
                                                            <div
                                                                className={`ruletime  notifyleft ${classes.rulelabel}`}
                                                            >
                                                                SMS Notification
                                                            </div>
                                                            <div className="notifyright">
                                                                <AntSwitch
                                                                    checked={getsmsOnOFFValue}
                                                                    value={getsmsOnOFFValue}
                                                                    onChange={handlesmsOnOffChange}
                                                                    name="checkedC"
                                                                />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        {getsmsOnOFFValue === true ? (
                                                            <div>
                                                                <label
                                                                    className={`ruletime  ${classes.rulelabel}`}
                                                                >
                                                                    Notify to following users
                                                                </label>
                                                                <div className="customInputBase w-100">
                                                                    <IconButton
                                                                        className={classes.iconButton}
                                                                        aria-label="menu"
                                                                    >
                                                                        <Search />
                                                                    </IconButton>
                                                                    <Autocomplete
                                                                        multiple
                                                                        id="MobileNumbersList"
                                                                        className="notifyemailsmsbox"
                                                                        options={dropMobileNumbersList?.map(
                                                                            (option) =>
                                                                                option.FirstName +
                                                                                "  (" +
                                                                                option.MobilePhone +
                                                                                ")"
                                                                        )}
                                                                        freeSolo
                                                                        renderTags={(value, getTagProps) =>
                                                                            value?.map((option, index) => (
                                                                                <Chip
                                                                                    variant="outlined"
                                                                                    label={option}
                                                                                    {...getTagProps({ index })}
                                                                                />
                                                                            ))
                                                                        }
                                                                        onChange={(event, newValue) => {
                                                                            setMobileNumbersList(newValue);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                placeholder=""
                                                                                onChange={(e) => {
                                                                                    prepareUserNameList(e.target.value);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <label
                                                                        className={`ruletime  ${classes.rulelabel}`}
                                                                    >
                                                                        SMS message{" "}
                                                                    </label>
                                                                    <textarea
                                                                        className="fc-width100"
                                                                        rows="3"
                                                                        value={getSmsMessage}
                                                                        onChange={(e) => {
                                                                            handleOnChangeSmsMessage(e);
                                                                        }}
                                                                    ></textarea>
                                                                    <br />
                                                                </div>
                                                                {smsError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please fill in recipients and SMS message
                                                                    </p>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {getemailOnOFFValue === true &&
                                                        getsmsOnOFFValue === false ? (
                                                        <Button
                                                            className="ruleaddbtn email-btn"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("email", expanded);
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    ) : getsmsOnOFFValue === true &&
                                                        getemailOnOFFValue === false ? (
                                                        <Button
                                                            className="ruleaddbtn sms-btn"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("sms", expanded);
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    ) : getsmsOnOFFValue === true &&
                                                        getemailOnOFFValue === true ? (
                                                        <Button
                                                            className="ruleaddbtn smsemail-btn"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("smsemail", expanded);
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            className="ruleaddbtn disable-btn"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("disablesmsemail", expanded);
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    className="borderleftsummary"
                                    style={{ padding: "12px 0px 0px 0px" }}
                                >
                                    <Grid container spacing={3} className="moiblepaddzero">
                                        <Grid item xs={12} md={12} className="addruleItems1">
                                            <Paper
                                                className={classes.paper}
                                                style={{ boxShadow: "none", padding: "10px 0px" }}
                                            >
                                                <div className="d-flex align-items-center justify-content-between px-3">
                                                    <h6 className="summaryicon">Rule Preview</h6>
                                                    <button
                                                        className="btn printbtn p-0 printbtn"
                                                        onClick={handlePrintClick}
                                                    >
                                                        <img
                                                            src={printer}
                                                            alt="printerimg"
                                                            className="img-fluid"
                                                        />
                                                    </button>
                                                </div>
                                                <div ref={summaryRef}>
                                                    <Suspense
                                                        fallback={
                                                            <ClipLoader
                                                                color={color}
                                                                loading={loading}
                                                                css={override}
                                                                size={60}
                                                            />
                                                        }
                                                    >
                                                        <NewSummary
                                                            allFields={deletethisfields}
                                                            handleActionCondition={handleActionConditionfunc}
                                                            Cleareditoverlay={getCleareditoverlay}
                                                        />
                                                    </Suspense>
                                                </div>
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12} md={12} className="text-center ">
                                            <div className="backgroundbtn">
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        newrule();
                                                    }}
                                                    className="savebtn"
                                                    disabled={getToggleconfirm}
                                                >
                                                    Save & Submit
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        handleAdddata("empty");
                                                    }}
                                                    className="deletebtn"
                                                >
                                                    Delete All
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </div>
            </section>
        </div>
    );
}
