import {
  createSlice,
  PayloadAction,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { sensorService } from "../services/sensorService";
import { RuleEngineNotificationTemplate } from "../config";

const mockData = {
  Email: ["Jagadeesh   (jagadeesh@craftany.com)"],
  FromSMS: ["Jagadeesh   (845-454-5454)"],
  RuleName: "rule1",
  ParkID: "Park 2",
  Rule: [
    {
      Field: ["CFDP:Field 1: Moisture Sensor", "CFDP:Field 2: Moisture Sensor"],
      MoistureValue: "above",
      Duration: "10m",
      MoistureCondition: "2",
      NextCriteria: "AND",
    },
    {
      Field: ["CFDP:Field 2: Moisture Sensor", "CFDP:Field 3: Moisture Sensor"],
      MoistureValue: "above",
      Duration: "10m",
      MoistureCondition: "3",
      NextCriteria: "AND",
    },
  ],
  EmailBody: "Email Body",
  SMS: "SMS",
  Action: [
    {
      fieldName: "F2",
      fieldStatus: "C",
    },
    {
      fieldName: "F1",
      fieldStatus: "O",
    },
  ],
};

export const initialState1 = {
  data: {
    Email: [],
    FromSMS: [],
    RuleName: "",
    ParkID: "",
    RuleID: "",
    ParkName: "",
    Rule: [
      {
        Field: [],
        MoistureValue: "",
        Duration: "10",
        DurationIn: "m",
        MoistureCondition: "",
        NextCriteria: "",
        FloodCondition: "",
        FloodDuration: "10",
        FloodDurationIn: "m",
        FloodValue: "",
      },
    ],
    EmailBody: RuleEngineNotificationTemplate.email,
    SMS: "",
    Action: [
      {
        fieldName: "",
        fieldStatus: "",
      },
    ],
  },
};


export const initialState = {
  data: {
    RuleID: "",
    RuleName: "",
    RuleDescription: "",
    PriorityValue: "",
    RuleIsAlwaysOn: false,
    RuleValidityStartDate: "",
    RuleValidityEndDate: "",
    OperationalTime: [
      {
        DayofWeek: "",
        StartTime: "",
        EndTime: "",
      }
    ],
    TriggerStrategy: "",
    Conditions: [
      {
        ParkID: "",
        Devicetype: "",
        DeviceID: "",
        TriggerValue: "",
        TriggerCriteria: "",
        Tolerance: "",
      }
    ],
    Action: [
      {
        ParkID: "",
        Devicetype: "",
        DeviceID: "",
        Action: "",
      }
    ],
    sendSMSNotification: false,
    SMSRecipients: [],
    SMSBody: '',
    sendEmailNotification: false,
    EmailRecipients: [],
    EmailBody: "",
    BlockDuration: "",

  }
}

export const sensorsThunk = createAsyncThunk(
  "get/sensors",
  async (payloadObj) => {
    return await sensorService(payloadObj);
  }
);

const rules = createSlice({
  name: "rules",
  initialState,
  reducers: {
    umountRulesState: () => {
      return { ...initialState };
    },
    updateRulesState(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sensorsThunk.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sensorsThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.sensors = action.payload.data;
    });
    builder.addCase(sensorsThunk.rejected, (state, action) => {
      //TODO
    });
  },
});


export const stateSelector_ = ({ rules }) => rules;

export const rules_ = createSelector(stateSelector_, (rules) => rules.data);

export const sensors_ = createSelector(
  stateSelector_,
  (rules) => rules.sensors
);

export const hasLoaded_ = createSelector(
  stateSelector_,
  (rules) => rules.hasLoaded
);

export const { updateRulesState, umountRulesState } = rules.actions;

export default rules.reducer;
