import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { Link } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "./GateModel";
import smartParkService from "../../axios";
import IconButton from "@material-ui/core/IconButton";
import { Clear, OpenInNewRounded } from "@material-ui/icons";
import { login_, token_ } from "../../reducers/login";
import RefreshIcon from "@material-ui/icons/Refresh";
import ClipLoader from "react-spinners/ClipLoader";
import _ from "lodash";
import "../../assets/scss/App.scss";
import { GenerateEncryptedToken } from "../../views/Common";
import { css } from "@emotion/core";
import locationicon from "../../assets/img/google-maps.png";
import clsx from "clsx";
const ButtonLink = withStyles((theme) => ({
  root: {
    display: "inline !important",
    color: "blue !important",
    marginRight: "10px !important",
    padding: "0 !important",
    textDecoration: "underline",
    border: "none !important",
    fontSize: "13px !important",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
  },
}))(Link);
const useStyles = makeStyles((theme) => ({
  refresh: {
    cursor: "pointer",
    margin: "auto",
    "&.spin": {
      animation: "$spin 1s 1",
      pointerEvents: "none",
    },
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
const override = css`
  display: block;
  border-color: #0076bb;
  margin-left: 650px;
`;
export default function Courtstatus() {
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const [spin, setSpin] = React.useState(false);
  const token = useSelector(token_);
  const classes = useStyles();
  const [reload, setreload] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();
  const [courts, setcourts] = React.useState([]);
  const getFields = () => {
    var url = "api/courts";
    smartParkService
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.length > 0) {
            setLoading(false);
            setcourts(res.data.data);
          }
        }
        setSpin(false);
      })
      .catch((error) => {
        setSpin(false);
      });
  };
  function padValue(value) {
    return value < 10 ? "0" + value : value;
  }
  function formatDate(dateVal) {
    if (!dateVal) return "";

    var d = new Date(dateVal);
    // debugger;
    var sMonth = padValue(d.getMonth() + 1);
    var sDay = padValue(d.getDate());
    var sYear = d.getFullYear();

    var hour =
      d.getHours() == 0
        ? 12
        : d.getHours() > 12
        ? d.getHours() - 12
        : d.getHours();
    var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
    var ampm = d.getHours() < 12 ? "AM" : "PM";
    var time = padValue(hour) + ":" + min + " " + ampm;

    return sMonth + "/" + sDay + "/" + sYear + " " + time;
  }
  const refreshCanvas = () => {
    setSpin(true);
    setreload(!reload);
  };
  //setInterval(refreshCanvas, 5 * 60 * 1000);
  React.useEffect(() => {
    smartParkService.post("api/courts").then((res, err) => {
      if (res.status === 200) getFields();
    });
  }, [reload]);
  return (
    <div>
      <div class="box">
        <div class="box-header padB0">
          <Link
            className={classes.company}
            onClick={() =>
              window.open(
                "https://www.google.com/maps/place/1301+Morrisville+Pkwy,+Morrisville,+NC+27560",
                "_blank"
              )
            }
          >
            <span
              style={{
                color: "#0076bb",
                textDecoration: "underline",
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              <img
                src={locationicon}
                style={{
                  height: "25px",
                  backgroundSize: "cover",
                }}
              />{" "}
              1301 Morrisville Pkwy, Morrisville, NC 27560
            </span>
          </Link>
          <br />
          <h6
            class="marB0 float-left"
            style={{ marginLeft: "10px", marginTop: "5px" }}
          >
            Tennis Courts Status
          </h6>
          <span class="float-right">
            <RefreshIcon
              edge="end"
              fontsize="medium"
              className={clsx({
                [classes.refresh]: true,
                spin: spin,
              })}
              onClick={refreshCanvas}
              spin={360}
            />
          </span>
        </div>
        <div class="box-body">
          <div class="divGrid">
            <div class="divGridHeading">
              <div class="divGridRow">
                <div class="divGridHead">Court</div>
                <div class="divGridHead">Status</div>
                <div class="divGridHead">Last Reported DateTime</div>
              </div>
            </div>
            <div class="divGridBody">
              {loading ? (
                <ClipLoader loading={loading} css={override} size={50} />
              ) : (
                <>
                  {courts.length > 0
                    ? courts.map((m) => (
                        <div class="divGridRow">
                          <div class="divGridCell">{m.court_name}</div>
                          <div class="divGridCell">
                            <div class="d-flex">
                              <FiberManualRecordIcon
                                fontSize="small"
                                style={{
                                  color: m.value == 0 ? green[500] : red[500],
                                }}
                              />{" "}
                              {m.value == 0 ? "Not Occupied" : "Occupied"}
                            </div>
                          </div>
                          <div class="divGridCell">
                            <div class="d-flex">
                              {formatDate(m.lastReportedDateTime)}
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
