import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
//import styles from "./loginStyle.js";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import { Lock, TrendingFlat } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import GolfLogo from "./../../assets/img/GolfLogo.png";
import GoogleLogin from "react-google-login";
import MicrosoftLogin from "react-microsoft-login";
import { useHistory } from "react-router-dom";
import { UserAgentApplication } from "msal";
import styles from "../../components/components.js";
import { Microsoft_AD_Config } from "../../config/index.js";
import { Helmet } from "react-helmet";
import Auth from "../../Auth.js";
import smartParkService from "../../axios/index.js";
import { normalizeError, getUserProfile } from "../../utils/MSUtils.js";
import "../../assets/scss/App.scss";
import {
  loginAuth,
  hasLoaded_,
  login_,
  unmountLogin,
  golfLogiin,
} from "../../reducers/login.js";
import {
  permissions_,
  Getaccesspermissions,
} from "../../reducers/permissions.js";
import _ from "lodash";
import { PublicClientApplication } from "@azure/msal-browser";
import { GenerateEncryptedToken } from "../Common.js";

const useStyles = makeStyles(styles);
const useFormStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "90%",
  },
}));

export default function GolfLoginPage(props) {
  // const YOUR_CLIENT_ID = "ca306474-5ea0-4a15-a60f-27de5cac7c04";
  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();
  const dispatch = useDispatch();

  const loginHasLoaded = useSelector(hasLoaded_);
  const loginDetails = useSelector(login_);

  const [googleLogin, setGoogleLogin] = React.useState(false);
  const [LoginHint, setLoginHint] = React.useState(false);
  const [getLoginEmailHint, setLoginEmailHint] = React.useState(false);
  const [getLoginPassHint, setLoginPassHint] = React.useState(false);
  const [getLoginWrongHint, setLoginWrongHint] = React.useState(false);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    setGoogleLogin(params.get("adminmode") === "1" ? true : false);
    if (params.get("sessionexpired")) {
      dispatch(unmountLogin());
      history.push("/golf-login");
    } else if (loginHasLoaded) {
      const rolearray = _.get(loginDetails, ["Role"], "");
      var keys = Object.keys(rolearray);
      var filtered = keys.filter(function (key) {
        return rolearray[key];
      });
      var dataobj = {};
      dataobj.RoleName = filtered;
      //const parkid = _.get(loginDetails, ["parkID"], "");
      //dispatch(Getaccesspermissions(parkid,dataobj));
      history.push("/AdminGolfDashboard");
    }
  }, [loginHasLoaded]);

  async function MSAL_Login() {
    try {
      const getRedirectURI = () => {
        var currentURI = window.location.href;
        currentURI = currentURI.substr(0, currentURI.lastIndexOf("/") + 1);
        var allowedURIs = Microsoft_AD_Config.redirectUri;
        if (allowedURIs.indexOf(currentURI) > -1) {
          return currentURI;
        } else {
          return null;
        }
      };
      let userAgentApplication = new PublicClientApplication({
        auth: {
          clientId: Microsoft_AD_Config.clientId,
          redirectUri: getRedirectURI(),
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: true,
        },
        loginHint: "Login with Samanalytic account.",
        sid: "123456DFS",
      });
      const loginResponse = await userAgentApplication.loginPopup({
        scopes: Microsoft_AD_Config.scopes,
        prompt: "select_account",
      });

      var login_username = "";
      var login_user_emailid = "";
      var login_user_id = "";

      if (loginResponse && loginResponse.account) {
        login_username = loginResponse.account.name;
        login_user_emailid = loginResponse.account.username;
      }

      Auth.authenticate();
      var domain = login_user_emailid.split("@")[1];
      var adminRole = {
        Citizen: true,
        Administrator: false,
        PWStaff: false,
        PWSupervisor: false,
      };
      if (
        domain &&
        (domain.toLowerCase() === "samanalytic.com" ||
          domain.toLowerCase() === "townofmorrisville.org")
      ) {
        // setRole({
        //   Citizen: true,
        //   Administrator: true,
        //   PWStaff: false,
        //   PWSupervisor: false,
        // });
        adminRole = {
          Citizen: false,
          Administrator: false,
          PWStaff: true,
          PWSupervisor: false,
        };
      }

      var obj = {
        //  UserName: UserName,
        UserType: "PWStaff",
        EmailAddress: login_user_emailid.toLowerCase(),
        Role: adminRole,
        FirstName: login_username.split(" ")[0],
        LastName: login_username.split(" ")[1],
        IsActive: true,
        Authentication: "ActiveDirectory",
      };
      smartParkService
        .post("api/auth/microsoft/login", { Microsoftresponse: loginResponse })
        .then((res, err) => {
          if (res.status === 200) {
            console.log("microsoftdata saved");
          }
        });

      dispatch(loginAuth(obj));
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  const history = useHistory();
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const CustomCheckbox = withStyles({
    root: {
      color: "#03354b",
      "&$checked": {
        color: "#03354b",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  const [checked, setChecked] = React.useState(true);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setChecked(event.target.checked);
  };
  const authHandler = (err, data) => {
    if (data) {
      history.push("/overview");
    }
  };
  const [role, setRole] = React.useState({
    Citizen: true,
    Administrator: false,
    PWStaff: false,
    PWSupervisor: false,
  });
  const responseGoogle = async (response) => {
    const user = response.profileObj;
    Auth.authenticate();
    var obj = {
      //  UserName: UserName,
      UserType: "Citizen",
      EmailAddress: user.email.toLowerCase(),
      Role: role,
      FirstName: user.givenName,
      LastName: user.familyName,
      IsActive: true,
      Authentication: "Google",
    };

    dispatch(loginAuth(obj));
    //history.push("/UserDashboard");
  };

  const responseGoogleFailure = (response) => {
    history.push("/");
  };
  const onRouteSignUp = () => {
    let path = "/SignUp";
    history.push(path);
  };
  const onRouteForgotPwd = () => {
    return false;
    let path = "/ForgotPassword";
    history.push(path);
  };
  const [EmailString, setEmailString] = React.useState("");
  const [PasswordString, setPasswordString] = React.useState("");
  const onEmailhandleChange = (e) => {
    setEmailString(e.target.value);
    console.log(e.target.value);
  };

  const onPasswordhandleChange = (e) => {
    setPasswordString(e.target.value);
    console.log(e.target.value);
  };

  const handleLogin = () => {
    setLoginHint(false);
    setLoginEmailHint(false);
    setLoginPassHint(false);
    setLoginWrongHint(false);
    const defaultUserName = "Golfcourse";
    const defaultPassword = "Gr33nF@1rW@y$!Adm1n";
    const defaultUserName1 = "GolfCourseClient";
    const defaultPassword1 = "Gr33nF@1rW@y$!Client";
    if (EmailString == "" && PasswordString == "") {
      setLoginHint(true);
      return false;
    }
    if (EmailString == "" && PasswordString !== "") {
      setLoginEmailHint(true);
      return false;
    }
    if (EmailString !== "" && PasswordString == "") {
      setLoginPassHint(true);
      return false;
    }
    if (
      (defaultUserName === EmailString && defaultPassword === PasswordString) ||
      (defaultUserName1 === EmailString && defaultPassword1 === PasswordString)
    ) {
      // User details match, proceed with login
      dispatch(golfLogiin(defaultUserName, defaultPassword));
      setPasswordString("");
      setEmailString("");
    } else {
      setLoginWrongHint(true);
      return false;
    }
  };
  const { ...rest } = props;
  return (
    <div className={classes.section}>
      <Helmet>
        <title>CAROLINA TRACE COUNTRY CLUB</title>
      </Helmet>
      <Grid container justifyContent="center" alignItems="center">
        <div className={classes.loginBox}>
          <Grid item xs={12}>
            <div className={`text-center ${classes.loginHeader}`}>
              <div className={`mx-auto ${classes.logo}`}>
                <img src={GolfLogo} alt="Logo" />
              </div>
              <h6>CAROLINA TRACE COUNTRY CLUB</h6>
            </div>
            <Card variant="outlined">
              <form className={classes.form}>
                <h4 className={classes.divider}>Let's get started now! </h4>
                {LoginHint && (
                  <div style={{ color: "#ff0000", padding: "5px" }}>
                    Please enter email address and password
                  </div>
                )}
                {getLoginPassHint && (
                  <div style={{ color: "#ff0000", padding: "5px" }}>
                    Please enter password
                  </div>
                )}
                {getLoginEmailHint && (
                  <div style={{ color: "#ff0000", padding: "5px" }}>
                    Please enter email address
                  </div>
                )}
                {getLoginWrongHint && (
                  <div style={{ color: "#ff0000", padding: "5px" }}>
                    Please enter Correct email address and password
                  </div>
                )}
                <CardContent>
                  <Grid container item xs={12} className="golfemailpass">
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        onChange: onEmailhandleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: onPasswordhandleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12} className={classes.f13}>
                    <Grid item xs={6}>
                      <label>
                        <CustomCheckbox
                          color="primary"
                          inputProps={{ "aria-label": "Remember Me" }}
                        />{" "}
                        Remember Me
                      </label>
                    </Grid>
                    {/*<Grid item xs={6} className={classes.forgotPwd}>
                      <a color="primary" onClick={onRouteForgotPwd}>
                        Forgot Your Password?
                      </a>
                    </Grid>*/}
                  </Grid>
                  <Grid container item xs={12} className={classes.textCenter}>
                    {/*<Grid item xs={6}>
                      <Button disabled="disable"
                        color="primary"
                        variant="outlined"
                        className={classes.loginButtons}
                        onClick={onRouteSignUp}
                      >
                        SIGN UP
                      </Button>
                    </Grid>*/}
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.loginButtons}
                        onClick={handleLogin}
                      >
                        LOGIN &nbsp;&nbsp; <TrendingFlat />
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                  {/*<div className={classes.textCenter} xs={12}>
                    <p class="fb-500">- OR LOGIN WITH -</p>
                  </div>{" "}
                  <br />
                  <div className={classes.textCenter}>
                    {googleLogin ?
                      <GoogleLogin
                        className={classes.btnGoogle}
                        clientId="968192198480-fvsui1mu2dmp0t4to8ctfqpnvjhb553a.apps.googleusercontent.com"
                        buttonText="Google"
                        cssClass="btnGoogle"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogleFailure}
                        cookiePolicy={"single_host_origin"}
                      /> : ""}
                    
                    <Button
                      className={classes.btnMicrosoft}
                      onClick={MSAL_Login}
                    >
                      <span className={classes.iconMicrosoft}>&nbsp;</span>
                      &nbsp; Microsoft AD
                    </Button>
                  </div>*/}
                </CardContent>
              </form>
            </Card>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
