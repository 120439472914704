import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    makeStyles,
    ThemeProvider,
    createTheme,
    withStyles,
} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { KeyboardBackspace } from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Button from "../../components/CustomButtons/Button.js";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import FormControl from "@material-ui/core/FormControl";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import InputMask from "react-input-mask";
import { login_, token_, updateloginState } from "../../reducers/login";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(styles);
const theme = createTheme({
    palette: {
        primary: {
            main: "#03354b",
        },
    },
});
const helperTextStyles = makeStyles((theme) => ({
    root: {
        margin: 4,
        color: "red",
    },
    error: {
        "&.MuiFormHelperText-root.Mui-error": {
            color: theme.palette.common.white,
        },
    },
}));

export default function AccountDashboard() {
    const classes = useStyles();
    const history = useHistory();
    const helperTestClasses = helperTextStyles();
    const CustomCheckbox = withStyles({
        root: {
            color: "#03354b",
            "&$checked": {
                color: "#03354b",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const [state, setState] = React.useState({
        Citizen: false,
        Administrator: false,
        PWStaff: false,
        PWSupervisor: false,
    });
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");
    const userrole = _.get(loginDetails, ["Role"], "");
    const token = useSelector(token_);
    smartParkService.defaults.headers.common["x-access-token"] = token;
    const [UserName, SetUserName] = React.useState("");
    const [UserType, SetUserType] = React.useState("");
    const [FirstName, SetFirstName] = React.useState("");
    const [LastName, SetLastName] = React.useState("");
    const [MiddleName, SetMiddleName] = React.useState("");
    const [EmailAddress, SetEmailAddress] = React.useState("");
    const [Gender, SetGender] = React.useState("");
    const [roleRows, setroleRows] = React.useState([]);
    const [Address1, SetAddress1] = React.useState("");
    const [Address2, SetAddress2] = React.useState("");
    const [City, SetCity] = React.useState("");
    const [State1, setState1] = React.useState("");
    const [PhoneNumber, setPhoneNumber] = React.useState("");
    const [errusernme, seterrusername] = React.useState("");
    const [errfirstname, seterrfirstname] = React.useState("");
    const [errlastname, seterrlastname] = React.useState("");
    const [erremailaddress, seterremailaddress] = React.useState("");
    const [errphonenumber, seterrphonenumber] = React.useState("");
    const [erraddress1, seterraddress1] = React.useState("");
    const [errstate, seterrstate] = React.useState("");
    const [errcity, seterrcity] = React.useState("");

    const States = [
        { StateCode: "AL", StateName: "ALABAMA" },
        { StateCode: "AK", StateName: "ALASKA" },
        { StateCode: "AZ", StateName: "ARIZONA" },
        { StateCode: "AR", StateName: "ARKANSAS" },
        { StateCode: "CA", StateName: "CALIFORNIA" },
        { StateCode: "CO", StateName: "COLORADO" },
        { StateCode: "CT", StateName: "CONNECTICUT" },
        { StateCode: "DE", StateName: "DELAWARE" },
        { StateCode: "DC", StateName: "DISTRICT OF COLUMBIA" },
        { StateCode: "FL", StateName: "FLORIDA" },
        { StateCode: "GA", StateName: "GEORGIA" },
        { StateCode: "HI", StateName: "HAWAII" },
        { StateCode: "ID", StateName: "IDAHO" },
        { StateCode: "IL", StateName: "ILLINOIS" },
        { StateCode: "IN", StateName: "INDIANA" },
        { StateCode: "IA", StateName: "IOWA" },
        { StateCode: "KS", StateName: "KANSAS" },
        { StateCode: "KY", StateName: "KENTUCKY" },
        { StateCode: "LA", StateName: "LOUISIANA" },
        { StateCode: "ME", StateName: "MAINE" },
        { StateCode: "MD", StateName: "MARYLAND" },
        { StateCode: "MA", StateName: "MASSACHUSETTS" },
        { StateCode: "MI", StateName: "MICHIGAN" },
        { StateCode: "MN", StateName: "MINNESOTA" },
        { StateCode: "MS", StateName: "MISSISSIPPI" },
        { StateCode: "MO", StateName: "MISSOURI" },
        { StateCode: "MT", StateName: "MONTANA" },
        { StateCode: "NE", StateName: "NEBRASKA" },
        { StateCode: "NV", StateName: "NEVADA" },
        { StateCode: "NH", StateName: "NEW HAMPSHIRE" },
        { StateCode: "NJ", StateName: "NEW JERSEY" },
        { StateCode: "NM", StateName: "NEW MEXICO" },
        { StateCode: "NY", StateName: "NEW YORK" },
        { StateCode: "NC", StateName: "NORTH CAROLINA" },
        { StateCode: "ND", StateName: "NORTH DAKOTA" },
        { StateCode: "OH", StateName: "OHIO" },
        { StateCode: "OK", StateName: "OKLAHOMA" },
        { StateCode: "OR", StateName: "OREGON" },
        { StateCode: "PA", StateName: "PENNSYLVANIA" },
        { StateCode: "RI", StateName: "RHODE ISLAND" },
        { StateCode: "SC", StateName: "SOUTH CAROLINA" },
        { StateCode: "SD", StateName: "SOUTH DAKOTA" },
        { StateCode: "TN", StateName: "TENNESSEE" },
        { StateCode: "TX", StateName: "TEXAS" },
        { StateCode: "UT", StateName: "UTAH" },
        { StateCode: "VT", StateName: "VERMONT" },
        { StateCode: "VA", StateName: "VIRGINIA" },
        { StateCode: "WA", StateName: "WASHINGTON" },
        { StateCode: "WV", StateName: "WEST VIRGINIA" },
        { StateCode: "WI", StateName: "WISCONSIN" },
        { StateCode: "WY", StateName: "WYOMING" },
        { StateCode: "PR", StateName: "PUERTO RICO" },
        { StateCode: "GU", StateName: "GUAM" },
        { StateCode: "AS", StateName: "AMERICAN SAMOA" },
    ];
    const renderstateoptions = (option, { inputValue }) => {
        const matches = match(option.StateName, inputValue);
        const parts = parse(option.StateName, matches);

        return (
            <div>
                {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                    </span>
                ))}
            </div>
        );
    };
    const validateEmail = () => {
        if (EmailAddress == "" || EmailAddress == undefined) {
            seterremailaddress("Please enter email address");
        }
        if (EmailAddress != "") {
            var regexEmail = /\w+([-+.']\w+)@\w+([-.]\w+)\.\w+([-.]\w+)*/;
            if (!regexEmail.test(EmailAddress)) {
                seterremailaddress("Invalid email address");
            }
        }
    };
    const validatephonenumber = () => {
        if (
            PhoneNumber != "" &&
            PhoneNumber.replaceAll("-", "").trim().length != 10
        ) {
            seterrphonenumber("Please enter a valid phone number");
        }
    };
    const validation = () => {
        var requiredFields = true;
        var Errorlabels = {};
        if (FirstName == "" || FirstName == undefined) {
            seterrfirstname("Please Enter FirstName");
            requiredFields = false;
        }
        if (LastName == "" || LastName == undefined) {
            seterrlastname("Please Enter LastName");
            requiredFields = false;
        }
        if (EmailAddress == "" || EmailAddress == undefined) {
            seterremailaddress("Please Enter EmailAddress");
            requiredFields = false;
        }
        if (EmailAddress != "") {
            var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
            if (!regexEmail.test(EmailAddress)) {
                seterremailaddress("In Valid Email pattern Ex :joe@**.** ");
                requiredFields = false;
            }
        }
        // if (Address1 == "" || Address1 == undefined) {
        //   seterraddress1("Please Enter Address Line 1");
        //   requiredFields = false;
        // }
        // if (City == "" || City == undefined) {
        //   seterrcity("Please Enter City");
        //   requiredFields = false;
        // }
        if (PhoneNumber == "" || PhoneNumber == undefined) {
            seterrphonenumber("Please Enter PhoneNumber");
            requiredFields = false;
        }
        // if (State1 == "" || State1 == undefined) {
        //   seterrstate("Please Enter State");
        //   requiredFields = false;
        // }
        return requiredFields;
    };
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const onChangeValue = (e) => {
        SetGender(e.target.value);
    };
    React.useEffect(() => {
        var id = window.location.href.split("=")[1];
        smartParkService.get("api/user/" + id).then((res, err) => {
            setPhoneNumber(res.data.data.MobilePhone);
            SetFirstName(res.data.data.FirstName);
            SetLastName(res.data.data.LastName);
            setState(res.data.data.Role);
            SetCity(res.data.data.City);
            SetAddress1(res.data.data.Address1);
            SetAddress2(res.data.data.Address2);
            SetUserType(res.data.data.UserType);
            SetEmailAddress(res.data.data.EmailAddress);
            SetGender(res.data.data.Gendar);
            setState1(res.data.data.State);
            SetMiddleName(res.data.data.MiddleName);
        });
    }, []);

    const UpdateUser = () => {
        const alett = validation();
        if (alett) {
            var id = window.location.href.split("=")[1];
            var Dataobject = {
                UserName: UserName,
                UserType: UserType,
                EmailAddress: EmailAddress,
                Role: state,
                FirstName: FirstName,
                LastName: LastName,
                MiddleName: MiddleName,
                Gendar: Gender,
                Address1: Address1,
                Address2: Address2,
                City: City,
                State: State1,
                MobilePhone: PhoneNumber,
            };
            smartParkService
                .post("api/users/" + id, Dataobject, {
                    headers: {
                        userid: userid,
                    },
                })
                .then((res, err) => {
                    if (res.status == 200) {
                        history.push("/UserDashboard");
                        if (res.data.data.nModified == 1) {
                            updateloginState("Role", state);
                            alert("Changes updated Successfully");
                        } else {
                            alert("No changes in Db");
                        }
                    }
                    else if (res.status === 401 || res.status === 403) {
                        alert("You do not have permission to update the user details");
                    }
                })
                .catch(() => {
                    alert("Error occurred while updating the user details");
                });
        }
    };
    React.useEffect(() => {
        var url = "api/park/" + parkid + "/roles";
        smartParkService.get(url).then((res) => {
            if (res.data.status == 200) {
                setroleRows([...res.data.data]);
            }
        });
    }, []);
    return (
        <div className={classes.root}>
            <div className={classes.contentPage}>
                <section class="content-header">
                    <h3 class="float-left">Users List</h3>
                    <div class="float-right">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="#">Home</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a class="active" href="#">
                                    Users
                                </a>
                            </li>
                        </ol>
                    </div>
                </section>
                <section class="content">
                    <div class="content-middle">
                        <div class="content-sub-header">
                            <div>
                                <Link component="button" onClick={() => history.push("/Users")}>
                                    {" "}
                                    <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                                </Link>
                            </div>
                            <div>
                                <h3>My Account</h3>
                            </div>
                            <div class="display-xs-0 ">
                                <Link component="button" className="invisible" onClick={() => history.push("/Users")}>
                                    {" "}
                                    <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                                </Link>
                            </div>
                        </div>
                        <Paper className={classes.paper}>
                            <ThemeProvider theme={theme}>
                                <div class="form-body userprofilecontainer">
                                    <Grid container spacing={3} className={classes.pad20}>
                                        {/* <Grid item xs={12} sm={6}>
					  <TextField
						variant="outlined"
						size="small"
						required
						id="username"
						name="username"
						label="User Name"
						disabled
						value={UserName}
						fullWidth
						autoComplete="given-name"
						onChange={(e) => {
						  SetUserName(e.target.value);
						}}
					  />
					  <div style={{ color: "red" }}>{errusernme}</div>
					</Grid> */}
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                required
                                                id="Email"
                                                name="Email"
                                                label="Email"
                                                value={EmailAddress}
                                                helperText={erremailaddress}
                                                FormHelperTextProps={{ classes: helperTestClasses }}
                                                onClick={() => {
                                                    seterremailaddress("");
                                                }}
                                                disabled
                                                fullWidth
                                                autoComplete="Email"
                                                onChange={(e) => {
                                                    SetEmailAddress(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                required
                                                id="firstName"
                                                name="firstName"
                                                label="First name"
                                                value={FirstName}
                                                helperText={errfirstname}
                                                FormHelperTextProps={{ classes: helperTestClasses }}
                                                fullWidth
                                                onClick={() => {
                                                    seterrfirstname("");
                                                }}
                                                autoComplete="given-name"
                                                onChange={(e) => {
                                                    SetFirstName(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                required
                                                id="lastName"
                                                name="lastName"
                                                label="Last name"
                                                value={LastName}
                                                fullWidth
                                                helperText={errlastname}
                                                FormHelperTextProps={{ classes: helperTestClasses }}
                                                onClick={() => {
                                                    seterrlastname("");
                                                }}
                                                autoComplete="family-name"
                                                onChange={(e) => {
                                                    SetLastName(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12}>
					  <TextField
						variant="outlined"
						size="small"
						id="address1"
						name="address1"
						label="Address line 1*"
						value={Address1}
						helperText={erraddress1}
                        FormHelperTextProps={{ classes: helperTestClasses }}
						fullWidth
						autoComplete="address-line1"
						onClick={() => {
						  seterraddress1("");
						}}
						onChange={(e) => {
						  SetAddress1(e.target.value);
						}}
					  />
					</Grid>
					<Grid item xs={12}>
					  <TextField
						variant="outlined"
						size="small"
						id="address2"
						name="address2"
						value={Address2}
						label="Address line 2"
						fullWidth
						autoComplete="address-line"
						onChange={(e) => {
						  SetAddress2(e.target.value);
						}}
					  />
					</Grid>
					<Grid item xs={12} sm={4}>
					  <TextField
						variant="outlined"
						size="small"
						required
						id="city"
						name="city"
						onClick={() => {
						  seterrcity("");
						}}
						label="City"
						helperText={errcity}
                        FormHelperTextProps={{ classes: helperTestClasses }}
						value={City}
						fullWidth
						autoComplete="address-level"
						onChange={(e) => {
						  SetCity(e.target.value);
						}}
					  />
					 
					</Grid>
					<Grid item xs={12} sm={4}>
					  <Autocomplete
						id="state-selection"
						size="small"
						options={States}
						value={State1}
						onChange={(event, newValue) => {
						  setState1(newValue);
						}}
						helperText={errstate}
                        FormHelperTextProps={{ classes: helperTestClasses }}
						getOptionLabel={(option) => option.StateName}
						renderInput={(params) => (
						  <TextField
							{...params}
							label="Select State"
							variant="outlined"
							onClick={() => {
							  seterrstate("");
							}}
						  />
						)}
						renderOption={renderstateoptions}
					  />
					 
					</Grid> */}
                                        {/* <Grid item xs={12} sm={4}>
		<TextField variant="outlined"  size="small"
		  required
		  id="zip"
		  name="zip"
		  label="Zip / Postal code"
		  fullWidth
		  autoComplete="postal-code"
		  
		/>
	  </Grid>
	 <Grid item xs={12} sm={4}>
		<TextField variant="outlined"  size="small"
		  required
		  id="country"
		  name="country"
		  label="Country"
		  fullWidth
		  autoComplete="country"
		/>
	  </Grid>*/}
                                        <Grid item xs={12}>
                                            <InputMask
                                                mask="999-999-9999"
                                                value={PhoneNumber}
                                                fullWidth
                                                onChange={(e) => {
                                                    setPhoneNumber(e.target.value);
                                                }}
                                                maskChar=" "
                                                onBlur={validatephonenumber}
                                            >
                                                {() => (
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        required
                                                        fullWidth
                                                        helperText={errphonenumber}
                                                        FormHelperTextProps={{ classes: helperTestClasses }}
                                                        id="username"
                                                        name="username"
                                                        label="Phone"
                                                        onClick={() => {
                                                            seterrphonenumber("");
                                                        }}
                                                    />
                                                )}
                                            </InputMask>
                                            {/* <TextField variant="outlined" size="small"
					  required
					  id="Phone"
					  name="Phone"
					  label="Phone"
					  value={PhoneNumber}
					  fullWidth
					  onClick={()=>{
						seterrphonenumber("")
					  }}
					  autoComplete="Phone Number"
					  onChange={(e) => { setPhoneNumber(e.target.value); }} */}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label class="form-label">Choose Role &nbsp;&nbsp;</label>
                                            <FormControl component="fieldset">
                                                <FormGroup row>
                                                    {roleRows.length > 0
                                                        ? roleRows.map((e) => {
                                                            var SRoleName = e.Role;
                                                            return (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={state[SRoleName]}
                                                                            onChange={handleChange}
                                                                            name={e.Role}
                                                                            disabled={!userrole.Administrator}
                                                                        />
                                                                    }
                                                                    label={e.RoleDescription}
                                                                />
                                                            );
                                                        })
                                                        : null}
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div class="form-footer">
                                    <Button
                                        color="default"
                                        round
                                        onClick={() => {
                                            history.push("/Users");
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </ThemeProvider>
                        </Paper>
                    </div>
                </section>
            </div>
        </div>
    );
}
