import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import logo from "../assets/img/Logo.png";
import { AccountCircle, ExpandMore } from "@material-ui/icons";
import Auth from "../Auth";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import GolfLogo from "../assets/img/GolfLogo.png";
import {
    login_,
    unmountLogin,
    usersessionid_,
    token_,
} from "../reducers/login";
import { tokenCheckInterval } from "../config";
import smartParkService from "../../src/axios";
import {
    permissions_,
    unmountPermissions,
    Getaccesspermissions,
    hasLoadedpermissions_,
} from "../reducers/permissions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& .MuiOutlinedInput-input": {
            padding: "5px !important",
        },
    },
    header: {},
    logo: {
        backgroundColor: "#fff",
        padding: 5,
        float: "left",
        "& img": {
            width: 150,
            height: 46,
            [theme.breakpoints.down("md")]: {
                width: 100,
                height: 30,
            },
        },
    },
    collapseMenu: {
        marginLeft: 10,
    },
    select: {
        border: "1px solid #8f9598",
        borderRadius: 4,
        borderBottom: "none",
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "#FFF",
        color: "#03354b",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        boxShadow: "0 2px 2px rgba(0,0,0,.03), 0 1px 0 rgba(0,0,0,.03)",
        [theme.breakpoints.down("sm")]: {
            "& h6": { fontSize: "x-small !important" },
            "& .MuiInputBase-root": { fontSize: "x-small !important" },
            "& .MuiMenuItem-root": { fontSize: "x-small !important" },
        },
    },
    navBarMenu: {
        float: "right",
        height: "65px",
    },
    hide: {
        display: "none",
    },
    sm_hide: {
        [theme.breakpoints.down("sm")]: { display: "none" },
    },
}));
export default function CustomHeader() {
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        setInterval(function () {
            smartParkService
                .get("api/check")
                .then((res, err) => {
                    if (res.status !== 200) {
                        window.location.href = window.location.origin;
                        dispatch(unmountLogin());
                    }
                })
                .catch((err) => {
                    window.location.href = window.location.origin;
                    dispatch(unmountLogin());
                });
        }, tokenCheckInterval);
    }, []);
    const dispatch = useDispatch();
    const loginDetails = useSelector(login_);
    const usersessionid = useSelector(usersessionid_);
    const [selectpark, setselectedpark] = React.useState("cfdp");
    const token = useSelector(token_);
    smartParkService.defaults.headers.common["x-access-token"] = token;
    const role = _.get(loginDetails, ["Role"], "");
    const firstName = _.get(loginDetails, ["FirstName"], "");
    const emailAddress = _.get(loginDetails, ["EmailAddress"], "");
    const ParkID = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");

    // const user = JSON.parse(localStorage.getItem('user'))
    const setprofilename = () => {
        // const user= JSON.parse(localStorage.getItem('user'))
        if (!firstName && emailAddress) return emailAddress.split("@")[0];
        else return firstName;
    };

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [parkname, setparkname] = React.useState("");
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const aythLogout = async () => {
        var url = "api/park/" + ParkID + "/usersessions/";
        await smartParkService
            .post(url + usersessionid)
            .then((res) => {
                if (res.data.status == 200) {
                    dispatch(unmountLogin());
                    dispatch(unmountPermissions());
                    history.push("/");
                    Auth.signout();
                }
            })
            .catch((error) => {
                dispatch(unmountLogin());
                Auth.signout();
                history.push("/");
            });
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        // history.push("/UserProfile?id=" + userid);
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const Logout = async () => {
        dispatch(unmountLogin());
        Auth.signout();
        history.push("/ctcc");
    };
    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem
            // onClick={() => {
            //   history.push("/UserProfile?id=" + userid);
            // }}
            >
                Profile
            </MenuItem>
            <MenuItem
            // onClick={() => {
            //   history.push("/MyAccount?id=" + userid);
            // }}
            >
                My account
            </MenuItem>
            <MenuItem onClick={() => Logout()}>Logout</MenuItem>
        </Menu>
    );

    useEffect(() => {
        if (location.pathname === "/tenniscourts") {
            setselectedpark("mafctenniscourts");
        } else if (location.pathname === "/churchstreettenniscourts") {
            setselectedpark("churchstreettenniscourts");
        } else if (location.pathname === "/mcppark") {
            setselectedpark("mcppark");
        } else if (location.pathname === "/cfdppark") {
            setselectedpark("cfdp");
        } else {
            setselectedpark("all");
        }
    }, [location]);

    return (
        <div className={classes.header}>
            <AppBar position="fixed" className={classes.appBar}>
                <div className={classes.logo}>
                    <img src={GolfLogo} alt="Logo" />
                </div>
                <div>
                    <h4 class="dbtitle marB0">CAROLINA TRACE COUNTRY CLUB DASHBOARD</h4>
                    {/* <Select
            //className={classes.select}
            fullWidth
            size="small"
            labelId="label"
            value={selectpark}
            onChange={(e) => {
              setselectedpark(e.target.value);
              if (e.target.value == "mafctenniscourts") {
                history.push("/tenniscourts");
              } else if (e.target.value == "churchstreettenniscourts") {
                history.push("/churchstreettenniscourts");
              } else if (e.target.value == "mcppark") {
                history.push("/mcppark");
              } else if (e.target.value == "all") {
                history.push("/overview");
              } else {
                history.push("/cfdppark");
              }
            }}
          >
            <MenuItem value="all" id="all">
              Golf Overview
            </MenuItem>
            <MenuItem value="cfdp" id="cfdp">
              Cedar Fork District Park
            </MenuItem>
            <MenuItem value="mafctenniscourts" id="mafctenniscourts">
              MAFC
            </MenuItem>
            <MenuItem
              value="churchstreettenniscourts"
              id="churchstreettenniscourts"
            >
              Church Street Park
            </MenuItem>
            <MenuItem value="mcppark" id="mcppark">
              Morrisville Community Park
            </MenuItem>
          </Select> */}
                    <Select
                        //className={classes.select}
                        fullWidth
                        size="small"
                        labelId="label"
                        value={selectpark}
                        onChange={(e) => {
                            setselectedpark(e.target.value);
                            if (e.target.value == "practice") {
                                history.push("/PracticeGreen");
                            }
                            // else if (e.target.value == "churchstreettenniscourts") {
                            //   history.push("/churchstreettenniscourts");
                            // } else if (e.target.value == "mcppark") {
                            //   history.push("/mcppark");
                            // } else if (e.target.value == "all") {
                            //   history.push("/overview");
                            // } 
                            else {
                                history.push("/Golfoverview");
                            }
                        }}
                    >
                        <MenuItem value="all" id="all">
                            Golf Overview
                        </MenuItem>
                        <MenuItem value="practice" id="practice">
                            Practice Green
                        </MenuItem>
                        {/* <MenuItem value="lake" id="lake">
              Lake Course
            </MenuItem>
            <MenuItem value="greek" id="greek">
              Greek Course
            </MenuItem> */}
                    </Select>
                </div>
                <div className={classes.navBarMenu}>
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                    >
                        <AccountCircle fontSize="large" /> &nbsp;
                        <span class="user-info display-xs-0">
                            <h6>{setprofilename()}</h6>
                            <span>Profile Details</span>
                        </span>
                        <ExpandMore className={classes.sm_hide} />
                    </IconButton>
                </div>
                {renderMenu}
            </AppBar>
        </div>
    );
}
