import React, { useState, useEffect, useCallback } from "react";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import smartParkService from "../../axios";
import { useSelector, useDispatch } from "react-redux";
import { login_, token_ } from "../../reducers/login";
import { sensors_, sensorsHasLoaded_ } from "../../reducers/sensors";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { fieldmoisturethunk } from "../../reducers/fieldmoisture";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

var labels = { 0: "Close", 5: "", 10: "Open" };
// Timezone offset in minutes/60 = hours
// hours * 60 = total minutes
// total minutes * 60 = total seconds
// total seconds * 1000 = total difference in milliseconds
const localTimeZoneOffset =
  (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;

function prepareGraphPlot(data) {
  try {
    var graphs = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i] && data[i].length) {
        graphs.push({
          name: data[i][0].field + " moisture",
          data: (function () {
            var ohlcData = [];
            var linemockData = data[i];
            for (var j = 0; j < linemockData.length; j++) {
              ohlcData.push([
                Number(linemockData[j]["date"]) - localTimeZoneOffset, // the date
                Number(linemockData[j]["moisture"]), // moisture
              ]);
            }
            return ohlcData;
          })(),
        });
      }
    }
    return graphs;
  } catch (error) {
    console.log("Error in prepareGraphPlot(). Exception Message: " + error);
  }
}

function FieldMoistureandStatusChart(props) {
  const loginDetails = useSelector(login_);
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const sensors = useSelector(sensors_);
  const sensorHasLoaded = useSelector(sensorsHasLoaded_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const fields = props.selectedFileds || [];
  const [color, setColor] = React.useState("#ffffff");
  const [dataResults, setdataResults] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedFields, setselectedFields] = useState([props.selectedFields]);
  const [selectedDateRange, setselectedDateRange] = useState({});
  const dispatch = useDispatch();

  function getUpdatedGrpahParameters(selectedFileds, min, max) {
    var fields = [];
    if (selectedFileds) {
      var selectedfields = selectedFileds;
      for (var i = 0; i < selectedfields.length; i++) {
        selectedfields[i] &&
          selectedfields[i].value &&
          fields.push(selectedfields[i].value);
      }
    }
    return `api/citizen/moisturev1/hour?startDate=${min
      }&endDate=${max}&fields=${fields.join(",")}`;
  }
  const debouncedSave = useCallback(
    _.debounce((nextValue) => {
      // console.log("selectedFileds", props.selectedFileds);
      const { event, selectedFields } = nextValue;
      // console.log("nextValue", nextValue);
      let min = event.min;
      let max = event.max;
      let interval = max - min;
      // console.log("useCallback");
      dispatch(
        fieldmoisturethunk(
          getUpdatedGrpahParameters(props.selectedFileds, min, max)
        )
      );
    }, 1000),
    [] // will be created only once initially
  );

  const getConfig = (mockData) => ({
    credits: {
      enabled: false,
    },
    chart: {
      type: "spline",
      events: {
        load: function (event) {
          event.target.reflow();
        },
      },
    },
    showInLegend: true,
    rangeSelector: {
      selected: 1,
      enabled: false, // To hide the zoom 1m 3m and Input fields
      // inputEnabled: false,
      // buttonTheme: {
      //     visibility: 'hidden'
      // },
      // labelStyle: {
      //     visibility: 'hidden'
      // }
    },
    height: "100%",
    title: {
      text: "Field Status and Moisture",
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%l:%M %p}",
      },
      events: {
        afterSetExtremes: function (event) {
          let min = event.min;
          let max = event.max;
          let interval = max - min;
          // console.log(interval);
          this.update({
            tickPositioner: function () {
              if (interval <= 86499999) {
                debouncedSave({ event, selectedFields });
              }
            },
          });
        },
      },
    },
    yAxis: {
      tickInterval: 2,
    },

    tooltip: {
      split: true,
      xDateFormat: "%A, %b %e, %I:%M %p",
      pointFormatter: function () {
        var point = this;
        var statusTooltip = point.series.name;
        var moistureTooltip = point.series.name;
        if (point.series.name.indexOf("status") == -1) {
          return (
            '<span style="color:' +
            point.color +
            '">\u25CF</span> ' +
            (moistureTooltip + ": " + point.y.toFixed(2)) +
            "</b><br/>"
          );
        } else {
          return false;
        }
      },
    },
    chart: {
      events: {
        load: function (event) {
          event.target.reflow();
        },
      },
    },
    series: prepareGraphPlot(mockData),
  });
  useEffect(() => {
    if (sensorHasLoaded) {
      getData();
    }
  }, [
    sensorHasLoaded,
    fields.length,
    props.selectedDateRange.start,
    props.selectedDateRange.end,
  ]);

  const getData = () => {
    var fields = [];
    if (props.selectedFileds) {
      var selectedfields = props.selectedFileds;
      for (var i = 0; i < selectedfields.length; i++) {
        fields.push(selectedfields[i].value);
      }
    }
    var range = {};
    if (props.selectedDateRange) {
      var dates = props.selectedDateRange;
      range.startDate = dates.start;
      range.endDate = dates.end;
    }

    var diff = range.endDate - range.startDate;
    var view = "hour";
    // if (diff <= 86499999) {
    //   view = "minute";
    // }
    let url =
      "api/citizen/moisturev1/hour?startDate=" +
      (range.startDate) +
      "&endDate=" +
      (range.endDate) +
      "&fields=" +
      fields.join(",");
    // console.log(url);
    setLoading(true);
    // setTimeout(()=>{
    smartParkService
      .get(url)
      .then((res) => {
        if (res) {
          // console.log(res);
          // console.log(selectedfields);
          var graphData = fieldwiseFilter(
            selectedfields,
            res.data.data,
            res.data.details
          );
          // console.log(graphData);
          setdataResults(graphData);
        } else {
          console.log("FloodChart else error: ");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("FloodChart error: ", error);
        setLoading(false);
      });
    // },2000);
  };

  const getUniqueSensors = (fieldID) => {
    // console.log(sensors);
    if (sensors && sensors.length > 0) {
      return sensors.filter((obj) => {
        if (obj.fieldID === fieldID) {
          return obj;
        }
      });
    } else {
      return [];
    }
  };

  const fieldwiseFilter = (fields, data, namedetails) => {
    var filteredData = [];
    if (fields && fields.length > 0 && data) {
      for (var i = 0; i < fields.length; i++) {
        const uniqueSensors = getUniqueSensors(fields[i].value);
        if (uniqueSensors && uniqueSensors.length > 0) {
          for (var j = 0; j < uniqueSensors.length; j++) {
            var graphData = [];
            data.filter(function (obj) {
              if (obj.sensor == uniqueSensors[j].SensorCollectionID) {
                const sensorName = namedetails.find(
                  (detail) =>
                    detail.sensor === uniqueSensors[j].SensorCollectionID
                )?.name;
                graphData.push({
                  sensor: uniqueSensors[j].SensorCollectionID,
                  field: sensorName || "Unknown Field",
                  date: obj.date,
                  moisture: obj.moisture,
                  status: 10,
                });
              }
            });
            filteredData.push(graphData);
          }
        }
      }
    }
    return filteredData;
  };

  return (
    <div className="App">
      {loading ? (
        <ClipLoader loading={loading} css={override} size={60} />
      ) : (
        <>
          {" "}
          <HighchartsReact
            highcharts={HighStock}
            constructorType={"stockChart"}
            options={getConfig(dataResults)}
          />
        </>
      )}
    </div>
  );
}

export default FieldMoistureandStatusChart;
