import React, { useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import Select from "@material-ui/core/Select";
import {
  userDashboard_,
  updateFieldSelections,
} from "../../../reducers/userDashboard";
import MultiSelect from "react-multi-select-component";
import { GenerateEncryptedToken } from "../../Common";
import smartParkService from "../../../axios";
import PopularTimesChart from "./populartimes";
import {
  DateRange,
  ExpandMore,
  Remove,
  Build,
  Clear,
} from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MCPImg from "../../../assets/img/MorrisvilleCommunityPark.jpg";
import FieldStatusChart from "../../Charts/CitizenFieldStatusChart";
import { AccountCircle, NotificationsOutlined } from "@material-ui/icons";
import CutsomCard from "./../../../components/CustomCard";
import CourtStatusCard from "./../../../components/CourtStatusCard";
import AdminGolfCourseDashboard from "./../../../components/AdminGolfCourseDashBoard";
import logo from "../../../assets/img/Logo.png";
import GolfLogo from "../../../assets/img/GolfLogo.png";
import { Grid, Link, ListItemIcon } from "@material-ui/core";
import WeatherReport from "../../../components/weather-report";
import WeatherReportGolf from "../../../components/weather-report/indexGolf.js";
import NotifyMe from "../../../views/NotifyMe";
import PopularGraph from "../../Charts/PopularGraph";
import CSTCCourtGraph from "../../Charts/CSTCCourtGraph";
import moment from "moment";
// import GolfMoreDetails from "./golf-more-details";
import GolfMoreDetails from "./Admin-golf-more-details.js";
import { setFieldCourts } from "../../../reducers/userDashboard";
import { escapeSelector } from "jquery";
import Auth from "../../../Auth";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {
  loginAuth,
  hasLoaded_,
  login_,
  unmountLogin,
  golfLogiin,
} from "../../../reducers/login";
// import {
//   login_,
//   unmountLogin,
//   usersessionid_,
//   token_,
//   hasLoaded_
// } from "../../../reducers/login";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  section1: {
    background: "#f8f8f8",
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  section2: {
    background: "#fff",
    padding: theme.spacing(1, 4),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    // display: "none",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "right",
      width: "23%",
    },
  },

  AppTitle: {
    cursor: "pointer",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  pageHeader: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  appBar: {
    background: "#fff",
    padding: theme.spacing(1),
  },
  sectionMobile: {
    position: "Relative",
    // [theme.breakpoints.up("sm")]: {
    //   display: "none",
    // },
  },
  mainContent: {
    marginTop: 90,
  },
  userLogin: {
    color: "#444",
    margin: theme.spacing(0, 2),
  },
  refreshButton: {
    background: "#fff !important",
    fontWeight: 600,
  },
}));
const ModalDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {},
  },
}))(Dialog);

const millisecond_in_a_day = 86400000;
export default function AdminGolfDashboard() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [NotifyPopUp, setNotifyPopUp] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [moreDetails, setMoreDetails] = React.useState(false);
  const [istoggle, setIstoggle] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [cardType, setCardType] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);
  const [googleLogin, setGoogleLogin] = React.useState(false);
  const loginHasLoaded = useSelector(hasLoaded_);
  const loginDetails = useSelector(login_);
  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();
  const [state, setState] = React.useState({
    start: moment().subtract(1, "days"),
    end: moment(),
  });
  // Here new code for golf course
  const [GolfCouseData, setGolfCouseData] = React.useState([]);
  const [SingleGolfCouseData, setSingleGolfCouseData] = React.useState([]);

  const getGolfCourseData = () => {
    smartParkService
      .get("api/events/golf-demo/golf/courses")
      .then((res, err) => {
        setGolfCouseData(res.data.data);
        if ((res?.data?.data).length > 0) {
          let Alldata = res.data.data;
          console.log(Alldata);
          let foundItem = Alldata.find(
            (item) => item.name === "Practice Green"
          );
          // 'foundItem' will be the first object in the array where the condition is true
          if (foundItem) {
            setSingleGolfCouseData(foundItem);
          } else {
            console.log("No item found with the specified condition.");
          }
          // setSingleGolfCouseData(Alldata);
        }
      })
      .catch(() => {
        console.log(
          "Error occurred while fetching citizen dashboard sensordata"
        );
      });
  };

  React.useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    setGoogleLogin(params.get("adminmode") === "1" ? true : false);
    if (params.get("sessionexpired")) {
      dispatch(unmountLogin());
      history.push("/golf-login");
    } else if (!loginHasLoaded) {
      history.push("/golf-login");
    }
  }, [loginHasLoaded]);

  React.useEffect(() => {
    getGolfCourseData();
  }, [refresh]);

  const handleOpen = (value) => {
    setState({
      start: moment().subtract(30, "days"),
      end: moment(),
    });
    // setOpen(true);
    //dispatch(setFieldCourts([]));
    setSingleGolfCouseData(value);
    setMoreDetails(true);
    //window.scrollTo(0, 0)
  };
  const handleClose = () => {
    //setOpen(false);
    setCardType("");
  };
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
    setIstoggle(!istoggle);
  };
  const mobileMenuId = "primary-search-account-menu-mobile";
  const Logout = async () => {
    dispatch(unmountLogin());
    Auth.signout();
    history.push("/ctcc");
  };
  const funcrefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <AppBar position="fixed" className={`golfappbar ${classes.appBar}`}>
        <Helmet>
          <title>CAROLINA TRACE COUNTRY CLUB</title>
        </Helmet>
        <Toolbar className={`golftoolbar ${classes.toolbar}`}>
          <div
            className="logoBlockgolf"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (moreDetails) {
                setMoreDetails(false);
              } else {
                window.location.reload();
              }
            }}
          >
            <img src={GolfLogo} alt="Logo" />
          </div>
          <Typography
            variant="h4"
            color="primary"
            className={`golfapptitle ${classes.AppTitle}`}
            onClick={() => {
              if (moreDetails) {
                setMoreDetails(false);
              } else {
                window.location.reload();
              }
            }}
          >
            CAROLINA TRACE COUNTRY CLUB DASHBOARD
          </Typography>
          <div className={`desktopicon ${classes.sectionDesktop}`}>
            {loginHasLoaded && (
              <MenuItem onClick={() => Logout()}>
                <Link color="secondary">
                  <b>Logout</b>
                </Link>
              </MenuItem>
            )}
          </div>
          <div
            className={`mobileicon ${classes.sectionMobile}`}
            onClick={handleMobileMenuOpen}
          >
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              color="primary"
              className="mobilebar"
            >
              <MenuIcon />
            </IconButton>
            {istoggle ? (
              <div className="logoutcss">
                <MenuItem onClick={() => Logout()}>
                  <Link color="secondary">
                    <b>Logout</b>
                  </Link>
                </MenuItem>
              </div>
            ) : (
              ""
            )}
          </div>
        </Toolbar>
      </AppBar>

      {!moreDetails ? (
        <>
          <main className={classes.mainContent}>
            <Helmet>
              <title>CAROLINA TRACE COUNTRY CLUB</title>
            </Helmet>
            <section className={classes.section1}>
              <Grid container spacing={4} className="justify-content-center">
                {/* <Grid item xs={12}>
                <WeatherReportGolf />
              </Grid> */}
                {GolfCouseData &&
                  GolfCouseData.length > 0 &&
                  GolfCouseData.map((obj, idx) => (
                    <Grid item xs={12} md={6} lg={4} key={idx}>
                      <AdminGolfCourseDashboard
                        cardType="golfCourse"
                        actions
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        courseData={obj}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      />
                    </Grid>
                  ))}
              </Grid>
            </section>
          </main>
        </>
      ) : (
        <GolfMoreDetails
          cardType={cardType}
          setMoreDetails={setMoreDetails}
          courseData={SingleGolfCouseData}
          morerefresh={funcrefresh}
        />
      )}
      <footer className="dash-footer">Powered by Varidx</footer>
    </>
  );
}
