export const getParkId = ({ parkCode }) => {
  const parkIds = {
    cfdp: "c7a1d85f-8168-4e64-8e38-116a0624bfea",
    churchstreettenniscourts: "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944",
    mafctenniscourts: "91474e36-76c5-4d59-a8da-2998ab14a4bf",
    ccnp: "c7a1d85f-8168-4e64-8e38-116a0624bfed",
    shiloh: "c7a1d85f-8168-4e64-8e38-116a0624bfec",
    morrisvillecommunitypark: "c7a1d85f-8168-4e64-8e38-116a0624bfeb",
  };

  return parkIds[parkCode] || null;
};
