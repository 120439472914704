import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import Table from "react-bootstrap/Table";
import { Grid, Paper } from "@material-ui/core";
import { BiSolidCheckCircle } from "react-icons/bi";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Button from "../../components/CustomButtons/Button.js";
import smartParkService from "../../axios";
import { useLocation } from "react-router-dom";
import alllog from "../../assets/img/alllog.png";
import printer from "../../assets/img/printer-icn2.png";
import shape from "../../assets/img/shape.png";
import { GenerateEncryptedToken } from "../../views/Common";
import { login_ } from "../../reducers/login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsToggleOn } from "react-icons/bs";
import { MdSettings } from "react-icons/md";
import { BiSolidPencil } from "react-icons/bi";
import { HiArrowLongLeft } from "react-icons/hi2";
import { AiOutlineCopy } from "react-icons/ai";
import { RxUpdate } from "react-icons/rx";
import { HiPencilAlt } from "react-icons/hi";
import update from "../../assets/img/update.png";
import create from "../../assets/img/create.png";
import enable from "../../assets/img/enable.png";
import disable from "../../assets/img/disable.png";
import deleteicon from "../../assets/img/delete.png";
import ignored from "../../assets/img/ignored.png";
// import { useParams } from 'react-router-dom';

import {
    initialState,
    rules_,
    updateRulesState,
    umountRulesState,
} from "../../reducers/rules";
import {
    permissions_,
    Getaccesspermissions,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { todosAction } from "../../reducers/reducers.js";
const weekdayclass = makeStyles((theme) => ({
    weekdayborder: {
        border: "1px solid #fff",
        borderRadius: "0.25rem",
    },
    samepadd: {
        "& .MuiSelect-select": {
            padding: "12px auto !important",
        },
    },
}));
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    close: {
        padding: theme.spacing(0.5),
    },
}));

// Multiple select ================

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

//  emaillist section ====================

let cloneRulesInitialState = _.cloneDeep(initialState.data);

export default function Loglistview() {
    const classes = useStyles();
    const textRef = useRef(null);
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");
    const location = useLocation();
    // console.log(location);
    // const stateDetail = location?.state.detail;
    // const logstatedetail = location?.state.logname;
    // datainput ------=======================================================
    const [loading, setLoading] = React.useState(true);
    const [permission, setpermission] = React.useState({});
    const [getCombineddayofweek, setCombineddayofweek] = React.useState([]);
    const [geteditdata, seteditdata] = useState({});
    const [getauditdata, setauditdata] = useState({});
    const history = useHistory();
    const [getoperational, setoperational] = useState({});
    const [color, setColor] = React.useState("#ffffff");
    const [snackPack, setSnackPack] = React.useState([]);
    const [getRefresh, setRefresh] = React.useState(false);
    const [messageInfo, setMessageInfo] = React.useState(undefined);

    var stateDetail;
    if (location?.state !== undefined) {
        try {
            stateDetail = location?.state?.detail;
        } catch (error) {
            console.log("Location state error " + error);
        }
    }
    // form field store ---------------

    // form dropdown ===================
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);

    const handleCopyClick = () => {
        if (textRef.current) {
            const range = document.createRange();
            range.selectNode(textRef.current);
            window.getSelection().removeAllRanges(); // Deselect any existing selection
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges(); // Deselect the text
            const mes = (
                <div className="toastsuccess">
                    <div className="toasticon">
                        <BiSolidCheckCircle />
                    </div>
                    <div className="toastmessage">
                        <b>Success</b>
                        <div>Copy SuccessFully</div>
                    </div>
                </div>
            );
            toast.success(mes, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: "toastcontainer",
            });
        }
    };

    React.useEffect(() => {
        console.log(stateDetail);
        if (stateDetail !== "" && stateDetail !== undefined) {
            let url = `api/park/ruleengine/viewrulelog/${stateDetail}`;
            var restoken = GenerateEncryptedToken();
            smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        // console.log(res);
                        if (Object.keys(res.data).length == 0) {
                            setRefresh(!getRefresh);
                        }
                        if (res?.data.status == 200) {
                            if (
                                res?.data?.log_info !== null &&
                                res?.data?.log_info !== undefined
                            ) {
                                seteditdata(res?.data?.log_info);
                            }
                            if (
                                res?.data?.audit_info !== null &&
                                res?.data?.audit_info !== undefined
                            ) {
                                setauditdata(res?.data?.audit_info);
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.error("View Rule list error: ", error);
                });
        }
    }, [stateDetail, getRefresh]);

    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);
    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "RuleEngine";
        });
        setpermission(Dpermissions[0]);
    };

    useEffect(() => {
        if (getauditdata !== null) {
            const operationalSetting = getauditdata?.OperationalTime;
            if (operationalSetting !== "" && operationalSetting !== undefined) {
                // Create an object to store consolidated data
                const consolidatedData = {};
                const daysOfWeekOrder = [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                ];
                // Iterate through the array and consolidate data
                operationalSetting?.forEach((item) => {
                    if (!consolidatedData[item.DayofWeek]) {
                        consolidatedData[item.DayofWeek] = {
                            DayofWeek: item.DayofWeek,
                            TimeIntervals: [],
                        };
                    }
                    consolidatedData[item.DayofWeek].TimeIntervals.push({
                        StartTime: item.StartTime,
                        EndTime: item.EndTime,
                    });
                });

                // Print the consolidated data
                const sortedConsolidatedData = {};
                daysOfWeekOrder?.forEach((day) => {
                    if (consolidatedData[day]) {
                        sortedConsolidatedData[day] = consolidatedData[day];
                    }
                });

                setoperational(consolidatedData);
                console.log(sortedConsolidatedData);
            }
        }
    }, [getauditdata]);

    function rulestartenddate(date) {
        try {
            const otherZone = moment
                .tz(date, "America/New_York")
                .format("L h:mm:ss A");
            return otherZone;
        } catch {
            return date;
        }
    }

    function getMonthName(monthNumber) {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        return months[monthNumber - 1];
    }

    // function timeset(time) {
    //   const hours = Math.floor(time / 60);
    //   const min = time % 60;
    //   const formattime = `${hours}Hrs ${min}Min`;
    //   return formattime;
    // }
    function Dayweekconvert(dayweek) {
        if (dayweek === 0) {
            return "Sunday";
        }
        if (dayweek === 1) {
            return "Monday";
        }
        if (dayweek === 2) {
            return "Tuesday";
        }
        if (dayweek === 3) {
            return "Wednesday";
        }
        if (dayweek === 4) {
            return "Thursday";
        }
        if (dayweek === 5) {
            return "Friday";
        }
        if (dayweek === 6) {
            return "Saturday";
        }
    }

    const handlePrintClick = () => {
        window.print();
    };

    function EditRule(ruledataid) {
        if (permission.create == 1) {
            let path = "editrulecreate";
            // setnewrule(true);
            history.push({
                pathname: `${path}`,
                search: "", // Adding query parameters to the URL
                state: { detail: `${ruledataid}` }, // Adding state data
            });
        } else {
            alert("You do not have permission to create rule");
        }
    }

    const viewBackbtn = () => {
        history.go(-1);
    };

    function lastfivechar(ruleidview) {
        const lastFiveChars = "#" + ruleidview.slice(-5);
        return lastFiveChars;
    }
    // new rule create api =========================

    function convertToAMPM(timeStr) {
        // Check if the input has colons
        console.log(timeStr);
        timeStr = timeStr.toString();
        // return timeStr;
        if (timeStr.includes(":")) {
            const [hours, minutes] = timeStr.split(":");
            let period = "AM";
            let parsedHours = parseInt(hours);
            if (parsedHours >= 12) {
                period = "PM";
                if (parsedHours > 12) {
                    parsedHours -= 12;
                }
            }
            return `${parsedHours !== "" ? parsedHours : "00"}:${minutes !== "" ? minutes : "00"
                } ${period}`;
        } else {
            // If no colons, assume it's in the format "HHMM"
            if (timeStr === "0") {
                return "0:00 AM";
            }
            // Assume any three or four-digit input is in "HHMM" format
            if (typeof timeStr !== "string") {
                return "Invalid input";
            }
            // Extract hours and minutes from the input
            const hours = timeStr.substring(0, timeStr.length - 2);
            const minutes = timeStr.substring(timeStr.length - 2);
            let period = "AM";
            // Parse hours and minutes
            let parsedHours = parseInt(hours) || 0; // Use default value if parsing fails
            const parsedMinutes = parseInt(minutes) || 0; // Use default value if parsing fails
            // Adjust period and parsedHours for PM
            if (parsedHours >= 12) {
                period = "PM";
                if (parsedHours > 12) {
                    parsedHours -= 12;
                }
            }

            // Ensure leading zero for single-digit hours and minutes
            const formattedHours = parsedHours.toString().padStart(2, "0");
            const formattedMinutes = parsedMinutes.toString().padStart(2, "0");

            return `${formattedHours}:${formattedMinutes} ${period}`;
        }
    }

    function ruletoauditpage(logstateDetail) {
        let path = "AuditLogpage";
        history.push({
            pathname: `${path}`,
            state: { detail: `${logstateDetail}` },
        });
        window.scrollTo(0, 0);
    }
    const routeAllRulepage = () => {
        if (permission.create == 1) {
            let path = "allrulelogspage";
            history.push({
                pathname: `${path}`,
            });
        } else {
            alert("You do not have permission to All Rule Logs");
        }
    };

    function audittorulepage(logstateDetail) {
        let path = "RuleLogpage";
        history.push({
            pathname: `${path}`,
            state: { detail: `${logstateDetail}` },
        });
        window.scrollTo(0, 0);
    }

    function simpledate(date) {
        try {
            const otherZone = moment
                .tz(date, "America/New_York")
                .format("L h:mm:ss A");
            return otherZone;
        } catch {
            return date;
        }
    }

    return (
        <div className={classes.contentPage}>
            {geteditdata !== "" &&
                geteditdata !== undefined &&
                Object.keys(geteditdata).length > 0 ? (
                <div>
                    <section className="filtersection viewfilter">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <div className="container-fluid top-searchbar">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-12 my-2 rightcenter">
                                            {permission.create == 1 ? (
                                                <div>
                                                    <Button
                                                        className="vieweditbtn viewgeneralbtn"
                                                        size="md"
                                                        variant="contained"
                                                        onClick={routeAllRulepage}
                                                    >
                                                        <img
                                                            src={alllog}
                                                            alt="printerimg"
                                                            className="img-fluid"
                                                        />
                                                        <span className="mx-2">Rule Execution Logs</span>
                                                    </Button>
                                                    <Button
                                                        className="vieweditbtn viewruletoauditbtn"
                                                        size="md"
                                                        variant="contained"
                                                        onClick={() => ruletoauditpage(geteditdata.RuleID)}
                                                    >
                                                        <img
                                                            src={alllog}
                                                            alt="printerimg"
                                                            className="img-fluid"
                                                        />
                                                        <span className="mx-2">Audit Logs</span>
                                                    </Button>
                                                    <Button
                                                        className="vieweditbtn viewaudittorulebtn"
                                                        size="md"
                                                        variant="contained"
                                                        onClick={() => audittorulepage(geteditdata.RuleID)}
                                                    >
                                                        <img
                                                            src={alllog}
                                                            alt="printerimg"
                                                            className="img-fluid"
                                                        />
                                                        <span className="mx-2">Rule Logs</span>
                                                    </Button>
                                                    <Button
                                                        className="vieweditbtn"
                                                        size="md"
                                                        variant="contained"
                                                        onClick={() => EditRule(geteditdata.RuleID)}
                                                    >
                                                        <BiSolidPencil />
                                                        <span className="mx-2">Edit</span>
                                                    </Button>

                                                    <Button
                                                        className="viewprintbtn"
                                                        size="md"
                                                        variant="contained"
                                                        onClick={handlePrintClick}
                                                    >
                                                        <img
                                                            src={printer}
                                                            alt="printerimg"
                                                            className="img-fluid"
                                                        />
                                                        <span className="mx-2">Print</span>
                                                    </Button>
                                                    <Button
                                                        className="viewbackbtn"
                                                        size="md"
                                                        variant="contained"
                                                        onClick={viewBackbtn}
                                                    >
                                                        <HiArrowLongLeft />
                                                        <span className="mx-2">Back</span>
                                                    </Button>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </section>
                    <section className="content mobilecontent">
                        <div className="content-middle rules-middle w-100 ">
                            <div className="container-fluid mobilewidthviewrule">
                                <div className="firstrowviewone">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-4 col-lg-4 mobileruleheaderview">
                                            <div className="ruleid firstrowview logfirstview">
                                                <span className="hash">#</span>
                                                <div>
                                                    <div className="mr-2 sameviewhead"> Rule Id :</div>
                                                    <div className="copyruleid">
                                                        <Accordion className="bg-transparent ruleidacco">
                                                            <AccordionSummary
                                                                expandIcon={<KeyboardArrowRightIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                className="accorhead"
                                                            >
                                                                {geteditdata.RuleID !== "" &&
                                                                    geteditdata.RuleID !== undefined
                                                                    ? lastfivechar(geteditdata.RuleID)
                                                                    : ""}
                                                            </AccordionSummary>
                                                            <AccordionDetails className="d-block">
                                                                <div ref={textRef}>
                                                                    {geteditdata.RuleID}
                                                                    <span
                                                                        className="copy"
                                                                        onClick={handleCopyClick}
                                                                    >
                                                                        <AiOutlineCopy />
                                                                    </span>
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mobileruleheaderview">
                                            <div className="ruleid firstrowview logfirstview">
                                                <span className="priority">
                                                    <img src={shape} className="img-fluid" />
                                                </span>
                                                <div>
                                                    <div className="mr-2 sameviewhead"> Audit ID :</div>
                                                    <div className="copyruleid">
                                                        {geteditdata.AuditID !== "" &&
                                                            geteditdata.AuditID !== undefined ? (
                                                            <Accordion className="bg-transparent ruleidacco">
                                                                <AccordionSummary
                                                                    expandIcon={<KeyboardArrowRightIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                    className="accorhead"
                                                                >
                                                                    {lastfivechar(geteditdata.AuditID)}
                                                                </AccordionSummary>
                                                                <AccordionDetails className="d-block">
                                                                    <div ref={textRef}>
                                                                        {geteditdata.AuditID}
                                                                        <span
                                                                            className="copy"
                                                                            onClick={handleCopyClick}
                                                                        >
                                                                            {" "}
                                                                            <AiOutlineCopy />
                                                                        </span>
                                                                    </div>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        ) : (
                                                            <div className="colorbluetext">NA</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4  mobileruleheaderview">
                                            <div className="ruleid firstrowview logfirstview">
                                                <span className="togglestatus">
                                                    {geteditdata.OperationType == "Update" ? (
                                                        <img src={update} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {geteditdata.OperationType == "Disable" ? (
                                                        <img src={disable} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {geteditdata.OperationType == "Create" ? (
                                                        <img src={create} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {geteditdata.OperationType == "Delete" ? (
                                                        <img src={deleteicon} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {geteditdata.OperationType == "Enable" ? (
                                                        <img src={enable} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {geteditdata.OperationType == "Triggered" ? (
                                                        <img src={ignored} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                                <div>
                                                    <div className="mr-2 sameviewhead">
                                                        {" "}
                                                        Operation Type :
                                                    </div>
                                                    <div className="statuscolor">
                                                        <span className="colorbluetext">
                                                            {geteditdata.OperationType}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="secondrowview my-2">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-12 ">
                                            <div className="leftviewsidelog">
                                                <div className="topbackcolor">
                                                    <div className="row">
                                                        <div className="col-12 col-md-4 col-lg-4 ">
                                                            <div className="leftinnerboxlog emailcss">
                                                                <div className="loghead">Modified By</div>
                                                                <div>
                                                                    Name :{" "}
                                                                    {geteditdata.ModifiedBy?.FullName
                                                                        ? geteditdata.ModifiedBy?.FullName
                                                                        : "None"}
                                                                </div>
                                                                <div>
                                                                    Email :{" "}
                                                                    {geteditdata.ModifiedBy?.Email
                                                                        ? geteditdata.ModifiedBy?.Email
                                                                        : "None"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4 bordersame">
                                                            <div className="leftinnerboxlog emailcss">
                                                                <div className="loghead">
                                                                    Notification Status
                                                                </div>
                                                                <div>
                                                                    Email :{" "}
                                                                    {getauditdata?.sendEmailNotification === true
                                                                        ? "Sent"
                                                                        : "Disable"}
                                                                </div>
                                                                <div>
                                                                    SMS :{" "}
                                                                    {getauditdata?.sendSMSNotification === true
                                                                        ? "Sent"
                                                                        : "Disable"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 col-lg-4">
                                                            <div className="leftinnerboxlog emailcss">
                                                                <div className="loghead">
                                                                    {geteditdata.OperationType} Date
                                                                </div>
                                                                <div>
                                                                    Date : {simpledate(geteditdata.InsertDate)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <hr className="hrbottomline" />
                                                        </div>
                                                        {geteditdata?.Conditions?.length > 0 ? (
                                                            <div className="col-12 col-md-3 col-lg-2 align-self-center">
                                                                <div className="loghead leftinnerboxlog">
                                                                    Conditions
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {geteditdata?.Conditions?.length > 0 ? (
                                                            <div className="col-12 col-md-9 col-lg-10">
                                                                <div className="row leftinnerboxlog mobilemt">
                                                                    {geteditdata.Conditions?.map((data, idx) => {
                                                                        return (
                                                                            <div
                                                                                className="col-12 col-md-6 col-lg-6 conditiontable"
                                                                                key={idx}
                                                                            >
                                                                                <Table
                                                                                    size="sm"
                                                                                    className="viewcondition logviewcondition h-100"
                                                                                >
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Park Name :{" "}
                                                                                                <div>
                                                                                                    {data.ParkName === "Morrisville Community Park"
                                                                                                        ? "MCP"
                                                                                                        : data.ParkName ===
                                                                                                            "Morrisville Aquatic and Fitness Center"
                                                                                                            ? "MAFC"
                                                                                                            : data.ParkName ===
                                                                                                                "Cedar Fork District Park"
                                                                                                                ? "CFDP" : data.ParkName === "Shiloh Park" ? 'SP'
                                                                                                                    : data.ParkName === "Crabtree Creek Nature Park" ? 'CCNP'
                                                                                                                        : data.ParkName === "Church Street Park" ? 'CSP'
                                                                                                                            : "NA"}
                                                                                                </div>{" "}
                                                                                            </td>
                                                                                            <td>
                                                                                                Device Type :{" "}
                                                                                                <div> {data.Devicetype} </div>{" "}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Device Name :{" "}
                                                                                                <div> {data.DeviceName} </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                Trigger Criteria :{" "}
                                                                                                <div>
                                                                                                    {" "}
                                                                                                    {data.TriggerCriteria}{" "}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Trigger Value :{" "}
                                                                                                <div> {data.TriggerValue} </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                Duration :{" "}
                                                                                                <div> {data.Tolerance} </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                {data.ObservedState
                                                                                                    ? "ObservedMessage"
                                                                                                    : "Observed Value"}{" "}
                                                                                                :
                                                                                                <div>
                                                                                                    {" "}
                                                                                                    {`${data.ObservedState
                                                                                                            ? data.ObservedState
                                                                                                                .ObservedMessage
                                                                                                            : data.ObservedValue
                                                                                                        }`}{" "}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                Status :{" "}
                                                                                                <div>
                                                                                                    {" "}
                                                                                                    {`${data.ObservedState
                                                                                                            ? data.ObservedState
                                                                                                                .wasConditionMet
                                                                                                            : data.Status
                                                                                                        }`}{" "}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </Table>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {geteditdata?.Actions?.length > 0 ? (
                                                            <div className="col-12 col-md-3 col-lg-2 align-self-center">
                                                                <div className="loghead leftinnerboxlog">
                                                                    Actions
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {geteditdata?.Actions?.length > 0 ? (
                                                            <div className="col-12 col-md-9 col-lg-10">
                                                                <div className="row leftinnerboxlog mobilemt">
                                                                    {geteditdata.Actions?.map((data, idx) => {
                                                                        return (
                                                                            <div
                                                                                className="col-12 col-md-6 col-lg-6 conditiontable"
                                                                                key={idx}
                                                                            >
                                                                                <Table
                                                                                    size="sm"
                                                                                    className="viewcondition h-100 logviewcondition"
                                                                                >
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Park Name :{" "}
                                                                                                <div>
                                                                                                    {data.ParkName === "Morrisville Community Park"
                                                                                                        ? "MCP"
                                                                                                        : data.ParkName ===
                                                                                                            "Morrisville Aquatic and Fitness Center"
                                                                                                            ? "MAFC"
                                                                                                            : data.ParkName ===
                                                                                                                "Cedar Fork District Park"
                                                                                                                ? "CFDP" : data.ParkName === "Shiloh Park" ? 'SP'
                                                                                                                    : data.ParkName === "Crabtree Creek Nature Park" ? 'CCNP'
                                                                                                                        : data.ParkName === "Church Street Park" ? 'CSP'
                                                                                                                            : "NA"}
                                                                                                </div>{" "}
                                                                                            </td>
                                                                                            <td>
                                                                                                Device Type :{" "}
                                                                                                <div> {data.Devicetype} </div>{" "}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Device Name :{" "}
                                                                                                <div> {data.DeviceName} </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                Action :{" "}
                                                                                                <div> {data.Action} </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                Reason :{" "}
                                                                                                <div>{data.Reason}</div>
                                                                                            </td>
                                                                                            <td>
                                                                                                Status :{" "}
                                                                                                <div>{data.Status}</div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </Table>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {getauditdata !== "" &&
                                                        getauditdata !== undefined &&
                                                        getauditdata !== null &&
                                                        Object.keys(getauditdata).length > 0 ? (
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="rightsideview">
                                                                <div className="row">
                                                                    <div className="col-12 col-md-3 col-lg-3">
                                                                        <div className="rulekey"> Rule Name </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-9 col-lg-9">
                                                                        <div className="rulevalue">
                                                                            {" "}
                                                                            {getauditdata.RuleName}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-3 col-lg-3">
                                                                        <div className="rulekey">
                                                                            {" "}
                                                                            Rule Description
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-9 col-lg-9">
                                                                        <div className="rulevalue">
                                                                            {" "}
                                                                            {getauditdata.RuleDescription}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-3 col-lg-3">
                                                                        <div className="rulekey">
                                                                            {" "}
                                                                            Priority Number
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-9 col-lg-9">
                                                                        <div className="rulevalue">
                                                                            {getauditdata.PriorityValue}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-3 col-lg-3">
                                                                        <div className="rulekey">
                                                                            {" "}
                                                                            Validity Settings
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-9 col-lg-9">
                                                                        <div className="rulevalue">
                                                                            {getauditdata.RuleIsAlwaysOn === true ? (
                                                                                "Rule Always ON"
                                                                            ) : (
                                                                                <div>
                                                                                    <span>Start Date : </span>{" "}
                                                                                    {rulestartenddate(
                                                                                        getauditdata.RuleValidityStartDate
                                                                                    )}{" "}
                                                                                    &nbsp;&nbsp;&nbsp;{" "}
                                                                                    <span>End Date : </span>{" "}
                                                                                    {rulestartenddate(
                                                                                        getauditdata.RuleValidityEndDate
                                                                                    )}{" "}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-3 col-lg-3">
                                                                        <div className="rulekey">
                                                                            {" "}
                                                                            Operational Settings
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-9 col-lg-9">
                                                                        <div className="rulevalue">
                                                                            <div className="row">
                                                                                {getoperational &&
                                                                                    Object.keys(getoperational).length >
                                                                                    0 ? (
                                                                                    <div className="container-fluid paddright py-1">
                                                                                        <div className="row printpageview">
                                                                                            {Object.values(
                                                                                                getoperational
                                                                                            )?.map((dayData, index) => (
                                                                                                <div
                                                                                                    className="col-6 col-md-3 col-lg-3"
                                                                                                    key={index}
                                                                                                >
                                                                                                    <div className="viewdayofweek h-100">
                                                                                                        <Accordion className="viewaccordion">
                                                                                                            <AccordionSummary
                                                                                                                expandIcon={
                                                                                                                    <KeyboardArrowRightIcon />
                                                                                                                }
                                                                                                                aria-controls={`panel${index + 1
                                                                                                                    }a-content`}
                                                                                                                id={`panel${index + 1
                                                                                                                    }a-header`}
                                                                                                                className="accorhead"
                                                                                                            >
                                                                                                                <Typography
                                                                                                                    className={
                                                                                                                        classes.heading
                                                                                                                    }
                                                                                                                >
                                                                                                                    {Dayweekconvert(
                                                                                                                        dayData.DayofWeek
                                                                                                                    )}
                                                                                                                </Typography>
                                                                                                            </AccordionSummary>
                                                                                                            <AccordionDetails className="d-block">
                                                                                                                {dayData.TimeIntervals?.map(
                                                                                                                    (
                                                                                                                        timeInterval,
                                                                                                                        idx
                                                                                                                    ) => (
                                                                                                                        <Typography
                                                                                                                            key={idx}
                                                                                                                            className="ruletime"
                                                                                                                        >
                                                                                                                            {convertToAMPM(
                                                                                                                                timeInterval.StartTime
                                                                                                                            )}{" "}
                                                                                                                            To{" "}
                                                                                                                            {convertToAMPM(
                                                                                                                                timeInterval.EndTime
                                                                                                                            )}
                                                                                                                        </Typography>
                                                                                                                    )
                                                                                                                )}
                                                                                                            </AccordionDetails>
                                                                                                        </Accordion>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-3 col-lg-3">
                                                                        <div className="rulekey">
                                                                            {" "}
                                                                            Triggering Strategy
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-9 col-lg-9">
                                                                        <div className="rulevalue">
                                                                            {getauditdata.TriggerStrategy}
                                                                        </div>
                                                                    </div>
                                                                    {getauditdata.TriggerStrategy !==
                                                                        "TIME BASED RULE" ? (
                                                                        <div className="col-12 col-md-3 col-lg-3">
                                                                            <div className="rulekey">
                                                                                {" "}
                                                                                Conditional Settings
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {getauditdata.TriggerStrategy !==
                                                                        "TIME BASED RULE" ? (
                                                                        <div className="col-12 col-md-9 col-lg-9">
                                                                            <div className="rulevalue">
                                                                                <div className="row">
                                                                                    {getauditdata.Conditions?.map(
                                                                                        (data, idx) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className="col-12 col-md-6 col-lg-6 conditiontable"
                                                                                                    key={idx}
                                                                                                >
                                                                                                    <Table
                                                                                                        size="sm"
                                                                                                        className="viewcondition"
                                                                                                    >
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    Park Name :{" "}
                                                                                                                    <div>
                                                                                                                        {data.ParkName === "Morrisville Community Park"
                                                                                                                            ? "MCP"
                                                                                                                            : data.ParkName ===
                                                                                                                                "Morrisville Aquatic and Fitness Center"
                                                                                                                                ? "MAFC"
                                                                                                                                : data.ParkName ===
                                                                                                                                    "Cedar Fork District Park"
                                                                                                                                    ? "CFDP" : data.ParkName === "Shiloh Park" ? 'SP'
                                                                                                                                        : data.ParkName === "Crabtree Creek Nature Park" ? 'CCNP'
                                                                                                                                            : data.ParkName === "Church Street Park" ? 'CSP'
                                                                                                                                                : "NA"}
                                                                                                                    </div>{" "}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    Device Type :{" "}
                                                                                                                    <div>
                                                                                                                        {" "}
                                                                                                                        {
                                                                                                                            data.Devicetype
                                                                                                                        }{" "}
                                                                                                                    </div>{" "}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    Device Name :{" "}
                                                                                                                    <div>
                                                                                                                        {" "}
                                                                                                                        {
                                                                                                                            data.DeviceName
                                                                                                                        }{" "}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    Trigger Criteria :{" "}
                                                                                                                    <div>
                                                                                                                        {" "}
                                                                                                                        {
                                                                                                                            data.TriggerCriteria
                                                                                                                        }{" "}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    Trigger Value :{" "}
                                                                                                                    <div>
                                                                                                                        {" "}
                                                                                                                        {
                                                                                                                            data.TriggerValue
                                                                                                                        }{" "}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    Duration :{" "}
                                                                                                                    <div>
                                                                                                                        {" "}
                                                                                                                        {
                                                                                                                            data.Tolerance
                                                                                                                        }{" "}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </Table>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {getauditdata.Actions &&
                                                                        getauditdata.Actions.length > 0 && (
                                                                            <div className="col-12 col-md-3 col-lg-3">
                                                                                <div className="rulekey">
                                                                                    Actions Settings
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    {getauditdata.Actions &&
                                                                        getauditdata.Actions.length > 0 && (
                                                                            <div className="col-12 col-md-9 col-lg-9">
                                                                                <div className="rulevalue">
                                                                                    <div className="row">
                                                                                        {getauditdata.Actions?.map(
                                                                                            (data, idx) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className="col-12 col-md-6 col-lg-6 conditiontable"
                                                                                                        key={idx}
                                                                                                    >
                                                                                                        <Table
                                                                                                            size="sm"
                                                                                                            className="viewcondition"
                                                                                                        >
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        Park Name :{" "}
                                                                                                                        <div>
                                                                                                                            {data.ParkName === "Morrisville Community Park"
                                                                                                                                ? "MCP"
                                                                                                                                : data.ParkName ===
                                                                                                                                    "Morrisville Aquatic and Fitness Center"
                                                                                                                                    ? "MAFC"
                                                                                                                                    : data.ParkName ===
                                                                                                                                        "Cedar Fork District Park"
                                                                                                                                        ? "CFDP" : data.ParkName === "Shiloh Park" ? 'SP'
                                                                                                                                            : data.ParkName === "Crabtree Creek Nature Park" ? 'CCNP'
                                                                                                                                                : data.ParkName === "Church Street Park" ? 'CSP'
                                                                                                                                                    : "NA"}
                                                                                                                        </div>{" "}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        Device Type :{" "}
                                                                                                                        <div>
                                                                                                                            {" "}
                                                                                                                            {
                                                                                                                                data.Devicetype
                                                                                                                            }{" "}
                                                                                                                        </div>{" "}
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        Device Name :{" "}
                                                                                                                        <div>
                                                                                                                            {" "}
                                                                                                                            {
                                                                                                                                data.DeviceName
                                                                                                                            }{" "}
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        Action :{" "}
                                                                                                                        <div>
                                                                                                                            {" "}
                                                                                                                            {data.Action}{" "}
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </Table>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    {(getauditdata.SMSRecipients !== undefined &&
                                                                        getauditdata.SMSRecipients.length > 0 &&
                                                                        getauditdata.SMSBody !== "") ||
                                                                        (getauditdata.EmailRecipients !== undefined &&
                                                                            getauditdata.EmailRecipients.length > 0 &&
                                                                            getauditdata.EmailBody !== "") ? (
                                                                        <div className="col-12 col-md-3 col-lg-3">
                                                                            <div className="rulekey destopviewnotify">
                                                                                Notification Settings
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    {(getauditdata.SMSRecipients !== undefined &&
                                                                        getauditdata.SMSRecipients.length > 0 &&
                                                                        getauditdata.SMSBody !== "") ||
                                                                        (getauditdata.EmailRecipients !== undefined &&
                                                                            getauditdata.EmailRecipients.length > 0 &&
                                                                            getauditdata.EmailBody !== "") ? (
                                                                        <div className="col-12 col-md-9 col-lg-9">
                                                                            <div className="rulevalue ">
                                                                                <div className="row">
                                                                                    {getauditdata.SMSRecipients !==
                                                                                        undefined &&
                                                                                        getauditdata.SMSRecipients.length >
                                                                                        0 &&
                                                                                        getauditdata.SMSBody !== "" ? (
                                                                                        <div className="col-12 col-md-6 col-lg-6 conditiontable">
                                                                                            <div className="smsnotify">
                                                                                                <div className="smshead">
                                                                                                    SMS Notification Users Name :{" "}
                                                                                                </div>
                                                                                                {getauditdata.SMSRecipients?.map(
                                                                                                    (data, idx) => {
                                                                                                        return (
                                                                                                            <span key={idx}>
                                                                                                                {getauditdata
                                                                                                                    .SMSRecipients
                                                                                                                    ?.length > 1
                                                                                                                    ? ", "
                                                                                                                    : ""}{" "}
                                                                                                                {data}{" "}
                                                                                                            </span>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="col-12 col-md-6 col-lg-6 conditiontable">
                                                                                            <div className="smsnotify">
                                                                                                <div className="smshead">
                                                                                                    SMS Notification{" "}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                    {getauditdata.SMSRecipients !==
                                                                                        undefined &&
                                                                                        getauditdata.SMSRecipients.length >
                                                                                        0 &&
                                                                                        getauditdata.SMSBody !== "" ? (
                                                                                        <div className="col-12 col-md-6 col-lg-6 conditiontable">
                                                                                            <div className="smsnotify">
                                                                                                <div className="smshead">
                                                                                                    {" "}
                                                                                                    SMS Message :{" "}
                                                                                                </div>
                                                                                                <span>
                                                                                                    {getauditdata.SMSBody}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="col-12 col-md-6 col-lg-6 conditiontable">
                                                                                            <div className="smsnotify">
                                                                                                <div className="smshead">
                                                                                                    Disabled{" "}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}

                                                                                    {getauditdata.EmailRecipients !==
                                                                                        undefined &&
                                                                                        getauditdata.EmailRecipients.length >
                                                                                        0 &&
                                                                                        getauditdata.EmailBody !== "" ? (
                                                                                        <div className="col-12 col-md-6 col-lg-6 conditiontable">
                                                                                            <div className="smsnotify">
                                                                                                <div className="smshead">
                                                                                                    {" "}
                                                                                                    Email Notification Users Name
                                                                                                    :{" "}
                                                                                                </div>
                                                                                                {getauditdata.EmailRecipients?.map(
                                                                                                    (data, idx) => {
                                                                                                        return (
                                                                                                            <span key={idx}>
                                                                                                                {getauditdata
                                                                                                                    .EmailRecipients
                                                                                                                    ?.length > 1
                                                                                                                    ? ", "
                                                                                                                    : ""}{" "}
                                                                                                                {data}{" "}
                                                                                                            </span>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="col-12 col-md-6 col-lg-6 conditiontable">
                                                                                            <div className="smsnotify">
                                                                                                <div className="smshead">
                                                                                                    Email Notification{" "}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                    {getauditdata.EmailRecipients !==
                                                                                        undefined &&
                                                                                        getauditdata.EmailRecipients.length >
                                                                                        0 &&
                                                                                        getauditdata.EmailBody !== "" ? (
                                                                                        <div className="col-12 col-md-6 col-lg-6 conditiontable">
                                                                                            <div className="smsnotify">
                                                                                                <div className="smshead">
                                                                                                    {" "}
                                                                                                    Email Message :{" "}
                                                                                                </div>
                                                                                                <span>
                                                                                                    {getauditdata.EmailBody}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="col-12 col-md-6 col-lg-6 conditiontable">
                                                                                            <div className="smsnotify">
                                                                                                <div className="smshead">
                                                                                                    Disabled{" "}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    {/* <div className="col-12 col-md-3 col-lg-3">
                                    <div className="rulekey">
                                      {" "}
                                      Override Settings
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-9 col-lg-9">
                                    <div className="rulevalue">
                                      <span>Block Duration : </span>{" "}
                                      {timeset(getauditdata.BlockDuration)}
                                    </div>
                                  </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                <div className="h-100">
                    <ClipLoader
                        color={color}
                        loading={loading}
                        css={override}
                        size={60}
                    />
                </div>
            )}
        </div>
    );
}
