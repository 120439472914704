/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import PopularTimesChart from "../UserDashboard/Dashboard/populartimes";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../Common";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import moment from "moment";
import {
  selectedFieldCourts_,
  selectedFieldDetails_,
} from "../../reducers/userDashboard";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  Select,
  MenuItem,
  Button,
  ListSubheader,
} from "@material-ui/core";
const underLine = {
  borderBottomWidth: "3px",
  borderBottomStyle: "solid",
  borderBottomColor: "#8ab4f8",
  cursor: "pointer",
};
const formatDate = (timestamp) => {
  let formattedDate;
  formattedDate = moment.utc(timestamp).format("MM-DD-YYYY HH:mm:ss") + " GMT" + moment.utc(timestamp).format("Z");
  return formattedDate
}
const downloadCSV = (
  graph,
  selectedDateRange,
  selectedCourt,
  MAFCCourtData,
  selectedDay
) => {
  const courtName = MAFCCourtData.filter(
    (obj) => obj.court_api_id === selectedCourt
  )[0].court_name;
  const courtgroupName = MAFCCourtData.filter(
    (obj) => obj.court_api_id === selectedCourt
  )[0].FieldGroup;
  const startDate = selectedDateRange.start;
  const endDate = selectedDateRange.end;
  // startDate.utc();
  // endDate.utc();

  const csvRows = JSON.parse(JSON.stringify(graph));
  csvRows.unshift(["time", "occupancy"]);
  csvRows.unshift(["FieldGroup: ", courtgroupName]);
  csvRows.unshift(["Court: ", courtName]);
  csvRows.unshift(["Selected Day: ", moment().day(selectedDay).format("dddd")]);
  csvRows.unshift(["Range", formatDate(startDate) + " - " + formatDate(endDate)]);
  // Convert rows to CSV content
  let csvContent =
    "data:text/csv;charset=utf-8," +
    csvRows.map((row) => row.join(",")).join("\n");
  // Create a download link and trigger the download
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  const file_name =
    "popular-times-" + moment().format("MMDDYYYY-HHmmss") + ".csv";
  link.setAttribute("download", file_name);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const PopularGraph = (props) => {
  const override = css`
    display: block;
    margin: 100px auto;
    border-color: #0076bb;
  `;
  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();

  // console.log(">>>", props, props.selectedCourt);

  const momenttz = require("moment-timezone");
  const [graphData, setGraphData] = useState([]);
  const MAFCCourtData = useSelector(selectedFieldCourts_);
  const selectedField = useSelector(selectedFieldDetails_);
  const [groupedItems, setgroupedItems] = React.useState({});
  const [selectedCourt, setSelectedCourts] = useState(props.selectedCourt);
  // const [selectedCourt, setSelectedCourts] = useState("646ad9ae1d3c8eb8022cdb93");
  const [loading, setLoading] = useState(true);
  const [getMcppopulargraph, setMcppopulargraph] = useState([]);
  const [selectedDay, setSelectedDay] = useState(
    Number(moment(new Date()).day())
  );

  useEffect(() => {
    var mcpdata;
    mcpdata = localStorage.getItem("mcpcourtsdta");
    var mcpdataArray = JSON.parse(mcpdata);
    setMcppopulargraph(mcpdataArray);
  }, []);

  // Grouping mcp pickel and tenis courts in popular graph==============

  useEffect(() => {
    let groupedItemsone = {};
    if (getMcppopulargraph?.length > 0) {
      getMcppopulargraph?.forEach((obj) => {
        if (!groupedItemsone[obj.FieldGroup]) {
          groupedItemsone[obj.FieldGroup] = [];
        }
        groupedItemsone[obj.FieldGroup].push(obj);
      });
      setgroupedItems(groupedItemsone);
    }
  }, [getMcppopulargraph]);

  useEffect(() => {
    setLoading(true);
    const path =
      selectedField.key === "mafctenniscourts"
        ? "courts"
        : selectedField.key === "churchstreettenniscourts"
          ? "churchstreetcourts"
          : "mcpcourts";
    let startDate = new Date("01/01/2020").toISOString();
    let endDate = new Date().toISOString();
    const localTimeZoneOffset =
      (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;
    if (selectedField.parkid === "c7a1d85f-8168-4e64-8e38-116a0624bfeb") {
      if (props.selectedDateRange) {
        const dates = props.selectedDateRange;
        var startdate =
          moment(new Date(dates.start)).format("YYYY-MM-DD").toString() +
          "T00:00:00";
        startDate = momenttz.tz(startdate, "America/New_York");
        startDate = startDate.unix() * 1000;
        var enddate =
          moment(new Date(dates.end)).format("YYYY-MM-DD").toString() +
          "T23:59:59";
        endDate = momenttz.tz(enddate, "America/New_York");
        endDate = endDate.unix() * 1000;
        // Set time to 23:59:59.999
      }
    } else {
      if (props.selectedDateRange) {
        const dates = props.selectedDateRange;
        // Convert selected date range to timestamps
        var startdate =
          moment(new Date(dates.start)).format("YYYY-MM-DD").toString() +
          "T00:00:00";
        startDate = momenttz.tz(startdate, "America/New_York");
        startDate = startDate.unix() * 1000;
        var enddate =
          moment(new Date(dates.end)).format("YYYY-MM-DD").toString() +
          "T23:59:59";
        endDate = momenttz.tz(enddate, "America/New_York");
        endDate = endDate.unix() * 1000;
      }
    }

    if (selectedCourt) {
      var url = `api/${path}/graphdata?startDate=${startDate}&endDate=${endDate}&courtid=${selectedCourt}&weakday=${selectedDay}`;
      smartParkService.get(url).then((res, err) => {
        if (res.data.status) {
          setGraphData(res.data.resultArray);
          setLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCourt,
    selectedDay,
    props.selectedDateRange.start._d,
    props.selectedDateRange.end._d,
  ]);

  function asdf(e) {
    setSelectedCourts(e.target.value);
  }

  return (
    <>
      <Card elevation={8}>
        <CardHeader
          style={{ height: "10px", marginTop: "10px", marginBottom: "10px" }}
          title="Popular Times"
          action={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                gap: "10px",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                aria-label="settings"
                onClick={() => {
                  const courtData =
                    selectedField.key === "mcpfields"
                      ? getMcppopulargraph
                      : MAFCCourtData;
                  downloadCSV(
                    graphData,
                    props.selectedDateRange,
                    selectedCourt,
                    courtData,
                    selectedDay
                  );
                }}
              >
                Export Data
              </Button>
            </Box>
          }
        ></CardHeader>
        <CardContent>
          {selectedField.parkid === "c7a1d85f-8168-4e64-8e38-116a0624bfeb" ? (
            <>
              {/* <Select
                style={{
                  width: "200px",
                  height: "35px",
                  marginTop: "-10px",
                  marginBottom: "10px"
                }}
                variant="outlined"
                size="small"
                labelId="label"
                value={selectedCourt}
                onChange={handleSelectChange}
              >
                {Object.keys(groupedItems).map(group => (
                  <div key={group}>
                    <ListSubheader>
                      {group}
                    </ListSubheader>
                    {groupedItems[group].map(obj => (
                      <MenuItem value={obj.court_api_id} key={obj.court_api_id}>
                        {obj.court_name}
                      </MenuItem>
                    ))}
                  </div>
                ))}
              </Select> */}
              <select
                id="courtSelect"
                onChange={(e) => asdf(e)}
                className="courtselect"
                value={selectedCourt}
              >
                {Object.keys(groupedItems).map((group) => (
                  <optgroup label={group} key={group}>
                    {groupedItems[group].map((obj) => (
                      <option value={obj.court_api_id} key={obj.court_api_id}>
                        {obj.court_name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </>
          ) : (
            <>
              <Select
                style={{
                  width: "200px",
                  height: "35px",
                  marginTop: "-10px",
                  marginBottom: "10px",
                }}
                variant="outlined"
                size="small"
                labelId="label"
                value={selectedCourt}
                onChange={(e) => {
                  setSelectedCourts(e.target.value);
                }}
              >
                {MAFCCourtData &&
                  MAFCCourtData.length > 0 &&
                  MAFCCourtData.map((obj) => (
                    <MenuItem value={obj.court_api_id}>
                      {obj.court_name}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={selectedDay === 0 ? underLine : { cursor: "pointer" }}
              onClick={() => {
                setSelectedDay(0);
              }}
            >
              Sun
            </div>
            <div
              style={selectedDay === 1 ? underLine : { cursor: "pointer" }}
              onClick={() => {
                setSelectedDay(1);
              }}
            >
              Mon
            </div>
            <div
              style={selectedDay === 2 ? underLine : { cursor: "pointer" }}
              onClick={() => {
                setSelectedDay(2);
              }}
            >
              Tue
            </div>
            <div
              style={selectedDay === 3 ? underLine : { cursor: "pointer" }}
              onClick={() => {
                setSelectedDay(3);
              }}
            >
              Wed
            </div>
            <div
              style={selectedDay === 4 ? underLine : { cursor: "pointer" }}
              onClick={() => {
                setSelectedDay(4);
              }}
            >
              Thur
            </div>
            <div
              style={selectedDay === 5 ? underLine : { cursor: "pointer" }}
              onClick={() => {
                setSelectedDay(5);
              }}
            >
              Fri
            </div>
            <div
              style={selectedDay === 6 ? underLine : { cursor: "pointer" }}
              onClick={() => {
                setSelectedDay(6);
              }}
            >
              Sat
            </div>
          </div>
          {loading ? (
            <ClipLoader loading={loading} css={override} size={60} />
          ) : (
            <PopularTimesChart graphData={graphData} />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default PopularGraph;
