import smartParkService from "../axios";

export const getDataNotifyUsersMobile = async (mobilePhone) => {
    return await (await smartParkService.get(`/api/park/notifyusersmobile?phone=${mobilePhone}`));
}

export const getDataNotifyUsersEmail = async (emailAddress) => {
    return await (await smartParkService.get(`/api/park/notifyusersemail?email=${emailAddress}`));
}

export const notifyMeServicePost = async (payloadObj) => {
    return await (await smartParkService.post('/api/park/notifyusers',payloadObj)).data.data;
}

export const getCourts = async () => {    
    return await (await smartParkService.get('api/courts')).data;
}