import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    textAlign: "center",
    paddingBottom: 10,
    marginTop: 20
  },
  company: { fontWeight: "bold", fontSize: "1rem" },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container>
        <Typography variant="body2">
          Powered by &nbsp;
          <Link
            className={classes.company}
            onClick={() => window.open("https://varidx.io", "_blank")}
          >
            Varidx
          </Link>
        </Typography>
      </Container>
    </footer>
  );
}
