import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { Link } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "./FieldModel";
import HoleFormDialog from "./HolesClose.js";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import IconButton from "@material-ui/core/IconButton";
import { Clear, OpenInNewRounded } from "@material-ui/icons";
import { login_ } from "../../reducers/login";
import { makeStyles } from "@material-ui/core/styles";
import {
    permissions_,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import {
    sensors_,
    sensorsHasLoaded_,
    sensorServiceThunk,
} from "../../reducers/sensors";
import _ from "lodash";
import "../../assets/scss/App.scss";
import { updateCurrentFieldStatus } from "../../reducers/userDashboard";

const ButtonLink = withStyles((theme) => ({
    root: {
        display: "inline !important",
        color: "blue !important",
        marginRight: "10px !important",
        padding: "0 !important",
        textDecoration: "underline",
        border: "none !important",
        fontSize: "13px !important",
        [theme.breakpoints.down("xl")]: {
            fontSize: "14px !important",
        },
    },
}))(Link);

const useStyles = makeStyles(styles);

export default function GolfHoleStatus(props) {
    const dispatch = useDispatch();
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const sensors = useSelector(sensors_);
    const sensorHasLoaded = useSelector(sensorsHasLoaded_);
    const [sensorstatus, setsensorstatus] = React.useState({});
    const [status, setstatus] = React.useState([]);
    const [open, setopen] = React.useState(false);
    const [permission, setpermission] = React.useState({});
    const [Openorclose, setOpenorclose] = React.useState("");
    const [fieldNames, setfieldNames] = React.useState([]);
    const [Iselectall, setIselectall] = React.useState(false);
    const [allholeChecked, setallholeChecked] = React.useState(false);
    const [btnopenactive, setopenbtnactive] = React.useState(false);
    const [btncloseactive, setclosebtnactive] = React.useState(false);
    const [refresh, setrefresh] = React.useState(false);
    const [allChecked, setallChecked] = React.useState(false);
    const [statusarray, setstatusarray] = React.useState([]);
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);
    const classes = useStyles();
    const GolfCourseData = props?.GolfHoleData;

    useEffect(() => {
        if (fieldNames.length > 0) {
            const isOpen = fieldNames.every((item) => item.HoleStatus === "Open");
            const isClosed = fieldNames.every((item) => item.HoleStatus === "Close");
            if (isOpen) {
                dispatch(updateCurrentFieldStatus("All Fields Open"));
            } else if (isClosed) {
                dispatch(updateCurrentFieldStatus("All Fields Closed"));
            } else {
                const l = fieldNames.filter((item) => item.HoleStatus === "Open");
                // console.log("l", fieldNames, isOpen, isClosed, l);
                dispatch(
                    updateCurrentFieldStatus(
                        `${l.length}/${fieldNames.length} Fields Open`
                    )
                );
            }
        } else {
            dispatch(updateCurrentFieldStatus(null));
        }
    }, [fieldNames]);

    const getGolfholes = () => {
        try {
            if (GolfCourseData && GolfCourseData?.hole_data.length > 0) {
                var data = GolfCourseData?.hole_data;
                console.log(data);
                var dataconst = data.map((d) => {
                    return {
                        fieldID: d.HoleID,
                        isChecked: false,
                        fieldName: d.HoleName,
                        fieldStatus: d.HoleStatus,
                        fieldfinalstatus: d.finalStatus,
                    };
                });
                var fieldstatusdta = data.map((e) => {
                    return {
                        fieldStatus: e.FieldStatus,
                    };
                });

                let statarr = [];
                fieldstatusdta.forEach((element) => {
                    statarr.push(element.fieldStatus);
                });
                setstatusarray(statarr);
                setfieldNames(dataconst);
            }
        } catch {
            console.log("error in  gole hole");
        }
    };
    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "FieldDisplayStatus";
        });
        setpermission(Dpermissions[0]);
    };
    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);

    React.useEffect(() => {
        getGolfholes();
    }, [GolfCourseData, refresh]);

    const handleClose = () => {
        setopen(false);
        setallChecked(false);
        setopenbtnactive(false);
        setclosebtnactive(false);
        setrefresh(!refresh);
        let fieldNames1 = fieldNames.map((item) => ({
            ...item,
            isChecked: false,
        }));
        setfieldNames(fieldNames1);
        props.refreshmorepage();
    };

    const handleFieldopen = () => {
        var isListChecked = false;
        if (
            fieldNames.filter(function (obj) {
                if (obj.isChecked) isListChecked = true;
            })
        );
        if (isListChecked) {
            setOpenorclose("Open");
            setopen(true);
        }
    };
    const handleFieldClose = () => {
        var isListChecked = false;
        if (
            fieldNames.filter(function (obj) {
                if (obj.isChecked) isListChecked = true;
            })
        );
        if (isListChecked) {
            setOpenorclose("Close");
            setopen(true);
        }
    };
    const handleChange = (e) => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        let fieldNames1 = fieldNames;
        let allChecked1 = allChecked;
        if (itemName === "checkAll") {
            allChecked1 = checked;
            fieldNames1 = fieldNames.map((item) => ({
                ...item,
                isChecked: checked,
            }));
        } else {
            fieldNames1 = fieldNames.map((item) =>
                item.fieldID === itemName ? { ...item, isChecked: checked } : item
            );
            allChecked1 = fieldNames1.every((item) => item.isChecked);
        }

        setopenbtnactive(false);
        setclosebtnactive(false);
        fieldNames1.map((item) => {
            if (item.fieldStatus === "Open") {
                if (item.isChecked == true) {
                    setopenbtnactive(true);
                    setclosebtnactive(true);
                }
            } else if (item.fieldStatus === "Close") {
                if (item.isChecked == true) {
                    setopenbtnactive(true);
                    setclosebtnactive(true);
                }
            }
        });
        setfieldNames(fieldNames1);
        setallChecked(allChecked1);
    };
    const updateStateList = (e, value) => {
        if (e.target.checked) {
            //append to array
            // this.setState({
            //   status: status.concat([value]),
            // });
            setstatus(status.concat([value]));
        } else {
            //remove from array
            setstatus(
                status.filter(function (val) {
                    return val !== value;
                })
            );
        }
    };
    React.useEffect(() => {
        setopenbtnactive(false);
        setclosebtnactive(false);
    }, []);

    return (
        <div>
            <HoleFormDialog
                show={open}
                status={fieldNames}
                closePopup={handleClose}
                openclose={Openorclose}
                allGolfcourse={GolfCourseData}
            />

            <div className="box">
                <div className="box-header   text-center">
                    <h6 className="  box-title ">Hole Status</h6>
                    <div className="text-center" style={{ marginBottom: "-10px" }}>
                        {/* {permission.update == 1 ? ( */}
                        <ButtonLink
                            onClick={handleFieldopen}
                            className={`${classes.openclosedisabled} ${btncloseactive === true ? classes.blueButton : ""
                                } `}
                        >
                            Open Holes
                        </ButtonLink>
                        {/* ) : (
              ""
            )} */}
                        {/* {permission.update == 1 ? ( */}
                        <ButtonLink
                            onClick={handleFieldClose}
                            className={`${classes.openclosedisabled} ${btnopenactive === true ? classes.redButton : ""
                                } `}
                        >
                            Close Holes
                        </ButtonLink>
                        {/* ) : (
              ""
            )} */}
                    </div>
                </div>
                <div className="box-body">
                    <div className="divGrid">
                        <div className="divGridHeading">
                            <div className="divGridRow">
                                <div className="divGridHead">
                                    {" "}
                                    <Checkbox
                                        color="primary"
                                        style={{ padding: "2px" }}
                                        checked={allChecked}
                                        name="checkAll"
                                        onChange={(e) => handleChange(e)}
                                    />{" "}
                                    Hole
                                </div>
                                <div className="divGridHead">Status</div>
                            </div>
                        </div>
                        <div className="divGridBody">
                            {fieldNames?.map((m) => (
                                <div className="divGridRow">
                                    <div className="divGridCell">
                                        <Checkbox
                                            color="primary"
                                            name={m.fieldID}
                                            checked={m.isChecked}
                                            style={{ padding: "2px" }}
                                            key={m.fieldName}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        {m.fieldName}
                                    </div>
                                    <div className="divGridCell">
                                        <div className={`d-flex align-items-center`}>
                                            <FiberManualRecordIcon
                                                fontSize="small"
                                                style={{
                                                    color:
                                                        m.fieldStatus === "Open" ? green[500] : red[500],
                                                }}
                                            />{" "}
                                            {m.fieldStatus == "Open" && m.fieldfinalstatus !== ""
                                                ? m.fieldfinalstatus
                                                : m.fieldStatus}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
