import React, { useEffect, useState } from "react";
import Highcharts, { chart, color } from "highcharts";
import { useTheme } from "@material-ui/core/styles";
import HighchartsReact from "highcharts-react-official";
import { Paper, Grid, useMediaQuery, Typography } from "@material-ui/core";
import WorkoutStatusCard from "./WorkoutStatusCard";
import smartParkService from "../../../axios";

const MafcWorkoutArea = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [dataFetched, setDataFetched] = useState(false);
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Weekly Summary",
      align: "left",
    },
    xAxis: {
      categories: ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"],
      crosshair: false,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Occupancy",
      },
    },
    tooltip: {
      valueSuffix: "",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    // responsive: {
    //   rules: [
    //     {
    //       condition: {
    //         maxWidth: 500,
    //       },
    //       chartOptions: {
    //         chart: {
    //           height: 250,
    //         },
    //         subtitle: {
    //           text: null,
    //         },
    //         navigator: {
    //           enabled: false,
    //         },
    //       },
    //     },
    //   ],
    // },
    series: [
      {
        name: "Treadmill",
        data: [6, 26, 10, 8, 7, 14, 10],
        visible: true,
      },
      {
        name: "Rowers",
        data: [6, 26, 10, 8, 7, 14, 10],
        visible: false,
      },
      {
        name: "Steppers",
        data: [6, 26, 10, 8, 7, 14, 10],
        visible: false,
      },
    ],
  };

  const getConfig = () => ({
    plotOptions: {
      column: {
        colorByPoint: true,
      },
    },

    // colors: ["#0076bb"],

    rangeSelector: {
      enabled: false,
      inputEnabled: false,
      buttonTheme: {
        visibility: "hidden",
      },
      labelStyle: {
        visibility: "hidden",
      },
    },
    // height: "350px",

    title: {
      text: "Popular Times",
      align: "left",
    },
    chart: {
      // height: (9 / 22.5) * 100 + "%",
      type: "column",
    },
    plotOptions: {
      // column: {
      //   stacking: "normal",
      // },
    },

    xAxis: {
      categories: [
        "6 am",
        "7 am",
        "8 am",
        "9 am",
        "10 am",
        "11 am",
        "12",
        "1 pm",
        "2 pm",
        "3 pm",
        "4 pm",
        "5 pm",
        "6 pm",
        "7 pm",
        "8 pm",
        "9 pm",
        "10 pm",
      ],
    },
    yAxis: {
      visible: false,
      min: 0,
      title: {
        text: "",
      },
    },
    tooltip: {
      split: true,
      formatter: function () {
        var crowd = this.y;
        if (crowd >= 10)
          return "Occupied " + crowd + " times.</br> Usually Busy On " + this.x;
        else
          return (
            "Occupied " +
            crowd +
            " times.</br> Usually Not So Busy On " +
            this.x
          );
      },
    },

    series: [
      {
        name: "Sunday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: true,
      },
      {
        name: "Monday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
      {
        name: "Tuesday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
      {
        name: "Wednesday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
      {
        name: "Thursday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
      {
        name: "Friday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
      {
        name: "Saturday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
    ],
  });

  const category = [
    ["6 am", 0],
    ["7 am", 2],
    ["8 am", 1],
    ["9 am", 2],
    ["10 am", 2],
    ["11 am", 1],
    ["12", 0],
    ["1 pm", 1],
    ["2 pm", 2],
    ["3 pm", 0],
    ["4 pm", 1],
    ["5 pm", 5],
    ["6 pm", 5],
    ["7 pm", 5],
    ["8 pm", 4],
    ["9 pm", 2],
    ["10 pm", 0],
  ];

  const [cardsData, setCardsData] = useState({
    1: {
      sensor_id: "1",
      sensor_value: "",
      title: "Weight Room",
      color: "#f0f5ff",
    },
    "6654ead818873d08a664442a": {
      sensor_id: "6654ead818873d08a664442a",
      sensor_value: "",
      title: "Spin Room",
      color: "#faf9f3",
    },
    "6654ed43b6eb4b095b73e933": {
      sensor_id: "6654ed43b6eb4b095b73e933",
      sensor_value: "",
      title: "Stretching Area",
      color: "#e8ffef",
    },
    "6654e4bcebeba9086fd9672f": {
      sensor_id: "6654e4bcebeba9086fd9672f",
      sensor_value: "",
      title: "Group Exercise Room",
      color: "#dcf7fc",
    },
  });

  useEffect(async () => {
    let url = `api/ubidots/counters/list/91474e36-76c5-4d59-a8da-2998ab14a4bf`;
    await smartParkService.get(url).then((res) => {
      if (res && res.status === 200 && res.data.data.length > 0) {
        const newCardsData = { ...cardsData };

        res.data.data.forEach((sensor) => {
          if (newCardsData[sensor.counterid]) {
            newCardsData[sensor.counterid] = {
              ...newCardsData[sensor.counterid],
              sensor_value: sensor.countervalue,
              // Update any other fields as needed
            };
          }
        });
        setCardsData(newCardsData);
        setDataFetched(true);
      }
    });
  }, []);
  return (
    <Grid
      container
      spacing={4}
      style={{
        height: "100vh",
        marginBottom: "1.5rem",
      }}
    >
      <Grid item xs={12} lg={8}>
        <div style={{ height: "100%" }}>
          <Grid container style={{ height: "100%" }}>
            {dataFetched &&
              Object.entries(cardsData).map(([id, data]) => {
                return <WorkoutStatusCard key={data.sensor_id} data={data} />;
              })}
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ height: isLargeScreen ? "100%" : "auto", width: "100%" }}
        >
          <Grid
            item
            style={{ height: isLargeScreen ? "50%" : "auto", width: "100%" }}
          >
            <Paper style={{ height: "100%" }} elevation={8}>
              <Typography style={{ padding: 10 }} variant="h5">
                Coming Soon
              </Typography>
              {/* <HighchartsReact
                highcharts={Highcharts}
                options={getConfig()}
                containerProps={{ style: { height: "100%" } }}
              /> */}
            </Paper>
          </Grid>
          <Grid
            item
            style={{ height: isLargeScreen ? "50%" : "auto", width: "100%" }}
          >
            <Paper style={{ height: "100%" }} elevation={8}>
              <Typography style={{ padding: 10 }} variant="h5">
                Coming Soon
              </Typography>
              {/* <HighchartsReact
                highcharts={Highcharts}
                options={options}
                containerProps={{ style: { height: "100%" } }}
              /> */}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MafcWorkoutArea;
