import React, { useState, useEffect } from "react";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../../views/Common";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;
const localTimeZoneOffset =
  (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;

const getConfig = (floodData) => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: "areaspline",
    events: {
      load: function (event) {
        event.target.reflow();
      },
    },
  },
  showInLegend: true,
  rangeSelector: {
    selected: 1,
    enabled: false, // To hide the zoom 1m 3m and Input fields
  },
  height: "100%",
  yAxis: {
    title: {
      text: "Water Level (ft)",
    },
    resize: {
      enabled: true,
    },
    tickInterval: 2,
    max: 290,
    min: 270,
  },
  series: prepareGraphPlot(floodData),
  tooltip: {
    split: true,
    pointFormatter: function () {
      var point = this;
      return (
        '<span style="color:' +
        point.color +
        '">\u25CF</span> Water Level <b>' +
        point.y.toFixed(2) +
        "</b>(ft)</b><br/>"
      );
    },
  },
});

let globalohlcData = [];
const prepareGraphPlot = (data) => {
  try {
    return {
      name: "Water Level",
      data: (function () {
        let ohlcData = [];
        let oldDate = Number(data[0]["date"]) - localTimeZoneOffset;
        for (var j = 0; j < data.length; j++) {
          let currentDate = Number(data[j]["date"]) - localTimeZoneOffset;
          let Difference_In_Time = currentDate - oldDate;
          let Difference_In_Days = Math.floor(
            Difference_In_Time / (1000 * 3600 * 24)
          );
          if (Difference_In_Days >= 1) {
            var oldTimeInDateFormat = new Date(oldDate);
            for (var i = 1; i < Difference_In_Days; i++) {
              oldTimeInDateFormat.setDate(oldTimeInDateFormat.getDate() + 1);
              let oldTimeInDateInTimeFormat = oldTimeInDateFormat.getTime();
              ohlcData.push([
                oldTimeInDateInTimeFormat - localTimeZoneOffset, // date
                null,
              ]);
            }
          }

          oldDate = currentDate;
          ohlcData.push([
            currentDate, // date
            data[j]["level"], // water level
          ]);
        }
        globalohlcData = ohlcData;
        return ohlcData;
      })(),
    };
  } catch (error) {
    console.log("Error in prepareGraphPlot(). Exception Message: " + error);
  }
};

function FloodChart({ start, end, dataLoaded, exportParkid, graphData }) {
  const parkid = "c7a1d85f-8168-4e64-8e38-116a0624bfea";
  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();
  const [loading, setLoading] = React.useState(true);
  const [waterlevelData, setwaterlevelData] = useState([]);

  const loadingHandler = (state) => {
    setLoading(state);
    dataLoaded(state);
  };

  useEffect(() => {
    getData();
  }, [start, end]);
  useEffect(() => {
    if (exportParkid === parkid) {
      graphData(waterlevelData);
    }
  }, [exportParkid]);
  const getData = () => {
    loadingHandler(true);
    let startDate = start || new Date("01/01/2020").toISOString();
    let endDate = end || new Date().toISOString();

    let url =
      "/api/citizen/flood/region?startDate=" +
      startDate +
      "&endDate=" +
      endDate; // + "&region=" + region;
    setLoading(true);
    smartParkService
      .get(url)
      .then((res) => {
        if (res) {
          setwaterlevelData(res.data.data);
          // props.setData(res.data.data);
          loadingHandler(false);
        } else {
          console.log("FloodChart else error: ");
        }
      })
      .catch((error) => {
        console.error("FloodChart error: ", error);
        loadingHandler(false);
      });
  };

  return (
    <div className="App">
      {loading ? (
        <ClipLoader loading={loading} css={override} size={60} />
      ) : (
        <>
          <HighchartsReact
            highcharts={HighStock}
            constructorType={"stockChart"}
            options={getConfig(waterlevelData)}
          />
        </>
      )}
    </div>
  );
}

export default FloodChart;
export const waterlevelarry = globalohlcData;
