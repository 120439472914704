import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "../../components/components.js";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { initialState, sensorsThunk, hasLoaded_ } from "../../reducers/rules";
import { sensors_, sensorsHasLoaded_ } from "../../reducers/sensors";
import { login_, token_ } from "../../reducers/login";
import smartParkService from "../../axios";
import {
  Search,
  AddCircle,
  KeyboardArrowLeft,
  Cancel,
} from "@material-ui/icons";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles(styles);
export default function SelectSensors(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [sensor, setSensor] = React.useState();
  const [fields, setFields] = React.useState(_.cloneDeep(props.rules?.Rule));
  const [sensorsList, setsensorsList] = React.useState([]);
  const [displaysensorsList, setdisplaysensorsList] = React.useState([]);
  const [gotSensors, setGotSensors] = React.useState(false);
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;

  function handleChangeInput(i, event) {
    const values = _.cloneDeep(fields);
    const { name, value } = event.target;
    values[i][name] = value;
    // if (values[i].Field) {
    //   values[i].Field = sensor
    // }
    setFields(values);
    props.updateRuleStateObj("Rule", values);
  }

  function handleAddInput() {
    setdisplaysensorsList(sensorsList);
    const values = _.cloneDeep(fields);
    values.push({
      Field: [],
      MoistureValue: "",
      Duration: "10",
      DurationIn: "m",
      MoistureCondition: "",
      NextCriteria: "",
      FloodCondition: "",
      FloodDuration: "10",
      FloodDurationIn: "m",
      FloodValue: "",
    });
    setFields(values);
    props.updateRuleStateObj("Rule", values);
  }

  function handleRemoveInput(i) {
    const values = _.cloneDeep(fields);
    if (values.length > 1) {
      values.splice(i, 1);
    }
    setFields(values);
    props.updateRuleStateObj("Rule", values);
  }

  React.useEffect(() => {
    smartParkService.get("api/park/" + parkid + "/fields").then((res, err) => {
      // console.log(res.data.data);
      setsensorsList(res.data.data);
      setdisplaysensorsList(res.data.data);
    });
  }, []);
  // const preparesensorsobj = async (idx) => {
  //   var selectindexobj = _.cloneDeep(fields);
  //   var indexfields = selectindexobj[idx].Field;
  //   if (fields[idx].Field.length > 0) {
  //     var selectedtypeofsensor = "";
  //     await sensorsList.map((sensor) => {
  //       if (sensor.sensorname === fields[idx].Field[0]) {
  //         selectedtypeofsensor = sensor.typeofsensor;
  //       }
  //     });
  //     var resultsensorlist = await sensorsList.filter((list) => {
  //       return list.typeofsensor == selectedtypeofsensor;
  //     });
  //     setdisplaysensorsList(resultsensorlist);
  //   } else {
  //     setdisplaysensorsList(sensorsList);
  //   }
  // };

  const onChangeSensor = async (value, index) => {
    // if (value.length === 1) {
    //   var selectedtypeofsensor = "";
    //   await sensorsList.map((sensor) => {
    //     if (sensor.sensorname === value[0]) {
    //       selectedtypeofsensor = sensor.typeofsensor;
    //     }
    //   });
    //   var resultsensorlist = await sensorsList.filter((list) => {
    //     return list.typeofsensor == selectedtypeofsensor;
    //   });
    //   setdisplaysensorsList(resultsensorlist);
    // }
    // if (value.length == 0) {
    //   setdisplaysensorsList(sensorsList);
    //     debugger;
    const values = _.cloneDeep(fields);
    var array = [];
    value.map((e) => {
      if (e) {
        array.push(e);
      }
    });
    values[index].Field = array;
    setFields(values);
    setSensor(value);
    props.updateRuleStateObj("Rule", values);
  };
  const error = props.Errors;

  return (
    <React.Fragment>
      <div>
        {fields.map((field, idx) => {
          return (
            <div key={`${field}-${idx}`}>
              <div class="stepper-row-content1">
                <div class="d-flex alignCenter">
                  <div
                    class="customInputBase"
                  // onClick={() => {
                  //   preparesensorsobj(idx);
                  // }}
                  >
                    <IconButton
                      className={classes.iconButton}
                      aria-label="menu"
                    >
                      <Search />
                    </IconButton>
                    <Autocomplete
                      multiple
                      id="sensorsList"
                      options={displaysensorsList.map(
                        (option) => option.FieldName
                      )}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="" />
                      )}
                      name="Field"
                      onChange={(event, value) => onChangeSensor(value, idx)}
                      value={field.Field}
                    />
                  </div>{" "}
                  &nbsp;
                  {fields.length > 1 ? (
                    <div>
                      <Cancel
                        className={classes.iconError}
                        onClick={() => handleRemoveInput(idx)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {idx == fields.length - 1 ? (
                    <div>
                      <AddCircle
                        style={{ color: green[500] }}
                        onClick={() => handleAddInput(idx)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ color: "red" }}>{ }</div>
                <br />
                <div class="wrapperrow">
                  <div>
                    {"When "}
                    <Select
                      className={classes.w75}
                      variant="outlined"
                      name="MoistureCondition"
                      value={field.Metric}
                      onChange={(e) => handleChangeInput(idx, e)}
                    >
                      <MenuItem value="moisture">Moisture</MenuItem>
                    </Select>
                    {" level is "}
                    <Select
                      className={classes.w75}
                      variant="outlined"
                      name="MoistureCondition"
                      value={field.Criteria}
                      onChange={(e) => handleChangeInput(idx, e)}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="above">above</MenuItem>
                      <MenuItem value="below">below</MenuItem>
                    </Select>{" "}
                    the threshold value{" "}
                    <TextField
                      labelWidth={0}
                      id=""
                      className={classes.w75}
                      size="small"
                      variant="outlined"
                      name="MoistureValue"
                      value={field.MetricValue}
                      onChange={(e) => handleChangeInput(idx, e)}
                    />
                  </div>
                  <br />
                </div>

                <div class="wrapperrow">
                  <div
                    style={{
                      display: fields.length > idx + 1 ? "block" : "none",
                    }}
                  >
                    <FormControl
                      size="small"
                      className={classes.w10p}
                      variant="outlined"
                    >
                      <Select
                        displayEmpty
                        name="NextCriteria"
                        value={field.NextCriteria}
                        onChange={(e) => handleChangeInput(idx, e)}
                      >
                        <MenuItem value="AND">AND</MenuItem>
                        <MenuItem value="OR">OR</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <br />
            </div>
          );
        })}
        <div style={{ color: "red", fontSize: "15px" }}>{error.sensordata}</div>
      </div>
    </React.Fragment>
  );
}
