// OccupancyCardRenderer.js
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import OccupancyCardMobile from "./OccupancyCardMobile.js";
import OccupancyCardTablet from "./OccupancyCardTablet.js";
import { Grid } from "@material-ui/core";
const OccupancyCardRenderer = ({ heading, fieldList }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid container spacing={4}>
      <Grid style={{ width: "100%" }} item>
        {isSmallScreen ? (
          <OccupancyCardMobile fieldList={fieldList} heading={heading} />
        ) : (
          <Grid container spacing={2}>
            {fieldList.map((field, index) => {
              return (
                <Grid item key={index} xs={fieldList.length > 1 ? 6 : 12}>
                  <OccupancyCardTablet field={field} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default OccupancyCardRenderer;
