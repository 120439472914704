import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { GenerateEncryptedToken } from "../Common.js";
import { login_ } from "../../reducers/login.js";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Button from "../../components/CustomButtons/Button.js";
import MaskedInput from "react-maskedinput";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { TiArrowBack } from "react-icons/ti";
import DatePicker from "react-datepicker";
import calendar from "../../assets/img/calendar-icon.png";
import smartParkService from "../../axios/index.js";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import "react-toastify/dist/ReactToastify.css";
import alllog from "../../assets/img/alllog.png";
import noaction from "../../assets/img/noaction.png";
import notsupport from "../../assets/img/notsupport.png";
import success from "../../assets/img/success.png";
import failed from "../../assets/img/failed.png";
import ignored from "../../assets/img/ignored.png";
import overruled from "../../assets/img/overruled.png";
import blocked from "../../assets/img/blocked.png";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Tablepaginate from "./Tablepaginate.js";
import { initialState } from "../../reducers/rules.js";
import {
    permissions_,
    Getaccesspermissions,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const weekdayclass = makeStyles((theme) => ({
    weekdayborder: {
        border: "1px solid #fff",
        borderRadius: "0.25rem",
    },
    samepadd: {
        "& .MuiSelect-select": {
            padding: "12px auto !important",
        },
    },
}));
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    close: {
        padding: theme.spacing(0.5),
    },
}));
// Multiple select ================
const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;
//  emaillist section ====================
let cloneRulesInitialState = _.cloneDeep(initialState.data);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" position="top" ref={ref} {...props} />;
});
export default function RuleLogpage(props) {
    const classes = useStyles();
    const textRef = useRef(null);
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");
    const location = useLocation();
    // datainput ------=======================================================
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    const [getSearchfilter, setSearchfilter] = React.useState([]);
    const [color, setColor] = React.useState("#ffffff");
    const [getparks, setparks] = React.useState([]);
    const [getSearchpark, setSearchpark] = React.useState("");
    const [gethandleParkId, sethandleParkId] = React.useState("");
    const [getfinaldevice, setfinaldevice] = React.useState([]);
    const [startDate, setStartDate] = React.useState(
        new Date(moment().subtract(7, "days"))
    );
    const [endDate, setEndDate] = React.useState(new Date(moment()));
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showEndDatePicker, setshowEndDatePicker] = useState(false);
    const [getfinaldevicetype, setfinaldevicetype] = React.useState([]);
    const [getShouldUpdatePage, setShouldUpdatePage] = React.useState(false);
    const [gethandleParkDevice, sethandleParkDevice] = React.useState("");
    const [getapiRuleID, setapiRuleID] = React.useState("");
    const [getLogTriggeredtype, setLogTriggeredtype] = React.useState("");
    const [gethandleParkDeviceType, sethandleParkDeviceType] = React.useState("");
    const [getdname, setdname] = React.useState("");
    const [getListdata, setListdata] = React.useState([]);
    const theme = useTheme();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(15);
    const [pending, setPending] = React.useState(true);
    const [rows, setRowsdata] = React.useState([]);
    const [getRuleName, setRuleName] = React.useState("");
    const [getRuleDescription, setRuleDescription] = React.useState("");
    const [getLogRuleID, setLogRuleID] = React.useState("");
    const [getapiRuleName, setapiRuleName] = React.useState("");
    const [getTotalrules, setTotalrules] = React.useState("");
    const [getTotalRowselect, setTotalRowselect] = React.useState("");
    const [getcheckrow, setcheckrow] = React.useState(false);
    const [totalRows, setTotalRows] = React.useState(10);
    const [getShowHeading, setShowHeading] = React.useState(false);
    const [getMobileRuleList, setMobileRuleList] = React.useState([]);
    // const [stateDetail, setstateDetail] = React.useState("");
    const [getSearchruleDevicetype, setSearchruleDevicetype] = React.useState("");
    const [getSearchruleDevice, setSearchruleDevice] = React.useState("");
    const [refresh, setRefresh] = useState(false);
    const [permission, setpermission] = React.useState({});
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);

    var stateDetail;
    if (location?.state !== undefined) {
        try {
            stateDetail = location?.state?.detail;
        } catch (error) {
            console.log("Location state error " + error);
        }
    }

    useEffect(() => {
        if (stateDetail !== "" && stateDetail !== undefined) {
            let url = `api/park/ruleengine/viewrule/${stateDetail}`;
            var restoken = GenerateEncryptedToken();
            smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        if (Object.keys(res.data).length == 0) {
                            setRefresh(!refresh);
                        }
                        if (
                            Object.keys(res.data.data).length == 0 &&
                            Object.keys(res.data.data) == undefined
                        ) {
                            setRefresh(!refresh);
                        }
                        if (res?.data?.status == 200) {
                            // seteditdata(res.data.data);
                            setRuleName(res.data.data.RuleName);
                            setRuleDescription(res.data.data.RuleDescription);
                        }
                    }
                })
                .catch((error) => {
                    console.error("View Rule list error: ", error);
                });
        }
    }, [stateDetail]);

    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);
    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "RuleEngine";
        });
        setpermission(Dpermissions[0]);
    };

    function handleLogType(e) {
        let logtypevalue = e.target.value;
        if (logtypevalue !== "") {
            setLogTriggeredtype(logtypevalue);
        } else {
            setLogTriggeredtype("");
        }
        setShouldUpdatePage(true);
    }
    useEffect(() => {
        funcSearchoptions();
    }, []);

    function funcSearchoptions() {
        try {
            let url = "api/park/ruleengine/searchoptions";
            var restoken = GenerateEncryptedToken();
            smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        try {
                            if (res.status === 200) {
                                if (res.data.status == 200) {
                                    var dataa = [];
                                    var devices = {};
                                    res?.data?.options?.forEach((data, i) => {
                                        dataa.push({
                                            id: data.ID,
                                            name: data.Name,
                                            shortname: data.ShortName,
                                            device: data.Devices,
                                        });
                                        devices[data.ID] = data.Devices;
                                    });
                                    setparks(dataa);
                                    setSearchfilter(devices);
                                } else {
                                    funcSearchoptions();
                                }
                            } else {
                                funcSearchoptions();
                            }
                        } catch {
                            console.log("some error in search Dropdown api ");
                        }
                    } else {
                        console.log("Search else error: ");
                    }
                })
                .catch((error) => {
                    console.error("Search error: ", error);
                });
        } catch {
            console.log("Search Option Error");
        }
    }

    const handleImgClick = () => {
        setShowDatePicker(true);
        setshowEndDatePicker(false);
    };
    const handleDatePickerBlur = () => {
        setShowDatePicker(false);
    };
    const handleEndImgClick = () => {
        setshowEndDatePicker(true);
        setShowDatePicker(false);
    };
    const handleEndDatePickerBlur = () => {
        setshowEndDatePicker(false);
    };

    const handleParkId = (elem) => {
        const selectedOption = elem.target.options[elem.target.selectedIndex];
        // Access the data-name attribute value
        const dataName = selectedOption.getAttribute("data-name");
        setSearchpark(dataName);
        if (elem.target.value !== "All Parks") {
            sethandleParkId(elem.target.value);
            const parkid = elem.target.value;
            var alldevices = getSearchfilter;
            var finaldevice = alldevices[parkid];
            setfinaldevice(
                Object.keys(finaldevice).filter(
                    (category) => category === "Park" || finaldevice[category].length > 0
                )
            );
        } else {
            setfinaldevice([]);
            setfinaldevicetype([]);
            sethandleParkDeviceType("");
            sethandleParkDevice("");
            setSearchpark("");
            setSearchruleDevicetype("");
            setSearchruleDevice("");
            setdname("");
            setapiRuleName("");
            setapiRuleID("");
            sethandleParkId("");
        }
        setShouldUpdatePage(true);
    };
    const handledeviceType = (event) => {
        setfinaldevicetype([]);
        sethandleParkDevice("");
        if (event.target.value !== "Device type") {
            const seldevice = event.target.value;
            sethandleParkDeviceType(event.target.value);
            const parkid = gethandleParkId;
            const park = getparks;
            const parkdevice = getfinaldevice;
            var parkobj = [];
            park?.forEach((data, idx) => {
                if (data.id == parkid) {
                    parkobj = data;
                }
            });
            var devices = parkobj["device"] ?? [];
            var devicetype = devices[seldevice] ?? [];
            if (!Array.isArray(devicetype)) {
                let devicetypeone = [devicetype];
                setfinaldevicetype(devicetypeone);
            } else {
                setfinaldevicetype(devicetype);
            }
        } else {
            sethandleParkDeviceType("");
            sethandleParkDevice("");
        }
        setShouldUpdatePage(true);
    };

    const handledevice = (elem) => {
        if (elem.target.value !== "Device") {
            sethandleParkDevice(elem.target.value);
            const handledevicename = elem.target.options[elem.target.selectedIndex];
            const dname = handledevicename.getAttribute("data-device");
            setdname(dname);
        } else {
            sethandleParkDevice("");
        }
        setShouldUpdatePage(true);
    };

    function funcStartDate(e) {
        if (e !== null && e !== undefined) {
            const startDateTime = new Date(e);
            startDateTime.setHours(0, 0, 0);
            setStartDate(startDateTime);
            setShowDatePicker(false);
        } else {
            setStartDate("");
            setShowDatePicker(false);
        }
        setShouldUpdatePage(true);
    }
    function funcEndDate(e) {
        setEndDate("");
        if (e !== null && e !== undefined) {
            const endDateTime = new Date(e);
            endDateTime.setHours(23, 59, 59);
            setEndDate(endDateTime);
        } else {
            setEndDate("");
        }
        setshowEndDatePicker(false);
        setShouldUpdatePage(true);
    }
    const handleRuleId = (elem) => {
        setapiRuleID(elem.target.value);
    };

    useEffect(() => {
        if (getListdata.length > 0) {
            const timeout = setTimeout(() => {
                setRowsdata(getListdata);
                setPending(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [getListdata, refresh]);

    function simpledate(date) {
        try {
            const otherZone = moment
                .tz(date, "America/New_York")
                .format("L h:mm:ss A");
            return otherZone;
        } catch {
            return date;
        }
    }

    function trivalue(data, TriggerCriteria, Devicetype) {
        let symbol;
        if (TriggerCriteria === "exactly" || TriggerCriteria === "Exactly") {
            symbol = "==";
        }
        if (TriggerCriteria === "above" || TriggerCriteria === "Above") {
            symbol = ">";
        }
        if (TriggerCriteria === "below" || TriggerCriteria === "Below") {
            symbol = "<";
        }

        if (
            data === "opened" ||
            data === "Opened" ||
            data === "closed" ||
            data === "Closed"
        ) {
            return data;
        } else {
            return `${symbol} ${Devicetype && Devicetype.toLowerCase() === "court"
                    ? data.toLowerCase() === "open"
                        ? "Unoccupied"
                        : "Occupied"
                    : data
                }`;
        }
    }
    const htmlFormatter = ({ value }) => (
        <div dangerouslySetInnerHTML={{ __html: value }} />
    );

    useEffect(() => {
        if (getRuleName !== "" && getRuleDescription !== "") {
            refreshData();
        }
    }, [currentPage, itemsPerPage, refresh, getRuleDescription, getRuleName]);

    const refreshData = () => {
        if (getShouldUpdatePage) {
            setCurrentPage(1);
            setItemsPerPage(10);
        }
        const updatedPage = getShouldUpdatePage ? 1 : currentPage; // Update this with the new page number
        const updatedItemsPerPage = getShouldUpdatePage ? 10 : itemsPerPage; // Update this with the new items per page value
        let estTimeZone = "America/New_York"; // Eastern Standard Time (EST)
        // Convert startDate to EST and format
        let estStartTimestamp = "";
        if (startDate !== "" && startDate !== undefined) {
            estStartTimestamp = moment(startDate)
                .tz(estTimeZone)
                .endOf("day")
                .format("L h:mm:ss A");
        } else {
            estStartTimestamp = "";
        }
        // Convert endDate to EST and format
        let estEndTimestamp = "";
        if (endDate !== "" && endDate !== undefined) {
            estEndTimestamp = moment(endDate)
                .tz(estTimeZone)
                .set({ hour: 23, minute: 59, second: 59 })
                .format("L h:mm:ss A");
        } else {
            estEndTimestamp = "";
        }
        console.log(estStartTimestamp);
        console.log(estEndTimestamp);
        // Convert EST formatted timestamps to UTC
        let unixStartTimestamp =
            estStartTimestamp !== ""
                ? moment(estStartTimestamp, "L h:mm:ss A").utc().valueOf()
                : "";
        let unixEndTimestamp =
            estEndTimestamp !== ""
                ? moment(estEndTimestamp, "L h:mm:ss A").utc().valueOf()
                : "";

        try {
            var finaldata = [];
            let url = "api/park/ruleengine/listrulelogs";
            var restoken = GenerateEncryptedToken();
            var requestBody = {
                Page: updatedPage,
                Limit: updatedItemsPerPage,
                RuleID: stateDetail,
                // RuleName: getRuleName,
                StartDate: unixStartTimestamp,
                EndDate: unixEndTimestamp,
                LogType: getLogTriggeredtype, //SUCCESS, FAILED, NO_ACTION, IGNORED, OVERRULED, BLOCKED, NOT_SUPPORTED
                ParkID: gethandleParkId,
                Devicetype: gethandleParkDeviceType,
                DeviceID: gethandleParkDevice,
            };
            smartParkService
                .post(url, requestBody, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    setShouldUpdatePage(false);
                    if (res) {
                        // console.log(res);
                        if (res.data?.data?.length < 1) {
                            setPending(false);
                            setLoading(false);
                        }
                        if (res.data.status === "200") {
                            console.log(res.data?.metadata.total);
                            setTotalrules(res.data?.metadata.total);
                            setTotalRows(res.data?.metadata.pages);
                            setShowHeading(true);
                        }
                        if (res.data.data?.length > 0) {
                            setMobileRuleList(res.data.data);
                        }

                        res.data.data?.forEach((data, idx) => {
                            setLogRuleID(data.RuleID);
                            let datavalue;
                            datavalue = data.Actions.map((item) => item.Status).filter(
                                (action) => action
                            );
                            const datasame = datavalue.some((item) => item === item);
                            console.log(datasame);
                            if (
                                datavalue.length > 0 &&
                                datavalue.every((status) => status === datavalue[0])
                            ) {
                                datavalue = datavalue[0];
                            } else {
                                console.log("Values in the array are different.");
                            }
                            const Logseverity = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize logtableicons">
                                            {datavalue == "IGNORED" ? (
                                                <img src={ignored} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "NOT_SUPPORTED" ? (
                                                <img src={notsupport} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "BLOCKED" ? (
                                                <img src={blocked} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "OVERRULED" ? (
                                                <img src={overruled} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "NO_ACTION" ? (
                                                <img src={noaction} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "FAILED" ? (
                                                <img src={failed} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "SUCCESS" ? (
                                                <img src={success} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "" && "NA"}
                                        </div>
                                    </div>
                                );
                            };
                            const logOperationType = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">{data.OperationType}</div>
                                    </div>
                                );
                            };
                            const logdate = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">
                                            {simpledate(data.InsertDate)}
                                        </div>
                                    </div>
                                );
                            };
                            const ruledes = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">
                                            {getRuleName ?? getRuleName}
                                        </div>
                                        <hr className="my-1 mb-2" />
                                        <div className="commonsize">
                                            {getRuleDescription ?? getRuleDescription}
                                        </div>
                                    </div>
                                );
                            };
                            const CombineTriggerConditions = () => {
                                console.log(data.Conditions);
                                return (
                                    <div className="text-center w-100 ">
                                        {data.Conditions?.length > 0
                                            ? data.Conditions?.map((condition, idx) => {
                                                const parkNameDisplay =
                                                    condition.ParkName === "Morrisville Community Park"
                                                        ? "MCP"
                                                        : condition.ParkName ===
                                                            "Morrisville Aquatic and Fitness Center"
                                                            ? "MAFC"
                                                            : condition.ParkName ===
                                                                "Cedar Fork District Park"
                                                                ? "CFDP"
                                                                : condition.ParkName === "Shiloh Park"
                                                                    ? "SP"
                                                                    : condition.ParkName ===
                                                                        "Crabtree Creek Nature Park"
                                                                        ? "CCNP"
                                                                        : condition.ParkName === "Church Street Park"
                                                                            ? "CSP"
                                                                            : "NA";
                                                return (
                                                    <Accordion
                                                        className={`viewaccordion trigeracco ${(condition.Status &&
                                                                condition.Status == "true") ||
                                                                (condition.ObservedState &&
                                                                    condition.ObservedState.wasConditionMet ==
                                                                    true)
                                                                ? "logtrue"
                                                                : (condition.Status &&
                                                                    condition.Status == "false") ||
                                                                    (condition.ObservedState &&
                                                                        condition.ObservedState.wasConditionMet ==
                                                                        false)
                                                                    ? "logfalse"
                                                                    : ""
                                                            }`}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<KeyboardArrowRightIcon />}
                                                            aria-controls={`panel${idx + 1}a-content`}
                                                            id={`panel${idx + 1}a-header`}
                                                            className="accorhead"
                                                        >
                                                            <Typography
                                                                className={`w-100  ${classes.heading}`}
                                                            >
                                                                <span className="headname commonsize">
                                                                    {parkNameDisplay} , {condition.DeviceName} ,{" "}
                                                                    {trivalue(
                                                                        condition.TriggerValue,
                                                                        condition.TriggerCriteria,
                                                                        condition.Devicetype
                                                                    )}{" "}
                                                                </span>{" "}
                                                                <span
                                                                    className={`allstatus ${(condition.Status &&
                                                                            condition.Status == "true") ||
                                                                            (condition.ObservedState &&
                                                                                condition.ObservedState
                                                                                    .wasConditionMet == true)
                                                                            ? "truecolor"
                                                                            : (condition.Status &&
                                                                                condition.Status == "false") ||
                                                                                (condition.ObservedState &&
                                                                                    condition.ObservedState
                                                                                        .wasConditionMet == false)
                                                                                ? "falsecolor"
                                                                                : ""
                                                                        }`}
                                                                >
                                                                    Status:{" "}
                                                                    {`${condition.ObservedState &&
                                                                            condition.ObservedState
                                                                            ? condition.ObservedState
                                                                                .wasConditionMet
                                                                            : condition.Status
                                                                        }`}
                                                                </span>
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails className="d-block">
                                                            <Table
                                                                size="sm"
                                                                className="text-left"
                                                                key={idx}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Park Name: {parkNameDisplay}</td>
                                                                        <td>
                                                                            Device Type: {condition.Devicetype}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Device Name: {condition.DeviceName}
                                                                        </td>
                                                                        <td>
                                                                            Trigger Value:{" "}
                                                                            {condition.Devicetype.toLowerCase() ===
                                                                                "court"
                                                                                ? condition.TriggerValue.toLowerCase() ===
                                                                                    "open"
                                                                                    ? "Unoccupied"
                                                                                    : "Occupied"
                                                                                : condition.TriggerValue}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Trigger Criteria:{" "}
                                                                            {condition.TriggerCriteria}
                                                                        </td>
                                                                        <td>Duration: {condition.Tolerance}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            {condition.ObservedState
                                                                                ? "ObservedMessage"
                                                                                : "ObservedValue"}{" "}
                                                                            :
                                                                            {condition.Devicetype &&
                                                                                condition.Devicetype.toLowerCase() ===
                                                                                "court"
                                                                                ? condition.ObservedValue &&
                                                                                    condition.ObservedValue.toLowerCase() ===
                                                                                    "open"
                                                                                    ? "Unoccupied"
                                                                                    : condition.ObservedValue &&
                                                                                        condition.ObservedValue.toLowerCase() ===
                                                                                        "close"
                                                                                        ? "Occupied"
                                                                                        : condition.ObservedState
                                                                                            .ObservedMessage
                                                                                : condition.ObservedState &&
                                                                                    condition.ObservedState
                                                                                    ? condition.ObservedState
                                                                                        .ObservedMessage
                                                                                    : condition.ObservedValue}
                                                                        </td>
                                                                        <td>
                                                                            Status:{" "}
                                                                            {`${condition.ObservedState &&
                                                                                    condition.ObservedState
                                                                                    ? condition.ObservedState
                                                                                        .wasConditionMet
                                                                                    : condition.Status
                                                                                }`}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                );
                                            })
                                            : ""}
                                        {data.Conditions?.length == 0 ? (
                                            <div>TIME BASED RULE</div>
                                        ) : (
                                            ""
                                        )}
                                        <hr className="my-1 mb-2" />
                                        {data.Actions?.map((data, idx) => {
                                            const parkNameDisplay =
                                                data.ParkName === "Morrisville Community Park"
                                                    ? "MCP"
                                                    : data.ParkName ===
                                                        "Morrisville Aquatic and Fitness Center"
                                                        ? "MAFC"
                                                        : data.ParkName === "Cedar Fork District Park"
                                                            ? "CFDP"
                                                            : data.ParkName === "Shiloh Park"
                                                                ? "SP"
                                                                : data.ParkName === "Crabtree Creek Nature Park"
                                                                    ? "CCNP"
                                                                    : data.ParkName === "Church Street Park"
                                                                        ? "CSP"
                                                                        : "NA";
                                            return (
                                                <Accordion
                                                    className={`viewaccordion trigeracco ${data.Status == "IGNORED"
                                                            ? "logignore"
                                                            : data.Status == "SUCCESS"
                                                                ? "logsuccess"
                                                                : data.Status == "FAILED"
                                                                    ? "logfailed"
                                                                    : data.Status == "NO_ACTION"
                                                                        ? "lognoaction"
                                                                        : data.Status == "OVERRULED"
                                                                            ? "logoveruled"
                                                                            : data.Status == "BLOCKED"
                                                                                ? "logblocked"
                                                                                : data.Status == "NOT_SUPPORTED"
                                                                                    ? "lognotsupport"
                                                                                    : data.Status == "TRIGGERED"
                                                                                        ? "logtriggered"
                                                                                        : ""
                                                        }`}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<KeyboardArrowRightIcon />}
                                                        aria-controls={`panel${idx + 1}a-content`}
                                                        id={`panel${idx + 1}a-header`}
                                                        className="accorhead"
                                                    >
                                                        <Typography className={`w-100  ${classes.heading}`}>
                                                            <span className="headname commonsize">
                                                                {parkNameDisplay},{data.DeviceName},
                                                                {data.Action}{" "}
                                                            </span>{" "}
                                                            <span
                                                                className={`actionlogstatus ${data.Status == "IGNORED" ? "ignoreColor" : ""
                                                                    }`}
                                                            >
                                                                Status : {data.Status}
                                                            </span>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="d-block">
                                                        <Table
                                                            striped
                                                            bordered
                                                            hover
                                                            size="sm"
                                                            className="text-left"
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <td>Park Name : {parkNameDisplay}</td>
                                                                    <td>Device Type : {data.Devicetype}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Device : {data.DeviceName}</td>
                                                                    <td>Action : {data.Action}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Reason : {data.Reason}</td>
                                                                    <td>Status : {data.Status}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        })}
                                        {data.Actions.length === 0 && "NA"}
                                    </div>
                                );
                            };
                            const enabledisablesmenu = () => {
                                return (
                                    <div className="menubtn">
                                        <button
                                            className="btn logviewbtn"
                                            onClick={() => viewlogpage(data._id)}
                                        >
                                            <span className="actionbutton">View Log</span>
                                        </button>
                                    </div>
                                );
                            };
                            finaldata.push({
                                Logseverity: Logseverity(),
                                LogOperationType: logOperationType(),
                                RuleDes: ruledes(),
                                logdate: logdate(),
                                Conditionalsetting: CombineTriggerConditions(),
                                enabledisablemenu: enabledisablesmenu(),
                            });
                        });
                        setListdata(finaldata);
                    } else {
                        console.log("Rule log list error response: ");
                    }
                })
                .catch((error) => {
                    console.error("Rule log List error: ", error);
                });
        } catch {
            console.log("Log List error ");
        }
    };

    const columnsdata = [
        {
            field: "Severity",
            name: (
                <div className="w-100">
                    <div className="tableheading">Severity </div>
                </div>
            ),
            minWidth: "5%",
            width: "5%",
            cell: (row) => row.Logseverity,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "RuleNameDes",
            name: (
                <div className="w-100">
                    <div className="tableheading">Rule Name </div>
                    <hr className="my-2" />
                    <div className="tableheading">Rule Description</div>
                </div>
            ),
            minWidth: "20%",
            width: "20%",
            cell: (row) => row.RuleDes,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "OperationType",
            name: (
                <div className="w-100">
                    <div className="tableheading">OperationType </div>
                </div>
            ),
            minWidth: "5%",
            width: "10%",
            cell: (row) => row.LogOperationType,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "Logdate",
            name: (
                <div className="w-100">
                    <div className="tableheading">Date</div>
                </div>
            ),
            minWidth: "10%",
            width: "10%",
            cell: (row) => row.logdate,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "Conditionalsetting",
            name: (
                <div className="w-100">
                    <div className="tableheading">Conditions</div>
                    <hr className="my-2" />
                    <div className="tableheading">Actions</div>
                </div>
            ),
            minWidth: "20%",
            width: "45%",
            editable: true,
            cell: (row) => row.Conditionalsetting,
            renderCell: htmlFormatter,
        },
        {
            field: "Settings",
            name: <div className="tableheading">Settings</div>,
            minWidth: "2%",
            // width: "5%",
            editable: true,
            cell: (row) => row.enabledisablemenu,
            renderCell: htmlFormatter,
        },
    ];
    const customStyles = {
        rows: {
            style: {
                minHeight: "100px",
                // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",
                minHeight: "80px",

                // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",

                // override the row height
            },
        },
    };

    const functablerow = (itemvalue) => {
        setItemsPerPage(parseInt(itemvalue));
    };

    const funcCurrentpage = (itemvalue) => {
        setCurrentPage(itemvalue);
        setItemsPerPage(itemsPerPage);
    };

    const cancelAllFilter = () => {
        setLogTriggeredtype("");
        setapiRuleID("");
        setEndDate("");
        setStartDate("");
        sethandleParkId("");
        sethandleParkDevice("");
        sethandleParkDeviceType("");
        setdname("");
        setSearchruleDevice("");
        setparks([]);
        setPending(true);
        setLoading(true);
        funcSearchoptions();
        setRefresh(!refresh);
        setCurrentPage(1);
        setItemsPerPage(10);
    };
    const backtorulepage = () => {
        let path = "/Rules";
        history.push(path);
    };

    const ruletoauditpage = () => {
        let path = "AuditLogpage";
        history.push({
            pathname: `${path}`,
            state: { detail: `${stateDetail}` },
        });
        window.scrollTo(0, 0);
    };
    const routeAllRulepage = () => {
        if (permission.create == 1) {
            let path = "allrulelogspage";
            history.push({
                pathname: `${path}`,
            });
        } else {
            alert("You do not have permission to All Rule Logs");
        }
    };
    function ViewRule(ruledataid) {
        // if (permission.create == 1) {
        let path = "rulelistview";
        // setnewrule(true);
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${ruledataid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
        // } else {
        //   alert("You do not have permission to create rule");
        // }
    }

    function viewlogpage(logid) {
        // if (permission.create == 1) {
        let path = "ViewLogDetails";
        // setnewrule(true);
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${logid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
        // } else {
        //   alert("You do not have permission to create rule");
        // }
    }
    return (
        <div className="demoevent">
            <section className="content">
                <div className="logheading">
                    <h5 className="mb-0">Rule Logs </h5>
                    <div className="rightbtn logrightbtn">
                        <button
                            className="btn-sm btn badge-pill backicon btn-color"
                            onClick={backtorulepage}
                        >
                            <TiArrowBack className="mr-1" />
                            Back To Rule List Page
                        </button>
                        <button
                            className="btn-sm btn  backicon btn-logbtn viewruletoauditbtn"
                            onClick={ruletoauditpage}
                        >
                            <img src={alllog} alt="printerimg" className="img-fluid" />
                            Audit Logs
                        </button>
                    </div>
                </div>
                <div className="searchlogging">
                    <div>
                        <div className="row">
                            <div className="col-12 logmobilepadd">
                                <Card border="info" className="my-2 searchcard">
                                    <Card.Header>Custom Search</Card.Header>
                                    <Card.Body>
                                        <div className="allelment">
                                            <div className="allcontainer allbordersame">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch removelogborder">
                                                            <label>Rule ID : </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Rule ID"
                                                                value={
                                                                    stateDetail !== "" ? stateDetail : "Rule ID"
                                                                }
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch removelogborder">
                                                            <label>Rule Name : </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Rule Name"
                                                                value={
                                                                    getRuleName !== "" ? getRuleName : "Rule Name"
                                                                }
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch removelogborder">
                                                            <label>Start Date : </label>
                                                            <div
                                                                className={classes.startdatecss}
                                                                style={{
                                                                    position: "relative",
                                                                    border: "1px solid #5b6065",
                                                                    width: "100%",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <DatePicker
                                                                    // showTimeSelect
                                                                    dateFormat="MM/dd/yyyy"
                                                                    selected={startDate}
                                                                    selectsStart
                                                                    startDate={startDate}
                                                                    onChange={(e) => funcStartDate(e)}
                                                                    maxDate={new Date()}
                                                                    className="form-control"
                                                                    placeholderText="mm/day/year"
                                                                    onFocus={handleImgClick}
                                                                    onBlur={handleDatePickerBlur}
                                                                    open={showDatePicker}
                                                                    customInput={
                                                                        <MaskedInput mask="11/11/1111" />
                                                                    }
                                                                />
                                                                <img
                                                                    src={calendar}
                                                                    className="img-fluid calenderimg"
                                                                    alt="calender image"
                                                                    onClick={handleImgClick}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch removelogborder">
                                                            <label>End Date : </label>
                                                            <div
                                                                className={classes.startdatecss}
                                                                style={{
                                                                    position: "relative",
                                                                    border: "1px solid #5b6065",
                                                                    width: "100%",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <DatePicker
                                                                    // showTimeSelect
                                                                    dateFormat="MM/dd/yyyy"
                                                                    selected={endDate}
                                                                    selectsStart
                                                                    placeholderText="mm/day/year"
                                                                    endDate={endDate}
                                                                    minDate={startDate}
                                                                    maxDate={new Date()}
                                                                    onChange={(e) => funcEndDate(e)}
                                                                    className="form-control"
                                                                    onFocus={handleEndImgClick}
                                                                    onBlur={handleEndDatePickerBlur}
                                                                    open={showEndDatePicker}
                                                                    customInput={
                                                                        <MaskedInput mask="11/11/1111" />
                                                                    }
                                                                />
                                                                <img
                                                                    src={calendar}
                                                                    className="img-fluid calenderimg"
                                                                    alt="calender image"
                                                                    onClick={handleEndImgClick}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <Form>
                                                            <Form.Group
                                                                controlId="exampleForm.ControlSelect1"
                                                                className="formdiv"
                                                            >
                                                                <Form.Label className="logsearch">
                                                                    Rule Log Search :
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={(e) => handleLogType(e)}
                                                                    value={getLogTriggeredtype}
                                                                >
                                                                    <option value="">Search By LogType</option>
                                                                    <option value="SUCCESS">SUCCESS</option>
                                                                    <option value="FAILED">FAILED</option>
                                                                    <option value="NO_ACTION">NO_ACTION</option>
                                                                    <option value="IGNORED">IGNORED</option>
                                                                    <option value="OVERRULED">OVERRULED</option>
                                                                    <option value="BLOCKED">BLOCKED</option>
                                                                    <option value="NOT_SUPPORTED">
                                                                        NOT_SUPPORTED
                                                                    </option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch">
                                                            <label>Park Name : </label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={(e) => handleParkId(e)}
                                                                style={{ width: "100%" }}
                                                                value={gethandleParkId}
                                                            >
                                                                <option>All Parks</option>
                                                                {getparks?.map((data, idx) => (
                                                                    <option
                                                                        key={idx}
                                                                        value={data.id}
                                                                        data-idx={idx}
                                                                        data-name={data.name}
                                                                    >
                                                                        {data.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch">
                                                            <label>Device Type : </label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={(e) => handledeviceType(e)}
                                                                style={{ width: "100%" }}
                                                            >
                                                                <option>Device type</option>
                                                                {gethandleParkId !== "" &&
                                                                    getfinaldevice?.map((data, idx) => (
                                                                        <option
                                                                            key={idx}
                                                                            value={data}
                                                                            data-idx={idx}
                                                                        >
                                                                            {data}
                                                                        </option>
                                                                    ))}
                                                            </Form.Control>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch">
                                                            <label>Device Name : </label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={(e) => handledevice(e)}
                                                                style={{ width: "100%" }}
                                                            >
                                                                <option>Device</option>
                                                                {(gethandleParkId !== "" ||
                                                                    gethandleParkDeviceType !== "") &&
                                                                    getfinaldevicetype?.map((data, idx) => (
                                                                        <option
                                                                            key={idx}
                                                                            data-device={
                                                                                data.Name
                                                                                    ? data.Name
                                                                                    : data.FieldName
                                                                                        ? data.FieldName
                                                                                        : data.SensorName
                                                                            }
                                                                            value={
                                                                                data.ID
                                                                                    ? data.ID
                                                                                    : data.FieldID
                                                                                        ? data.FieldID
                                                                                        : data.SensorID
                                                                            }
                                                                            data-idx={idx}
                                                                        >
                                                                            {data.Name
                                                                                ? data.Name
                                                                                : data.FieldName
                                                                                    ? data.FieldName
                                                                                    : data.SensorName}
                                                                        </option>
                                                                    ))}
                                                            </Form.Control>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-1">
                                                    <div className="col-12 col-md-12 spacebet logbtnleftright">
                                                        <button
                                                            type="button"
                                                            className="btn  mr-2 logSearchbtn"
                                                            onClick={() => refreshData()}
                                                        >
                                                            Search
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn logcancelbtn"
                                                            onClick={cancelAllFilter}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                {pending === false ? (
                    <div className="showtablehead logbordercolor">
                        <div className="leftheading"></div>
                        <div className="rightheading">
                            <div className="createaddrulebtn logcreatebtn">
                                <Button
                                    className="addrule my-0 "
                                    size="md"
                                    variant="contained"
                                    onClick={() => ViewRule(stateDetail)}
                                    disabled={getcheckrow}
                                >
                                    <img
                                        src={alllog}
                                        alt="printerimg"
                                        className="img-fluid mr-2 "
                                    />{" "}
                                    <span className="createrule"> VIEW RULE DETAILS </span>
                                </Button>
                                {/* ) : (
                                        ""
                                    )} */}
                                {/* {permission.create == 1 ? ( */}
                                <Button
                                    className="addrule my-0 viewgeneralbtn"
                                    size="md"
                                    variant="contained"
                                    onClick={routeAllRulepage}
                                    disabled={getcheckrow}
                                >
                                    <img
                                        src={alllog}
                                        alt="printerimg"
                                        className="img-fluid mr-2 "
                                    />{" "}
                                    <span className="createrule">Rule Execution Logs</span>
                                </Button>
                                {/* ) : (
                                    ""
                                )} */}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className="allogs desktopruleview mediumlogview">
                    <div className="content-middle rules-middle mobilewidth mb-2">
                        <DataTable
                            columns={columnsdata}
                            data={getListdata}
                            striped
                            responsive
                            className="custom-table"
                            progressPending={pending}
                            progressComponent={
                                <ClipLoader
                                    color={color}
                                    loading={loading}
                                    css={override}
                                    size={60}
                                />
                            }
                            customStyles={customStyles}
                        />
                    </div>
                    {getListdata?.length > 0 && pending === false ? (
                        <Tablepaginate
                            totalpage={totalRows}
                            tablerowvalue={functablerow}
                            currentPagevalue={currentPage}
                            rulecurrentpage={funcCurrentpage}
                            totalpagenum={getTotalrules}
                            pageleft="pageleft"
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div className="allogs mobileruleview">
                    <div className="content-middle rules-middle mobilewidth mb-2">
                        {getMobileRuleList?.length > 0 && pending === false ? (
                            getMobileRuleList &&
                            getMobileRuleList?.map((ruledata, idx) => {
                                let datavalue;
                                datavalue = ruledata.Actions.map((item) => item.Status).filter(
                                    (action) => action
                                );
                                const datasame = datavalue.some((item) => item === item);
                                console.log(datasame);
                                if (
                                    datavalue.length > 0 &&
                                    datavalue.every((status) => status === datavalue[0])
                                ) {
                                    datavalue = datavalue[0];
                                } else {
                                    console.log("Values in the array are different.");
                                }
                                return (
                                    <div
                                        className={`row mobilerulerow  ${idx == 0 ? "" : "mt-2"} `}
                                        key={idx}
                                    >
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Severity</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div className="commonsize logtableicons">
                                                    {datavalue == "IGNORED" ? (
                                                        <img src={ignored} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "NOT_SUPPORTED" ? (
                                                        <img src={notsupport} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "BLOCKED" ? (
                                                        <img src={blocked} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "OVERRULED" ? (
                                                        <img src={overruled} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "NO_ACTION" ? (
                                                        <img src={noaction} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "FAILED" ? (
                                                        <img src={failed} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "SUCCESS" ? (
                                                        <img src={success} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Rule Name</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div>{getRuleName ?? getRuleName}</div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Description</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div>{getRuleDescription ?? getRuleDescription}</div>
                                            </div>
                                        </div>

                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Date</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 pl-1">
                                            <div className="validity ruleContent">
                                                <div className="rulealways">
                                                    {simpledate(ruledata.InsertDate)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Conditions</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="conditional ruleContent">
                                                {ruledata.Conditions?.length > 0 ? (
                                                    ruledata.Conditions?.map((condition, idx) => {
                                                        const parkNameDisplay =
                                                            condition.ParkName ===
                                                                "Morrisville Community Park"
                                                                ? "MCP"
                                                                : condition.ParkName ===
                                                                    "Morrisville Aquatic and Fitness Center"
                                                                    ? "MAFC"
                                                                    : condition.ParkName ===
                                                                        "Cedar Fork District Park"
                                                                        ? "CFDP"
                                                                        : condition.ParkName === "Shiloh Park"
                                                                            ? "SP"
                                                                            : condition.ParkName ===
                                                                                "Crabtree Creek Nature Park"
                                                                                ? "CCNP"
                                                                                : condition.ParkName === "Church Street Park"
                                                                                    ? "CSP"
                                                                                    : "NA";
                                                        return (
                                                            <Accordion
                                                                className={`viewaccordion trigeracco ${(condition.Status &&
                                                                        condition.Status == "true") ||
                                                                        (condition.ObservedState &&
                                                                            condition.ObservedState.wasConditionMet ==
                                                                            true)
                                                                        ? "logtrue"
                                                                        : (condition.Status &&
                                                                            condition.Status == "false") ||
                                                                            (condition.ObservedState &&
                                                                                condition.ObservedState
                                                                                    .wasConditionMet == false)
                                                                            ? "logfalse"
                                                                            : ""
                                                                    }`}
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<KeyboardArrowRightIcon />}
                                                                    aria-controls={`panel${idx + 1}a-content`}
                                                                    id={`panel${idx + 1}a-header`}
                                                                    className="accorhead"
                                                                >
                                                                    <Typography
                                                                        className={`w-100  ${classes.heading}`}
                                                                    >
                                                                        <span className="headname commonsize">
                                                                            {parkNameDisplay} , {condition.DeviceName}{" "}
                                                                            ,{" "}
                                                                            {trivalue(
                                                                                condition.TriggerValue,
                                                                                condition.TriggerCriteria,
                                                                                condition.Devicetype
                                                                            )}{" "}
                                                                        </span>{" "}
                                                                        <span
                                                                            className={`allstatus ${(condition.Status &&
                                                                                    condition.Status == "true") ||
                                                                                    (condition.ObservedState &&
                                                                                        condition.ObservedState
                                                                                            .wasConditionMet == true)
                                                                                    ? "truecolor"
                                                                                    : (condition.Status &&
                                                                                        condition.Status == "false") ||
                                                                                        (condition.ObservedState &&
                                                                                            condition.ObservedState
                                                                                                .wasConditionMet == false)
                                                                                        ? "falsecolor"
                                                                                        : ""
                                                                                }`}
                                                                        >
                                                                            Status:{" "}
                                                                            {`${condition.ObservedState &&
                                                                                    condition.ObservedState
                                                                                    ? condition.ObservedState
                                                                                        .wasConditionMet
                                                                                    : condition.Status
                                                                                }`}
                                                                        </span>
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails className="d-block">
                                                                    <Table
                                                                        size="sm"
                                                                        className="text-left"
                                                                        key={idx}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Park Name: {parkNameDisplay}</td>
                                                                                <td>
                                                                                    Device Type: {condition.Devicetype}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Device Name: {condition.DeviceName}
                                                                                </td>
                                                                                <td>
                                                                                    Trigger Value:{" "}
                                                                                    {condition.Devicetype.toLowerCase() ===
                                                                                        "court"
                                                                                        ? condition.TriggerValue.toLowerCase() ===
                                                                                            "open"
                                                                                            ? "Unoccupied"
                                                                                            : "Occupied"
                                                                                        : condition.TriggerValue}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Trigger Criteria:{" "}
                                                                                    {condition.TriggerCriteria}
                                                                                </td>
                                                                                <td>Duration: {condition.Tolerance}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    {condition.ObservedState
                                                                                        ? "ObservedMessage"
                                                                                        : "ObservedValue"}{" "}
                                                                                    :
                                                                                    {condition.Devicetype &&
                                                                                        condition.Devicetype.toLowerCase() ===
                                                                                        "court"
                                                                                        ? condition.ObservedValue &&
                                                                                            condition.ObservedValue.toLowerCase() ===
                                                                                            "open"
                                                                                            ? "Unoccupied"
                                                                                            : condition.ObservedValue &&
                                                                                                condition.ObservedValue.toLowerCase() ===
                                                                                                "close"
                                                                                                ? "Occupied"
                                                                                                : condition.ObservedState
                                                                                                    .ObservedMessage
                                                                                        : condition.ObservedState &&
                                                                                            condition.ObservedState
                                                                                            ? condition.ObservedState
                                                                                                .ObservedMessage
                                                                                            : condition.ObservedValue}
                                                                                </td>
                                                                                <td>
                                                                                    Status:{" "}
                                                                                    {`${condition.ObservedState &&
                                                                                            condition.ObservedState
                                                                                            ? condition.ObservedState
                                                                                                .wasConditionMet
                                                                                            : condition.Status
                                                                                        }`}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="textblue">TIME BASED RULE</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Actions</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="actions ruleContent">
                                                {ruledata.Actions?.map((data, idx) => {
                                                    const parkNameDisplay =
                                                        data.ParkName === "Morrisville Community Park"
                                                            ? "MCP"
                                                            : data.ParkName ===
                                                                "Morrisville Aquatic and Fitness Center"
                                                                ? "MAFC"
                                                                : data.ParkName === "Cedar Fork District Park"
                                                                    ? "CFDP"
                                                                    : data.ParkName === "Shiloh Park"
                                                                        ? "SP"
                                                                        : data.ParkName === "Crabtree Creek Nature Park"
                                                                            ? "CCNP"
                                                                            : data.ParkName === "Church Street Park"
                                                                                ? "CSP"
                                                                                : "NA";
                                                    return (
                                                        <Accordion
                                                            className={`viewaccordion trigeracco ${data.Status == "IGNORED"
                                                                    ? "logignore"
                                                                    : data.Status == "SUCCESS"
                                                                        ? "logsuccess"
                                                                        : data.Status == "FAILED"
                                                                            ? "logfailed"
                                                                            : data.Status == "NO_ACTION"
                                                                                ? "lognoaction"
                                                                                : data.Status == "OVERRULED"
                                                                                    ? "logoveruled"
                                                                                    : data.Status == "BLOCKED"
                                                                                        ? "logblocked"
                                                                                        : data.Status == "NOT_SUPPORTED"
                                                                                            ? "lognotsupport"
                                                                                            : data.Status == "TRIGGERED"
                                                                                                ? "logtriggered"
                                                                                                : ""
                                                                }`}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<KeyboardArrowRightIcon />}
                                                                aria-controls={`panel${idx + 1}a-content`}
                                                                id={`panel${idx + 1}a-header`}
                                                                className="accorhead"
                                                            >
                                                                <Typography
                                                                    className={`w-100  ${classes.heading}`}
                                                                >
                                                                    <span className="headname commonsize">
                                                                        {parkNameDisplay},{data.DeviceName},
                                                                        {data.Action}{" "}
                                                                    </span>{" "}
                                                                    <span
                                                                        className={`actionlogstatus ${data.Status == "IGNORED"
                                                                                ? "ignoreColor"
                                                                                : ""
                                                                            }`}
                                                                    >
                                                                        Status : {data.Status}
                                                                    </span>
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails className="d-block">
                                                                <Table
                                                                    striped
                                                                    bordered
                                                                    hover
                                                                    size="sm"
                                                                    className="text-left"
                                                                >
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Park Name : {parkNameDisplay}</td>
                                                                            <td>Device Type : {data.Devicetype}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Device : {data.DeviceName}</td>
                                                                            <td>Action : {data.Action}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Reason : {data.Reason}</td>
                                                                            <td>Status : {data.Status}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Settings</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="settings ruleContent">
                                                <div className="menubtn mobilemenubtn">
                                                    <button
                                                        className="btn logviewbtn"
                                                        onClick={() => viewlogpage(ruledata._id)}
                                                    >
                                                        <span className="actionbutton">View Log</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : loading ? (
                            <ClipLoader
                                color={color}
                                loading={loading}
                                css={override}
                                size={60}
                            />
                        ) : (
                            <div className="norecord">No Record Found</div>
                        )}
                    </div>
                    {getListdata?.length > 0 && pending === false ? (
                        <Tablepaginate
                            totalpage={totalRows}
                            tablerowvalue={functablerow}
                            currentPagevalue={currentPage}
                            rulecurrentpage={funcCurrentpage}
                            totalpagenum={getTotalrules}
                            pageleft="pageleft"
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </section>
        </div>
    );
}
