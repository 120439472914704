import React, { useState, useRef, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Paper, Grid, useMediaQuery, Box, Typography } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import totalOpen from "../../../assets/img/totalOpen.svg";
import totalClosed from "../../../assets/img/totalClosed.svg";
import poolOpen from "../../../assets/img/poolOpen.svg";
import poolClosed from "../../../assets/img/poolClosed.svg";
import swimmer from "../../../assets/img/swimmer.svg";
import swimmer1 from "../../../assets/img/swimmer1.svg";
import swimmer2 from "../../../assets/img/swimmer2.svg";
import swimmer3 from "../../../assets/img/swimmer3.svg";
import swimmer4 from "../../../assets/img/swimmer4.svg";
import poolRoomImage from "../../../assets/img/poolroom.png";
import SvgContainer from "./SvgContainer";
import MultipleSeriesChart from "./yearlyGraph";
import OccupancyCardRenderer from "../../../components/OccupancyCard/OccupancyCardRenderer";

const setColor = ({ type, status }) => {
  if (type === "text") {
    if (status === "closed") {
      return "#c14d52";
    } else if (status === "open") {
      return "#019242";
    }
  } else if (type === "background") {
    if (status === "closed") {
      return "#fce2e6";
    } else if (status === "open") {
      return "#e5f7ec";
    }
  }
};

const MafcPoolArea = () => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [graphHeight, setGraphHeight] = useState(650);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const updateWidth = () => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  };
  useEffect(() => {
    updateWidth();
  }, [containerRef]);
  const poolMap = [
    {
      name: "Total Count",
      type: "rect",
      xAxis: 835,
      yAxis: 100,
      width: 350,
      height: 160,
      color: "#ffffff",
      radiusX: "10px",
      radiusY: "10px",
      stroke: "#e8e7e7",
      strokeWidth: "1",
      lineCap: "butt",
    },
    {
      name: "Lap Pool",
      type: "rect",
      xAxis: 1240,
      yAxis: 100,
      width: 350,
      height: 160,
      color: "#ffffff",
      radiusX: "10px",
      radiusY: "10px",
      stroke: "#e8e7e7",
      strokeWidth: "2",
      lineCap: "butt",
    },
    {
      name: "Total Count Icon",
      type: "image",
      width: 60,
      xAxis: 855,
      yAxis: 110,
      body: totalOpen,
    },
    {
      name: "Lap Pool Icon",
      type: "image",
      width: 60,
      xAxis: 1260,
      yAxis: 110,
      body: poolOpen,
    },
    {
      name: "Total Count Occupancy",
      type: "rect",
      xAxis: 1075,
      yAxis: 110,
      width: 100,
      height: 30,
      color: setColor({ type: "background", status: "open" }),
    },
    {
      name: "Lap Pool Occupancy",
      type: "rect",
      xAxis: 1480,
      yAxis: 110,
      width: 100,
      height: 30,
      color: setColor({ type: "background", status: "open" }),
    },
    {
      name: "Total Count Occupancy Text",
      type: "text",
      xAxis: 1100,
      yAxis: 130,
      body: "Open",
      fontSize: 20,
      textColor: setColor({ type: "text", status: "open" }),
    },
    {
      name: "Lap Pool Occupancy Text",
      type: "text",
      xAxis: 1505,
      yAxis: 130,
      body: "Open",
      fontSize: 20,
      textColor: setColor({ type: "text", status: "open" }),
    },
    {
      name: "Total Count Label",
      type: "text",
      xAxis: 935,
      yAxis: 180,
      body: "Total Count",
      fontSize: 36,
    },

    {
      name: "Lap Pool Label",
      type: "text",
      xAxis: 1340,
      yAxis: 180,
      body: "Lap Pool",
      fontSize: 36,
    },
    {
      name: "Total Count Value",
      type: "text",
      xAxis: 940,
      yAxis: 230,
      body: "Coming Soon",
      fontSize: 32,
    },
    {
      name: "Lap Pool Value",
      type: "text",
      xAxis: 1345,
      yAxis: 230,
      body: "Coming Soon",
      fontSize: 32,
    },
    {
      name: "Lane6Swimmer",
      type: "image",
      width: 240,
      xAxis: 1056,
      yAxis: 578,
      body: swimmer,
    },
    {
      name: "Lane3Swimmer",
      type: "image",
      width: 240,
      xAxis: 460,
      yAxis: 578,
      body: swimmer,
    },
    {
      name: "Lane1Swimmer",
      type: "image",
      width: 105,
      xAxis: 545,
      yAxis: 415,
      body: swimmer1,
    },
    {
      name: "Lane5Swimmer2",
      type: "image",
      width: 105,
      xAxis: 1240,
      yAxis: 430,
      body: swimmer2,
    },
    {
      name: "Lane2Swimmer",
      type: "image",
      width: 105,
      xAxis: 715,
      yAxis: 425.83,
      body: swimmer2,
    },
    {
      name: "Lane4Swimmer",
      type: "image",
      width: 210,
      xAxis: 851,
      yAxis: 476.93,
      body: swimmer4,
    },
  ];
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Weekly Summary",
      align: "left",
    },
    xAxis: {
      categories: ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"],
      crosshair: false,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Occupancy",
      },
    },
    tooltip: {
      valueSuffix: "",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    // responsive: {
    //   rules: [
    //     {
    //       condition: {
    //         maxWidth: 500,
    //       },
    //       chartOptions: {
    //         chart: {
    //           height: 250,
    //         },
    //         subtitle: {
    //           text: null,
    //         },
    //         navigator: {
    //           enabled: false,
    //         },
    //       },
    //     },
    //   ],
    // },
    series: [
      {
        name: "Splash Pod",
        data: [6, 26, 10, 8, 7, 14, 10],
        visible: true,
      },
      {
        name: "Swim Lanes",
        data: [6, 26, 10, 8, 7, 14, 10],
        visible: false,
      },
      {
        name: "Pool",
        data: [6, 26, 10, 8, 7, 14, 10],
        visible: false,
      },
    ],
  };

  const fieldsList = [
    {
      name: "Pool Area Occupancy",
      chips: [
        { name: "Total Count:- ", value: "", color: "#006ea6" },
        { name: "Lap Pool:- ", value: "", color: "#006ea6" },
      ],
    },
  ];

  const getConfig = () => ({
    plotOptions: {
      column: {
        colorByPoint: true,
      },
    },

    // colors: ["#0076bb"],
    // responsive: {
    //   rules: [
    //     {
    //       condition: {
    //         maxWidth: 500,
    //       },
    //       chartOptions: {
    //         chart: {
    //           height: graphHeight,
    //         },
    //         subtitle: {
    //           text: null,
    //         },
    //         navigator: {
    //           enabled: false,
    //         },
    //       },
    //     },
    //   ],
    // },

    rangeSelector: {
      enabled: false,
      inputEnabled: false,
      buttonTheme: {
        visibility: "hidden",
      },
      labelStyle: {
        visibility: "hidden",
      },
    },
    // height: "350px",

    title: {
      text: "",
      align: "left",
    },
    chart: {
      type: "column",
      height: "100%", // Ensure the chart respects container height
    },
    plotOptions: {
      // column: {
      //   stacking: "normal",
      // },
    },

    xAxis: {
      categories: [
        "6 am",
        "7 am",
        "8 am",
        "9 am",
        "10 am",
        "11 am",
        "12",
        "1 pm",
        "2 pm",
        "3 pm",
        "4 pm",
        "5 pm",
        "6 pm",
        "7 pm",
        "8 pm",
        "9 pm",
        "10 pm",
      ],
    },
    yAxis: {
      visible: false,
      min: 0,
      title: {
        text: "",
      },
    },
    tooltip: {
      split: true,
      formatter: function () {
        var crowd = this.y;
        if (crowd >= 10)
          return "Occupied " + crowd + " times.</br> Usually Busy On " + this.x;
        else
          return (
            "Occupied " +
            crowd +
            " times.</br> Usually Not So Busy On " +
            this.x
          );
      },
    },

    series: [
      {
        name: "Sunday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: true,
      },
      {
        name: "Monday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
      {
        name: "Tuesday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
      {
        name: "Wednesday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
      {
        name: "Thursday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
      {
        name: "Friday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
      {
        name: "Saturday",
        showInLegend: true,
        // colorByPoint: true,
        data: category,
        visible: false,
      },
    ],
  });

  const category = [
    ["6 am", 0],
    ["7 am", 2],
    ["8 am", 1],
    ["9 am", 2],
    ["10 am", 2],
    ["11 am", 1],
    ["12", 0],
    ["1 pm", 1],
    ["2 pm", 2],
    ["3 pm", 0],
    ["4 pm", 1],
    ["5 pm", 5],
    ["6 pm", 5],
    ["7 pm", 5],
    ["8 pm", 4],
    ["9 pm", 2],
    ["10 pm", 0],
  ];
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OccupancyCardRenderer fieldList={fieldsList} />
        </Grid>
      </Grid>
      <Grid
        innerRef={containerRef}
        style={{
          // height: width < 1200 ? "600px" : "600px",
          marginBottom: 10,
        }}
        container
        spacing={2}
      >
        <Grid item xs={12} lg={8}>
          <Paper elevation={7}>
            <SvgContainer
              title="Pool Area"
              mappingArray={poolMap}
              image={poolRoomImage}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Paper
            style={{
              height: "100%",
            }}
            elevation={7}
          >
            <Box p={2}>
              <Typography variant="h5">Coming Soon</Typography>
            </Box>
            {/* <HighchartsReact
              highcharts={Highcharts}
              options={getConfig()}
              containerProps={{
                style: { height: "100%", width: "100%" },
              }}
            /> */}
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper style={{ height: 400 }} elevation={7}>
            <Box p={2}>
              <Typography variant="h5">Coming Soon</Typography>
            </Box>
            {/* <HighchartsReact
              highcharts={Highcharts}
              options={options}
              containerProps={{ style: { height: 640, width: "100%" } }}
            /> */}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ height: 400 }} elevation={7}>
            <Box p={2}>
              <Typography variant="h5">Coming Soon</Typography>
            </Box>
            {/* <MultipleSeriesChart /> */}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default MafcPoolArea;
