import React, { useState, useRef, Suspense } from "react";
import { useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BiSolidXCircle } from "react-icons/bi";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import "../../assets/scss/App.scss";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import { useSelector, useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import Button from "../../components/CustomButtons/Button.js";
import { GenerateEncryptedToken } from "../../views/Common";
import {
  permissions_,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import { login_ } from "../../reducers/login";
import _ from "lodash";
import { paperAutoAdjustIntervalInMS } from "../../config/index";
import { BiSolidCheckCircle } from "react-icons/bi";
import { TfiReload } from "react-icons/tfi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkPermissions } from "../../utils/checkPermissions.js";
const NewRulelist = React.lazy(() => import("./NewRules.js"));

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" position="top" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

export default function Rules(props) {
  const loginDetails = useSelector(login_);
  const userid = _.get(loginDetails, ["UserID"], "");
  const [loading, setLoading] = React.useState(true);
  const [color, setColor] = React.useState("#ffffff");
  const [permission, setpermission] = React.useState({});
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);
  const [open, setOpen] = React.useState(false);
  const [deleteopen, setdeleteOpen] = React.useState(false);
  const [getSearchfilter, setSearchfilter] = React.useState([]);
  const [getparks, setparks] = React.useState([]);
  const [selectAllrow, setSelectAllrow] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [pending, setPending] = React.useState(true);
  const [getDeleterefresh, setDeleterefresh] = React.useState(false);
  const [getToggleconfirm, setToggleconfirm] = React.useState("");
  const [getConfirmMessage, setConfirmMessage] = React.useState(false);
  const [getdeleteToggleconfirm, setdeleteToggleconfirm] = React.useState("");
  const [getdisableRuleid, setdisableRuleid] = React.useState([]);
  const [getdeleteRuleid, setdeleteRuleid] = React.useState([]);
  const [getableRuleid, setableRuleid] = React.useState([]);
  const [getableRulename, setableRulename] = React.useState([]);
  const [getableRuledes, setableRuledes] = React.useState([]);
  const [getableRulepriority, setableRulepriority] = React.useState([]);
  const [getMultipledisabled, setMultipledisabled] = React.useState([]);
  const [getruleNameText, setruleNameText] = React.useState([]);
  const [getruleDesText, setruleDesText] = React.useState([]);
  const [getpriorityNumberText, setpriorityNumberText] = React.useState([]);
  const [getenabledisablemenutext, setenabledisablemenutext] = React.useState(
    []
  );
  const [multiopen, setmultiOpen] = React.useState(false);
  const [getdisableRulestatus, setdisableRulestatus] = React.useState("");
  const [getdisableRulepriority, setdisableRulepriority] = React.useState("");
  const [getdisableRuledes, setdisableRuledes] = React.useState("");
  const [getdisableRulename, setdisableRulename] = React.useState("");
  const [getdeleteRulepriority, setdeleteRulepriority] = React.useState("");
  const [getdeleteRuledes, setdeleteRuledes] = React.useState("");
  const [getdeleteRulename, setdeleteRulename] = React.useState("");
  const [getTotalrules, setTotalrules] = React.useState("");
  const [getTotalRowselect, setTotalRowselect] = React.useState("");
  const [getoperational, setoperational] = React.useState({});
  const [multideleteopen, setmultideleteopen] = React.useState(false);
  const [getcheckrow, setcheckrow] = React.useState(false);
  const [getabledisableRulestatus, setabledisableRulestatus] =
    React.useState("");
  const [getSearchrulename, setSearchrulename] = React.useState("");
  const [getSearchruleID, setSearchruleID] = React.useState("");
  const [getSearchrulestartPriority, setSearchrulestartPriority] =
    React.useState("");
  const [getSearchruleendPriority, setSearchruleendPriority] =
    React.useState("");
  const [getSearchruleStartDate, setSearchruleStartDate] = React.useState("");
  const [getShouldUpdatePage, setShouldUpdatePage] = React.useState(false);
  const [getSearchruleEndDate, setSearchruleEndDate] = React.useState("");
  const [getSearchruleparkname, setSearchruleparkname] = React.useState("");
  const [getSearchruleDevicetype, setSearchruleDevicetype] = React.useState("");
  const [getSearchruleDevice, setSearchruleDevice] = React.useState("");
  const [getBulkActions, setBulkActions] = React.useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [MobileIsDisabled, setMobileIsDisabled] = useState(true);
  const [allCheckRule, setAllCheckRule] = useState([]);
  const [allCheckRuleName, setAllCheckRuleName] = useState("");
  const [allCheckRulePriority, setAllCheckRulePriority] = useState("");
  const [allCheckRuleDes, setAllCheckRuleDes] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const numRows = getableRuleid.length;

  function togglerule() {
    if (
      getToggleconfirm === "" ||
      (getToggleconfirm.toLowerCase() !== "yes" &&
        getToggleconfirm !== "YES" &&
        getToggleconfirm !== "Yes")
    ) {
      setConfirmMessage(true);
      return false;
    }
    setAllCheckRule([]);
    setMultipledisabled([]);
    setruleNameText([]);
    setruleDesText([]);
    setpriorityNumberText([]);
    setenabledisablemenutext([]);
    setSelectAllChecked(false);
    if (
      (getToggleconfirm.toLowerCase() == "yes" &&
        getToggleconfirm == "YES" &&
        getToggleconfirm == "Yes") ||
      getToggleconfirm !== ""
    ) {
      if (getdisableRulestatus !== "" && getdisableRuleid.length > 0) {
        let url = "api/park/ruleengine/togglestatus";
        var restoken = GenerateEncryptedToken();
        var requestBody = {
          Status: getdisableRulestatus,
          RuleIDs: getdisableRuleid,
        };
        smartParkService
          .post(url, requestBody, {
            headers: {
              "x-access-token": restoken,
              userid: userid,
            },
          })
          .then((res) => {
            if (res) {
              setDeleterefresh(true);
              if (res.status === 200) {
                if (res.data.status === "200") {
                  handleClose();
                  const mes = (
                    <div className="toastsuccess">
                      <div className="toasticon">
                        <BiSolidCheckCircle />
                      </div>
                      <div className="toastmessage">
                        <b>Success</b>
                        <div>{res.data.message}</div>
                      </div>
                    </div>
                  );
                  toast.success(mes, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: "toastcontainer",
                  });
                  setDeleterefresh(true);
                } else {
                  const mes = (
                    <div className="toastsuccess">
                      <div className="erroricon">
                        <BiSolidXCircle />
                      </div>
                      <div className="toastmessage">
                        <b>Error</b>
                        <div>{res.data.message}</div>
                      </div>
                    </div>
                  );
                  toast.error(mes, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: "errorcontainer",
                  });
                  setDeleterefresh(true);
                }
              }
            }
          });
      }
    }
  }
  function deletethisrule() {
    if (
      getdeleteToggleconfirm == "" ||
      (getdeleteToggleconfirm.toLowerCase() !== "yes" &&
        getdeleteToggleconfirm !== "YES" &&
        getdeleteToggleconfirm !== "Yes")
    ) {
      setConfirmMessage(true);
      return false;
    }
    setAllCheckRule([]);
    setMultipledisabled([]);
    setruleNameText([]);
    setruleDesText([]);
    setpriorityNumberText([]);
    setenabledisablemenutext([]);
    setSelectAllChecked(false);

    if (
      (getdeleteToggleconfirm.toLowerCase() == "yes" &&
        getdeleteToggleconfirm == "YES" &&
        getdeleteToggleconfirm == "Yes") ||
      getdeleteToggleconfirm !== ""
    ) {
      if (getdeleteRuleid && getdeleteRuleid.length > 0) {
        let url = "api/park/ruleengine/deleterules";
        var restoken = GenerateEncryptedToken();
        var requestBody = {
          RuleIDs: getdeleteRuleid,
        };
        smartParkService
          .post(url, requestBody, {
            headers: {
              "x-access-token": restoken,
              userid: userid,
            },
          })
          .then((res) => {
            if (res) {
              if (res.data.status == 200) {
                handleClose();
                const mes = (
                  <div className="toastsuccess">
                    <div className="toasticon">
                      <BiSolidCheckCircle />
                    </div>
                    <div className="toastmessage">
                      <b>Success</b>
                      <div>{res.data.message}</div>
                    </div>
                  </div>
                );
                toast.success(mes, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  className: "toastcontainer",
                });
                setDeleterefresh(true);
              } else {
                const mes = (
                  <div className="toastsuccess">
                    <div className="erroricon">
                      <BiSolidXCircle />
                    </div>
                    <div className="toastmessage">
                      <b>Error</b>
                      <div>{res.data.message}</div>
                    </div>
                  </div>
                );
                toast.error(mes, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  className: "errorcontainer",
                });
              }
            }
          });
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
    setdeleteOpen(false);
    setmultiOpen(false);
    setmultideleteopen(false);
    setToggleconfirm("");
    setdeleteToggleconfirm("");
    setConfirmMessage(false);
    setBulkActions("");
    setcheckrow(false);
    setIsDisabled(true);
    setSelectAllrow(!selectAllrow);
    setSelectedRows([]);
  };

  React.useEffect(() => {
    funcSearchoptions();
  }, []);

  function funcSearchoptions() {
    try {
      let url = "api/park/ruleengine/searchoptions";
      var restoken = GenerateEncryptedToken();
      smartParkService
        .get(url, {
          headers: {
            "x-access-token": restoken,
            userid: userid,
          },
        })
        .then((res) => {
          if (res) {
            try {
              if (res.status === 200) {
                if (res.data.status == 200) {
                  var dataa = [];
                  var devices = {};
                  res.data.options?.forEach((data, i) => {
                    dataa.push({
                      id: data.ID,
                      name: data.Name,
                      shortname: data.ShortName,
                      device: data.Devices,
                    });
                    devices[data.ID] = data.Devices;
                  });
                  setparks(dataa);
                  setSearchfilter(devices);
                } else {
                  funcSearchoptions();
                }
              } else {
                funcSearchoptions();
              }
            } catch {
              console.log("some error in search Dropdown api ");
            }
          } else {
            console.log("Search else error: ");
          }
        })
        .catch((error) => {
          console.error("Search error: ", error);
        });
    } catch {
      console.log("Search Option Error");
    }
  }

  React.useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);

  // const [rows, setrows] = React.useState([]);

  const rulepage = (e) => {
    e.preventDefault();
    if (permission.create == 1) {
      let path = "Rules";
      history.push({
        pathname: "/Rules",
        state: { detail: "Rule List" },
      });
    } else {
      alert("You do not have permission to create rule");
    }
  };

  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "RuleEngine";
    });
    setpermission(Dpermissions[0]);
  };

  setTimeout(() => {
    var element = document.getElementsByClassName("ruleGrid__SmartPark");
    if (element && element.length > 0) {
      var calculatedHeight = element[0].clientHeight + 25 + "px";
      document.getElementById("rulePaper__SmartPark").style.height =
        calculatedHeight;
    }
  }, paperAutoAdjustIntervalInMS);

  function multitogglerule() {
    if (
      getToggleconfirm === "" ||
      (getToggleconfirm.toLowerCase() !== "yes" &&
        getToggleconfirm !== "YES" &&
        getToggleconfirm !== "Yes")
    ) {
      setConfirmMessage(true);
      return false;
    }
    setAllCheckRule([]);
    setMultipledisabled([]);
    setruleNameText([]);
    setruleDesText([]);
    setpriorityNumberText([]);
    setenabledisablemenutext([]);
    setSelectAllChecked(false);

    if (
      (getToggleconfirm.toLowerCase() == "yes" &&
        getToggleconfirm == "YES" &&
        getToggleconfirm == "Yes") ||
      getToggleconfirm !== ""
    ) {
      if (getabledisableRulestatus !== "" && getableRuleid?.length > 0) {
        var newStatus;
        if (getabledisableRulestatus === "false") {
          newStatus = 1;
        }
        if (getabledisableRulestatus === "true") {
          newStatus = 0;
        }
        let url = "api/park/ruleengine/togglestatus";
        var restoken = GenerateEncryptedToken();
        var requestBody = {
          Status: newStatus,
          RuleIDs: getableRuleid,
        };
        smartParkService
          .post(url, requestBody, {
            headers: {
              "x-access-token": restoken,
              userid: userid,
            },
          })
          .then((res) => {
            if (res) {
              setDeleterefresh(true);
              if (res.status === 200) {
                if (res.data.status === "200") {
                  handleClose();
                  const mes = (
                    <div className="toastsuccess">
                      <div className="toasticon">
                        <BiSolidCheckCircle />
                      </div>
                      <div className="toastmessage">
                        <b>Success</b>
                        <div>{res.data.message}</div>
                      </div>
                    </div>
                  );
                  toast.success(mes, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: "toastcontainer",
                  });
                  setcheckrow(false);
                  setDeleterefresh(true);
                } else {
                  const mes = (
                    <div className="toastsuccess">
                      <div className="erroricon">
                        <BiSolidXCircle />
                      </div>
                      <div className="toastmessage">
                        <b>Error</b>
                        <div>{res.data.message}</div>
                      </div>
                    </div>
                  );
                  toast.error(mes, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: "errorcontainer",
                  });
                  setcheckrow(false);
                  setDeleterefresh(true);
                }
              }
            }
          });
      }
    }
  }

  function multideletethisrule() {
    if (
      getToggleconfirm === "" ||
      (getToggleconfirm.toLowerCase() !== "yes" &&
        getToggleconfirm !== "YES" &&
        getToggleconfirm !== "Yes")
    ) {
      setConfirmMessage(true);
      return false;
    }
    setAllCheckRule([]);
    setMultipledisabled([]);
    setruleNameText([]);
    setruleDesText([]);
    setpriorityNumberText([]);
    setenabledisablemenutext([]);

    if (
      (getToggleconfirm.toLowerCase() == "yes" &&
        getToggleconfirm == "YES" &&
        getToggleconfirm == "Yes") ||
      getToggleconfirm !== ""
    ) {
      if (getableRuleid && getableRuleid.length > 0) {
        let url = "api/park/ruleengine/deleterules";
        var restoken = GenerateEncryptedToken();
        var requestBody = {
          RuleIDs: getableRuleid,
        };
        smartParkService
          .post(url, requestBody, {
            headers: {
              "x-access-token": restoken,
              userid: userid,
            },
          })
          .then((res) => {
            if (res) {
              if (res.data.status == 200) {
                handleClose();
                const mes = (
                  <div className="toastsuccess">
                    <div className="toasticon">
                      <BiSolidCheckCircle />
                    </div>
                    <div className="toastmessage">
                      <b>Success</b>
                      <div>{res.data.message}</div>
                    </div>
                  </div>
                );
                toast.success(mes, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  className: "toastcontainer",
                });
                setcheckrow(false);
                setDeleterefresh(true);
              } else {
                const mes = (
                  <div className="toastsuccess">
                    <div className="erroricon">
                      <BiSolidXCircle />
                    </div>
                    <div className="toastmessage">
                      <b>Error</b>
                      <div>{res.data.message}</div>
                    </div>
                  </div>
                );
                toast.error(mes, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  className: "errorcontainer",
                });
                setcheckrow(false);
              }
            }
          });
      }
    }
  }

  const funcwindowreload = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("sdfsdfdsf555555555555555");
    setPending(!pending);
  };

  React.useEffect(() => {
    // Check conditions and trigger refreshData if needed
    if (
      getSearchrulename === "" &&
      getSearchruleID === "" &&
      getSearchrulestartPriority === "" &&
      getSearchruleendPriority === "" &&
      getSearchruleStartDate === "" &&
      getSearchruleEndDate === "" &&
      getSearchruleparkname === "" &&
      getSearchruleDevicetype === "" &&
      getSearchruleDevice === ""
    ) {
      console.log("66666666666666fsdfsfsf");
      setPending(true);
    }
  }, [
    getSearchrulename,
    getSearchruleID,
    getSearchrulestartPriority,
    getSearchruleendPriority,
    getSearchruleStartDate,
    getSearchruleEndDate,
    getSearchruleparkname,
    getSearchruleDevicetype,
    getSearchruleDevice,
  ]);

  React.useEffect(() => {
    checkAllData(allCheckRule);
  }, [allCheckRule, allCheckRuleName, allCheckRulePriority, allCheckRuleDes]);

  function checkAllData(allCheckRule) {
    setTotalRowselect(allCheckRule.length);
    setcheckrow(true);
    setMobileIsDisabled(false);
    if (allCheckRule.length == 0) {
      setBulkActions("");
      setcheckrow(false);
      setMobileIsDisabled(true);
    }
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // fullScreen
        className="confirmdialogbox"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="Details">
              <div className="rulemodaldes">
                <span>Rule Name : </span> <div>{getdisableRulename}</div>
              </div>
              <div className="rulemodaldes">
                <span>Rule Description :</span>
                <div>{getdisableRuledes}</div>
              </div>
              <div className="rulemodaldes">
                <span>Rule Priority :</span> <div>{getdisableRulepriority}</div>
              </div>
            </div>
            <div>
              <strong>
                Are you sure you want to{" "}
                {getdisableRulestatus == 1 ? "Enable" : "Disable"} the Rule ?
              </strong>
            </div>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Please Type Yes or No"
            type="text"
            value={getToggleconfirm}
            onChange={(e) => setToggleconfirm(e.target.value)}
            className="text-uppercase"
            fullWidth
          />
          {getConfirmMessage && (
            <p style={{ color: "red" }}>
              * Please fill this field ( YES or yes ){" "}
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="cancelbtn">
            Cancel
          </Button>
          <Button onClick={togglerule} className="agreebtn">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={multiopen}
        TransitionComponent={Transition}
        keepMounted
        className="confirmdialogbox"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="Details">
              <table className="table w-100">
                <thead>
                  <tr>
                    <th>Rule Name</th>
                    <th>Rule Description</th>
                    <th>Rule Priority</th>
                    {/* <th>Rule Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: numRows }, (_, index) => (
                    <tr key={index}>
                      <td>{getableRulename[index]}</td>
                      <td>{getableRuledes[index]}</td>
                      <td>{getableRulepriority[index]}</td>
                      {/* <td>{getableRulestatus}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <strong>Are you sure you want to toggle the Rule ?</strong>
            </div>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Please Type Yes or No"
            type="text"
            value={getToggleconfirm}
            onChange={(e) => setToggleconfirm(e.target.value)}
            className="text-uppercase"
            fullWidth
          />
          {getConfirmMessage && (
            <p style={{ color: "red" }}>
              * Please fill this field ( YES or yes ){" "}
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="cancelbtn">
            Cancel
          </Button>
          <Button onClick={multitogglerule} className="agreebtn">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={multideleteopen}
        TransitionComponent={Transition}
        keepMounted
        className="confirmdialogbox"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="Details">
              <table className="table w-100">
                <thead>
                  <tr>
                    <th>Rule Name</th>
                    <th>Rule Description</th>
                    <th>Rule Priority</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: numRows }, (_, index) => (
                    <tr key={index}>
                      <td>{getableRulename[index]}</td>
                      <td>{getableRuledes[index]}</td>
                      <td>{getableRulepriority[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <strong>Are you sure you want to Delete the Rule ?</strong>
            </div>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Please Type Yes or No"
            type="text"
            value={getToggleconfirm}
            onChange={(e) => setToggleconfirm(e.target.value)}
            className="text-uppercase"
            fullWidth
          />
          {getConfirmMessage && (
            <p style={{ color: "red" }}>
              * Please fill this field ( YES or yes )
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="cancelbtn">
            Cancel
          </Button>
          <Button onClick={multideletethisrule} className="agreebtn">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteopen}
        TransitionComponent={Transition}
        keepMounted
        className="confirmdialogbox"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="Details">
              <div className="rulemodaldes">
                <span>Rule Name : </span> <div>{getdeleteRulename}</div>
              </div>
              <div className="rulemodaldes">
                <span>Rule Description :</span>
                <div>{getdeleteRuledes}</div>
              </div>
              <div className="rulemodaldes">
                <span>Rule Priority :</span> <div>{getdeleteRulepriority}</div>
              </div>
            </div>
            <div>
              <strong>Are you sure you want to Delete the Rule ?</strong>
            </div>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Please Type Yes or No"
            type="text"
            value={getdeleteToggleconfirm}
            onChange={(e) => setdeleteToggleconfirm(e.target.value)}
            className="text-uppercase"
            fullWidth
          />
          {getConfirmMessage && (
            <p style={{ color: "red" }}>
              * Please fill this field ( YES or yes ){" "}
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="cancelbtn">
            Cancel
          </Button>
          <Button onClick={deletethisrule} className="agreebtn">
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.contentPage}>
        <section className="content-header">
          <h3 className="float-left">Rule List</h3>
          <div className="float-right">
            <div className="reloadbtn">
              <button className="btn reload" onClick={funcwindowreload}>
                <TfiReload />
                <span>Click To Reload</span>
              </button>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#" onClick={rulepage}>
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#" className="active">
                    Rules
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </section>
        <section className="newdesktopruleview">
          {checkPermissions({
            permission: "RuleEngine",
            permissionType: "read",
          }) ? (
            <Suspense
              fallback={
                <ClipLoader
                  color={color}
                  loading={loading}
                  css={override}
                  size={60}
                />
              }
            >
              <NewRulelist reloadtable={pending} />
            </Suspense>
          ) : (
            <div
              style={{
                color: "red",
                position: "absolute",
                top: "50%",
                left: "40%",
                fontSize: "20px",
                transform: "translate(" - (50 % ", ") - (50 % ")"),
              }}
            >
              "You do not have read permissions. Please contact Admin"
            </div>
          )}
        </section>
      </div>
    </div>
  );
}
