import { combineReducers } from '@reduxjs/toolkit'

import rulesReducer from './rules'
import loginReducer from './login';
import permissionsReducer from './permissions'
import fieldMoistureReducer from './fieldmoisture'
import sensorReducer from './sensors'
import userDashboard from './userDashboard';
import notifyMe from './notifyMe';
import fieldsReducer from './fields';
import todoReducers from './reducers';

const rootReducer = combineReducers({
	rules: rulesReducer,
	login: loginReducer,
	permissions: permissionsReducer,
	fieldMoisture: fieldMoistureReducer,
	sensors: sensorReducer,
	userDashboard: userDashboard,
	notifyMe: notifyMe,
	fields: fieldsReducer,
	formdatareduce: todoReducers
})
// export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
