import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import FieldStatus from "./FieldStatus";

function getModalStyle() {
  const top = 15;
  const left = 20;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0.2, 0.2, 0.2),
    borderRadius: "5px",
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const closeImg = {
    cursor: "pointer",
    position: "absolute",
    top: "3px",
    right: "8px",
    width: "25px",
  };
  return (
    <div>
      <Modal
        open={props.show}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <img
            src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
            style={closeImg}
            onClick={props.closePopup}
          />
          <FieldStatus fieldStatus={props.fieldStatus} />
        </div>
      </Modal>
    </div>
  );
}
