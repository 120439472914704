import React, { useEffect, useState, useCallback, useMemo } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, styled } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Box,
  CircularProgress,
  Grid,
  IconButton,
} from "@material-ui/core";
import { DirectionsOutlined, MoreVert } from "@material-ui/icons";
import { BsFillBellFill } from "react-icons/bs";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../../views/Common";
import Carousel from "react-bootstrap/Carousel";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MdToggleOn, MdToggleOff } from "react-icons/md";
import {
  setSelectedField,
  fieldDetailsByType_,
  setFieldCourts,
} from "../../reducers/userDashboard";
import "./courtstatuscard.css";
import "../../demo.css";
import { object } from "prop-types";
const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    height: "100%",
  },
  notifycsp: {
    fontSize: 13,
  },
  bellicon: {
    fontSize: "1.5rem",
  },
  notificationicon: {
    background: "white",
    display: "flex",
    position: "absolute",
    top: 5,
    zIndex: 99,
    right: 5,
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    cursor: "pointer",
    marginTop: "-70px",
    position: "relative",
    padding: theme.spacing(0, 2),
    height: 60,
  },
  cardContent: {
    paddingTop: 32,
  },
  ht364: {
    height: 373,
    overflowY: "auto",
    paddingBottom: "10px !important",
  },
  actionbtn: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "6px",
    right: "0",
  },
  h5: {
    color: "#fff",
    fontWeight: 600,
    width: "50%",
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    textShadow: "-2px 2px 2px #000",
  },
  imgCard: {
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 16,
    },
    "& .MuiCardContent-root": {
      [theme.breakpoints.up("lg")]: {
        height: 487,
      },
    },
  },
  media: {
    height: 200,
    cursor: "default",
    cursor: "pointer",
  },
  customButton: {
    textTransform: "capitalize",
    fontSize: "0.95em",
    fontWeight: 500,
    boxShadow: "none !important",
  },
  titleBtn: {
    background: "#53de21 !important",
    color: "#fff",
    fontWeight: "bold",
    textTransform: "capitalize",
    boxShadow: "none !important",
    borderRadius: 20,
    fontSize: 16,
    width: 100,
  },
  closetext: {
    position: "absolute",
    top: "50%",
    zIndex: "99",
    color: "white",
    left: "50%",
    transform: "translate(-50%,-50%)",
    fontSize: "2rem",
  },
}));
const occupiedStyle = {
  borderRadius: 4,
  backgroundColor: "#fec6c6",
  color: "red",
  fontSize: "12px",
  fontWeight: "bold",
};

const unOccupiedStyle = {
  borderRadius: 4,
  backgroundColor: "#b8f0d2",
  color: "#0b9444",
  fontSize: "12px",
  fontWeight: "bold",
};
const listItemContainerStyle = {
  height: "100%",
};
export default function GolfCourseCard(props) {
  const {
    actions,
    allNotify,
    cardType,
    cardStyle = "simple",
    getFields,
    refresh,
    courseData,
  } = props;
  // const [selectedCard, setSelectedCard] = useState(null);
  const [fields, setFields] = useState([]);
  const classes = useStyles();
  const [mcparr, setmcparr] = useState([]);
  const [getAllParknotifi, setAllParknotifi] = useState([]);
  const [getSortedCourseData, setSortedCourseData] = useState([]);
  const [status, setStatus] = useState("");
  const selectedCard = useSelector((state) =>
    fieldDetailsByType_(state, cardType)
  );
  const dispatch = useDispatch();
  const [closeoverlay, setcloseoverlay] = React.useState(true);
  const [getNotification, setNotification] = React.useState(false);
  const [getNotificationRefresh, setNotificationRefresh] =
    React.useState(false);
  const [getNotificationMessage, setNotificationMessage] = React.useState("");
  const [getnotifyWeblink, setnotifyWeblink] = React.useState("");
  const [getnotifyWebname, setnotifyWebname] = React.useState("");
  const parkrealid =
    cardType === "cfdp"
      ? "c7a1d85f-8168-4e64-8e38-116a0624bfea"
      : cardType === "churchstreettenniscourts"
        ? "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944"
        : cardType === "mafctenniscourts"
          ? "91474e36-76c5-4d59-a8da-2998ab14a4bf"
          : "c7a1d85f-8168-4e64-8e38-116a0624bfeb";

  // console.log(courseData);

  useEffect(() => {
    let sortCourseData;
    sortCourseData = courseData.hole_data.sort(function (a, b) {
      return a.DisplayOrder - b.DisplayOrder;
    });
    setSortedCourseData(sortCourseData);
  }, [courseData]);
  // useEffect(() => {
  //   openfn();
  // }, []);
  let getGolfOverlayID = JSON.parse(localStorage.getItem("GolfOverlayID"));

  const chunkSize = 6;
  const holeChunks = [];
  // Divide the hole_data array into chunks of size 6
  for (let i = 0; i < getSortedCourseData?.length; i += chunkSize) {
    holeChunks.push(getSortedCourseData?.slice(i, i + chunkSize));
  }

  useEffect(() => {
    notificationFunction();
  }, [parkrealid, getNotificationRefresh]);

  function simpledate(date) {
    try {
      const parsedDate = new Date(date);
      const simpleDate = parsedDate.toISOString().split("T")[0];
      return simpleDate;
    } catch {
      return date;
    }
  }

  const notificationFunction = () => {
    if (parkrealid !== "" && parkrealid !== undefined && parkrealid !== null) {
      let url = `api/notification/detail/${parkrealid}`;
      smartParkService
        .get(url)
        .then((res) => {
          if (res.status == 200) {
            setNotification(false);
            if (res.data.status == 200) {
              let allParkdetails = res.data.data;
              console.log(allParkdetails);
              if (allParkdetails !== null) {
                let nstartDate = simpledate(allParkdetails.startDate);
                let nendDate = simpledate(allParkdetails.endDate);
                let newdate = simpledate(new Date());
                if (allParkdetails.parkId == parkrealid) {
                  if (newdate >= nstartDate && newdate <= nendDate) {
                    console.log("sdlfjdsfsl=======");
                    console.log(allParkdetails);
                    if (allParkdetails.parkId == parkrealid) {
                      setNotificationMessage(allParkdetails.message);
                      setnotifyWeblink(allParkdetails.webLink);
                      setnotifyWebname(allParkdetails.webName);
                      setNotification(true);
                    }
                  } else {
                    setNotification(false);
                  }
                }
              }
            }
          }
        })

        .catch((error) => {
          console.error("Notification error: ", error);
        });
    } else {
      console.log("please Enter all details");
    }
  };

  // console.log(getAllParknotifi);

  function openfn() {
    if (!selectedCard) {
      return;
    }
    smartParkService
      .get("api/park/" + parkrealid + "/facilitiesmanagement")
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          if (res.data.message === false) {
            setcloseoverlay(false);
          } else {
            setcloseoverlay(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // MT-58 issue

  let cardHeights = [];

  // Get all the card elements with the same class
  let cards = document.getElementsByClassName("MuiGrid-grid-md-6");
  // Initialize a variable to store the highest height
  let maxHeight = 0;
  // Loop through each card element
  for (let i = 0; i < cards.length; i++) {
    let card = cards[i];
    // Get the height of the current card
    let height = card.clientHeight;
    // Push the height to the array
    cardHeights.push(height);
    // Update the maxHeight if the current height is greater
    if (height > maxHeight) {
      maxHeight = height;
    }
  }

  for (let i = 0; i < cards.length; i++) {
    let card = cards[i];
    let innerDiv = card.querySelector(".MuiPaper-rounded");
    innerDiv.style.minheight = `${maxHeight}px`;
  }

  return (
    <>
      {courseData ? (
        cardStyle === "simple" ? (
          <Card elevation={8} className={`${classes.card} sameheight`}>
            <CardActionArea
              className={
                (getGolfOverlayID || [])?.some(
                  (entry) => entry.golfID === courseData.golfID
                ) &&
                  getGolfOverlayID.find(
                    (entry) => entry.golfID === courseData.golfID
                  )?.status.toLowerCase() === "closed"
                  ? "parkbtn"
                  : "overlaybg"
              }
              onClick={() => {
                if (courseData.name === "Practice Green") {
                  props.handleOpen(courseData);
                }
              }}
            >
              <CardMedia
                className={classes.media}
                image={courseData.img_path}
              />
              {(getGolfOverlayID || [])?.some(
                (entry) => entry.golfID === courseData.golfID
              ) &&
                getGolfOverlayID.find(
                  (entry) => entry.golfID === courseData.golfID
                )?.status === "Close" ? (
                <div className={classes.closetext}>Closed</div>
              ) : (
                ""
              )}

              {getNotification ? (
                <div className="notificationicon">
                  <div className="notify">
                    <BsFillBellFill className="text-danger notifybellicon" />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </CardActionArea>
            <div
              className={classes.cardTitle}
              onClick={() => {
                if (courseData.name === "Practice Green") {
                  props.handleOpen(courseData);
                }
              }}
            >
              <h5 className={classes.h5}>{courseData.name}</h5>
            </div>
            <CardContent className="conheight mb-4">
              <div className="position-relative">
                <h6 className="title1 samesize">Status</h6>
                <div className="golfcarousel">
                  <Carousel
                    fade
                    interval={null}
                    prevIcon={<FaChevronLeft />}
                    nextIcon={<FaChevronRight />}
                  >
                    {holeChunks?.map((chunk, index) => (
                      <Carousel.Item key={index}>
                        <div className="grid-container p-0">
                          {chunk?.map((field) => (
                            <div className="itemgolf text-center golfitems">
                              {console.log(field)}
                              <div
                                className={
                                  field.HoleStatus !== null && field.HoleStatus.toLowerCase() === "closed"
                                    ? `list-item closed `
                                    : field.finalStatus.toLowerCase() ==
                                      "very wet"
                                      ? `list-item verywetcolor`
                                      : field.finalStatus.toLowerCase() == "wet"
                                        ? `list-item wetcolor`
                                        : `list-item open `
                                }
                                style={listItemContainerStyle}
                                key={field.HoleID}
                              >
                                <span className="listItemSpanStyle w-100">
                                  <div className="listItemSpanDivStyle">
                                    {field.HoleName}
                                  </div>
                                </span>
                                <label className="listItemLabelStyle">
                                  {field.HoleStatus !== null && field.HoleStatus.toLowerCase() == "open" &&
                                    field.finalStatus !== ""
                                    ? field.finalStatus
                                    : field.HoleStatus !== null && field.HoleStatus}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  {!holeChunks.length ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  ) : null}
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.actionbtn}>
              {actions ? (
                <>
                  {courseData.name == "Practice Green" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.customButton}
                      onClick={() => {
                        props.handleOpen(courseData);
                      }}
                    >
                      More Details
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled
                      className={classes.customButton}
                    >
                      More Details
                    </Button>
                  )}

                  {/*<Button
                    color="primary"
                    startIcon={<DirectionsOutlined />}
                    className={classes.customButton}
                    onClick={openDirections}
                  >
                    Get Directions
                  </Button>*/}
                </>
              ) : (
                ""
              )}
              {allNotify ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.customButton}
                >
                  All Notifications
                </Button>
              ) : (
                ""
              )}
            </CardActions>
          </Card>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={4}>
              <Card elevation={8} className="sameheightcard">
                <CardHeader
                  title={courseData.name}
                  className="text-center"
                ></CardHeader>
                <h6 className="title1 samesize px-3">Status</h6>
                <CardContent className={`golfleftheight ${classes.ht364} pt-1`}>
                  <div>
                    <ul className="status-list frontgolfpadd">
                      {getSortedCourseData?.map((field, idx) => (
                        <li key={field.HoleID + idx}>
                          <span>{field.HoleName}</span>
                          <label
                            className={
                              field.HoleStatus !== null && field.HoleStatus.toLowerCase() === "closed"
                                ? `list-item closed openclosegrid`
                                : field.finalStatus.toLowerCase() == "very wet"
                                  ? `list-item openclosegrid verywetcolor`
                                  : field.finalStatus.toLowerCase() == "wet"
                                    ? `list-item openclosegrid wetcolor`
                                    : `list-item open openclosegrid`
                            }
                            style={{ textTransform: "uppercase" }}
                          >
                            {field.HoleStatus !== null && field.HoleStatus.toLowerCase() == "open" &&
                              field.finalStatus !== ""
                              ? field.finalStatus
                              : field.HoleStatus !== null && field.HoleStatus}
                          </label>
                        </li>
                      ))}

                      {!courseData.hole_data.length ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress />
                        </Box>
                      ) : null}
                    </ul>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Card elevation={8} className={classes.imgCard}>
                <CardContent>
                  <img
                    src={courseData.img_path}
                    alt="map"
                    width="100%"
                    height="455px"
                    className="parkimg"
                  />
                </CardContent>
              </Card>
            </Grid>
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
}
GolfCourseCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  actions: PropTypes.bool,
  allNotify: PropTypes.bool,
  refresh: PropTypes.bool,
};
