import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TempBarChart from "../Charts/TemperatureChart";
import TempChart from "../Charts/CitizenTemperatureChart";
import FieldStatusChart from "../Charts/FieldStatusChart";
import PopularGraph from "../Charts/PopularGraph";
import SingleFieldStatusChart from "../Charts/SingleFieldStatusChart";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Menu from "@material-ui/core/Menu";
import { moistureChart } from "../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import MCPfieldstatus from "./MCPfieldstatus";
import ShilohFields from "./ShilohFields.js";
import CrabtreeFields from "./CnccFields.js";
import Mcpgatestatus from "./GateStatusCSP";
import ParkOpenClose from "./ParkOpenClose";
import Notification from "./Notificationmodal.js";
import { IoMdNotifications } from "react-icons/io";
import { IoNotificationsCircleOutline } from "react-icons/io5";
import { MdCircleNotifications } from "react-icons/md";
import {
  DateRange,
  MenuOpen,
  KeyboardBackspace,
  PhoneIphone,
  Room,
  ExpandMore,
  Remove,
  Build,
  Clear,
} from "@material-ui/icons";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MultiSelect from "react-multi-select-component";
import Modal from "./Modal";
import { dummyService } from "../../axios";
import FloodChart from "../Charts/FloodChart";
import "../../assets/scss/App.scss";
import {
  selectedFieldDetails_,
  selectedFieldCourts_,
  getFirstCourt_,
} from "../../reducers/userDashboard";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

import FieldStatus from "./FieldStatus";
import Courtstatus from "./courtsstatus";
import Mcpcourtstatus from "./mcpcourtstatus";
import ChurchStreetTennisCourt from "./ChurchStreetTennisCourt";
import GateStatus from "./GateStatus";
import GateStatusCSP from "./GateStatusCSP";
import FloodSensorStatus from "./FloodSensorStatus";
import AdminDashboard from "./Dashboard/Dashboard-2-admin";

import "../../assets/scss/App.scss";
import styles from "../../components/components.js";
import smartParkService from "../../axios";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { login_, token_ } from "../../reducers/login";
import { sensors_, sensorsHasLoaded_ } from "../../reducers/sensors";
import { fields_, fieldsHasLoaded_ } from "../../reducers/fields";
import {
  permissions_,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import {
  userDashboard_,
  updateFieldSelections,
  currentFieldStatus_,
  updateCurrentFieldStatus,
} from "../../reducers/userDashboard";

import _ from "lodash";
import FloodChartMCP from "../Charts/FloodChartMcp.js";
import MCPFloodSensorStatus from "./MCPFloodSensor.js";
import Tabs from "../../components/Tabs/Tabs";
import MafcEquipmentRoom from "./Dashboard/MafcEquipmentRoom";
import MafcPoolArea from "./Dashboard/MafcPoolArea.js";
import MafcWorkoutArea from "./Dashboard/MafcWorkoutArea.js";
import MafcLayoutsPage from "./Dashboard/MafcLayoutsPage.js";
import MafcRacquetBall from "./Dashboard/MafcRacquetBall.js";
import MafcRacquetWidget from "./MafcRacquetWidget.js";
import { checkPermissions } from "../../utils/checkPermissions.js";
import CSPFields from "./CSPFields.js";
const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const millisecond_in_a_day = 86400000;

const useStyles = makeStyles(styles);

export default function UserDashboard() {
  const history = useHistory();
  const loginDetails = useSelector(login_);
  const sensors = useSelector(sensors_);
  const fields = useSelector(fields_);
  const fieldsHasLoaded = useSelector(fieldsHasLoaded_);
  const allFields = useSelector(currentFieldStatus_);
  const [allFields_, setAllFields] = React.useState("");
  const sensorHasLoaded = useSelector(sensorsHasLoaded_);
  const fieldsoption = useSelector(selectedFieldCourts_);
  const token = useSelector(token_);
  const _userdashboard = useSelector(userDashboard_);
  const dispatch = useDispatch();
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [loading] = React.useState(true);
  const [color] = React.useState("#ffffff");
  const [state, setState] = React.useState({
    start: moment().subtract(30, "days").unix() * 1000,
    end: moment().unix() * 1000,
  });
  const selectedField = useSelector(selectedFieldDetails_);

  const classes = useStyles();
  const [options, setOptions] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [mcpoptions, setmcpOptions] = React.useState([]);
  const [mcpselected, setmcpSelected] = React.useState([]);
  const mafcTabs = [
    "Tennis and Racquetball",
    "Pool Area",
    "Workout Area",
    "Equipment Room",
    "Layouts",
  ];
  const [selectedTab, setSelectedTab] = useState(0);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const role = _.get(loginDetails, ["Role"], "");
  const parkid = _.get(loginDetails, ["parkID"], "");

  const [fielddisplaypermission, setfielddisplaypermission] = React.useState(
    {}
  );
  const [gatedisplaypermission, setgatedisplaypermission] = React.useState({});
  const [flooddisplaypermission, setflooddisplaypermission] = React.useState(
    {}
  );
  const firstFieldCourt = useSelector(getFirstCourt_);
  const [permission, setpermission] = React.useState({});
  const [selectpark, setselectedpark] = React.useState("cfdp");
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [notifyMobile, setNotifyMobile] = React.useState("");
  const [notifyEmail, setNotifyEmail] = React.useState("");
  const [graphData, setGraphData] = React.useState([]);
  const [wethergraph, setWethergraph] = React.useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [showhide, setshowhide] = React.useState("none");
  const csptempparkid = "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944";
  const cfdptempparkid = "c7a1d85f-8168-4e64-8e38-116a0624bfea";
  const McpparkID = "c7a1d85f-8168-4e64-8e38-116a0624bfeb";
  const updateWethergraph = (data) => {
    setWethergraph(data);
  };

  const NotifyMe = () => {
    const data = {
      EmailAddress: notifyEmail,
      MobilePhone: notifyMobile,
    };
    smartParkService
      .post("api/park/" + parkid + "/notify", data)
      .then((res) => {
        if (res.status === 200) {
          alert("Notify User Saved");
        } else {
          alert(" User Details Not Saved");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const localTimeZoneOffset =
    (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;
  const getData = async () => {
    var graphData = [];
    var fields = [];
    if (selected) {
      var selectedfields = selected;
      for (var i = 0; i < selectedfields.length; i++) {
        fields.push(selectedfields[i].value);
      }
    }
    var range = {};
    var selectedDateRange = { start: start, end: end };
    if (selectedDateRange) {
      var dates = selectedDateRange;
      range.startDate = dates.start;
      range.endDate = dates.end;
    }

    if (moistureChart.useDummyData == false) {
      var diff = range.endDate - range.startDate;
      var view = "hour";
      if (diff <= 86499999) {
        view = "minute";
      }
      let url =
        "api/park/" +
        parkid +
        "/moisturev1/" +
        view +
        "?startDate=" +
        range.startDate +
        "&endDate=" +
        range.endDate +
        "&fields=" +
        fields.join(",");
      await smartParkService
        .get(url)
        .then((res) => {
          if (res) {
            graphData = fieldwiseFilter(selectedfields, res.data.data);
          } else {
            console.log("FloodChart else error: ");
          }
        })
        .catch((error) => {
          console.error("FloodChart error: ", error);
        });
    }

    return graphData;
  };
  // const fieldwiseFilter = (fields, data) => {
  //   var filteredData = [];
  //   if (fields && fields.length > 0 && data) {
  //     for (var i = 0; i < fields.length; i++) {
  //       var graphData = [];
  //       data.filter(function (obj) {
  //         if (obj.sensor == fields[i].value) {
  //           graphData.push({
  //             sensor: fields[i],
  //             field: fields[i].label,
  //             date: obj.date,
  //             moisture: obj.moisture,
  //             status: 10,
  //           });
  //         }
  //       });

  //       filteredData.push(graphData);
  //     }
  //   }
  //   return filteredData;
  // };

  useEffect(() => {
    if (fieldsoption.length && firstFieldCourt && !selected.length)
      setSelected([firstFieldCourt]);
  }, [firstFieldCourt, selected, fieldsoption]);

  useEffect(() => {
    const { fieldDate } = _userdashboard?.data;
    if (fieldDate) {
      let { start, end } = fieldDate;
      const _rangeType = _userdashboard?.data?.fieldDate?.rangeType;
      const rangeDate = getDateBasedOnRange(_rangeType);
      if (_rangeType != "Custom Range" && rangeDate.length) {
        try {
          const startDate = rangeDate[0];
          const endDate = rangeDate[1];
          setState({ start: startDate, end: endDate });
        } catch (err) {
          setState({ start: start, end: end });
        }
      } else {
        setState({ start: start, end: end });
      }
    }
  }, [_userdashboard]);

  const getDateBasedOnRange = (rangeType) => {
    switch (rangeType) {
      case "Last 24 Hours":
        return [
          moment().subtract(1, "days").unix() * 1000,
          moment().unix() * 1000,
        ];
      case "Last Week":
        return [
          moment().subtract(6, "days").unix() * 1000,
          moment().unix() * 1000,
        ];
      case "Last Month":
        return [
          moment().subtract(1, "month").startOf("month").unix() * 1000,
          moment().subtract(1, "month").endOf("month").unix() * 1000,
        ];
      case "Last 3 Month":
        return [
          moment().subtract(3, "month").startOf("month").unix() * 1000,
          moment().subtract(1, "month").endOf("month").unix() * 1000,
        ];
      case "Last 6 Month":
        return [
          moment().subtract(6, "month").startOf("month").unix() * 1000,
          moment().subtract(1, "month").endOf("month").unix() * 1000,
        ];
      case "Last Year":
        return [
          moment().subtract(12, "month").startOf("month").unix() * 1000,
          moment().subtract(1, "month").endOf("month").unix() * 1000,
        ];
      default:
        return [];
    }
  };
  const { start, end } = state;
  const handleCallback = (start, end, rangeType) => {
    let startUnixtime = start._d.getTime();
    let endUnixtime = end._d.getTime();
    start._d.getTime();
    if (endUnixtime - startUnixtime <= millisecond_in_a_day) {
      let endDate = moment().unix() * 1000;
      endUnixtime = endDate;
      startUnixtime = endUnixtime - millisecond_in_a_day;
    }
    dispatch(
      updateFieldSelections({
        fieldDate: {
          rangeType: rangeType,
          start: startUnixtime,
          end: endUnixtime,
        },
      })
    );
    //dispatch(updateFieldSelections({ fieldDate: { start, end } }));
    setState({ start, end });
    // getGraphData();
  };

  const [fieldStatus, setFieldStatus] = React.useState({});
  const updateFieldStatus = (fieldStatusData) => {
    setFieldStatus(JSON.stringify(fieldStatusData));
  };
  const label =
    moment(start).utc().format("MMMM D, YYYY") +
    " - " +
    moment(end).utc().format("MMMM D, YYYY");
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const mobileMenuId = "Charts-Info-Button";

  //Notify Me Popover
  const [PopoveranchorEl, setPopoverAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPopoverAnchorEl(null);
  };

  const open = Boolean(PopoveranchorEl);
  const id = open ? "simple-popover" : undefined;

  const [showFieldStatus] = React.useState(false);

  const Jsondownload = async () => {
    const data = await getData();
    var fileDownload = require("js-file-download");
    fileDownload(JSON.stringify(data), "Data.json");
  };
  const [] = React.useState({
    LastUpdateDate: new Date().toLocaleDateString(),
  });
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
      getFieldpermissions();
      getgatepermissions();
      getFloodpermissions();
    }
  }, [permissionsHasLoaded]);

  useEffect(() => {
    const email = _.get(loginDetails, ["EmailAddress"], "");
    if (!email) {
      history.push("/");
    }
    //getParkFields();
  }, []);

  useEffect(() => {
    // console.log(fieldsHasLoaded);
    if (fieldsHasLoaded) {
      var options = [];
      // console.log(fields);
      fields.forEach(function (field) {
        options.push({
          label: field.FieldName,
          value: field.id,
        });
      });
      setOptions(options);
      const { fieldSelections } = _userdashboard?.data;
      if (fieldSelections.length) {
        setSelected(fieldSelections);
      } else {
        setSelected([options[0]]);
      }
      const isOpen = sensors.every((field) => field.sensorstatus == "Open");
      const isClosed = sensors.every((field) => field.sensorstatus == "Closed");
      if (isOpen == false && isClosed == false) {
        setAllFields("");
      } else if (isOpen == true) {
        setAllFields("open");
      } else {
        setAllFields("closed");
      }
      //getGraphData();
    }
  }, [fieldsHasLoaded]);

  useEffect(() => {
    if (sensorHasLoaded && showhide === "block") {
      getGraphData();
    }
  }, [showhide, start, end]);

  useEffect(() => {
    if (selected.length) {
      dispatch(updateFieldSelections({ fieldSelections: selected }));
    }
  }, [selected]);

  function getGraphData() {
    // var fields = sensors.map((s) => {
    //   return s.SensorCollectionID;
    // });

    var individualFields = fields.map((s) => {
      return s.FieldID;
    });

    var startDate = start;
    var endDate = end;

    var diff = endDate - startDate;
    var view = "hour";
    if (diff <= 86499999) {
      view = "minute";
    }
    let url =
      "api/park/" +
      parkid +
      "/moisturev1/" +
      view +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&fields=" +
      individualFields.join(",");
    //setLoading(true);
    // setTimeout(()=>{
    smartParkService
      .get(url)
      .then((res) => {
        if (res) {
          var graphData = fieldwiseFilter(fields, res.data.data);
          setGraphData(graphData);
        } else {
          console.log("FloodChart else error: ");
        }
        //setLoading(false);
      })
      .catch((error) => {
        console.error("FloodChart error: ", error);
        //setLoading(false);
      });
  }
  useEffect(() => {
    if (window.location.href.indexOf("/overview") > -1) {
      setselectedpark("all");
      setshowhide("none");
    } else if (window.location.href.indexOf("/MAFC") > -1) {
      setselectedpark("mafctenniscourts");
      setshowhide("none");
    } else if (window.location.href.indexOf("/ChurchStreetPark") > -1) {
      setselectedpark("churchstreettenniscourts");
      setshowhide("none");
    } else if (window.location.href.indexOf("/MorrisvilleCommunityPark") > -1) {
      setselectedpark("mcppark");
      setshowhide("none");
    } else if (window.location.href.indexOf("/shiloh") > -1) {
      setselectedpark("shiloh");
      setshowhide("none");
    } else if (window.location.href.indexOf("/ccnp") > -1) {
      setselectedpark("ccnp");
      setshowhide("none");
    } else {
      setselectedpark("cfdp");
      // setshowhide("none");
    }
  });

  const fieldwiseFilter = (fields, data) => {
    var filteredData = [];
    if (fields && fields.length > 0 && data) {
      for (var i = 0; i < fields.length; i++) {
        var graphData = [];
        var fieldname = sensors.find((f) => {
          return f.fieldID === fields[i].FieldID;
        }).sensorname;
        var sensorCollectionID = sensors.find((f) => {
          return f.fieldID === fields[i].FieldID;
        }).SensorCollectionID;
        data.filter(function (obj) {
          if (obj.sensor == sensorCollectionID) {
            graphData.push({
              sensor: sensorCollectionID,
              fieldID: fields[i].FieldID,
              field: fieldname,
              date: obj.date,
              moisture: obj.moisture,
              status: 10,
            });
          }
        });
        filteredData.push({ field: fieldname, graphData });
      }
    }
    return filteredData;
  };

  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "UserDashboard";
    });
    setpermission(Dpermissions[0]);
  };

  const getFieldpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "FieldDisplayStatus";
    });

    setfielddisplaypermission(Dpermissions[0]);
  };
  const getFloodpermissions = async () => {
    // console.log(permissionsobj);
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "Floodsensor1";
    });

    setflooddisplaypermission(Dpermissions[0]);
  };
  const getgatepermissions = async () => {
    // console.log(permissionsobj);
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "GateStatus";
    });

    setgatedisplaypermission(Dpermissions[0]);
  };

  useEffect(() => {
    getParkFields();
  }, []);

  const getParkFields = () => {
    smartParkService
      //.get("api/park/" + parkid + "/sensordata")
      .get("api/citizen/fielddata2")
      .then((res) => {
        if (res.status === 200) {
          var fields = res.data.data;
          var options = [];
          fields.forEach(function (field) {
            options.push({
              //label: field.sensorname,
              //value: field.SensorCollectionID,
              label: field.FieldName,
              value: field.id,
            });
          });
          //options.push({ label: "All Fields", value: "ALL" });
          setmcpOptions(options);
          setmcpSelected([options[0]]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      selectpark === "mafctenniscourts" ||
      selectpark === "churchstreettenniscourts"
    ) {
      dispatch(updateCurrentFieldStatus(null));
    }
  }, [selectpark]);

  const funcAllnotification = () => {
    let path = "allNotifications";
    history.push({
      pathname: `${path}`,
    });
  };

  return (
    <div className={classes.contentPage}>
      {checkPermissions({
        permission: "UserDashboard",
        permissionType: "read",
      }) ? (
        <div>
          {window.location.href.indexOf("/overview") > -1 ? (
            <section
              class="content-header notifyheaderbtn"
              style={{ minheight: "30px", height: "auto" }}
            >
              {checkPermissions({
                permission: "Announcement",
                permissionType: "create",
              }) && (
                  <Button
                    className="btn notificationpark"
                    onClick={() => setModalShow(true)}
                  >
                    <IoNotificationsCircleOutline /> Park Notification
                  </Button>
                )}
              {checkPermissions({
                permission: "Announcement",
                permissionType: "read",
              }) && (
                  <Button
                    className="btn seenotificationpark"
                    onClick={funcAllnotification}
                  >
                    <MdCircleNotifications /> View All Notifications
                  </Button>
                )}
              <Notification
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </section>
          ) : (
            <section
              class="content-header"
              style={{ minheight: "80px", height: "auto" }}
            >
              <h3>
                Current Status:
                {<span class="sucessColor">{allFields ?? "NA"}</span>}
              </h3>
              {window.location.href.indexOf("/overview") > -1 ? (
                <div></div>
              ) : (
                <div>
                  <Modal show={showFieldStatus} fieldStatus={fieldStatus} />
                  <div className={classes.buttonGroup}>
                    <ButtonGroup size="small" color="primary" aria-label="">
                      <Button>
                        <DateRangePicker
                          initialSettings={{
                            startDate: new Date(start),
                            endDate: new Date(end),
                            maxDate: new Date(),
                            ranges: {
                              "Last 24 Hours":
                                getDateBasedOnRange("Last 24 Hours"),
                              "Last Week": getDateBasedOnRange("Last Week"),
                              "Last Month": getDateBasedOnRange("Last Month"),
                              "Last 3 Month":
                                getDateBasedOnRange("Last 3 Month"),
                              "Last 6 Month":
                                getDateBasedOnRange("Last 6 Month"),
                              "Last Year": getDateBasedOnRange("Last Year"),
                            },
                          }}
                          onCallback={handleCallback}
                        >
                          <div id="reportrange">
                            <DateRange />
                            <span>{label}</span> <ExpandMore />
                          </div>
                        </DateRangePicker>
                      </Button>
                    </ButtonGroup>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={PopoveranchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div id="NotifyBlock">
                        <form
                          noValidate
                          autoComplete="off"
                          className={classes.notifyBlock}
                        >
                          <TextField
                            id="notifyNumber"
                            label="Your Number"
                            variant="outlined"
                            size="small"
                            color="primary"
                            value={notifyMobile}
                            onChange={(e) => setNotifyMobile(e.target.value)}
                          />{" "}
                          <br />
                          <br />
                          <TextField
                            id="notifyEmail"
                            label="Your Email"
                            variant="outlined"
                            size="small"
                            color="primary"
                            value={notifyEmail}
                            onChange={(e) => setNotifyEmail(e.target.value)}
                          />
                          <br />
                          <br />
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            className={classes.btnRound}
                            onClick={NotifyMe}
                          >
                            Notify
                          </Button>
                        </form>
                      </div>
                    </Popover>
                  </div>
                </div>
              )}
            </section>
          )}
          {selectpark === "all" ? (
            <AdminDashboard />
          ) : selectpark === "cfdp" ? (
            <section class="content">
              <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                  {/* Field Status and Moisture Chart Data */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Paper elevation={3}>
                        <div class="box">
                          <div class="box-header with-border">
                            <h6 class="box-title">
                              Field Data – Historical &nbsp;&nbsp;
                            </h6>
                            <div
                              style={{
                                minwidth: "150px",
                                maxWidth: "350px",
                                width: "100%",
                                display: "inline-block",
                              }}
                            >
                              <MultiSelect
                                className={classes.Hgt30}
                                options={options}
                                value={selected}
                                onChange={setSelected}
                              />
                            </div>
                            &nbsp;&nbsp;
                            <div style={{ display: "inline-block" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    onChange={(e) => {
                                      e.target.checked == true
                                        ? setshowhide("block")
                                        : setshowhide("none");
                                    }}
                                  />
                                }
                                label="Show Individual Field Moisture Graphs"
                              />
                            </div>
                            <div
                              class="box-tools pull-right"
                              style={{ display: "none" }}
                            >
                              <IconButton
                                aria-label="Minimize"
                                size="small"
                                className={classes.margin2}
                              >
                                <Remove fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                aria-label="Settings"
                                size="small"
                                className={classes.margin2}
                              >
                                <Build fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                aria-label="Clear"
                                size="small"
                                className={classes.margin2}
                              >
                                <Clear fontSize="inherit" />
                              </IconButton>
                            </div>
                          </div>
                          <div class="box-body">
                            {selected.length > 0 ? (
                              <FieldStatusChart
                                selectedFileds={selected}
                                selectedDateRange={{ start: start, end: end }}
                                selectionChanged={new Date().getTime()}
                                updateFieldStatus={updateFieldStatus}
                              />
                            ) : (
                              <div>Loading..</div>
                            )}
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                    {graphData &&
                      graphData.length > 0 &&
                      graphData.map((g) => {
                        if (g.graphData) {
                          return (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ display: showhide }}
                            >
                              <Paper elevation={3}>
                                <div class="box">
                                  <div class="box-header with-border">
                                    <h6 class="box-title">
                                      Moisture Level :{g.field}
                                    </h6>
                                    <div class="box-tools pull-right">
                                      <IconButton
                                        aria-label="Minimize"
                                        size="small"
                                        className={classes.margin2}
                                      >
                                        <Remove fontSize="inherit" />
                                      </IconButton>
                                      <IconButton
                                        aria-label="Settings"
                                        size="small"
                                        className={classes.margin2}
                                      >
                                        <Build fontSize="inherit" />
                                      </IconButton>
                                      <IconButton
                                        aria-label="Clear"
                                        size="small"
                                        className={classes.margin2}
                                      >
                                        <Clear fontSize="inherit" />
                                      </IconButton>
                                    </div>
                                  </div>
                                  <div class="box-body">
                                    <SingleFieldStatusChart
                                      selectedDateRange={{
                                        start: start,
                                        end: end,
                                      }}
                                      graphData={g}
                                    />
                                  </div>
                                </div>
                              </Paper>
                            </Grid>
                          );
                        }
                      })}
                    <Grid item xs={12} md={12}>
                      <Paper elevation={3}>
                        <div class="box">
                          <div class="box-header with-border">
                            <h6 class="box-title">
                              Average Temperature and Precipitation
                            </h6>
                            <div
                              class="box-tools pull-right"
                              style={{ display: "none" }}
                            >
                              <IconButton
                                aria-label="Minimize"
                                size="small"
                                className={classes.margin2}
                              >
                                <Remove fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                aria-label="Settings"
                                size="small"
                                className={classes.margin2}
                              >
                                <Build fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                aria-label="Clear"
                                size="small"
                                className={classes.margin2}
                              >
                                <Clear fontSize="inherit" />
                              </IconButton>
                            </div>
                          </div>
                          <div class="box-body">
                            <TempChart
                              selectedDateRange={{ start: start, end: end }}
                              selectionChanged={new Date().getTime()}
                              parkid={cfdptempparkid}
                              updateWethergraph={updateWethergraph}
                            />
                          </div>
                        </div>
                      </Paper>
                      <Paper elevation={3}>
                        <div class="box">
                          <div class="box-header with-border">
                            <h6 class="box-title">
                              Crabtree Creek Flood Data – Historical
                            </h6>
                            <div
                              class="box-tools pull-right"
                              style={{ display: "none" }}
                            >
                              <IconButton
                                aria-label="Minimize"
                                size="small"
                                className={classes.margin2}
                              >
                                <Remove fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                aria-label="Settings"
                                size="small"
                                className={classes.margin2}
                              >
                                <Build fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                aria-label="Clear"
                                size="small"
                                className={classes.margin2}
                              >
                                <Clear fontSize="inherit" />
                              </IconButton>
                            </div>
                          </div>
                          <div class="box-body">
                            <FloodChart
                              selectedDateRange={{ start: start, end: end }}
                            // selectionChanged={new Date().getTime()}
                            />
                          </div>
                        </div>
                      </Paper>

                      <br />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  {checkPermissions({
                    permission: "Fields",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <FieldStatus />
                      </Paper>
                    )}
                  {checkPermissions({
                    permission: "GateStatus",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <GateStatus
                          parkID={"c7a1d85f-8168-4e64-8e38-116a0624bfea"}
                        />
                      </Paper>
                    )}
                  {checkPermissions({
                    permission: "ParkStatus",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <ParkOpenClose
                          parkID={"c7a1d85f-8168-4e64-8e38-116a0624bfea"}
                        />
                      </Paper>
                    )}
                  {checkPermissions({
                    permission: "Floodsensor1",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <FloodSensorStatus
                          parkid={"c7a1d85f-8168-4e64-8e38-116a0624bfea"}
                        />
                      </Paper>
                    )}
                </Grid>
              </Grid>
              {/* Average Temperature and Precipitation */}
              {/* <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      md={flooddisplaypermission.read == 1 ? 9 : 12}
                    >
                      <Paper elevation={3}>
                        <div class="box">
                          <div class="box-header with-border">
                            <h6 class="box-title">
                              Average Temperature and Precipitation
                            </h6>
                            <div
                              class="box-tools pull-right"
                              style={{ display: "none" }}
                            >
                              <IconButton
                                aria-label="Minimize"
                                size="small"
                                className={classes.margin2}
                              >
                                <Remove fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                aria-label="Settings"
                                size="small"
                                className={classes.margin2}
                              >
                                <Build fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                aria-label="Clear"
                                size="small"
                                className={classes.margin2}
                              >
                                <Clear fontSize="inherit" />
                              </IconButton>
                            </div>
                          </div>
                          <div class="box-body">
                            <TempBarChart
                              selectedDateRange={{ start: start, end: end }}
                              selectionChanged={new Date().getTime()}
                              parkid={cfdptempparkid}
                              updateWethergraph={updateWethergraph}
                            />
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid> */}
              {/* </Grid> */}
            </section>
          ) : selectpark == "mafctenniscourts" ? (
            <section class="content">
              <div
                style={{
                  display:
                    window.location.search === "?future=1" ? "block" : "none",
                }}
              >
                <Tabs
                  tabsList={mafcTabs}
                  setActiveTab={(e) => {
                    setSelectedTab(e);
                  }}
                />
              </div>
              {selectedTab === 0 && (
                <Grid container spacing={3}>
                  {checkPermissions({
                    permission: "Fields",
                    permissionType: "read",
                  }) && (
                      <Grid item xs={12} md={9}>
                        <Paper elevation={3}>
                          <Courtstatus />
                        </Paper>
                        <Paper elevation={3}>
                          <MafcRacquetWidget />
                        </Paper>
                      </Grid>
                    )}
                  {checkPermissions({
                    permission: "ParkStatus",
                    permissionType: "read",
                  }) && (
                      <Grid item xs={12} md={3}>
                        <Paper elevation={3}>
                          <ParkOpenClose
                            parkID={"91474e36-76c5-4d59-a8da-2998ab14a4bf"}
                          />
                        </Paper>
                      </Grid>
                    )}
                </Grid>
              )}
              {selectedTab === 1 && <MafcPoolArea />}
              {selectedTab === 2 && <MafcWorkoutArea />}
              {selectedTab === 3 && (
                <MafcEquipmentRoom
                  defaultSize="800px"
                  lgScreen="600px"
                  xlscreen={"680px"}
                  xxlScreen={"1000px"}
                />
              )}
              {selectedTab === 4 && <MafcLayoutsPage />}
            </section>
          ) : selectpark == "churchstreettenniscourts" ? (
            <section class="content">
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Paper elevation={3}>
                    {checkPermissions({
                      permission: "Fields",
                      permissionType: "read",
                    }) && <ChurchStreetTennisCourt />}
                  </Paper>
                  <Paper elevation={3}>
                    <div class="box">
                      <div class="box-header with-border">
                        <h6 class="box-title">
                          Average Temperature and Precipitation
                        </h6>
                        <div
                          class="box-tools pull-right"
                          style={{ display: "none" }}
                        >
                          <IconButton
                            aria-label="Minimize"
                            size="small"
                            className={classes.margin2}
                          >
                            <Remove fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Settings"
                            size="small"
                            className={classes.margin2}
                          >
                            <Build fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Clear"
                            size="small"
                            className={classes.margin2}
                          >
                            <Clear fontSize="inherit" />
                          </IconButton>
                        </div>
                      </div>
                      <div class="box-body">
                        <TempChart
                          selectedDateRange={{ start: start, end: end }}
                          selectionChanged={new Date().getTime()}
                          parkid={csptempparkid}
                          updateWethergraph={updateWethergraph}
                        />
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  {checkPermissions({
                    permission: "GateStatus",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <GateStatusCSP
                          parkID={"ba0a6f3f-e265-43d1-aaf8-ff21d43d3944"}
                        />
                      </Paper>
                    )}
                  {checkPermissions({
                    permission: "ParkStatus",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <ParkOpenClose
                          parkID={"ba0a6f3f-e265-43d1-aaf8-ff21d43d3944"}
                        />
                      </Paper>
                    )}
                  {checkPermissions({
                    permission: "Fields",
                    permissionType: "read",
                  }) ? (
                    <Paper elevation={3}>
                      <CSPFields />
                    </Paper>
                  ) : null}
                </Grid>
              </Grid>
            </section>
          ) : selectpark == "mcppark" ? (
            <section class="content">
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  md={
                    checkPermissions({
                      permission: "ParkStatus",
                      permissionType: "read",
                    })
                      ? 8
                      : 12
                  }
                >
                  <Paper elevation={3}>
                    <div class="box">
                      <div class="box-header with-border">
                        <h6 class="box-title">
                          Field Data – Historical &nbsp;&nbsp;
                        </h6>
                        <div
                          style={{
                            minwidth: "150px",
                            maxWidth: "350px",
                            width: "100%",
                            display: "inline-block",
                          }}
                        >
                          <MultiSelect
                            className={classes.Hgt30}
                            options={mcpoptions}
                            value={mcpselected}
                            onChange={setmcpSelected}
                          />
                        </div>
                        &nbsp;&nbsp;
                        {/* <div style={{ display: "inline-block" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    onChange={(e) => {
                                      e.target.checked == true
                                        ? setshowhide("block")
                                        : setshowhide("none");
                                    }}
                                  />
                                }
                                label="Show Individual Field Moisture Graphs"
                              />
                            </div> */}
                        <div
                          class="box-tools pull-right"
                          style={{ display: "none" }}
                        >
                          <IconButton
                            aria-label="Minimize"
                            size="small"
                            className={classes.margin2}
                          >
                            <Remove fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Settings"
                            size="small"
                            className={classes.margin2}
                          >
                            <Build fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Clear"
                            size="small"
                            className={classes.margin2}
                          >
                            <Clear fontSize="inherit" />
                          </IconButton>
                        </div>
                      </div>
                      <div class="box-body">
                        {mcpselected.length > 0 ? (
                          <FieldStatusChart
                            selectedFileds={mcpselected}
                            selectedDateRange={{ start: start, end: end }}
                            selectionChanged={new Date().getTime()}
                            updateFieldStatus={updateFieldStatus}
                          />
                        ) : (
                          <div>Loading..</div>
                        )}
                      </div>
                    </div>
                  </Paper>
                  <Paper elevation={3}>
                    <div class="box">
                      <div class="box-header with-border">
                        <h6 class="box-title">
                          Average Temperature and Precipitation
                        </h6>
                        <div
                          class="box-tools pull-right"
                          style={{ display: "none" }}
                        >
                          <IconButton
                            aria-label="Minimize"
                            size="small"
                            className={classes.margin2}
                          >
                            <Remove fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Settings"
                            size="small"
                            className={classes.margin2}
                          >
                            <Build fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Clear"
                            size="small"
                            className={classes.margin2}
                          >
                            <Clear fontSize="inherit" />
                          </IconButton>
                        </div>
                      </div>
                      <div class="box-body">
                        <TempChart
                          selectedDateRange={{ start: start, end: end }}
                          selectionChanged={new Date().getTime()}
                          parkid={McpparkID}
                          updateWethergraph={updateWethergraph}
                        />
                      </div>
                    </div>
                  </Paper>
                  <Paper elevation={3}>
                    <div class="box">
                      <div class="box-header with-border">
                        <h6 class="box-title">
                          Morrisville Community Park Flood Data – Historical
                        </h6>
                        <div
                          class="box-tools pull-right"
                          style={{ display: "none" }}
                        >
                          <IconButton
                            aria-label="Minimize"
                            size="small"
                            className={classes.margin2}
                          >
                            <Remove fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Settings"
                            size="small"
                            className={classes.margin2}
                          >
                            <Build fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Clear"
                            size="small"
                            className={classes.margin2}
                          >
                            <Clear fontSize="inherit" />
                          </IconButton>
                        </div>
                      </div>
                      <div class="box-body">
                        <FloodChartMCP start={start} end={end} />
                      </div>
                    </div>
                  </Paper>
                </Grid>
                {graphData &&
                  graphData.length > 0 &&
                  graphData.map((g) => {
                    if (g.graphData) {
                      return (
                        <Grid item xs={12} md={6} style={{ display: showhide }}>
                          <Paper elevation={3}>
                            <div class="box">
                              <div class="box-header with-border">
                                <h6 class="box-title">
                                  Moisture Level :{g.field}
                                </h6>
                                <div class="box-tools pull-right">
                                  <IconButton
                                    aria-label="Minimize"
                                    size="small"
                                    className={classes.margin2}
                                  >
                                    <Remove fontSize="inherit" />
                                  </IconButton>
                                  <IconButton
                                    aria-label="Settings"
                                    size="small"
                                    className={classes.margin2}
                                  >
                                    <Build fontSize="inherit" />
                                  </IconButton>
                                  <IconButton
                                    aria-label="Clear"
                                    size="small"
                                    className={classes.margin2}
                                  >
                                    <Clear fontSize="inherit" />
                                  </IconButton>
                                </div>
                              </div>
                              <div class="box-body">
                                <SingleFieldStatusChart
                                  selectedDateRange={{
                                    start: start,
                                    end: end,
                                  }}
                                  graphData={g}
                                />
                              </div>
                            </div>
                          </Paper>
                        </Grid>
                      );
                    }
                  })}
                <Grid item xs={12} md={4}>
                  {checkPermissions({
                    permission: "GateStatus",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <Mcpgatestatus
                          parkID={"c7a1d85f-8168-4e64-8e38-116a0624bfeb"}
                        />
                      </Paper>
                    )}
                  {checkPermissions({
                    permission: "Fields",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <MCPfieldstatus />
                      </Paper>
                    )}
                  {checkPermissions({
                    permission: "ParkStatus",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <ParkOpenClose
                          parkID={"c7a1d85f-8168-4e64-8e38-116a0624bfeb"}
                        />
                      </Paper>
                    )}
                  {checkPermissions({
                    permission: "Fields",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <Mcpcourtstatus />
                      </Paper>
                    )}
                  {checkPermissions({
                    permission: "Floodsensor1",
                    permissionType: "read",
                  }) && (
                      <Paper elevation={3}>
                        <MCPFloodSensorStatus />
                      </Paper>
                    )}
                </Grid>
              </Grid>
            </section>
          ) : selectpark === "ccnp" ? (
            <section class="content">
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Paper elevation={3}>
                    {checkPermissions({
                      permission: "ParkStatus",
                      permissionType: "read",
                    }) && (
                        <ParkOpenClose
                          parkID={"c7a1d85f-8168-4e64-8e38-116a0624bfed"}
                        />
                      )}
                  </Paper>
                  <Paper elevation={3}>
                    {checkPermissions({
                      permission: "Fields",
                      permissionType: "read",
                    }) && (
                        <CrabtreeFields fieldsUrl={"api/citizen/fielddata5"} />
                      )}
                  </Paper>
                </Grid>
              </Grid>
            </section>
          ) : selectpark === "shiloh" ? (
            <section class="content">
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Paper elevation={3}>
                    {checkPermissions({
                      permission: "ParkStatus",
                      permissionType: "read",
                    }) && (
                        <ParkOpenClose
                          parkID={"c7a1d85f-8168-4e64-8e38-116a0624bfec"}
                        />
                      )}
                  </Paper>
                  <Paper elevation={3}>
                    {checkPermissions({
                      permission: "Fields",
                      permissionType: "read",
                    }) && <ShilohFields fieldsUrl={"api/citizen/fielddata4"} />}
                  </Paper>
                </Grid>
              </Grid>
            </section>
          ) : (
            <>
              <section class="content"></section>
            </>
          )}
          {/* {renderMobileMenu} */}
        </div>
      ) : { loading } ? (
        <ClipLoader color={color} loading={loading} css={override} size={60} />
      ) : (
        <div
          style={{
            color: "red",
            position: "absolute",
            top: "50%",
            left: "40%",
            fontSize: "20px",
            transform: "translate(" - (50 % ", ") - (50 % ")"),
          }}
        >
          "You do not have read permissions. Please contact Admin"
        </div>
      )}
    </div>
  );
}
