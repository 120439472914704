import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import smartParkService from "../../axios";
import { login_, token_ } from "../../reducers/login";
import _ from "lodash";
import "../../assets/scss/App.scss";

export default function FloodSensorStatus({ parkid }) {
  const loginDetails = useSelector(login_);
  const token = useSelector(token_);
  const start = moment().tz("America/New_York").subtract(30, "day").unix();
  const end = moment().tz("America/New_York").unix();
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [FloodSensorValues, setFloodSensorValues] = React.useState({});

  const getGreenStreamDetails = () => {
    let url = `/api/park/${parkid}/flood/region?startDate=${start * 1000
      }&endDate=${end * 1000}`;
    smartParkService
      .get(url)
      .then((res) => {
        if (res?.status === 200) {
          const data = res?.data?.data[res.data.data.length - 1];
          setFloodSensorValues({
            level: data.level,
            voltage: data.battery_voltage,
          });
        }
      })
      .catch((error) => { });
  };

  React.useEffect(() => {
    getGreenStreamDetails();
  }, []);

  return (
    <div>
      {Object.entries(FloodSensorValues).length > 0 && (
        <div class="box">
          <div class="box-header with-border">
            <h6>Flood Sensor 1</h6>
          </div>
          <div class="box-body">
            <div class="divGrid">
              <div class="divGridBody">
                <div class="divGridRow">
                  <div class="divGridCell">Water Level</div>
                  <div class="divGridCell">{FloodSensorValues.level} ft.</div>
                </div>
                <div class="divGridRow">
                  <div class="divGridCell">Battery Voltage</div>
                  <div class="divGridCell">{FloodSensorValues.voltage}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
