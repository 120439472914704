import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import smartParkService from "../../axios";
import { login_, token_ } from "../../reducers/login";
import _ from "lodash";
import { GenerateEncryptedToken } from "../../views/Common";
const localTimeZoneOffset =
  (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;

function TemperatureChart(props) {
  // console.log(props);
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();

  const [precipitationdata, setprecipitationdata] = useState([]);
  const [temperaturedata, settemperaturedata] = useState([]);
  const [selectedDateRange, setselectedDateRange] = useState({});

  useEffect(() => {
    getData();
  }, [props.selectedDateRange.start, props.selectedDateRange.end]);

  const getData = async () => {
    const data = [];
    let startDate = new Date("01/01/2020").toISOString();
    let endDate = new Date().toISOString();

    if (props.selectedDateRange) {
      var dates = props.selectedDateRange;
      startDate = dates.start;
      endDate = dates.end;
    }

    await smartParkService
      .get(
        "api/citizen/getweather4?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&parkid=" +
        props.parkid +
        "&dataType=Temperature"
      )
      .then((res) => {
        if (res) {
          data.push(res.data.data);

          let temperatureArray = res.data.data.map((data) => {
            return [Number(data.UnixDateEpoch), data.value];
          });

          settemperaturedata(temperatureArray);
        } else {
        }
      });

    await smartParkService
      .get(
        "api/citizen/getweather4?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&parkid=" +
        props.parkid +
        "&dataType=HourlyRain"
      )
      .then((res) => {
        if (res) {
          data.push(res.data.data);
          let precipitationArray = res.data.data.map((data) => {
            return [Number(data.UnixDateEpoch), data.value];
          });
          setprecipitationdata(precipitationArray);
        }
        weatherdatafn(data, props);
      });
  };

  return (
    <div className="App">
      {
        <HighchartsReact
          highcharts={HighStock}
          constructorType={"stockChart"}
          options={getConfig(precipitationdata, temperaturedata)}
        />
      }
    </div>
  );
}

var wethergraph = [];
function weatherdatafn(wetherdata, props) {
  wethergraph = wetherdata;
  props.updateWethergraph(wetherdata); // Call the updateWethergraph function
}

const getConfig = (precipitation, temperature) => ({
  credits: {
    enabled: false,
  },
  time: {
    useUTC: false,
  },
  rangeSelector: {
    selected: 1,
    enabled: false, // To hide the zoom 1m 3m and Input fields
  },
  navigator: {
    enabled: true,
  },
  chart: {
    zoomType: "xy",
  },
  yAxis: [
    {
      // Pri3y yAxis
      labels: {
        format: "{value}",
      },
      title: {
        text: "Temperature(F)",
      },
    },
    {
      // Secondary yAxis
      title: {
        text: "Precipitation in inches",
      },
      labels: {
        format: "{value}",
      },
      opposite: false,
    },
  ],
  tooltip: {
    split: true,
    pointFormatter: function () {
      var point = this;
      return (
        '<b><span style="color:' +
        point.color +
        '">\u25CF</span> ' +
        point.y.toFixed(2) +
        (point.series.name.indexOf("Temperature") > -1 ? " °F" : " in") +
        "</b><br/>"
      );
    },
  },
  legend: {
    layout: "vertical",
    align: "left",
    x: 120,
    verticalAlign: "top",
    y: 100,
    floating: true,
    backgroundColor: "red",
  },
  series: [
    {
      name: "Precipitation",
      type: "line",
      yAxis: 1,
      data: precipitation,
      dataGrouping: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: " in",
      },
    },
    {
      name: "Temperature",
      type: "spline",
      data: temperature,
      dataGrouping: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: " °F",
      },
    },
  ],
});

export default TemperatureChart;
