import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { Link, setRef } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "./GateModelCSP";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../components/components.js";
import smartParkService from "../../axios";
import IconButton from "@material-ui/core/IconButton";
import { Clear, OpenInNewRounded } from "@material-ui/icons";
import { login_, token_ } from "../../reducers/login";
import _ from "lodash";
import clsx from "clsx";
import { Refresh } from "@material-ui/icons";
import ClipLoader from "react-spinners/ClipLoader";
// import momenttz from "moment-timezone";
// import moment from "moment-timezone";

import moment from "moment";
import "moment-timezone";
import "../../assets/scss/App.scss";
import {
  permissions_,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import MCPGatemodal from "./GateModelMCP";
import ToggleModal from "../../components/Modals/ToggleModal";
import { checkPermissions } from "../../utils/checkPermissions.js";
const ButtonLink = withStyles((theme) => ({
  root: {
    display: "inline !important",
    color: "blue !important",
    marginRight: "10px !important",
    padding: "0 !important",
    textDecoration: "underline",
    border: "none !important",
    fontSize: "13px !important",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
  },
}))(Link);
const useStyles = makeStyles(styles);
const useStylesone = makeStyles((theme) => ({
  refresh: {
    cursor: "pointer",
    margin: "auto",
    "&.spin": {
      animation: "$spin 1s 1",
      pointerEvents: "none",
    },
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

export default function GateStatusCSP(props) {
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const { parkID } = props;
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [devicestatus, setdevicestatus] = React.useState({});
  const [status, setstatus] = React.useState([]);
  const [permission, setpermission] = React.useState({});
  const [open, setopen] = React.useState(false);
  const [mcpopen, setmcpopen] = React.useState(false);
  const [Openorclose, setOpenorclose] = React.useState("");
  const [devicename, setdevicename] = React.useState([]);
  const [devicevar, setdevicevar] = React.useState([]);
  const [devicefeedback, setdevicefeedback] = React.useState([]);
  const [Iselectall, setIselectall] = React.useState(false);
  const [btnopenactive, setopenbtnactive] = React.useState(false);
  const [btncloseactive, setclosebtnactive] = React.useState(false);
  const [allChecked, setallChecked] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [reload, setreload] = React.useState(false);
  const [loadingone, setLoadingone] = React.useState(false);
  const [getdatarefresh, setGetDataRefresh] = React.useState(null);
  const [getdatawithoutrefresh, setGetDataWithoutRefresh] =
    React.useState(null);
  const [spin, setSpin] = React.useState(false);
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);
  const classes = useStyles();
  const classesone = useStylesone();

  useEffect(() => {
    getFeedback();
  }, []);

  const handleRefresh = () => {
    setSpin(true);
    setLoadingone(true);
    // setreload(!reload);
    var url = "api/park/" + parkID + "/gatestatusfeedbackreload-arduinocloud";
    smartParkService.get(url).then((res) => {
      if (res.status === 200) {
        setGetDataRefresh(res.status);
        setGetDataWithoutRefresh(true);
        setRefresh((s) => !s);
      }
      setSpin(false);
      setallChecked(false);
    });
  };

  const getFields = () => {
    // var url = "api/park/" + parkID + "/gatestatusfeedback-arduinocloud";
    var url = "api/park/" + parkID + "/arduinodevice";
    smartParkService
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          var data = res.data.data;
          const dataconst = data.map((d) => {
            return {
              deviceID: d.id,
              isChecked: false,
              ...d,
            };
          });

          setdevicename(dataconst);
        }
      })
      .catch((error) => { });
  };

  const getFeedback = () => {
    var url = "api/park/" + parkID + "/gatestatusfeedback-arduinocloud";
    smartParkService
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setLoadingone(false);
          var data = res.data.feedback;
          const dataconst = data.map((d) => {
            return {
              deviceID: d.id,
              isChecked: false,
              ...d,
            };
          });
          setdevicefeedback(
            dataconst.filter((m) => m.Value === "true" || m.Value === "false")
          );
          setdevicevar(
            dataconst.filter((m) => m.Value !== "true" && m.Value !== "false")
          );
        }
      })
      .catch((error) => { });
  };

  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "GateStatus";
    });
    setpermission(Dpermissions[0]);
  };
  React.useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);
  const handleClose = () => {
    setopen(false);
    setmcpopen(false);
    setallChecked(false);
    setopenbtnactive(false);
    setclosebtnactive(false);
    getFields();
    handleRefresh();
    getFeedback();
  };
  const handleFieldopen = (parkID) => {
    let toggleAllowed = false;
    devicename.forEach((device) => {
      if (device.isChecked === true && btncloseactive) {
        toggleAllowed = true;
        setFieldData((prevState) => [
          ...prevState,
          {
            deviceType: "gate",
            park_id: device.parkid,
            primaryStatus: device.devicestatus,
            updateStatus: "Open",
            field_name: device.devicename,
            field_status: device.devicestatus,
            field_id: device.deviceID,
            url_suffix: "/updategatestatus-arduinocloud",
          },
        ]);
      }
    });
    if (toggleAllowed && btncloseactive) {
      setModalStatus(true);
    }

    // var isListChecked = false;
    // if (
    //   devicename.filter(function (obj) {
    //     if (obj.isChecked) isListChecked = true;
    //   })
    // );
    // if (isListChecked) {
    //   setOpenorclose("Open");
    //   if (parkID === "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944") {
    //     setopen(true);
    //   }
    //   if (parkID === "c7a1d85f-8168-4e64-8e38-116a0624bfeb") {
    //     setmcpopen(true);
    //   }
    // }
  };
  const handleFieldClose = (parkID) => {
    let toggleAllowed = false;
    devicename.forEach((device) => {
      if (device.isChecked === true && btnopenactive) {
        toggleAllowed = true;
        setFieldData((prevState) => [
          ...prevState,
          {
            deviceType: "gate",
            park_id: device.parkid,
            primaryStatus: device.devicestatus,
            updateStatus: "Closed",
            field_name: device.devicename,
            field_status: device.devicestatus,
            field_id: device.deviceID,
            url_suffix: "/updategatestatus-arduinocloud",
          },
        ]);
      }
    });
    if (toggleAllowed && btnopenactive) {
      setModalStatus(true);
    }
    // var isListChecked = false;
    // if (
    //   devicename.filter(function (obj) {
    //     if (obj.isChecked) isListChecked = true;
    //   })
    // );
    // if (isListChecked) {
    //   setOpenorclose("Closed");
    //   if (parkID === "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944") {
    //     setopen(true);
    //   }
    //   if (parkID === "c7a1d85f-8168-4e64-8e38-116a0624bfeb") {
    //     setmcpopen(true);
    //   }
    // }
  };
  const refreshCanvas = () => {
    setSpin(true);
    setreload(!reload);
  };
  const handleChange = (e) => {
    const itemName = e.target.name;
    const checked = e.target.checked;
    let devicename1 = devicename;
    let allChecked1 = allChecked;
    if (itemName === "checkAll") {
      allChecked1 = checked;
      devicename1 = devicename.map((item) => ({
        ...item,
        isChecked: checked,
      }));
    } else {
      devicename1 = devicename.map((item) =>
        item.deviceID === itemName ? { ...item, isChecked: checked } : item
      );
      allChecked1 = devicename1.every((item) => item.isChecked);
    }
    setopenbtnactive(false);
    setclosebtnactive(false);
    devicename1.map((item) => {
      if (item.devicestatus === "Open") {
        if (item.isChecked == true) {
          setopenbtnactive(true);
          // setclosebtnactive(true);
        }
      } else if (item.devicestatus === "Closed") {
        if (item.isChecked == true) {
          // setopenbtnactive(true);
          setclosebtnactive(true);
        }
      }
    });
    setdevicename(devicename1);
    setallChecked(allChecked1);
  };
  const updateStateList = (e, value) => {
    if (e.target.checked) {
      //append to array
      // this.setState({
      //   status: status.concat([value]),
      // });
      setstatus(status.concat([value]));
    } else {
      //remove from array

      setstatus(
        status.filter(function (val) {
          return val !== value;
        })
      );
    }
  };
  React.useEffect(() => {
    getFields();
    getFeedback();
  }, [devicestatus, refresh]);

  React.useEffect(() => {
    setopenbtnactive(false);
    setclosebtnactive(false);
  }, []);

  // React.useEffect(()=>{

  // },[])

  function updatetimestamp(timedate) {
    // console.log(timedate);
    const otherZone = moment.tz(timedate, "America/New_York");
    // console.log(otherZone.format());
    const ESTTime = otherZone.format("MM-DD-YY h:mm A");
    return ESTTime;
  }
  const [modalStatus, setModalStatus] = useState(false);
  const userid = _.get(loginDetails, ["UserID"], "");
  const [fieldData, setFieldData] = useState([]);
  const firstname = _.get(loginDetails, ["FirstName"], "");
  const lastname = _.get(loginDetails, ["LastName"], "");
  const toggleHandler = async (e) => {
    const overrideActions = Object.values(toggleCheckBoxData).filter(
      (checkbox) => {
        if (checkbox.value) {
          return checkbox;
        }
      }
    );
    let url = `api/park/${e[0].park_id}${e[0].url_suffix}`;
    const data = {
      overrideActions: overrideActions[0].name,
      LastEditedBy: `${firstname} ${lastname}`,
      disableNotification: true,
      deviceIDs: [],
      gates: [],
      status: e[0].updateStatus,
    };
    e.forEach((gateData) => {
      data.gates.push(gateData.field_name);
      data.deviceIDs.push(gateData.field_id);
    });
    console.log(data);
    await smartParkService
      .post(url, data, {
        headers: {
          userid: userid,
        },
      })
      .then((res) => {
        if (Number(res.status) === 200) {
          handleRefresh();
          setclosebtnactive(false);
          setopenbtnactive(false);
        }
      });
  };
  const handleCheckbox = (e) => {
    Object.keys(toggleCheckBoxData).map((checkbox) => {
      if (e.target.checked) {
        setToggleCheckBoxData((prevdata) => ({
          ...prevdata,
          [checkbox]: {
            ...prevdata[checkbox],
            value: checkbox === e.target.name ? e.target.checked : false,
          },
        }));
      } else {
        setToggleCheckBoxData((prevState) => ({
          ...prevState,
          [e.target.name]: {
            ...prevState[e.target.name],
            value: e.target.checked,
          },
        }));
      }
    });
  };
  const closeModal = () => {
    setFieldData([]);
    setModalStatus(false);
  };
  const [toggleCheckBoxData, setToggleCheckBoxData] = useState({
    Block: { name: "Block", label: "Block rule actions", value: false },
    Unblock: { name: "Unblock", label: "Unblock rule actions", value: false },
  });
  const checkBoxDescription = [
    "The automatic rules have actions that can open or close the devices.",
    "Do you want to block the actions of those rules?",
  ];
  const helpText = [
    `If you chose to block, then gates, fields, park will remain ${fieldData[0]?.updateStatus
      ? fieldData[0].updateStatus
      : "in [open/closed] state"
    }, until the next manual change.`,
    "If you chose to unblock the rule actions, the gates, fields, park will change state based on the rules. This manual action will be overridden.",
  ];

  return (
    <div>
      <ToggleModal
        submitButtonHandler={(e) => {
          toggleHandler(e);
        }}
        handleCheckbox={handleCheckbox}
        disableData={fieldData}
        closeModalHandler={closeModal}
        checkBoxData={toggleCheckBoxData}
        checkBoxDescription={checkBoxDescription}
        modalState={modalStatus}
        checkBoxHelpText={helpText}
      />
      {/*<Modal*/}
      {/*  show={open}*/}
      {/*  status={devicename}*/}
      {/*  closePopup={handleClose}*/}
      {/*  openclose={Openorclose}*/}
      {/*/>*/}
      {/*<MCPGatemodal*/}
      {/*  show={mcpopen}*/}
      {/*  status={devicename}*/}
      {/*  closePopup={handleClose}*/}
      {/*  openclose={Openorclose}*/}
      {/*/>*/}
      <div className="box">
        <div className="box-body" style={{ padding: "0px" }}>
          <div className="divGrid">
            <div className="divGridBody">
              <div
                className="headgate"
                style={{ background: "rgb(0, 118, 187)" }}
              >
                <h6
                  className=" text-center text-white fw-bold"
                  style={{ margin: "0px" }}
                >
                  Gate Status
                </h6>
              </div>
              {devicevar.map((m) => (
                <div className="cloudhead">
                  <div className="gridwidth text-left">
                    {m.Name === "Cloud Variable" ? "Cloud" : m.Name}
                  </div>
                  <div className="gridwidth text-left">{m.Value ?? "NA"}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="box-header text-center">
          {checkPermissions({
            permission: "GateStatus",
            permissionType: "update",
          }) && (
              <div className="text-center" style={{ marginBottom: "-10px" }}>
                <ButtonLink
                  onClick={() => handleFieldopen(parkID)}
                  className={`${classes.openclosedisabled} ${btncloseactive === true ? classes.blueButton : ""
                    } `}
                >
                  Open Gates
                </ButtonLink>

                <ButtonLink
                  onClick={() => handleFieldClose(parkID)}
                  className={`${classes.openclosedisabled} ${btnopenactive === true ? classes.redButton : ""
                    } `}
                >
                  Close Gates
                </ButtonLink>
                <span style={{ color: "black !important" }}>
                  <Refresh
                    edge="end"
                    fontsize="medium"
                    className={clsx({
                      [classesone.refresh]: true,
                      spin: spin,
                    })}
                    onClick={handleRefresh}
                    spin={360}
                  />
                </span>
              </div>
            )}
        </div>

        <div className="box-body">
          <div className="divGrid">
            <div className="divGridHeading">
              <div className="divGridRow">
                <div className="divGridHead">
                  {checkPermissions({
                    permission: "GateStatus",
                    permissionType: "update",
                  }) && (
                      <Checkbox
                        color="primary"
                        style={{ padding: "2px" }}
                        checked={allChecked}
                        name="checkAll"
                        onChange={(e) => handleChange(e)}
                      />
                    )}
                  Gate
                </div>
                <div className="divGridHead">Status</div>
                <div className="divGridHead">Feedback</div>
              </div>
            </div>
            <div className="divGridBody">
              {devicename.map((m, index) => (
                <div key={m.deviceID} className="divGridRow">
                  <div className="divGridCell">
                    {checkPermissions({
                      permission: "GateStatus",
                      permissionType: "update",
                    }) && (
                        <Checkbox
                          color="primary"
                          name={m.deviceID}
                          checked={m.isChecked}
                          style={{ padding: "2px" }}
                          key={m.devicename}
                          onChange={(e) => handleChange(e)}
                        />
                      )}
                    {m.devicename}
                  </div>
                  <div className="divGridCell">
                    <div className="d-flex">
                      <FiberManualRecordIcon
                        fontSize="small"
                        style={{
                          color:
                            m.devicestatus === "Open" ? green[500] : red[500],
                        }}
                      />{" "}
                      {m.devicestatus}
                    </div>
                  </div>
                  <div className="divGridCell">
                    {loadingone ? (
                      <span>Loading....</span>
                    ) : getdatarefresh === 200 &&
                      getdatawithoutrefresh === true ? (
                      <span>
                        {devicefeedback &&
                          devicefeedback[index]?.Value === "true"
                          ? "Closed"
                          : "Open"}
                      </span>
                    ) : (
                      <span>
                        {devicefeedback &&
                          devicefeedback[index]?.Value === "true"
                          ? "Closed"
                          : "Open"}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="divGrid mt-3 mainfeedback">
            <div className="divGridBody">
              <div className="divGridRow" style={{ fontWeight: "bold" }}>
                <div
                  className="divGridCell head"
                  style={{
                    gridColumn: "1 / 3",
                    textAlign: "left",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  Gates
                </div>
                <div class="divGridCell head">Last Updated</div>
              </div>
              {devicefeedback.map((m) => (
                <div key={m.deviceID} className="divGridRow">
                  <div className="divGridCell">{m.Name}</div>
                  <div className="divGridCell">
                    <div className="d-flex">
                      {updatetimestamp(m.LastValueUpdatedDate)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
