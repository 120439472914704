import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import smartParkService from "../../axios";
import { KeyboardBackspace } from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import styles from "../../components/components.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { loginAuth, hasLoaded_, login_, token_ } from "../../reducers/login";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
  unmountPermissions,
} from "../../reducers/permissions";
import _ from "lodash";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const [role, setRole] = React.useState("");
  const dispatch = useDispatch();
  const [rolename, setrolename] = React.useState([]);
  const [permissionsRows, setpermissionsRows] = React.useState([]);
  const loginHasLoaded = useSelector(hasLoaded_);
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;

  const handleChange = (event) => {
    setRole(event.target.value);
    smartParkService
      .get("api/park/" + parkid + "/rolepermission/" + event.target.value)
      .then((res, error) => {
        if (res) {
          setpermissionsRows([...res.data.data[0].RolePermission]);
        } else {
        }
      });
  };

  var SaveRolepermissions = () => {
    var dataobject = {};
    dataobject.RolePermission = permissionsRows;
    dataobject.LastEditedBy = "varma";
    smartParkService
      .post("api/park/" + parkid + "/rolepermissions/" + role, dataobject)
      .then((res, error) => {
        if (res) {
          dispatch(unmountPermissions());
          alert("Role Permission Updated Successfully");
          const rolearray = _.get(loginDetails, ["Role"], "");
          var keys = Object.keys(rolearray);
          var filtered = keys.filter(function (key) {
            return rolearray[key];
          });
          dispatch(Getaccesspermissions({ parkid, query: filtered.join(",") }));
        } else {
        }
      });
  };
  const radioonchange = (e) => {
    var change = e.target.name.split("_");
    var changeid = change[0];
    var changename = change[1];
    var permissionsobject = permissionsRows;
    for (var i = 0; i < permissionsobject.length; i++) {
      if (permissionsobject[i].feature == changeid) {
        permissionsobject[i][changename] = e.target.checked ? "1" : "0";
      }
    }
    setpermissionsRows([...permissionsobject]);
  };

  React.useEffect(() => {
    smartParkService
      .get("api/park/" + parkid + "/rolepermission")
      .then((res, error) => {
        if (res.data.data.length > 0) {
          setRole(res.data.data[0].Role);
          setrolename([...res.data.data]);
        } else {
        }
      });
    smartParkService
      .get("api/park/" + parkid + "/rolepermission/" + role)
      .then((res, error) => {
        if (res.data.data.length > 0) {
          setpermissionsRows([...res.data.data[0].RolePermission]);
        } else {
        }
      });
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Access Management</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" class="active">
                  Access Management
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <div class="content-middle">
            <div class="content-sub-header">
              <div>
                <Link
                  component="button"
                  onClick={() => history.push("./AccessManagment")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
              <div>
                <h3>Edit Role Permissions </h3>
              </div>
              <div class="display-xs-0">
                <Link
                  style={{ visibility: "hidden" }}
                  component="button"
                  onClick={() => history.push("/AccessManagement")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
            </div>

            <Paper className={classes.paper}>
              <div class="form-body pad20">
                <div class="form-column-layout">
                  <div class="form-col">
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={classes.w80p}
                    >
                      <InputLabel id="ChooseLableRole">Choose Role</InputLabel>
                      <Select
                        labelId="ChooseLableRole"
                        id="ChooseRole"
                        value={role}
                        label="Choose Role"
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Choose Role" }}
                      >
                        <MenuItem value="" disabled>
                          Choose
                        </MenuItem>
                        {rolename.length > 0
                          ? rolename.map((e) => {
                              return (
                                <MenuItem value={e.Role}>{e.Role}</MenuItem>
                              );
                            })
                          : null}
                        {/* <MenuItem value="test">test</MenuItem>
                        <MenuItem value="my dest">my dest</MenuItem>
                        <MenuItem value="PW Supervisor">PW Supervisor</MenuItem>
                        <MenuItem value="Administrator">Administrator</MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                  <br />

                  <div class="form-col">
                    <label>
                      {" "}
                      <b>Features</b>
                    </label>
                    <div>
                      {permissionsRows.length > 0
                        ? permissionsRows.map((e) => {
                            return (
                              <div class="contentBox">
                                <div class="contentBoxRow">
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="adminView"
                                          color="primary"
                                        />
                                      }
                                      label={e.feature}
                                    />
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      name={e.feature + "_create"}
                                      checked={e.create == "1" ? true : false}
                                      control={<Switch color="secondary" />}
                                      label="Create"
                                      labelPlacement="top"
                                      onChange={radioonchange}
                                    />
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      name={e.feature + "_read"}
                                      checked={e.read == "1" ? true : false}
                                      value="Read"
                                      control={<Switch color="secondary" />}
                                      label="Read"
                                      labelPlacement="top"
                                      onChange={radioonchange}
                                    />
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      name={e.feature + "_update"}
                                      value="Update"
                                      checked={e.update == "1" ? true : false}
                                      control={<Switch color="secondary" />}
                                      label="Update"
                                      labelPlacement="top"
                                      onChange={radioonchange}
                                    />
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      name={e.feature + "_delete"}
                                      checked={e.delete == "1" ? true : false}
                                      control={<Switch color="secondary" />}
                                      label="Delete"
                                      labelPlacement="top"
                                      onChange={radioonchange}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-footer">
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  className={classes.btnRound}
                  onClick={SaveRolepermissions}
                >
                  SAVE
                </Button>
                &nbsp;&nbsp;
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  className={classes.btnRound}
                  onClick={() => {
                    history.push("/AccessManagment");
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </Paper>
          </div>
        </section>
      </div>
    </div>
  );
}
