import React, { useState, useEffect } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import Select from "@material-ui/core/Select";
import {
    userDashboard_,
    updateFieldSelections,
} from "../../../reducers/userDashboard";
import MultiSelect from "react-multi-select-component";
import { GenerateEncryptedToken } from "../../Common";
import smartParkService from "../../../axios";
import PopularTimesChart from "./populartimes";
import {
    DateRange,
    ExpandMore,
    Remove,
    Build,
    Clear,
} from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MCPImg from "../../../assets/img/MorrisvilleCommunityPark.jpg";
import FieldStatusChart from "../../Charts/CitizenFieldStatusChart";
import { AccountCircle, NotificationsOutlined } from "@material-ui/icons";
import CutsomCard from "../../../components/CustomCard";
import AdminCourtStatusCard from "../../../components/AdminCourtStatusCard";

import logo from "../../../assets/img/Logo.png";
import { Grid, Link, ListItemIcon } from "@material-ui/core";
import WeatherReport from "../../../components/weather-report";
import NotifyMe from "../../NotifyMe";
import PopularGraph from "../../Charts/PopularGraph";
import CSTCCourtGraph from "../../Charts/CSTCCourtGraph";
import moment from "moment";
import WidgetMoreDetails from "./widget-more-details";
import { setFieldCourts } from "../../../reducers/userDashboard";
import { escapeSelector } from "jquery";
import AdminFloodSensorStatus from "./AdminFloodSensorStatus";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    section1: {
        background: "#f8f8f8",
        padding: theme.spacing(1, 4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    section2: {
        background: "#fff",
        padding: theme.spacing(1, 4),
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            alignItems: "center",
        },
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    AppTitle: {
        cursor: "pointer",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
        },
    },
    pageHeader: {
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    appBar: {
        background: "#fff",
        padding: theme.spacing(1),
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    mainContent: {
        marginTop: 15,
        marginLeft: 50,
        marginBottom: 50,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 40,
        },
    },
    userLogin: {
        color: "#444",
        margin: theme.spacing(0, 2),
    },
    refreshButton: {
        background: "#fff !important",
        fontWeight: 600,
    },
    "grid-itemone": {
        flexDirection: "column",
    },
    "grid-item-status": {
        flexGrow: 1,
    },
}));

const ModalDialog = withStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {},
    },
}))(Dialog);

const millisecond_in_a_day = 86400000;
export default function Dashboard() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [NotifyPopUp, setNotifyPopUp] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [moreDetails, setMoreDetails] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [cardType, setCardType] = React.useState("");
    const [MAFCCourtsData, setMAFCCourtsData] = React.useState([]);
    const [CSTCCourtsData, setCSTCCourtsData] = React.useState([]);
    smartParkService.defaults.headers.common["x-access-token"] =
        GenerateEncryptedToken();
    const [MAFCCourts, setMAFCCourts] = React.useState("Court 1");
    const [CSTCCourts, setCSTCCourts] = React.useState("Church Street Court 1");
    const [getmaxHeight, setMaxHeight] = useState(0);
    const [state, setState] = React.useState({
        start: moment().subtract(1, "days"),
        end: moment(),
    });
    let closeImg = {
        cursor: "pointer",
        float: "right",
        marginTop: "5px",
        width: "20px",
    };

    const getMAFCCourts = () => {
        smartParkService.get("api/courts").then((res, err) => {
            setMAFCCourts(res.data.data[0].court_api_id);
            setMAFCCourtsData(res.data.data);
        });
    };
    const getCSTCCourtsData = () => {
        smartParkService.get("api/churchstreetcourts").then((res, err) => {
            setCSTCCourts(res.data.data[0].court_api_id);
            setCSTCCourtsData(res.data.data);
        });
    };
    const getDateBasedOnRange = (rangeType) => {
        switch (rangeType) {
            case "Last 24 Hours":
                return [moment().subtract(1, "days").toDate(), moment().toDate()];
            case "LastWeek":
                return [moment().subtract(6, "days").toDate(), moment().toDate()];
            case "Last Month":
                return [
                    moment().subtract(1, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                ];
            case "Last 3 Month":
                return [
                    moment().subtract(3, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                ];
            case "Last 6 Month":
                return [
                    moment().subtract(6, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                ];
            case "LastYear":
                return [
                    moment().subtract(12, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                ];
            default:
                return [];
        }
    };
    const { start, end } = state;
    const handleCallback = (start, end, rangeType) => {
        if (
            new Date(end._d).getTime() - new Date(start._d).getTime() <=
            millisecond_in_a_day
        ) {
            var endDate = new Date().getTime();
            end = moment(endDate);
            start = moment(endDate - millisecond_in_a_day);
        }
        //dispatch(updateFieldSelections({ fieldDate: { start, end } }));
        setState({ start, end });
        // getGraphData();
    };
    const label =
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
    const [fieldStatus, setFieldStatus] = React.useState({});
    const updateFieldStatus = (fieldStatusData) => {
        setFieldStatus(JSON.stringify(fieldStatusData));
    };
    const getParkFields = async () => {
        var restoken = await GenerateEncryptedToken();
        await smartParkService
            .get("api/citizen/fielddata", {
                headers: { "x-access-token": restoken },
            })
            .then((res) => {
                if (res.status === 200) {
                    var fields = res.data.data;
                    var options = [];
                    fields.forEach(function (field) {
                        options.push({
                            label: field.FieldName,
                            value: field.id,
                        });
                    });
                    //options.push({ label: "All Fields", value: "ALL" });
                    setOptions(options);
                    setSelected([options[0]]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    React.useEffect(() => {
        getParkFields();
        getMAFCCourts();
        getCSTCCourtsData();
    }, []);
    // const start=1640975400000;
    // const end=1643567400000;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleOpen = (value) => {
        setState({
            start: moment().subtract(30, "days"),
            end: moment(),
        });
        // setOpen(true);
        dispatch(setFieldCourts([]));
        setCardType(value);
        setMoreDetails(true);
        window.scrollTo(0, 0);
    };
    const handleClose = () => {
        setOpen(false);
        setCardType("");
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleCloseEvent = () => {
        setNotifyPopUp(false);
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={() => setNotifyPopUp(true)}>
                <ListItemIcon>&nbsp;</ListItemIcon>
                Notify Me
            </MenuItem>
            {/* <MenuItem>
        <IconButton aria-label="show 5 new notifications" color="inherit">
          <Badge badgeContent={5} color="error">
            <NotificationsOutlined />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
        </Menu>
    );
    const fieldStatusGraph = (
        <div className={classes.paper}>
            <Paper elevation={3}>
                <div className="box" style={{ width: "700px", height: "550px" }}>
                    <div className="box-header with-border">
                        <h6 className="box-title">Field Data – Historical &nbsp;&nbsp;</h6>
                        <CloseIcon style={closeImg} onClick={handleClose} />
                        <br />
                        <br />
                        <div
                            style={{
                                display: "flex",
                                alignContent: "center",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <div
                                style={{
                                    minwidth: "150px",
                                    maxWidth: "350px",
                                    width: "100%",
                                    display: "inline-block",
                                    marginTop: "7px",
                                }}
                            >
                                <MultiSelect
                                    className={classes.Hgt30}
                                    options={options}
                                    value={selected}
                                    onChange={setSelected}
                                />
                            </div>

                            <div className="box-tools pull-right" style={{ display: "none" }}>
                                <IconButton
                                    aria-label="Minimize"
                                    size="small"
                                    className={classes.margin2}
                                >
                                    <Remove fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                    aria-label="Settings"
                                    size="small"
                                    className={classes.margin2}
                                >
                                    <Build fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                    aria-label="Clear"
                                    size="small"
                                    className={classes.margin2}
                                >
                                    <Clear fontSize="inherit" />
                                </IconButton>
                            </div>
                            <div
                                style={{
                                    float: "right",
                                    marginRight: "10px",
                                    marginTop: "10px",
                                }}
                            >
                                <ButtonGroup size="small" color="primary" aria-label="">
                                    <Button>
                                        <DateRangePicker
                                            initialSettings={{
                                                startDate: start.toDate(),
                                                endDate: end.toDate(),
                                                maxDate: new Date(),
                                                ranges: {
                                                    "Last 24 Hours": getDateBasedOnRange("Last 24 Hours"),
                                                    LastWeek: getDateBasedOnRange("LastWeek"),
                                                    "Last Month": getDateBasedOnRange("Last Month"),
                                                    "Last 3 Month": getDateBasedOnRange("Last 3 Month"),
                                                    "Last 6 Month": getDateBasedOnRange("Last 6 Month"),
                                                    LastYear: getDateBasedOnRange("LastYear"),
                                                },
                                            }}
                                            onCallback={handleCallback}
                                        >
                                            <div id="reportrange">
                                                <DateRange />
                                                <span>{label}</span> <ExpandMore />
                                            </div>
                                        </DateRangePicker>
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>

                    <div className="box-body">
                        {selected.length > 0 ? (
                            <FieldStatusChart
                                selectedFileds={selected}
                                selectedDateRange={{ start: start, end: end }}
                                selectionChanged={new Date().getTime()}
                                updateFieldStatus={updateFieldStatus}
                            />
                        ) : (
                            <div>Loading..</div>
                        )}
                    </div>
                </div>
            </Paper>
        </div>
    );
    const MAFCGraph = () => {
        return (
            <div className={classes.paper}>
                <Paper elevation={3}>
                    <div className="box" style={{ width: "700px", height: "450px" }}>
                        <div className="box-header with-border">
                            <h6 className="box-title">MAFC Tennis Courts</h6>
                            <CloseIcon style={closeImg} onClick={handleClose} />
                            <br />
                            <br />
                            <div
                                style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Select
                                    style={{ width: "300px", height: "35px", marginTop: "10px" }}
                                    variant="outlined"
                                    size="small"
                                    labelId="label"
                                    value={MAFCCourts}
                                    onChange={(e) => {
                                        setMAFCCourts(e.target.value);
                                    }}
                                >
                                    {MAFCCourtsData &&
                                        MAFCCourtsData.length > 0 &&
                                        MAFCCourtsData.map((obj) => (
                                            <MenuItem value={obj.court_api_id}>
                                                {obj.court_name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                <div
                                    style={{
                                        float: "right",
                                        marginRight: "10px",
                                        marginTop: "10px",
                                    }}
                                >
                                    <ButtonGroup size="small" color="primary" aria-label="">
                                        <Button>
                                            <DateRangePicker
                                                initialSettings={{
                                                    startDate: start.toDate(),
                                                    endDate: end.toDate(),
                                                    maxDate: new Date(),
                                                    ranges: {
                                                        "Last 24 Hours":
                                                            getDateBasedOnRange("Last 24 Hours"),
                                                        LastWeek: getDateBasedOnRange("LastWeek"),
                                                        "Last Month": getDateBasedOnRange("Last Month"),
                                                        "Last 3 Month": getDateBasedOnRange("Last 3 Month"),
                                                        "Last 6 Month": getDateBasedOnRange("Last 6 Month"),
                                                        LastYear: getDateBasedOnRange("LastYear"),
                                                    },
                                                }}
                                                onCallback={handleCallback}
                                            >
                                                <div id="reportrange">
                                                    <DateRange />
                                                    <span>{label}</span> <ExpandMore />
                                                </div>
                                            </DateRangePicker>
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <PopularGraph
                                value={MAFCCourts}
                                selectedDateRange={{ start: start, end: end }}
                            />
                        </div>
                    </div>
                </Paper>
            </div>
        );
    };
    const cstcGraph = () => {
        return (
            <div className={classes.paper}>
                <Paper elevation={3}>
                    <div className="box" style={{ width: "700px", height: "450px" }}>
                        <div className="box-header with-border">
                            <h6 className="box-title">Church Street Park Tennis Court(s)</h6>
                            <CloseIcon style={closeImg} onClick={handleClose} />
                            <div
                                style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Select
                                    style={{ width: "300px", height: "35px", marginTop: "10px" }}
                                    variant="outlined"
                                    size="small"
                                    labelId="label"
                                    value={CSTCCourts}
                                    onChange={(e) => {
                                        setCSTCCourts(e.target.value);
                                    }}
                                >
                                    {CSTCCourtsData &&
                                        CSTCCourtsData.length > 0 &&
                                        CSTCCourtsData.map((obj) => (
                                            <MenuItem value={obj.court_api_id}>
                                                {obj.court_name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                <div
                                    style={{
                                        float: "right",
                                        marginRight: "10px",
                                        marginTop: "10px",
                                    }}
                                >
                                    <ButtonGroup size="small" color="primary" aria-label="">
                                        <Button>
                                            <DateRangePicker
                                                initialSettings={{
                                                    startDate: start.toDate(),
                                                    endDate: end.toDate(),
                                                    maxDate: new Date(),
                                                    ranges: {
                                                        "Last 24 Hours":
                                                            getDateBasedOnRange("Last 24 Hours"),
                                                        LastWeek: getDateBasedOnRange("LastWeek"),
                                                        "Last Month": getDateBasedOnRange("Last Month"),
                                                        "Last 3 Month": getDateBasedOnRange("Last 3 Month"),
                                                        "Last 6 Month": getDateBasedOnRange("Last 6 Month"),
                                                        LastYear: getDateBasedOnRange("LastYear"),
                                                    },
                                                }}
                                                onCallback={handleCallback}
                                            >
                                                <div id="reportrange">
                                                    <DateRange />
                                                    <span>{label}</span> <ExpandMore />
                                                </div>
                                            </DateRangePicker>
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <CSTCCourtGraph
                                value={CSTCCourts}
                                selectedDateRange={{ start: start, end: end }}
                            />
                        </div>
                    </div>
                </Paper>
            </div>
        );
    };

    const modalBody = () => {
        if (cardType) {
            // eslint-disable-next-line default-case
            switch (cardType) {
                case "cfdp":
                    return fieldStatusGraph;
                case "mafc":
                    return MAFCGraph();
                case "cstc":
                    return cstcGraph();
            }
        }
    };

    return (
        <>
            {!moreDetails ? (
                <>
                    <main className={classes.mainContent}>
                        <section className={classes.section1}>
                            <Grid container spacing={4} className="justify-content-center">
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    alignItems="stretch"
                                    className={`${classes["grid-itemone"]}`}
                                >
                                    {/* <AdminFloodSensorStatus value="cfdp" /> */}
                                    <Grid container className={`${classes["grid-item-status"]}`}>
                                        <Grid item xs={12}>
                                            <AdminCourtStatusCard
                                                cardType="cfdp"
                                                actions
                                                handleClose={handleClose}
                                                handleOpen={handleOpen}
                                                modalValue="cfdp"
                                                url="/CederForkDistrictPark"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    alignItems="stretch"
                                    className={`${classes["grid-itemone"]}`}
                                >
                                    {/* <AdminFloodSensorStatus value="churchstreettenniscourts" /> */}
                                    <Grid container className={`${classes["grid-item-status"]}`}>
                                        <Grid item xs={12}>
                                            <AdminCourtStatusCard
                                                cardType="churchstreettenniscourts"
                                                actions
                                                handleClose={handleClose}
                                                handleOpen={handleOpen}
                                                modalValue="csppark"
                                                url="/ChurchStreetPark"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    alignItems="stretch"
                                    className={`${classes["grid-itemone"]}`}
                                >
                                    {/* <AdminFloodSensorStatus value="morrisvillecommunitypark"/> */}
                                    <Grid container className={`${classes["grid-item-status"]}`}>
                                        <Grid item xs={12}>
                                            <AdminCourtStatusCard
                                                cardType="ccnp"
                                                actions
                                                handleClose={handleClose}
                                                handleOpen={handleOpen}
                                                url="/ccnp"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    alignItems="stretch"
                                    className={`${classes["grid-itemone"]}`}
                                >
                                    {/* <AdminFloodSensorStatus value="mafctenniscourts"/> */}
                                    <Grid container className={`${classes["grid-item-status"]}`}>
                                        <Grid item xs={12}>
                                            <AdminCourtStatusCard
                                                cardType="mafctenniscourts"
                                                actions
                                                handleClose={handleClose}
                                                handleOpen={handleOpen}
                                                modalValue="mafc"
                                                url="/MAFC"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    alignItems="stretch"
                                    className={`${classes["grid-itemone"]}`}
                                >
                                    {/* <AdminFloodSensorStatus value="morrisvillecommunitypark"/> */}
                                    <Grid container className={`${classes["grid-item-status"]}`}>
                                        <Grid item xs={12}>
                                            <AdminCourtStatusCard
                                                cardType="morrisvillecommunitypark"
                                                actions
                                                handleClose={handleClose}
                                                handleOpen={handleOpen}
                                                url="/MorrisvilleCommunityPark"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    alignItems="stretch"
                                    className={`${classes["grid-itemone"]}`}
                                >
                                    {/* <AdminFloodSensorStatus value="morrisvillecommunitypark"/> */}
                                    <Grid container className={`${classes["grid-item-status"]}`}>
                                        <Grid item xs={12}>
                                            <AdminCourtStatusCard
                                                cardType="shiloh"
                                                actions
                                                handleClose={handleClose}
                                                handleOpen={handleOpen}
                                                url="/shiloh"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </section>
                    </main>
                </>
            ) : (
                <WidgetMoreDetails
                    cardType={cardType}
                    setMoreDetails={setMoreDetails}
                />
            )}
            {/* <footer className="dash-footer">Powered by Varidx</footer> */}
            <NotifyMe NotifyPopUp={NotifyPopUp} handleCloseEvent={handleCloseEvent} />
        </>
    );
}
