export const setCookie = ({
  fetchedon = new Date().getTime(),
  name,
  value,
  minutes,
}) => {
  const elapsedTime = (Date.now() - fetchedon) / (60 * 1000);
  const remainingMinutes = Math.max(0, minutes - elapsedTime);
  const expires = new Date(
    Date.now() + remainingMinutes * 60 * 1000
  ).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    JSON.stringify(value)
  )}; path=/; expires=${expires}`;
};

export const getCookie = (name) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(name + "="))
    ?.split("=")[1];

  return cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : null;
};
