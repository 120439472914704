import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { makeStyles, Paper, Grid, Typography } from "@material-ui/core";
import equipmentRoomImage from "../../../assets/img/equipmentroom1.png";
import OccupancyCard from "../../../components/OccupancyCard/OccupancyCard";
import SvgContainer from "./SvgContainer";
import steppers from "../../../assets/img/steppers.png";
import ellipticals from "../../../assets/img/ellipticals.png";
import rowers from "../../../assets/img/rowers.png";
import OccupancyCardRenderer from "../../../components/OccupancyCard/OccupancyCardRenderer";
import treadmillImage from "../../../assets/img/treadmill.png";
import treadmillBusy from "../../../assets/img/Treadmill-busy.png";
import treadmillEmpty from "../../../assets/img/Treadmill-empty.png";
import ellipticalsBusy from "../../../assets/img/Ellipticals-busy.png";
import ellipticalsEmpty from "../../../assets/img/Ellipticals-empty.png";

const fieldsList = [
  {
    name: "Treadmills",
    icon: treadmillImage,
    chips: [
      { name: "Total :- ", value: "", color: "#006ea6" },
      { name: "Available :- ", value: " ", color: "#4caf50" },
    ],
  },
  {
    name: "Bikes",
    icon: steppers,
    chips: [
      { name: "Total :- ", value: "", color: "#006ea6" },
      { name: "Available :- ", value: "", color: "#4caf50" },
    ],
  },
  {
    name: "Ellipticals",
    icon: ellipticals,
    chips: [
      { name: "Total :- ", value: "", color: "#006ea6" },
      { name: "Available :- ", value: "", color: "#4caf50" },
    ],
  },
  {
    name: "Rowers",
    icon: rowers,
    chips: [
      { name: "Total :- ", value: "", color: "#006ea6" },
      { name: "Available :- ", value: "", color: "#4caf50" },
    ],
  },
];

const MafcEquipmentRoom = ({
  defaultSize = "100%",
  lgScreen,
  xlscreen,
  xxlScreen,
}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      marginBottom: 10,
    },
    paperContainer: {
      height: defaultSize,
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 1279px)": {
        height: "100%",
      },
      "@media (min-width: 1280px) and (max-width:1439px)": {
        height: lgScreen || defaultSize,
      },
      "@media (min-width: 1440px) and (max-width:1749px)": {
        height: xlscreen || defaultSize,
      },
      "@media (min-width: 1750px) ": {
        height: xxlScreen || defaultSize,
      },
    },
    chartContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    graphTitle: {
      paddingTop: 5,
      paddingLeft: 10,
    },
  }));
  const classes = useStyles();

  const [totalOccupancy, setTotalOccupancy] = useState("Coming Soon");
  const equipmentRoomMap = [
    {
      name: "Total Count",
      type: "rect",
      xAxis: 650,
      yAxis: 10,
      width: 495,
      height: 100,
      color: "#487fb3",
      radiusX: "10px",
      radiusY: "10px",
      stroke: "#a1a1a0",
      strokeWidth: "5",
      lineCap: "butt",
    },
    {
      name: "Current Occupancy",
      type: "text",
      xAxis: 680,
      yAxis: 70,
      body: `${totalOccupancy} `,
      fontSize: 30,
      textColor: "#fdfdfd",
    },
    {
      name: "Treadmill 4",
      type: "image",
      width: 200,
      xAxis: 368,
      yAxis: 5,
      body: treadmillBusy,
    },
    {
      name: "Treadmill 3",
      type: "image",
      width: 200,
      xAxis: 302,
      yAxis: 50,
      body: treadmillBusy,
    },
    {
      name: "Treadmill 2",
      type: "image",
      width: 200,
      xAxis: 232,
      yAxis: 90,
      body: treadmillBusy,
    },
    {
      name: "Treadmill 1",
      type: "image",
      width: 200,
      xAxis: 160,
      yAxis: 135,
      body: treadmillBusy,
    },

    {
      name: "Ellipticals 3",
      type: "image",
      width: 160,
      xAxis: 740,
      yAxis: 165,
      body: ellipticalsBusy,
    },
    {
      name: "Ellipticals 4",
      type: "image",
      width: 160,
      xAxis: 820,
      yAxis: 200,
      body: ellipticalsBusy,
    },
    {
      name: "Ellipticals 2",
      type: "image",
      width: 160,
      xAxis: 900,
      yAxis: 240,
      body: ellipticalsBusy,
    },
    {
      name: "Ellipticals 1",
      type: "image",
      width: 160,
      xAxis: 990,
      yAxis: 280,
      body: ellipticalsBusy,
    },
  ];
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Weekly Summary",
      align: "left",
    },
    xAxis: {
      categories: ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"],
      crosshair: false,
      accessibility: {
        description: "Days of the Week",
      },
    },
    yAxis: {
      visible: false,
      min: 0,
      title: {
        text: "Occupancy",
      },
    },
    tooltip: {
      valueSuffix: "",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Treadmill",
        data: [6, 26, 10, 8, 7, 14, 10],
        visible: true,
      },
      {
        name: "Rowers",
        data: [6, 26, 10, 8, 7, 14, 10],
        visible: false,
      },
      {
        name: "Steppers",
        data: [6, 26, 10, 8, 7, 14, 10],
        visible: false,
      },
    ],
  };

  const getConfig = () => ({
    plotOptions: {
      column: {
        colorByPoint: true,
      },
    },
    title: {
      text: "",
      align: "left",
    },
    chart: {
      type: "column",
    },
    xAxis: {
      categories: [
        "6 am",
        "7 am",
        "8 am",
        "9 am",
        "10 am",
        "11 am",
        "12",
        "1 pm",
        "2 pm",
        "3 pm",
        "4 pm",
        "5 pm",
        "6 pm",
        "7 pm",
        "8 pm",
        "9 pm",
        "10 pm",
      ],
    },
    yAxis: {
      visible: false,
      min: 0,
      title: {
        text: "",
      },
    },
    tooltip: {
      split: true,
      formatter: function () {
        var crowd = this.y;
        if (crowd >= 10)
          return "Occupied " + crowd + " times.</br> Usually Busy On " + this.x;
        else
          return (
            "Occupied " +
            crowd +
            " times.</br> Usually Not So Busy On " +
            this.x
          );
      },
    },
    series: [
      {
        name: "Sunday",
        showInLegend: true,
        data: category,
        visible: true,
      },
      {
        name: "Monday",
        showInLegend: true,
        data: category,
        visible: false,
      },
      {
        name: "Tuesday",
        showInLegend: true,
        data: category,
        visible: false,
      },
      {
        name: "Wednesday",
        showInLegend: true,
        data: category,
        visible: false,
      },
      {
        name: "Thursday",
        showInLegend: true,
        data: category,
        visible: false,
      },
      {
        name: "Friday",
        showInLegend: true,
        data: category,
        visible: false,
      },
      {
        name: "Saturday",
        showInLegend: true,
        data: category,
        visible: false,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            xAxis: {
              labels: {
                rotation: -45,
                align: "center",
              },
            },
          },
        },
      ],
    },
  });

  const category = [
    ["6 am", 0],
    ["7 am", 2],
    ["8 am", 1],
    ["9 am", 2],
    ["10 am", 2],
    ["11 am", 1],
    ["12", 0],
    ["1 pm", 1],
    ["2 pm", 2],
    ["3 pm", 0],
    ["4 pm", 1],
    ["5 pm", 5],
    ["6 pm", 5],
    ["7 pm", 5],
    ["8 pm", 4],
    ["9 pm", 2],
    ["10 pm", 0],
  ];

  return (
    <>
      <div className={classes.container}>
        <OccupancyCardRenderer
          heading={"Equipment Room Occupancy"}
          fieldList={fieldsList}
        />
      </div>

      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Paper className={classes.paperContainer} elevation={7}>
            <SvgContainer
              title="Pool Area"
              mappingArray={equipmentRoomMap}
              image={equipmentRoomImage}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Paper className={classes.paperContainer} elevation={7}>
            <Typography className={classes.graphTitle} variant="h5">
              Coming Soon
            </Typography>
            {/* <div className={classes.chartContainer}>
              <HighchartsReact
                highcharts={Highcharts}
                options={getConfig(category)}
                containerProps={{
                  style: { width: "100%", height: "100%" }, // Ensure chart takes full width and height
                }}
              />
            </div> */}
          </Paper>
        </Grid>
      </Grid>
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={7}>
            <Typography
              style={{ height: 400 }}
              className={classes.graphTitle}
              variant="h5"
            >
              Coming Soon
            </Typography>
            {/* <HighchartsReact
              highcharts={Highcharts}
              options={options}
              containerProps={{ style: { height: "100%", width: "100%" } }}
            /> */}
          </Paper>
        </Grid>
      </Grid>
      <Grid className={classes.container} container spacing={2}>
        {fieldsList.map((field, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            <OccupancyCard
              heading={field.name}
              icon={field.icon}
              fieldsList={field.chips}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default MafcEquipmentRoom;
