import React, { useEffect, useState, useRef } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import Table from 'react-bootstrap/Table';
import { Grid, Paper } from '@material-ui/core';
import { BiSolidCheckCircle } from 'react-icons/bi';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Button from "../CustomButtons/Button.js";
import smartParkService from "../../axios/index.js";
import { useLocation } from "react-router-dom";
import printer from '../../assets/img/printer-icn2.png';
import shape from '../../assets/img/shape.png';
import { GenerateEncryptedToken } from "../../views/Common.js";
import { login_ } from "../../reducers/login.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsToggleOn } from 'react-icons/bs';
import { MdSettings } from 'react-icons/md';
import { BiSolidPencil } from 'react-icons/bi';
import { HiArrowLongLeft } from 'react-icons/hi2';
import { AiOutlineCopy } from 'react-icons/ai';
// import { useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { TfiReload } from 'react-icons/tfi';
import {
    initialState,
    rules_,
    updateRulesState,
    umountRulesState,
} from "../../reducers/rules.js";
import {
    permissions_,
    Getaccesspermissions,
    hasLoadedpermissions_,
} from "../../reducers/permissions.js";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { todosAction } from '../../reducers/reducers.js';
import demoone from '../../assets/img/demoone.PNG';
import demotwo from '../../assets/img/demotwo.PNG';
import logo from '../../assets/img/eventlogo.PNG';

const weekdayclass = makeStyles((theme) => ({
    weekdayborder: {
        border: "1px solid #fff",
        borderRadius: "0.25rem",
    },
    samepadd: {
        "& .MuiSelect-select": {
            padding: "12px auto !important"
        }
    }
}));
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    close: {
        padding: theme.spacing(0.5),
    },
}));

// Multiple select ================

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

//  emaillist section ====================

let cloneRulesInitialState = _.cloneDeep(initialState.data);

export default function Humsub() {
    const classes = useStyles();
    const textRef = useRef(null);
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");
    const location = useLocation();

    // datainput ------=======================================================
    const [loading, setLoading] = React.useState(true);
    const [permission, setpermission] = React.useState({});
    const [getCombineddayofweek, setCombineddayofweek] = React.useState([]);
    const [geteditdata, seteditdata] = useState({});
    const history = useHistory();
    const [getoperational, setoperational] = useState({});
    const [color, setColor] = React.useState("#ffffff");
    const [snackPack, setSnackPack] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [messageInfo, setMessageInfo] = React.useState(undefined);


    // form field store ---------------

    // form dropdown ===================
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);

    return (
        <div className="demoevent">
            <section className="content eventcontent">
                <div>
                    <CardGroup className='democard'>
                        <div className='mobileeventheading'>Food Counters at The Cobblestone Courtyard</div>
                        <Card>
                            <div className='eventheading'>Food Counters at The Cobblestone Courtyard</div>
                            <Card.Img variant="top" src={demoone} />
                            <Card.Body className='p-1'>
                                <div className='waittime row'>
                                    <div className="col-12 col-md-6">
                                        <div className='leftwaittime'>Queue length (approximate wait time)</div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className='rightwaittime'><span>as of Oct 14, 2023 3:45:05 pm </span> <TfiReload className="reload" /> </div>
                                    </div>
                                </div>
                                <div className='totaleventdata'>
                                    <div className='row'>
                                        <div className="col-6 col-md-6">
                                            <div className='lefttotal'>Tower</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='righttotal'>23 (15 minutes)</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='lefttotal'>Royal India</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='righttotal'>15 (12 minutes)</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='lefttotal'>Tandoor Indian</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='righttotal'>27 (23 minutes)</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='lefttotal'>Nukkad Indian Street Food</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='righttotal'>7 (3 minutes)</div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>

                        </Card>

                        <div className='mobileeventheading'>People in the Amphitheatre</div>
                        <Card>
                            <div className='eventheading'>People in the Amphitheatre</div>
                            <Card.Img variant="top" src={demotwo} />
                            <Card.Body className='p-1'>
                                <div className='waittime row'>
                                    <div className="col-12 col-md-6">
                                        <div className='leftwaittime'>Approximate number of people <br />
                                            Since Oct 14, 2023 8:30 am</div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className='rightwaittime'><span>as of Oct 14, 2023 3:45:05 pm </span> <TfiReload className="reload" /> </div>
                                    </div>
                                </div>
                                <div className='totaleventdata'>
                                    <div className='row'>
                                        <div className="col-6 col-md-6">
                                            <div className='lefttotal'>Total</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='righttotal'>4,186</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='lefttotal'>Main Entrance</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='righttotal'>3,200</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='lefttotal'>VIP Entrance</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='righttotal'>206</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='lefttotal'>Parking Entrance</div>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <div className='righttotal'>780</div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </div>

            </section>
        </div>
    )

}

