import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardBackspace, ExpandMore } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { States,Countries } from "../../config";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import _ from "lodash";
import InputLabel from "@material-ui/core/InputLabel";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputMask from "react-input-mask";

import { useSelector, useDispatch } from "react-redux";
import {
  sensors_,
  sensorsHasLoaded_,
  sensorServiceThunk,
} from "../../reducers/sensors";

const useStyles = makeStyles(styles);

const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "red",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white,
    },
  },
}));
export default function EditManufacturer() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const helperTestClasses = helperTextStyles();
  const loginDetails = useSelector(login_);
  const ParkID = _.get(loginDetails, ["parkID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [ManufacturerName, SetManufacturerName] = React.useState("");
  const [ManufacturerID, SetManufacturerID] = React.useState("");
  const [ManufacturerDate, setManufacturerDate] = React.useState("");
  const [erremailaddress, seterremailaddress] = React.useState("");
  const [errphonenumber, seterrphonenumber] = React.useState("");
  const [webSite, setWebSite] = useState("");
  const [emailID, setEmailID] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const userid = _.get(loginDetails, ["UserID"], "");
  const firstName = _.get(loginDetails, ["FirstName"], "");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const assetID = urlParams.get("id");
  React.useEffect(() => {
    var url = `api/park/${ParkID}/manufacturer?manufacturerID=${assetID}`;
    smartParkService
      .get(url, {
        headers: {
          userid: userid,
        },
      })
      .then(async (res, err) => {
        if (res.data.status == 200) {
          var {
            ManufacturerID,
            ManufacturerDate,
            Address1,
            Address2,
            City,
            State,
            Zip,
            Country,
            ManufacturerName,
            PhoneNumber,
            Email,
            WebSite
          } = res.data.data;
          setManufacturerDate(ManufacturerDate);
          SetManufacturerID(ManufacturerID);
          SetManufacturerName(ManufacturerName);
          setCountry(Country);
          setState(State);
          setZip(Zip);
          setCity(City);
          setPhoneNumber(PhoneNumber);
          setAddress1(Address1);
          setAddress2(Address2);
          setEmailID(Email);
          setWebSite(WebSite);
        }
      });
  }, []);

  const Save = () => {
    if(validation()){
    var dataObj = {};
    dataObj.ManufacturerID = ManufacturerID;
    dataObj.ManufacturerName = ManufacturerName;
    dataObj.ManufacturerDate = ManufacturerDate;
    dataObj.Address1 = address1;
    dataObj.Address2 = address2;
    dataObj.City = city;
    dataObj.State = state;
    dataObj.Zip = zip;
    dataObj.Country = country;
    dataObj.PhoneNumber = phoneNumber;
    dataObj.Email = emailID;
    dataObj.WebSite = webSite;
    dataObj.InsertedBy = firstName;
    // dataobj.InsertedUserID = userid;
    var url = "api/park/" + ParkID + "/updatemanufacturer?manufacturerID="+assetID;
    smartParkService
      .post(url, dataObj, {
        headers: {
          userid: userid,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          history.push("/Manufacturer");
        }
        else if (res.status === 401 || res.status === 403) {
          alert("You do not have permission to update manufacturer details");  
        }
      })
      .catch(() => {
        alert("Error occurred while updating the manufacturer details");
      });
    }
  };
  const validation = () => {
    var requiredFields = true;
    if (emailID !== "") {
      var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!regexEmail.test(emailID)) {
        seterremailaddress("Invalid email address");
        requiredFields = false;
      }
    }
    if (
      phoneNumber !== "" &&
      phoneNumber.replaceAll("-", "").trim().length !== 10
    ) {
      seterrphonenumber("Please Enter 10 Digit PhoneNumber");
      requiredFields = false;
    }
    return requiredFields;
  };
  return (
    <div className={classes.root}>
      <div>
        <section class="content-header">
          <h3 class="float-left">Asset</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                  Sensor
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <div class="content-middle">
            <div class="content-sub-header">
              <div>
                <Link
                  component="button"
                  onClick={() => history.push("/Manufacturer")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
              <div>
                <h3>Edit Item</h3>
              </div>
              <div class="display-xs-0">
                <Link
                  style={{ visibility: "hidden" }}
                  component="button"
                  onClick={() => history.push("/AssetManagement")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
            </div>
            <Paper className={classes.paper + " " + classes.withStripe}>
              <div class="form-body">
                <Grid container spacing={3} className={classes.pad20}>
                  <Grid item xs={12} sm={12}>
                    <Select
                      fullWidth
                      value="Cedar Fork District Park"
                      label="Facility Name"
                    >
                      <MenuItem value="Cedar Fork District Park">
                        Cedar Fork District Park
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                        <Grid container spacing={3} className={classes.pad20}>
                          <Grid item xs={12} sm={6}>
                            <div class="d-flex alignCenter">
                              <TextField
                                variant="outlined"
                                size="small"
                                id="ManufacturerID"
                                value={ManufacturerID}
                                name="ManufacturerID"
                                label="Manufacturer ID"
                                fullWidth
                                onChange={(e) => {
                                  SetManufacturerID(e.target.value);
                                }}
                              />
                              &nbsp;
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div class="d-flex alignCenter">
                              <TextField
                                variant="outlined"
                                size="small"
                                id="ManufacturerName"
                                value={ManufacturerName}
                                name="ManufacturerName"
                                label="Manufacturer Name"
                                fullWidth
                                onChange={(e) => {
                                  SetManufacturerName(e.target.value);
                                }}
                              />
                              &nbsp;
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              label="Manufacturer Date"
                              value={ManufacturerDate}
                              fullWidth
                              onChange={(e) => {
                                setManufacturerDate(e.target.value);
                              }}
                            ></TextField>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={address1}
                              fullWidth
                              label="Address 1"
                              onChange={(e) => {
                                setAddress1(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={address2}
                              fullWidth
                              label="Address 2"
                              onChange={(e) => {
                                setAddress2(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={city}
                              fullWidth
                              label="City"
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                          <div class="d-flex alignCenter">     
                                <Autocomplete
                                  size="small"
                                  id="combo-box-demo"
                                  options={States.map(
                                    (option) => option.StateName
                                  )}
                                  onChange={(event, value) =>{
                                    if(value){
                                    setState(value+"")
                                    }
                                  }
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="State"
                                      variant="outlined"
                                      onChange={(e)=>{setState(e.target.value)}}
                                    />
                                  )}
                                  value={state+""}
                                />
                            
                              &nbsp;
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={zip}
                              fullWidth
                              label="Zip Code"
                              onChange={(e) => {
                                setZip(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                          <div class="d-flex alignCenter">     
                                <Autocomplete
                                  size="small"
                                  id="combo-box-demo"
                                  options={Countries}
                                  onChange={(event, value) =>{
                                    if(value){
                                    setCountry(value+"")
                                    }
                                  }
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country"
                                      variant="outlined"
                                    />
                                  )}
                                  value={country+""}
                                />
                            
                              &nbsp;
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                          <InputMask
                              mask="999-999-9999"
                              fullWidth
                              value={phoneNumber}
                              onChange={(e) => {
                                setPhoneNumber(e.target.value);
                              }}
                              maskChar=""
                            >
                              {() => (
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  required
                                  fullWidth
                                  id="username"
                                  name="username"
                                  helperText={errphonenumber}
                                  FormHelperTextProps={{
                                    classes: helperTestClasses,
                                  }}
                                  label="Phone"
                                  onClick={() => {
                                    seterrphonenumber("");
                                  }}
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={emailID}
                              fullWidth
                              label="Email"
                              helperText={erremailaddress}
                              FormHelperTextProps={{
                                classes: helperTestClasses,
                              }}
                              onChange={(e) => {
                                setEmailID(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={webSite}
                              fullWidth
                              label="Web Site"
                              onChange={(e) => {
                                setWebSite(e.target.value);
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                        </Grid>
                        <br />
                  </Grid>
                </Grid>
              </div>
              <div class="form-footer">
                <Button
                  size="sm"
                  color="primary"
                  variant="contained"
                  className={classes.btnRound}
                  onClick={Save}
                >
                  SAVE
                </Button>
                &nbsp;&nbsp;
                <Button
                  size="sm"
                  variant="outlined"
                  className={classes.btnRound}
                  onClick={() => {
                    history.push("/Manufacturer");
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </Paper>
          </div>
        </section>
      </div>
    </div>
  );
}
