import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import smartParkService from "../../axios";
import { login_, token_ } from "../../reducers/login";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const localTimeZoneOffset =
  (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;
const getConfig = (floodData) => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: "areaspline",
    events: {
      load: function (event) {
        event.target.reflow();
      },
    },
  },
  showInLegend: true,
  rangeSelector: {
    selected: 1,
    enabled: false, // To hide the zoom 1m 3m and Input fields
  },
  height: "100%",
  xAxis: {
    type: "datetime",
    labels: {
      format: "{value:%l:%M %p}",
    },
  },
  yAxis: {
    title: {
      text: "Water Level (ft)",
    },
    resize: {
      enabled: true,
    },
    tickInterval: 2,
    max: 350,
    min: 200,
  },
  series: prepareGraphPlot(floodData),
  tooltip: {
    split: true,
    xDateFormat: "%A, %b %e, %I:%M %p",
    pointFormatter: function () {
      var point = this;
      return (
        '<span style="color:' +
        point.color +
        '">\u25CF</span> Water Level <b>' +
        point.y.toFixed(2) +
        "</b>(ft)</b><br/>"
      );
    },
  },
});

function prepareGraphPlot(data) {
  try {
    return {
      name: "Water Level",
      data: (function () {
        var ohlcData = [];

        let oldDate = Number(data[0]["date"] - localTimeZoneOffset);
        for (var j = 0; j < data.length; j++) {
          let currentDate = Number(data[j]["date"] - localTimeZoneOffset);
          var Difference_In_Time = currentDate - oldDate;
          var Difference_In_Days = Math.floor(
            Difference_In_Time / (1000 * 3600 * 24)
          );

          if (Difference_In_Days >= 1) {
            var oldTimeInDateFormat = new Date(oldDate);
            for (var i = 1; i < Difference_In_Days; i++) {
              oldTimeInDateFormat.setDate(oldTimeInDateFormat.getDate() + 1);
              var oldTimeInDateInTimeFormat = oldTimeInDateFormat.getTime();
              ohlcData.push([
                oldTimeInDateInTimeFormat - localTimeZoneOffset, // date
                null,
              ]);
            }
          }

          oldDate = currentDate;
          ohlcData.push([
            currentDate, // date
            data[j]["distance"], // water level
          ]);
        }
        return ohlcData;
      })(),
    };
  } catch (error) {
    console.log("Error in prepareGraphPlot(). Exception Message: " + error);
  }
}
function FloodChartMCP({ start, end, dataLoaded, exportParkid, graphData }) {
  const parkid = "c7a1d85f-8168-4e64-8e38-116a0624bfeb";
  const [loading, setLoading] = React.useState(true);
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [waterlevelData, setwaterlevelData] = useState([]);
  const loadingHandler = (state) => {
    setLoading(state);
    if (dataLoaded) {
      dataLoaded(state);
    }
  };

  useEffect(() => {
    getData();
  }, [start, end]);
  useEffect(() => {
    if (exportParkid === parkid) {
      graphData(waterlevelData);
    }
  }, [exportParkid]);

  const getData = () => {
    loadingHandler(true);
    let startDate = start || new Date("01/01/2020").toISOString();
    let endDate = end || new Date().toISOString();
    let url = `api/citizen/ubidots/${parkid}/flood?startDate=${startDate}&endDate=${endDate}`;
    smartParkService
      .get(url)
      .then((res) => {
        if (res) {
          setwaterlevelData(res.data.data);
          loadingHandler(false);
        } else {
          console.log("FloodChart else error: ");
        }
      })
      .catch((error) => {
        console.error("FloodChart error: ", error);
        loadingHandler(false);
      });
  };

  return (
    <div className="App">
      {loading ? (
        <ClipLoader loading={loading} css={override} size={60} />
      ) : (
        <>
          <HighchartsReact
            highcharts={HighStock}
            constructorType={"stockChart"}
            options={getConfig(waterlevelData)}
          />
        </>
      )}
    </div>
  );
}

export default FloodChartMCP;
