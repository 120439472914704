import React, { useEffect, useState } from "react";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import CurrentWeather from "./CurrentWeather";
import { getCookie, setCookie } from "../../../utils/cookieFunctions";
import weatherWidgetApi from "../api/weatherWidgetApi";
import { WeatherForecast } from "./WeatherForecast";

const useStyles = makeStyles({
  root: {
    background:
      "linear-gradient(180deg,#005889,#0072b1 56.77%,#6ccbff 141.56%)",
    "& .MuiCardContent-root": {
      padding: "8px !important",
    },
  },
});

const handleData = async (data) => {
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let index = 0;
  let newData = {};
  data.list.forEach((d) => {
    const icon = d.weather[0].icon.slice(0, 2);
    const day = new Date(d.dt * 1000).getDay();
    if (!newData[dayNames[day]]) {
      newData[dayNames[day]] = {
        idx: index,
        min: d.main.temp_min,
        max: d.main.temp_max,
        [`${icon}d`]: 1,
      };
      index++;
    } else {
      if (newData[dayNames[day]].min > d.main.temp_min) {
        newData[dayNames[day]].min = d.main.temp_min;
      }
      if (newData[dayNames[day]].max < d.main.temp_max) {
        newData[dayNames[day]].max = d.main.temp_max;
      }
    }
    if (newData[dayNames[day]][`${icon}d`]) {
      newData[dayNames[day]][`${icon}d`]++;
    } else {
      newData[dayNames[day]][`${icon}d`] = 1;
    }
  });
  const sortedWeatherData = Object.entries(newData).sort(
    ([, a], [, b]) => a.idx - b.idx
  );

  return sortedWeatherData;
};

const WeatherWidget = () => {
  const classes = useStyles();
  const [currentWeather, setCurrentWeather] = useState();
  const [forecastWeather, setForecastWeather] = useState();

  const fetchData = async () => {
    const currentData = getCookie("currentData");
    const forecastData = getCookie("forecastData");
    if (currentData && forecastData) {
      console.log("using existing data");
      setCurrentWeather(currentData);
      setForecastWeather(forecastData);
    } else {
      try {
        const res = await weatherWidgetApi();
        const sortedData = await handleData(res.data.forecastdata);
        setCurrentWeather(res.data.weatherdata);
        setForecastWeather(sortedData);
        setCookie({
          name: "currentData",
          fetchedon: res.fetchedOn.UNIX,
          value: res.data.weatherdata,
          minutes: 60,
        });
        setCookie({
          name: "forecastData",
          fetchedon: res.fetchedOn.UNIX,
          value: sortedData,
          minutes: 60,
        });
        console.log("Fetched new data");
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className="WeatherAndForecast">
          <CurrentWeather data={currentWeather} />
          <div className="WeatherAndForecast__container">
            {forecastWeather &&
              forecastWeather.map((data, index) => {
                return <WeatherForecast key={index} data={data} />;
              })}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default WeatherWidget;
