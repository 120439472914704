import React, { useCallback, useEffect, useState, useRef } from "react";
import { myArray, myfunction_v2 } from "../../Charts/CitizenFieldStatusChart";
import { waterlevelarry } from "../../Charts/CitizenFloodChart";
import { coutdata } from "../../Charts/CitizenTemperatureChart";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../assets/img/Logo.png";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Typography,
  ButtonGroup,
  MenuItem,
  Menu,
  ListItemIcon,
  Badge,
} from "@material-ui/core";
import {
  DateRange,
  ExpandMore,
  ArrowBackIos,
  HomeOutlined,
  NotificationsOutlined,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import {
  selectedFieldDetails_,
  selectedFieldCourts_,
  getFirstCourt_,
} from "../../../reducers/userDashboard";
import CourtStatusCard from "./../../../components/CourtStatusCard";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import FieldStatusChart from "../../Charts/CitizenFieldStatusChart";
import FloodChart from "../../Charts/CitizenFloodChart";
import TempBarChart from "../../Charts/CitizenTemperatureChart";
import PopularGraph from "../../Charts/PopularGraph";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MultiSelect from "react-multi-select-component";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import smartParkService from "../../../axios";
import "./widgetmoredetails.css";
import FloodChartMCP from "../../Charts/FloodChartMcp";
import TabsComponent from "../../../components/Tabs/Tabs";
import MafcPoolArea from "./MafcPoolArea";
import MafcWorkoutArea from "./MafcWorkoutArea";
import MafcEquipmentRoom from "./MafcEquipmentRoom";

const useStyles = makeStyles((theme) => ({
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  AppTitle: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  appBar: {
    background: "#fff",
    padding: theme.spacing(1),
  },
  section1: {
    background: "#f8f8f8",
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  mainContent: {
    marginTop: 90,
    padding: "60px",
    paddingTop: "0px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px",
    },
  },
  pageHeader: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  refreshButton: {
    background: "#fff !important",
    fontWeight: 600,
  },
  customButton: {
    textTransform: "capitalize",
    fontSize: "0.95em",
    fontWeight: 500,
    boxShadow: "none !important",
  },
  page_title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  flexDirection: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));
const localTimeZoneOffset =
  (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;

const millisecond_in_a_day = 86400000;
export default function WidgetMoreDetails(props) {
  const { cardType, setMoreDetails, getresval } = props;
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false);
  const [refreshDateString, setRefreshDateString] = useState();
  const [fieldStatus, setFieldStatus] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [NotifyPopUp, setNotifyPopUp] = useState(false);
  const selectedField = useSelector(selectedFieldDetails_);
  const fieldsoption = useSelector(selectedFieldCourts_);
  const firstFieldCourt = useSelector(getFirstCourt_);
  const [selected, setSelected] = useState([]);
  const [selectop, setSelectop] = useState([]);
  const [wethergraph, setWethergraph] = useState([]);
  const [getmcpcourtsdata, setMcpcourtsdata] = useState([]);
  const [floodta, setfloodta] = useState([]);
  const [getwaterData, setwaterData] = useState([]);
  const [exportparkid, setExportParkid] = useState("");
  const [loading, setLoading] = useState(true);
  const updateWethergraph = (data) => {
    setWethergraph(data);
  };
  const [state, setState] = useState({
    start: moment().subtract(30, "days").unix() * 1000,
    end: moment().unix() * 1000,
  });
  const { start, end } = state;
  const mafcTabs = [
    "Tennis and Racquetball",
    "Pool Area",
    "Workout Area",
    "Equipment Room",
  ];
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    var mcpdata;
    mcpdata = localStorage.getItem("mcpcourtsdta");
    var mcpdataArray = JSON.parse(mcpdata);
    setMcpcourtsdata(mcpdataArray);
  }, []);

  // useEffect(() => {
  //   var Sdate = new Date(start._d).getTime();
  //   var Edate = new Date(end._d).getTime();
  //
  //   let url =
  //     "/api/citizen/flood/region?startDate=" +
  //     (Sdate + localTimeZoneOffset) +
  //     "&endDate=" +
  //     (Edate + localTimeZoneOffset); // + "&region=" + region;
  //   smartParkService
  //     .get(url)
  //     .then((res) => {
  //       if (res) {
  //         // console.log(res.data.data);
  //         prepareGraphPlot(res.data.data);
  //       } else {
  //         console.log("FloodChart else error: ");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("FloodChart error: ", error);
  //     });
  //
  // }, [start, end])

  // console.log(getmcpcourtsdata);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => setNotifyPopUp(true)}>
        <ListItemIcon>&nbsp;</ListItemIcon>
        Notify Me
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 5 new notifications" color="inherit">
          <Badge badgeContent={5} color="error">
            <NotificationsOutlined />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
    </Menu>
  );
  // var Sdate = new Date(start._d).getTime();
  // var Edate = new Date(end._d).getTime();

  // let url =
  //   "/api/citizen/flood/region?startDate=" +
  //   (Sdate + localTimeZoneOffset) +
  //   "&endDate=" +
  //   (Edate + localTimeZoneOffset); // + "&region=" + region;
  // smartParkService
  //   .get(url)
  //   .then((res) => {
  //     if (res) {
  //       prepareGraphPlot(res.data.data);
  //     } else {
  //       console.log("FloodChart else error: ");
  //     }
  //   })
  //   .catch((error) => {
  //     console.error("FloodChart error: ", error);
  //   });

  const handleCallback = (start, end, rangeType) => {
    let startUnixtime =
      rangeType === "Last 24 Hours"
        ? start._i.getTime()
        : new Date(start).getTime();
    let endUnixtime =
      rangeType === "Last 24 Hours"
        ? end._i.getTime()
        : new Date(end).getTime();
    if (endUnixtime - startUnixtime <= millisecond_in_a_day) {
      let endDate = moment().unix() * 1000;
      endUnixtime = endDate;
      startUnixtime = endUnixtime - millisecond_in_a_day;
    }

    setState({ start: startUnixtime, end: endUnixtime });

    // export data for cfdp

    // let url =
    //   "/api/citizen/flood/region?startDate=" +
    //   startUnixtime +
    //   "&endDate=" +
    //   endUnixtime; // + "&region=" + region;
    // smartParkService
    //   .get(url)
    //   .then((res) => {
    //     if (res) {
    //       // console.log(res.data.data);
    //       prepareGraphPlot(res.data.data);
    //     } else {
    //       console.log("FloodChart else error: ");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("FloodChart error: ", error);
    //   });

    // export data for cfdp
  };

  const prepareGraphPlot = async (data) => {
    // console.log(data[0]["date"]);
    try {
      return {
        name: "Water Level",
        data: (function () {
          let ohlcData = [];
          let oldDate = Number(data[0]["date"]);
          for (var j = 0; j < data.length; j++) {
            let currentDate = Number(data[j]["date"]);
            let Difference_In_Time = currentDate - oldDate;
            let Difference_In_Days = Math.floor(
              Difference_In_Time / (1000 * 3600 * 24)
            );
            if (Difference_In_Days >= 1) {
              var oldTimeInDateFormat = new Date(oldDate);
              for (var i = 1; i < Difference_In_Days; i++) {
                oldTimeInDateFormat.setDate(oldTimeInDateFormat.getDate() + 1);
                let oldTimeInDateInTimeFormat = oldTimeInDateFormat.getTime();
                ohlcData.push([
                  oldTimeInDateInTimeFormat, // date
                  null,
                ]);
              }
            }

            oldDate = currentDate;
            ohlcData.push([
              currentDate, // date
              data[j]["level"] || data[j]["distance"], // water level
            ]);
          }
          // localStorage.setItem("floodata", JSON.stringify(ohlcData));
          // setwaterData(ohlcData);
          return ohlcData;
        })(),
      };
    } catch (error) {
      console.log("Error in prepareGraphPlot(). Exception Message: " + error);
      localStorage.setItem("floodata", JSON.stringify([]));
    }
  };
  const options = { year: "numeric", month: "long", day: "numeric" };
  // const formattedDate = date.toLocaleDateString("en-US", options);
  const label =
    new Date(start).toLocaleDateString("en-US", options) +
    " - " +
    new Date(end).toLocaleDateString("en-US", options);
  const getDateBasedOnRange = (rangeType) => {
    switch (rangeType) {
      case "Last 24 Hours":
        return [moment().subtract(1, "days").toDate(), moment().toDate()];
        return [moment().subtract(1, "days").toDate(), moment().toDate()];
      case "Last Week":
        return [moment().subtract(6, "days").toDate(), moment().toDate()];
        return [moment().subtract(6, "days").toDate(), moment().toDate()];
      case "Last Month":
        return [
          moment().subtract(1, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
          moment().subtract(1, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      case "Last 3 Month":
        return [
          moment().subtract(3, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
          moment().subtract(3, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      case "Last 6 Month":
        return [
          moment().subtract(6, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
          moment().subtract(6, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      case "Last Year":
        return [
          moment().subtract(12, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
          moment().subtract(12, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      default:
        return [];
    }
  };

  const refreshDate = useCallback(() => {
    const date = new Date();
    const time = date.toLocaleString("en-US", {
      hour: "numeric",
      hour12: true,
    });
    const month_short = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = month_short[date.getMonth()];
    const year = date.getFullYear();
    const str = `Updated at ${time} on ${month} ${year}`;
    setRefreshDateString(str);
  }, []);
  const refreshHandler = useCallback(() => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
      refreshDate();
    }, 1000);
  }, [refreshDate]);
  useEffect(() => {
    refreshDate();
  }, []);

  // console.log(firstFieldCourt);
  // console.log(fieldsoption);

  useEffect(() => {
    // console.log("MT", [firstFieldCourt, selected, fieldsoption, selectedField])
    if (fieldsoption.length > 0 && firstFieldCourt && selected.length == 0)
      setSelected([fieldsoption[0]]);
    setSelectop(fieldsoption);
  }, [firstFieldCourt, selected, fieldsoption, selectedField]);
  const updateFieldStatus = (fieldStatusData) => {
    setFieldStatus(JSON.stringify(fieldStatusData));
  };
  const getFields = (f) => {
    // setSelected([f[0]]);
    // setFields(f);
  };

  // Historical grapfh data
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const formattedTime = `${hours}:${minutes}${seconds !== "00" ? ":" + seconds : ":00"
      }`;
    return `${month}-${day}-${year} ${formattedTime}`;
  }
  function downloadCSV(data) {
    const sensorNames = [...new Set(data.map((sensor) => sensor.name))];
    let csvContent = "data:text/csv;charset=utf-8,";
    const headerRow = `DateTime,${sensorNames.join(",")}\n`;
    csvContent += headerRow;
    const maxRows = Math.max(...data.map((sensor) => sensor.data.length));
    const maxData = data.find((sensor) => sensor.data.length === maxRows);
    for (let i = 0; i < maxRows; i++) {
      const rowData = sensorNames
        .map((name) => {
          const sensorData = data.find((sensor) => sensor.name === name);
          return i < sensorData.data.length ? sensorData.data[i][1] : "";
        })
        .join(",");
      const timestamp = formatDate(maxData.data[i][2]) + "\t";
      const row = `${timestamp},${rowData}\n`;
      csvContent += row;
    }
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    const file_name = "data-" + moment().format("MMDDYYYY-HHmmss") + ".csv";
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  // Historical grapfh data

  const exportDataHandler = async (e) => {
    const parkid = e.currentTarget.getAttribute("data-key");
    setExportParkid(parkid);
  };
  const floodDataCsvDownload = async (data) => {
    const flooddata = await prepareGraphPlot(data);
    ccfdCSV(flooddata.data);
    setExportParkid("");
  };
  const downloadFloodExcelData = async (e) => {
    // setExportData(true);
    // setTimeout(() => {
    //   setExportData(false);
    // }, 3000);
    return;
    var Sdate = start;
    var Edate = end;
    setState({ start: start, end: end });
    let url =
      "/api/citizen/flood/region?startDate=" + Sdate + "&endDate=" + Edate; // + "&region=" + region;
    await smartParkService
      .get(url)
      .then(async (res) => {
        if (res) {
          // console.log(res.data.data);
          const data = prepareGraphPlot(res.data.data);
          ccfdCSV(data.data);
        } else {
          console.log("FloodChart else error: ");
        }
      })
      .catch((error) => {
        console.error("FloodChart error: ", error);
      });
  };
  //Crabtree Creek Flood Data
  async function ccfdCSV(data) {
    const csvRows = [];
    // Add headers
    csvRows.push(["date-time", "water level"]);
    // Add data rows
    data.forEach(([timestamp, value]) => {
      const formattedDate = formatDate(timestamp) + "\t";
      csvRows.push([formattedDate, value]);
    });
    // Convert rows to CSV content
    let csvContent =
      "data:text/csv;charset=utf-8," +
      csvRows.map((row) => row.join(",")).join("\n");
    // Create a download link and trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    const file_name =
      "flood-data-" + moment().format("MMDDYYYY-HHmmss") + ".csv";
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  //Crabtree Creek Flood Data
  // Temperature and Precipitation
  function tempandpre(graph) {
    const csvRows = [];
    // Add headers
    const temperatureData = graph[0]; // Replace with your temperature data array
    const precipitationData = graph[1]; // Replace with your precipi
    const result = combineArrays(temperatureData, precipitationData);
    csvRows.push(["date-time", "temperatureData", "precipitationData"]);
    for (let i = 0; i < result.length; i++) {
      csvRows.push([
        formatDate(result[i].time) + "\t",
        result[i].temp,
        result[i].precipitation,
      ]);
    }
    // for (let i = 0; i < temperatureData.length; i++) {
    //   const temperatureEntry = temperatureData[i];
    //   const precipitationEntry = precipitationData[i];
    //   const datetime = formatDate(temperatureEntry.UnixDateEpoch) + "\t"; // Convert temperature timestamp to datetime string

    //   const temperature = temperatureEntry?.value;
    //   const precipitation = precipitationEntry?.value;
    //   csvRows.push([datetime, temperature, precipitation]);
    // }
    // Convert rows to CSV content
    let csvContent =
      "data:text/csv;charset=utf-8," +
      csvRows.map((row) => row.join(",")).join("\n");
    // Create a download link and trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    const file_name =
      "weather-data-" + moment().format("MMDDYYYY-HHmmss") + ".csv";
    link.setAttribute("download", file_name);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function combineArrays(arr1, arr2) {
    const combined = [];
    const map1 = new Map(arr1.map((item) => [item.UnixDateEpoch, item.value]));
    const map2 = new Map(arr2.map((item) => [item.UnixDateEpoch, item.value]));

    const allTimes = new Set([...map1.keys(), ...map2.keys()]);

    allTimes.forEach((time) => {
      combined.push({
        time: time,
        temp: map1.has(time) ? map1.get(time) : "N.A",
        precipitation: map2.has(time) ? map2.get(time) : "N.A",
      });
    });

    return combined;
  }
  return (
    <>
      <main className={`adminbg ${classes.mainContent}`}>
        {cardType !== "mafctenniscourts" && (
          <Container fixed>
            <section className={`adminbg ${classes.section1}`}>
              <Box
                display="flex"
                className={classes.pageHeader}
                justifyContent={"space-between"}
                mb={4}
                mt={4}
              >
                <Box display="flex" alignItems={"center"}>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => setMoreDetails(false)}
                  >
                    <HomeOutlined className="HomeIcon" />
                  </IconButton>{" "}
                  &nbsp;&nbsp;
                  <Typography variant="h4" className={classes.page_title}>
                    {selectedField.header}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.flexDirection}
                >
                  <ButtonGroup size="small" color="primary" aria-label="">
                    <Button>
                      <DateRangePicker
                        initialSettings={{
                          startDate: new Date(start),
                          endDate: new Date(end),
                          maxDate: new Date(),
                          ranges: {
                            "Last 24 Hours":
                              getDateBasedOnRange("Last 24 Hours"),
                            "Last Week": getDateBasedOnRange("Last Week"),
                            "Last Month": getDateBasedOnRange("Last Month"),
                            "Last 3 Month": getDateBasedOnRange("Last 3 Month"),
                            "Last 6 Month": getDateBasedOnRange("Last 6 Month"),
                            "Last Year": getDateBasedOnRange("Last Year"),
                          },
                        }}
                        onCallback={handleCallback}
                      >
                        <div id="reportrange">
                          <DateRange />
                          <span>{label}</span> <ExpandMore />
                        </div>
                      </DateRangePicker>
                    </Button>
                  </ButtonGroup>
                  <Button
                    color="secondary"
                    className={classes.refreshButton}
                    onClick={refreshHandler}
                  >
                    REFRESH DATA
                  </Button>
                </Box>
              </Box>

              <Grid container spacing={4} className="allgraph">
                <CourtStatusCard
                  cardType={cardType}
                  cardStyle="seperate"
                  actions
                  getFields={getFields}
                  refresh={refresh}
                  getresval={getresval}
                  dispatchEvent={true}
                />
                {selectedField?.graphs.includes("Flood") ? (
                  <Grid item xs={12}>
                    <Card elevation={8} className="cardgraphset">
                      <CardHeader
                        title={
                          selectedField.header !== "Morrisville Community Park"
                            ? "Crabtree Creek Flood Data"
                            : "Morrisville Community Park Flood Data"
                        }
                        action={
                          <Button
                            data-key={selectedField.parkid}
                            color="primary"
                            variant="contained"
                            aria-label="ExportData"
                            disabled={loading}
                            onClick={(e) => exportDataHandler(e)}
                          >
                            Export Data
                          </Button>
                        }
                      ></CardHeader>
                      <CardContent>
                        {fieldsoption.length > 0 ? (
                          selectedField.header ===
                            "Morrisville Community Park" ? (
                            <FloodChartMCP
                              start={start}
                              end={end}
                              exportParkid={exportparkid}
                              dataLoaded={(e) => {
                                setLoading(e);
                              }}
                              graphData={(data) => {
                                floodDataCsvDownload(data);
                              }}
                            />
                          ) : (
                            <FloodChart
                              end={end}
                              start={start}
                              exportParkid={exportparkid}
                              dataLoaded={(e) => {
                                setLoading(e);
                              }}
                              graphData={(data) => {
                                floodDataCsvDownload(data);
                              }}
                            />
                          )
                        ) : (
                          <div>Loading..</div>
                        )}
                        {/* {selectedField.header ===
                          "Morrisville Community Park" &&
                        fieldsoption.length > 0
                          ? "hi"
                          : "hello"} */}
                        {/* {fieldsoption.length > 0 ? (
                          <FloodChart
                            selectedDateRange={{ start: start, end: end }}
                            selectionChanged={new Date().getTime()}
                          />
                        ) : (
                          <div>Loading..</div>
                        )} */}
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  <></>
                )}
                {selectedField?.graphs.includes("popular") ? (
                  <Grid item xs={12}>
                    {selectedField.parkid ==
                      "c7a1d85f-8168-4e64-8e38-116a0624bfeb" ? (
                      <>
                        {getmcpcourtsdata.length > 0 &&
                          getmcpcourtsdata[0]?.court_api_id ? (
                          <PopularGraph
                            selectedDateRange={{ start: start, end: end }}
                            selectedCourt={getmcpcourtsdata[0]?.court_api_id}
                          />
                        ) : (
                          <div>Loading..</div>
                        )}
                      </>
                    ) : (
                      <>
                        {fieldsoption.length > 0 &&
                          selected[0]?.court_api_id ? (
                          <PopularGraph
                            selectedDateRange={{ start: start, end: end }}
                            selectedCourt={selected[0]?.court_api_id}
                          />
                        ) : (
                          <div>Loading..</div>
                        )}
                      </>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}
                {selectedField?.graphs.includes("Historical") ? (
                  <Grid item xs={12}>
                    <Card elevation={8} className="cardgraphset">
                      <CardHeader
                        title="Historical Field Data"
                        action={
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="selectboxcss"
                          >
                            <MultiSelect
                              className={`${classes.Hgt30} multiselectbox`}
                              options={fieldsoption}
                              value={selected}
                              onChange={setSelected}
                            />
                            <Button
                              color="primary"
                              variant="contained"
                              aria-label="ExportData"
                              onClick={() => downloadCSV(myfunction_v2())} //myArray)}
                            >
                              Export Data
                            </Button>
                          </Box>
                        }
                      ></CardHeader>
                      <CardContent>
                        {fieldsoption.length > 0 ? (
                          <FieldStatusChart
                            selectedFileds={selected}
                            selectedDateRange={{ start: start, end: end }}
                            selectionChanged={new Date().getTime()}
                            updateFieldStatus={updateFieldStatus}
                          />
                        ) : (
                          <div>Loading..</div>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  <></>
                )}

                {selectedField?.graphs.includes("Temperature") ? (
                  <Grid item xs={12}>
                    <Card elevation={8} className="cardgraphset">
                      <CardHeader
                        title="Average Temperature and Precipitation"
                        subheader="( Precipitation in inches)"
                        action={
                          <Button
                            color="primary"
                            variant="contained"
                            aria-label="settings"
                            onClick={() => tempandpre(wethergraph)}
                          >
                            Export Data
                          </Button>
                        }
                      ></CardHeader>
                      <CardContent>
                        {fieldsoption.length > 0 ? (
                          <TempBarChart
                            selectedDateRange={{ start: start, end: end }}
                            selectionChanged={new Date().getTime()}
                            parkid={selectedField.parkid}
                            updateWethergraph={updateWethergraph}
                          />
                        ) : (
                          <div>Loading..</div>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </section>
          </Container>
        )}
        {cardType === "mafctenniscourts" && (
          <Grid container>
            <Container fixed>
              <section
                style={{ width: "100%" }}
                className={`adminbg ${classes.section1}`}
              >
                <Box
                  display="flex"
                  className={classes.pageHeader}
                  justifyContent={"space-between"}
                  mb={4}
                  mt={4}
                >
                  <Box display="flex" alignItems={"center"}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => setMoreDetails(false)}
                    >
                      <HomeOutlined className="HomeIcon" />
                    </IconButton>{" "}
                    &nbsp;&nbsp;
                    <Typography variant="h4" className={classes.page_title}>
                      {selectedField.header}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.flexDirection}
                  >
                    <ButtonGroup size="small" color="primary" aria-label="">
                      <Button>
                        <DateRangePicker
                          initialSettings={{
                            startDate: new Date(start),
                            endDate: new Date(end),
                            maxDate: new Date(),
                            ranges: {
                              "Last 24 Hours":
                                getDateBasedOnRange("Last 24 Hours"),
                              "Last Week": getDateBasedOnRange("Last Week"),
                              "Last Month": getDateBasedOnRange("Last Month"),
                              "Last 3 Month":
                                getDateBasedOnRange("Last 3 Month"),
                              "Last 6 Month":
                                getDateBasedOnRange("Last 6 Month"),
                              "Last Year": getDateBasedOnRange("Last Year"),
                            },
                          }}
                          onCallback={handleCallback}
                        >
                          <div id="reportrange">
                            <DateRange />
                            <span>{label}</span> <ExpandMore />
                          </div>
                        </DateRangePicker>
                      </Button>
                    </ButtonGroup>
                    <Button
                      color="secondary"
                      className={classes.refreshButton}
                      onClick={refreshHandler}
                    >
                      REFRESH DATA
                    </Button>
                  </Box>
                </Box>

                <Grid container spacing={4} className="allgraph">
                  <Grid
                    style={{
                      display:
                        window.location.search === "?future=1"
                          ? "block"
                          : "none",
                    }}
                    item
                    xs={12}
                  >
                    <TabsComponent
                      tabsList={mafcTabs}
                      setActiveTab={(e) => {
                        setSelectedTab(e);
                      }}
                    />
                  </Grid>
                  {selectedTab === 0 && (
                    <>
                      <CourtStatusCard
                        cardType={cardType}
                        cardStyle="seperate"
                        actions
                        getFields={getFields}
                        refresh={refresh}
                        getresval={getresval}
                        dispatchEvent={true}
                      />
                      {selectedField?.graphs.includes("Flood") && (
                        <Grid item xs={12}>
                          <Card elevation={8} className="cardgraphset">
                            <CardHeader
                              title="Crabtree Creek Flood Data"
                              action={
                                <Button
                                  color="primary"
                                  variant="contained"
                                  aria-label="ExportData"
                                  onClick={() => downloadFloodExcelData()}
                                >
                                  Export Data
                                </Button>
                              }
                            ></CardHeader>
                            <CardContent>
                              {fieldsoption.length > 0 ? (
                                <FloodChart
                                  selectedDateRange={{ start: start, end: end }}
                                  selectionChanged={new Date().getTime()}
                                />
                              ) : (
                                <div>Loading..</div>
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                      {selectedField?.graphs.includes("popular") && (
                        <Grid item xs={12}>
                          {selectedField.parkid ==
                            "c7a1d85f-8168-4e64-8e38-116a0624bfeb" ? (
                            <>
                              {getmcpcourtsdata.length > 0 &&
                                getmcpcourtsdata[0]?.court_api_id ? (
                                <PopularGraph
                                  selectedDateRange={{ start: start, end: end }}
                                  selectedCourt={
                                    getmcpcourtsdata[0]?.court_api_id
                                  }
                                />
                              ) : (
                                <div>Loading..</div>
                              )}
                            </>
                          ) : (
                            <>
                              {fieldsoption.length > 0 &&
                                selected[0]?.court_api_id ? (
                                <PopularGraph
                                  selectedDateRange={{ start: start, end: end }}
                                  selectedCourt={selected[0]?.court_api_id}
                                />
                              ) : (
                                <div>Loading..</div>
                              )}
                            </>
                          )}
                        </Grid>
                      )}
                      {selectedField?.graphs.includes("Historical") && (
                        <Grid item xs={12}>
                          <Card elevation={8} className="cardgraphset">
                            <CardHeader
                              title="Historical Field Data"
                              action={
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="selectboxcss"
                                >
                                  <MultiSelect
                                    className={`${classes.Hgt30} multiselectbox`}
                                    options={fieldsoption}
                                    value={selected}
                                    onChange={setSelected}
                                  />
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    aria-label="ExportData"
                                    onClick={() => downloadCSV(myfunction_v2())} //myArray)}
                                  >
                                    Export Data
                                  </Button>
                                </Box>
                              }
                            ></CardHeader>
                            <CardContent>
                              {fieldsoption.length > 0 ? (
                                <FieldStatusChart
                                  selectedFileds={selected}
                                  selectedDateRange={{ start: start, end: end }}
                                  selectionChanged={new Date().getTime()}
                                  updateFieldStatus={updateFieldStatus}
                                />
                              ) : (
                                <div>Loading..</div>
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                      {selectedField?.graphs.includes("Temperature") && (
                        <Grid item xs={12}>
                          <Card elevation={8} className="cardgraphset">
                            <CardHeader
                              title="Average Temperature and Precipitation"
                              subheader="( Precipitation in inches)"
                              action={
                                <Button
                                  color="primary"
                                  variant="contained"
                                  aria-label="settings"
                                  onClick={() => tempandpre(wethergraph)}
                                >
                                  Export Data
                                </Button>
                              }
                            ></CardHeader>
                            <CardContent>
                              {fieldsoption.length > 0 ? (
                                <TempBarChart
                                  selectedDateRange={{ start: start, end: end }}
                                  selectionChanged={new Date().getTime()}
                                  parkid={selectedField.parkid}
                                  updateWethergraph={updateWethergraph}
                                />
                              ) : (
                                <div>Loading..</div>
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                    </>
                  )}
                  {selectedTab === 1 && (
                    <Grid item xs={12}>
                      <MafcPoolArea />
                    </Grid>
                  )}
                  {selectedTab === 2 && (
                    <Grid item xs={12}>
                      <MafcWorkoutArea />
                    </Grid>
                  )}
                  {selectedTab === 3 && (
                    <Grid item xs={12}>
                      <MafcEquipmentRoom defaultSize="590px" />
                    </Grid>
                  )}
                </Grid>
              </section>
            </Container>
          </Grid>
        )}
      </main>
    </>
  );
}
