import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../components/components.js";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Search } from "@material-ui/icons";
import smartParkService from "../../axios";

const useStyles = makeStyles(styles);
export default function SelectSMSNotify(props) {
  const [message, setMessage] = React.useState(props.rules?.SMS);
  const [MobileNumbersList, setMobileNumbersList] = React.useState([]);

  const prepareUserList = (value) => {
    if (value.length > 2) {
      smartParkService.get("api/UserName/" + value).then((res) => {
        if (res) {
          setMobileNumbersList(res.data.data);
        } else {
        }
      });
    } else {
      setMobileNumbersList([]);
    }
  };

  const onChangeNumber = (value) => {
    props.updateRuleStateObj("FromSMS", value);
  };

  const onChangeMessage = (e) => {
    setMessage(e.target.value);
    props.updateRuleStateObj("SMS", e.target.value);
  };
  const error = props.Errors;
  const classes = useStyles();
  return (
    <React.Fragment>
      <div class="stepper-row-content1">
        <div>
          <label>Notify to following users</label>
          <div class="customInputBase">
            <IconButton className={classes.iconButton} aria-label="menu">
              <Search />
            </IconButton>
            <Autocomplete
              multiple
              id="MobileNumbersList"
              options={MobileNumbersList.map(
                (option) => option.FirstName + "  (" + option.MobilePhone + ")"
              )}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder=""
                  onChange={(e) => {
                    prepareUserList(e.target.value);
                  }}
                />
              )}
              onChange={(event, value) => onChangeNumber(value)}
              defaultValue={props.rules?.FromSMS}
            />
          </div>
          <div style={{ color: "red", fontSize: "15px" }}>{error.FromSMS}</div>
        </div>
        <br />
        <div>
          <label>SMS message </label>
          <textarea
            class="fc-width100"
            rows="3"
            value={message}
            onChange={(e) => onChangeMessage(e)}
          ></textarea>
          <div style={{ color: "red", fontSize: "15px" }}>{error.SMSbody}</div>
          <br />
        </div>
      </div>
    </React.Fragment>
  );
}

const MobileNumbersList = [];
