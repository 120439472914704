import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import smartParkService from "../../axios";
import { useHistory } from "react-router-dom";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import _ from "lodash";
const ButtonSm = withStyles((theme) => ({
  root: {
    fontSize: 13,
    textTransform: "none",
    borderRadius: "100px",
  },
}))(Button);
const ModalDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      padding: "25px",
    },
  },
}))(Dialog);
export default function FormDialog(props) {
  // console.log(props);
  const history = useHistory();
  const loginDetails = useSelector(login_);
  const userid = _.get(loginDetails, ["UserID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [yes, setYes] = React.useState("");
  const [isValid, setIsvalid] = React.useState(true);
  const [enableRuleFlag, setEnableRuleFlag] = React.useState(false)
  const parkid = _.get(loginDetails, ["parkID"], "");
  const handleSubmit = async () => {
    if (yes.trim().toLowerCase() === "yes") {
      var perparefieldnamearray = () => {
        var fieldarray = [];
        for (var i = 0; i < props.status.length; i++) {
          if (props.status[i].isChecked == true) {
            fieldarray.push(props.status[i].deviceID);
          }
        }
        return fieldarray;
      };

      var deviceIDs = perparefieldnamearray();
      var deviceid = JSON.stringify(deviceIDs);
      var status = props.openclose;

      var perpareGatenamearray = async () => {
        var gatenamearray = [];
        for (var i = 0; i < props.status.length; i++) {
          if (props.status[i].isChecked == true) {
            gatenamearray.push(props.status[i].devicename);
          }
        }
        return gatenamearray;
      };
      var dataobj = {};
      dataobj.gates = await perpareGatenamearray();
      dataobj.deviceIDs = deviceIDs;
      dataobj.status = status;
      dataobj.LastEditedBy = _.get(loginDetails, ["FirstName"], "");

      dataobj.disableNotification = true;
      let search = window.location.search;
      let params = new URLSearchParams(search);

      if (params.get('adminmode') === "1")
        dataobj.disableNotification = false;

      var url = "api/park/" + parkid + "/updatedevice";
      smartParkService
        .post(url, dataobj, {
          headers: {
            userid: userid,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const respData = res.data;
            const errResp = respData && respData.length > 0 && respData.filter((obj) => {
              return obj.status !== 200
            })
            let errFieldNames = errResp && errResp.length > 0 && errResp.map((obj) => {
              return obj.gate;
            })
            if (errFieldNames && errFieldNames.length > 0) {
              // alert("Error occurred while changing " + errFieldNames.join(", ") + " gate(s) status");
            }
            else {
              if (props.openclose === "Closed" && enableRuleFlag) props.setEnableRule(false)
              if (props.openclose === "Open" && enableRuleFlag) props.setEnableRule(true)
            }
          } else {
          }
          setYes("");
          props.closePopup();
        })
        .catch((err) => {
          setYes("");
          props.closePopup();
          alert("Error occurred while updating device status");
          console.log(err);
        });
    } else {
      setIsvalid(false);
    }
  };
  return (
    <div>
      <ModalDialog open={props.show} aria-labelledby="gate-modal">
        <DialogTitle id="gate-modal-title">
          <strong>
            {props.openclose === "Closed" ? "Close" : "Open"} Gates at Cedar
            Fork District Park
          </strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div class="plainGrid fc-width50">
              <div class="plainGridHeader">
                <div class="plainGridRow">
                  <div class="plainGridHead" style={{ width: "700px" }}>
                    Device Name
                  </div>
                  <div class="plainGridHead" style={{ width: "700px" }}>
                    Current Status
                  </div>
                </div>
              </div>
              <div class="plainGridBody">
                {props.status.map(
                  (m) =>
                    m.isChecked == true && (
                      <div class="plainGridRow">
                        <div class="plainGridCell">{m.devicename}</div>
                        <div class="plainGridCell">{m.devicestatus}</div>
                      </div>
                    )
                )}
              </div>
            </div>
            <br />
            <p style={{ fontSize: "0.9rem" }}>
              Cedar Fork District Park sign will display these gates{" "}
              {props.openclose === "Closed" ? "closed" : "open"}.<br />
              <br />
              Please type "YES" below to confirm{" "}
              {props.openclose === "Closed" ? "closing" : "opening"} the
              selected gates
            </p>
            <br />
            <div class="divGridCell">
              <Checkbox
                color="primary"
                checked={enableRuleFlag}
                style={{ padding: "2px", marginRight: '10px' }}
                onChange={(e) => setEnableRuleFlag(e.target.checked)}
              />
              <span style={{ color: '#4e4a4a' }}> {props.openclose === "Closed" ? 'Disable' : 'Enable'} all the rules </span>
            </div>
            <br />
            <TextField
              placeholder="Type YES for confirmation"
              variant="outlined"
              size="small"
              color="primary"
              value={yes}
              onChange={(e) => setYes(e.target.value)}
            />
          </DialogContentText>
          {isValid ? "" : <div>Please Type YES for confirmation</div>}
        </DialogContent>
        <DialogActions>
          <ButtonSm
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            {props.openclose === "Closed" ? "Close Gates" : "Open Gates"}
          </ButtonSm>
          <ButtonSm
            size="small"
            color="primary"
            variant="outlined"
            onClick={props.closePopup}
          >
            Cancel
          </ButtonSm>
        </DialogActions>
      </ModalDialog>
    </div>
  );
}
