import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { GenerateEncryptedToken } from "../../views/Common";
import smartParkService from "../../axios";
import { useHistory } from "react-router-dom";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import _ from "lodash";
const ButtonSm = withStyles((theme) => ({
    root: {
        fontSize: 13,
        textTransform: "none",
        borderRadius: "100px",
    },
}))(Button);
const ModalDialog = withStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {
            padding: "25px",
        },
    },
}))(Dialog);
export default function ParkOpenCloseModal(props) {
    const history = useHistory();
    const loginDetails = useSelector(login_);
    const userid = _.get(loginDetails, ["UserID"], "");
    const token = useSelector(token_);
    smartParkService.defaults.headers.common["x-access-token"] = token;
    const [yes, setYes] = React.useState("");
    const [isValid, setIsvalid] = React.useState(true);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const currentparkID = props.currentparkID;

    function handleSubmit(popenclose) {
        console.log(popenclose);
        var statusopenclose;
        if (popenclose === "Open") {
            statusopenclose = popenclose;
        }
        if (popenclose === "Closed") {
            statusopenclose = popenclose;
        }
        console.log(statusopenclose);
        var requestBody = {
            status: statusopenclose,
        };
        if (yes.trim().toLowerCase() == "yes") {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            console.log(requestBody);
            // if (params.get('adminmode') === "1")
            var url = "api/park/" + currentparkID + "/facilitiesmanagement";
            var restoken = GenerateEncryptedToken();
            smartParkService
                .post(url, requestBody, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    // console.log(res);
                    if (res.status === 200) {
                        const respData = res.data;
                        const errResp =
                            respData &&
                            respData.length > 0 &&
                            respData.filter((obj) => {
                                return obj.status !== 200;
                            });
                        let errFieldNames =
                            errResp &&
                            errResp.length > 0 &&
                            errResp.map((obj) => {
                                return obj.gate;
                            });
                        if (errFieldNames && errFieldNames.length > 0) {
                            alert(
                                "Error occurred while changing " +
                                errFieldNames.join(", ") +
                                " gate(s) status"
                            );
                        }
                    } else {
                    }
                    setYes("");
                    props.closePopup();
                })
                .catch((err) => {
                    setYes("");
                    props.closePopup();
                    alert("Error occurred while updating device status");
                    console.log(err);
                });
            // } else {
            //     setIsvalid(false);
        }
    }
    return (
        <div>
            <ModalDialog open={props.show} aria-labelledby="gate-modal">
                <DialogTitle id="gate-modal-title">
                    <strong>
                        {props.openclose === "Closed" ? "Close" : "Open"} The{" "}
                        {props.nameofPark}
                    </strong>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div class="plainGrid fc-width50">
                            <div class="plainGridHeader">
                                <div class="plainGridRow">
                                    <div class="plainGridHead" style={{ width: "700px" }}>
                                        Park Name
                                    </div>
                                    <div class="plainGridHead" style={{ width: "700px" }}>
                                        Current Status
                                    </div>
                                </div>
                            </div>
                            <div class="plainGridBody">
                                <div class="plainGridRow">
                                    <div class="plainGridCell">{props.nameofPark}</div>
                                    <div class="plainGridCell">
                                        {props.currentparkstatus === true ? (
                                            <span>Open</span>
                                        ) : props.currentparkstatus === false ? (
                                            <span>Close</span>
                                        ) : (
                                            <span>Loading ...</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <p style={{ fontSize: "0.9rem" }}>
                            Please type "YES" below to confirm{" "}
                            {props.openclose === "Closed" ? "closing" : "opening"} the
                            selected Park
                        </p>
                        <br />
                        <TextField
                            placeholder="Type YES for confirmation"
                            variant="outlined"
                            size="small"
                            color="primary"
                            value={yes}
                            onChange={(e) => setYes(e.target.value)}
                        />
                    </DialogContentText>
                    {isValid ? "" : <div>Please Type YES for confirmation</div>}
                </DialogContent>
                <DialogActions>
                    <ButtonSm
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit(props.openclose)}
                    >
                        {props.openclose === "Closed" ? "Close Park" : "Open Park"}
                    </ButtonSm>
                    <ButtonSm
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={props.closePopup}
                    >
                        Cancel
                    </ButtonSm>
                </DialogActions>
            </ModalDialog>
        </div>
    );
}
