import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Link from "@material-ui/core/Link";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";
import { Edit, Delete } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import { confirmAlert } from "react-confirm-alert";
import smartParkService from "../../axios";
import Paper from "@material-ui/core/Paper";
import { Email, Sms, Add, ToggleOff, DeleteOutline } from "@material-ui/icons";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import "../../assets/scss/App.scss";
import styles from "../../components/components.js";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import { paperAutoAdjustIntervalInMS } from "../../config/index"
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import moment from "moment";
import _ from "lodash";
const useStyles = makeStyles(styles);

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

export default function Rules(props) {
  const [loading, setLoading] = React.useState(true);
  const [color, setColor] = React.useState("#ffffff");
  const classes = useStyles();
  const history = useHistory();
  const [permission, setpermission] = React.useState({});
  const [rows, setrows] = React.useState([]);
  const [load, setload] = React.useState(false);
  const loginDetails = useSelector(login_);
  const ParkID = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common['x-access-token'] = token;
  const routeAddField = () => {
    let path = "AddDevice";
    history.push(path);
  };

  const columns = [
    { field: "devicename", headerName: "Device Name", width: 250 },
    { field: "deviceType", headerName: "Device Type", width: 250 },
    { field: "ModelNo", headerName: "Model", width: 250 },
    {
      field: "insertedBy",
      headerName: "Inserted By",
      width: 250
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <div>
          {permission.delete == 1 ?
            <Delete
              style={{ color: "#03354b", fontSize: "1.2rem", cursor: "pointer" }}
              onClick={() => {
                Deletedevice(params.row.deviceID);
              }}
            />
            : ""}
        </div>
      ),
    },
  ];
  const Deletedevice = (id) => {
    confirmAlert({
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var url = "api/park/" + ParkID + "/device/";
            smartParkService.delete(url+ id,{
              headers: {
                'userid': userid
              }
            }).then((res, err) => {
              setload(load == true ? false : true);
              alert("Record Deleted Successfully");
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  React.useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);

  React.useEffect(() => {
    var url = "api/park/" + ParkID + "/device";
    smartParkService.get(url).then((res) => {
      if (res) {
        setrows(res.data.data);
      } else {
      }
    });
  }, [load]);
  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "Device";
    });
    setpermission(Dpermissions[0]);
  };
  setTimeout(() => {
    var element = document.getElementsByClassName("deviceGrid__SmartPark");
    if (element && element.length > 0) {
      var calculatedHeight = (element[0].clientHeight + 25) + "px";
      document.getElementById("devicePaper__SmartPark").style.height = calculatedHeight;
    }
  }, paperAutoAdjustIntervalInMS);
  return (
    <div className={classes.root}>
      {permission.read == 1 ? (
        <div className={classes.contentPage}>
          <section class="content-header">
            <h3 class="float-left">Devices</h3>
            <div class="float-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a class="active" href="#">
                    Devices
                  </a>
                </li>
              </ol>
            </div>
          </section>
          <section class="content">
            <Grid container>
              <div class="content-middle">
                <Grid item xs={12}>
                  <div class="btnRules">
                    {permission.create == 1 ?
                      <Link component="button" onClick={routeAddField}>
                        {" "}
                        <Add style={{ fontSize: 18 }} /> Add New Device
                      </Link>
                      : ""}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid item xs={12}>
                  <Paper id='devicePaper__SmartPark' className={classes.paper + " " + classes.withStripe}>
                    <div style={{ width: "100%", cursor: "pointer" }}>
                      <DataGrid
                        className="deviceGrid__SmartPark"
                        rows={rows}
                        columns={columns}
                        hideFooter={true}
                        autoHeight={true}
                        onCellClick={(e) => {
                          if (e.field != "Actions")
                            history.push("/EditDevice?id=" + e.row.deviceID);
                        }}
                      />
                    </div>
                  </Paper>
                </Grid>
              </div>
            </Grid>
          </section>
        </div>
      ) : { loading } ? (
        <ClipLoader color={color} loading={loading} css={override} size={60} />
      ) : (
        <div
          style={{
            color: "red",
            position: "absolute",
            top: "50%",
            left: "40%",
            fontSize: "20px",
            transform: "translate(" - (50 % ", ") - (50 % ")"),
          }}
        >
          "You do not have read permissions. Please contact Admin"
        </div>
      )}
    </div>
  );
}
