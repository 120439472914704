import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { login_ } from "../../reducers/login";
import { sensors_, sensorsHasLoaded_ } from "../../reducers/sensors";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import {
  Button,
  Grid,
  Link,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
} from "@material-ui/core";
import {
  Email,
  Sms,
  Search,
  Close,
  KeyboardBackspace,
  Cancel,
  AddCircle,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import styles from "../../components/components.js";
import SelectParks from "./SelectParks.js";
import SelectSensors from "./SelectSensors.js";
import SelectEmailNotify from "./SelectEmailNotify.js";
import SelectSMSNotify from "./SelectSMSNotify.js";
import SelectAutomaticActions from "./SelectAutomaticActions.js";
import smartParkService from "../../axios";
import {
  initialState,
  rules_,
  updateRulesState,
  umountRulesState,
} from "../../reducers/rules";
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import Summary from "./summary";

const useStyles = makeStyles(styles);

const useInputStyles = makeStyles((theme) => ({
  newRuleBlock: {
    width: "100%",

    "& .MuiOutlinedInput-root": {
      borderRadius: "100px",
    },
  },
}));

function getSteps() {
  return [
    "Select Park(s)",
    "Select Sensors",
    "Select Email Notification",
    "Select SMS Notification",
    "Select Automatic Actions",
  ];
}
const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "red",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.red,
    },
  },
}));

let cloneRulesInitialState = _.cloneDeep(initialState.data);
function AddRule({ width }) {
  const isSmallScreen = /xs|sm/.test(width);
  const stepperProps = {
    orientation: isSmallScreen ? "vertical" : "horizontal",
  };
  const loginDetails = useSelector(login_);
  const [permission, setpermission] = React.useState({});
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const sensorList = useSelector(sensors_);
  const dispatch = useDispatch();
  const helperTestClasses = helperTextStyles();
  const rules = useSelector(rules_);
  const [sensorsList, setsensorsList] = React.useState([]);
  const rulesRef = useRef(_.cloneDeep(rules));
  const classes = useStyles();
  const history = useHistory();
  const [error, seterror] = React.useState({});
  const inputClasses = useInputStyles();
  const steps = getSteps();
  const { pathname } = useLocation();
  const [showhide, setshowhide] = React.useState(false);

  React.useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);
  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "RuleEngine";
    });
    setpermission(Dpermissions[0]);
  };
  useEffect(() => {
    if (pathname === "/EditRole") {
      rulesRef.current = rules;
    }
    return () => {
      dispatch(umountRulesState());
    };
  }, []);
  //Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [rule, setRule] = React.useState(rules.RuleName);

  const isStepOptional = (step) => {
    return step === 2;
  };

  const isStepFailed = (step) => {
    return step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const ruleNameOnChange = (e) => {
    setRule(e.target.value);
    updateRuleStateObj("RuleName", e.target.value);
  };

  const updateRuleStateObj = (key, Value) => {
    const rules = _.cloneDeep(rulesRef.current);
    rules[key] = Value;
    rulesRef.current = rules;
  };

  const updateRulesReduxState = () => {
    dispatch(updateRulesState(rulesRef.current));
  };

  const validate = () => {
    var requiredFields = true;
    var validateerror = {};
    const rules = rulesRef.current;
    if (activeStep == 0) {
      if (!rules.RuleName) {
        requiredFields = false;
        validateerror.RuleName = "Please enter rule name";
        console.log("Please fill RuleName");
      }
      if (!rules.ParkName) {
        requiredFields = false;
        validateerror.ParkName = "Please select a park";
        console.log("Please fill ParkName");
      }
    }
    if (activeStep == 1) {
      if (!rules.RuleName) {
        requiredFields = false;
        validateerror.RuleName = "Please enter rule name";
      }
      if (rules.Rule.length > 0) {
        console.log("error");
        let data = rules.Rule;
        for (var i = 0; i < data.length; i++) {
          // if(data[i].Field.length<=0){
          // 	requiredFields = false;
          // 	console.log("Please enter fields");
          // }
          if (!data[i].MoistureCondition) {
            requiredFields = false;
            console.log("Please fill moisturecondition");
          }
          if (!data[i].Duration) {
            requiredFields = false;
            console.log("Please fill duration");
          }
          if (!data[i].MoistureValue) {
            requiredFields = false;
            console.log("Please fill moisturevalue");
          }
          if (i + 1 < data.length) {
            if (!data[i].NextCriteria) {
              requiredFields = false;
              console.log("Please fill nextciteria");
            }
          }
        }
        if (!requiredFields) {
          validateerror.sensordata =
            "Please select rule criteria(sensor name, moisture level,threshold duration,threshold value)";
        }
      }
    }
    if (activeStep == 2) {
      if (!rules.RuleName) {
        validateerror.RuleName = "Please enter rule name";
        requiredFields = false;
      }
      if (rules.Email.length <= 0) {
        requiredFields = false;
        validateerror.Email = "Please select users to be notified";
      }
      if (!rules.EmailBody) {
        requiredFields = false;
        validateerror.Emailbody = "Please enter email message";
      }
    }
    // if (activeStep == 3) {
    //   if (!rules.RuleName) {
    //     requiredFields = false;
    //     validateerror.RuleName = "Please enter rule name";
    //   }
    //   if (rules.FromSMS.length <= 0) {
    //     requiredFields = false;
    //     validateerror.FromSMS = "Please select users to be notified";
    //   }
    //   if (!rules.SMS) {
    //     requiredFields = false;
    //     validateerror.SMSbody = "Please enter SMS message";
    //   }
    // }
    if (activeStep == 4) {
      if (!rules.RuleName) {
        requiredFields = false;
        validateerror.RuleName = "Please enter rule Name";
        console.log("Please fill RuleName");
      }
      if (rules.Action.length > 0) {
        let data = rules.Action;
        console.log(data);
        for (var i = 0; i < data.length; i++) {
          // if(!data[i].fieldName){
          // 	requiredFields = false;
          // 	console.log("Please fill Field Name");
          // }
          if (!data[i].fieldStatus) {
            requiredFields = false;
            console.log("Please fill Field Status");
          }
        }
        if (!requiredFields) {
          validateerror.AutomaticActions =
            "Please select field name and action";
        }
      }
    }
    seterror(validateerror);
    return requiredFields;
  };
  const testnotification = () => {
    const rules = rulesRef.current;
    var email = rules.Email;
    var emailarray = [];
    var usernamearray = [];
    var subject = "Rule Engine Test Notification";
    var sensors = rules.Rule[0].Field.join();
    var emailhtml = rules.EmailBody;
    var emailstring = "";
    var string = "";
    for (var i = 0; i < rules.Rule.length; i++) {
      var sensors = rules.Rule[i].Field.join();
      string += i + 1 + ") Sensors -  " + sensors + " <br/>";
      string +=
        "&nbsp;&nbsp;&nbsp;Moisture level " +
        rules.Rule[i].MoistureValue +
        "​ the threshold during the last " +
        rules.Rule[i].Duration +
        " <br/>";
      string +=
        "&nbsp;&nbsp; Alert threshold  " +
        rules.Rule[i].MoistureCondition +
        " <br/>";
    }
    email.map((e) => {
      usernamearray.push(e.split("(")[0]);
      emailarray.push(e.split("(")[1].replace(")", ""));
    });
    for (var i = 0; i < emailarray.length; i++) {
      emailstring =
        "Hi " +
        usernamearray[i] +
        " ,</br>The Rule " +
        rules.RuleName +
        " is created for the " +
        rules.ParkID +
        "  with following configuration.<br/>";
      emailstring += string;
      var emailContent = {};
      emailContent.toEmail = emailarray[i];
      emailContent.subject = subject;
      emailContent.body = emailhtml;
      emailContent.html = emailstring;
      smartParkService.post("api/email", emailContent).then((res, err) => {
        if (res.data.status == 200) {
          alert("Emails Sent");
        }
      });
    }
  };
  React.useEffect(() => {
    smartParkService
      .get("api/park/" + parkid + "/allsensordata")
      .then((res, err) => {
        setsensorsList(res.data.data);
      });
  }, []);
  const handleNext = () => {
    if (activeStep == 4) {
      if (permission.update == 1) {
        handleSubmit();
      }
      else {
        alert("You do not have permission to update rule.");
        return false;
      }
    }
    updateRulesReduxState();
    if (validate()) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(skipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    updateRulesReduxState();
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = () => {
    const rules = rulesRef.current;

    try {
      let RuleID = rules.RuleID;
      let parkName = rules.ParkName;
      let ruleName = rules.RuleName;
      var ruleObject = _.cloneDeep(rules.Rule);
      let emailRecipiets = rules.Email;
      var emailBody = rules.EmailBody;
      var smsRecipiets = rules.FromSMS;
      let smsText = rules.SMS;
      var actions = _.cloneDeep(rules.Action);
      var currentSensors = sensorsList;
      // for (var i = 0; i < ruleObject.length; i++) {
      //   ruleObject[i].sensors = [];
      //   for (var j = 0; j < ruleObject[i].Field.length; j++) {
      //     const selectedField = currentSensors.filter(function (obj) {
      //       if (obj.sensorname === ruleObject[i].Field[j]) return obj;
      //     });
      //     if (selectedField.length) {
      //       ruleObject[i].sensors.push(selectedField[0].SensorCollectionID);
      //     } else {
      //       alert(
      //         "Invalid Field(s) are selected. Please clear and reselect the fields"
      //       );
      //       return false;
      //     }
      //   }
      // }

      // for (var i = 0; i < actions.length; i++) {
      //   actions[i].sensor = currentSensors.filter(function (obj) {
      //     if (obj.sensorname === actions[i].fieldName) return obj;
      //   })[0].SensorCollectionID;

      // }

      var Dataobject = {
        RuleID: RuleID,
        RuleName: ruleName,
        ParkID: parkid,
        ParkName: parkName,
        Rule: ruleObject,
        Email: emailRecipiets,
        EmailBody: emailBody,
        FromSMS: smsRecipiets,
        SMS: smsText,
        Action: actions,
      };
      smartParkService
        .post("api/park/" + parkid + "/ruleconfiguration", Dataobject, {
          headers: {
            userid: userid,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setshowhide(true);
            alert(ruleName + " rule is saved successfully.");
            // history.push("/Rules");
            //sendEmail(prepareEmailobject(emailobject), sensor, park)
          }
          else if (res.status === 401 || res.status === 403) {
            alert("You do not have permission to create the rule");
          }
          else {
            alert("Invalid User Data");
          }
        })
        .catch(() => {
          alert("Error occurred while creating the rule");
        });
    } catch (error) {
      console.log(error);
    }
  };
  const getStepList = (step, label) => {
    switch (step) {
      case 0:
        return (
          <li className={activeStep > step ? "stepActive" : ""}>
            <p>
              <i>{step}</i>
              {label}
            </p>
            {rules.ParkName && <span>{rules.ParkName}</span>}
          </li>
        );
      case 1:
        return (
          <li className={activeStep > step ? "stepActive" : ""}>
            <p>
              <i>{step}</i>
              {label}
            </p>
            {rules.Rule[0].Duration && <span>{rules.Rule[0].Duration}</span>}
            {rules.Rule[0].Field[0] && <span>{rules.Rule[0].Field[0]}</span>}
            {rules.Rule[0].MoistureCondition && (
              <span>{rules.Rule[0].MoistureCondition}</span>
            )}
            {rules.Rule[0].MoistureValue && (
              <span>{rules.Rule[0].MoistureValue}</span>
            )}
            {rules.Rule[0].NextCriteria && (
              <span>{rules.Rule[0].NextCriteria}</span>
            )}
          </li>
        );
      case 2:
        return (
          <li className={activeStep > step ? "stepActive" : ""}>
            <p>
              <i>{step}</i>
              {label}
            </p>
            {rules.Email.length > 0 && <span>{rules.Email}</span>}
          </li>
        );
      case 3:
        return (
          <li className={activeStep > step ? "stepActive" : ""}>
            <p>
              <i>{step}</i>
              {label}
            </p>{" "}
            {rules.FromSMS.length > 0 && <span>{rules.FromSMS}</span>}{" "}
          </li>
        );
      case 4:
        return (
          <li className={activeStep > step ? "stepActive" : ""}>
            <p>
              <i>{step}</i>
              {label}
            </p>
            {rules.Action.length && rules.Action[0].fieldName && (
              <span>{rules.Action[0].fieldName}</span>
            )}
            {rules.Action.length && rules.Action[0].fieldStatus && (
              <span>
                {rules.Action[0].fieldStatus == "C" && "closed"}{" "}
                {rules.Action[0].fieldStatus == "O" && "opened"}
              </span>
            )}{" "}
          </li>
        );
    }
  };
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <SelectParks
            updateRuleStateObj={updateRuleStateObj}
            rules={rules}
            Errors={error}
          />
        );
      case 1:
        return (
          <SelectSensors
            updateRuleStateObj={updateRuleStateObj}
            rules={rules}
            Errors={error}
          />
        );
      case 2:
        return (
          <SelectEmailNotify
            updateRuleStateObj={updateRuleStateObj}
            rules={rules}
            Errors={error}
          />
        );
      case 3:
        return (
          <SelectSMSNotify
            updateRuleStateObj={updateRuleStateObj}
            rules={rules}
            Errors={error}
          />
        );
      case 4:
        return (
          <SelectAutomaticActions
            updateRuleStateObj={updateRuleStateObj}
            rules={rules}
            Errors={error}
          />
        );
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Rules</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                  Rules
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <div className="content-middle rules-middle">
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <div class="content-sub-header">
                  <div>
                    <Link
                      component="button"
                      onClick={() => history.push("/Rules")}
                    >
                      {" "}
                      <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                    </Link>
                  </div>
                  <div>
                    <h3>Add New Rule</h3>
                  </div>
                  <div>
                    <Link
                      style={{ visibility: "hidden" }}
                      component="button"
                      onClick={() => history.push("/Rules")}
                    >
                      {" "}
                      <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                    </Link>
                  </div>
                </div>
                <Paper className={classes.paper}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        id="newRule"
                        label="New Rule Name"
                        className={inputClasses.newRuleBlock}
                        variant="outlined"
                        helperText={error.RuleName}
                        FormHelperTextProps={{ classes: helperTestClasses }}
                        value={rule}
                        onChange={(e) => ruleNameOnChange(e)}
                      />
                      {/* <CustomInput labelText="New Rule Name" formControlProps={{ fullWidth: true }} inputProps={{ 'aria-label': 'add new rule' }} /> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {permission.update == 1 ?
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.btnRound}
                          onClick={handleSubmit}
                        >
                          SAVE RULE
                        </Button> : ""}{" "}
                      &nbsp;&nbsp;
                      {showhide ? (
                        <Button
                          color="primary"
                          variant="outlined"
                          className={classes.btnRound}
                          onClick={testnotification}
                        >
                          TEST NOTIFICATION
                        </Button>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      {/* <Stepper activeStep={activeStep} orientation="vertical"> */}
                      <Stepper activeStep={activeStep} {...stepperProps}>
                        {steps.map((label, index) => {
                          const stepProps = {};
                          const labelProps = {};
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      <div class="pad20">
                        <div>
                          <Typography className={classes.instructions}>
                            {getStepContent(activeStep)}
                          </Typography>
                          <div class="stepper-footer">
                            <Button
                              size="sm"
                              color="primary"
                              variant="contained"
                              className={classes.btnRound}
                              disabled={activeStep === 0}
                              onClick={handleBack}
                            >
                              PREV
                            </Button>
                            <div>
                              &nbsp;&nbsp;&nbsp;
                              {activeStep < 5 ? (
                                <Button
                                  color="primary"
                                  size="sm"
                                  variant="contained"
                                  onClick={handleNext}
                                  className={classes.btnRound}
                                >
                                  {activeStep == steps.length - 1
                                    ? "FINISH & SAVE"
                                    : "NEXT"}
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} className="addruleItems">
                <Paper className={classes.paper + " " + classes.withStripe}>
                  <h6 className="summaryicon">Summary </h6>
                  <Summary />
                </Paper>
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    </div>
  );
}
AddRule.propTypes = {
  /** The name of the current breakpoint, for example "sm" */
  width: PropTypes.string.isRequired,
};

export default withWidth()(AddRule);
