import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";
import { Edit, Delete } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import { confirmAlert } from "react-confirm-alert";
import Select from "@material-ui/core/Select";
import smartParkService from "../../axios";
import Paper from "@material-ui/core/Paper";
import { Email, Sms, Add, ToggleOff, DeleteOutline } from "@material-ui/icons";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import "../../assets/scss/App.scss";
import styles from "../../components/components.js";
import moment from "moment";
import { paperAutoAdjustIntervalInMS } from "../../config/index"
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import _ from "lodash";
const useStyles = makeStyles(styles);

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

export default function Rules(props) {
  const [loading, setLoading] = React.useState(true);
  const [color, setColor] = React.useState("#ffffff");
  const classes = useStyles();
  const [load, setload] = React.useState(false);
  const loginDetails = useSelector(login_);
  const ParkID = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const [fieldID,setFieldID]= React.useState("0");
  const [fieldsList, setFieldsList] = React.useState([]);
  const [sensorList, setSensorList] = React.useState([]);
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const history = useHistory();
  const [permission, setpermission] = React.useState({});
  const [rows, setrows] = React.useState([]);
  const routeAddField = () => {
    let path = "AddSensor";
    history.push(path);
  };
  const setFieldRows=async (arrayList,fieldIDValue)=>{
    if(fieldIDValue!==0){
      setrows(arrayList.filter((obj)=>{
       return obj.fieldID===fieldIDValue
      }))
    }
    else{
      setrows(arrayList)
    }
  }
 React.useEffect(()=>{
    var url = "api/park/" + ParkID + "/fields";
    smartParkService
      .get(url,{
        headers: {
          userid: userid,
        },
      })
      .then((res) => {
        setFieldsList(res.data.data);
      })
      .catch(() => {
        alert("Error occurred while fetching the fields");
      });
  },[load])
  React.useEffect(()=>{
    var url = "api/park/" + ParkID + "/sensordata";
    smartParkService
      .get(url,{
        headers: {
          userid: userid,
        },
      })
      .then((res) => {
        setSensorList(res.data.data)
        setrows(res.data.data)
      })
      .catch(() => {
        alert("Error occurred while fetching the sensors");
      });
  },[load])
  const columns = [
    { field: "fieldName", headerName: "Field Name", width: 200 },
    { field: "SensorCollectionID", headerName: "Sensor ID", width: 250 },
    { field: "sensorname", headerName: "Sensor Name", width: 220 },
    {
      field: "lastReportedMoistureValue",
      headerName: "Last Reported Moisture",
      width: 220,
      valueFormatter: (params) => {
        if (params.value) {
          return parseFloat(params.value).toFixed(2);
        }
      },
    },
    { field: "batteryLevel", headerName: "Battery Level", width: 150 },
    {
      field: "lastReportedDateTime",
      headerName: "Last Reported Date Time",
      width: 250,
      valueFormatter: (params) => {
        if (params.value) {
          return moment(new Date(params.value)).format('MM/DD/YYYY hh:mm A');
        }
      },
    },

    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <div>
          {permission.delete == 1 ?
            <Delete
              style={{ color: "#03354b", fontSize: "1.2rem", cursor: "pointer" }}
              onClick={() => {
                Deletedevice(params.row.id);
              }}
            />
            : ""}
        </div>
      ),
    },
  ];

  setTimeout(() => {
    var element = document.getElementsByClassName("sensorGrid__SmartPark");
    if (element && element.length > 0) {
      var calculatedHeight = (element[0].clientHeight + 25) + "px";
      document.getElementById("sensorPaper__SmartPark").style.height = calculatedHeight;
    }
  }, paperAutoAdjustIntervalInMS);
  const Deletedevice = (id) => {
    confirmAlert({
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var url = `api/park/${ParkID}/field?fieldID=${fieldID}&&sensorID=${id}`;
            smartParkService
              .delete(url, {
                headers: {
                  userid: userid,
                },
              })
              .then((res, err) => {
                setload(load == true ? false : true);
                alert("Record Deleted Successfully");
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  React.useEffect(() => {
    if (permissionsHasLoaded) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);
  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "Sensor";
    });
    setpermission(Dpermissions[0]);
  };
  return (
    <div className={classes.root}>
      {permission.read == 1 ? (
        <div className={classes.contentPage}>
          <section class="content-header">
            <h3 class="float-left">Sensor</h3>
            <div class="float-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a class="active" href="#">
                    Sensor
                  </a>
                </li>
              </ol>
            </div>
          </section>
          <section class="content">
            <Grid container>
              <div class="content-middle" style={{ width: "95%" }}>
                <Grid item xs={12}>
                  <div class="btnRules">
                    {permission.create == 1 ?
                      <Link component="button" onClick={routeAddField}>
                        {" "}
                        <Add style={{ fontSize: 18 }} /> Add New Sensor
                      </Link>
                      : ""}
                  </div>
                </Grid>
                <br/>
                <Grid item xs={12}>
                  <div class="btnRules">
                  <Grid item xs={12} sm={12}>
                    <Select
                      fullWidth
                      value="Cedar Fork District Park"
                      label="Field Name"
                      value={fieldID}
                      onChange={(e) => {
                        setFieldID(e.target.value)
                        setFieldRows(sensorList,e.target.value)
                      }}
                    >
                      <MenuItem value={0}>All</MenuItem>
                      {fieldsList.length > 0
                          ? fieldsList.map((e) => {
                              return (
                                <MenuItem  name={e.FieldName} value={e.id}>{e.FieldName}</MenuItem>
                              );
                            })
                          : null}
                    </Select>
                  </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid item xs={12}>
                  <Paper id='sensorPaper__SmartPark' className={classes.paper + " " + classes.withStripe}>
                    <div style={{ width: "100%", cursor: "pointer" }}>
                      <DataGrid
                        className="sensorGrid__SmartPark"
                        rows={rows}
                        columns={columns}
                        hideFooter={true}
                        autoHeight={true}
                        onCellClick={(e) => {
                          if (e.field != "Actions"){
                           history.push(`/EditSensor?sensorid=${e.row.sensorID}`);
                        }}}
                      />
                    </div>
                  </Paper>
                </Grid>
              </div>
            </Grid>
          </section>
        </div>
      ) : { loading } ? (
        <ClipLoader color={color} loading={loading} css={override} size={60} />
      ) : (
        <div
          style={{
            color: "red",
            position: "absolute",
            top: "50%",
            left: "40%",
            fontSize: "20px",
            transform: "translate(" - (50 % ", ") - (50 % ")"),
          }}
        >
          "You do not have read permissions. Please contact Admin"
        </div>
      )}
    </div>
  );
}
