import React, { useEffect, useState, useCallback, useRef } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, styled } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "../../views/UserDashboard/FieldModel";
import Parkclose from "../../views/UserDashboard/CloseAllparkmodal";
import { MdToggleOn, MdToggleOff } from "react-icons/md";
import Cspgatemodal from "../../views/UserDashboard/GateModelCSP";
import CfdpgateModal from "../../views/UserDashboard/GateModel";
import MCPgatemodal from "../../views/UserDashboard/GateModelMCP";
import AdminFloodSensorStatus from "..//..//views/UserDashboard/Dashboard/AdminFloodSensorStatus";
import {
    Button,
    Card,
    CardHeader,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Box,
    CircularProgress,
    Grid,
    IconButton,
} from "@material-ui/core";
import { DirectionsOutlined, MoreVert } from "@material-ui/icons";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../../views/Common";
import userDashboard, {
    setSelectedField,
    fieldDetailsByType_,
    setFieldCourts,
} from "../../reducers/userDashboard";
import "./courtstatuscard.css";
import { BsFillBellFill } from "react-icons/bs";
import MCPmodal from "../../views/UserDashboard/MCPfieldModal";
import {
    permissions_,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import { object } from "prop-types";
import ToggleModal from "../Modals/ToggleModal";
import _ from "lodash";
import { login_ } from "../../reducers/login";
import { checkPermissions } from "../../utils/checkPermissions";
const useStyles = makeStyles((theme) => ({
    card: {
        position: "relative",
        height: "100%",
    },
    greentoggle: {
        position: "absolute",
        top: "-3px",
        right: "2px",
        "& svg": {
            fontSize: "1.2rem",
            color: "#53b953",
        },
    },
    redtoggle: {
        position: "absolute",
        top: "-3px",
        right: "2px",
        "& svg": {
            fontSize: "1.2rem",
            color: "#f76363",
        },
    },
    cardTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        marginTop: "-70px",
        position: "relative",
        padding: theme.spacing(0, 2),
        height: 60,
    },
    cardContent: {
        paddingTop: 32,
    },
    ht364: {
        height: 373,
        overflowY: "auto",
        paddingBottom: "10px !important",
    },
    actionbtn: {
        position: "absolute",
        bottom: "0",
        width: "100%",
        left: "6px",
        right: "0",
    },
    h5: {
        color: "#fff",
        fontWeight: 600,
        width: "50%",
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
        },
        textShadow: "-2px 2px 2px #000",
    },
    imgCard: {
        "& .MuiCardContent-root:last-child": {
            paddingBottom: 16,
        },
        "& .MuiCardContent-root": {
            [theme.breakpoints.up("lg")]: {
                height: 487,
            },
        },
    },
    media: {
        height: 200,
        cursor: "default",
        cursor: "pointer",
    },
    customButton: {
        textTransform: "capitalize",
        fontSize: "0.92rem",
        fontWeight: 500,
        boxShadow: "none !important",
        [theme.breakpoints.down("sm")]: {
            fontSize: "13px",
        },
    },
    titleBtn: {
        background: "#53de21 !important",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "capitalize",
        boxShadow: "none !important",
        borderRadius: 20,
        fontSize: 16,
        width: 100,
    },
    margincfdp: {
        marginBottom: "2px !important",
    },
    closetext: {
        position: "absolute",
        top: "50%",
        zIndex: "99",
        color: "white",
        left: "50%",
        transform: "translate(-50%,-50%)",
        fontSize: "2rem",
    },
}));
const occupiedStyle = {
    borderRadius: 4,
    backgroundColor: "#fec6c6",
    color: "red",
    fontSize: "12px",
    fontWeight: "bold",
};
const unOccupiedStyle = {
    borderRadius: 4,
    backgroundColor: "#b8f0d2",
    color: "#0b9444",
    fontSize: "12px",
    fontWeight: "bold",
};
const listItemContainerStyle = {
    height: "100%",
};
export default function CourtStatusCard(props) {
    const {
        actions,
        allNotify,
        cardType,
        cardStyle = "simple",
        getFields,
    } = props;
    const loginDetails = useSelector(login_);
    const userid = _.get(loginDetails, ["UserID"], "");
    const [refresh, setRefresh] = useState(false);
    const [fields, setFields] = useState([]);
    const classes = useStyles();
    const [mcparr, setmcparr] = useState([]);
    const [getfieldNames, setfieldNames] = useState([]);
    const [getcfdpfieldNames, setcfdpfieldNames] = useState([]);
    const [Openorclose, setOpenorclose] = React.useState("");
    const [cfdpOpenorclose, setcfdpOpenorclose] = React.useState("");
    const [Openorclosepark, setOpenorclosepark] = React.useState("");
    const userefcontent = useRef(null);
    // const [getcfdpid, setcfdpid] = React.useState("");
    const [open, setopen] = React.useState(false);
    const [getcfdpgateopen, setcfdpgateopen] = React.useState(false);
    const [cspgateopen, setcspgateopen] = React.useState(false);
    const [MCPgateopen, setMCPgateopen] = React.useState(false);
    const [parkopen, setparkopen] = React.useState(false);
    const [status, setStatus] = useState("");
    const [getparkid, setparkid] = React.useState("");
    const [getcfdpparkid, setcfdpparkid] = React.useState("");
    const selectedCard = useSelector((state) =>
        fieldDetailsByType_(state, cardType)
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const [permission, setpermission] = React.useState({});
    const permissionsobj = useSelector(permissions_);
    const [allChecked, setallChecked] = React.useState(false);
    const getcfdpid = "c7a1d85f-8168-4e64-8e38-116a0624bfea";
    const [commonparkid, setCommonparkid] = React.useState("");
    const [cfdpgatedata, setcfdpgatedata] = React.useState([]);
    const [cspgatedata, setcspgatedata] = React.useState([]);
    const [getmcpgatedata, setmcpgatedata] = React.useState([]);
    const [gethandleclosepark, sethandleClosepark] = React.useState("");
    const [cfdpgateparkid, setcfdpgateparkid] = React.useState(
        "c7a1d85f-8168-4e64-8e38-116a0624bfea"
    );
    const [cspgateparkid, setcspgateparkid] = React.useState(
        "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944"
    );
    const [devicename, setdevicename] = React.useState("cfdp");
    const [closeoverlay, setcloseoverlay] = React.useState(true);
    const [isdisabled, setisdisabled] = React.useState(true);
    const [parkcloseoverlay, setparkcloseoverlay] = React.useState(true);
    const parkrealid =
        cardType === "cfdp"
            ? "c7a1d85f-8168-4e64-8e38-116a0624bfea"
            : cardType === "churchstreettenniscourts"
                ? "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944"
                : cardType === "mafctenniscourts"
                    ? "91474e36-76c5-4d59-a8da-2998ab14a4bf"
                    : cardType === "ccnp"
                        ? "c7a1d85f-8168-4e64-8e38-116a0624bfed"
                        : cardType === "shiloh"
                            ? "c7a1d85f-8168-4e64-8e38-116a0624bfec"
                            : cardType === "morrisvillecommunitypark"
                                ? "c7a1d85f-8168-4e64-8e38-116a0624bfeb"
                                : null;
    const [getparkopenname, setparkopenname] = React.useState([]);
    const [getparkopenstatus, setparkopenstatus] = React.useState([]);
    const [getCspgates, setCspgates] = React.useState([]);
    const [getMCPgates, setMCPgates] = React.useState([]);
    const [fieldsByGroup, setfieldsByGroup] = React.useState({});
    const [newFields, setNewFields] = useState([]);
    const [getcspOpenorclose, setcspOpenorclose] = React.useState("");
    const [getMCPOpenorclose, setMCPOpenorclose] = React.useState("");
    const [getcfdpgatestatus, setcfdpgatestatus] = React.useState("");
    const [getNotification, setNotification] = React.useState(false);
    const [getNotificationMessage, setNotificationMessage] = React.useState("");
    const [getcfdpOpenor, setcfdpOpenor] = React.useState("");
    const [getopenclosep, setopenclosep] = React.useState("");
    const [getnotifyWeblink, setnotifyWeblink] = React.useState("");
    const [getnotifyWebname, setnotifyWebname] = React.useState("");
    const [modalStatus, setModalStatus] = useState(false);
    const [fieldData, setFieldData] = useState([]);
    // console.log("this", fieldsByGroup);
    const handleCheckbox = (e) => {
        Object.keys(toggleCheckBoxData).map((checkbox) => {
            if (e.target.checked) {
                setToggleCheckBoxData((prevdata) => ({
                    ...prevdata,
                    [checkbox]: {
                        ...prevdata[checkbox],
                        value: checkbox === e.target.name ? e.target.checked : false,
                    },
                }));
            } else {
                setToggleCheckBoxData((prevState) => ({
                    ...prevState,
                    [e.target.name]: {
                        ...prevState[e.target.name],
                        value: e.target.checked,
                    },
                }));
            }
        });
    };
    const closeModal = () => {
        setFieldData([]);
        setModalStatus(false);
    };
    const [toggleCheckBoxData, setToggleCheckBoxData] = useState({
        Block: { name: "Block", label: "Block rule actions", value: false },
        Unblock: { name: "Unblock", label: "Unblock rule actions", value: false },
    });
    const checkBoxDescription = [
        "The automatic rules have actions that can open or close the devices.",
        "Do you want to block the actions of those rules?",
    ];
    const helpText = [
        `If you chose to block, then gates, fields, park will remain ${fieldData[0]?.updateStatus
            ? fieldData[0].updateStatus
            : "in [open/closed] state"
        }, until the next manual change.`,
        "If you chose to unblock the rule actions, the gates, fields, park will change state based on the rules. This manual action will be overridden.",
    ];
    const firstname = _.get(loginDetails, ["FirstName"], "");
    const lastname = _.get(loginDetails, ["LastName"], "");
    const toggleHandler = async (e) => {
        const overrideActions = Object.values(toggleCheckBoxData).filter(
            (checkbox) => {
                if (checkbox.value) {
                    return checkbox;
                }
            }
        );
        //for single device
        if (e.length === 1) {
            let url = "api/park/" + e[0].park_id + e[0].url_suffix;
            const data = {
                overrideActions: overrideActions[0].name,
                LastEditedBy: `${firstname} ${lastname}`,
                disableNotification: true,
            };
            if (e[0].deviceType === "gate") {
                data.deviceIDs = e[0].field_id;
                data.gates = e[0].field_name;
                data.status = e[0].updateStatus;
            }
            if (e[0].deviceType === "field") {
                data.fieldID = e[0].field_id;
                data.fieldName = e[0].field_name;
                data.fieldStatus = e[0].updateStatus;
            }
            if (e[0].deviceType === "park") {
                data.status = e[0].updateStatus;
            }
            await smartParkService
                .post(url, data, {
                    headers: {
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (Number(res.status) === 200) {
                        if (e[0].deviceType === "gate") {
                            getparkgates();
                        }
                        if (e[0].deviceType === "field") {
                            setRefresh((r) => !r);
                        }
                        if (e[0].deviceType === "park") {
                            openfn();
                        }
                    }
                });
        } else {
            let field_url_suffix =
                e[0].park_id !== "c7a1d85f-8168-4e64-8e38-116a0624bfea"
                    ? "/updatefieldstatusnoled"
                    : "/updatefieldstatus";
            let url = "api/park/" + e[0].park_id + field_url_suffix;

            let gates = [],
                gate_id = [],
                fields = [],
                field_id = [],
                parkId;
            e.forEach((device) => {
                if (device.deviceType === "park") {
                    parkId = device.park_id;
                }
                if (device.deviceType === "gate") {
                    gates.push(device.field_name);
                    gate_id.push(device.field_id);
                }
                if (device.deviceType === "Field") {
                    fields.push(device.field_name);
                    field_id.push(device.field_id);
                }
            });
            const parkData = {
                status: e[0].updateStatus,
                overrideActions: overrideActions[0].name,
                LastEditedBy: `${firstname} ${lastname}`,
            };
            const fieldData = {
                overrideActions: overrideActions[0].name,
                LastEditedBy: `${firstname} ${lastname}`,
                disableNotification: true,
                fieldID: field_id,
                fieldName: fields,
                fieldStatus: e[0].updateStatus,
            };

            if (parkId) {
                await smartParkService
                    .post(`api/park/${e[0].park_id}/facilitiesmanagement`, parkData, {
                        headers: {
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (Number(res.data.status) === 200) {
                            setcloseoverlay(e[0].updateStatus.toLowerCase() === "open");
                        }
                    });
            }
            if (gates.length > 0) {
                let url_suffix;
                if (parkId === "c7a1d85f-8168-4e64-8e38-116a0624bfea") {
                    url_suffix = "/updatedevice";
                } else {
                    url_suffix = "/updategatestatus-arduinocloud";
                }
                let url = `api/park/${parkId}${url_suffix}`;
                const gateData = {
                    overrideActions: overrideActions[0].name,
                    deviceIDs: gate_id,
                    gates: gates,
                    status: e[0].updateStatus,
                    LastEditedBy: `${firstname} ${lastname}`,
                    disableNotification: true,
                };
                await smartParkService
                    .post(url, gateData, {
                        headers: {
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (Number(res.status) === 200) {
                            getparkgates();
                        }
                    });
            }

            if (fields.length > 0) {
                await smartParkService
                    .post(url, fieldData, {
                        headers: {
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (Number(res.status) === 200) {
                            setRefresh((r) => !r);
                        }
                    });
            }
        }
    };
    useEffect(() => {
        openfn();
    }, []);

    function openfn() {
        if (!selectedCard) {
            return;
        }
        smartParkService
            .get("api/park/" + parkrealid + "/facilitiesmanagement")
            .then((res) => {
                if (res.status === 200) {
                    // console.log("respp", parkrealid, res);
                    if (res.data.message === false) {
                        setcloseoverlay(false);
                    } else {
                        setcloseoverlay(true);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        notificationFunction();
    }, [parkrealid, refresh]);

    function simpledate(date) {
        try {
            const parsedDate = new Date(date);
            const simpleDate = parsedDate.toISOString().split("T")[0];
            return simpleDate;
        } catch {
            return date;
        }
    }

    const notificationFunction = () => {
        if (parkrealid !== "" && parkrealid !== undefined && parkrealid !== null) {
            let url = `api/notification/detail/${parkrealid}`;
            smartParkService
                .get(url)
                .then((res) => {
                    if (res.status == 200) {
                        setNotification(false);
                        if (res.data.status == 200) {
                            let allParkdetails = res.data.data;
                            // console.log(allParkdetails);
                            if (allParkdetails !== null) {
                                let nstartDate = simpledate(allParkdetails.startDate);
                                let nendDate = simpledate(allParkdetails.endDate);
                                let newdate = simpledate(new Date());
                                if (allParkdetails.parkId == parkrealid) {
                                    if (newdate >= nstartDate && newdate <= nendDate) {
                                        // console.log(allParkdetails);
                                        if (allParkdetails.parkId == parkrealid) {
                                            // console.log(allParkdetails);
                                            setNotificationMessage(allParkdetails.message);
                                            setnotifyWeblink(allParkdetails.webLink);
                                            setnotifyWebname(allParkdetails.webName);
                                            setNotification(true);
                                        }
                                    } else {
                                        setNotification(false);
                                    }
                                }
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.error("Notification error: ", error);
                });
        } else {
            console.log("please Enter all details");
        }
    };

    React.useEffect(() => {
        if (
            cfdpgatedata.length > 0 &&
            // mcparr.length > 0 &&
            cspgatedata.length > 0
        ) {
            setisdisabled(false);
        }
    }, [cfdpgatedata, mcparr, cspgatedata]);

    const getNewFields = async (parkid) => {
        let url =
            parkid === "91474e36-76c5-4d59-a8da-2998ab14a4bf"
                ? `api/courts/list/${parkid}`
                : "api/citizen/fielddata6";
        return await smartParkService.get(url).then((res) => {
            if (res.status === 200 && res.data.data.length > 0) {
                let fields = res.data.data.map((field) => {
                    return {
                        FieldGroup: field.FieldGroup,
                        className: field.courtoccupancy
                            ? field.courtoccupancy === "Occupied"
                                ? "closed"
                                : "open"
                            : field.FieldStatus
                                ? field.FieldStatus === "Closed"
                                    ? "closed"
                                    : "open"
                                : "closed",
                        court_api_id: field.courtid || field.FieldID,
                        court_name: field.courtname || field.FieldName,
                        fieldId: field.courtid || field.FieldID,
                        label: field.courtname || field.FieldName,
                        status: field.courtoccupancy || field.FieldStatus,
                        parkID: field.parkid || field.parkID,
                    };
                });
                return fields;
            }
        });
    };
    async function getParkFields(carddata) {
        const { url, cardColumns, id } = carddata;
        var restoken = GenerateEncryptedToken();
        const res = await smartParkService.get(url, {
            headers: {
                "x-access-token": restoken,
            },
        });
        if (
            carddata.key === "mafctenniscourts" ||
            carddata.key === "churchstreettenniscourts"
        ) {
            setNewFields(await getNewFields(carddata.parkid));
        }
        if (res.status >= 200 && res.status < 300) {
            var fields = res.data.data;
            const statusOFFields =
                fields &&
                fields.length > 0 &&
                fields.filter((obj) => {
                    let status = obj[cardColumns[1]];
                    return status === "Open" || status === 0;
                });
            let typeOfField = fields[0][cardColumns[1]];
            if (typeof typeOfField === "string")
                statusOFFields.length > 0 ? setStatus("Open") : setStatus("Closed");
            else {
                statusOFFields.length > 0
                    ? setStatus("Unoccupied")
                    : setStatus("Occupied");
            }

            fields.map((field, index) => {
                if (!field._orginalName) {
                    field._orginalName = `${cardColumns[0] === "court_name"
                            ? "Court " + (index + 1)
                            : field[cardColumns[0]]
                        }`;
                }
                let status = field[cardColumns[1]];
                let className = status === "Closed" ? "closed" : "open";

                if (typeof status != "string") {
                    className = status ? "closed" : "open";
                    status = status ? "Occupied" : "Unoccupied";
                } else {
                    status = status;
                }
                field._status = status;
                field.fieldId = field[id];
                field.className = className;
                field.label = field[cardColumns[0]];
                field.value = field[id];
                return field;
            });

            if (fields && fields.length) {
                fields.forEach((field) => {
                    field._orginalName === "Fields 1 & 2" && (field.displayOrder = 1);
                    field._orginalName === "Field 3" && (field.displayOrder = 2);
                    field._orginalName === "Field 4" && (field.displayOrder = 3);
                    field._orginalName === "Field 5" && (field.displayOrder = 4);
                    field._orginalName === "Fields 6-8" && (field.displayOrder = 5);
                });
            }

            fields.sort((a, b) => a.displayOrder - b.displayOrder);
            setFields(fields);
            getFields && getFields(fields);
        }
    }

    async function getpark(carddata) {
        if (carddata.key === "mcpfields") {
            const mcpurl = smartParkService.defaults.baseURL;
            const urldata = mcpurl + "/api/mcpcourts";
            const { cardColumns, id } = carddata;
            var restoken = GenerateEncryptedToken();
            const res = await smartParkService.get(urldata, {
                headers: {
                    "x-access-token": restoken,
                },
            });
            if (res.status >= 200 && res.status < 300) {
                var fielddta = res.data.data;
                fielddta.map((field, index) => {
                    field._orginalName = `${cardColumns[0] === "court_name"
                            ? "Court " + (index + 1)
                            : field[cardColumns[0]]
                        }`;
                    let status = field[cardColumns[1]];
                    let className = status === "Closed" ? "closed" : "open";
                    if (typeof status != "string") {
                        className = field.value ? "closed" : "open";
                        status = field.value ? "Occupied" : "Unoccupied";
                    } else {
                        status = status;
                    }
                    field._status = status;
                    field.fieldId = field[id];
                    field.className = className;
                    field.label = field[cardColumns[0]];
                    field.value = field[id];
                    return field;
                });
                fielddta.sort((a, b) => a.displayOrder - b.displayOrder);
                setmcparr(fielddta);
                var fielddtaString = JSON.stringify(fielddta);
                localStorage.setItem("mcpcourtsdta", fielddtaString);
            }
        }
    }

    // MT-58 issue

    let cardHeights = [];

    // Get all the card elements with the same class
    let cards = document.getElementsByClassName("MuiGrid-grid-md-6");
    // Initialize a variable to store the highest height
    let maxHeight = 0;
    // Loop through each card element
    for (let i = 0; i < cards.length; i++) {
        let card = cards[i];
        // Get the height of the current card
        let height = card.clientHeight;
        // Push the height to the array
        cardHeights.push(height);
        // Update the maxHeight if the current height is greater
        if (height > maxHeight) {
            maxHeight = height;
        }
    }

    for (let i = 0; i < cards.length; i++) {
        let card = cards[i];
        let innerDiv = card.querySelector(".MuiPaper-rounded");
        innerDiv.style.minheight = `${maxHeight}px`;
    }

    useEffect(() => {
        if (fields) {
            const fieldsByGroupone = {};
            fields?.forEach((field) => {
                const group = field.FieldGroup || "Field";
                if (!fieldsByGroupone[group]) {
                    fieldsByGroupone[group] = [];
                }
                fieldsByGroupone[group].push(field);
            });
            setfieldsByGroup(fieldsByGroupone);
        }
    }, [fields]);

    useEffect(() => {
        if (selectedCard) {
            getParkFields(selectedCard);
            getpark(selectedCard);
            dispatch(setSelectedField(selectedCard));
        }
    }, [selectedCard, refresh]);

    const openDirections = useCallback(() => {
        window.open(selectedCard.mapUrl, "_blank");
    }, [selectedCard]);

    // today change

    const handleClose = () => {
        setopen(false);
        setallChecked(false);
        setcspgateopen(false);
        setMCPgateopen(false);
        setcfdpgateopen(false);
        getparkgates();
        getmcpfielddata();
        // closefn();
        openfn();
        setRefresh((r) => !r);
    };

    const handleClosecfdp = () => {
        setopen(false);
        setallChecked(false);
        cfdpgetFields();
        // closefn();
        openfn();
        setRefresh((r) => !r);
    };

    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "Fields";
        });
        setpermission(Dpermissions[0]);
    };

    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);

    const getmcpfielddata = () => {
        var url = "api/citizen/fielddata2";
        smartParkService
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    var data = res.data.data;
                    // console.log(data);
                    setparkid(data[0].parkID);
                    var dataconst = data.map((d) => {
                        return {
                            fieldID: d.id,
                            isChecked: false,
                            fieldName: d.FieldName,
                            fieldStatus: d.FieldStatus,
                        };
                    });
                    var fieldstatusdta = data.map((e) => {
                        return {
                            fieldStatus: e.FieldStatus,
                        };
                    });
                    let statarr = [];
                    fieldstatusdta.forEach((element) => {
                        statarr.push(element.fieldStatus);
                    });

                    setfieldNames(dataconst);
                }
            })
            .catch((error) => { });
    };

    useEffect(() => {
        if (cardType === "morrisvillecommunitypark") {
            getmcpfielddata();
        }
    }, []);

    const getparkgates = () => {
        // console.log(gateparkid);
        if (cfdpgateparkid) {
            var url = "api/park/" + cfdpgateparkid + "/device";
            smartParkService
                .get(url)
                .then((res) => {
                    if (res.status === 200) {
                        var data = res.data.data;
                        setcfdpgatedata(data);
                    }
                })
                .catch((error) => { });
        }
        if (cspgateparkid) {
            var url = "api/park/" + cspgateparkid + "/arduinodevice";
            smartParkService
                .get(url)
                .then((res) => {
                    if (res.status === 200) {
                        var data = res.data.data;
                        setcspgatedata(data);
                    }
                })
                .catch((error) => { });
        }
        if (cardType === "morrisvillecommunitypark") {
            if (parkrealid === "c7a1d85f-8168-4e64-8e38-116a0624bfeb") {
                var url = "api/park/" + parkrealid + "/arduinodevice";
                smartParkService
                    .get(url)
                    .then((res) => {
                        if (res.status === 200) {
                            var data = res.data.data;
                            setmcpgatedata(data);
                        }
                    })
                    .catch((error) => { });
            }
        }
    };

    useEffect(() => {
        getparkgates();
    }, [cfdpgateparkid, cspgateparkid, cardType]);

    // cfdp data

    const cfdpgetFields = () => {
        var url = "api/park/" + getcfdpid + "/fields";
        smartParkService
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    var data = res.data.data;
                    setcfdpparkid(data[0].parkID);
                    var dataconst = data.map((d) => {
                        return {
                            fieldID: d.id,
                            isChecked: false,
                            fieldName: d.FieldName,
                            fieldStatus: d.FieldStatus,
                        };
                    });
                    var fieldstatusdta = data.map((e) => {
                        return {
                            fieldStatus: e.FieldStatus,
                        };
                    });
                    let statarr = [];
                    fieldstatusdta.forEach((element) => {
                        statarr.push(element.fieldStatus);
                    });
                    // console.log(dataconst);
                    // setstatusarray(statarr);
                    setcfdpfieldNames(dataconst);
                }
            })
            .catch((error) => { });
    };

    useEffect(() => {
        if (cardType === "cfdp") {
            cfdpgetFields();
        }
    }, []);

    function openclosefields(parkid, fieldgroup, fieldstatus, mcpfieldId) {
        if (parkid === "c7a1d85f-8168-4e64-8e38-116a0624bfeb") {
            setCommonparkid(parkid);
            if (fieldgroup === "Field") {
                if (fieldstatus === "Closed") {
                    if (permission.update == 1) {
                        var isListChecked = false;
                        if (
                            getfieldNames.filter(function (obj) {
                                if (obj.fieldID === mcpfieldId) {
                                    obj.isChecked = !obj.isChecked; // Change the value of obj.isChecked
                                }
                                if (obj.isChecked) isListChecked = true;
                            })
                        );
                        if (isListChecked) {
                            setOpenorclose("Open");
                            setopen(true);
                        }
                    } else {
                        alert("You do not have permission to update Sensor Status");
                    }
                } else {
                    if (permission.update == 1) {
                        var isListChecked = false;
                        if (
                            getfieldNames.filter(function (obj) {
                                if (obj.fieldID === mcpfieldId) {
                                    obj.isChecked = !obj.isChecked; // Change the value of obj.isChecked
                                }
                                if (obj.isChecked) isListChecked = true;
                            })
                        );
                        if (isListChecked) {
                            setOpenorclose("Closed");
                            setopen(true);
                        }
                    } else {
                        alert("You do not have permission to update Sensor Status");
                    }
                }
            }
        } else if (parkid === "c7a1d85f-8168-4e64-8e38-116a0624bfea") {
            setCommonparkid(parkid);
            if (fieldstatus === "Closed" && fieldstatus !== "Open") {
                // console.log("---------drt");
                // console.log(fieldstatus);
                if (permission.update == 1) {
                    var isListChecked = false;
                    if (
                        getcfdpfieldNames.filter(function (obj) {
                            if (obj.fieldID === mcpfieldId) {
                                obj.isChecked = !obj.isChecked; // Change the value of obj.isChecked
                            }
                            if (obj.isChecked) isListChecked = true;
                        })
                    );
                    if (isListChecked) {
                        setcfdpOpenorclose("Open");
                        setopen(true);
                    }
                } else {
                    alert("You do not have permission to update Sensor Status");
                }
            } else if (fieldstatus === "Open" && fieldstatus !== "Closed") {
                // console.log(fieldstatus);
                // console.log("treot-----------");
                if (permission.update == 1) {
                    var isListChecked = false;
                    if (
                        getcfdpfieldNames.filter(function (obj) {
                            if (obj.fieldID === mcpfieldId) {
                                obj.isChecked = !obj.isChecked; // Change the value of obj.isChecked
                            }
                            if (obj.isChecked) isListChecked = true;
                        })
                    );
                    if (isListChecked) {
                        setcfdpOpenorclose("Closed");
                        setopen(true);
                    }
                } else {
                    alert("You do not have permission to update Sensor Status");
                }
            }
        }
    }

    const handleclosepark = () => {
        setparkopen(false);
        setallChecked(false);
        // closefn();
        openfn();
        cfdpgetFields();
        getparkgates();
        getmcpfielddata();

        setRefresh((r) => !r);
    };

    function closeallpark(e, props) {
        console.log("props", props);
        const parkdata = {
            cfdp: {
                name: "Cedar Fork District Park",
                id: "c7a1d85f-8168-4e64-8e38-116a0624bfea",
            },
            churchstreettenniscourts: {
                name: "Church Street Park",
                id: "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944",
            },
            mafctenniscourts: {
                name: "Morrisville Aquatics & Fitness Center",
                id: "91474e36-76c5-4d59-a8da-2998ab14a4bf",
            },
            morrisvillecommunitypark: {
                name: "Morrisville Community Park",
                id: "c7a1d85f-8168-4e64-8e38-116a0624bfeb",
            },
            shiloh: {
                name: "Shiloh Park",
                id: "c7a1d85f-8168-4e64-8e38-116a0624bfec",
            },
            ccnp: {
                name: "Crabtree Creek Nature Park",
                id: "c7a1d85f-8168-4e64-8e38-116a0624bfed",
            },
        };

        setFieldData((prevState) => [
            ...prevState,
            {
                deviceType: "park",
                park_id: parkdata[props.cardType].id,
                primaryStatus: closeoverlay ? "Open" : "Closed",
                updateStatus: closeoverlay ? "Closed" : "Open",
                field_name: parkdata[props.cardType].name,
                field_status: closeoverlay ? "Open" : "Closed",
                field_id: parkdata[props.cardType].id,
                url_suffix: "/facilitiesmanagement",
            },
        ]);

        let buttonElement = e.currentTarget;
        let buttonText = buttonElement.textContent;
        console.log(props);

        let finalparkdata = [];
        // console.log(e.currentTarget);
        let allEle = e.currentTarget.closest(".openclosebtnsection");
        let prevsib = allEle.previousSibling;
        // let allEle = e.currentTarget.previousSibling;
        let allcardata = prevsib.querySelectorAll(".openclosegrid");
        // console.log(allcardata);
        allcardata?.forEach(function (ele) {
            if (
                ele.getAttribute("data-status").toLowerCase() === "open" ||
                ele.getAttribute("data-status").toLowerCase() === "closed"
            ) {
                setFieldData((prevdata) => [
                    ...prevdata,
                    {
                        field_name: ele.getAttribute("data-name"),
                        field_status: ele.getAttribute("data-status"),
                        deviceType: ele.getAttribute("data-deviceType"),
                        field_id: ele.getAttribute("data-id"),
                    },
                ]);
            }

            finalparkdata.push({
                fieldname: ele.getAttribute("data-name"),
                fieldstatus: ele.getAttribute("data-status"),
                fieldid: ele.getAttribute("data-id"),
            });
        });

        // setFieldData([{
        //   field_id: field_id,
        //   field_status: field_status,
        //   field_name: field_name
        // }])
        setModalStatus(true);
        // console.log('pd',finalparkdata)

        // setopenclosep(e);
        // console.log(buttonText);
        if (buttonText === "Close All") {
            setopenclosep("Closed");
        } else if (buttonText === "Open All") {
            setopenclosep("Open");
        } else {
            setopenclosep("");
        }
        // Output: "Open All" or "Close All"
        setparkopenname(finalparkdata);
        // setparkopenstatus(parkstatus);
        setparkopen("open");
        // setOpenorclosepark("Open");
        // setopen(true);
    }

    function handlecfdpgateShow(datacfdp, cfdpgateid, cfdpgatestatus) {
        if (cfdpgatestatus == "Closed") {
            if (permission.update == 1) {
                var isListChecked = false;
                if (
                    datacfdp.filter(function (obj) {
                        if (obj.deviceID == cfdpgateid) {
                            obj.isChecked = !obj.isChecked; // Change the value of obj.isChecked
                        }
                        if (obj.isChecked) isListChecked = true;
                    })
                );
                if (isListChecked) {
                    setcfdpOpenor("Open");
                    setcfdpgateopen(true);
                }
            } else {
                alert("You do not have permission to update Sensor Status");
            }
        } else {
            if (permission.update == 1) {
                var isListChecked = false;
                if (
                    datacfdp.filter(function (obj) {
                        if (obj.deviceID == cfdpgateid) {
                            obj.isChecked = !obj.isChecked; // Change the value of obj.isChecked
                        }
                        if (obj.isChecked) isListChecked = true;
                    })
                );
                if (isListChecked) {
                    setcfdpOpenor("Closed");
                    setcfdpgateopen(true);
                }
            } else {
                alert("You do not have permission to update Sensor Status");
            }
        }
    }

    function handlecspgateShow(datacsp, gateid, cspgatestatus) {
        if (cspgatestatus == "Closed") {
            if (permission.update == 1) {
                var isListChecked = false;
                if (
                    datacsp.filter(function (obj) {
                        if (obj.deviceID == gateid) {
                            obj.isChecked = !obj.isChecked; // Change the value of obj.isChecked
                        }
                        if (obj.isChecked) isListChecked = true;
                    })
                );
                if (isListChecked) {
                    setcspOpenorclose("Open");
                    setcspgateopen(true);
                }
            } else {
                alert("You do not have permission to update Sensor Status");
            }
        } else {
            if (permission.update == 1) {
                var isListChecked = false;
                if (
                    datacsp.filter(function (obj) {
                        if (obj.deviceID == gateid) {
                            obj.isChecked = !obj.isChecked; // Change the value of obj.isChecked
                        }
                        if (obj.isChecked) isListChecked = true;
                    })
                );
                if (isListChecked) {
                    setcspOpenorclose("Closed");
                    setcspgateopen(true);
                }
            } else {
                alert("You do not have permission to update Sensor Status");
            }
        }
    }

    function openclosegatefields(parkid, gateid, cspgatestatus) {
        var url = "api/park/" + parkid + "/arduinodevice";
        smartParkService
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    var data = res.data.data;
                    const dataconst = data.map((d) => {
                        return {
                            deviceID: d.id,
                            isChecked: false,
                            ...d,
                        };
                    });

                    setCspgates(dataconst);
                    handlecspgateShow(dataconst, gateid, cspgatestatus);
                }
            })
            .catch((error) => { });
    }

    function openclosecfdpgatefields(cfdpparkid, cfdpgateid, cfdpgatestatus) {
        var url = "api/park/" + cfdpparkid + "/device";
        smartParkService
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    var data = res.data.data;
                    var dataconst = data.map((d) => {
                        return {
                            deviceID: d.id,
                            isChecked: false,
                            devicename: d.devicename,
                            devicestatus: d.devicestatus,
                        };
                    });

                    setCspgates(dataconst);
                    handlecfdpgateShow(dataconst, cfdpgateid, cfdpgatestatus);
                }
            })
            .catch((error) => { });
    }

    function handleMCPgateShow(datamcp, gateid, mcpgatestatus) {
        if (mcpgatestatus == "Closed") {
            if (permission.update == 1) {
                var isListChecked = false;
                if (
                    datamcp.filter(function (obj) {
                        if (obj.deviceID == gateid) {
                            obj.isChecked = !obj.isChecked; // Change the value of obj.isChecked
                        }
                        if (obj.isChecked) isListChecked = true;
                    })
                );
                if (isListChecked) {
                    setMCPOpenorclose("Open");
                    setMCPgateopen(true);
                }
            } else {
                alert("You do not have permission to update Sensor Status");
            }
        } else {
            if (permission.update == 1) {
                var isListChecked = false;
                if (
                    datamcp.filter(function (obj) {
                        if (obj.deviceID == gateid) {
                            obj.isChecked = !obj.isChecked; // Change the value of obj.isChecked
                        }
                        if (obj.isChecked) isListChecked = true;
                    })
                );
                if (isListChecked) {
                    setMCPOpenorclose("Closed");
                    setMCPgateopen(true);
                }
            } else {
                alert("You do not have permission to update Sensor Status");
            }
        }
    }

    function openclosemcpgatefields(mcpparkid, mcpgateid, mcpgatestatus) {
        var url = "api/park/" + mcpparkid + "/arduinodevice";
        smartParkService
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    var data = res.data.data;
                    console.log(data);
                    var dataconst = data.map((d) => {
                        return {
                            deviceID: d.id,
                            isChecked: false,
                            devicename: d.devicename,
                            devicestatus: d.devicestatus,
                        };
                    });

                    setMCPgates(dataconst);
                    handleMCPgateShow(dataconst, mcpgateid, mcpgatestatus);
                }
            })
            .catch((error) => { });
    }

    function funcLinkAdd(webname, weblink) {
        try {
            if (webname !== "" && weblink !== "") {
                return (
                    <a href={weblink} className="btn-link weblinkcss" target="_blank">
                        {webname}
                    </a>
                );
            }
            if (webname !== "" && weblink == "") {
                return <span> {webname} </span>;
            }
            if (webname == "" && weblink !== "") {
                return (
                    <a href={weblink} className="btn-link weblinkcss" target="_blank">
                        {weblink}
                    </a>
                );
            }
        } catch {
            return (
                <span>
                    {webname},{weblink}
                </span>
            );
        }
    }

    return (
        <>
            <ToggleModal
                submitButtonHandler={(e) => {
                    toggleHandler(e);
                }}
                handleCheckbox={handleCheckbox}
                disableData={fieldData}
                closeModalHandler={closeModal}
                checkBoxData={toggleCheckBoxData}
                checkBoxDescription={checkBoxDescription}
                modalState={modalStatus}
                checkBoxHelpText={helpText}
            />
            {/*{commonparkid === "c7a1d85f-8168-4e64-8e38-116a0624bfeb" ? (*/}
            {/*  <>*/}
            {/*    <MCPmodal*/}
            {/*      show={open}*/}
            {/*      status={getfieldNames}*/}
            {/*      closePopup={handleClose}*/}
            {/*      openclose={Openorclose}*/}
            {/*      parkid={getparkid}*/}
            {/*    />*/}
            {/*  </>*/}
            {/*) : (*/}
            {/*  <></>*/}
            {/*)}*/}
            {/*{commonparkid === "c7a1d85f-8168-4e64-8e38-116a0624bfea" ? (*/}
            {/*  <>*/}
            {/*    <Modal*/}
            {/*      show={open}*/}
            {/*      status={getcfdpfieldNames}*/}
            {/*      closePopup={handleClosecfdp}*/}
            {/*      openclose={cfdpOpenorclose}*/}
            {/*      parkid={getcfdpparkid}*/}
            {/*    />*/}
            {/*  </>*/}
            {/*) : (*/}
            {/*  <></>*/}
            {/*)}*/}
            {/*{*/}
            {/*  <Cspgatemodal*/}
            {/*    show={cspgateopen}*/}
            {/*    status={getCspgates}*/}
            {/*    closePopup={handleClose}*/}
            {/*    openclose={getcspOpenorclose}*/}
            {/*  />*/}
            {/*}*/}
            {/*{*/}
            {/*  <MCPgatemodal*/}
            {/*    show={MCPgateopen}*/}
            {/*    status={getMCPgates}*/}
            {/*    closePopup={handleClose}*/}
            {/*    openclose={getMCPOpenorclose}*/}
            {/*  />*/}
            {/*}*/}

            {/*{*/}
            {/*  <CfdpgateModal*/}
            {/*    show={getcfdpgateopen}*/}
            {/*    status={getCspgates}*/}
            {/*    closePopup={handleClose}*/}
            {/*    openclose={getcfdpOpenor}*/}
            {/*  />*/}
            {/*}*/}

            {/*<Parkclose*/}
            {/*  show={parkopen}*/}
            {/*  status={devicename}*/}
            {/*  closePopup={handleclosepark}*/}
            {/*  openclose={Openorclosepark}*/}
            {/*  pname={getparkopenname}*/}
            {/*  parkid={commonparkid}*/}
            {/*  fieldsByGroup={fieldsByGroup}*/}
            {/*  parkname={cardType}*/}
            {/*  parkopenclose={getopenclosep}*/}
            {/*/>*/}

            {selectedCard ? (
                cardStyle === "simple" ? (
                    <Card
                        elevation={8}
                        className={`${classes.card} sameheight closeopencard`}
                    >
                        <AdminFloodSensorStatus value={cardType} />
                        <CardActionArea className={closeoverlay === false ? "parkbtn" : ""}>
                            <CardMedia
                                className={classes.media}
                                image={selectedCard.imgURL}
                                onClick={() => {
                                    history.push(props.url);
                                }}
                            />
                            {closeoverlay === false ? (
                                <div className={classes.closetext}>Closed</div>
                            ) : (
                                ""
                            )}
                            {getNotification ? (
                                <div className="notificationicon">
                                    <div className="notify">
                                        <BsFillBellFill className="text-danger notifybellicon" />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </CardActionArea>
                        <div
                            className={classes.cardTitle}
                            onClick={() => {
                                history.push(props.url);
                            }}
                        >
                            <h5 className={classes.h5}>{selectedCard.header}</h5>
                        </div>
                        <CardContent className="conheight openclosebox">
                            {cardType === "cfdp" ? (
                                <>
                                    <div>
                                        {cfdpgatedata.length > 0 ? (
                                            <h6 className="title1 samesize">Gate Status</h6>
                                        ) : (
                                            ""
                                        )}
                                        <Grid
                                            container
                                            spacing={2}
                                            className={`status-list  listcontainer ${classes.margincfdp}`}
                                        >
                                            {cfdpgatedata.map((gate) => (
                                                <Grid
                                                    item
                                                    xs={4}
                                                    key={gate.deviceID}
                                                    onClick={() => {
                                                        if (
                                                            gate.id &&
                                                            checkPermissions({
                                                                permission: "GateStatus",
                                                                permissionType: "update",
                                                            })
                                                        ) {
                                                            setModalStatus(true);
                                                            setFieldData([
                                                                {
                                                                    deviceType: "gate",
                                                                    primaryStatus: gate.devicestatus,
                                                                    updateStatus:
                                                                        gate.devicestatus.toLowerCase() === "open"
                                                                            ? "Closed"
                                                                            : "Open",
                                                                    park_id: gate.parkid,
                                                                    field_id: [gate.deviceID],
                                                                    field_status: [gate.devicestatus],
                                                                    field_name: [gate.devicename],
                                                                    url_suffix: "/updatedevice",
                                                                },
                                                            ]);
                                                        }

                                                        // permission.update == 1 &&
                                                        // openclosecfdpgatefields(
                                                        //   gate.parkid,
                                                        //   gate.id,
                                                        //   gate.devicestatus
                                                        // )
                                                    }}
                                                >
                                                    <div
                                                        className={`list-item openclosegrid togglebtn2 ${gate.devicestatus} mousepointer`}
                                                        style={listItemContainerStyle}
                                                        data-deviceType={"gate"}
                                                        data-id={gate.deviceID}
                                                        data-name={gate.devicename}
                                                        data-status={gate.devicestatus}
                                                    >
                                                        <span className="listItemSpanStyle">
                                                            <div className="listItemSpanDivStyle cfdpgate">
                                                                {gate.devicename}
                                                            </div>
                                                        </span>
                                                        <label className="listItemLabelStyle mousepointer">
                                                            {gate.devicestatus}
                                                        </label>
                                                        {gate.parkid &&
                                                            checkPermissions({
                                                                permission: "GateStatus",
                                                                permissionType: "update",
                                                            }) ? (
                                                            gate.devicestatus === "Open" ? (
                                                                <div className={`${classes.greentoggle}`}>
                                                                    <MdToggleOn />
                                                                </div>
                                                            ) : (
                                                                <div className={`${classes.redtoggle}`}>
                                                                    <MdToggleOff />
                                                                </div>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {cardType === "churchstreettenniscourts" ? (
                                <>
                                    <div>
                                        {cspgatedata.length > 0 ? (
                                            <h6 className="title1 samesize">Gate Status</h6>
                                        ) : (
                                            ""
                                        )}
                                        <Grid
                                            container
                                            spacing={2}
                                            className={`status-list  listcontainer ${classes.margincfdp}`}
                                        >
                                            {cspgatedata.map((gate) => (
                                                <Grid
                                                    item
                                                    xs={4}
                                                    key={gate.id}
                                                    onClick={() => {
                                                        if (
                                                            gate.id &&
                                                            checkPermissions({
                                                                permission: "GateStatus",
                                                                permissionType: "update",
                                                            })
                                                        ) {
                                                            setModalStatus(true);
                                                            setFieldData([
                                                                {
                                                                    deviceType: "gate",
                                                                    primaryStatus: gate.devicestatus,
                                                                    updateStatus:
                                                                        gate.devicestatus.toLowerCase() === "open"
                                                                            ? "Closed"
                                                                            : "Open",
                                                                    park_id: gate.parkid,
                                                                    field_id: [gate.id],
                                                                    field_status: [gate.devicestatus],
                                                                    field_name: [gate.devicename],
                                                                    url_suffix: "/updategatestatus-arduinocloud",
                                                                },
                                                            ]);
                                                            //   permission.update == 1 &&
                                                            //   openclosegatefields(
                                                            //     gate.parkid,
                                                            //     gate.id,
                                                            //     gate.devicestatus
                                                            //   )
                                                            // }
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className={`list-item openclosegrid togglebtn2 ${gate.devicestatus} mousepointer`}
                                                        style={listItemContainerStyle}
                                                        data-deviceType={"gate"}
                                                        data-id={gate.id}
                                                        data-name={gate.devicename}
                                                        data-status={gate.devicestatus}
                                                    >
                                                        <span className="listItemSpanStyle">
                                                            <div className="listItemSpanDivStyle">
                                                                {gate.devicename}
                                                            </div>
                                                        </span>
                                                        <label className="listItemLabelStyle mousepointer">
                                                            {gate.devicestatus}
                                                        </label>
                                                        {gate.parkid &&
                                                            checkPermissions({
                                                                permission: "GateStatus",
                                                                permissionType: "update",
                                                            }) ? (
                                                            gate.devicestatus === "Open" ? (
                                                                <div className={`${classes.greentoggle}`}>
                                                                    <MdToggleOn />
                                                                </div>
                                                            ) : (
                                                                <div className={`${classes.redtoggle}`}>
                                                                    <MdToggleOff />
                                                                </div>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {cardType === "morrisvillecommunitypark" ? (
                                <>
                                    <div>
                                        {getmcpgatedata.length > 0 ? (
                                            <h6 className="title1 samesize">Gate Status</h6>
                                        ) : (
                                            ""
                                        )}
                                        <Grid
                                            container
                                            spacing={2}
                                            className={`status-list  listcontainer ${classes.margincfdp}`}
                                        >
                                            {getmcpgatedata.map((gate) => (
                                                <Grid
                                                    item
                                                    xs={4}
                                                    key={gate.id}
                                                    onClick={
                                                        () => {
                                                            if (
                                                                gate.id &&
                                                                checkPermissions({
                                                                    permission: "GateStatus",
                                                                    permissionType: "update",
                                                                })
                                                            ) {
                                                                setModalStatus(true);
                                                                setFieldData([
                                                                    {
                                                                        deviceType: "gate",
                                                                        primaryStatus: gate.devicestatus,
                                                                        updateStatus:
                                                                            gate.devicestatus.toLowerCase() === "open"
                                                                                ? "Closed"
                                                                                : "Open",
                                                                        park_id: gate.parkid,
                                                                        field_id: [gate.id],
                                                                        field_status: [gate.devicestatus],
                                                                        field_name: [gate.devicename],
                                                                        url_suffix:
                                                                            "/updategatestatus-arduinocloud",
                                                                    },
                                                                ]);
                                                            }
                                                        }
                                                        // permission.update == 1 &&
                                                        // openclosemcpgatefields(
                                                        //   gate.parkid,
                                                        //   gate.id,
                                                        //   gate.devicestatus
                                                        // )
                                                    }
                                                >
                                                    <div
                                                        className={`list-item openclosegrid togglebtn2 ${gate.devicestatus} mousepointer`}
                                                        style={listItemContainerStyle}
                                                        data-deviceType={"gate"}
                                                        data-id={gate.id}
                                                        data-name={gate.devicename}
                                                        data-status={gate.devicestatus}
                                                    >
                                                        <span className="listItemSpanStyle">
                                                            <div
                                                                className={
                                                                    gate.id ===
                                                                        "ea61d2fd-b95b-4326-b46b-51cf264cef13"
                                                                        ? "listItemSpanDivStyle  gatelineheight"
                                                                        : "listItemSpanDivStyle"
                                                                }
                                                            >
                                                                {gate.devicename}
                                                            </div>
                                                        </span>
                                                        <label className="listItemLabelStyle mousepointer">
                                                            {gate.devicestatus}
                                                        </label>
                                                        {gate.parkid &&
                                                            checkPermissions({
                                                                permission: "GateStatus",
                                                                permissionType: "update",
                                                            }) ? (
                                                            gate.devicestatus === "Open" ? (
                                                                <div className={`${classes.greentoggle}`}>
                                                                    <MdToggleOn />
                                                                </div>
                                                            ) : (
                                                                <div className={`${classes.redtoggle}`}>
                                                                    <MdToggleOff />
                                                                </div>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {Object.entries(fieldsByGroup).map(([group, groupFields]) => (
                                <div key={group}>
                                    <div>
                                        <h6 className="title1 samesize">{group} Status</h6>
                                        <Grid
                                            container
                                            spacing={2}
                                            className="status-list listcontainer"
                                        >
                                            {groupFields.map((field) => (
                                                <Grid
                                                    item
                                                    xs={4}
                                                    key={field.fieldId}
                                                    onClick={
                                                        () => {
                                                            if (field.FieldGroup === "Field") {
                                                                if (
                                                                    checkPermissions({
                                                                        permission: "Fields",
                                                                        permissionType: "update",
                                                                    })
                                                                ) {
                                                                    setModalStatus(true);
                                                                    setFieldData([
                                                                        {
                                                                            deviceType: "field",
                                                                            primaryStatus: field.FieldStatus,
                                                                            updateStatus:
                                                                                field.FieldStatus.toLowerCase() ===
                                                                                    "open"
                                                                                    ? "Closed"
                                                                                    : "Open",
                                                                            park_id: field.parkID,
                                                                            field_id: [field.fieldId],
                                                                            field_status: [field.FieldStatus],
                                                                            field_name: [field.FieldName],
                                                                            url_suffix:
                                                                                field.parkID !==
                                                                                    "c7a1d85f-8168-4e64-8e38-116a0624bfea"
                                                                                    ? "/updatefieldstatusnoled"
                                                                                    : "/updatefieldstatus",
                                                                        },
                                                                    ]);
                                                                }
                                                            }
                                                        }
                                                        // permission.update == 1 &&
                                                        // openclosefields(
                                                        //   field.parkID,
                                                        //   field.FieldGroup,
                                                        //   field.FieldStatus,
                                                        //   field.fieldId
                                                        // )
                                                    }
                                                >
                                                    <div
                                                        className={`list-item togglebtn openclosegrid  ${field.className} mousepointer`}
                                                        style={listItemContainerStyle}
                                                        data-deviceType={field.FieldGroup}
                                                        data-id={
                                                            field.FieldGroup === "Field"
                                                                ? field.id
                                                                : field.court_api_id
                                                        }
                                                        data-status={field._status}
                                                        data-name={
                                                            field.FieldGroup === "Field"
                                                                ? field.FieldName
                                                                : field.court_name
                                                        }
                                                    >
                                                        <span className="listItemSpanStyle">
                                                            <div className="listItemSpanDivStyle">
                                                                {field._orginalName}
                                                            </div>
                                                        </span>
                                                        <label className="listItemLabelStyle mousepointer">
                                                            {field._status}
                                                        </label>
                                                        {field.parkID &&
                                                            checkPermissions({
                                                                permission: "Fields",
                                                                permissionType: "update",
                                                            }) ? (
                                                            field._status === "Open" ? (
                                                                <div className={`${classes.greentoggle}`}>
                                                                    <MdToggleOn />
                                                                </div>
                                                            ) : (
                                                                <div className={`${classes.redtoggle}`}>
                                                                    <MdToggleOff />
                                                                </div>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Grid>
                                            ))}

                                            {cardType === "morrisvillecommunitypark" ? (
                                                <>
                                                    {Array.from(
                                                        new Set(mcparr?.map((field) => field.FieldGroup))
                                                    ).map((group) => (
                                                        <div key={group} className="w-100 fieldgrouppad">
                                                            <h6 className="title1 samesize">
                                                                {group} status
                                                            </h6>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                className="status-list listcontainer"
                                                            >
                                                                {mcparr
                                                                    .filter((field) => field.FieldGroup === group)
                                                                    .map((field, idx) => {
                                                                        const status = field._status;
                                                                        return (
                                                                            <Grid item xs={4} key={idx}>
                                                                                <div
                                                                                    className={`list-item openclosegrid ${field.className} mousepointer`}
                                                                                    style={listItemContainerStyle}
                                                                                    data-id={field.court_api_id}
                                                                                    data-name={field.court_name}
                                                                                    data-status={field._status}
                                                                                >
                                                                                    <span className="listItemSpanStyle">
                                                                                        <div className="listItemSpanDivStyle">
                                                                                            {field.court_name}
                                                                                        </div>
                                                                                    </span>
                                                                                    <label className="listItemLabelStyle mousepointer">
                                                                                        <span>{status}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                            </Grid>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {cardType === "mafctenniscourts" ||
                                                cardType === "churchstreettenniscourts" ? (
                                                <>
                                                    <div className="w-100 fieldgrouppad">
                                                        <h6 className="title1 samesize">
                                                            {newFields[0].FieldGroup} status
                                                        </h6>

                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            className="status-list listcontainer"
                                                        >
                                                            {newFields.map((field, idx) => {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                        key={field.fieldId}
                                                                        onClick={() => {
                                                                            if (field.FieldGroup === "Field") {
                                                                                if (
                                                                                    checkPermissions({
                                                                                        permission: "Fields",
                                                                                        permissionType: "update",
                                                                                    })
                                                                                ) {
                                                                                    setModalStatus(true);
                                                                                    setFieldData([
                                                                                        {
                                                                                            deviceType: "field",
                                                                                            primaryStatus: field.status,
                                                                                            updateStatus:
                                                                                                field.status.toLowerCase() ===
                                                                                                    "open"
                                                                                                    ? "Closed"
                                                                                                    : "Open",
                                                                                            park_id: field.parkID,
                                                                                            field_id: [field.fieldId],
                                                                                            field_status: [field.status],
                                                                                            field_name: [field.court_name],
                                                                                            url_suffix:
                                                                                                field.parkID !==
                                                                                                    "c7a1d85f-8168-4e64-8e38-116a0624bfea"
                                                                                                    ? "/updatefieldstatusnoled"
                                                                                                    : "/updatefieldstatus",
                                                                                        },
                                                                                    ]);
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={`list-item togglebtn openclosegrid  ${field.className} mousepointer`}
                                                                            style={listItemContainerStyle}
                                                                            data-deviceType={field.FieldGroup}
                                                                            data-id={
                                                                                field.id ||
                                                                                field.fieldId ||
                                                                                field.court_api_id
                                                                            }
                                                                            data-status={field.status}
                                                                            data-name={
                                                                                field.FieldName || field.court_name
                                                                            }
                                                                        >
                                                                            <span className="listItemSpanStyle">
                                                                                <div className="listItemSpanDivStyle">
                                                                                    {field.court_name}
                                                                                </div>
                                                                            </span>
                                                                            <label className="listItemLabelStyle mousepointer">
                                                                                {field.status}
                                                                            </label>
                                                                            {field.parkID &&
                                                                                field.FieldGroup === "Field" &&
                                                                                checkPermissions({
                                                                                    permission: "Fields",
                                                                                    permissionType: "update",
                                                                                }) ? (
                                                                                field.status === "Open" ? (
                                                                                    <div
                                                                                        className={`${classes.greentoggle}`}
                                                                                    >
                                                                                        <MdToggleOn />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className={`${classes.redtoggle}`}
                                                                                    >
                                                                                        <MdToggleOff />
                                                                                    </div>
                                                                                )
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </div>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <Grid item xs={12}>
                                                {getNotification ? (
                                                    <div className="notebox mcpnotebook">
                                                        <div className="notification">
                                                            <p
                                                                className={`text-danger notifiystyle ${classes.notifycsp}`}
                                                            >
                                                                <div className="text-danger font-weight-bold">
                                                                    Notification :{" "}
                                                                </div>
                                                                <div className="notifymessageweblink">
                                                                    <div>{getNotificationMessage} </div>
                                                                    <div>
                                                                        {funcLinkAdd(
                                                                            getnotifyWebname,
                                                                            getnotifyWeblink
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {!groupFields.length ? (
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <CircularProgress />
                                            </Box>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </CardContent>
                        <CardActions
                            className={`${classes.actionbtn} openclosebtnsection `}
                        >
                            {actions ? (
                                <div className="adminbtnaction">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.customButton}
                                        onClick={() => {
                                            history.push(props.url);
                                        }}
                                    >
                                        Advanced Settings
                                    </Button>
                                    {closeoverlay === false &&
                                        checkPermissions({
                                            permission: "ParkStatus",
                                            permissionType: "update",
                                        }) &&
                                        checkPermissions({
                                            permission: "Fields",
                                            permissionType: "update",
                                        }) &&
                                        checkPermissions({
                                            permission: "GateStatus",
                                            permissionType: "update",
                                        }) ? (
                                        <Button
                                            color="primary"
                                            className={`openclosebtn ${classes.customButton}`}
                                            onClick={(e) => closeallpark(e, props)}
                                            disabled={isdisabled}
                                        >
                                            Open All
                                        </Button>
                                    ) : closeoverlay === true &&
                                        checkPermissions({
                                            permission: "ParkStatus",
                                            permissionType: "update",
                                        }) &&
                                        checkPermissions({
                                            permission: "Fields",
                                            permissionType: "update",
                                        }) &&
                                        checkPermissions({
                                            permission: "GateStatus",
                                            permissionType: "update",
                                        }) ? (
                                        <Button
                                            color="primary"
                                            className={`openclosebtn ${classes.customButton}`}
                                            onClick={(e) => closeallpark(e, props)}
                                            disabled={isdisabled}
                                        >
                                            Close All
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ) : (
                                ""
                            )}
                            {allNotify ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.customButton}
                                >
                                    All Notifications
                                </Button>
                            ) : (
                                ""
                            )}
                        </CardActions>
                    </Card>
                ) : (
                    <>
                        <Grid item xs={12} sm={12} md={4}>
                            <Card elevation={8}>
                                <CardHeader
                                    title={selectedCard.header}
                                    className="text-center"
                                ></CardHeader>
                                <CardContent className={classes.ht364}>
                                    {Object.entries(fieldsByGroup).map(([group, groupFields]) => (
                                        <div key={group}>
                                            <h6 className="title1 samesize">{group} Status</h6>
                                            <ul className="status-list">
                                                {groupFields.map((field) => (
                                                    <li key={field.fieldId}>
                                                        <span>{field._orginalName}</span>
                                                        <label
                                                            className={field.className}
                                                            style={{ textTransform: "uppercase" }}
                                                        >
                                                            {field._status}
                                                        </label>
                                                    </li>
                                                ))}
                                                {cardType === "morrisvillecommunitypark" ? (
                                                    <>
                                                        {Array.from(
                                                            new Set(mcparr.map((field) => field.FieldGroup))
                                                        ).map((group) => (
                                                            <div key={group}>
                                                                <h6 className="title1 samesize">
                                                                    {group} status
                                                                </h6>
                                                                <ul className="padleft">
                                                                    {mcparr
                                                                        .filter(
                                                                            (field) => field.FieldGroup === group
                                                                        )
                                                                        .map((field, idx) => {
                                                                            const status = field._status;
                                                                            return (
                                                                                <li key={field.fieldId}>
                                                                                    <span>{field.court_name}</span>
                                                                                    <label
                                                                                        className={field.className}
                                                                                        style={{
                                                                                            textTransform: "uppercase",
                                                                                        }}
                                                                                    >
                                                                                        {status}
                                                                                    </label>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                </ul>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {!groupFields.length ? (
                                                    <Box
                                                        sx={{ display: "flex", justifyContent: "center" }}
                                                    >
                                                        <CircularProgress />
                                                    </Box>
                                                ) : null}
                                            </ul>
                                        </div>
                                    ))}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        color="primary"
                                        startIcon={<DirectionsOutlined />}
                                        className={classes.customButton}
                                        onClick={openDirections}
                                    >
                                        Get Directions
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Card elevation={8} className={classes.imgCard}>
                                <CardContent>
                                    <img
                                        src={selectedCard.fullImgURL}
                                        alt="map"
                                        width="100%"
                                        height="455px"
                                        className="parkimg"
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                )
            ) : (
                <></>
            )}
        </>
    );
}
CourtStatusCard.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    actions: PropTypes.bool,
    allNotify: PropTypes.bool,
    refresh: PropTypes.bool,
};
