import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../assets/scss/App.scss";
import styles from "../components/components.js";
import Footer from "./../components/Footer";
import {
  AppBar,
  Toolbar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Logo from "../assets/img/Logo.png";
import AllFieldsClose from "../assets/img/FieldMap/allfields_close.png";
import AllFieldsOpen from "../assets/img/FieldMap/allfields_open.png";
import f_1_2_Close from "../assets/img/FieldMap/1_2_close.png";
import f_1_2_3_Close from "../assets/img/FieldMap/1_2_3_close.png";
import f_1_2_3_4_5_Close from "../assets/img/FieldMap/1_2_3_4_5_close.png";
import f_1_2_Open from "../assets/img/FieldMap/1_2_open.png";
import f_1_2_3_Open from "../assets/img/FieldMap/1_2_3_open.png";
import f_1_2_3_4_5_Open from "../assets/img/FieldMap/1_2_3_4_5_open.png";

const useStyles = makeStyles(styles);

export default function CourtFieldStatus() {
  const sensors = [
    {
      field: "SN0013A20041BA6B7B",
      fieldName: "Field 1",
      status: 0,
      checked: false,
    },
    {
      field: "SN0013A20041BAC472",
      fieldName: "Field 2",
      status: 0,
      checked: false,
    },
    {
      field: "SN0013A20041BAC648",
      fieldName: "Field 3",
      status: 0,
      checked: false,
    },
    {
      field: "SN0013A20041BAC603",
      fieldName: "Field 4",
      status: 0,
      checked: false,
    },
    {
      field: "SN0013A20041BAC654",
      fieldName: "Field 5",
      status: 0,
      checked: false,
    },
    {
      field: "SN0013A20041BAC4D5",
      fieldName: "Field 6",
      status: 0,
      checked: false,
    },
  ];
  const [fields, setFields] = useState(sensors);
  const [currentSrc, setCurrentSrc] = useState(AllFieldsClose);
  const classes = useStyles();

  var canvas = useRef(null);
  var map = useRef(null);

  const [f3Left, setF3Left] = useState(0);
  const [f3Top, setF3Top] = useState(0);

  const [isMapImageLoaded, setIsMapImageLoaded] = useState(false);
  const [mapWidth, setMapWidth] = useState(0);
  const [mapHeight, setMapHeight] = useState(0);

  const mapImageLoaded = () => {
    setMapWidth(map.current.width);
    setMapHeight(map.current.height);

    var context = canvas.current.getContext("2d");
    var centerX = canvas.current.width / 2;
    var centerY = canvas.current.height / 2;
    var radius = 18.5;

    context.beginPath();
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    context.fillStyle = "red";
    context.fill();
    context.lineWidth = 3.8;
    context.strokeStyle = "white";
    context.stroke();

    //setF3Left(106);
    //setF3Top(270);
  };

  //   useEffect(() => {
  //     debugger;
  //     isMapImageLoaded && mapImageLoaded();
  //   }, [isMapImageLoaded]);

  const preparenewobject = (e, field) => {
    if (field === "all") {
      for (var i = 0; i < fields.length; i++) {
        fields[i].status = e.target.checked ? 1 : 0;
        fields[i].checked = e.target.checked;
      }
    } else {
      for (var i = 0; i < fields.length; i++) {
        if (fields[i].field === field) {
          if (fields[i].status === 0) fields[i].status = 1;
          else fields[i].status = 0;
          fields[i].checked = e.target.checked;
        }
      }
    }
    //console.log(JSON.stringify(fields));
    setFields([...fields]);

    var openFields = fields.filter((field) => field.status === 1);

    if ((field === "all" && e.target.checked) || openFields.length === 6) {
      setCurrentSrc(AllFieldsOpen);
    } else if (
      (field === "all" && e.target.checked === false) ||
      openFields.length === 0
    ) {
      setCurrentSrc(AllFieldsClose);
    } else {
      var f1 = 0,
        f2 = 0,
        f3 = 0,
        f4 = 0,
        f5 = 0,
        f6 = 0;
      for (var i = 0; i < fields.length; i++) {
        if (fields[i].status === 1) {
          if (
            fields[i].field === "SN0013A20041BA6B7B" ||
            fields[i].field === "SN0013A20041BAC472"
          ) {
            f1 = 1;
          } else if (fields[i].field === "SN0013A20041BAC648") {
            f3 = 1;
          } else if (
            fields[i].field === "SN0013A20041BAC603" ||
            fields[i].field === "SN0013A20041BAC654"
          ) {
            f4 = 1;
          } else if (fields[i].field === "SN0013A20041BAC4D5") {
            f6 = 1;
          }
        }
      }
      if (f1 === 1) {
        setCurrentSrc(f_1_2_Open);
      }
      if (f1 === 1 && f3 === 1) {
        setCurrentSrc(f_1_2_3_Open);
      }
      if (f1 === 1 && f3 === 1 && f4 === 1) {
        setCurrentSrc(f_1_2_3_4_5_Open);
      }
      if (f1 === 0) {
        setCurrentSrc(f_1_2_Close);
      }
      if (f1 === 0 && f3 === 0) {
        setCurrentSrc(f_1_2_3_Close);
      }
      if (f1 === 0 && f3 === 0 && f4 === 0) {
        setCurrentSrc(f_1_2_3_4_5_Close);
      }
    }
  };

  return (
    <div>
      {/* <AppBar position="static" style={{ background: "none" }}>
        <Toolbar>
          <Box display="flex" alignItems="center" justifyContent="space-evenly">
            <div class="logoBlock">
              <img
                src={Logo}
                alt="Logo"
                className={classes.logo}
                style={{ padding: 2 }}
              />
            </div>
            <h3 class="title">Morrisville Smart City Dashaboard</h3>
            <input type="text" class="search display-xs-0" />
          </Box>
        </Toolbar>
      </AppBar> */}
      <div class="contentWrapper">
        <Grid container>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12}>
            <h3 class="subtitle">
              Morrisville Field{" "}
              <small class="gray">
                25800 Cricket Pitch Way Morrisville, NC 27560
              </small>
            </h3>
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>
            <Grid container className={classes.sm_hidden}>
              <Grid item xs={12}>
                <img
                  ref={map}
                  src={currentSrc}
                  alt="Field Map"
                  onLoad={() => {
                    setIsMapImageLoaded(true);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.sm_visible}>
              <Grid item xs={12}>
                <img
                  ref={map}
                  src={currentSrc}
                  alt="Field Map"
                  onLoad={() => {
                    setIsMapImageLoaded(true);
                  }}
                  width="350"
                  height="209"
                />
              </Grid>
            </Grid>
            {/* <canvas
              ref={canvas}
              width={mapWidth}
              height={mapHeight}
              style={{ position: "absolute", left: { f3Left }, top: { f3Top } }}
            /> */}
            {/* <img
              ref={map}
              src={currentSrc}
              alt="Field Map"
              onLoad={() => {
                setIsMapImageLoaded(true);
              }} 
            />*/}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            className={classes.textCenter}
            style={{ margin: "auto" }}
          >
            <div
              class="box-body"
            // style={{ width: "30%", marginLeft: "35%", marginTop: "-5%" }}
            >
              <div class="divGrid">
                <div class="divGridHeading">
                  <div class="divGridRow">
                    <div class="divGridHead">
                      <Checkbox
                        color="primary"
                        style={{ padding: "2px" }}
                        onChange={(e) => preparenewobject(e, "all")}
                      />
                      Field
                    </div>
                    <div class="divGridHead">Status</div>
                  </div>
                </div>
                <div class="divGridBody">
                  {fields || fields.length > 0
                    ? fields.map((m) => (
                      <div class="divGridRow">
                        <div class="divGridCell">
                          <Checkbox
                            color="primary"
                            name={m.fieldName}
                            checked={m.checked}
                            style={{ padding: "2px" }}
                            key={m.field}
                            onChange={(e) => preparenewobject(e, m.field)}
                          />
                          {m.fieldName}
                        </div>

                        <div class="divGridCell">
                          <div class="d-flex">
                            {m.checked == 0 ? "Closed" : "Open"}
                          </div>
                        </div>
                      </div>
                    ))
                    : ""}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
