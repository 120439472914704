import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import { sensorService } from '../services/sensorService'

export const initialState = {
    isLoading: false,
    hasLoaded: false,
    data: []
}

export const sensorServiceThunk = createAsyncThunk(
    "get/sensors",
    async (parkid, payloadObj) => {
        return await sensorService(parkid, payloadObj)
    }
);

const sensors = createSlice({
    name: 'sensors',
    initialState,
    reducers: {
        unmountSensors: () => {
            return { ...initialState }
        }
    },
    extraReducers: builder => {
        builder.addCase(sensorServiceThunk.pending, (state, action) => {
            state.isLoading = true;
            state.hasLoaded = false;
        });
        builder.addCase(sensorServiceThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.hasLoaded = true
            state.data = action.payload;
        });
        builder.addCase(sensorServiceThunk.rejected, (state, action) => {
            //TODO
        });
    }
})

export const stateSelector_ = ({ sensors }) => sensors;

export const sensors_ = createSelector(stateSelector_, (sensors) => sensors.data.data)

export const sensorsHasLoaded_ = createSelector(stateSelector_, (sensors) => sensors.hasLoaded)

export const { unmountSensors } = sensors.actions

export default sensors.reducer
