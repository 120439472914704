import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { GenerateEncryptedToken } from "../../views/Common";
import smartParkService from "../../axios";
import { sensorServiceThunk } from "../../reducers/sensors";
import _ from "lodash";
import { withStyles } from "@material-ui/core";
import { login_, unmountLogin, token_ } from "../../reducers/login";

const ButtonSm = withStyles((theme) => ({
  root: {
    fontSize: 13,
    textTransform: "none",
    borderRadius: "100px",
  },
}))(Button);
const ModalDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      padding: "25px",
    },
  },
}))(Dialog);

export default function GolfCloseallpark(props) {
  //   const dispatch = useDispatch();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [yes, setYes] = React.useState("");
  const allparkdata = props.pname;
  const parkname = props.parkname;
  const parokopenclosedta = props.parkopenclose;
  const [isValid, setIsvalid] = React.useState(true);
  //   const parkrealname = parkname === "cfdp" ? "Cedar Fork District Park" : parkname === "churchstreettenniscourts" ? "Church Street Park" : parkname === "mafctenniscourts" ? "Morrisville Aquatics & Fitness Center" : "Morrisville Community Park";
  //   const loginDetails = useSelector(login_);
  //   const userid = _.get(loginDetails, ["UserID"], "");
  const [getParkNameStatus, setParkNameStatus] = React.useState("");

  function handleSubmit() {
    console.log(parokopenclosedta);
    var statusopenclose;
    if (parokopenclosedta.toLowerCase() === "open") {
      statusopenclose = "Open";
    }
    if (parokopenclosedta.toLowerCase() === "closed") {
      statusopenclose = "Closed";
    }
    let data = props.pname.map((m) => m.fieldid);
    var requestBody = {
      HoleID: data,
      HoleStatus: statusopenclose,
      modifiedBy: "Vikas",
    };

    console.log(requestBody);
    if (yes.trim().toLowerCase() == "yes") {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      // if (params.get('adminmode') === "1")
      var url = `api/events/golf-demo/golf/${props.parkname.golfID}/updatehole`;
      var restoken = GenerateEncryptedToken();
      smartParkService
        .post(url, requestBody, {
          headers: {
            "x-access-token": restoken,
            //   "userid": userid
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // Retrieve existing data from localStorage
            const existingData =
              JSON.parse(localStorage.getItem("GolfOverlayID")) || [];
            const newValue = props?.parkname?.golfID; // Replace with the actual value
            // Check if an entry with the same golfID already exists
            const existingEntryIndex = existingData.findIndex(
              (entry) => entry.golfID === newValue
            );
            if (existingEntryIndex !== -1) {
              // Update the status value for the existing entry
              existingData[existingEntryIndex].status = parokopenclosedta;
            } else {
              // Create a new entry with the status and ID
              const newEntry = {
                status: parokopenclosedta,
                golfID: newValue,
              };
              // Add the new entry to the existing data array
              existingData.push(newEntry);
            }
            // Save the updated array back to localStorage
            localStorage.setItem("GolfOverlayID", JSON.stringify(existingData));
            // Log the updated localStorage values
            const respData = res.data;
            const errResp =
              respData &&
              respData.length > 0 &&
              respData.filter((obj) => {
                return obj.status !== 200;
              });
            let errFieldNames =
              errResp &&
              errResp.length > 0 &&
              errResp.map((obj) => {
                return obj.gate;
              });
            if (errFieldNames && errFieldNames.length > 0) {
              alert(
                "Error occurred while changing " +
                errFieldNames.join(", ") +
                " gate(s) status"
              );
            }
          }
          setYes("");
          props.closePopup();
          props.overlay();
        })
        .catch((err) => {
          setYes("");
          props.closePopup();
          alert("Error occurred while updating device status");
          console.log(err);
        });
    } else {
      setIsvalid(false);
    }
  }

  return (
    <div>
      <ModalDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.show}
        aria-labelledby="field-model"
        className="golfcloseallmodal"
      >
        <DialogTitle id="field-model-title">
          <strong>
            <div>
              {parokopenclosedta.toLowerCase() === "open" ? "Open" : "Close"} All Holes at{" "}
              {props.parkname.name}
            </div>
          </strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div class="plainGrid fc-width50 mobilewidth">
              <div class="plainGridHeader">
                <div class="plainGridRow">
                  <div class="plainGridHead">Hole Name</div>
                  <div class="plainGridHead">Current Status</div>
                </div>
              </div>
              <div class="plainGridBody">
                {props.pname.map((m) => (
                  <div class="plainGridRow">
                    <div class="plainGridCell">{m.fieldname}</div>
                    <div class="plainGridCell">
                      {m.fieldstatus.toLowerCase() == "open" && m.fieldfinalStatus !== ""
                        ? m.fieldfinalStatus
                        : m.fieldstatus}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <br />
            <p style={{ fontSize: "0.9rem" }}>
              Please type "YES" below to confirm the selected Golf Course
            </p>
            <br />
            <TextField
              placeholder="Type YES for confirmation"
              variant="outlined"
              size="small"
              color="primary"
              value={yes}
              onChange={(e) => setYes(e.target.value)}
            />
          </DialogContentText>
          {isValid ? "" : <div>Please Type YES for confirmation</div>}
        </DialogContent>
        <DialogActions>
          <ButtonSm
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            {parokopenclosedta.toLowerCase() === "open" ? "Open" : "Close"} All
          </ButtonSm>
          <ButtonSm
            size="small"
            color="primary"
            variant="outlined"
            onClick={props.closePopup}
          >
            Cancel
          </ButtonSm>
        </DialogActions>
      </ModalDialog>
    </div>
  );
}
