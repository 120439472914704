import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import smartParkService from "../../axios";
import { login_, token_ } from "../../reducers/login";
import _ from "lodash";

const localTimeZoneOffset = ((new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000);

function TemperatureChart(props) {
	const loginDetails = useSelector(login_);
	const parkid = _.get(loginDetails, ['parkID'], '');
	const token = useSelector(token_);
	smartParkService.defaults.headers.common['x-access-token'] = token;

	const [precipitationdata, setprecipitationdata] = useState([]);
	const [temperaturedata, settemperaturedata] = useState([]);
	const [selectedDateRange, setselectedDateRange] = useState({})

	useEffect(() => { getData(); }, [ props.selectedDateRange.start._d,props.selectedDateRange.end._d ]);

	const getMarker = (value) =>{
		console.log("value",value);
		if(value>45){
			return  'url(https://www.highcharts.com/samples/graphics/sun.png)';
		}
		else return '';
	}
	const getData = () => {
		let startDate = new Date("01/01/2020").toISOString();
		let endDate = new Date().toISOString();

		if (props.selectedDateRange) {
			var dates = props.selectedDateRange;
			startDate = new Date(dates.start._d).getTime();
			endDate = new Date(dates.end._d).getTime();
		}

		smartParkService.get("api/park/" + parkid + "/getweather?startDate=" + (startDate + localTimeZoneOffset) + "&endDate=" + (endDate + localTimeZoneOffset)).then((res) => {
			if (res) {
				var precipitation = res.data.data.precipitation;
				var temperature = res.data.data.temperature;
				var precipitationArray = precipitation.map(function (pre) { return [(Number(pre[0]) - localTimeZoneOffset), pre[1]] });
				var temperatureArray = temperature.map(function (pre) { return [(Number(pre[0]) - localTimeZoneOffset), pre[1]] });
				//var temperatureArray = temperature.map(function (pre) { return {"x":(Number(pre[0]) - localTimeZoneOffset), "y":pre[1], marker: {symbol: getMarker(pre[1])}}});
				setprecipitationdata(precipitationArray)
				settemperaturedata(temperatureArray);
			} else {
			}
		});
	}

	return (
		<div className="App">
			{
				<HighchartsReact
					highcharts={HighStock}
					constructorType={"stockChart"}
					options={getConfig(precipitationdata, temperaturedata)}
				/>
			}
		</div>
	);

}

const getConfig = (precipitation, temperature) => ({
	credits: {
		enabled: false
	  },
	time: {
		useUTC: false
	},
	rangeSelector: {
		selected: 1,
		enabled: false,// To hide the zoom 1m 3m and Input fields 
	},
	navigator: {
		enabled: true
	},
	chart: {
		zoomType: 'xy',
		events: {
			load: function (event) {
				event.target.reflow();
			}
		}
	},
	xAxis: {
		type: 'datetime',
		labels: {
			format: '{value:%l:%M %p}'
		},
	},
	yAxis: [{ // Pri3y yAxis
		labels: {
			format: '{value}',
		},
		title: {
			text: 'Temperature(F)',
		}
	}, { // Secondary yAxis
		title: {
			text: 'Precipitation in inches',
		},
		labels: {
			format: '{value}',
		},
		opposite: false
	}],
	tooltip: {
		split: true,
		xDateFormat: "%A, %b %e, %I:%M %p",
		pointFormatter: function () {
			var point = this;
			return '<b><span style="color:' + point.color + '">\u25CF</span> ' + point.y.toFixed(2) + (point.series.name.indexOf("Temperature") > -1 ? ' °F' : ' in') + '</b><br/>';
		}
	},
	legend: {
		layout: 'vertical',
		align: 'left',
		x: 120,
		verticalAlign: 'top',
		y: 100,
		floating: true,
		backgroundColor: 'red'
	},
	series: [
		{
			name: 'Precipitation',
			type: 'column',
			yAxis: 1,
			data: precipitation,
			tooltip: {
				valueSuffix: ' in'
			}

		},
		{
			name: 'Temperature',
			type: 'spline',
			marker: {
				enabled: true,
			},
			tooltip: {
				valueSuffix: ' °F'
			},
			data: temperature
		}]
});
export default TemperatureChart;