import React from "react";

import Weather from "./Weather";
import Forecast from "./Forecast";

import "../styles/WeatherAndForecast.css";

function WeatherAndForecast({ weatherInfo, location }) {
  const date = dateBuilder(new Date());

  function dateBuilder(d) {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const day  = d.getDay()

    return days.slice(day).concat(days.slice(0,day))
  }

  return (
    <div className="WeatherAndForecast">
      <Weather weatherInfo={weatherInfo} location={location} date={date[0]} />
      <div className="WeatherAndForecast__container">
        <Forecast weatherInfo={weatherInfo.daily[0]} date={date[0]} />
        <Forecast weatherInfo={weatherInfo.daily[1]} date={date[1]} />
        <Forecast weatherInfo={weatherInfo.daily[2]} date={date[2]} />
        <Forecast weatherInfo={weatherInfo.daily[3]} date={date[3]} />
        <Forecast weatherInfo={weatherInfo.daily[4]} date={date[4]} />
        <Forecast weatherInfo={weatherInfo.daily[5]} date={date[5]} />
        <Forecast weatherInfo={weatherInfo.daily[6]} date={date[6]} />
      </div>
    </div>
  );
}

export default WeatherAndForecast;
