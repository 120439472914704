import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabsComponent({
  tabsList = ["Home"],
  setActiveTab = () => {
    console.log("no function");
  },
}) {
  const classes = useStyles();
  const [selectedTab, setselectedTab] = useState();
  const handleChange = (event, newValue) => {
    setselectedTab(newValue);
  };

  useEffect(() => {
    setselectedTab(0);
  }, []);

  useEffect(() => {
    setActiveTab(selectedTab);
  }, [selectedTab]);

  return (
    <div style={{ marginBottom: "1rem" }} className={classes.root}>
      <AppBar position="static" color="default">
        <Box display="flex" justifyContent="center" width="100%">
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
          >
            {tabsList.map((tab) => {
              return <Tab label={tab} />;
            })}
          </Tabs>
        </Box>
      </AppBar>
    </div>
  );
}
