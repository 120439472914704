import { AccessTimeOutlined } from "@material-ui/icons";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { loginService } from "../services/login";

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {
    FirstName: "",
    LastName: '',
    EmailAddress: "",
    UserID: "",
    Role: [],
  },
  usersessionid: "",
};

export const loginAuth = createAsyncThunk("post/users", async (payloadObj) => {
  return await loginService(payloadObj);
});
/**
 * Here we add code for golf demo
 */
export const golfLogiin = createAsyncThunk("golfLogiin", async (payloadObj) => {
  const payload = {
    data: {
      FirstName: "Golf Course",
      EmailAddress: "info@samanalytic.com",
      UserID: Math.floor(Math.random() * 100),
      Role: [],
    },
    token: Math.floor(Math.random() * 100),
    usersessionid: Math.floor(Math.random() * 100),
  }
  return payload;
});


const login = createSlice({
  name: "login",
  initialState,
  reducers: {
    unmountLogin: () => {
      return { ...initialState };
    },
    updatesessionid(state, action) {
      state.usersessionid = action.payload;
    },
    updateloginState(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAuth.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loginAuth.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.data = action.payload.data;
      state.token = action.payload.token;
      state.usersessionid = action.payload.usersessionid;
    });
    builder.addCase(golfLogiin.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(golfLogiin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.data = action.payload.data;
      state.token = action.payload.token;
      state.usersessionid = action.payload.usersessionid;
    });
    builder.addCase(loginAuth.rejected, (state, action) => {
      //TODO
    });
  },
});

export const stateSelector_ = ({ login }) => login;

export const login_ = createSelector(stateSelector_, (login) => login.data);

export const hasLoaded_ = createSelector(
  stateSelector_,
  (login) => login.hasLoaded
);
export const usersessionid_ = createSelector(
  stateSelector_,
  (login) => login.usersessionid
);
export const token_ = createSelector(stateSelector_, (login) => login.token);

export const { unmountLogin, updatesessionid, updateloginState } =
  login.actions;

export default login.reducer;
