import * as React from "react";
import Link from "@material-ui/core/Link";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import RichTextEditor from "react-rte";
import { css } from "@emotion/core";
import Paper from "@material-ui/core/Paper";
import "../../assets/scss/App.scss";
import styles from "../../components/components.js";
import notificaitonStyles from "./styles";
import Button from "@material-ui/core/Button";
import smartParkService from "../../axios";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../../reducers/permissions";

const useStyles = makeStyles(styles);
const useNotificaitonStyles = makeStyles(notificaitonStyles);

export default function Notification(props) {
  const classes = useStyles();
  const history = useHistory();
  const notify = useNotificaitonStyles();
  const loginDetails = useSelector(login_);
  const userid = _.get(loginDetails, ["UserID"], "");
  const ParkID = _.get(loginDetails, ["parkID"], "");
  const Name = _.get(loginDetails, ["FirstName"], "");
  const token = useSelector(token_);
  const [permission, setpermission] = React.useState([]);
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [emailMessage, setEmailMessage] = React.useState(
    RichTextEditor.createValueFromString("<p></p>", "html")
  );
  const [smsmessage, setsmsmessage] = React.useState("");
  React.useEffect(() => {
    var GranularnotificationID = "81786b29-6a37-43c2-a4c5-ea77f2bc1020";
    smartParkService
      .get(
        "api/park/" +
        ParkID +
        "/Granularnotification?GranularnotificationID=" +
        GranularnotificationID
      )
      .then((res) => {
        // console.log(res);
        if (res.data.status) {
          setEmailMessage(
            RichTextEditor.createValueFromString(
              res.data.data.EmailAddressTemplete,
              "html"
            )
          );
          setsmsmessage(res.data.data.MobilePhoneTemplete);
        } else {
        }
      });
  }, []);

  React.useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);
  const onChange = (value) => {
    setEmailMessage(value);
  };
  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "NotificationTemplate";
    });
    setpermission(Dpermissions[0]);
  };

  const SaveNotifyTemplete = () => {
    var obj = {};
    obj.GranularnotificationID = "81786b29-6a37-43c2-a4c5-ea77f2bc1020";
    obj.EmailAddressTemplete = emailMessage
      .toString("html")
      .replace(/<(?:.|\n)*?>/gm, "<br/>");
    obj.MobilePhoneTemplete = smsmessage;
    obj.LastEditedBy = Name;
    smartParkService
      .post("api/park/" + ParkID + "/Granularnotification", obj)
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          history.push("/AdminDashboard");
        }
      })
      .catch(() => { });
  };
  return (
    <div className={classes.root}>
      <div className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Notification Template</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                  Notifications Template
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <Grid container>
            <div class="content-middle">
              <Paper className={classes.paper + " " + classes.withStripe}>
                <div style={{ width: "100%" }}>
                  <div className={notify.emailmessage}>
                    <label className={notify.label}>Email message </label>
                    <RichTextEditor
                      className={notify.richTextEditor}
                      value={emailMessage}
                      onChange={onChange}
                    />
                  </div>

                  <div className={notify.smsmessage}>
                    <label className={notify.label}>SMS message </label>
                    <textarea
                      className={notify.textarea}
                      value={smsmessage}
                      onChange={(e) => {
                        setsmsmessage(e.target.value);
                      }}
                    />
                  </div>

                  <div
                    className={notify.actionButtons}
                    onClick={SaveNotifyTemplete}
                  >
                    {permission.create == 1 ? (
                      <Button size="small" color="primary" variant="contained">
                        Save
                      </Button>
                    ) : (
                      ""
                    )}

                    <Button size="small" variant="contained">
                      Cancel
                    </Button>
                  </div>
                </div>
              </Paper>
            </div>
          </Grid>
        </section>
      </div>
    </div>
  );
}
