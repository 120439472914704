/* eslint-disable no-loop-func */
import React, { useState, useEffect, useCallback } from "react";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import smartParkService from "../../axios";
import { useSelector, useDispatch } from "react-redux";
import { login_, token_ } from "../../reducers/login";
import { sensors_, sensorsHasLoaded_ } from "../../reducers/sensors";
import { GenerateEncryptedToken } from "../../views/Common";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { fieldmoisturethunk } from "../../reducers/fieldmoisture";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

var labels = { 0: "Close", 5: "", 10: "Open" };
// Timezone offset in minutes/60 = hours
// hours * 60 = total minutes
// total minutes * 60 = total seconds
// total seconds * 1000 = total difference in milliseconds
const localTimeZoneOffset =
  (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;
var graphs = [];
let setCsvData;
function prepareGraphPlot(data) {
  try {
    graphs = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i] && data[i].length) {

        graphs.push({
          name: data[i][0].field,
          type: "spline",
          data: (function () {
            var ohlcData = [];
            var linemockData = data[i];
            for (var j = 0; j < linemockData.length; j++) {
              ohlcData.push([
                Number(new Date(linemockData[j]["date"]).getTime()) - localTimeZoneOffset, // the date
                Number(linemockData[j]["moisture"]), // moisture
              ]);
            }
            return ohlcData;
          })(),
        });
      }
    }
    return graphs;
  } catch (error) {
    console.log("Error in prepareGraphPlot(). Exception Message: " + error);
  }
}
//console.log(graphs);
function GolfCourseChart(props) {
  const loginDetails = useSelector(login_);
  const dispatch = useDispatch();
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();

  const sensors = useSelector(sensors_);
  const sensorHasLoaded = useSelector(sensorsHasLoaded_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const fields = props.selectedFileds || [];
  const [color, setColor] = React.useState("#ffffff");
  const [dataResults, setdataResults] = useState([]);
  const [MaxY, setMaxY] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedFields, setselectedFields] = useState([props.selectedFields]);
  const [selectedDateRange, setselectedDateRange] = useState({});
  const [sensorList, setSensorList] = React.useState([]);

  // define state for store graph data for download csv
  //const [CsvData, setCsvData] = React.useState([]);
  function getUpdatedGrpahParameters(selectedFileds, min, max) {
    //console.log("selectedfields", selectedfields);
    var fields = [];
    if (selectedFileds) {
      var selectedfields = selectedFileds;
      for (var i = 0; i < selectedfields.length; i++) {
        selectedfields[i] &&
          selectedfields[i].value &&
          fields.push(selectedfields[i].value);
      }
    }

    return `api/citizen/moisturev1/hour?startDate=${min + localTimeZoneOffset
      }&endDate=${max + localTimeZoneOffset}&fields=${fields.join(",")}`;
  }
  const debouncedSave = useCallback(
    _.debounce((nextValue) => {
      //console.log("selectedFileds", props.selectedFileds);
      const { event, selectedFields } = nextValue;
      //console.log("nextValue", nextValue);
      let min = event.min;
      let max = event.max;
      let interval = max - min;
      //console.log("useCallback");
      dispatch(
        fieldmoisturethunk(
          getUpdatedGrpahParameters(props.selectedFileds, min, max)
        )
      );
    }, 1000),
    [] // will be created only once initially
  );
  const getConfig = (mockData) => ({
    credits: {
      enabled: false,
    },
    chart: {
      type: "spline",
      events: {
        load: function (event) {
          event.target.reflow();
        },
      },
    },
    showInLegend: true,
    rangeSelector: {
      selected: 1,
      enabled: false, // To hide the zoom 1m 3m and Input fields
      // inputEnabled: false,
      // buttonTheme: {
      //     visibility: 'hidden'
      // },
      // labelStyle: {
      //     visibility: 'hidden'
      // }
    },
    height: "100%",
    title: {
      text: props.graphTitle,// "Field Status and Moisture",
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%l:%M %p}",
      },
      events: {
        /*afterSetExtremes: function (event) {
          let min = event.min;
          let max = event.max;
          let interval = max - min;
          // console.log(interval);
          this.update({
            tickPositioner: function () {
              if (interval <= 86499999) {
                debouncedSave({ event, selectedFields });
              }
            },
          });
        },*/
      },
    },
    yAxis: {
      min: 0,
      max: MaxY + .5,
      events: {
        afterSetExtremes: function (event) {
          this.update({
            max: MaxY,
            tickPositioner: function () {
              setMaxY(event.target.dataMax + .5)
            },
          })
        },
      },
    },
    // currentField="temperature"
    tooltip: {
      split: true,
      xDateFormat: "%A, %b %e, %I:%M %p",
      pointFormatter: function () {
        var point = this;
        var statusTooltip = point.series.name;
        var moistureTooltip = point.series.name;
        if (point.series.name.indexOf("status") == -1) {
          return (
            '<span style="color:' +
            point.color +
            '">\u25CF</span> ' +
            (moistureTooltip + ": " + point.y.toFixed(2)) +
            "</b><br/>"
          );
        } else {
          return false;
        }
      },
    },
    chart: {
      events: {
        load: function (event) {
          event.target.reflow();
        },
      },
    },
    series: prepareGraphPlot(mockData),
  });


  useEffect(() => {
    getData();
  }, [
    props.refresh,
    props.currentHole,
    fields.length,
    props.selectedDateRange.start._d,
    props.selectedDateRange.end._d,
  ]);

  const getData = () => {
    var fields = [];

    if (props.selectedFileds) {
      var selectedfields = props.selectedFileds;
      for (var i = 0; i < selectedfields.length; i++) {
        fields.push(selectedfields[i].value);
      }
    }
    var range = {};
    if (props.selectedDateRange) {

      var dates = props.selectedDateRange;
      //console.log(dates.start._d)
      range.startDate = new Date(dates.start._d).getTime();
      range.endDate = new Date(dates.end._d).getTime();

    }

    var diff = range.endDate - range.startDate;
    var view = "hour";
    if (diff <= 86499999) {
      view = "minute";
    }
    //console.log(sensorList)   
    let url =
      "api/events/golf-demo/golf/" + props.courseData.golfID + "/chart/data?from_date=" +
      ((range.startDate + localTimeZoneOffset) / 1000).toFixed(0) +
      "&to_date=" +
      ((range.endDate + localTimeZoneOffset) / 1000).toFixed(0) +
      "&device_id=" +
      fields.join(",") +
      "&holeId=" +
      props.currentHole

    setLoading(true);
    // setTimeout(()=>{
    smartParkService
      .get(url)
      .then((res) => {
        if (res) {
          //console.log(res);
          setCsvData = res.data.data;
          var graphData = fieldwiseFilter(selectedfields, res.data.data, res.data.details);
          //console.log(props.currentField)
          //console.log(graphData)
          let checkMax = Math.max(...graphData.map(o => {
            return Math.max(...o.map(i => i.moisture))
          }))
          //console.log(checkMax)
          setMaxY(checkMax)
          setdataResults(graphData);
        } else {
          console.log("FloodChart else error: ");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("FloodChart error: ", error);
        setLoading(false);
      });
  };

  const getUniqueSensors = (fieldID) => {
    if (sensorList && sensorList.length > 0) {
      return sensorList.filter((obj) => {
        if (obj.fieldID === fieldID) {
          return obj;
        }
      });
    } else {
      return [];
    }
  };

  const fieldwiseFilter = (fields, data, namedetails) => {
    var filteredData = [];
    if (data && data.length > 0 && data) {
      for (var i = 0; i < data.length; i++) {
        //const uniqueSensors = getUniqueSensors(fields[i].value);
        //if (uniqueSensors && uniqueSensors.length > 0) {
        //for (var j = 0; j < uniqueSensors.length; j++) {
        var graphData = [];
        data[i].graph_data = data[i].graph_data.sort((a, b) => { return new Date(a.timestamp) - new Date(b.timestamp) })
        data[i].graph_data.filter(function (obj) {
          //if (obj.sensor == uniqueSensors[j].SensorCollectionID) {
          //const sensorName = namedetails.find(
          //(detail) => detail.sensor === uniqueSensors[j].SensorCollectionID
          //)?.name;
          graphData.push({
            sensor: data[i].deviceid,//uniqueSensors[j].SensorCollectionID,
            field: (data[i].deviceid == '21735') ? 'Dry Spot - Practice Green' : 'Wet Spot - Practice Green',//sensorName || "Unknown Field",
            date: obj.timestamp,
            moisture: obj[props.currentField],
            status: 1,
          });
          //}
        });
        filteredData.push(graphData);
        //}
        //}
      }
    }
    //console.log('aaaaa')
    //console.log(filteredData)
    return filteredData;
  };

  return (
    <div className="App">
      {loading ? (
        <ClipLoader loading={loading} css={override} size={60} />
      ) : (
        <>
          {" "}
          <HighchartsReact
            highcharts={HighStock}
            constructorType={"stockChart"}
            options={getConfig(dataResults)}
          />
        </>
      )}
    </div>
  );
}

const csvFieldFilter = (data, varType) => {
  var filteredData = [];
  if (data && data.length > 0 && data) {
    for (var i = 0; i < data.length; i++) {
      //const uniqueSensors = getUniqueSensors(fields[i].value);
      //if (uniqueSensors && uniqueSensors.length > 0) {
      //for (var j = 0; j < uniqueSensors.length; j++) {
      var graphData = [];
      data[i].graph_data = data[i].graph_data.sort((a, b) => { return new Date(a.timestamp) - new Date(b.timestamp) })
      data[i].graph_data.filter(function (obj) {
        //if (obj.sensor == uniqueSensors[j].SensorCollectionID) {
        //const sensorName = namedetails.find(
        //(detail) => detail.sensor === uniqueSensors[j].SensorCollectionID
        //)?.name;
        graphData.push({
          sensor: data[i].deviceid,//uniqueSensors[j].SensorCollectionID,
          field: (data[i].deviceid == '21735') ? 'Dry Spot - Practice Green' : 'Wet Spot - Practice Green',//sensorName || "Unknown Field",
          date: obj.timestamp,
          moisture: obj[varType],
          status: 1,
        });
        //}
      });
      filteredData.push(graphData);
      //}
      //}
    }
  }
  return filteredData;
};

const prepareCSVPlot = (data) => {
  try {
    let csvData = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i] && data[i].length) {

        csvData.push({
          name: data[i][0].field,
          type: "spline",
          data: (function () {
            var ohlcData = [];
            var linemockData = data[i];
            for (var j = 0; j < linemockData.length; j++) {
              ohlcData.push([
                Number(new Date(linemockData[j]["date"]).getTime()) - localTimeZoneOffset, // the date
                Number(linemockData[j]["moisture"]), // moisture
              ]);
            }
            return ohlcData;
          })(),
        });
      }
    }
    return csvData;
  } catch (error) {
    console.log("Error in prepareGraphPlot(). Exception Message: " + error);
  }
}

export const myArray = graphs;
export function myfunction_v2(varType) {
  var graphData = csvFieldFilter(setCsvData, varType);
  return prepareCSVPlot(graphData)
  //return graphs; 
};
export default GolfCourseChart;
