const notificaitonStyles = theme =>({ 
    label:{
      width:'100%',
      display:'block'
    },
    emailmessage: {
      width:'100%',
      marginBottom:'20px'
    },
    richTextEditor:{
      minHeight:'200px'
    },
    smsmessage:{ 

    },
    textarea:{
      width:'100%',
      border:'1px solid #ccc',
      minHeight:'80px',
      padding:'10px'
    },
    actionButtons:{
      margin:'10px 0px',
      textAlign:'right',
      '& button':{
        margin:'0px 10px'
      }
    }
});

export default notificaitonStyles;