import React, { Suspense, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { BiSolidCheckCircle } from "react-icons/bi";
import { MdSettings } from "react-icons/md";
import { BiSolidXCircle } from "react-icons/bi";
import alllog from "../../assets/img/alllog.png";
import styles from "../../components/components.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { todosAction } from "../../reducers/reducers.js";
import Button from "../../components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import smartParkService from "../../axios";
import moment from "moment";
import { GenerateEncryptedToken } from "../../views/Common";
import { login_ } from "../../reducers/login";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import plusicontwo from "../../assets/img/whiteplus.png";
import gridtwo from "../../assets/img/gridtwo.png";
import gridone from "../../assets/img/gridone.png";
import {
    permissions_,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { BsToggleOn } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { css } from "@emotion/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { checkPermissions } from "../../utils/checkPermissions.js";
const Tablepaginate = React.lazy(() => import("./Tablepaginate.js"));
// import Tablepaginate from "./Tablepaginate.js";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" position="top" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

export default function NewRule(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loginDetails = useSelector(login_);
    const userid = _.get(loginDetails, ["UserID"], "");
    const [filteredData, setFilteredData] = useState([]);
    const [statusFilter, setStatusFilter] = useState("");
    const [triggerTypeFilter, setTriggerTypeFilter] = useState("");
    const [totalRows, setTotalRows] = React.useState(null);
    const [itemsPerPage, setItemsPerPage] = React.useState(15);
    const [getdeleteToggleconfirm, setdeleteToggleconfirm] = React.useState("");
    const [getdisableRulename, setdisableRulename] = React.useState("");
    const [getdeleteRulepriority, setdeleteRulepriority] = React.useState("");
    const [getdeleteRuledes, setdeleteRuledes] = React.useState("");
    const [getdeleteRulename, setdeleteRulename] = React.useState("");
    const [getdisableRulepriority, setdisableRulepriority] = React.useState("");
    const [getdisableRuledes, setdisableRuledes] = React.useState("");
    const [getdeleteRuleid, setdeleteRuleid] = React.useState([]);
    const [getMultipledisabled, setMultipledisabled] = React.useState([]);
    const [getruleNameText, setruleNameText] = React.useState([]);
    const [getruleDesText, setruleDesText] = React.useState([]);
    const [getpriorityNumberText, setpriorityNumberText] = React.useState([]);
    const [getenabledisablemenutext, setenabledisablemenutext] = React.useState(
        []
    );
    const [permission, setpermission] = React.useState({});
    const permissionsobj = useSelector(permissions_);
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllrow, setSelectAllrow] = React.useState(false);
    // datainput ------=======================================================
    const [getListdata, setListdata] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [color, setColor] = React.useState("#ffffff");
    const [pending, setPending] = React.useState(true);
    const [getDeleterefresh, setDeleterefresh] = React.useState(false);
    const [getdisableRuleid, setdisableRuleid] = React.useState([]);
    const [getdisableRulestatus, setdisableRulestatus] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [multiopen, setmultiOpen] = React.useState(false);
    const [multideleteopen, setmultideleteopen] = React.useState(false);
    const [getToggleconfirm, setToggleconfirm] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);
    const [geteditdata, seteditdata] = React.useState([]);
    const [rows, setRowsdata] = React.useState([]);
    const [getableRuleid, setableRuleid] = React.useState([]);
    const [getableRulestatus, setableRulestatus] = React.useState([]);
    const [getabledisableRulestatus, setabledisableRulestatus] =
        React.useState("");
    const [getableRulename, setableRulename] = React.useState([]);
    const [getableRuledes, setableRuledes] = React.useState([]);
    const [getMobileRuleList, setMobileRuleList] = React.useState([]);
    const [getableRulepriority, setableRulepriority] = React.useState([]);
    const [deleteopen, setdeleteOpen] = React.useState(false);
    const [getBulkActions, setBulkActions] = React.useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [getConfirmMessage, setConfirmMessage] = React.useState(false);
    const [summaryAdddata, setSummaryAddData] = useState({});
    const [getAllRules, setAllRules] = useState(null);
    const history = useHistory();
    const numRows = getableRuleid.length;
    // error States ===========---------------=======================
    const [refresh, setRefresh] = useState(false);
    // for related rule =================
    const [getvalidityRuleIsAlwaysOn, setvalidityRuleIsAlwaysOn] =
        React.useState("");
    const [getvalidityRuleStartDate, setvalidityRuleStartDate] =
        React.useState("");
    const [getvalidityRuleEndDate, setvalidityRuleEndDate] = React.useState("");
    const [getRelatedConditon, setRelatedConditon] = React.useState([]);
    const [getRelatedAction, setRelatedAction] = React.useState([]);
    const [getRelatedConditionActions, setRelatedConditionActions] =
        React.useState(false);
    const [getcheckrow, setcheckrow] = React.useState(false);
    const [getTotalRowselect, setTotalRowselect] = React.useState("");
    const [allCheckRule, setAllCheckRule] = useState([]);
    const [allCheckRuleName, setAllCheckRuleName] = useState("");
    const [allCheckRulePriority, setAllCheckRulePriority] = useState("");
    const [allCheckRuleDes, setAllCheckRuleDes] = useState("");
    const [Changefilter, setChangefilter] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    // for related rule =================
    // form dropdown ===================

    var allf = "";
    allf = localStorage.getItem("addfields");

    function AllByAltdata(getrule) {
        // Initialize arrays outside of the loop
        let updatedSelectedRowsid = [];
        let updatedSelectedRowsname = [];
        let updatedSelectedRowsdes = [];
        let updatedSelectedRowsnum = [];
        let updatedSelectedRowsmenu = [];
        getrule?.forEach((data) => {
            const { RuleID, RuleDescription, RuleName, PriorityValue, Disabled } =
                data;
            if (updatedSelectedRowsid?.includes(RuleID)) {
                updatedSelectedRowsid.splice(updatedSelectedRowsid.indexOf(RuleID), 1);
                updatedSelectedRowsdes.splice(
                    updatedSelectedRowsdes.indexOf(RuleDescription),
                    1
                );
                updatedSelectedRowsname.splice(
                    updatedSelectedRowsname.indexOf(RuleName),
                    1
                );
                updatedSelectedRowsnum.splice(
                    updatedSelectedRowsnum.indexOf(PriorityValue),
                    1
                );
                updatedSelectedRowsmenu.splice(
                    updatedSelectedRowsmenu.indexOf(Disabled),
                    1
                );
            } else {
                updatedSelectedRowsid.push(RuleID);
                updatedSelectedRowsdes.push(RuleDescription);
                updatedSelectedRowsname.push(RuleName);
                updatedSelectedRowsnum.push(PriorityValue);
                updatedSelectedRowsmenu.push(Disabled);
            }
        });
        setMultipledisabled(updatedSelectedRowsid);
        setruleNameText(updatedSelectedRowsname);
        setruleDesText(updatedSelectedRowsdes);
        setpriorityNumberText(updatedSelectedRowsnum);
        setenabledisablemenutext(updatedSelectedRowsmenu);
    }

    const togglecheckSelectAll = () => {
        setSelectAllChecked(!selectAllChecked);
        if (!selectAllChecked) {
            let allRuleIDs = getMobileRuleList?.map((ruledata) => ruledata.RuleID);
            setAllCheckRule(allRuleIDs);
            AllByAltdata(getMobileRuleList);
        } else {
            setAllCheckRule([]);
            setMultipledisabled([]);
            setruleNameText([]);
            setruleDesText([]);
            setpriorityNumberText([]);
            setenabledisablemenutext([]);
        }
    };

    function toggleRowSelectionone(
        e,
        RuleID,
        RuleDescription,
        RuleName,
        PriorityValue,
        Disabled
    ) {
        // console.log(RuleID, RuleDescription, RuleName, PriorityValue, Disabled);
        // setAllCheckRule([]);
        const allid = [...allCheckRule];
        const updatedSelectedRowsid = [...getMultipledisabled];
        const updatedSelectedRowsname = [...getruleNameText];
        const updatedSelectedRowsdes = [...getruleDesText];
        const updatedSelectedRowsnum = [...getpriorityNumberText];
        const updatedSelectedRowsmenu = [...getenabledisablemenutext];
        if (allid?.includes(RuleID)) {
            allid.splice(allid.indexOf(RuleID), 1);
            updatedSelectedRowsid.splice(updatedSelectedRowsid.indexOf(RuleID), 1);
            updatedSelectedRowsdes.splice(
                updatedSelectedRowsdes.indexOf(RuleDescription),
                1
            );
            updatedSelectedRowsname.splice(
                updatedSelectedRowsname.indexOf(RuleName),
                1
            );
            updatedSelectedRowsnum.splice(
                updatedSelectedRowsnum.indexOf(PriorityValue),
                1
            );
            updatedSelectedRowsmenu.splice(
                updatedSelectedRowsmenu.indexOf(Disabled),
                1
            );
        } else {
            allid.push(RuleID);
            updatedSelectedRowsid.push(RuleID);
            updatedSelectedRowsdes.push(RuleDescription);
            updatedSelectedRowsname.push(RuleName);
            updatedSelectedRowsnum.push(PriorityValue);
            updatedSelectedRowsmenu.push(Disabled);
        }
        setAllCheckRule(allid);
        setMultipledisabled(updatedSelectedRowsid);
        setruleNameText(updatedSelectedRowsname);
        setruleDesText(updatedSelectedRowsdes);
        setpriorityNumberText(updatedSelectedRowsnum);
        setenabledisablemenutext(updatedSelectedRowsmenu);
    }

    React.useEffect(() => {
        checkAllData(allCheckRule);
    }, [allCheckRule, allCheckRuleName, allCheckRulePriority, allCheckRuleDes]);

    function checkAllData(allCheckRule) {
        setTotalRowselect(allCheckRule.length);
        setcheckrow(true);
        setIsDisabled(false);
        if (allCheckRule.length == 0) {
            setBulkActions("");
            setcheckrow(false);
            setIsDisabled(true);
        }
    }

    useEffect(() => {
        if (props?.editalldata) {
            setSummaryAddData(props?.editalldata);
        }
    }, [props?.editalldata]);
    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "RuleEngine";
        });
        setpermission(Dpermissions[0]);
    };
    React.useEffect(() => {
        setPending(true);
        // if (getListdata && getListdata.length > 0) {
        const timeout = setTimeout(() => {
            // setRowsdata(getListdata);
            setStatusFilter("");
            setTriggerTypeFilter("");
            setCurrentPage(1);
        }, 2000);
        return () => clearTimeout(timeout);
        // }
    }, [refresh, props?.reloadtable]);

    function Viewlog(logruleid) {
        let path = "RuleLogpage";
        history.push({
            pathname: `${path}`,
            state: { detail: `${logruleid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
        // props.scrollTop();
        // history.push("/RuleLogpage");
    }
    function Auditlog(auditruleid) {
        let path = "AuditLogpage";
        history.push({
            pathname: `${path}`,
            state: { detail: `${auditruleid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
        // history.push("/AuditLogpage");
    }

    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);
    useEffect(() => {
        if (allf !== null && allf !== undefined) {
            let param = allf.split("~");
            addSummary(param[0]);
        }
    }, [allf]);

    function addSummary(e) {
        if (e === "2") {
            setvalidityRuleIsAlwaysOn(summaryAdddata.getOnOFFValue);
            // setvalidityRuleStartDate(timestampconvert(summaryAdddata.startDate));
            // setvalidityRuleEndDate(timestampconvert(summaryAdddata.endDate));
            setvalidityRuleStartDate(summaryAdddata.getOnOFFValue);
            setvalidityRuleEndDate(summaryAdddata.getOnOFFValue);
        }
        if (e === "5") {
            setRelatedConditionActions(false);
            let conditions = [];
            const newConditionsObject = {
                ParkID: summaryAdddata.getparkName,
                ParkName: summaryAdddata.getConditionParkName,
                Devicetype: summaryAdddata.getparkDeviceType,
                DeviceID: summaryAdddata.getparkDeviceId,
                DeviceName: summaryAdddata.getConditonparktype,
                TriggerValue: summaryAdddata.getTriggervalue,
                TriggerCriteria: summaryAdddata.getTriggerCriteria,
                Tolerance: summaryAdddata.getTolerance,
            };
            conditions.push(newConditionsObject);
            setRelatedConditon(conditions);
            setRelatedConditionActions(true);
        }
        if (e === "6") {
            setRelatedConditionActions(false);
            let Actions = [];
            const newActions = {
                ParkID: summaryAdddata.gethandleParkId,
                ParkName: summaryAdddata.getActionpartkname,
                Devicetype: summaryAdddata.gethandleParkDeviceType,
                DeviceID: summaryAdddata.gethandleParkDevice,
                DeviceName: summaryAdddata.getActiondevicetypename,
                Action: summaryAdddata.gethandleOpenClosePark,
            };
            Actions.push(newActions);
            // console.log(Actions);
            setRelatedAction(Actions);
            setRelatedConditionActions(true);
        }
    }

    function groupByDayOfWeek(operationalTime) {
        const groupedData = {};
        operationalTime.forEach((dayData) => {
            const dayOfWeek = dayData.DayofWeek;
            if (!groupedData[dayOfWeek]) {
                groupedData[dayOfWeek] = [];
            }
            groupedData[dayOfWeek].push(dayData);
        });
        return Object.values(groupedData);
    }

    function simpledate(date) {
        try {
            if (!date) {
                return "NEVER";
            }
            const otherZone = moment.tz(date, "America/New_York").format("LL");
            return otherZone;
        } catch {
            return date;
        }
    }

    const functablerow = (itemvalue) => {
        setItemsPerPage(parseInt(itemvalue));
    };

    const funcCurrentpage = (itemvalue) => {
        setCurrentPage(itemvalue);
        setItemsPerPage(itemsPerPage);
    };

    function Dayweekconvert(dayweek) {
        if (dayweek === 0) {
            return "Sun";
        }
        if (dayweek === 1) {
            return "Mon";
        }
        if (dayweek === 2) {
            return "Tue";
        }
        if (dayweek === 3) {
            return "Wed";
        }
        if (dayweek === 4) {
            return "Thu";
        }
        if (dayweek === 5) {
            return "Fri";
        }
        if (dayweek === 6) {
            return "Sat";
        }
    }

    const handleClose = () => {
        setOpen(false);
        setdeleteOpen(false);
        setmultiOpen(false);
        setmultideleteopen(false);
        setToggleconfirm("");
        setdeleteToggleconfirm("");
        setConfirmMessage(false);
        setBulkActions("");
        setcheckrow(false);
        setIsDisabled(true);
        setSelectAllrow(!selectAllrow);
        setSelectedRows([]);
    };

    function trivalue(data, TriggerCriteria, Devicetype) {
        let symbol;
        if (TriggerCriteria === "exactly" || TriggerCriteria === "Exactly") {
            symbol = "==";
        }
        if (TriggerCriteria === "above" || TriggerCriteria === "Above") {
            symbol = ">";
        }
        if (TriggerCriteria === "below" || TriggerCriteria === "Below") {
            symbol = "<";
        }

        if (
            data === "opened" ||
            data === "Opened" ||
            data === "closed" ||
            data === "Closed"
        ) {
            return data;
        } else {
            return `${symbol} ${Devicetype && Devicetype.toLowerCase() === "court"
                    ? data.toLowerCase() === "open"
                        ? "Unoccupied"
                        : "Occupied"
                    : data
                }`;
        }
    }

    function viewrule(ruledataid) {
        // if (permission.create == 1) {
        let path = "rulelistview";
        // setnewrule(true);
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${ruledataid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
        // } else {
        //   alert("You do not have permission to create rule");
        // }
    }
    function EditRule(ruledataid) {
        // if (permission.create == 1) {
        let path = "editrulecreate";
        // setnewrule(true);
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${ruledataid}` }, // Adding state data
        });
        window?.scrollTo(0, 0);
        if (props?.scrollTop && typeof props?.scrollTop === "function") {
            setTimeout(() => {
                props?.scrollTop();
            }, 100);
        }
        // } else {
        //   alert("You do not have permission to create rule");
        // }
    }
    function enabledisablerule(
        toggleruleid,
        togglestatus,
        rulename,
        ruledes,
        rulepriority
    ) {
        setdeleteOpen(false);
        var statusvalue;
        if (togglestatus === false) {
            statusvalue = 0;
        } else {
            statusvalue = 1;
        }
        var enabledisableRuleIds = [];
        enabledisableRuleIds.push(toggleruleid);
        setdisableRuleid(enabledisableRuleIds);
        setdisableRulestatus(statusvalue);
        setdisableRulename(rulename);
        setdisableRuledes(ruledes);
        setdisableRulepriority(rulepriority);
        setOpen(true);
    }
    function multienablerule(
        toggleruleid,
        rulename,
        ruledes,
        rulepriority,
        togglestatus,
        togglestate
    ) {
        setConfirmMessage(false);
        if (
            toggleruleid?.length > 0 &&
            rulename?.length > 0 &&
            ruledes?.length > 0 &&
            rulepriority?.length > 0 &&
            togglestatus?.length > 0
        ) {
            try {
                setabledisableRulestatus(togglestate);
                setableRuleid(toggleruleid);
                setableRulename(rulename);
                setableRuledes(ruledes);
                setableRulepriority(rulepriority);
                setableRulestatus(togglestatus);
                setmultiOpen(true);
            } catch {
                console.log("Some error");
            }
        }
    }
    function multidisablerule(
        toggleruleid,
        rulename,
        ruledes,
        rulepriority,
        togglestatus,
        togglestate
    ) {
        setConfirmMessage(false);
        if (
            toggleruleid?.length > 0 &&
            rulename?.length > 0 &&
            ruledes?.length > 0 &&
            rulepriority?.length > 0 &&
            togglestatus?.length > 0
        ) {
            try {
                setabledisableRulestatus(togglestate);
                setableRuleid(toggleruleid);
                setableRulename(rulename);
                setableRuledes(ruledes);
                setableRulepriority(rulepriority);
                setableRulestatus(togglestatus);
                setmultiOpen(true);
            } catch {
                console.log("Some error");
            }
        }
    }

    async function togglerule() {
        if (
            getToggleconfirm === "" ||
            (getToggleconfirm.toLowerCase() !== "yes" &&
                getToggleconfirm !== "YES" &&
                getToggleconfirm !== "Yes")
        ) {
            setConfirmMessage(true);
            return false;
        }
        setAllCheckRule([]);
        setMultipledisabled([]);
        setruleNameText([]);
        setruleDesText([]);
        setpriorityNumberText([]);
        setenabledisablemenutext([]);
        setSelectAllChecked(false);
        if (
            (getToggleconfirm.toLowerCase() == "yes" &&
                getToggleconfirm == "YES" &&
                getToggleconfirm == "Yes") ||
            getToggleconfirm !== ""
        ) {
            if (getdisableRulestatus !== "" && getdisableRuleid.length > 0) {
                handleClose();
                let url = "api/park/ruleengine/togglestatus";
                var restoken = GenerateEncryptedToken();
                var requestBody = {
                    Status: getdisableRulestatus,
                    RuleIDs: getdisableRuleid,
                };
                await smartParkService
                    .post(url, requestBody, {
                        headers: {
                            "x-access-token": restoken,
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (res) {
                            setDeleterefresh(true);
                            if (res.status === 200) {
                                if (res.data.status === "200") {
                                    const mes = (
                                        <div className="toastsuccess">
                                            <div className="toasticon">
                                                <BiSolidCheckCircle />
                                            </div>
                                            <div className="toastmessage">
                                                <b>Success</b>
                                                <div>{res.data.message}</div>
                                            </div>
                                        </div>
                                    );
                                    toast.success(mes, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                        className: "toastcontainer",
                                    });
                                    setDeleterefresh(true);
                                    setSelectedRows([]);
                                } else {
                                    const mes = (
                                        <div className="toastsuccess">
                                            <div className="erroricon">
                                                <BiSolidXCircle />
                                            </div>
                                            <div className="toastmessage">
                                                <b>Error</b>
                                                <div>{res.data.message}</div>
                                            </div>
                                        </div>
                                    );
                                    toast.error(mes, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                        className: "errorcontainer",
                                    });
                                    setDeleterefresh(true);
                                    setSelectedRows([]);
                                }
                            }
                        }
                    });
            }
        }
    }

    async function multitogglerule() {
        if (
            getToggleconfirm === "" ||
            (getToggleconfirm.toLowerCase() !== "yes" &&
                getToggleconfirm !== "YES" &&
                getToggleconfirm !== "Yes")
        ) {
            setConfirmMessage(true);
            return false;
        }
        setAllCheckRule([]);
        setMultipledisabled([]);
        setruleNameText([]);
        setruleDesText([]);
        setpriorityNumberText([]);
        setenabledisablemenutext([]);
        setSelectAllChecked(false);
        if (
            (getToggleconfirm.toLowerCase() == "yes" &&
                getToggleconfirm == "YES" &&
                getToggleconfirm == "Yes") ||
            getToggleconfirm !== ""
        ) {
            if (getabledisableRulestatus !== "" && getableRuleid?.length > 0) {
                var newStatus;
                if (getabledisableRulestatus === "false") {
                    newStatus = 1;
                }
                if (getabledisableRulestatus === "true") {
                    newStatus = 0;
                }
                handleClose();
                // console.log(newStatus);
                let url = "api/park/ruleengine/togglestatus";
                var restoken = await GenerateEncryptedToken();
                var requestBody = {
                    Status: newStatus,
                    RuleIDs: getableRuleid,
                };
                await smartParkService
                    .post(url, requestBody, {
                        headers: {
                            "x-access-token": restoken,
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (res) {
                            // handleClose();
                            if (res.status === 200) {
                                if (res.data.status === "200") {
                                    const mes = (
                                        <div className="toastsuccess">
                                            <div className="toasticon">
                                                <BiSolidCheckCircle />
                                            </div>
                                            <div className="toastmessage">
                                                <b>Success</b>
                                                <div>{res.data.message}</div>
                                            </div>
                                        </div>
                                    );
                                    toast.success(mes, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                        className: "toastcontainer",
                                    });
                                    setDeleterefresh(true);
                                    setSelectedRows([]);
                                } else {
                                    const mes = (
                                        <div className="toastsuccess">
                                            <div className="erroricon">
                                                <BiSolidXCircle />
                                            </div>
                                            <div className="toastmessage">
                                                <b>Error</b>
                                                <div>{res.data.message}</div>
                                            </div>
                                        </div>
                                    );
                                    toast.error(mes, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                        className: "errorcontainer",
                                    });
                                    setDeleterefresh(true);
                                    setSelectedRows([]);
                                }
                            }
                        }
                    });
            }
        }
    }

    function deletethisrule() {
        if (
            getdeleteToggleconfirm == "" ||
            (getdeleteToggleconfirm.toLowerCase() !== "yes" &&
                getdeleteToggleconfirm !== "YES" &&
                getdeleteToggleconfirm !== "Yes")
        ) {
            setConfirmMessage(true);
            return false;
        }
        setAllCheckRule([]);
        setMultipledisabled([]);
        setruleNameText([]);
        setruleDesText([]);
        setpriorityNumberText([]);
        setenabledisablemenutext([]);
        setSelectAllChecked(false);
        if (
            (getdeleteToggleconfirm.toLowerCase() == "yes" &&
                getdeleteToggleconfirm == "YES" &&
                getdeleteToggleconfirm == "Yes") ||
            getdeleteToggleconfirm !== ""
        ) {
            if (getdeleteRuleid && getdeleteRuleid.length > 0) {
                let url = "api/park/ruleengine/deleterules";
                var restoken = GenerateEncryptedToken();
                var requestBody = {
                    RuleIDs: getdeleteRuleid,
                };
                smartParkService
                    .post(url, requestBody, {
                        headers: {
                            "x-access-token": restoken,
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (res) {
                            if (res.data.status == 200) {
                                handleClose();
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="toasticon">
                                            <BiSolidCheckCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Success</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.success(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "toastcontainer",
                                });
                                setDeleterefresh(true);
                                // searchnewrule();
                            } else {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="erroricon">
                                            <BiSolidXCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Error</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.error(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "errorcontainer",
                                });
                            }
                        }
                    });
            }
        }
    }
    function multideletethisrule() {
        if (
            getToggleconfirm === "" ||
            (getToggleconfirm.toLowerCase() !== "yes" &&
                getToggleconfirm !== "YES" &&
                getToggleconfirm !== "Yes")
        ) {
            setConfirmMessage(true);
            return false;
        }
        setAllCheckRule([]);
        setMultipledisabled([]);
        setruleNameText([]);
        setruleDesText([]);
        setpriorityNumberText([]);
        setenabledisablemenutext([]);
        setSelectAllChecked(false);
        if (
            (getToggleconfirm.toLowerCase() == "yes" &&
                getToggleconfirm == "YES" &&
                getToggleconfirm == "Yes") ||
            getToggleconfirm !== ""
        ) {
            if (getableRuleid && getableRuleid.length > 0) {
                let url = "api/park/ruleengine/deleterules";
                var restoken = GenerateEncryptedToken();
                var requestBody = {
                    RuleIDs: getableRuleid,
                };
                smartParkService
                    .post(url, requestBody, {
                        headers: {
                            "x-access-token": restoken,
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (res) {
                            handleClose();
                            if (res.data.status == 200) {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="toasticon">
                                            <BiSolidCheckCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Success</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.success(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "toastcontainer",
                                });
                                setDeleterefresh(true);
                                // searchnewrule();
                            } else {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="erroricon">
                                            <BiSolidXCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Error</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.error(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "errorcontainer",
                                });
                            }
                        }
                    });
            }
        } else {
            handleClose();
        }
    }

    const DeleteRule = (
        deleteruleid,
        dataRuleName,
        dataRuleDescription,
        dataPriorityValue
    ) => {
        console.log(deleteruleid);
        console.log(dataRuleName);
        console.log(dataRuleDescription);
        console.log(dataPriorityValue);
        setOpen(false);
        var deleteruleIDs = [];
        deleteruleIDs.push(deleteruleid);
        setdeleteRuleid(deleteruleIDs);
        setdeleteRulename(dataRuleName);
        setdeleteRuledes(dataRuleDescription);
        setdeleteRulepriority(dataPriorityValue);
        setdeleteOpen(true);
    };

    const multiDeleteRule = (
        deleteruleid,
        dataRuleName,
        dataRuleDescription,
        dataPriorityValue
    ) => {
        setOpen(false);
        setmultiOpen(false);
        try {
            setableRuleid(deleteruleid);
            setableRulename(dataRuleName);
            setableRuledes(dataRuleDescription);
            setableRulepriority(dataPriorityValue);
            setmultideleteopen(true);
        } catch {
            console.log("Some error");
        }
    };

    React.useEffect(async () => {
        let pagenum;
        if (Changefilter) {
            pagenum = 1;
            setCurrentPage(1);
        } else {
            pagenum = currentPage;
        }
        try {
            let url = "api/park/ruleengine/listrules";
            var restoken = GenerateEncryptedToken();
            var requestBody = {
                Page: pagenum,
                Limit: itemsPerPage,
                TriggerStrategy: triggerTypeFilter.toUpperCase(),
                Status: statusFilter.toUpperCase(),
            };
            await smartParkService
                .post(url, requestBody, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        setTotalRows(res?.data?.metadata?.pages);
                        setAllRules(res?.data?.metadata?.total);
                        let arraydata = res?.data?.data;
                        if (props?.rulepriorityno) {
                            let filterarraydata = arraydata.filter(
                                (item) => item.PriorityValue !== props?.rulepriorityno
                            );
                            setListdata(filterarraydata);
                        } else {
                            setListdata(res?.data?.data);
                        }
                        if (res.data.data?.length < 1) {
                            setPending(false);
                        }
                        if (res.data.data?.length > 0) {
                            setMobileRuleList(res.data.data);
                        }
                        let allruleid = [];
                        let allrulename = [];
                        res.data.data?.forEach((data) => {
                            allruleid.push(data.RuleID);
                            allrulename.push(data.RuleName);
                            seteditdata(data.OperationalTime);
                        });
                        setDeleterefresh(false);
                        if (props?.relatedruleid) {
                            props?.relatedruleid(allruleid);
                        }
                        setPending(false);
                    } else {
                        console.log("Rule list error: ");
                        setDeleterefresh(false);
                    }
                    setChangefilter(false);
                })
                .catch((error) => {
                    console.error("Rule List error: ", error);
                    setDeleterefresh(false);
                });
        } catch {
            console.log("List error ");
        }
    }, [
        getDeleterefresh,
        currentPage,
        itemsPerPage,
        props?.rulepriorityno,
        props.reloadtable,
        statusFilter,
        triggerTypeFilter,
    ]);

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
        setChangefilter(true);
        // console.log(e.target.value);
    };

    const handleTriggerTypeFilterChange = (e) => {
        // console.log(e.target.value);
        setTriggerTypeFilter(e.target.value);
        setChangefilter(true);
    };

    function convertToAMPM(timeStr) {
        // Check if the input has colons
        timeStr = timeStr.toString();
        // return timeStr;
        if (timeStr.includes(":")) {
            const [hours, minutes] = timeStr.split(":");
            let period = "AM";
            let parsedHours = parseInt(hours);
            if (parsedHours >= 12) {
                period = "PM";
                if (parsedHours > 12) {
                    parsedHours -= 12;
                }
            }
            return `${parsedHours !== "" ? parsedHours : "00"}:${minutes !== "" ? minutes : "00"
                } ${period}`;
        } else {
            // If no colons, assume it's in the format "HHMM"
            if (timeStr === "0") {
                return "0:00 AM";
            }
            // Assume any three or four-digit input is in "HHMM" format
            if (typeof timeStr !== "string") {
                return "Invalid input";
            }
            // Extract hours and minutes from the input
            const hours = timeStr.substring(0, timeStr.length - 2);
            const minutes = timeStr.substring(timeStr.length - 2);
            let period = "AM";
            // Parse hours and minutes
            let parsedHours = parseInt(hours) || 0; // Use default value if parsing fails
            const parsedMinutes = parseInt(minutes) || 0; // Use default value if parsing fails
            // Adjust period and parsedHours for PM
            if (parsedHours >= 12) {
                period = "PM";
                if (parsedHours > 12) {
                    parsedHours -= 12;
                }
            }

            // Ensure leading zero for single-digit hours and minutes
            const formattedHours = parsedHours.toString().padStart(2, "0");
            const formattedMinutes = parsedMinutes.toString().padStart(2, "0");

            return `${formattedHours}:${formattedMinutes} ${period}`;
        }
    }

    const enabledisablesitem = (ruledata) => {
        return (
            <section className="menubtn" dataid={ruledata}>
                <Dropdown drop={"up"} className="customdropup">
                    <Dropdown.Toggle
                        variant="transparent"
                        className="settingdropdowntwo border-0 "
                    >
                        <MdSettings />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="actionicons">
                        <Dropdown.Item href="#" onClick={() => viewrule(ruledata.RuleID)}>
                            <span className="actionbutton"> View </span>
                        </Dropdown.Item>
                        {checkPermissions({
                            permission: "RuleEngine",
                            permissionType: "update",
                        }) && (
                                <Dropdown.Item href="#" onClick={() => EditRule(ruledata.RuleID)}>
                                    <span className="actionbutton">Edit</span>
                                </Dropdown.Item>
                            )}
                        <Dropdown.Item
                            href="#"
                            onClick={() =>
                                DeleteRule(
                                    ruledata.RuleID,
                                    ruledata.RuleName,
                                    ruledata.RuleDescription,
                                    ruledata.PriorityValue
                                )
                            }
                        >
                            <span className="actionbutton">Delete</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" onClick={() => Viewlog(ruledata.RuleID)}>
                            <span className="actionbutton">Rule Logs</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" onClick={() => Auditlog(ruledata.RuleID)}>
                            <span className="actionbutton">Audit Logs</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </section>
        );
    };

    function convertCapitalCase(str) {
        if (str.toLowerCase() === "time based rule") {
            return str.charAt(0).toUpperCase() + str.slice(1, 10).toLowerCase();
        }
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    function convertday(item) {
        try {
            let result = [];
            item &&
                item.map((item) => {
                    if (item.DayofWeek == "1") {
                        result.push("M");
                    }
                    if (item.DayofWeek == "2") {
                        result.push("T");
                    }
                    if (item.DayofWeek == "3") {
                        result.push("W");
                    }
                    if (item.DayofWeek == "4") {
                        result.push("Th");
                    }
                    if (item.DayofWeek == "5") {
                        result.push("F");
                    }
                    if (item.DayofWeek == "6") {
                        result.push("Sat");
                    }
                    if (item.DayofWeek == "0") {
                        result.push("S");
                    }
                });
            let newSetData = new Set(result);
            return Array.from(newSetData).toString();
        } catch (error) {
            console.log("error in converting day");
        }
    }

    const customStyles = {
        responsiveWrapper: {
            style: {
                overflow: "visible",
                marginTop: "1rem",
            },
        },
    };
    const endDateSort = (rowA, rowB) => {
        const a = rowA.RuleValidityEndDate;
        const b = rowB.RuleValidityEndDate;
        // Handle undefined or null values
        if (!a) return 1;
        if (!b) return -1;
        const dateA = new Date(a);
        const dateB = new Date(b);
        if (dateA > dateB) {
            return 1;
        }
        if (dateA < dateB) {
            return -1;
        }
        return 0;
    };
    const operationTimeSort = (rowA, rowB) => {
        const a = rowA.OperationalTime.length;
        const b = rowB.OperationalTime.length;
        // // Handle undefined or null values
        if (!a) return 1;
        if (!b) return -1;

        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    };
    const statusSort = (rowA, rowB) => {
        const a = rowA.Disabled.toString();
        const b = rowB.Disabled.toString();

        // // Handle undefined or null values
        if (!a) return 1;
        if (!b) return -1;

        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    };

    const columnsdata = [
        {
            name: "Rule Name",
            selector: (row) => row.RuleName,
            sortable: true,
            minWidth: "200px",
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                // whiteSpace: "nowrap",
                // maxWidth: "300px",
            },
        },
        {
            name: "Priority",
            selector: (row) => row.PriorityValue,
            sortable: true,
            maxWidth: "150px",
            width: "100px",
        },
        {
            name: "Trigger Type",
            selector: (row) => convertCapitalCase(row.TriggerStrategy),
            sortable: true,
            maxWidth: "200px",
            width: "160px",
        },
        {
            name: "Day of week",
            selector: (row) => (
                <div>
                    <p>{convertday(row.OperationalTime)}</p>
                </div>
            ),
            sortable: false,
            maxWidth: "200px",
            width: "160px",
            // sortFunction: operationTimeSort,
        },
        {
            name: "End Date",
            selector: (row) => (
                <div>
                    <p>{simpledate(row.RuleValidityEndDate)}</p>
                </div>
            ),
            sortable: true,
            maxWidth: "200px",
            width: "160px",
            sortFunction: endDateSort,
        },
        {
            name: "Status",
            selector: (row) => (
                <div>
                    <p>{convertCapitalCase(row.Status)}</p>
                </div>
            ),
            sortable: true,
            maxWidth: "150px",
            width: "110px",
            sortFunction: statusSort,
        },
        {
            name: "Action",
            selector: (row) => enabledisablesitem(row),
            maxWidth: "70px",
            width: "50px",
        },
    ];

    const routeAddRule = () => {
        if (permission.create == 1) {
            let path = "createnewRule";
            history.push({
                pathname: `${path}`,
                search: "", // Adding query parameters to the URL
                state: { detail: "Create Rule" },
            });
            dispatch(todosAction.resetState());
        } else {
            alert("You do not have permission to create rule");
        }
    };

    const routeAllRulepage = () => {
        if (permission.create == 1) {
            let path = "allrulelogspage";
            history.push({
                pathname: `${path}`,
            });
        } else {
            alert("You do not have permission to All Rule Logs");
        }
    };

    function funcBulkAction(e) {
        if (e.target.value === "") {
            setBulkActions("");
            setSelectedRows([]);
            setcheckrow(false);
            setIsDisabled(true);
            setSelectAllrow(!selectAllrow);
            setMultipledisabled([]);
            setruleNameText([]);
            setruleDesText([]);
            setpriorityNumberText([]);
            setenabledisablemenutext([]);
        }
        if (e.target.value === "enable") {
            setBulkActions("enable");
        }
        if (e.target.value === "disable") {
            setBulkActions("disable");
        }
        if (e.target.value === "delete") {
            setBulkActions("delete");
        }
    }

    const handleSelectChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
        try {
            if (selectedRows.length > 0) {
                setIsDisabled(false);
                let rulelistid = [];
                let ruleNameText = [];
                let ruleDesText = [];
                let priorityNumberText = [];
                let enabledisablemenutext = [];
                setTotalRowselect(selectedRows.length);
                setcheckrow(true);
                setIsDisabled(false);

                selectedRows &&
                    selectedRows?.forEach((data) => {
                        console.log(data);
                        rulelistid.push(data.RuleID);
                        ruleNameText.push(data.RuleName);
                        ruleDesText.push(data.RuleDescription);
                        priorityNumberText.push(data.PriorityValue);
                        enabledisablemenutext.push(data.Disabled);
                    });
                setMultipledisabled(rulelistid);
                setruleNameText(ruleNameText);
                setruleDesText(ruleDesText);
                setpriorityNumberText(priorityNumberText);
                setenabledisablemenutext(enabledisablemenutext);
            } else {
                setIsDisabled(true);
                setBulkActions("");
                setcheckrow(false);
            }
        } catch (err) {
            console.error("some went wrong ", err);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                className="confirmdialogbox"
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="Details">
                            <div className="rulemodaldes">
                                <span>Rule Name : </span> <div>{getdisableRulename}</div>
                            </div>
                            <div className="rulemodaldes">
                                <span>Rule Description :</span>
                                <div>{getdisableRuledes}</div>
                            </div>
                            <div className="rulemodaldes">
                                <span>Rule Priority :</span> <div>{getdisableRulepriority}</div>
                            </div>
                        </div>
                        <div>
                            <strong>
                                Are you sure you want to{" "}
                                {getdisableRulestatus == 1 ? "Enable" : "Disable"} the Rule ?
                            </strong>
                        </div>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Please Type Yes or No"
                        type="text"
                        value={getToggleconfirm}
                        onChange={(e) => setToggleconfirm(e.target.value)}
                        className="text-uppercase"
                        fullWidth
                    />
                    {getConfirmMessage && (
                        <p style={{ color: "red" }}>
                            * Please fill this field ( YES or yes ){" "}
                        </p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="cancelbtn">
                        Cancel
                    </Button>
                    <Button
                        className="agreebtn"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            togglerule();
                        }}
                    >
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={multiopen}
                TransitionComponent={Transition}
                keepMounted
                className="confirmdialogbox"
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="Details">
                            <table className="table w-100">
                                <thead>
                                    <tr>
                                        <th>Rule Name</th>
                                        <th>Rule Description</th>
                                        <th>Rule Priority</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: numRows }, (_, index) => (
                                        <tr key={index}>
                                            <td>{getableRulename[index]}</td>
                                            <td>{getableRuledes[index]}</td>
                                            <td>{getableRulepriority[index]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <strong>Are you sure you want to toggle the Rule ?</strong>
                        </div>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Please Type Yes or No"
                        type="text"
                        value={getToggleconfirm}
                        onChange={(e) => setToggleconfirm(e.target.value)}
                        className="text-uppercase"
                        fullWidth
                    />
                    {getConfirmMessage && (
                        <p style={{ color: "red" }}>
                            * Please fill this field ( YES or yes ){" "}
                        </p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="cancelbtn">
                        Cancel
                    </Button>
                    <Button
                        className="agreebtn"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            multitogglerule();
                        }}
                    >
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={multideleteopen}
                TransitionComponent={Transition}
                keepMounted
                className="confirmdialogbox"
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="Details">
                            <table className="table w-100">
                                <thead>
                                    <tr>
                                        <th>Rule Name</th>
                                        <th>Rule Description</th>
                                        <th>Rule Priority</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: numRows }, (_, index) => (
                                        <tr key={index}>
                                            <td>{getableRulename[index]}</td>
                                            <td>{getableRuledes[index]}</td>
                                            <td>{getableRulepriority[index]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <strong>Are you sure you want to Delete the Rule ?</strong>
                        </div>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Please Type Yes or No"
                        type="text"
                        value={getToggleconfirm}
                        onChange={(e) => setToggleconfirm(e.target.value)}
                        className="text-uppercase"
                        fullWidth
                    />
                    {getConfirmMessage && (
                        <p style={{ color: "red" }}>
                            * Please fill this field ( YES or yes ){" "}
                        </p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="cancelbtn">
                        Cancel
                    </Button>
                    <Button
                        className="agreebtn"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            multideletethisrule();
                        }}
                    >
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteopen}
                TransitionComponent={Transition}
                keepMounted
                className="confirmdialogbox"
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="Details">
                            <div className="rulemodaldes">
                                <span>Rule Name : </span> <div>{getdeleteRulename}</div>
                            </div>
                            <div className="rulemodaldes">
                                <span>Rule Description :</span>
                                <div>{getdeleteRuledes}</div>
                            </div>
                            <div className="rulemodaldes">
                                <span>Rule Priority :</span> <div>{getdeleteRulepriority}</div>
                            </div>
                        </div>
                        <div>
                            <strong>Are you sure you want to Delete the Rule ?</strong>
                        </div>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Please Type Yes or No"
                        type="text"
                        value={getdeleteToggleconfirm}
                        onChange={(e) => setdeleteToggleconfirm(e.target.value)}
                        className="text-uppercase"
                        fullWidth
                    />
                    {getConfirmMessage && (
                        <p style={{ color: "red" }}>
                            * Please fill this field ( YES or yes ){" "}
                        </p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="cancelbtn">
                        Cancel
                    </Button>
                    <Button
                        className="agreebtn"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            deletethisrule();
                        }}
                    >
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

            <section
                className={`contentrule newdesktoplistview mt-2}`}
                style={{ marginLeft: "38px" }}
            >
                {pending === false ? (
                    <div className="listfiltersection">
                        <div className="row">
                            <div className="col-md-6 col-lg-7">
                                {checkPermissions({
                                    permission: "RuleEngine",
                                    permissionType: "update",
                                }) && (
                                        <div className="leftheading">
                                            <div>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => funcBulkAction(e)}
                                                    value={getBulkActions || ""}
                                                    style={{ width: "100%", marginRight: "5px" }}
                                                >
                                                    <option value="">Bulk Action</option>
                                                    <option value="enable">Enable</option>
                                                    <option value="disable">Disable</option>
                                                    <option value="delete">Delete</option>
                                                </Form.Control>
                                            </div>
                                            <div>
                                                {permission.create == 1 ? (
                                                    <>
                                                        {getBulkActions === "" ? (
                                                            <Button
                                                                className="blukrule empty my-0 "
                                                                variant="contained"
                                                                disabled
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {getBulkActions === "enable" ? (
                                                            <Button
                                                                className="blukrule enable my-0 "
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multienablerule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText,
                                                                        getenabledisablemenutext,
                                                                        "false"
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {getBulkActions === "disable" ? (
                                                            <Button
                                                                className="blukrule disable my-0 "
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multidisablerule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText,
                                                                        getenabledisablemenutext,
                                                                        "true"
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {getBulkActions === "delete" ? (
                                                            <Button
                                                                className="blukrule delete my-0 "
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multiDeleteRule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <div className="col-md-6 col-lg-5">
                                <div className="rightsearhfilter">
                                    <div>
                                        <div className="topbtn">
                                            {checkPermissions({
                                                permission: "RuleEngine",
                                                permissionType: "read",
                                            }) && (
                                                    <Button
                                                        className="addrule my-0 newaddrule viewgeneralbtn"
                                                        variant="contained"
                                                        onClick={routeAllRulepage}
                                                        disabled={getcheckrow}
                                                    >
                                                        <img
                                                            src={alllog}
                                                            alt="printerimg"
                                                            className="img-fluid mr-2"
                                                        />{" "}
                                                        <span className="createrule">
                                                            Rule Execution Logs
                                                        </span>
                                                    </Button>
                                                )}
                                            {checkPermissions({
                                                permission: "RuleEngine",
                                                permissionType: "create",
                                            }) && (
                                                    <Button
                                                        className="addrule newaddrule my-0 "
                                                        variant="contained"
                                                        onClick={routeAddRule}
                                                        disabled={getcheckrow}
                                                    >
                                                        <img
                                                            src={plusicontwo}
                                                            alt="printerimg"
                                                            className="img-fluid mr-2"
                                                        />
                                                        <span className="createrule"> CREATE NEW RULE </span>
                                                    </Button>
                                                )}
                                        </div>
                                    </div>
                                    <div className="selectfield my-2">
                                        {checkPermissions({
                                            permission: "RuleEngine",
                                            permissionType: "read",
                                        }) && (
                                                <div className="searchsdffilter">
                                                    <Form.Control
                                                        as="select"
                                                        onChange={handleTriggerTypeFilterChange}
                                                        value={triggerTypeFilter || ""}
                                                        style={{ width: "100%", marginRight: "5px" }}
                                                    >
                                                        <option value="">Trigger Type</option>
                                                        <option value="ALL">All</option>
                                                        <option value="ANY">Any</option>
                                                        <option value="TIME BASED RULE">Time Based</option>
                                                    </Form.Control>
                                                    <Form.Control
                                                        as="select"
                                                        onChange={handleStatusFilterChange}
                                                        value={statusFilter || ""}
                                                        style={{ width: "100%", marginRight: "5px" }}
                                                    >
                                                        <option value="">Status</option>
                                                        <option value="Enabled">Enabled</option>
                                                        <option value="Disabled">Disabled</option>
                                                        <option value="Expired">Expired</option>
                                                    </Form.Control>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className="content-middle rules-middle mobilewidth">
                    <DataTable
                        customStyles={customStyles}
                        columns={columnsdata}
                        data={getListdata}
                        striped
                        responsive
                        className="custom-table newcustom-table"
                        progressPending={pending}
                        progressComponent={
                            <ClipLoader
                                color={color}
                                loading={loading}
                                css={override}
                                size={60}
                            />
                        }
                        selectableRows
                        onSelectedRowsChange={handleSelectChange}
                        clearSelectedRows={selectAllrow}
                    />
                </div>
                {getListdata?.length > 0 && pending === false ? (
                    <div className="container-fluid py-2 bottomline">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-12">
                                <Suspense fallback={<div>loading</div>}>
                                    <Tablepaginate
                                        totalpage={totalRows}
                                        tablerowvalue={functablerow}
                                        currentPagevalue={currentPage}
                                        rulecurrentpage={funcCurrentpage}
                                        totalpagenum={getAllRules && getAllRules}
                                    />
                                </Suspense>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </section>

            <section className="content mobileruleview">
                {getMobileRuleList && getMobileRuleList?.length > 0 ? (
                    <div className="container-fluid">
                        <div className="row bordermobiletablehead">
                            <div className="col-12 col-sm-12  ">
                                <div className="mobilecheckrule">
                                    <span>Related Rule</span>
                                    <div>
                                        {permission.create == 1 ? (
                                            <Button
                                                className="addrule my-0 viewgeneralbtn"
                                                variant="contained"
                                                onClick={routeAllRulepage}
                                                disabled={getcheckrow}
                                            >
                                                <img
                                                    src={alllog}
                                                    alt="printerimg"
                                                    className="img-fluid mr-2 "
                                                />{" "}
                                                <span className="createrule">
                                                    {" "}
                                                    Rule Execution Logs{" "}
                                                </span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        {permission.create == 1 ? (
                                            <Button
                                                className="addrule my-0 "
                                                variant="contained"
                                                onClick={routeAddRule}
                                                disabled={getcheckrow}
                                            >
                                                <img
                                                    src={plusicontwo}
                                                    alt="printerimg"
                                                    className="img-fluid mr-1"
                                                />{" "}
                                                <span className="createrule"> CREATE </span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                            {getMobileRuleList?.length > 0 ? (
                                <div className="col-12 col-sm-12   border-bottom-0">
                                    <div className="showtablehead">
                                        <div className="leftheading">
                                            <div>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => funcBulkAction(e)}
                                                    value={getBulkActions || ""}
                                                    style={{ width: "100%", marginRight: "5px" }}
                                                >
                                                    <option value="">Bulk Action</option>
                                                    <option value="enable">Enable</option>
                                                    <option value="disable">Disable</option>
                                                    <option value="delete">Delete</option>
                                                </Form.Control>
                                            </div>
                                            <div>
                                                {permission.create == 1 ? (
                                                    <>
                                                        {getBulkActions === "" ? (
                                                            <Button
                                                                className="blukrule my-0 "
                                                                variant="contained"
                                                                disabled
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {getBulkActions === "enable" ? (
                                                            <Button
                                                                className="blukrule my-0 enableapply"
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multienablerule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText,
                                                                        getenabledisablemenutext,
                                                                        "false"
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {getBulkActions === "disable" ? (
                                                            <Button
                                                                className="blukrule my-0 disableapply"
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multidisablerule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText,
                                                                        getenabledisablemenutext,
                                                                        "true"
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {getBulkActions === "delete" ? (
                                                            <Button
                                                                className="blukrule my-0 deleteapply"
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multiDeleteRule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="rightheading mobilelineheight">
                                            <div className="createaddrulebtn">
                                                <input
                                                    type="checkbox"
                                                    className="rulecheck"
                                                    checked={selectAllChecked}
                                                    onChange={togglecheckSelectAll}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        {getMobileRuleList &&
                            getMobileRuleList?.map((ruledata, idx) => {
                                return (
                                    <div key={idx}>
                                        {ruledata.PriorityValue !== props?.rulepriorityno && (
                                            <div
                                                className={`row mobilerulerow ${idx == 0 ? "" : "mt-2"
                                                    } ${allCheckRule.includes(ruledata.RuleID)
                                                        ? "checkboxbackgound"
                                                        : ""
                                                    } `}
                                                key={idx}
                                            >
                                                <div className="col-12 col-sm-12 px-0 text-right">
                                                    <div className="ruleheading mobilelineheight">
                                                        <input
                                                            type="checkbox"
                                                            className="rulecheck checkmargin"
                                                            // data-ruledataRuleID={ruledata.RuleID ?? ruledata.RuleID}
                                                            checked={allCheckRule.includes(ruledata.RuleID)}
                                                            onChange={(e) =>
                                                                toggleRowSelectionone(
                                                                    e,
                                                                    ruledata.RuleID,
                                                                    ruledata.RuleDescription,
                                                                    ruledata.RuleName,
                                                                    ruledata.PriorityValue,
                                                                    ruledata.Disabled
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4 col-sm-4 px-1">
                                                    <div className="ruleheading">
                                                        <div>Rule Name</div>
                                                    </div>
                                                </div>
                                                <div className="col-8 col-sm-8 px-1">
                                                    <div className="ruleContent">
                                                        <div>{ruledata.RuleName}</div>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-sm-4 px-1">
                                                    <div className="ruleheading">
                                                        <div>Description</div>
                                                    </div>
                                                </div>
                                                <div className="col-8 col-sm-8 px-1">
                                                    <div className="ruleContent">
                                                        <div>{ruledata.RuleDescription}</div>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-sm-4 px-1">
                                                    <div className="ruleheading">
                                                        <div>Priority Number</div>
                                                    </div>
                                                </div>
                                                <div className="col-8 col-sm-8 pl-1">
                                                    <div className="priorityno">
                                                        {ruledata.PriorityValue}
                                                    </div>
                                                </div>
                                                <div className="col-4 col-sm-4 px-1">
                                                    <div className="ruleheading">
                                                        <div>Validity Setting</div>
                                                    </div>
                                                </div>
                                                <div className="col-8 col-sm-8 pl-1">
                                                    <div className="validity ruleContent">
                                                        <div className="rulealways">
                                                            {ruledata.RuleIsAlwaysOn === true ? (
                                                                <div className="commonsize">Rule Always ON</div>
                                                            ) : (
                                                                <div className="validitydates">
                                                                    <div className="commonsize mb-1">
                                                                        Start Date:{" "}
                                                                        {simpledate(ruledata.RuleValidityStartDate)}
                                                                    </div>
                                                                    <div className="commonsize">
                                                                        End Date:{" "}
                                                                        {simpledate(ruledata.RuleValidityEndDate)}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-sm-4 px-1">
                                                    <div className="ruleheading ">
                                                        <div>Operational Setting</div>
                                                    </div>
                                                </div>
                                                <div className="col-8 col-sm-8 pl-1 pr-1">
                                                    <div className="operational ruleContent">
                                                        <div className="py-1">
                                                            <div className="row printpageview">
                                                                {groupByDayOfWeek(ruledata.OperationalTime).map(
                                                                    (group, index) => (
                                                                        <div
                                                                            className="col-6 col-md-6 col-lg-6 conditionlist mobilecondition"
                                                                            key={index}
                                                                        >
                                                                            <div className="viewdayofweek h-100">
                                                                                <Accordion className="viewaccordion">
                                                                                    <AccordionSummary
                                                                                        expandIcon={
                                                                                            <KeyboardArrowRightIcon />
                                                                                        }
                                                                                        aria-controls={`panel${index + 1
                                                                                            }a-content`}
                                                                                        id={`panel${index + 1}a-header`}
                                                                                        className="accorhead "
                                                                                    >
                                                                                        <Typography
                                                                                            className={classes.heading}
                                                                                        >
                                                                                            <span className="headname commonsize">
                                                                                                {Dayweekconvert(
                                                                                                    group[0].DayofWeek
                                                                                                )}{" "}
                                                                                            </span>
                                                                                        </Typography>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails className="d-block px-2">
                                                                                        {group.map((timeInterval, idx) => (
                                                                                            <Typography
                                                                                                key={idx}
                                                                                                className="timecss"
                                                                                            >
                                                                                                {convertToAMPM(
                                                                                                    timeInterval.StartTime
                                                                                                )}{" "}
                                                                                                To{" "}
                                                                                                {convertToAMPM(
                                                                                                    timeInterval.EndTime
                                                                                                )}
                                                                                            </Typography>
                                                                                        ))}
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-sm-4 px-1">
                                                    <div className="ruleheading">
                                                        <div>Triggering Strategy</div>
                                                    </div>
                                                </div>
                                                <div className="col-8 col-sm-8 px-1">
                                                    <div className="triggering ruleContent">
                                                        {ruledata.TriggerStrategy == "ANY" ? (
                                                            <div>
                                                                <img src={gridtwo} className="img-fluid" />{" "}
                                                                <span>{ruledata.TriggerStrategy}</span>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {ruledata.TriggerStrategy == "ALL" ? (
                                                            <div>
                                                                <img src={gridone} className="img-fluid" />{" "}
                                                                <span>{ruledata.TriggerStrategy}</span>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {ruledata.TriggerStrategy == "TIME BASED RULE" ? (
                                                            <div>
                                                                <img src={gridone} className="img-fluid" />{" "}
                                                                <span>{ruledata.TriggerStrategy}</span>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>

                                                {ruledata.TriggerStrategy !== "TIME BASED RULE" ? (
                                                    <div className="col-4 col-sm-4 px-1">
                                                        <div className="ruleheading">
                                                            <div>Conditional Setting</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {ruledata.TriggerStrategy !== "TIME BASED RULE" ? (
                                                    <div className="col-8 col-sm-8 px-1">
                                                        <div className="conditional ruleContent">
                                                            {ruledata.Conditions?.map((condition, idx) => {
                                                                const parkNameDisplay =
                                                                    condition.ParkName ===
                                                                        "Morrisville Community Park"
                                                                        ? "MCP"
                                                                        : condition.ParkName ===
                                                                            "Morrisville Aquatic and Fitness Center"
                                                                            ? "MAFC"
                                                                            : condition.ParkName ===
                                                                                "Cedar Fork District Park"
                                                                                ? "CFDP"
                                                                                : "CSP";
                                                                return (
                                                                    <Accordion
                                                                        className="viewaccordion trigeracco mobilemargin"
                                                                        key={idx}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<KeyboardArrowRightIcon />}
                                                                            aria-controls={`panel${idx + 1}a-content`}
                                                                            id={`panel${idx + 1}a-header`}
                                                                            className="accorhead px-1"
                                                                        >
                                                                            <Typography className={classes.heading}>
                                                                                <span className="headname commonsize">
                                                                                    {parkNameDisplay} ,{" "}
                                                                                    {condition.DeviceName} ,{" "}
                                                                                    {trivalue(
                                                                                        condition.TriggerValue,
                                                                                        condition.TriggerCriteria,
                                                                                        condition.Devicetype
                                                                                    )}
                                                                                </span>
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails className="d-block px-1">
                                                                            <Table
                                                                                size="sm"
                                                                                className="text-left"
                                                                                key={idx}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Park Name: {parkNameDisplay}
                                                                                        </td>
                                                                                        <td>
                                                                                            Device Type:{" "}
                                                                                            {condition.Devicetype}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Device Name:{" "}
                                                                                            {condition.DeviceName}
                                                                                        </td>
                                                                                        <td>
                                                                                            Trigger Value:{" "}
                                                                                            {condition.Devicetype.toLowerCase() ===
                                                                                                "court"
                                                                                                ? condition.TriggerValue.toLowerCase() ===
                                                                                                    "open"
                                                                                                    ? "Unoccupied"
                                                                                                    : "Occupied"
                                                                                                : condition.TriggerValue}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Trigger Criteria:{" "}
                                                                                            {condition.TriggerCriteria}
                                                                                        </td>
                                                                                        <td>
                                                                                            Duration: {condition.Tolerance}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </Table>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                <div className="col-4 col-sm-4 px-1">
                                                    <div className="ruleheading">
                                                        <div>Actions</div>
                                                    </div>
                                                </div>
                                                <div className="col-8 col-sm-8 px-1">
                                                    <div className="actions ruleContent">
                                                        {ruledata.Actions?.map((data, idx) => {
                                                            const parkNameDisplay =
                                                                data.ParkName === "Morrisville Community Park"
                                                                    ? "MCP"
                                                                    : data.ParkName ===
                                                                        "Morrisville Aquatic and Fitness Center"
                                                                        ? "MAFC"
                                                                        : data.ParkName === "Cedar Fork District Park"
                                                                            ? "CFDP"
                                                                            : "CSP";
                                                            return (
                                                                <Accordion
                                                                    className=" mobilemargin viewaccordion trigeracco "
                                                                    key={idx}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<KeyboardArrowRightIcon />}
                                                                        aria-controls={`panel${idx + 1}a-content`}
                                                                        id={`panel${idx + 1}a-header`}
                                                                        className="accorhead px-1"
                                                                    >
                                                                        <Typography className={classes.heading}>
                                                                            <span className="headname commonsize">
                                                                                {parkNameDisplay},{data.DeviceName},
                                                                                {data.Action}{" "}
                                                                            </span>
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails className="d-block px-1">
                                                                        <Table
                                                                            striped
                                                                            bordered
                                                                            hover
                                                                            size="sm"
                                                                            className="text-left"
                                                                        >
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Park Name : {parkNameDisplay}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Device Type : {data.Devicetype}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Device : {data.DeviceName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Action : {data.Action}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="col-4 col-sm-4 px-1">
                                                    <div className="ruleheading">
                                                        <div>Settings</div>
                                                    </div>
                                                </div>
                                                <div className="col-8 col-sm-8 px-1">
                                                    <div className="settings ruleContent">
                                                        <div className="menubtn mobilemenubtn">
                                                            <div
                                                                className="icontoggle mr-3"
                                                                onClick={() =>
                                                                    enabledisablerule(
                                                                        ruledata.RuleID,
                                                                        ruledata.Disabled,
                                                                        ruledata.RuleName,
                                                                        ruledata.RuleDescription,
                                                                        ruledata.PriorityValue
                                                                    )
                                                                }
                                                            >
                                                                {ruledata.Disabled === false ? (
                                                                    <BsToggleOn className="ablebtn" />
                                                                ) : (
                                                                    <BsToggleOn className="disablebtn" />
                                                                )}
                                                            </div>
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="success"
                                                                    className="settingdropdown"
                                                                >
                                                                    <MdSettings />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="actionicons">
                                                                    <Dropdown.Item
                                                                        href="#"
                                                                        onClick={() => viewrule(ruledata.RuleID)}
                                                                    >
                                                                        <span className="actionbutton"> View </span>
                                                                    </Dropdown.Item>
                                                                    {checkPermissions({
                                                                        permission: "RuleEngine",
                                                                        permissionType: "update",
                                                                    }) && (
                                                                            <Dropdown.Item
                                                                                href="#"
                                                                                onClick={() => EditRule(ruledata.RuleID)}
                                                                            >
                                                                                <span className="actionbutton">Edit</span>
                                                                            </Dropdown.Item>
                                                                        )}
                                                                    {checkPermissions({
                                                                        permission: "RuleEngine",
                                                                        permissionType: "delete",
                                                                    }) && (
                                                                            <Dropdown.Item
                                                                                href="#"
                                                                                onClick={() =>
                                                                                    DeleteRule(
                                                                                        ruledata.RuleID,
                                                                                        ruledata.RuleName,
                                                                                        ruledata.RuleDescription,
                                                                                        ruledata.PriorityValue
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span className="actionbutton">
                                                                                    Delete
                                                                                </span>
                                                                            </Dropdown.Item>
                                                                        )}
                                                                    <Dropdown.Item
                                                                        href="#"
                                                                        onClick={() => Viewlog(ruledata.RuleID)}
                                                                    >
                                                                        <span className="actionbutton">
                                                                            Rule Logs
                                                                        </span>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        href="#"
                                                                        onClick={() => Auditlog(ruledata.RuleID)}
                                                                    >
                                                                        <span className="actionbutton">
                                                                            Audit Logs
                                                                        </span>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        {getMobileRuleList?.length > 0 && pending === false ? (
                            <div className="row mb-2 downblukactionborder">
                                <div className="col-12 col-sm-12 samemobilepadd">
                                    <div className="showtablehead">
                                        <div className="leftheading">
                                            <div>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => funcBulkAction(e)}
                                                    value={getBulkActions || ""}
                                                    style={{ width: "100%", marginRight: "5px" }}
                                                >
                                                    <option value="">Bulk Action</option>
                                                    <option value="enable">Enable</option>
                                                    <option value="disable">Disable</option>
                                                    <option value="delete">Delete</option>
                                                </Form.Control>
                                            </div>
                                            <div>
                                                {permission.create == 1 ? (
                                                    <>
                                                        {getBulkActions === "" ? (
                                                            <Button
                                                                className="blukrule my-0 "
                                                                variant="contained"
                                                                disabled
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {getBulkActions === "enable" ? (
                                                            <Button
                                                                className="blukrule my-0 enableapply"
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multienablerule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText,
                                                                        getenabledisablemenutext,
                                                                        "false"
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {getBulkActions === "disable" ? (
                                                            <Button
                                                                className="blukrule my-0 disableapply"
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multidisablerule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText,
                                                                        getenabledisablemenutext,
                                                                        "true"
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {getBulkActions === "delete" ? (
                                                            <Button
                                                                className="blukrule my-0 deleteapply"
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multiDeleteRule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="rightheading mobilelineheight">
                                            <div className="createaddrulebtn">
                                                <input
                                                    type="checkbox"
                                                    className="rulecheck"
                                                    checked={selectAllChecked}
                                                    onChange={togglecheckSelectAll}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {getMobileRuleList?.length > 0 && pending === false ? (
                            <Suspense fallback={<div>loading</div>}>
                                <Tablepaginate
                                    totalpage={totalRows}
                                    tablerowvalue={functablerow}
                                    currentPagevalue={currentPage}
                                    rulecurrentpage={funcCurrentpage}
                                    totalpagenum={getAllRules > 1 ? getAllRules - 1 : getAllRules}
                                />
                            </Suspense>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <ClipLoader
                        color={color}
                        loading={loading}
                        css={override}
                        size={60}
                    />
                )}
            </section>
        </div>
    );
}
