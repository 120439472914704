//import crypto from "crypto";
export const GenerateEncryptedToken = () => {
  // var algorithm = "aes256"; // or any other algorithm supported by OpenSSL
  // var key = "smartparknotificationsystem";
  // var date = +new Date(new Date().toDateString()) + "";
  // var cipher = crypto.createCipher(algorithm, key);
  // var encrypted = cipher.update(date+"", "utf8", "hex") + cipher.final("hex");
  // return "citizen"+encrypted;
  return "citizen";
};
