import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import smartParkService from "../../axios";
import { login_, token_ } from "../../reducers/login";
import _ from "lodash";
import moment from "moment";
import "../../assets/scss/App.scss";

export default function MCPFloodSensorStatus(props) {
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [FloodSensorValues, setFloodSensorValues] = React.useState({});
  const startDate = moment()
    .tz("America/New_York")
    .subtract(15, "minutes")
    .unix();
  const endDate = moment().tz("America/New_York").unix();
  //   console.log("time", startDate, endDate);

  const fetchSensorData = () => {
    let url = `api/citizen/ubidots/c7a1d85f-8168-4e64-8e38-116a0624bfeb/flood?startDate=${startDate * 1000
      }&endDate=${endDate * 1000}`;

    smartParkService
      .get(url)
      .then((res) => {
        // console.log("url", res);
        if (res.status === 200) {
          setFloodSensorValues(res.data.data[res.data.data.length - 1]);
        }
      })
      .catch((error) => { });
  };

  React.useEffect(() => {
    fetchSensorData();
  }, []);

  return (
    <div>
      <div class="box">
        <div class="box-header with-border">
          <h6>Morrisville Community Park Flood Sensor</h6>
        </div>
        <div class="box-body">
          <div class="divGrid">
            <div className="divGridBody">
              <div className="divGridRow">
                <div className="divGridCell">Water Level</div>
                <div className="divGridCell">
                  {FloodSensorValues.distance} ft.
                </div>
              </div>
              <div className="divGridRow">
                <div className="divGridCell">Battery</div>
                <div className="divGridCell">{FloodSensorValues.battery} %</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
