import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { KeyboardBackspace } from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import TextField from "@material-ui/core/TextField";
import { login_, token_ } from "../../reducers/login";
import { useSelector } from "react-redux";
import _ from "lodash";
const useStyles = makeStyles(styles);

export default function EditField() {
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ['parkID'], '');
  const userid = _.get(loginDetails, ["UserID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common['x-access-token'] = token;
  const name = _.get(loginDetails, ['FirstName'], '');
  const classes = useStyles();
  const history = useHistory();
  const [fieldName,setFieldName]=useState("");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fieldID=urlParams.get('fieldID');
  React.useEffect(()=>{
    var url=`api/park/${parkid}/field?fieldID=${fieldID}`;
    smartParkService
    .get(url,{
      headers: {
        userid: userid,
      }})
    .then(async (res, err) => {
      if (res.data.status == 200) {
        setFieldName(res.data.data.FieldName)
      }
    })
  },[])
  const saveField=()=>{
    var fieldObject = {};
    fieldObject.FieldName = fieldName;
    fieldObject.LastEditedBy =name;
    fieldObject.LastEditDate =new Date();
     smartParkService
    .post("api/park/" + parkid + "/updatefield?fieldID="+fieldID, fieldObject,{
      headers: {
        userid: userid,
      }})
    .then(async (res, err) => {
      if (res.data.status == 200) {
        history.push("/field");
      }
    })

  }
  return (
    <div className={classes.root}>
      <div  className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Fields</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                  Fields
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <div class="content-middle">
            <div class="content-sub-header">
              <div>
                <Link
                  component="button"
                  onClick={() => history.push("/field")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
              <div>
                <h3>Add Field</h3>
              </div>
              <div class="display-xs-0">
                <Link
                  style={{ visibility: "hidden" }}
                  component="button"
                  onClick={() => history.push("/")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
            </div>

            <Paper className={classes.paper}>
              <div class="form-body pad20">
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="fieldName"
                  value={fieldName}
                  name="FieldName"
                  onChange={(e) => {
                    setFieldName(e.target.value);
                  }}
                  label="Field Name"
                  className={classes.w80p}
                />
              </div>
              <div class="form-footer">
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  className={classes.btnRound}
                  onClick={saveField}
                >
                  SAVE
                </Button>
                &nbsp;&nbsp;
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  className={classes.btnRound}
                  onClick={()=>{history.push("/field")}}
                >
                  CANCEL
                </Button>
              </div>
            </Paper>
          </div>
        </section>
      </div>
    </div>
  );
}
