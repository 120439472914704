import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { PermissionsService } from "../services/Permissions";

export const initialState = {
  isLoading: false,
  hasLoadedpermissions: false,
  data: {
    permissionsdata: [],
  },
};

export const Getaccesspermissions = createAsyncThunk(
  "Get/Permissions",
  async (parkid, payloadObj) => {
    return await PermissionsService(parkid, payloadObj);
  }
);

const permissions = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    unmountPermissions: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Getaccesspermissions.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(Getaccesspermissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasLoadedpermissions = true;
      state.data = action.payload;
    });
    builder.addCase(Getaccesspermissions.rejected, (state, action) => {
      //TODO
    });
  },
});

export const stateSelector_ = ({ permissions }) => permissions;
export const permissions_ = createSelector(
  stateSelector_,
  (permissions) => permissions.data
);
export const hasLoadedpermissions_ = createSelector(
  stateSelector_,
  (permissions) => permissions.hasLoadedpermissions
);

export const { unmountPermissions } = permissions.actions;

export default permissions.reducer;
