import React from "react";

const iconAltText = {
  "01d": "clear sky",
  "02d": "few clouds",
  "03d": "scattered clouds",
  "04d": "broken clouds",
  "09d": "shower rain",
  "10d": "rain",
  "11d": "thunderstorm",
  "13d": "snow",
  "50d": "mist",
};

function determineWeatherKeyAndIcon(data) {
  const prioritizedIcons = ["50d", "13d", "11d", "10d", "09d"];

  for (let icon of prioritizedIcons) {
    if (data.hasOwnProperty(icon)) {
      return icon;
    }
  }

  let mostOccurringIcon = null;
  let maxCount = 0;

  for (let key in data) {
    if (key !== "idx" && key !== "min" && key !== "max") {
      if (data[key] > maxCount) {
        maxCount = data[key];
        mostOccurringIcon = key;
      }
    }
  }
  return mostOccurringIcon;
}

export const WeatherForecast = ({ data }) => {
  const weatherData = determineWeatherKeyAndIcon(data[1]);

  return (
    <div>
      {weatherData && (
        <div>
          <h1 className="Forecast__title">{data[0]}</h1>
          <img
            className="Forecast__weather-icon"
            src={"https://openweathermap.org/img/wn/" + weatherData + ".png"}
            alt={iconAltText[weatherData]}
          />
          <div className="Forecast__temperature">
            <span className="temperature__max">
              {Math.round(data[1].max)}
              <sup className="temperature__symbol">°</sup>
            </span>
            <span className="temperature__min">
              {Math.round(data[1].min)}
              <sup className="temperature__symbol">°</sup>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
