import React from "react";

const CurrentWeather = ({ data }) => {
  return (
    <div className="Weather">
      {data && (
        <div>
          <h2 className="other-info__city">{data.name}</h2>
          <div className="Weather__info">
            <div>
              <img
                className="Weather__icon"
                src={
                  "https://openweathermap.org/img/wn/" +
                  data.weather[0].icon +
                  ".png"
                }
                alt={data.weather[0].main}
              />
              <h3 className="other-info__clouds">
                <span className="today_style">
                  {new Date(data.dt * 1000).toDateString()}
                </span>
                , <br /> {data.weather[0].description}
              </h3>
            </div>
            <ul className="Weather__list">
              <li className="list__temperature">
                {Math.round(data.main.temp)}
                <sup className="list__temperature-symbol">°F</sup>
              </li>
              <li> Humidity: {data.main.humidity}%</li>
              <li>Wind: {Math.round(data.wind.speed)} mph </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentWeather;
