import React from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  Chip,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  listItemHeading: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  chipContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "nowrap",
  },
  chip: {
    flex: "1 1 100%",
    margin: theme.spacing(0.25),
  },
}));

const OccupancyCardTablet = ({ field }) => {
  const classes = useStyles();
  return (
    <Hidden mdUp>
      <Card elevation={8}>
        <CardContent>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    className={classes.listItemHeading}
                  >
                    {field.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.chipContainer}>
                  {field.chips.map((chip) => (
                    <Chip
                      className={classes.chip}
                      style={{
                        backgroundColor: chip.color || "#12d958de",
                        color: "#FFFFFF",
                      }}
                      key={chip.value}
                      label={chip.name + chip.value}
                      size="small"
                    />
                  ))}
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Hidden>
  );
};

export default OccupancyCardTablet;
