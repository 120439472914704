import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { convertToRaw } from "draft-js";
import Form from "react-bootstrap/Form";
import { BiSolidCheckCircle } from "react-icons/bi";
import { ReportProblemOutlined } from "@material-ui/icons";
import { MdSettings } from "react-icons/md";
import { BiSolidXCircle } from "react-icons/bi";
import alllog from "../../assets/img/alllog.png";
import { HiArrowLongLeft } from "react-icons/hi2";
import MaskedInput from "react-maskedinput";
import styles from "../../components/components.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { todosAction } from "../../reducers/reducers.js";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "../../components/CustomButtons/Button.js";
import Card from "react-bootstrap/Card";
import Switch from "@material-ui/core/Switch";
import DatePicker from "react-datepicker";
import TextField from "@material-ui/core/TextField";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../../views/Common";
import { login_ } from "../../reducers/login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    permissions_,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import Dropdown from "react-bootstrap/Dropdown";
import { css } from "@emotion/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import Tablepaginate from "../Rules/Tablepaginate.js";
import calendar from "../../assets/img/calendar-icon.png";
import Notification from "./Notificationmodal.js";
import moment from "moment";
import { checkPermissions } from "../../utils/checkPermissions.js";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" position="top" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);
const weekdayclass = makeStyles((theme) => ({
    weekdayborder: {
        border: "1px solid #fff",
        borderRadius: "0.25rem",
    },
    samepadd: {
        "& .MuiSelect-select": {
            padding: "12px auto !important",
        },
    },
}));

export default function ViewAllNotifications(props) {
    const classes = useStyles();
    const theme = useTheme();
    const myclass = weekdayclass();
    const dispatch = useDispatch();
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");
    const [totalRows, setTotalRows] = React.useState(null);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [getdeleteToggleconfirm, setdeleteToggleconfirm] = React.useState("");
    const [getdisableRulename, setdisableRulename] = React.useState("");
    const [getdeleteRulepriority, setdeleteRulepriority] = React.useState("");
    const [getdeleteparkstartdate, setdeleteparkstartdate] = React.useState("");
    const [getdeleteparkenddate, setdeleteparkenddate] = React.useState("");
    const [getdeleteRuledes, setdeleteRuledes] = React.useState("");
    const [getdeleteRulename, setdeleteRulename] = React.useState("");
    const [getdeleteRuleid, setdeleteRuleid] = React.useState([]);
    const [getMultipledisabled, setMultipledisabled] = React.useState([]);
    const [getruleNameText, setruleNameText] = React.useState([]);
    const [getruleDesText, setruleDesText] = React.useState([]);
    const [getrulewebname, setrulewebname] = React.useState([]);
    const [getrulweblink, setrulweblink] = React.useState([]);
    const [getpriorityNumberText, setpriorityNumberText] = React.useState([]);
    const [getenabledisablemenutext, setenabledisablemenutext] = React.useState(
        []
    );
    const [permission, setpermission] = React.useState({});
    const [getparks, setparks] = React.useState([]);
    const [getdeleteid, setdeleteid] = React.useState("");
    const permissionsobj = useSelector(permissions_);
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllrow, setSelectAllrow] = React.useState(false);
    // datainput ------=======================================================
    const [getListdata, setListdata] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [color, setColor] = React.useState("#ffffff");
    const [pending, setPending] = React.useState(true);
    const [getDeleterefresh, setDeleterefresh] = React.useState(false);
    const [getdisableRuleid, setdisableRuleid] = React.useState([]);
    const [getdisableRulestatus, setdisableRulestatus] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [multiopen, setmultiOpen] = React.useState(false);
    const [multideleteopen, setmultideleteopen] = React.useState(false);
    const [getToggleconfirm, setToggleconfirm] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);
    const [getShouldUpdatePage, setShouldUpdatePage] = React.useState(false);
    const [geteditdata, seteditdata] = React.useState([]);
    const [rows, setRowsdata] = React.useState([]);
    const [getableRuleid, setableRuleid] = React.useState([]);
    const [getableRulestatus, setableRulestatus] = React.useState([]);
    const [getabledisableRulestatus, setabledisableRulestatus] =
        React.useState("");
    const [getableRulename, setableRulename] = React.useState([]);
    const [getableRuledes, setableRuledes] = React.useState([]);
    const [getMobileRuleList, setMobileRuleList] = React.useState([]);
    const [getableRulepriority, setableRulepriority] = React.useState([]);
    const [deleteopen, setdeleteOpen] = React.useState(false);
    const [getBulkActions, setBulkActions] = React.useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [getConfirmMessage, setConfirmMessage] = React.useState(false);
    const [summaryAdddata, setSummaryAddData] = useState({});
    const [getAllRules, setAllRules] = useState(null);
    const history = useHistory();
    const numRows = getableRuleid.length;
    // error States ===========---------------=======================
    const [refresh, setRefresh] = useState(false);

    const [getcheckrow, setcheckrow] = React.useState(false);
    const [getTotalRowselect, setTotalRowselect] = React.useState("");
    const [allCheckRule, setAllCheckRule] = useState([]);
    const [allCheckRuleName, setAllCheckRuleName] = useState("");
    const [allCheckRulePriority, setAllCheckRulePriority] = useState("");
    const [allCheckRuleDes, setAllCheckRuleDes] = useState("");
    const [selectedRowsone, setSelectedRowsone] = useState([]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [getResponse, setResponse] = useState(false);
    const [showEndDatePicker, setshowEndDatePicker] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [getSearchfilter, setSearchfilter] = React.useState([]);
    const [gethandleParkId, sethandleParkId] = React.useState("");
    const [getnotifyId, setnotifyId] = React.useState("");
    const [getnotifyparkid, setnotifyparkid] = React.useState("");
    const [getnotifyparkname, setnotifyparkname] = React.useState("");
    const [getnotifystartdate, setnotifystartdate] = React.useState("");
    const [getnotifyenddate, setnotifyenddate] = React.useState("");
    const [getnotifymessage, setnotifymessage] = React.useState("");
    const [getnotifyweblink, setnotifyweblink] = React.useState("");
    const [getnotifywebname, setnotifywebname] = React.useState("");
    const [getdeleteparkwebname, setdeleteparkwebname] = React.useState([]);
    const [getdeleteparkweblink, setdeleteparkweblink] = React.useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const selectedRuleData = getMobileRuleList.filter((ruledata) =>
        selectedRowsone.includes(ruledata.RuleID)
    );
    // for related rule =================
    // form dropdown ===================

    var allf = "";
    allf = localStorage.getItem("addfields");

    useEffect(() => {
        funcSearchoptions();
    }, []);

    useEffect(() => {
        funcNotifyList();
    }, [getDeleterefresh, currentPage, itemsPerPage]);

    function funcLinkAdd(webname, weblink) {
        try {
            if (webname !== "" && weblink !== "") {
                return (
                    <a href={weblink} className="btn-link weblinkcss" target="_blank">
                        {webname}
                    </a>
                );
            }
            if (webname !== "" && weblink == "") {
                return <span> {webname} </span>;
            }
            if (webname == "" && weblink !== "") {
                return (
                    <a href={weblink} className="btn-link weblinkcss" target="_blank">
                        {weblink}
                    </a>
                );
            }
        } catch {
            return (
                <span>
                    {webname},{weblink}
                </span>
            );
        }
    }

    function funcSearchoptions() {
        try {
            let url = "api/park/announcement/announcementoptions";
            var restoken = GenerateEncryptedToken();
            smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        try {
                            if (res.status === 200) {
                                if (res.data.status == 200) {
                                    var dataa = [];
                                    var devices = {};
                                    res.data.options?.forEach((data, i) => {
                                        dataa.push({
                                            id: data.ID,
                                            name: data.Name,
                                            shortname: data.ShortName,
                                            device: data.Devices,
                                        });
                                        devices[data.ID] = data.Devices;
                                    });
                                    setparks(dataa);
                                    setSearchfilter(devices);
                                } else {
                                    funcSearchoptions();
                                }
                            } else {
                                funcSearchoptions();
                            }
                        } catch {
                            console.log("some error in search Dropdown api ");
                        }
                    } else {
                        console.log("Search else error: ");
                    }
                })
                .catch((error) => {
                    console.error("Search error: ", error);
                });
        } catch {
            console.log("Search Option Error");
        }
    }

    function funcNotifyList() {
        // let unixStartTimestamp = "";
        // let unixEndTimestamp = "";
        // if (startDate !== "" && startDate !== undefined) {
        //   const dateObjectStart = new Date(startDate);
        //   unixStartTimestamp = dateObjectStart.getTime();
        // } else {
        //   unixStartTimestamp = "";
        // }
        // if (endDate !== "" && endDate !== undefined) {
        //   const dateObjectEnd = new Date(endDate);
        //   unixEndTimestamp = dateObjectEnd.getTime();
        // } else {
        //   unixEndTimestamp = "";
        // }
        if (getShouldUpdatePage) {
            setCurrentPage(1);
        }
        const updatedPage = getShouldUpdatePage ? 1 : currentPage;
        let estTimeZone = "America/New_York"; // Eastern Standard Time (EST)
        // Convert startDate to EST and format
        let estStartTimestamp = "";
        if (startDate !== "" && startDate !== undefined) {
            estStartTimestamp = moment(startDate)
                .tz(estTimeZone)
                .format("L h:mm:ss A");
        } else {
            estStartTimestamp = "";
        }
        // Convert endDate to EST and format
        let estEndTimestamp = "";
        if (endDate !== "" && endDate !== undefined) {
            estEndTimestamp = moment(endDate)
                .tz(estTimeZone)
                .set({ hour: 23, minute: 59, second: 59 })
                .format("L h:mm:ss A");
        } else {
            estEndTimestamp = "";
        }
        console.log(estStartTimestamp);
        console.log(estEndTimestamp);
        // Convert EST formatted timestamps to UTC
        let unixStartTimestamp =
            estStartTimestamp !== ""
                ? moment(estStartTimestamp, "L h:mm:ss A").utc().valueOf()
                : "";
        let unixEndTimestamp =
            estEndTimestamp !== ""
                ? moment(estEndTimestamp, "L h:mm:ss A").utc().valueOf()
                : "";

        // console.log(unixStartTimestamp);
        // console.log(unixEndTimestamp);
        try {
            var finaldata = [];
            let url = "api/notification/list";
            var restoken = GenerateEncryptedToken();
            var requestBody = {
                Page: updatedPage,
                Limit: itemsPerPage,
                parkId: gethandleParkId,
                startDate: unixStartTimestamp,
                endDate: unixEndTimestamp,
            };
            smartParkService
                .post(url, requestBody, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    setShouldUpdatePage(false);
                    if (res) {
                        setAllRules(res?.data?.metadata?.total);
                        setTotalRows(res?.data?.metadata?.pages);
                        if (res.data.data?.length < 1) {
                            setPending(false);
                        }
                        if (res.data.data?.length > 0) {
                            setMobileRuleList(res.data.data);
                        }
                        res.data.data?.forEach((data, idx) => {
                            const ruledes = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">{data.parkName}</div>
                                    </div>
                                );
                            };
                            const rulePriority = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">
                                            {simpledate(data.startDate)}
                                        </div>
                                        <hr className="my-2" />
                                        <div className="triger commonsize p-0">
                                            {simpledate(data.endDate)}
                                        </div>
                                    </div>
                                );
                            };
                            const notificationMessage = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">
                                            {data.message} {funcLinkAdd(data.webName, data.webLink)}
                                        </div>
                                    </div>
                                );
                            };
                            const enabledisablesmenu = () => {
                                if (
                                    checkPermissions({
                                        permission: "Announcement",
                                        permissionType: "update",
                                    }) ||
                                    checkPermissions({
                                        permission: "Announcement",
                                        permissionType: "delete",
                                    })
                                ) {
                                    return (
                                        <div className="menubtn">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    className="settingdropdown"
                                                >
                                                    <MdSettings />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="actionicons">
                                                    {checkPermissions({
                                                        permission: "Announcement",
                                                        permissionType: "update",
                                                    }) && (
                                                            <Dropdown.Item
                                                                href="javascript:void(0)"
                                                                onClick={() =>
                                                                    notifyEditRule(
                                                                        data._id,
                                                                        data.parkId,
                                                                        data.parkName,
                                                                        data.startDate,
                                                                        data.endDate,
                                                                        data.message,
                                                                        data.webName,
                                                                        data.webLink
                                                                    )
                                                                }
                                                            >
                                                                <span className="actionbutton">Edit</span>
                                                            </Dropdown.Item>
                                                        )}
                                                    {checkPermissions({
                                                        permission: "Announcement",
                                                        permissionType: "delete",
                                                    }) && (
                                                            <Dropdown.Item
                                                                href="javascript:void(0)"
                                                                onClick={() =>
                                                                    notifyDeleteRule(
                                                                        data._id,
                                                                        data.parkName,
                                                                        data.startDate,
                                                                        data.endDate,
                                                                        data.message,
                                                                        data.webName,
                                                                        data.webLink
                                                                    )
                                                                }
                                                            >
                                                                <span className="actionbutton">Delete</span>
                                                            </Dropdown.Item>
                                                        )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div style={{ color: "red" }}>
                                            <ReportProblemOutlined />
                                        </div>
                                    );
                                }
                            };
                            finaldata.push({
                                id: data._id,
                                RuleDes: ruledes(),
                                Prioritynumber: rulePriority(),
                                NotificationMessage: notificationMessage(),
                                enabledisablemenu: enabledisablesmenu(),
                            });
                        });
                        setListdata(finaldata);
                        setDeleterefresh(false);
                        // props.relatedruleid(allruleid);
                    } else {
                        console.log("Rule list error: ");
                        setDeleterefresh(false);
                    }
                })
                .catch((error) => {
                    console.error("Rule List error: ", error);
                    setDeleterefresh(false);
                });
        } catch {
            console.log("List error ");
        }
    }

    const handleParkId = (elem) => {
        sethandleParkId("");
        if (elem.target.value !== "Select Park") {
            // console.log(elem.target.value);
            sethandleParkId(elem.target.value);
        } else {
            sethandleParkId("");
        }
        setShouldUpdatePage(true);
    };

    function AllByAltdata(getrule) {
        console.log(getrule);
        // Initialize arrays outside of the loop
        let updatedSelectedRowsid = [];
        let updatedSelectedRowsname = [];
        let updatedSelectedRowsdes = [];
        let updatedSelectedRowswebname = [];
        let updatedSelectedRowsweblink = [];
        let updatedSelectedRowsnum = [];
        let updatedSelectedRowsmenu = [];
        getrule?.forEach((data) => {
            const { _id, parkName, message, startDate, endDate, webName, webLink } =
                data;
            if (updatedSelectedRowsid?.includes(_id)) {
                updatedSelectedRowsid.splice(updatedSelectedRowsid.indexOf(_id), 1);
                updatedSelectedRowsdes.splice(
                    updatedSelectedRowsdes.indexOf(message),
                    1
                );
                updatedSelectedRowsname.splice(
                    updatedSelectedRowsname.indexOf(parkName),
                    1
                );
                updatedSelectedRowsnum.splice(
                    updatedSelectedRowsnum.indexOf(simpledate(startDate)),
                    1
                );
                updatedSelectedRowsmenu.splice(
                    updatedSelectedRowsmenu.indexOf(simpledate(endDate)),
                    1
                );
                updatedSelectedRowswebname.splice(
                    updatedSelectedRowsmenu.indexOf(webName),
                    1
                );
                updatedSelectedRowsweblink.splice(
                    updatedSelectedRowsmenu.indexOf(webLink),
                    1
                );
            } else {
                updatedSelectedRowsid.push(_id);
                updatedSelectedRowsdes.push(message);
                updatedSelectedRowsname.push(parkName);
                updatedSelectedRowsnum.push(simpledate(startDate));
                updatedSelectedRowsmenu.push(simpledate(endDate));
                updatedSelectedRowswebname.push(webName);
                updatedSelectedRowsweblink.push(webLink);
            }
        });
        setMultipledisabled(updatedSelectedRowsid);
        setruleNameText(updatedSelectedRowsname);
        setruleDesText(updatedSelectedRowsdes);
        setpriorityNumberText(updatedSelectedRowsnum);
        setenabledisablemenutext(updatedSelectedRowsmenu);
        setrulewebname(updatedSelectedRowswebname);
        setrulweblink(updatedSelectedRowswebname);
    }
    const togglecheckSelectAll = () => {
        setSelectAllChecked(!selectAllChecked);
        if (!selectAllChecked) {
            let allRuleIDs = getMobileRuleList?.map((ruledata) => ruledata._id);
            setAllCheckRule(allRuleIDs);
            AllByAltdata(getMobileRuleList);
        } else {
            setAllCheckRule([]);
            setMultipledisabled([]);
            setruleNameText([]);
            setruleDesText([]);
            setpriorityNumberText([]);
            setenabledisablemenutext([]);
            setrulewebname([]);
            setrulweblink([]);
        }
    };

    function toggleRowSelectionone(
        notificationID,
        message,
        parkname,
        startdate,
        endDate,
        webName,
        weblink
    ) {
        setAllCheckRule([]);
        const allid = [...allCheckRule];
        const updatedSelectedRowsid = [...getMultipledisabled];
        const updatedSelectedRowsname = [...getruleNameText];
        const updatedSelectedRowsdes = [...getruleDesText];
        const updatedSelectedRowsnum = [...getpriorityNumberText];
        const updatedSelectedRowsmenu = [...getenabledisablemenutext];
        const updatedSelectedRowswebname = [...getdeleteparkwebname];
        const updatedSelectedRowsweblink = [...getdeleteparkweblink];
        if (allid?.includes(notificationID)) {
            allid.splice(allid.indexOf(notificationID), 1);
            updatedSelectedRowsid.splice(
                updatedSelectedRowsid.indexOf(notificationID),
                1
            );
            updatedSelectedRowsdes.splice(updatedSelectedRowsdes.indexOf(message), 1);
            updatedSelectedRowsname.splice(
                updatedSelectedRowsname.indexOf(parkname),
                1
            );
            updatedSelectedRowsnum.splice(
                updatedSelectedRowsnum.indexOf(startdate),
                1
            );
            updatedSelectedRowsmenu.splice(
                updatedSelectedRowsmenu.indexOf(endDate),
                1
            );
            updatedSelectedRowswebname.splice(
                updatedSelectedRowswebname.indexOf(webName),
                1
            );
            updatedSelectedRowsweblink.splice(
                updatedSelectedRowsweblink.indexOf(weblink),
                1
            );
        } else {
            allid.push(notificationID);
            updatedSelectedRowsid.push(notificationID);
            updatedSelectedRowsdes.push(message);
            updatedSelectedRowsname.push(parkname);
            updatedSelectedRowsnum.push(startdate);
            updatedSelectedRowsmenu.push(endDate);
            updatedSelectedRowswebname.push(webName);
            updatedSelectedRowsweblink.push(weblink);
        }
        setAllCheckRule(allid);
        setMultipledisabled(updatedSelectedRowsid);
        setruleNameText(updatedSelectedRowsname);
        setruleDesText(updatedSelectedRowsdes);
        setpriorityNumberText(updatedSelectedRowsnum);
        setenabledisablemenutext(updatedSelectedRowsmenu);
        setdeleteparkwebname(updatedSelectedRowswebname);
        setdeleteparkweblink(updatedSelectedRowsweblink);
    }

    React.useEffect(() => {
        checkAllData(allCheckRule);
    }, [allCheckRule, allCheckRuleName, allCheckRulePriority, allCheckRuleDes]);

    function checkAllData(allCheckRule) {
        setTotalRowselect(allCheckRule.length);
        setcheckrow(true);
        setIsDisabled(false);
        if (allCheckRule.length == 0) {
            setBulkActions("");
            setcheckrow(false);
            setIsDisabled(true);
        }
    }

    useEffect(() => {
        if (props.editalldata) {
            setSummaryAddData(props.editalldata);
        }
    }, [props.editalldata]);
    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "RuleEngine";
        });
        setpermission(Dpermissions[0]);
    };
    React.useEffect(() => {
        if (getListdata.length > 0) {
            const timeout = setTimeout(() => {
                setRowsdata(getListdata);
                setPending(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [getListdata, refresh]);

    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);

    function simpledate(date) {
        // console.log(date);
        // try {
        //   const parsedDate = new Date(date);
        //   const formattedDate = parsedDate.toLocaleString();
        //   // console.log(formattedDate);
        //   // console.log("===================44444444444444444");
        //   return formattedDate;
        // } catch {
        //   return date;
        // }
        try {
            const otherZone = moment
                .tz(date, "America/New_York")
                .format("L h:mm:ss A");
            return otherZone;
        } catch {
            return date;
        }
    }

    const functablerow = (itemvalue) => {
        setItemsPerPage(parseInt(itemvalue));
    };

    const funcCurrentpage = (itemvalue) => {
        setCurrentPage(itemvalue);
        setItemsPerPage(itemsPerPage);
    };

    const handleClose = () => {
        setOpen(false);
        setdeleteOpen(false);
        setmultiOpen(false);
        setmultideleteopen(false);
        setToggleconfirm("");
        setdeleteToggleconfirm("");
        setConfirmMessage(false);
        setBulkActions("");
        setcheckrow(false);
        setIsDisabled(true);
        setSelectAllrow(!selectAllrow);
        setSelectedRows([]);
    };

    function deletethisrule() {
        if (
            getdeleteToggleconfirm == "" ||
            (getdeleteToggleconfirm.toLowerCase() !== "yes" &&
                getdeleteToggleconfirm !== "YES" &&
                getdeleteToggleconfirm !== "Yes")
        ) {
            setConfirmMessage(true);
            return false;
        }
        setAllCheckRule([]);
        setMultipledisabled([]);
        setruleNameText([]);
        setruleDesText([]);
        setpriorityNumberText([]);
        setenabledisablemenutext([]);
        setrulewebname([]);
        setrulweblink([]);
        setSelectAllChecked(false);
        if (
            (getdeleteToggleconfirm.toLowerCase() == "yes" &&
                getdeleteToggleconfirm == "YES" &&
                getdeleteToggleconfirm == "Yes") ||
            getdeleteToggleconfirm !== ""
        ) {
            if (getdeleteRuleid && getdeleteRuleid.length > 0) {
                let url = "api/notification/delete";
                var restoken = GenerateEncryptedToken();
                var requestBody = {
                    notificationId: getdeleteRuleid,
                };
                smartParkService
                    .post(url, requestBody, {
                        headers: {
                            "x-access-token": restoken,
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (res) {
                            if (res.data.status == 200) {
                                // console.log(res);
                                handleClose();
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="toasticon">
                                            <BiSolidCheckCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Success</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.success(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "toastcontainer",
                                });
                                setDeleterefresh(true);
                            } else {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="erroricon">
                                            <BiSolidXCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Error</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.error(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "errorcontainer",
                                });
                            }
                        }
                    });
            }
        }
    }
    function multideletethisrule() {
        if (
            getToggleconfirm === "" ||
            (getToggleconfirm.toLowerCase() !== "yes" &&
                getToggleconfirm !== "YES" &&
                getToggleconfirm !== "Yes")
        ) {
            setConfirmMessage(true);
            return false;
        }
        setAllCheckRule([]);
        setMultipledisabled([]);
        setruleNameText([]);
        setruleDesText([]);
        setpriorityNumberText([]);
        setrulewebname([]);
        setrulweblink([]);
        setenabledisablemenutext([]);
        setSelectAllChecked(false);

        if (
            (getToggleconfirm.toLowerCase() == "yes" &&
                getToggleconfirm == "YES" &&
                getToggleconfirm == "Yes") ||
            getToggleconfirm !== ""
        ) {
            if (getableRuleid && getableRuleid.length > 0) {
                let url = "api/notification/delete";
                var restoken = GenerateEncryptedToken();
                var requestBody = {
                    notificationId: getableRuleid,
                };
                smartParkService
                    .post(url, requestBody, {
                        headers: {
                            "x-access-token": restoken,
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (res) {
                            handleClose();
                            if (res.data.status == 200) {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="toasticon">
                                            <BiSolidCheckCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Success</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.success(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "toastcontainer",
                                });
                                setDeleterefresh(true);
                                // searchnewrule();
                            } else {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="erroricon">
                                            <BiSolidXCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Error</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.error(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "errorcontainer",
                                });
                            }
                        }
                    });
            }
        } else {
            handleClose();
        }
    }

    const multiDeleteRule = (
        deleteruleid,
        dataRuleName,
        dataRuleDescription,
        dataPriorityValue,
        datawebname,
        dataweblink
    ) => {
        setOpen(false);
        setmultiOpen(false);
        try {
            setableRuleid(deleteruleid);
            setableRulename(dataRuleName);
            setableRuledes(dataRuleDescription);
            setableRulepriority(dataPriorityValue);
            setrulewebname(datawebname);
            setrulweblink(dataweblink);
            setmultideleteopen(true);
        } catch {
            console.log("Some error in delete");
        }
    };

    const handleImgClick = () => {
        setShowDatePicker(true);
        setshowEndDatePicker(false);
    };

    const handleDatePickerBlur = () => {
        setShowDatePicker(false);
    };
    const handleEndImgClick = () => {
        setshowEndDatePicker(true);
        setShowDatePicker(false);
    };

    const handleEndDatePickerBlur = () => {
        setshowEndDatePicker(false);
    };

    function selectrulelist(rulelistdata) {
        // console.log(rulelistdata);
        let Notificationlistid = [];
        let Notificationmessage = [];
        let Notificationstartdate = [];
        let Notificationenddate = [];
        let NotificationName = [];
        setTotalRowselect(rulelistdata.length);
        setcheckrow(true);
        setIsDisabled(false);
        if (rulelistdata.length === 0) {
            setBulkActions("");
            setcheckrow(false);
            setIsDisabled(true);
        }
        rulelistdata?.forEach((data) => {
            console.log(data.NotificationMessage.props.children.props.children);
            // console.log(data.Prioritynumber.props.children[0].props.children[0]);
            Notificationlistid.push(data.id);
            Notificationmessage.push(
                data.NotificationMessage.props.children.props.children
            );
            NotificationName.push(data.RuleDes.props.children.props.children);
            Notificationstartdate.push(
                data.Prioritynumber.props.children[0].props.children
            );
            Notificationenddate.push(
                data.Prioritynumber.props.children[2].props.children
            );
        });

        setMultipledisabled(Notificationlistid);
        setruleNameText(NotificationName);
        setruleDesText(Notificationmessage);
        setpriorityNumberText(Notificationstartdate);
        setenabledisablemenutext(Notificationenddate);
    }

    function notifyEditRule(
        id,
        parkid,
        parkname,
        startdate,
        enddate,
        message,
        webname,
        weblink
    ) {
        // console.log(new Date(startdate));
        // console.log(new Date(enddate));
        setnotifyId(id);
        setnotifyparkid(parkid);
        setnotifyparkname(parkname);
        setnotifystartdate(startdate);
        setnotifyenddate(enddate);
        setnotifymessage(message);
        setnotifywebname(webname);
        setnotifyweblink(weblink);
        setModalShow(true);
    }

    function notifyDeleteRule(
        id,
        parkid,
        sdate,
        edate,
        message,
        webname,
        weblink
    ) {
        setOpen(false);
        var deleteruleIDs = [];
        deleteruleIDs.push(id);
        setdeleteRuleid(deleteruleIDs);
        setdeleteRulename(parkid);
        setdeleteRuledes(message);
        setdeleteparkstartdate(sdate);
        setdeleteparkenddate(edate);
        setdeleteparkwebname(webname);
        setdeleteparkweblink(weblink);
        setdeleteOpen(true);
    }

    const htmlFormatter = ({ value }) => (
        <div dangerouslySetInnerHTML={{ __html: value }} />
    );

    const columnsdata = [
        {
            field: "RuleNameDes",
            name: (
                <div className="w-100">
                    <div className="tableheading">Park Name</div>
                </div>
            ),
            minWidth: "10%",
            width: "25%",
            cell: (row) => row.RuleDes,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "Prioritynumber",
            name: (
                <div className="w-100">
                    <div className="tableheading">Start Date</div>
                    <hr className="my-2" />
                    <div className="tableheading">End Date</div>
                </div>
            ),
            minWidth: "10%",
            width: "20%",
            cell: (row) => row.Prioritynumber,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "NotificationMessage",
            name: (
                <div className="w-100">
                    <div className="tableheading">Message</div>
                </div>
            ),
            minWidth: "10%",
            // width: "12%",
            cell: (row) => row.NotificationMessage,
            editable: true,
            renderCell: htmlFormatter,
        },

        {
            field: "Settings",
            name: <div className="tableheading">Settings</div>,
            minWidth: "5%",
            width: "7%",
            editable: true,
            cell: (row) => row.enabledisablemenu,
            renderCell: htmlFormatter,
        },
    ];
    const customStyles = {
        rows: {
            style: {
                minHeight: "100px",
                // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",
                minHeight: "80px",

                // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",

                // override the row height
            },
        },
    };

    const routeAddRule = () => {
        if (permission.create == 1) {
            let path = "createnewRule";
            history.push({
                pathname: `${path}`,
                search: "", // Adding query parameters to the URL
                state: { detail: "Create Rule" },
            });
            dispatch(todosAction.resetState());
        } else {
            alert("You do not have permission to create rule");
        }
    };

    const routeAllRulepage = () => {
        if (permission.create == 1) {
            let path = "allrulelogspage";
            history.push({
                pathname: `${path}`,
            });
        } else {
            alert("You do not have permission to All Rule Logs");
        }
    };

    function funcBulkAction(e) {
        if (e.target.value === "") {
            setBulkActions("");
            setSelectedRows([]);
            setcheckrow(false);
            setIsDisabled(true);
            setSelectAllrow(!selectAllrow);
            setMultipledisabled([]);
            setruleNameText([]);
            setruleDesText([]);
            setpriorityNumberText([]);
            setenabledisablemenutext([]);
        }
        if (e.target.value === "delete") {
            setBulkActions("delete");
        }
    }

    function funcStartDate(e) {
        // console.log(e);
        if (e !== null && e !== undefined) {
            const startDateTime = new Date(e);
            startDateTime.setHours(0, 0, 0);
            setStartDate(startDateTime);
            setShowDatePicker(false);
        } else {
            setStartDate("");
            setEndDate("");
            setShowDatePicker(false);
        }
        setShouldUpdatePage(true);
    }
    function funcEndDate(e) {
        if (e !== null && e !== undefined) {
            const endDateTime = new Date(e);
            endDateTime.setHours(23, 59, 59);
            setEndDate(endDateTime);
            setshowEndDatePicker(false);
        } else {
            setEndDate("");
            setshowEndDatePicker(false);
        }
        setShouldUpdatePage(true);
    }

    const viewBackbtn = () => {
        history.go(-1);
    };
    const funcClearfilter = () => {
        sethandleParkId("");
        setStartDate("");
        setEndDate("");
        setDeleterefresh(true);
        setShouldUpdatePage(false);
    };
    const funchideNotifymodal = () => {
        setModalShow(false);
        setDeleterefresh(true);
    };

    return (
        <div>
            <Dialog
                open={multideleteopen}
                TransitionComponent={Transition}
                keepMounted
                className="notificationconfirmdialogbox"
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="Details notificationdetail">
                            <table className="table w-100">
                                <thead>
                                    <tr>
                                        <th>Park Name</th>
                                        <th>Park Notification</th>
                                        <th>Notification StartDate</th>
                                        <th>Notification EndDate</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {Array.from({ length: numRows }, (_, index) => (
                                        <tr key={index}>
                                            <td>{getableRulename[index]}</td>
                                            <td>
                                                {getableRuledes[index]}{" "}
                                                {funcLinkAdd(
                                                    getrulewebname[index],
                                                    getrulweblink[index]
                                                )}{" "}
                                            </td>
                                            <td>{getableRulepriority[index]}</td>
                                            <td>{getenabledisablemenutext[index]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <strong>
                                Are you sure you want to Delete the Notification ?
                            </strong>
                        </div>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Please Type Yes or No"
                        type="text"
                        value={getToggleconfirm}
                        onChange={(e) => setToggleconfirm(e.target.value)}
                        className="text-uppercase"
                        fullWidth
                    />
                    {getConfirmMessage && (
                        <p style={{ color: "red" }}>
                            * Please fill this field ( YES or yes ){" "}
                        </p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="cancelbtn">
                        Cancel
                    </Button>
                    <Button onClick={multideletethisrule} className="agreebtn">
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteopen}
                TransitionComponent={Transition}
                keepMounted
                className="notificationconfirmdialogbox"
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="Details notificationdetail">
                            <div className="rulemodaldes">
                                <span>Park Name : </span> <div>{getdeleteRulename}</div>
                            </div>
                            <div className="rulemodaldes">
                                <span>Park Notification :</span>
                                <div>
                                    {getdeleteRuledes}{" "}
                                    {funcLinkAdd(getdeleteparkwebname, getdeleteparkweblink)}{" "}
                                </div>
                            </div>
                            <div className="rulemodaldes">
                                <span>Notification StartDate :</span>{" "}
                                <div>{simpledate(getdeleteparkstartdate)}</div>
                            </div>
                            <div className="rulemodaldes">
                                <span>Notification EndDate :</span>{" "}
                                <div>{simpledate(getdeleteparkenddate)}</div>
                            </div>
                        </div>
                        <div>
                            <strong>
                                Are you sure you want to Delete the Notification ?
                            </strong>
                        </div>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Please Type Yes or No"
                        type="text"
                        value={getdeleteToggleconfirm}
                        onChange={(e) => setdeleteToggleconfirm(e.target.value)}
                        className="text-uppercase"
                        fullWidth
                    />
                    {getConfirmMessage && (
                        <p style={{ color: "red" }}>
                            * Please fill this field ( YES or yes ){" "}
                        </p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="cancelbtn">
                        Cancel
                    </Button>
                    <Button onClick={deletethisrule} className="agreebtn">
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Notification
                show={modalShow}
                notifyid={getnotifyId}
                notifypid={getnotifyparkid}
                notifypname={getnotifyparkname}
                notifySDate={getnotifystartdate}
                notifyEDate={getnotifyenddate}
                notifymess={getnotifymessage}
                notifyResponse={getResponse}
                notifyweblink={getnotifyweblink}
                notifywebName={getnotifywebname}
                onHide={funchideNotifymodal}
            />
            <div className="content searchlogging serachmt mb-0">
                <div>
                    <div className="row">
                        <div className="col-12 ">
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="notiheheading">All Notifications</span>
                                <Button
                                    className="viewbackbtn"
                                    size="md"
                                    variant="contained"
                                    onClick={viewBackbtn}
                                >
                                    <HiArrowLongLeft />
                                    <span className="mx-2">Back</span>
                                </Button>
                            </div>
                        </div>
                        <div className="col-12 logmobilepadd">
                            <Card border="info" className="my-2 searchcard">
                                <Card.Header>Custom Search</Card.Header>
                                <Card.Body>
                                    <div className="allelment">
                                        <div className="allcontainer allbordersame">
                                            <div className="row">
                                                <div className="col-12 col-md-6 spacebet">
                                                    <div className="timesearch notifydatepicker">
                                                        <label>Start Date : </label>
                                                        <div
                                                            className={classes.startdatecss}
                                                            style={{
                                                                position: "relative",
                                                                width: "100%",
                                                                borderRadius: "0.25rem",
                                                            }}
                                                        >
                                                            <DatePicker
                                                                // showTimeSelect
                                                                dateFormat="MM/dd/yyyy"
                                                                selected={startDate}
                                                                selectsStart
                                                                startDate={startDate}
                                                                onChange={(e) => funcStartDate(e)}
                                                                // maxDate={new Date()}
                                                                className="form-control"
                                                                placeholderText="mm/day/year"
                                                                onFocus={handleImgClick}
                                                                onBlur={handleDatePickerBlur}
                                                                open={showDatePicker}
                                                                customInput={<MaskedInput mask="11/11/1111" />}
                                                            />
                                                            <img
                                                                src={calendar}
                                                                className="img-fluid calenderimg"
                                                                alt="calender image"
                                                                onClick={handleImgClick}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 spacebet">
                                                    <div className="timesearch notifydatepicker">
                                                        <label>End Date : </label>
                                                        <div
                                                            className={classes.startdatecss}
                                                            style={{
                                                                position: "relative",
                                                                width: "100%",
                                                                borderRadius: "0.25rem",
                                                            }}
                                                        >
                                                            <DatePicker
                                                                // showTimeSelect
                                                                dateFormat="MM/dd/yyyy"
                                                                selected={endDate}
                                                                selectsStart
                                                                placeholderText="mm/day/year"
                                                                endDate={endDate}
                                                                minDate={startDate}
                                                                // maxDate={new Date()}
                                                                onChange={(e) => funcEndDate(e)}
                                                                className="form-control"
                                                                onFocus={handleEndImgClick}
                                                                onBlur={handleEndDatePickerBlur}
                                                                open={showEndDatePicker}
                                                                customInput={<MaskedInput mask="11/11/1111" />}
                                                            />
                                                            <img
                                                                src={calendar}
                                                                className="img-fluid calenderimg"
                                                                alt="calender image"
                                                                onClick={handleEndImgClick}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 spacebet">
                                                    <div className="timesearch ">
                                                        <label>Park Name : </label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={(e) => handleParkId(e)}
                                                            value={gethandleParkId}
                                                            style={{ width: "100%" }}
                                                        >
                                                            <option>Select Park</option>
                                                            {getparks?.map((data, idx) => (
                                                                <option
                                                                    key={idx}
                                                                    value={data.id}
                                                                    data-idx={idx}
                                                                    data-name={data.name}
                                                                >
                                                                    {data.name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-12 col-md-12 spacebet logbtnleftright">
                                                    {/* <button type='button' className='btn  mr-2 logSearchbtn' onClick={() => refreshData()}> */}
                                                    <button
                                                        type="button"
                                                        className="btn  mr-2 logSearchbtn"
                                                        onClick={() => funcNotifyList()}
                                                    >
                                                        Search
                                                    </button>
                                                    {/* <button type='button' className='btn logcancelbtn' onClick={cancelAllFilter}> */}
                                                    <button
                                                        type="button"
                                                        className="btn logcancelbtn"
                                                        onClick={funcClearfilter}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content notifydesktopruleview">
                {pending === false ? (
                    <div className="showtablehead">
                        <div className="leftheading">
                            <div>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => funcBulkAction(e)}
                                    value={getBulkActions || ""}
                                    style={{ width: "100%", marginRight: "5px" }}
                                >
                                    <option value="">Bulk Action</option>
                                    {checkPermissions({
                                        permission: "Announcement",
                                        permissionType: "delete",
                                    }) && <option value="delete">Delete</option>}
                                </Form.Control>
                            </div>
                            <div>
                                {permission.create == 1 ? (
                                    <>
                                        {getBulkActions === "" ? (
                                            <Button
                                                className="blukrule my-0 "
                                                size="md"
                                                variant="contained"
                                                disabled
                                            >
                                                <span className="rulebulkaction"> Apply </span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        {getBulkActions === "delete" ? (
                                            <Button
                                                className="blukrule my-0 "
                                                size="md"
                                                variant="contained"
                                                disabled={isDisabled}
                                                onClick={() =>
                                                    multiDeleteRule(
                                                        getMultipledisabled,
                                                        getruleNameText,
                                                        getruleDesText,
                                                        getpriorityNumberText,
                                                        getrulewebname,
                                                        getrulweblink
                                                    )
                                                }
                                            >
                                                <span className="rulebulkaction"> Apply </span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className="content-middle rules-middle mobilewidth">
                    <DataTable
                        columns={columnsdata}
                        data={getListdata}
                        selectableRows
                        selectableRowsHighlight
                        striped
                        responsive
                        className="custom-table"
                        progressPending={pending}
                        progressComponent={
                            <ClipLoader
                                color={color}
                                loading={loading}
                                css={override}
                                size={60}
                            />
                        }
                        customStyles={customStyles}
                        clearSelectedRows={selectAllrow}
                        onSelectedRowsChange={({ selectedRows }) => {
                            setSelectedRows(selectedRows);
                            selectrulelist(selectedRows);
                        }}
                    />
                </div>
                {getListdata?.length > 0 && pending === false ? (
                    <div className="container-fluid py-2 bottomline">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-4">
                                {
                                    <div className="leftheading justify-content-start">
                                        <div>
                                            <Form.Control
                                                as="select"
                                                onChange={(e) => funcBulkAction(e)}
                                                value={getBulkActions || ""}
                                                style={{ width: "100%", marginRight: "5px" }}
                                            >
                                                <option value="">Bulk Action</option>
                                                <option value="delete">Delete</option>
                                            </Form.Control>
                                        </div>
                                        <div>
                                            {permission.create == 1 ? (
                                                <>
                                                    {getBulkActions === "" ? (
                                                        <Button
                                                            className="blukrule my-0 "
                                                            size="md"
                                                            variant="contained"
                                                            disabled
                                                        >
                                                            <span className="rulebulkaction"> Apply </span>
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {getBulkActions === "delete" ? (
                                                        <Button
                                                            className="blukrule my-0 "
                                                            size="md"
                                                            variant="contained"
                                                            disabled={isDisabled}
                                                            onClick={() =>
                                                                multiDeleteRule(
                                                                    getMultipledisabled,
                                                                    getruleNameText,
                                                                    getruleDesText,
                                                                    getpriorityNumberText,
                                                                    getrulewebname,
                                                                    getrulweblink
                                                                )
                                                            }
                                                        >
                                                            <span className="rulebulkaction"> Apply </span>
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-12 col-md-8">
                                <Tablepaginate
                                    totalpage={totalRows}
                                    tablerowvalue={functablerow}
                                    currentPagevalue={currentPage}
                                    rulecurrentpage={funcCurrentpage}
                                    totalpagenum={getAllRules}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </section>

            <section className="content notifymobileruleview">
                {getMobileRuleList && getMobileRuleList?.length > 0 ? (
                    <div className="container-fluid">
                        <div className="row bordermobiletablehead">
                            {/* <div className="col-12 col-sm-12  ">
                <div className="mobilecheckrule">
                  <span>All Notifications</span>
                  {permission.create == 1 ? (
                    <Button
                      className="addrule my-0 viewgeneralbtn"
                      size="md"
                      variant="contained"
                      onClick={routeAllRulepage}
                      disabled={getcheckrow}
                    >
                      <img
                        src={alllog}
                        alt="printerimg"
                        className="img-fluid mr-2"
                      />{" "}
                      <span className="createrule">General Logs</span>
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div> */}
                            {getMobileRuleList?.length > 0 ? (
                                <div className="col-12 col-sm-12   border-bottom-0">
                                    <div className="showtablehead">
                                        <div className="leftheading">
                                            <div>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => funcBulkAction(e)}
                                                    value={getBulkActions || ""}
                                                    style={{ width: "100%", marginRight: "5px" }}
                                                >
                                                    <option value="">Bulk Action</option>
                                                    <option value="delete">Delete</option>
                                                </Form.Control>
                                            </div>
                                            <div>
                                                {permission.create == 1 ? (
                                                    <>
                                                        {getBulkActions === "" ? (
                                                            <Button
                                                                className="blukrule my-0 "
                                                                size="md"
                                                                variant="contained"
                                                                disabled
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {getBulkActions === "delete" ? (
                                                            <Button
                                                                className="blukrule my-0 deleteapply"
                                                                size="md"
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multiDeleteRule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText,
                                                                        getrulewebname,
                                                                        getrulweblink
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="rightheading mobilelineheight">
                                            <div className="createaddrulebtn">
                                                <input
                                                    type="checkbox"
                                                    className="rulecheck"
                                                    checked={selectAllChecked}
                                                    onChange={togglecheckSelectAll}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        {getMobileRuleList &&
                            getMobileRuleList?.map((ruledata, idx) => {
                                return (
                                    <>
                                        <div
                                            className={`row mobilerulerow ${idx == 0 ? "" : "mt-2"} ${allCheckRule.includes(ruledata._id)
                                                    ? "checkboxbackgound"
                                                    : ""
                                                } `}
                                            key={idx}
                                        >
                                            <div className="col-12 col-sm-12 px-0 text-right">
                                                <div className="ruleheading mobilelineheight">
                                                    <input
                                                        type="checkbox"
                                                        className="rulecheck checkmargin"
                                                        data-ruledataRuleID={ruledata._id}
                                                        checked={allCheckRule.includes(ruledata._id)}
                                                        onChange={(e) =>
                                                            toggleRowSelectionone(
                                                                ruledata._id,
                                                                ruledata.message,
                                                                ruledata.parkName,
                                                                simpledate(ruledata.startDate),
                                                                simpledate(ruledata.endDate),
                                                                ruledata.webName,
                                                                ruledata.webLink
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-4 px-1">
                                                <div className="ruleheading">
                                                    <div>Park Name</div>
                                                </div>
                                            </div>
                                            <div className="col-8 col-sm-8 px-1">
                                                <div className="ruleContent">
                                                    <div>{ruledata.parkName}</div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-4 px-1">
                                                <div className="ruleheading">
                                                    <div>Notification Date</div>
                                                </div>
                                            </div>
                                            <div className="col-8 col-sm-8 pl-1">
                                                <div className="validity ruleContent notifiycommonsize">
                                                    <div className="rulealways">
                                                        <div className="validitydates">
                                                            <div className="commonsize mb-1">
                                                                Start Date: {simpledate(ruledata.startDate)}
                                                            </div>
                                                            <div className="commonsize">
                                                                End Date: {simpledate(ruledata.endDate)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-4 px-1">
                                                <div className="ruleheading">
                                                    <div>Notification Message</div>
                                                </div>
                                            </div>
                                            <div className="col-8 col-sm-8 px-1">
                                                <div className="ruleContent">
                                                    <div>
                                                        {ruledata.message}{" "}
                                                        {funcLinkAdd(ruledata.webName, ruledata.webLink)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-4 px-1">
                                                <div className="ruleheading">
                                                    <div>Settings</div>
                                                </div>
                                            </div>
                                            <div className="col-8 col-sm-8 px-1">
                                                <div className="settings ruleContent">
                                                    <div className="menubtn mobilemenubtn">
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                variant="success"
                                                                className="settingdropdown"
                                                            >
                                                                <MdSettings />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="actionicons">
                                                                <Dropdown.Item
                                                                    href="javascript:void(0)"
                                                                    onClick={() =>
                                                                        notifyEditRule(
                                                                            ruledata._id,
                                                                            ruledata.parkId,
                                                                            ruledata.parkName,
                                                                            ruledata.startDate,
                                                                            ruledata.endDate,
                                                                            ruledata.message,
                                                                            ruledata.webName,
                                                                            ruledata.webLink
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="actionbutton">Edit</span>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    href="javascript:void(0)"
                                                                    onClick={() =>
                                                                        notifyDeleteRule(
                                                                            ruledata._id,
                                                                            ruledata.parkName,
                                                                            ruledata.startDate,
                                                                            ruledata.endDate,
                                                                            ruledata.message,
                                                                            ruledata.webName,
                                                                            ruledata.webLink
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="actionbutton">Delete</span>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        {getMobileRuleList?.length > 0 && pending === false ? (
                            <div className="row mb-2 downblukactionborder">
                                <div className="col-12 col-sm-12 samemobilepadd">
                                    <div className="showtablehead">
                                        <div className="leftheading">
                                            <div>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => funcBulkAction(e)}
                                                    value={getBulkActions || ""}
                                                    style={{ width: "100%", marginRight: "5px" }}
                                                >
                                                    <option value="">Bulk Action</option>
                                                    <option value="enable">Enable</option>
                                                    <option value="disable">Disable</option>
                                                    <option value="delete">Delete</option>
                                                </Form.Control>
                                            </div>
                                            <div>
                                                {permission.create == 1 ? (
                                                    <>
                                                        {getBulkActions === "" ? (
                                                            <Button
                                                                className="blukrule my-0 "
                                                                size="md"
                                                                variant="contained"
                                                                disabled
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {getBulkActions === "delete" ? (
                                                            <Button
                                                                className="blukrule my-0 deleteapply"
                                                                size="md"
                                                                variant="contained"
                                                                disabled={isDisabled}
                                                                onClick={() =>
                                                                    multiDeleteRule(
                                                                        getMultipledisabled,
                                                                        getruleNameText,
                                                                        getruleDesText,
                                                                        getpriorityNumberText,
                                                                        getrulewebname,
                                                                        getrulweblink
                                                                    )
                                                                }
                                                            >
                                                                <span className="rulebulkaction"> Apply </span>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="rightheading mobilelineheight">
                                            <div className="createaddrulebtn">
                                                <input
                                                    type="checkbox"
                                                    className="rulecheck"
                                                    checked={selectAllChecked}
                                                    onChange={togglecheckSelectAll}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {getMobileRuleList?.length > 0 && pending === false ? (
                            <Tablepaginate
                                totalpage={totalRows}
                                tablerowvalue={functablerow}
                                currentPagevalue={currentPage}
                                rulecurrentpage={funcCurrentpage}
                                totalpagenum={getAllRules}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <ClipLoader
                        color={color}
                        loading={loading}
                        css={override}
                        size={60}
                    />
                )}
            </section>
        </div>
    );
}
