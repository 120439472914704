import React, { useEffect } from "react";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import TempBarChart from "../Charts/CitizenTemperatureChart";
import FieldStatusChart from "../Charts/CitizenFieldStatusChart";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Menu from "@material-ui/core/Menu";
import parkMap from "../../assets/img/park-map.jpg";
import logo from "../../assets/img/Logo.png";
import { moistureChart } from "../../config";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { GenerateEncryptedToken } from "../../views/Common";
import {
  DateRange,
  PhoneIphone,
  Room,
  ExpandMore,
  Remove,
  Build,
  Clear,
  Person,
} from "@material-ui/icons";
import CourtMap from "../CourtFieldStatus";
import FieldMap from "../FieldStatus";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MultiSelect from "react-multi-select-component";
import Modal from "./Modal";
import { dummyService } from "../../axios";
import FloodChart from "../Charts/CitizenFloodChart";
import "../../assets/scss/App.scss";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

import FieldStatus from "./CitizenFieldStatus";
import Courtstatus from "./courtsstatus";
import GateStatus from "./CitizenGateStatus";
import FloodSensorStatus from "./CitizenFloodsensorStatus";

import "../../assets/scss/App.scss";
import styles from "../../components/components.js";
import smartParkService from "../../axios";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { login_, token_, hasLoaded_ } from "../../reducers/login";
import { sensors_, sensorsHasLoaded_ } from "../../reducers/sensors";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Footer from "./../../components/Footer";
import NotifyMe from "../../views/NotifyMe";

import {
  permissions_,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import {
  userDashboard_,
  updateFieldSelections,
} from "../../reducers/userDashboard";
import ChurchStreetTennisCourt from "./ChurchStreetTennisCourt";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const millisecond_in_a_day = 86400000;

const useStyles = makeStyles(styles);

export default function UserDashboard() {
  const history = useHistory();
  const loginDetails = useSelector(login_);
  const sensors = useSelector(sensors_);
  const loginHasLoaded = useSelector(hasLoaded_);
  const sensorHasLoaded = useSelector(sensorsHasLoaded_);
  const _userdashboard = useSelector(userDashboard_);
  const token = useSelector(token_);
  const [loading] = React.useState(true);
  const [color] = React.useState("#ffffff");

  const classes = useStyles();
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [selectpark, setselectedpark] = React.useState("");

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  // const [isAdmin, SetisAdmin] = React.useState(JSON.parse(localStorage.getItem("user")).Role.Administrator);

  const role = _.get(loginDetails, ["Role"], "");
  const parkid = _.get(loginDetails, ["parkID"], "");
  // const [isAdmin, SetisAdmin] = React.useState(
  //   role ? role.Administrator : false
  // );
  const [permission, setpermission] = React.useState({});
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [notifyMobile, setNotifyMobile] = React.useState("");
  const [notifyEmail, setNotifyEmail] = React.useState("");
  const [NotifyPopUp, setNotifyPopUp] = React.useState(false);

  const SaveNotifyMe = async () => {
    var restoken = await GenerateEncryptedToken();
    const data = {
      EmailAddress: notifyEmail,
      MobilePhone: notifyMobile,
    };
    smartParkService
      .post("api/park/" + parkid + "/notify", data, {
        headers: { "x-access-token": restoken },
      })
      .then((res) => {
        if (res.status === 200) {
          alert("Notify User Saved");
        } else {
          alert(" User Details Not Saved");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const localTimeZoneOffset =
    (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;
  const getData = async () => {
    var graphData = [];
    var fields = [];
    if (selected) {
      var selectedfields = selected;
      for (var i = 0; i < selectedfields.length; i++) {
        fields.push(selectedfields[i].value);
      }
    }
    var range = {};
    var selectedDateRange = { start: start, end: end };
    if (selectedDateRange) {
      var dates = selectedDateRange;
      range.startDate = new Date(dates.start._d).getTime();
      range.endDate = new Date(dates.end._d).getTime();
    }

    if (moistureChart.useDummyData == false) {
      var diff = range.endDate - range.startDate;
      var view = "hour";
      if (diff <= 86499999) {
        view = "minute";
      }
      var restoken = await GenerateEncryptedToken();
      let url =
        "api/park/" +
        parkid +
        "/moisturev1/" +
        view +
        "?startDate=" +
        (range.startDate + localTimeZoneOffset) +
        "&endDate=" +
        (range.endDate + localTimeZoneOffset) +
        "&fields=" +
        fields.join(",");
      await smartParkService
        .get(url, {
          headers: { "x-access-token": restoken },
        })
        .then((res) => {
          if (res) {
            graphData = fieldwiseFilter(selectedfields, res.data.data);
          } else {
            console.log("FloodChart else error: ");
          }
        })
        .catch((error) => {
          console.error("FloodChart error: ", error);
        });
    }

    return graphData;
  };
  React.useEffect(() => {
    const { fieldDate } = _userdashboard?.data;
    if (fieldDate) {
      let { start, end } = fieldDate;
      const _rangeType = _userdashboard?.data?.fieldDate?.rangeType;
      const rangeDate = getDateBasedOnRange(_rangeType);
      if (_rangeType != "Custom Range" && rangeDate.length) {
        try {
          const startDate = moment(rangeDate[0])._d.getTime();
          const endDate = moment(rangeDate[1])._d.getTime();
          setState({ start: moment(startDate), end: moment(endDate) });
        } catch (err) {
          setState({ start: moment(start), end: moment(end) });
        }
      } else {
        setState({ start: moment(start), end: moment(end) });
      }
    }
  }, [_userdashboard]);

  const getDateBasedOnRange = (rangeType) => {
    switch (rangeType) {
      case "Last 24 Hours":
        return [moment().subtract(1, "days").toDate(), moment().toDate()];
      case "LastWeek":
        return [moment().subtract(6, "days").toDate(), moment().toDate()];
      case "Last Month":
        return [
          moment().subtract(1, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      case "Last 3 Month":
        return [
          moment().subtract(3, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      case "Last 6 Month":
        return [
          moment().subtract(6, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      case "LastYear":
        return [
          moment().subtract(12, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ];
      default:
        return [];
    }
  };

  React.useEffect(() => {
    if (window.location.href.toLowerCase().indexOf("/login") > -1) {
      history.push("/login");
    } else {
      var parkname = window.location.href.split("?");
      if (parkname.length > 1) {
        setselectedpark(parkname[1]);
        //history.push("/?park=" + parkname[1]);
      } else {
        setselectedpark("cfdp");
        // history.push("/?park=cfdppark");
      }
    }
  }, []);
  useEffect(() => {
    if (selected.length) {
      dispatch(updateFieldSelections({ fieldSelections: selected }));
    }
  }, [selected]);

  const fieldwiseFilter = (fields, data) => {
    var filteredData = [];
    if (fields && fields.length > 0 && data) {
      for (var i = 0; i < fields.length; i++) {
        var graphData = [];
        data.filter(function (obj) {
          if (obj.sensor == fields[i].value) {
            graphData.push({
              sensor: fields[i],
              field: fields[i].label,
              date: obj.date,
              moisture: obj.moisture,
              status: 10,
            });
          }
        });

        filteredData.push(graphData);
      }
    }
    return filteredData;
  };
  const [state, setState] = React.useState({
    start: moment().subtract(1, "days"),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start, end, rangeType) => {
    if (
      new Date(end._d).getTime() - new Date(start._d).getTime() <=
      millisecond_in_a_day
    ) {
      var endDate = new Date().getTime();
      end = moment(endDate);
      start = moment(endDate - millisecond_in_a_day);
    }
    dispatch(
      updateFieldSelections({
        fieldDate: {
          rangeType: rangeType,
          start: new Date(start._d).getTime(),
          end: new Date(end._d).getTime(),
        },
      })
    );
    setState({ start, end });
  };

  const [fieldStatus, setFieldStatus] = React.useState({});
  const updateFieldStatus = (fieldStatusData) => {
    setFieldStatus(JSON.stringify(fieldStatusData));
  };
  const label =
    start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const mobileMenuId = "Charts-Info-Button";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <ButtonGroup orientation="vertical" color="primary" aria-label="">
        <Button>
          <Room />{" "}
        </Button>
        <Button>Choose Date</Button>
        <Button>
          <b>NOTIFY ME</b>
          <small>When Status Changed</small>
        </Button>
        <Button>
          <b>DATA</b> <small>Download</small>
        </Button>
      </ButtonGroup>
    </Menu>
  );

  //Notify Me Popover
  const [PopoveranchorEl, setPopoverAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    //setPopoverAnchorEl(event.currentTarget);
    setNotifyPopUp(true);
  };

  const handleCloseEvent = () => {
    setNotifyPopUp(!NotifyMe);
  };

  const handleClose = () => {
    setPopoverAnchorEl(null);
  };

  const open = Boolean(PopoveranchorEl);
  const id = open ? "simple-popover" : undefined;

  const [showFieldStatus] = React.useState(false);

  const Jsondownload = async () => {
    const data = await getData();
    var fileDownload = require("js-file-download");
    fileDownload(JSON.stringify(data), "Data.json");
  };
  const [] = React.useState({
    LastUpdateDate: new Date().toLocaleDateString(),
  });
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  useEffect(() => {
    if (loginHasLoaded) {
      history.push("/UserDashboard");
    }
  }, [loginHasLoaded]);

  useEffect(() => {
    getParkFields();
  }, []);
  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "UserDashboard ";
    });
    setpermission(Dpermissions[0]);
  };

  const getParkFields = async () => {
    var restoken = await GenerateEncryptedToken();
    await smartParkService
      .get("api/citizen/fielddata", {
        headers: { "x-access-token": restoken },
      })
      .then((res) => {
        if (res.status === 200) {
          var fields = res.data.data;
          var options = [];
          fields.forEach(function (field) {
            options.push({
              label: field.FieldName,
              value: field.id,
            });
          });
          //options.push({ label: "All Fields", value: "ALL" });
          setOptions(options);
          const { fieldSelections } = _userdashboard?.data;
          if (fieldSelections.length) {
            setSelected(fieldSelections);
          } else {
            setSelected([options[0]]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={classes.citizenDashboard}>
      <header className={classes.citizenHeader}>
        <div class="logoBlock">
          <img src={logo} alt="Logo" />
        </div>
        <div class="locator">
          <Modal show={showFieldStatus} fieldStatus={fieldStatus} />
          <h5 class="dbtitle marB0">Morrisville Smart City Dashboard</h5>
          <Select
            // className={classes.select}
            fullWidth
            size="small"
            labelId="label"
            id="select"
            value={selectpark}
            onChange={(e) => {
              setselectedpark(e.target.value);
              history.push("/?" + e.target.value);
            }}
          >
            <MenuItem value="cfdp">Cedar Fork District Park</MenuItem>
            <MenuItem value="mafctenniscourts">MAFC Tennis Courts</MenuItem>
            <MenuItem value="churchstreettenniscourts">Church Street Park Tennis Court(s)</MenuItem>
            {/* <MenuItem value="courtmap">Court Map</MenuItem>
            <MenuItem value="fieldmap">Field Map</MenuItem> */}
          </Select>
        </div>
        <div>
          <div className={classes.buttonGroup} style={{ float: "right" }}>
            <ButtonGroup size="small" color="primary" aria-label="">
              <Button>
                <DateRangePicker
                  initialSettings={{
                    startDate: start.toDate(),
                    endDate: end.toDate(),
                    maxDate: new Date(),
                    ranges: {
                      "Last 24 Hours": getDateBasedOnRange("Last 24 Hours"),
                      LastWeek: getDateBasedOnRange("LastWeek"),
                      "Last Month": getDateBasedOnRange("Last Month"),
                      "Last 3 Month": getDateBasedOnRange("Last 3 Month"),
                      "Last 6 Month": getDateBasedOnRange("Last 6 Month"),
                      LastYear: getDateBasedOnRange("LastYear"),
                    },
                  }}
                  onCallback={handleCallback}
                >
                  <div id="reportrange">
                    <DateRange />
                    <span>{label}</span> <ExpandMore />
                  </div>
                </DateRangePicker>
              </Button>
              <Button onClick={handleClick}>
                <PhoneIphone />
                <span className={classes.selectBtnOptn}>
                  <b style={{ lineHeight: "normal" }}>Notify Me</b>
                  <span className={classes.spanTxt}>When Status changed</span>
                </span>
              </Button>
            </ButtonGroup>
            <Popover
              id={id}
              open={open}
              anchorEl={PopoveranchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div id="NotifyBlock">
                <form
                  noValidate
                  autoComplete="off"
                  className={classes.notifyBlock}
                >
                  <TextField
                    id="notifyNumber"
                    label="Your Number"
                    variant="outlined"
                    size="small"
                    color="primary"
                    value={notifyMobile}
                    onChange={(e) => setNotifyMobile(e.target.value)}
                  />{" "}
                  <br />
                  <br />
                  <TextField
                    id="notifyEmail"
                    label="Your Email"
                    variant="outlined"
                    size="small"
                    color="primary"
                    value={notifyEmail}
                    onChange={(e) => setNotifyEmail(e.target.value)}
                  />
                  <br />
                  <br />
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    className={classes.btnRound}
                    onClick={SaveNotifyMe}
                  >
                    Notify
                  </Button>
                </form>
              </div>
            </Popover>
          </div>
        </div>
      </header>
      <div class="content-wrapper">
        {selectpark == "cfdp" ? (
          <section class="content" style={{ marginLeft: "0" }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={9}>
                <Paper elevation={3}>
                  <FieldStatus />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={3}>
                {" "}
                <Paper elevation={3}> {/* <FloodSensorStatus /> */}</Paper>{" "}
              </Grid>
            </Grid>
            <Grid container>
              {/* Field Status and Moisture Chart Data */}
              <Grid container>
                <Grid item xs={12}>
                  <Paper elevation={3}>
                    <div class="box">
                      <div class="box-header with-border">
                        <h6 class="box-title">
                          Field Data – Historical &nbsp;&nbsp;
                        </h6>
                        <div
                          style={{
                            minwidth: "150px",
                            maxWidth: "350px",
                            width: "100%",
                            display: "inline-block",
                          }}
                        >
                          <MultiSelect
                            className={classes.Hgt30}
                            options={options}
                            value={selected}
                            onChange={setSelected}
                          />
                        </div>

                        <div
                          class="box-tools pull-right"
                          style={{ display: "none" }}
                        >
                          <IconButton
                            aria-label="Minimize"
                            size="small"
                            className={classes.margin2}
                          >
                            <Remove fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Settings"
                            size="small"
                            className={classes.margin2}
                          >
                            <Build fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Clear"
                            size="small"
                            className={classes.margin2}
                          >
                            <Clear fontSize="inherit" />
                          </IconButton>
                        </div>
                      </div>
                      <div class="box-body">
                        {selected.length > 0 ? (
                          <FieldStatusChart
                            selectedFileds={selected}
                            selectedDateRange={{ start: start, end: end }}
                            selectionChanged={new Date().getTime()}
                            updateFieldStatus={updateFieldStatus}
                          />
                        ) : (
                          <div>Loading..</div>
                        )}
                      </div>
                    </div>
                  </Paper>
                </Grid>
                {/* Flood Chart */}
                <Grid item xs={12}>
                  <Paper elevation={3}>
                    <div class="box">
                      <div class="box-header with-border">
                        <h6 class="box-title">
                          Crabtree Creek Flood Data – Historical
                        </h6>
                        <div
                          class="box-tools pull-right"
                          style={{ display: "none" }}
                        >
                          <IconButton
                            aria-label="Minimize"
                            size="small"
                            className={classes.margin2}
                          >
                            <Remove fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Settings"
                            size="small"
                            className={classes.margin2}
                          >
                            <Build fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Clear"
                            size="small"
                            className={classes.margin2}
                          >
                            <Clear fontSize="inherit" />
                          </IconButton>
                        </div>
                      </div>
                      <div class="box-body">
                        {selected.length > 0 ? (
                          <FloodChart
                            selectedDateRange={{ start: start, end: end }}
                            selectionChanged={new Date().getTime()}
                          />
                        ) : (
                          <div>Loading..</div>
                        )}
                      </div>
                    </div>
                  </Paper>
                  <br />
                </Grid>
                {/* Average Temperature and Precipitation */}
                <Grid item xs={12}>
                  <Paper elevation={3}>
                    <div class="box">
                      <div class="box-header with-border">
                        <h6 class="box-title">
                          Average Temperature and Precipitation
                        </h6>
                        <div
                          class="box-tools pull-right"
                          style={{ display: "none" }}
                        >
                          <IconButton
                            aria-label="Minimize"
                            size="small"
                            className={classes.margin2}
                          >
                            <Remove fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Settings"
                            size="small"
                            className={classes.margin2}
                          >
                            <Build fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="Clear"
                            size="small"
                            className={classes.margin2}
                          >
                            <Clear fontSize="inherit" />
                          </IconButton>
                        </div>
                      </div>
                      <div class="box-body">
                        {selected.length > 0 ? (
                          <TempBarChart
                            selectedDateRange={{ start: start, end: end }}
                            selectionChanged={new Date().getTime()}
                          />
                        ) : (
                          <div>Loading..</div>
                        )}
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </section>
        ) : (
          ""
        )}
        {selectpark == "mafctenniscourts" ? (
          <>
            <section class="content" style={{ marginLeft: "0" }}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Paper elevation={4}>
                    <Courtstatus />
                  </Paper>
                  {/* <Paper elevation={3}><GateStatus /></Paper> */}
                </Grid>
              </Grid>
            </section>
          </>
        ) : (
          ""
        )}
        {selectpark == "churchstreettenniscourts" ? (
          <>
            <section class="content" style={{ marginLeft: "0" }}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Paper elevation={4}>
                    <ChurchStreetTennisCourt />
                  </Paper>
                  {/* <Paper elevation={3}><GateStatus /></Paper> */}
                </Grid>
              </Grid>
            </section>
          </>
        ) : (
          ""
        )}
        {selectpark == "courtmap" ? <CourtMap /> : ""}
        {selectpark == "fieldmap" ? <FieldMap /> : ""}
      </div>
      <Footer />
      <NotifyMe NotifyPopUp={NotifyPopUp} handleCloseEvent={handleCloseEvent} />
    </div>
  );
}
