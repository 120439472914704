import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { login_ } from "../../reducers/login";
import { sensors_, sensorsHasLoaded_ } from "../../reducers/sensors";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  Grid,
  Link,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
} from "@material-ui/core";
import styles from "../../components/components.js";
import smartParkService from "../../axios";
import {
  initialState,
  rules_,
  updateRulesState,
  umountRulesState,
} from "../../reducers/rules";

const useStyles = makeStyles(styles);


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#0076BB",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles1 = makeStyles({
  table: {
    minWidth: 500,
  },
  summer: {
    marginRight: '45px',
  }
});

const useInputStyles = makeStyles((theme) => ({
  newRuleBlock: {
    width: "100%",

    "& .MuiOutlinedInput-root": {
      borderRadius: "100px",
    },
  },
}));

function getSteps() {
  return [
    "Select Park(s)",
    "Select Sensors",
    "Select Email Notification",
    "Select SMS Notification",
    "Select Automatic Actions",
  ];
}
const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "red",
    fontSize: "15px",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white,
    },
  },
}));




let cloneRulesInitialState = _.cloneDeep(initialState.data);

export default function Summary(props) {
  const classe = useStyles1();
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const sensorList = useSelector(sensors_);
  const dispatch = useDispatch();
  const rules = useSelector(rules_);
  const rulesRef = useRef(_.cloneDeep(rules));
  const classes = useStyles();
  const steps = getSteps();
  const [getprop, setprop] = React.useState(props);
  // console.log(getprop);
  useEffect(() => { }, []);
  //Stepper
  const [activeStep, setActiveStep] = React.useState(0);

  // //   FloodCondition: "",
  //       FloodDuration: "",
  //       FloodValue: "",
  const getSensors = () => {
    var sensorsHtml = [];
    for (var i = 0; i < rules.Rule.length; i++) {
      if (rules.Rule[i]) {
        for (var j = 0; j < rules.Rule[i].Field.length; j++) {
          rules.Rule[i].Field[j] &&
            sensorsHtml.push(<span>{rules.Rule[i].Field[j]}</span>);
        }
        rules.Rule[i].MoistureCondition &&
          sensorsHtml.push(
            <span>
              {"Moisture Sensor Condition :" + rules.Rule[i].MoistureCondition}
            </span>
          );
        rules.Rule[i].MoistureValue &&
          sensorsHtml.push(
            <span>
              {"Moisture Sensor Value : " + rules.Rule[i].MoistureValue}
            </span>
          );
        rules.Rule[i].Duration &&
          sensorsHtml.push(
            <span>{"Moisture Sensor Duration :" + rules.Rule[i].Duration}</span>
          );

        rules.Rule[i].FloodCondition &&
          sensorsHtml.push(
            <span>
              {"Flood Sensor Condition : " + rules.Rule[i].FloodCondition}
            </span>
          );
        rules.Rule[i].FloodValue &&
          sensorsHtml.push(
            <span>{"Flood Sensor Value : " + rules.Rule[i].FloodValue}</span>
          );
        rules.Rule[i].FloodDuration &&
          sensorsHtml.push(
            <span>
              {"Flood Sensor Duration : " + rules.Rule[i].FloodDuration}
            </span>
          );

        rules.Rule[i].NextCriteria &&
          sensorsHtml.push(<span>{rules.Rule[i].NextCriteria}</span>);
      }
    }
    return sensorsHtml;
  };

  const getEmails = () => {
    var emails = [];
    for (var i = 0; i < rules.Email.length; i++) {
      emails.push(<span>{rules.Email}</span>);
    }
    return emails;
  };
  const getStepList = (step, label) => {
    switch (step) {
      case 0:
        return (
          <li className={activeStep > step ? "stepActive" : ""}>
            <p>
              <i>{step}</i>
              {label}
            </p>
            {rules.ParkName && <span>{rules.ParkName}</span>}
          </li>
        );
      case 1:
        return (
          <li className={activeStep > step ? "stepActive" : ""}>
            <p>
              <i>{step}</i>
              {label}
            </p>
            {getSensors().map((e) => e)}
          </li>
        );
      case 2:
        return (
          <li className={activeStep > step ? "stepActive" : ""}>
            <p>
              <i>{step}</i>
              {label}
            </p>
            {rules.Email.length > 0 &&
              rules.Email.map((e) => {
                return <span>{e}</span>;
              })}
          </li>
        );
      case 3:
        return (
          <li className={activeStep > step ? "stepActive" : ""}>
            <p>
              <i>{step}</i>
              {label}
            </p>{" "}
            {rules.FromSMS.length > 0 &&
              rules.FromSMS.map((e) => <span>{e}</span>)}{" "}
          </li>
        );
      case 4:
        return (
          <li className={activeStep > step ? "stepActive" : ""}>
            <p>
              <i>{step}</i>
              {label}
            </p>

            {rules.Action.length &&
              rules.Action.map((e) => (
                <span>
                  {e.fieldName +
                    " - " +
                    (e.fieldStatus == "C" ? "closed " : "opened ")}
                </span>
              ))}
            {/* {rules.Action.length && rules.Action[0].fieldName && <span>{rules.Action[0].fieldName}</span>}
					{rules.Action.length && rules.Action[0].fieldStatus && <span>{rules.Action[0].fieldStatus == 'C' && 'closed'} {rules.Action[0].fieldStatus == 'O' && "opened"}</span>}   */}
          </li>
        );
    }
  };

  return (
    <div>
      <div className="listItems" style={{ display: "flex", width: "100%" }}>
        <div style={{ flexGrow: 1, cursor: "pointer" }}>
          {rules.ParkName ? (
            <>
              <p className="rulename">
                Rule Name : <b>{rules.RuleName}</b>
              </p>
              {
                // console.log(steps)
              }
              <ul className="steps">
                {steps.map((label, index) => getStepList(index, label))}
              </ul>
            </>
          ) : (
            <p className="selectrulename text-center"> please select the fields </p>
          )}
        </div>
        {
          (getprop.id === "summer") ?
            <div className={classe.summer}>
              <TableContainer component={Paper}>
                <Table className={classe.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Dessert (100g serving)</StyledTableCell>
                      <StyledTableCell align="center">Calories</StyledTableCell>
                      <StyledTableCell align="center">Fat&nbsp;(g)</StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell align="center">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.calories}</StyledTableCell>
                        <StyledTableCell align="center">{row.fat}</StyledTableCell>

                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div> : <></>
        }

      </div>
    </div>
  );
}
