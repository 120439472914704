import React, { useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { GenerateEncryptedToken } from "../../Common";
import smartParkService from "../../../axios";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import GolfCourseCard from "./../../../components/GolfCourseCard";
import GolfLogo from "../../../assets/img/GolfLogo.png";
import { Grid, Link, ListItemIcon } from "@material-ui/core";
import WeatherReportGolf from "../../../components/weather-report/indexGolf.js";
import moment from "moment";
import GolfMoreDetails from "./golf-more-details";
import { Helmet } from "react-helmet";
import Auth from "../../../Auth";
import { useHistory } from "react-router-dom";
import {
  login_,
  unmountLogin,
  usersessionid_,
  token_,
  hasLoaded_,
} from "../../../reducers/login";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  section1: {
    background: "#f8f8f8",
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  section2: {
    background: "#fff",
    padding: theme.spacing(1, 4),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "right",
      width: "23%",
    },
  },
  AppTitle: {
    cursor: "pointer",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },

  pageHeader: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  appBar: {
    background: "#fff",
    padding: theme.spacing(1),
  },
  sectionMobile: {
    display: "flex",
    position: "Relative",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mainContent: {
    marginTop: 90,
  },
  userLogin: {
    color: "#444",
    margin: theme.spacing(0, 2),
  },
  refreshButton: {
    background: "#fff !important",
    fontWeight: 600,
  },
}));
const ModalDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {},
  },
}))(Dialog);

const millisecond_in_a_day = 86400000;
export default function GolfDemo() {
  const history = useHistory();
  const loginHasLoaded = useSelector(hasLoaded_);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [moreDetails, setMoreDetails] = React.useState(false);
  const [cardType, setCardType] = React.useState("");
  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();
  const [state, setState] = React.useState({
    start: moment().subtract(1, "days"),
    end: moment(),
  });
  // Here new code for golf course
  const [GolfCouseData, setGolfCouseData] = React.useState([]);
  const [SingleGolfCouseData, setSingleGolfCouseData] = React.useState([]);

  const getGolfCourseData = () => {
    smartParkService
      .get("api/events/golf-demo/golf/courses")
      .then((res, err) => {
        // console.log(res.data)
        setGolfCouseData(res.data.data);
      })
      .catch(() => {
        console.log(
          "Error occurred while fetching citizen dashboard sensordata"
        );
      });
  };
  React.useEffect(() => {
    getGolfCourseData();
  }, []);

  const handleOpen = (value) => {
    setState({
      start: moment().subtract(30, "days"),
      end: moment(),
    });
    // setOpen(true);
    //dispatch(setFieldCourts([]));
    setSingleGolfCouseData(value);
    setMoreDetails(true);
    //window.scrollTo(0, 0)
  };
  const handleClose = () => {
    //setOpen(false);
    setCardType("");
  };

  return (
    <>
      <AppBar position="fixed" className={`golfappbar ${classes.appBar}`}>
        <Helmet>
          <title>CAROLINA TRACE COUNTRY CLUB</title>
        </Helmet>
        <Toolbar className={`golftoolbar ${classes.toolbar}`}>
          <div
            className="logoBlockgolf"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (moreDetails) {
                setMoreDetails(false);
              } else {
                window.location.reload();
              }
            }}
          >
            <img src={GolfLogo} alt="Logo" />
          </div>
          <Typography
            variant="h4"
            color="primary"
            className={`golfapptitle ${classes.AppTitle}`}
            onClick={() => {
              if (moreDetails) {
                setMoreDetails(false);
              } else {
                window.location.reload();
              }
            }}
          >
            CAROLINA TRACE COUNTRY CLUB DASHBOARD
          </Typography>
          <div className={`desktopicon ${classes.sectionDesktop}`}></div>
          {/* <div className={classes.sectionDesktop}>
          {loginHasLoaded && 
          <MenuItem onClick={() => Logout()}>
              <Link color="secondary">
                <b>Logout</b>
              </Link>
            </MenuItem>
          }
          </div> */}
          {/* <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
          </div> */}
        </Toolbar>
      </AppBar>

      {!moreDetails ? (
        <>
          <main className={classes.mainContent}>
            <section className={classes.section1}>
              <Grid container spacing={4} className="justify-content-center">
                <Grid item xs={12} className="weatherreportcss">
                  <WeatherReportGolf />
                </Grid>
                {GolfCouseData &&
                  GolfCouseData.length > 0 &&
                  GolfCouseData.map((obj, idx) => (
                    <Grid item xs={12} md={6} lg={4} key={idx}>
                      <GolfCourseCard
                        cardType="golfCourse"
                        actions
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        courseData={obj}
                      />
                    </Grid>
                  ))}
              </Grid>
            </section>
          </main>
        </>
      ) : (
        <GolfMoreDetails
          cardType={cardType}
          setMoreDetails={setMoreDetails}
          courseData={SingleGolfCouseData}
        />
      )}
      <footer className="dash-footer">Powered by Varidx</footer>
    </>
  );
}
