import smartParkService from "../../../axios";

async function weatherWidgetApi() {
  try {
    const response = await smartParkService.get(
      "/api/external/weather/widgetdata"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching weather widget data:", error);
    throw error;
  }
}

export default weatherWidgetApi;
