import React from "react";
import { useSelector,useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { Link } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "./GateModel";
import smartParkService from "../../axios";
import IconButton from "@material-ui/core/IconButton";
import { Clear, OpenInNewRounded } from "@material-ui/icons";
import { login_, token_ } from "../../reducers/login";
import _ from "lodash";
import "../../assets/scss/App.scss";
const ButtonLink = withStyles((theme) => ({
  root: {
    display: "inline !important",
    color: "blue !important",
    marginRight: "10px !important",
    padding: "0 !important",
    textDecoration: "underline",
    border: "none !important",
    fontSize: "13px !important",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
  },
}))(Link);
export default function GateStatus() {
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ['parkID'], '');
  const token = useSelector(token_);
  smartParkService.defaults.headers.common['x-access-token'] = token;
  const [devicestatus, setdevicestatus] = React.useState({});
  const [status, setstatus] = React.useState([]);
  const [open, setopen] = React.useState(false);
  const [Openorclose, setOpenorclose] = React.useState("");
  const [devicename, setdevicename] = React.useState([]);
  const [Iselectall, setIselectall] = React.useState(false);
  const [allChecked, setallChecked] = React.useState(false);
  const getFields = () => {
    var url = "api/citizen/device";
    smartParkService
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          var data = res.data.data;
          var dataconst = data.map((d) => {
            return {
              deviceID: d.deviceID,
              isChecked: false,
              devicename: d.devicename,
              devicestatus: d.devicestatus,
            };
          });
          setdevicename(dataconst);
        }
      })
      .catch((error) => {});
  };
  const handleClose = () => {
    setopen(false);
    setallChecked(false);
    getFields();
  };
  const handleFieldopen = () => {
    var isListChecked = false;
    if (
      devicename.filter(function (obj) {
        if (obj.isChecked) isListChecked = true;
      })
    );
    if (isListChecked) {
      setOpenorclose("Open");
      setopen(true);
    }
  };
  const handleFieldClose = () => {
    var isListChecked = false;
    if (
      devicename.filter(function (obj) {
        if (obj.isChecked) isListChecked = true;
      })
    );
    if (isListChecked) {
      setOpenorclose("Closed");
      setopen(true);
    }
  };
  const handleChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    let devicename1 = devicename;
    let allChecked1 = allChecked;
    if (itemName === "checkAll") {
      allChecked1 = checked;
      devicename1 = devicename.map((item) => ({
        ...item,
        isChecked: checked,
      }));
    } else {
      devicename1 = devicename.map((item) =>
        item.deviceID === itemName ? { ...item, isChecked: checked } : item
      );
      allChecked1 = devicename1.every((item) => item.isChecked);
    }
    setdevicename(devicename1);
    setallChecked(allChecked1);
  };
  const updateStateList = (e, value) => {
    if (e.target.checked) {
      //append to array
      // this.setState({
      //   status: status.concat([value]),
      // });
      setstatus(status.concat([value]));
    } else {
      //remove from array

      setstatus(
        status.filter(function (val) {
          return val !== value;
        })
      );
    }
  };
  React.useEffect(() => {
    getFields();
  }, [devicestatus]);
  return (
    <div>
      <Modal
        show={open}
        status={devicename}
        closePopup={handleClose}
        openclose={Openorclose}
      />
      <div class="box">
        <div class="box-header padB0">
          <h6 class="marB0">Gate Status</h6>
          <div class="float-right" style={{ marginBottom: "-10px" }}>
          </div>
        </div>
        <div class="box-body">
          <div class="divGrid">
            <div class="divGridHeading">
              <div class="divGridRow">
                <div class="divGridHead">
                  {" "}
                  Gate
                </div>
                <div class="divGridHead">Status</div>
              </div>
            </div>
            <div class="divGridBody">
              {devicename.map((m) => (
                <div class="divGridRow">
                  <div class="divGridCell">
                    {m.devicename}
                  </div>
                  <div class="divGridCell">
                    <div class="d-flex">
                      <FiberManualRecordIcon
                        fontSize="small"
                        style={{
                          color:
                            m.devicestatus === "Open" ? green[500] : red[500],
                        }}
                      />{" "}
                      {m.devicestatus}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
