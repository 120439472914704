import React from "react";
import * as XLSX from "xlsx";
import { Button, Input } from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ImportExcelFunctionComp = ({
  handleClickOpen,
  handleClose,
  open,
  Title,
  ImportDataFromXLXSFile
}) => {
  const [fileNameStr, setFileNameStr] = React.useState("");
  const filePathset = (e) => {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    setFileNameStr(file);
    console.log(file);
  };
  const readFile = () => {
    var f = fileNameStr;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data); // shows that excel data is read
      var dataObj = convertToJson(data);
     // console.log(JSON.parse(dataObj)); // shows data in json format
      ImportDataFromXLXSFile(JSON.parse(dataObj));
    };
    reader.readAsBinaryString(f);
    handleClose();
  };
  const convertToJson = (csv) => {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Title ? Title : "Import Data"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Input type="file" onChange={filePathset} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={readFile}>
            <ImportExportIcon color="primary" /> Import
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImportExcelFunctionComp;
