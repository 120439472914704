import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Link from "@material-ui/core/Link";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";
import { Button, Input } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import { confirmAlert } from "react-confirm-alert";
import smartParkService from "../../axios";
import Paper from "@material-ui/core/Paper";
import { Email, Sms, Add, ToggleOff, DeleteOutline } from "@material-ui/icons";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import "../../assets/scss/App.scss";
import styles from "../../components/components.js";
import moment from "moment";
import { paperAutoAdjustIntervalInMS } from "../../config/index";
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import _ from "lodash";
import Export from "../../components/ImportExportComponents/Export";
import ImportExcelFunctionComp from "../../components/ImportExportComponents/ImportExcelFunctionComp";
const useStyles = makeStyles(styles);

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

export default function Assets(props) {
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#ffffff");
  const classes = useStyles();
  const [load, setload] = useState(false);
  const loginDetails = useSelector(login_);
  const ParkID = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const history = useHistory();
  const [permission, setpermission] = useState({});
  const [rows, setrows] = useState([]);
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const routeAddItem = () => {
    history.push("AddItem");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const columns = [
    { field: "DeviceName", headerName: "Device Name", width: 250 },
    { field: "DeviceType", headerName: "Type of Device", width: 250 },
    { field: "ManufacturerName", headerName: "Manufacturer", width: 250 },
    { field: "ModelNo", headerName: "Model Number", width: 250 },
    { field: "PowerInput", headerName: "Power Input", width: 250 },
    {
      field: "InstallationDate",
      headerName: "Installed on",
      width: 250,
      valueFormatter: (params) => {
        if (params.value) {
          return moment(new Date(params.value)).format("MM/DD/YYYY");
        }
      },
    },

    {
      field: "Actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div>
          <Delete
            style={{ color: "#03354b", fontSize: "1.2rem", cursor: "pointer" }}
            onClick={() => {
              DeleteAsset(params.row.id);
            }}
          />
        </div>
      ),
    },
  ];
  const fileName = "AssetManagement";
  setTimeout(() => {
    var element = document.getElementsByClassName("sensorGrid__SmartPark");
    if (element && element.length > 0) {
      var calculatedHeight = element[0].clientHeight + 25 + "px";
      document.getElementById("sensorPaper__SmartPark").style.height =
        calculatedHeight;
    }
  }, paperAutoAdjustIntervalInMS);
  const DeleteAsset = (id) => {
    confirmAlert({
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var url = "api/park/" + ParkID + "/asset?assetID=";
            smartParkService
              .delete(url + id, {
                headers: {
                  userid: userid,
                },
              })
              .then((res, err) => {
                setload(load == true ? false : true);
                alert("Record Deleted Successfully");
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };
  const ImportDataFromXLXSFile = (dataObj) => {
    var url = "api/park/" + ParkID + "/assetexceldatatoDB";
    smartParkService.post(url, dataObj).then((res) => {
      if (res) {
        // console.log(res);
        setload(!load);
      } else {
      }
    });
  };
  useEffect(() => {
    var url = "api/park/" + ParkID + "/assets";
    smartParkService.get(url).then((res) => {
      if (res) {
        const responseData = res.data.data;
        responseData.forEach(function (assetData) {
          delete assetData.ManufacturerID;
          delete assetData.ParkID;
          delete assetData.IsActive;
        });
        console.log(responseData);
        setExcelData(responseData);
      } else {
      }
    });
  }, [load]);
  useEffect(() => {
    var url = "api/park/" + ParkID + "/assets";
    smartParkService.get(url).then((res) => {
      if (res) {
        setrows(res.data.data);
      } else {
      }
    });
  }, [load]);
  return (
    <div className={classes.root}>
      <div className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Asset Management</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                  Asset Management
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <Grid container>
            <div class="content-middle" style={{ width: "95%" }}>
              <Grid item xs={12}>
                <div class="btnRules">
                  {/* {permission.create == 1 ? */}
                  <Link component="button" onClick={routeAddItem}>
                    {" "}
                    <Add style={{ fontSize: 18 }} /> Add Item
                  </Link>
                  <div style={{ float: "right", marginLeft: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <Export apiData={excelData} fileName={fileName} /> &nbsp;
                      <Button
                        className="btnRules"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid black",
                          borderRadius: "10px",
                        }}
                        onClick={handleClickOpen}
                      >
                        <ExpandMoreIcon color="primary" />
                        Import
                      </Button>
                    </div>
                  </div>
                  <ImportExcelFunctionComp
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    open={open}
                    ImportDataFromXLXSFile={ImportDataFromXLXSFile}
                    Title="Import Asset Management Data"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Grid item xs={12}>
                <Paper
                  id="sensorPaper__SmartPark"
                  className={classes.paper + " " + classes.withStripe}
                >
                  <div style={{ width: "100%", cursor: "pointer" }}>
                    <DataGrid
                      className="sensorGrid__SmartPark"
                      rows={rows}
                      columns={columns}
                      hideFooter={true}
                      autoHeight={true}
                      onCellClick={(e) => {
                        if (e.field != "Actions")
                          history.push("/EditItem?id=" + e.row.id);
                      }}
                    />
                  </div>
                </Paper>
              </Grid>
            </div>
          </Grid>
        </section>
      </div>
    </div>
  );
}
