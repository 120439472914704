import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";

const SvgContainer = ({ image, mappingArray }) => {
  const [viewBox, setViewBox] = useState("0 0 100 100");

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      setViewBox(`0 0 ${width} ${height}`);
    };
  }, []);

  return (
    <Box
      style={{
        flex: 1,
        display: "flex",
        height: "100%",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        style={{ width: "100%", height: "100%" }}
        preserveAspectRatio="xMidYMid meet"
      >
        <image
          href={image}
          x="0"
          y="0"
          width="100%"
          height="100%"
          style={{ maxHeight: "100%" }}
          preserveAspectRatio="xMidYMid meet"
        />
        {mappingArray?.map((item, index) => {
          if (item.type === "text") {
            return (
              <>
                <rect
                  key={index}
                  width={item?.display?.length * 7.5}
                  height={50}
                  fill="white"
                  opacity="0.8"
                  rx={5}
                  ry={5}
                  x={item.xAxis}
                  y={item.yAxis - 40}
                />
                <text
                  key={index}
                  x={item.xAxis}
                  y={item.yAxis}
                  fontFamily="Arial"
                  fontSize={item.fontSize || item.width / 3 || 10}
                  fill={item.textColor || "black"}
                  fontWeight="500"
                  enableBackground={false}
                >
                  {item.body}
                </text>
                {item.display && (
                  <text
                    key={`${index}-text`}
                    x={item.xAxis}
                    y={item.yAxis - 20}
                    fontFamily="Arial"
                    fontSize={item.width / 3}
                    fill="black"
                    fontWeight="500"
                  >
                    {item.display}
                  </text>
                )}
              </>
            );
          }
          if (item.type === "rect") {
            return (
              <rect
                key={index}
                x={item.xAxis}
                y={item.yAxis}
                width={item.width}
                height={item.height}
                rx={item.radiusX}
                ry={item.radiusY}
                fill={item.color}
                stroke={item.stroke || null}
                strokeWidth={item.strokeWidth || null}
                strokeLinecap={item.lineCap || null}
              />
            );
          }
          if (item.type === "image") {
            return (
              <>
                <rect
                  key={index}
                  width={item?.display?.length * 7.5}
                  height={60}
                  fill="white"
                  opacity="0.8"
                  rx={5}
                  ry={5}
                  x={item.xAxis}
                  y={item.yAxis - 20}
                />
                <image
                  key={index}
                  href={item.body}
                  x={item.xAxis}
                  y={item.yAxis}
                  width={item.width}
                  height={item.height}
                  fill={item.fillColor}
                />
                {item.display && (
                  <text
                    key={`${index}-text`}
                    x={item.xAxis}
                    y={item.yAxis - 2}
                    fontFamily="Arial"
                    fontSize={item.width / 3}
                  >
                    {item.display}
                  </text>
                )}
                {item.text && (
                  <text
                    key={`${index}-text`}
                    x={item.xAxis}
                    y={item.yAxis + 35}
                    fontFamily="Arial"
                    fontSize={item.width / 3}
                    fill="black"
                    fontWeight="500"
                  >
                    {item.text}
                  </text>
                )}
              </>
            );
          }
          return null;
        })}
      </svg>
    </Box>
  );
};

export default SvgContainer;
