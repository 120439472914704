import iconMicrosoft from "./../assets/img/icon-microsoft.svg";
const componentsStyle = (theme) => ({
  root: {
    display: "flex",
    "& .MuiFormControl-marginNormal": { margin: "0 !important" },
    "& .MuiTypography-root": { fontSize: "0.8rem", textAlign: "left" },
    "& .MuiDataGrid-root": { color: "#495057" },
    "& .MuiDataGrid-colCellTitle": { fontWeight: "bold" },
    "& .MuiDataGrid-row.Mui-even": { background: "#fafafa" },
    "& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
      background: "#fafafa",
    },
    "& .MuiDataGrid-root .MuiDataGrid-row": { fontSize: "0.8rem" },
    "& .MuiLink-root": {
      color: "#495057",
      marginRight: theme.spacing(2),
      display: "inline-flex",
      alignItems: "center",
      border: "1px solid #d3dbe4",
      padding: "0.5rem 0.7rem",
      borderRadius: "20px",
      fontSize: "0.9rem",
      [theme.breakpoints.down("xs")]: {
        padding: "0.2rem 0.5rem",
        fontSize: "0.8rem",
        margin: "5px",
      },
    },
    "& .MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#03354b",
    },
    "& .MuiLink-button:focus": {
      outline: "0",
    },
    "& .MuiLink-underlineHover:hover": {
      color: "#03354b",
      textDecoration: "none",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#03354b",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#3fb548",
    },
    "& .MuiSelect-root": {
      padding: "10px",
    },
    "& .MuiAutocomplete-root": { width: "100%" },
    "& .MuiInput-underline:before": { borderBottom: "none" },
    newRuleBlock: {
      width: "80%",
      padding: "10px",
      // '&.MuiOutlinedInput-root': {borderRadius:'100px'}
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
    "& .MuiButton-label": {
      fontSize: "0.7rem",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "x-small !important",
      },
    },
    "& .MuiInputBase-input": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "smaller",
      },
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "0.9rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "smaller",
      },
    },
    "& .MuiTab-root": { fontSize: "0.75rem", fontWeight: "bold" },
    "& .MuiTabs-flexContainer": {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "x-small !important",
      "& .MuiTypography-root": { fontSize: "x-small  " },
      "& .MuiTypography-body1": { fontSize: "x-small  " },
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: "red",
    },
  },
  f13: { fontSize: "13px" },
  section: {
    minHeight: "100vh",
    maxHeight: "1600px",
    overflow: "hidden",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
  },
  loginBox: {
    width: "400px",
    height: "auto",
    padding: "10px",
  },

  loginHeader: {
    marginBottom: "10px",
  },
  citizenDashboard: {
    display: "block",
  },
  tick: {
    color: "green",
    marginLeft: "20px"
  },
  citizenHeader: {
    minHeight: 50,
    height: "auto",
    padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0 2px 2px rgb(0 0 0 / 3%), 0 1px 0 rgb(0 0 0 / 3%)",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
      "& h5": { fontSize: "small" },
    },
  },
  sm_hidden: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  sm_visible: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  w150: { width: "150px" },
  w100: {
    width: "100%"
  },
  timeselect: {
    width: "100%"
  },
  startdatecss:
  {
    position: "relative",
    "& .react-datepicker-wrapper": {
      display: "inline"
    }

  },
  startdatecss:
  {
    "& input": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px !important",
      },
    },

  },
  rulelabel: {

    marginRight: "10px",
    marginBottom: "2px"
  },
  logo: {
    width: "200px",
    height: "60px",
    marginBottom: "10px",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  forgotPwd: {
    lineHeight: "2.5",
    textAlign: "right",
    "& a": {
      color: "#03354b",
    },
  },
  signIn: {
    justifyContent: "center",
    fontSize: "1rem",
  },
  floatLeft: {
    float: "left",
  },
  floatRight: {
    float: "right",
  },
  textCenter: {
    textAlign: "center",
  },
  btnGoogle: {
    border: "1px solid #e6e6e6 !important",
    boxShadow: "none !important",
    height: "50px",
    width: "150px",
    fontWeight: "bold !important",
    marginRight: "5px",
    borderRadius: "4px !important",
  },
  btnMicrosoft: {
    border: "1px solid #e6e6e6 !important",
    boxShadow: "none !important",
    height: "50px",
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.54)",
  },
  iconMicrosoft: {
    backgroundImage: "url(" + iconMicrosoft + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "20px",
    height: "20px",
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px",
  },
  cardPrimaryBg: { backgroundColor: "#03354b", color: "#fff" },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
  },
  divider: {
    marginTop: "0px",
    marginBottom: "0px",
    padding: "1rem 1.875rem 0",
    textAlign: "center",
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important",
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0",
  },
  inputIconsColor: {
    color: "#495057",
  },
  loginButtons: { borderRadius: "100px", width: "90%", fontSize: "0.75rem" },
  btnSignUp: {
    width: "150px",
    backgroundColor: "transparent",
    color: "#03354b",
    fontSize: "13px",
  },
  btnLogin: { width: "150px", fontSize: "13px" },

  btnRound: { borderRadius: "100px", fontSize: "0.75rem" },
  buttonGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  flexColMobile: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  alignItemsStartMobile: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  selectBox: {
    border: 0,
    outline: 0,
    color: "gray",
  },
  selectBtnOptn: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  moreHorizIcon: { color: "#03354b", background: "#FFF" },
  spanTxt: {
    color: "gray",
    fontSize: "10px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "normal",
      fontSize: "x-small !important",
    },
  },
  arrowIcon: { transform: "rotate(-90deg)" },
  datePicker: {
    background: "#fff",
    cursor: "pointer",
    padding: "5px 10px",
    border: "1px solid #ccc",
    width: "100%",
  },
  iconPrimary: { color: "#03354b" },
  iconError: { color: "red" },
  iconopen: { color: "green" },
  pad20: { padding: "20px" },
  padL35: { paddingLeft: "35px" },
  marRight: {
    marginRight: theme.spacing(1),
  },
  marLeft: {
    marginLeft: theme.spacing(1),
  },
  stepperBtn: {
    width: "30px",
    height: "30px",
    padding: "5px",
    borderRadius: "100%",
    marginRight: theme.spacing(2),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 100,
  },
  paper: {
    // boxShadow: "0 0 35px 0 rgba(73,80,87,.15)",
    transform: "translate3d(0px, 0px, 0px)",
    // borderTop: "6px solid #03354b",
    padding: 10,
    marginBottom: 0,
  },
  footerButtons: {
    textAlign: "center",
    padding: "10px 0",
    marginTop: "5px",
    borderTop: "1px solid #ddd",
  },
  withStripe: {
    borderTop: "6px solid #03354b",
  },
  twilightBlock: { border: "2px solid #ddd", margin: "1%" },
  borderRight: { borderRight: "2px solid #ddd" },
  textField: { width: "25%" },
  textRight: { textAlign: "right" },
  input: {
    marginLeft: theme.spacing(1),
    width: "80%",
    flex: 1,
  },
  closeButton: {
    float: "right",
  },
  w75: { width: 75 },
  w65: { width: 65 },
  w85: { width: 85 },
  w50: { width: 150 },
  w25p: { width: "25%" },
  w50p: { width: "50%" },
  w10p: { width: "10%" },
  w80p: { width: "80%" },
  w100p: { width: "100%" },
  modalHeight: { height: 600 },
  contentPage: {
    flexGrow: 1,
    marginTop: 65,
    minHeight: " calc(100vh - 100px )",
  },
  contentPageone: {
    flexGrow: 1,
    marginTop: 0,
  },
  smDisplayNone: {
    [theme.breakpoints.down("sm")]: { display: "none" },
  },
  select: {
    border: "1px solid #8f9598",
    borderRadius: 4,
    borderBottom: "none",
  },
  circularLoaderAtCenter: {
    position: "absolute",
    left: "46%",
    top: "50%",
  },
  rootacc: {
    width: '100%',
  },
  // heading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   fontWeight: theme.typography.fontWeightRegular,
  // },
  stepitem: {
    display: "inherit",
  },
  steppadd: {
    padding: "2px",
  },
  rulefieldsform: {
    display: "inherit",
  },
  openclosedisabled: {
    textDecoration: "none !important",
    background: "#cecbcb",
    color: "white !important",
    padding: "5px 10px !important",
    borderRadius: "5px"
  },
  blueButton: {
    background: "#0179bb",
    textDecoration: "none !important",
    color: "white !important",
    padding: "5px 10px !important",
    borderRadius: "5px"
  },
  redButton: {
    background: "#f44336",
    textDecoration: "none !important",
    color: "white !important",
    padding: "5px 10px !important",
    borderRadius: "5px"
  },
  yellowButton: {
    background: "#dfc89a"
  },


});

export default componentsStyle;
