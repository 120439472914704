import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import App from './App'
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './reducers/store';
import storeFormdata from './reducers/reducers';
import { createStore } from '@reduxjs/toolkit';
const storedata = createStore(storeFormdata);
// import store from './reducers/store';
// console.log(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
