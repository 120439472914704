import React ,{useState}from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  Add,
  AddCircle,
  Cancel,
  Sms,
  Search,
  Close,
  KeyboardBackspace,
  ExpandMore,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../../reducers/permissions";

const useStyles = makeStyles(styles);

const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "red",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white,
    },
  },
}));
export default function AddUser() {
  const [permission, setpermission] = useState({});
  const classes = useStyles();
  const history = useHistory();
  const helperTestClasses = helperTextStyles();
  const loginDetails = useSelector(login_);
  const ParkID = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const firstName = _.get(loginDetails, ["FirstName"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [devicename, setdevicename] = useState("");
  const [AzureDeviceID, SetAzureDeviceID] = useState("");
  const [AzureDeviceConnectionString, SetAzureDeviceConnectionString] =
    useState("");
  const [AzureHubID, setAzureHubID] = useState("");
  const [AzureHubConnectionString, SetAzureHubConnectionString] =
    useState("");
  const [LocationCoordinates, SetLocationCoordinates] = useState({});
  const [ManufacturerName, SetManufacturerName] = useState("");
  const [ManufacturerID, SetManufacturerID] = useState("");
  const [ManufacturerDate, setManufacturerDate] = useState("");
  const [ModelNo, SetModelNo] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [TypeOfDevice, setTypeOfDevice] = useState("");
  const [DevicecollectionID, setDevicecollectionID] = useState("");
  const [errors, seterror] = useState({});
  const [model,setModel]=useState("");
  const [partNo,setPartNo]=useState("");
  const [yearOfMake,setYearOfMake]=useState("");
  const [powerInput,setPowerInput]=useState("");
  const [powerOutput,setPowerOutput]=useState("");
const [batteryModel,setBatteryModel]=useState("");
const [batteryPartNo,setBatteryPartNo]=useState("");
const [batteryYearofMake,setBatteryYearofMake]=useState("");
const [comments, setComments] = useState("");
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  React.useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);
  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "Device";
    });
    setpermission(Dpermissions[0]);
  };

  React.useEffect(() => {
    var id = window.location.href.split("=")[1];
    var url = "api/park/" + ParkID + "/device/";
    smartParkService.get(url + id).then((res) => {
      if (res.data.status == 200) {
        var {Model,DeviceType,DeviceName,ManufacturerID,PartNo,YearofMake,PowerInput,PowerOutput,BatteryModel,BatteryPartNo,BatteryYearofMake,Comments} = res.data.data;
       setModel(Model);setTypeOfDevice(DeviceType);setdevicename(DeviceName);
       SetManufacturerID(ManufacturerID);setYearOfMake(YearofMake);setPartNo(PartNo);setPowerInput(PowerInput);
       setPowerOutput(PowerOutput);setBatteryModel(BatteryModel);setBatteryPartNo(BatteryPartNo);setBatteryYearofMake(BatteryYearofMake);
       setComments(Comments)
      }
    });
  }, []);

  const populateDataFromAssetDetails = (assetItem) => {
    setdevicename(assetItem.DeviceName);
    setTypeOfDevice(assetItem.DeviceType);
    setModel(assetItem.Model);
    setYearOfMake(assetItem.YearofMake);
    setPowerInput(assetItem.PowerInput);
    setPowerOutput(assetItem.PowerOutput);
    setBatteryModel(assetItem.BatteryModel);
    setBatteryPartNo(assetItem.BatteryPartNo);
    setBatteryYearofMake(assetItem.BatteryYearofMake);
  }
  
  const fetchSensorsFromAssets = (assetNumber) => {
    if (assetNumber) {
      var url = "api/park/" + ParkID + "/asset/query?assetNumber=" + (assetNumber).toUpperCase();
      smartParkService
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            console.log("response :", res.data);
            populateDataFromAssetDetails(res.data);
          }
        })
    }
  }

  const UpdatedDeviceClick = () => {
    var dataobj={};
    dataobj.ParkID = ParkID;
    dataobj.ManufacturerID = ManufacturerID;
    dataobj.DeviceName = devicename;
    dataobj.DeviceType = TypeOfDevice;
    dataobj.Model = model;
    dataobj.PartNo = partNo;
    dataobj.YearofMake = yearOfMake;
    dataobj.PowerInput = powerInput;
    dataobj.PowerOutput = powerOutput;
    dataobj.BatteryModel = batteryModel;
    dataobj.BatteryPartNo = batteryPartNo;
    dataobj.BatteryYearofMake = batteryYearofMake;
    dataobj.Comments = comments;
    dataobj.LastEditedBy=firstName;
    //dataobj.SensorStatus = "Closed";
    var id = window.location.href.split("=")[1];
    var url = "api/park/" + ParkID + "/device/";
    smartParkService
      .post(url + id, dataobj, {
        headers: {
          userid: userid,
        },
      })
      .then((res) => {
        if (res.data.status == 200) {
          history.push("/Device");
        }
        else if (res.status === 401 || res.status === 403) {
          alert("You do not have permission to update device");  
        }
      })
      .catch(() => {
        alert("Error occurred while updating the device");
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Devices</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                  Devices
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <div class="content-middle">
            <div class="content-sub-header">
              <div>
                <Link
                  component="button"
                  onClick={() => history.push("/Device")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
              <div>
                <h3>Edit Device</h3>
              </div>
              <div>
                <Link
                  style={{ visibility: "hidden" }}
                  component="button"
                  onClick={() => history.push("/Users")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
            </div>

            <Paper className={classes.paper}>
              <div class="form-body">
                <Grid container spacing={3} className={classes.pad20}>
                  <Grid item xs={12} sm={12}>
                    <Select
                      fullWidth
                      value="Cedar Fork District Park"
                      label="Facility Name"
                    >
                      <MenuItem value="Cedar Fork District Park">
                        Cedar Fork District Park
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="AssetNumber"
                      name="AssetNumber"
                      label="Enter Asset Number to Fetch from Sensor Details"
                      fullWidth
                      onChange={(e) => {
                        fetchSensorsFromAssets(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                            <FormControl className={classes.w100p} variant="outlined">
                              <Select
                                size="small"
                                value={TypeOfDevice||"1"}
                                onChange={(e) => {
                                  setTypeOfDevice(e.target.value);
                                }}
                                label={"Type of Device"}
                                className={classes.selectEmpty}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="1">Type Of Device</MenuItem>
                                <MenuItem value="Moisture">Moisture</MenuItem>
                                <MenuItem value="Flood">Flood</MenuItem>
                                <MenuItem value="Gate">Gate</MenuItem>
                              </Select>
                              <FormHelperText style={{ color: "red", fontSize: "15px" }}>
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                 
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="devicename"
                      name="devicename"
                      label="Device Name"
                      value={devicename}
                      helperText={errors.devicename}
                      FormHelperTextProps={{ classes: helperTestClasses }}
                      fullWidth
                      onChange={(e) => {
                        setdevicename(e.target.value);
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="Model"
                      name="Model"
                      label="Model"
                      value={model}
                      onChange={(e) => {
                        setModel(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="PartNo"
                      name="PartNo"
                      label="Part Number"
                      fullWidth
                      value={partNo}
                      onChange={(e) => {
                      setPartNo(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="YearofMake"
                      name="YearofMake"
                      label="Year of Make"
                      value={yearOfMake}
                      onChange={(e) => {
                        setYearOfMake(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="PowerInput"
                      name="PowerInput"
                      fullWidth
                      label="Power Input"
                      value={powerInput}
                      onChange={(e) => {
                        setPowerInput(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <TextField
                      variant="outlined"
                      size="small"
                      id="PowerOutput"
                      name="PowerOutput"
                      fullWidth
                      label="Power Output"
                      value={powerOutput}
                      onChange={(e) => {
                        setPowerOutput(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="BatteryModel"
                      name="BatteryModel"
                      fullWidth
                      label="Battery Model"
                      value={batteryModel}
                      onChange={(e) => {
                        setBatteryModel(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="batteryPartNo"
                      name="batteryPartNo"
                      fullWidth
                      label="Battery Part Number"
                      value={batteryPartNo}
                      onChange={(e) => {
                        setBatteryPartNo(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                  <TextField
                      variant="outlined"
                      size="small"
                      id="batteryYearofMake"
                      name="batteryYearofMake"
                      fullWidth
                      label="Battery Year Of Make"
                      value={batteryYearofMake}
                      onChange={(e) => {
                        setBatteryYearofMake(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                            <div class="d-flex alignCenter">
                              <TextField
                                multiline={true}
                                variant="outlined"
                                size="small"
                                value={comments}
                                fullWidth
                                label="Comments"
                                onChange={(e) => {
                                  setComments(e.target.value);
                                }}
                                fullWidth
                              ></TextField>
                            </div>
                          </Grid>
                </Grid>
              </div>
              <div class="form-footer">
                {permission.update == 1 ? (
                  <Button
                    size="sm"
                    color="primary"
                    variant="contained"
                    className={classes.btnRound}
                    onClick={UpdatedDeviceClick}
                  >
                    Update Device
                  </Button>
                ) : (
                  ""
                )}
                &nbsp;&nbsp;
                <Button
                  size="sm"
                  variant="outlined"
                  className={classes.btnRound}
                  onClick={() => {
                    history.push("/Device");
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </Paper>
          </div>
        </section>
      </div>
    </div>
  );
}
//   LocationCoordinates: { type: String },
//   SensorName: { type: String },
//   AzureDeviceID: { type: String },
//   AzureDeviceConnectionString: { type: String },
//   AzureHubID: { type: String },
//   AzureHubConnectionString: { type: String },
