import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import smartParkService from "../../axios";
import { useHistory } from "react-router-dom";
import { login_, unmountLogin, token_ } from "../../reducers/login";
import {
  sensors_,
  sensorServiceThunk,
  sensorsHasLoaded_,
} from "../../reducers/sensors";
import _ from "lodash";
const ButtonSm = withStyles((theme) => ({
  root: {
    fontSize: 13,
    textTransform: "none",
    borderRadius: "100px",
  },
}))(Button);
const ModalDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      padding: "25px",
    },
  },
}))(Dialog);
export default function FormDialog(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const sensorHasLoaded = useSelector(sensorsHasLoaded_);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [yes, setYes] = React.useState("");
  const [isValid, setIsvalid] = React.useState(true);
  const handleSubmit = async () => {
    if (yes.trim().toLowerCase() === "yes") {
      var perparefieldIDSarray = () => {
        var fieldarray = [];
        for (var i = 0; i < props.status.length; i++) {
          if (props.status[i].isChecked == true) {
            fieldarray.push(props.status[i].fieldID);
          }
        }
        return fieldarray;
      };
      var perparefieldnamearray = () => {
        var fieldnamearray = [];
        for (var i = 0; i < props.status.length; i++) {
          if (props.status[i].isChecked == true) {
            fieldnamearray.push(props.status[i].fieldName);
          }
        }
        return fieldnamearray;
      };
      var Dataobject = {};
      Dataobject.fieldID = perparefieldIDSarray();
      Dataobject.fieldName = perparefieldnamearray();
      Dataobject.fieldStatus = props.openclose;
      Dataobject.LastEditedBy = _.get(loginDetails, ["FirstName"], "");
      Dataobject.disableNotification = true;
      let search = window.location.search;
      let params = new URLSearchParams(search);

      if (params.get('adminmode') === "1")
        Dataobject.disableNotification = false;
      console.log(Dataobject)
      smartParkService
        .post("api/park/" + parkid + "/updatefieldstatus", Dataobject, {
          headers: {
            userid: userid,
          },
        })
        .then((res, error) => {
          if (res.status === 200) {
            const respData = res.data;
            const errResp = respData && respData.length > 0 && respData.filter((obj) => {
              return obj.status !== 200
            })
            let errFieldNames = errResp && errResp.length > 0 && errResp.map((obj) => {
              return obj.sensorName;
            })
            if (errFieldNames && errFieldNames.length > 0) {
              alert("Error occurred while changing " + errFieldNames.join(", ") + " field(s) status");
            }
            const parkid = _.get(loginDetails, ["parkID"], "");
            parkid && dispatch(sensorServiceThunk(parkid));
          }
          if (res.status === 401 || res.status === 403) {
            alert("You do not have permission to update field(s) Status");
          }
          setYes("");
          props.closePopup();
        })
        .catch((err) => {
          setYes("");
          props.closePopup();
          alert("Error occurred while updating field(s) status");
          console.log(err);
        });
    } else {
      setIsvalid(false);
    }
  };

  return (
    <div>
      <ModalDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.show}
        aria-labelledby="field-model"
      >
        <DialogTitle id="field-model-title">
          <strong>
            {props.openclose === "Closed" ? "Close" : "Open"} fields at Cedar
            Fork District Park
          </strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div class="plainGrid fc-width50">
              <div class="plainGridHeader">
                <div class="plainGridRow">
                  <div class="plainGridHead">Field Name</div>
                  <div class="plainGridHead">Current Status</div>
                </div>
              </div>
              <div class="plainGridBody">
                {props?.status?.map(
                  (m) =>
                    m.isChecked == true && (
                      <div class="plainGridRow">
                        <div class="plainGridCell">{m.fieldName}</div>
                        <div class="plainGridCell">{m.fieldStatus}</div>
                      </div>
                    )
                )}
              </div>
            </div>
            <br />
            <p style={{ fontSize: "0.9rem" }}>
              Cedar Fork District Park sign will display these fields{" "}
              {props.openclose === "Closed" ? "closed" : "open"}.<br />
              <br />
              Please type "YES" below to confirm{" "}
              {props.openclose === "Closed" ? "closing" : "opening"} the
              selected fields
            </p>
            <br />
            <TextField
              placeholder="Type YES for confirmation"
              variant="outlined"
              size="small"
              color="primary"
              value={yes}
              onChange={(e) => setYes(e.target.value)}
            />
          </DialogContentText>
          {isValid ? "" : <div>Please Type YES for confirmation</div>}
        </DialogContent>
        <DialogActions>
          <ButtonSm
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            {props.openclose === "Closed" ? "Close Fields" : "Open Fields"}
          </ButtonSm>
          <ButtonSm
            size="small"
            color="primary"
            variant="outlined"
            onClick={props.closePopup}
          >
            Cancel
          </ButtonSm>
        </DialogActions>
      </ModalDialog>
    </div>
  );
}
