import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { Link } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "./FieldModel";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import IconButton from "@material-ui/core/IconButton";
import { Clear, OpenInNewRounded } from "@material-ui/icons";
import { login_ } from "../../reducers/login";
import { makeStyles } from "@material-ui/core/styles";
import {
  permissions_,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import {
  sensors_,
  sensorsHasLoaded_,
  sensorServiceThunk,
} from "../../reducers/sensors";
import _ from "lodash";
import "../../assets/scss/App.scss";
import { updateCurrentFieldStatus } from "../../reducers/userDashboard";
import ToggleModal from "../../components/Modals/ToggleModal";
import { checkPermissions } from "../../utils/checkPermissions.js";

const ButtonLink = withStyles((theme) => ({
  root: {
    display: "inline !important",
    color: "blue !important",
    marginRight: "10px !important",
    padding: "0 !important",
    textDecoration: "underline",
    border: "none !important",
    fontSize: "13px !important",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
  },
}))(Link);

const useStyles = makeStyles(styles);

export default function GateStatus() {
  const dispatch = useDispatch();
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const sensors = useSelector(sensors_);
  const sensorHasLoaded = useSelector(sensorsHasLoaded_);
  const [sensorstatus, setsensorstatus] = React.useState({});
  const [status, setstatus] = React.useState([]);
  const [open, setopen] = React.useState(false);
  const [permission, setpermission] = React.useState({});
  const [Openorclose, setOpenorclose] = React.useState("");
  const [fieldNames, setfieldNames] = React.useState([]);
  const [Iselectall, setIselectall] = React.useState(false);
  const [btnopenactive, setopenbtnactive] = React.useState(false);
  const [btncloseactive, setclosebtnactive] = React.useState(false);
  const [allChecked, setallChecked] = React.useState(false);
  const [statusarray, setstatusarray] = React.useState([]);
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);
  const classes = useStyles();

  useEffect(() => {
    if (fieldNames.length > 0) {
      const isOpen = fieldNames.every((item) => item.fieldStatus === "Open");
      const isClosed = fieldNames.every(
        (item) => item.fieldStatus === "Closed"
      );
      if (isOpen) {
        dispatch(updateCurrentFieldStatus("All Fields Open"));
      } else if (isClosed) {
        dispatch(updateCurrentFieldStatus("All Fields Closed"));
      } else {
        const l = fieldNames.filter((item) => item.fieldStatus === "Open");
        // console.log("l", fieldNames, isOpen, isClosed, l);
        dispatch(
          updateCurrentFieldStatus(
            `${l.length}/${fieldNames.length} Fields Open`
          )
        );
      }
    } else {
      dispatch(updateCurrentFieldStatus(null));
    }
  }, [fieldNames]);

  const getFields = () => {
    var url = "api/park/" + parkid + "/fields";
    smartParkService
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          var data = res.data.data;
          // console.log(data)
          var dataconst = data.map((d) => {
            return {
              fieldID: d.id,
              isChecked: false,
              fieldName: d.FieldName,
              fieldStatus: d.FieldStatus,
            };
          });
          var fieldstatusdta = data.map((e) => {
            return {
              fieldStatus: e.FieldStatus,
            };
          });

          let statarr = [];
          fieldstatusdta.forEach((element) => {
            statarr.push(element.fieldStatus);
          });
          // console.log(statarr);
          setstatusarray(statarr);
          setfieldNames(dataconst);
        }
      })
      .catch((error) => { });
  };
  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "FieldDisplayStatus";
    });
    setpermission(Dpermissions[0]);
  };
  React.useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);

  React.useEffect(() => {
    getFields();
  }, []);

  const handleClose = () => {
    setopen(false);
    setallChecked(false);
    setopenbtnactive(false);
    setclosebtnactive(false);
    getFields();
    // const parkid = _.get(loginDetails, ["parkID"], "");
    // parkid && dispatch(sensorServiceThunk(parkid));
  };

  // setTimeout(() => {
  //   dispatch(sensorServiceThunk(parkid));
  // }, 10 * 60 * 1000);

  const handleFieldopen = () => {
    let toggleAllowed = false;
    fieldNames.forEach((device) => {
      if (device.isChecked === true && btncloseactive) {
        toggleAllowed = true;
        setFieldData((prevState) => [
          ...prevState,
          {
            deviceType: "Field",
            park_id: parkid,
            primaryStatus: device.fieldStatus,
            updateStatus: "Open",
            field_name: device.fieldName,
            field_status: device.fieldStatus,
            field_id: device.fieldID,
            url_suffix: "/updatefieldstatus",
          },
        ]);
      }
    });
    if (toggleAllowed && btncloseactive) {
      setModalStatus(true);
    }
    // if (permission.update == 1) {
    //   var isListChecked = false;
    //   if (
    //     fieldNames.filter(function (obj) {
    //       if (obj.isChecked) isListChecked = true;
    //     })
    //   );
    //   if (isListChecked) {
    //     setOpenorclose("Open");
    //     setopen(true);
    //   }
    // } else {
    //   alert("You do not have permission to update Sensor Status");
    // }
  };
  const handleFieldClose = () => {
    let toggleAllowed = false;
    fieldNames.forEach((device) => {
      if (device.isChecked === true && btnopenactive) {
        toggleAllowed = true;
        setFieldData((prevState) => [
          ...prevState,
          {
            deviceType: "Field",
            park_id: parkid,
            primaryStatus: device.fieldStatus,
            updateStatus: "Closed",
            field_name: device.fieldName,
            field_status: device.fieldStatus,
            field_id: device.fieldID,
            url_suffix: "/updatefieldstatus",
          },
        ]);
      }
    });
    if (toggleAllowed && btnopenactive) {
      setModalStatus(true);
    }
    // if (permission.update == 1) {
    //   var isListChecked = false;
    //   if (
    //     fieldNames.filter(function (obj) {
    //       if (obj.isChecked) isListChecked = true;
    //     })
    //   );
    //   if (isListChecked) {
    //     setOpenorclose("Closed");
    //     setopen(true);
    //   }
    // } else {
    //   alert("You do not have permission to update Sensor Status");
    // }
  };
  const handleChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    let fieldNames1 = fieldNames;
    let allChecked1 = allChecked;
    if (itemName === "checkAll") {
      allChecked1 = checked;
      fieldNames1 = fieldNames.map((item) => ({
        ...item,
        isChecked: checked,
      }));
    } else {
      fieldNames1 = fieldNames.map((item) =>
        item.fieldID === itemName ? { ...item, isChecked: checked } : item
      );
      allChecked1 = fieldNames1.every((item) => item.isChecked);
    }

    setopenbtnactive(false);
    setclosebtnactive(false);
    fieldNames1.map((item) => {
      if (item.fieldStatus === "Open") {
        if (item.isChecked == true) {
          setopenbtnactive(true);
          // setclosebtnactive(true);
        }
      } else if (item.fieldStatus === "Closed") {
        if (item.isChecked == true) {
          // setopenbtnactive(true);
          setclosebtnactive(true);
        }
      }
    });

    setfieldNames(fieldNames1);
    setallChecked(allChecked1);
  };
  const updateStateList = (e, value) => {
    if (e.target.checked) {
      //append to array
      // this.setState({
      //   status: status.concat([value]),
      // });
      setstatus(status.concat([value]));
    } else {
      //remove from array

      setstatus(
        status.filter(function (val) {
          return val !== value;
        })
      );
    }
  };
  React.useEffect(() => {
    setopenbtnactive(false);
    setclosebtnactive(false);
  }, []);
  const [modalStatus, setModalStatus] = useState(false);
  const userid = _.get(loginDetails, ["UserID"], "");
  const [fieldData, setFieldData] = useState([]);
  const firstname = _.get(loginDetails, ["FirstName"], "");
  const lastname = _.get(loginDetails, ["LastName"], "");
  const toggleHandler = async (e) => {
    const overrideActions = Object.values(toggleCheckBoxData).filter(
      (checkbox) => {
        if (checkbox.value) {
          return checkbox;
        }
      }
    );
    let url = `api/park/${e[0].park_id}${e[0].url_suffix}`;
    const data = {
      overrideActions: overrideActions[0].name,
      LastEditedBy: `${firstname} ${lastname}`,
      disableNotification: true,
      fieldID: [],
      fieldName: [],
      fieldStatus: e[0].updateStatus,
    };
    e.forEach((gateData) => {
      data.fieldName.push(gateData.field_name);
      data.fieldID.push(gateData.field_id);
    });
    // console.log(data)
    await smartParkService
      .post(url, data, {
        headers: {
          userid: userid,
        },
      })
      .then((res) => {
        if (Number(res.status) === 200) {
          getFields();
          handleChange({ target: { checked: false, name: "checkAll" } });
        }
      });
  };
  const handleCheckbox = (e) => {
    Object.keys(toggleCheckBoxData).map((checkbox) => {
      if (e.target.checked) {
        setToggleCheckBoxData((prevdata) => ({
          ...prevdata,
          [checkbox]: {
            ...prevdata[checkbox],
            value: checkbox === e.target.name ? e.target.checked : false,
          },
        }));
      } else {
        setToggleCheckBoxData((prevState) => ({
          ...prevState,
          [e.target.name]: {
            ...prevState[e.target.name],
            value: e.target.checked,
          },
        }));
      }
    });
  };
  const closeModal = () => {
    setFieldData([]);
    setModalStatus(false);
  };
  const [toggleCheckBoxData, setToggleCheckBoxData] = useState({
    Block: { name: "Block", label: "Block rule actions", value: false },
    Unblock: { name: "Unblock", label: "Unblock rule actions", value: false },
  });
  const checkBoxDescription = [
    "The automatic rules have actions that can open or close the devices.",
    "Do you want to block the actions of those rules?",
  ];
  const helpText = [
    `If you chose to block, then gates, fields, park will remain ${fieldData[0]?.updateStatus
      ? fieldData[0].updateStatus
      : "in [open/closed] state"
    }, until the next manual change.`,
    "If you chose to unblock the rule actions, the gates, fields, park will change state based on the rules. This manual action will be overridden.",
  ];

  return (
    <div>
      {/*<Modal*/}
      {/*  show={open}*/}
      {/*  status={fieldNames}*/}
      {/*  closePopup={handleClose}*/}
      {/*  openclose={Openorclose}*/}
      {/*/>*/}
      <ToggleModal
        submitButtonHandler={(e) => {
          toggleHandler(e);
        }}
        handleCheckbox={handleCheckbox}
        disableData={fieldData}
        closeModalHandler={closeModal}
        checkBoxData={toggleCheckBoxData}
        checkBoxDescription={checkBoxDescription}
        modalState={modalStatus}
        checkBoxHelpText={helpText}
      />
      <div class="box">
        <div class="box-header   text-center">
          <h6 class="  box-title ">Current Field Status</h6>
          {checkPermissions({
            permission: "Fields",
            permissionType: "update",
          }) && (
              <div class="text-center" style={{ marginBottom: "-10px" }}>
                <ButtonLink
                  onClick={handleFieldopen}
                  className={`${classes.openclosedisabled} ${btncloseactive === true ? classes.blueButton : ""
                    } `}
                >
                  Open Fields
                </ButtonLink>
                <ButtonLink
                  onClick={handleFieldClose}
                  className={`${classes.openclosedisabled} ${btnopenactive === true ? classes.redButton : ""
                    } `}
                >
                  Close Fields
                </ButtonLink>
              </div>
            )}
        </div>
        <div class="box-body">
          <div class="divGrid">
            <div class="divGridHeading">
              <div class="divGridRow">
                <div class="divGridHead">
                  {checkPermissions({
                    permission: "Fields",
                    permissionType: "updaate",
                  }) && (
                      <Checkbox
                        color="primary"
                        style={{ padding: "2px" }}
                        checked={allChecked}
                        name="checkAll"
                        onChange={(e) => handleChange(e)}
                      />
                    )}
                  Field
                </div>
                <div class="divGridHead">Status</div>
              </div>
            </div>
            <div class="divGridBody">
              {fieldNames.map((m) => (
                <div class="divGridRow">
                  <div class="divGridCell">
                    {checkPermissions({
                      permission: "Fields",
                      permissionType: "update",
                    }) && (
                        <Checkbox
                          color="primary"
                          name={m.fieldID}
                          checked={m.isChecked}
                          style={{ padding: "2px" }}
                          key={m.fieldName}
                          onChange={(e) => handleChange(e)}
                        />
                      )}
                    {m.fieldName}
                  </div>
                  <div class="divGridCell">
                    <div class="d-flex">
                      <FiberManualRecordIcon
                        fontSize="small"
                        style={{
                          color:
                            m.fieldStatus === "Open" ? green[500] : red[500],
                        }}
                      />{" "}
                      {m.fieldStatus === "Open" ? "Open" : "Closed"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
