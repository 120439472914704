import React, { useState, useEffect } from "react";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import moment from "moment";
import { ContinuousColorLegend } from "react-vis";

function PopularTimesChart(props) {
    const getConfig = () => ({
        plotOptions: {
            column: {
                colorByPoint: true
            }
        },
        // colors: [
        //     '#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', 
        //     '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'
        // ],
        colors: ['#0076bb'],
        navigator: {
            enabled: false
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false,
            buttonTheme: {
                visibility: 'hidden'
            },
            labelStyle: {
                visibility: 'hidden'
            }
        },
        height: "350px",
        credits: {
            enabled: false,
        },
        title: {
            text: null
        },
        chart: {
            height: (9 / 22.5 * 100) + '%',
            type: 'column'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                borderRadius: 10
            }
        },
        //  plotOptions: {
        //     column: {
        //         zones: [{
        //             value: 0, // Values up to 10 (not including) ...
        //              color: 'green' // ... have the color blue.
        //         }, {
        //              value:10,
        //             color: 'red' // Values from 10 (including) and up have the color red
        //          }]
        //      }
        //  },
        xAxis: {
            categories: ["4 am", "5 am", "6 am"
            ,"7 am","8 am", "9 am", "10 am", "11 am", "12", "1 pm", "2 pm", "3 pm"
            ,"4 pm", "5 pm", "6 pm", "7 pm","8 pm", "9 pm", "10 pm"
        ],
             //maxPadding: 0.0,
        },
        yAxis: {
            visible: false,
            min: 0,
            title: {
                text: ''
            }
        },
        tooltip: {
            split: true,
            formatter: function () {
                var crowd = this.y;
                if (crowd >= 10)
                    return "Occupied "+crowd+" times.</br> Usually Busy On "+this.x;
                else
                     return "Occupied "+crowd+" times.</br> Usually Not So Busy On "+this.x;
            },
        },
    
        series: [{
            showInLegend: false,
            colorByPoint: true,
            data: props.graphData}]
    });
    return (
        <div className="App">
            {
                <HighchartsReact
                highcharts={HighStock}
                // constructorType={"stockChart"}
                options={getConfig()}
                />
            }
        </div>
    );
}

export default PopularTimesChart;
