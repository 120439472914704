import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Form from "react-bootstrap/Form";
import smartParkService from "../../axios";
import { BiSolidCheckCircle } from "react-icons/bi";
import { BiSolidXCircle } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import { HiOutlinePencil } from "react-icons/hi";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export default function AddHoleStatus(props) {
    const [show, setShow] = useState(false);
    const classes = useStyles();
    const handleShow = () => setShow(true);
    const [getSortHoledata, setSortHoledata] = useState([]);
    const [getMaxerror, setMaxerror] = useState(false);
    const [gethandleHoleId, sethandleHoleId] = useState("");
    const [getHolemaxvalue, setHolemaxvalue] = useState("");
    const [getHoleminvalue, setHoleminvalue] = useState("");
    const [getHoleStatusname, setHoleStatusname] = useState("");
    const [getSingleHoleStatusId, setSingleHoleStatusId] = useState("");
    const [getHoleError, setHoleError] = useState(false);
    const [getHoleminError, setHoleminError] = useState(false);
    const [getHolemaxError, setHolemaxError] = useState(false);
    const [getHolestatusError, setHolestatusError] = useState(false);
    const AllMoistureStatus = props?.AllStatuslist;

    useEffect(() => {
        let sortdata;
        sortdata = props?.holedetails?.hole_data.sort(
            (a, b) => a.DisplayOrder - b.DisplayOrder
        );
        setSortHoledata(sortdata);
    }, [props.holedetails]);

    const handleHoleId = (elem) => {
        setHolemaxvalue("");
        setHoleminvalue("");
        setHoleStatusname("");
        if (elem.target.value !== "") {
            sethandleHoleId(elem.target.value);
            setHoleError(false);
        } else {
            setHoleError(true);
        }
    };
    function handleHolestatusname(e) {
        const inputweb = e.target.value;
        if (inputweb !== "" && inputweb !== undefined) {
            setHoleStatusname(inputweb);
            setHolestatusError(false);
        } else {
            setHoleStatusname("");
            setHolestatusError(true);
        }
    }
    function handleMinvalue(e) {
        const inputweb = e.target.value;
        if (inputweb !== "" && inputweb !== undefined) {
            setHoleminvalue(inputweb);
            setHoleminError(false);
        } else {
            setHoleminvalue("");
            setHoleminError(true);
        }
    }
    function handleMaxvalue(e) {
        const inputValue = e.target.value;
        if (inputValue !== "" && inputValue !== undefined) {
            setHolemaxvalue(inputValue);
            setHolemaxError(false);
        } else {
            setHolemaxvalue("");
            setHolemaxError(true);
        }
    }

    const AddGolfMoisture = () => {
        let duplicateval;
        setHoleError(false);
        setHoleminError(false);
        setHolemaxError(false);
        setHolestatusError(false);
        if (getHoleStatusname === "") {
            setHolestatusError(true);
        }
        if (getHoleminvalue === "") {
            setHoleminError(true);
        }
        if (getHolemaxvalue === "") {
            setHolemaxError(true);
        }
        if (gethandleHoleId === "") {
            setHoleError(true);
        }
        if (
            getHoleStatusname !== "" &&
            getHoleminvalue !== "" &&
            getHolemaxvalue !== "" &&
            gethandleHoleId !== ""
        ) {
            if (
                AllMoistureStatus &&
                AllMoistureStatus !== "" &&
                AllMoistureStatus !== undefined
            ) {
                duplicateval = AllMoistureStatus.some(
                    (item) =>
                        item.HoleID === gethandleHoleId &&
                        parseFloat(getHolemaxvalue) >= item.minVal &&
                        parseFloat(getHoleminvalue) <= item.maxVal
                );
            }
            if (duplicateval) {
                const mes = (
                    <div className="toastsuccess">
                        <div className="erroricon">
                            <BiSolidXCircle />
                        </div>
                        <div className="toastmessage">
                            <b>Error</b>
                            <div>Duplicate Moisture Value (Please Check the Status List)</div>
                        </div>
                    </div>
                );
                toast.error(mes, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: "errorcontainer",
                });
            } else {
                try {
                    var url = "api/events/golf/holes/status/add";
                    let currenttime = new Date().getTime();
                    var requestBody = [
                        {
                            StatusName: getHoleStatusname, //Use inside hole table
                            HoleID: gethandleHoleId,
                            minVal: parseFloat(getHoleminvalue),
                            maxVal: parseFloat(getHolemaxvalue),
                            InsertedDate: currenttime,
                        },
                    ];
                    smartParkService
                        .post(url, requestBody)
                        .then((res) => {
                            if (res.status === 200) {
                                try {
                                    const mes = (
                                        <div className="toastsuccess">
                                            <div className="toasticon">
                                                <BiSolidCheckCircle />
                                            </div>
                                            <div className="toastmessage">
                                                <b>Success</b>
                                                <div>{res.data.message}</div>
                                            </div>
                                        </div>
                                    );
                                    toast.success(mes, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                        className: "toastcontainer",
                                    });
                                } catch {
                                    console.log("Some error in golf status api ");
                                }
                                props.propsrefresh();
                                funclearfilter();
                            }
                        })
                        .catch((error) => { });
                } catch {
                    console.log("save status api error");
                }
            }
        }
    };
    const AddGolfMoistureupdate = () => {
        let duplicateval;
        setHoleError(false);
        setHoleminError(false);
        setHolemaxError(false);
        setHolestatusError(false);
        if (getHoleStatusname === "") {
            setHolestatusError(true);
        }
        if (getHoleminvalue === "") {
            setHoleminError(true);
        }
        if (getHolemaxvalue === "") {
            setHolemaxError(true);
        }
        if (gethandleHoleId === "") {
            setHoleError(true);
        }
        if (
            getHoleStatusname !== "" &&
            getHoleminvalue !== "" &&
            getHolemaxvalue !== "" &&
            gethandleHoleId !== ""
        ) {
            if (
                AllMoistureStatus &&
                AllMoistureStatus !== "" &&
                AllMoistureStatus !== undefined
            ) {
                duplicateval = AllMoistureStatus.some((item) => {
                    if (
                        item.StatusID !== props?.holestatusid &&
                        item.HoleID === gethandleHoleId
                    ) {
                        if (
                            parseFloat(getHolemaxvalue) >= item.minVal &&
                            parseFloat(getHoleminvalue) <= item.maxVal
                        ) {
                            return true;
                        }
                        if (
                            item.StatusName.toLowerCase() === getHoleStatusname.toLowerCase()
                        ) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            if (duplicateval) {
                const mes = (
                    <div className="toastsuccess">
                        <div className="erroricon">
                            <BiSolidXCircle />
                        </div>
                        <div className="toastmessage">
                            <b>Error</b>
                            <div>Duplicate Moisture Value (Please Check the Status List)</div>
                        </div>
                    </div>
                );
                toast.error(mes, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: "errorcontainer",
                });
            } else {
                try {
                    var url = "api/events/golf/holes/status/update";
                    let currenttime = new Date().getTime();
                    var requestBody = {
                        StatusID: getSingleHoleStatusId, //Use inside hole table
                        StatusName: getHoleStatusname,
                        minVal: parseFloat(getHoleminvalue),
                        maxVal: parseFloat(getHolemaxvalue),
                        modifiedDate: currenttime,
                    };
                    smartParkService
                        .post(url, requestBody)
                        .then((res) => {
                            if (res.status === 200) {
                                try {
                                    console.log(res);
                                    const mes = (
                                        <div className="toastsuccess">
                                            <div className="toasticon">
                                                <BiSolidCheckCircle />
                                            </div>
                                            <div className="toastmessage">
                                                <b>Success</b>
                                                <div>{res.data.message}</div>
                                            </div>
                                        </div>
                                    );
                                    toast.success(mes, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                        className: "toastcontainer",
                                    });
                                } catch {
                                    console.log("Some error in golf status api ");
                                }
                                props.propsrefresh();
                                funclearfilter();
                            }
                        })
                        .catch((error) => { });
                } catch {
                    console.log("save status api error");
                }
            }
        }
    };

    const funclearfilter = () => {
        setHolemaxvalue("");
        setHoleminvalue("");
        sethandleHoleId("");
        setHoleStatusname("");
        setHoleError(false);
        setHoleminError(false);
        setHolemaxError(false);
        setHolestatusError(false);
        setShow(false);
    };

    function funcupdate(id, name, max, min, holeId) {
        setHolemaxvalue(max);
        setHoleminvalue(min);
        sethandleHoleId(holeId);
        setHoleStatusname(name);
        setSingleHoleStatusId(id);
        setShow(true);
    }
    // useEffect(() => {
    //   AddGolfMoisture();
    // }, [getHoleStatusname, getHoleminvalue, getHolemaxvalue, gethandleHoleId]);

    return (
        <>
            {props.addhole == "Add" ? (
                <Button
                    variant="primary"
                    className="btn golfnotificationpark"
                    onClick={handleShow}
                >
                    Add Moisture Status
                </Button>
            ) : (
                <></>
            )}
            {props.addhole == "update" ? (
                <button
                    className="btn golfupdatebtn updatebtncolor"
                    onClick={() =>
                        funcupdate(
                            props.holestatusid,
                            props.holestatusname,
                            props.holestatusmax,
                            props.holestatusmin,
                            props.holeid
                        )
                    }
                >
                    <HiOutlinePencil />
                </button>
            ) : (
                <></>
            )}

            <Modal show={show} onHide={funclearfilter} centered size="xl">
                <Modal.Body>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <div className="container-fluid mobilegolfpadd">
                                <div className="row datepickerwidth">
                                    <div className="col-12">
                                        <div className="golfMositiureheading">
                                            <h3 className="text-center">
                                                {props.addhole == "Add"
                                                    ? "Add Moisture Status"
                                                    : "Update Moisture Status"}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="notificationlabel">Hole Name:</div>
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => handleHoleId(e)}
                                            value={gethandleHoleId}
                                            style={{ width: "100%", marginRight: "5px" }}
                                        >
                                            <option value="">Please Select Hole</option>
                                            {getSortHoledata?.map((data, idx) => (
                                                <option key={idx} value={data.HoleID}>
                                                    {data.HoleName}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        {getHoleError && (
                                            <p style={{ color: "red" }}>* Please Select Hole</p>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="notificationlabel">Hole Status Name</div>
                                        <input
                                            type="text"
                                            value={getHoleStatusname}
                                            placeholder="Enter Hole Status name (wet/very wet/dry/open)"
                                            onChange={(e) => handleHolestatusname(e)}
                                            className="form-control"
                                        />
                                        {getHolestatusError && (
                                            <p style={{ color: "red" }}>
                                                * Please Enter Status (wet/very-wet/dry/open)
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="notificationlabel">
                                            Minimum Moisture Value :
                                        </div>
                                        <input
                                            type="number"
                                            value={getHoleminvalue}
                                            placeholder="min-value"
                                            onChange={(e) => handleMinvalue(e)}
                                            className="form-control"
                                        />
                                        {getHoleminError && (
                                            <p style={{ color: "red" }}>
                                                * Please Enter Minimum value
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="notificationlabel">
                                            Maximum Moisture Value :
                                        </div>
                                        <input
                                            type="number"
                                            value={getHolemaxvalue}
                                            placeholder="max-value"
                                            onChange={(e) => handleMaxvalue(e)}
                                            className="form-control"
                                        />
                                        {getHolemaxError && (
                                            <p style={{ color: "red" }}>
                                                * Please Enter Maximum value
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer className="border-0 filterfooter pt-0 pb-3 px-4 mobilegolffooter">
                    {props.addhole == "Add" ? (
                        <Button
                            variant="primary"
                            onClick={AddGolfMoisture}
                            className="filterapplybtn btn mr-3"
                        >
                            Add
                        </Button>
                    ) : (
                        <></>
                    )}
                    {props.addhole == "update" ? (
                        <Button
                            variant="primary"
                            onClick={AddGolfMoistureupdate}
                            className="filterapplybtn btn mr-3"
                        >
                            Update
                        </Button>
                    ) : (
                        <></>
                    )}

                    <Button
                        variant="secondary"
                        onClick={funclearfilter}
                        className="filtercancelbtn notificlear btn"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
