export const UnixTimeStamp = (date) => {
  const estOffset = 240;
  const localOffset = new Date(date).getTimezoneOffset();
  const localUnixTimestamp = new Date(date).getTime();
  let adjustment;
  if (localOffset < 0) {
    adjustment = (estOffset + Math.abs(localOffset)) * 60;
    // console.log("negative", estOffset, Math.abs(localOffset), 60);
  } else if (localOffset > 0) {
    adjustment = (estOffset - localOffset) * 60;
    // console.log(
    //   "positive",
    //   estOffset,
    //   localOffset,
    //   (estOffset - localOffset) * 60
    // );
  } else {
    console.log("zero offset");
    adjustment = estOffset * 60;
  }
  const estUnixTimestamp = localUnixTimestamp + adjustment * 1000;
  //   console.log(estUnixTimestamp);
  return estUnixTimestamp;
};
