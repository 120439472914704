import React, { useEffect, useState, useCallback, useMemo } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, styled } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import {
  Button,
  Card,
  CardHeader,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Box,
  CircularProgress,
  Grid,
  IconButton,
} from "@material-ui/core";
import { DirectionsOutlined, MoreVert } from "@material-ui/icons";
import { BsFillBellFill } from "react-icons/bs";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../../views/Common";
import {
  setSelectedField,
  fieldDetailsByType_,
  setFieldCourts,
} from "../../reducers/userDashboard";

import "./courtstatuscard.css";
import { object } from "prop-types";
const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    height: "100%",
  },
  notifycsp: {
    fontSize: 13,
  },
  bellicon: {
    fontSize: "1.5rem",
  },
  notificationicon: {
    background: "white",
    display: "flex",
    position: "absolute",
    top: 5,
    zIndex: 99,
    right: 5,
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    marginTop: "-70px",
    position: "relative",
    padding: theme.spacing(0, 2),
    height: 60,
  },
  cardContent: {
    paddingTop: 32,
  },
  ht364: {
    height: 373,
    overflowY: "auto",
    paddingBottom: "10px !important",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "50px !important",
    },
  },
  actionbtn: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "6px",
    right: "0",
  },
  h5: {
    color: "#fff",
    fontWeight: 600,
    width: "50%",
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    textShadow: "-2px 2px 2px #000",
  },
  imgCard: {
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 16,
    },
    "& .MuiCardContent-root": {
      [theme.breakpoints.up("lg")]: {
        height: 487,
      },
    },
  },
  media: {
    height: 200,
    cursor: "default",
    cursor: "pointer",
  },
  customButton: {
    textTransform: "capitalize",
    fontSize: "0.95em",
    fontWeight: 500,
    boxShadow: "none !important",
  },
  titleBtn: {
    background: "#53de21 !important",
    color: "#fff",
    fontWeight: "bold",
    textTransform: "capitalize",
    boxShadow: "none !important",
    borderRadius: 20,
    fontSize: 16,
    width: 100,
  },
  closetext: {
    position: "absolute",
    top: "50%",
    zIndex: "99",
    color: "white",
    left: "50%",
    transform: "translate(-50%,-50%)",
    fontSize: "2rem",
  },
}));
const occupiedStyle = {
  borderRadius: 4,
  backgroundColor: "#fec6c6",
  color: "red",
  fontSize: "12px",
  fontWeight: "bold",
};
const unOccupiedStyle = {
  borderRadius: 4,
  backgroundColor: "#b8f0d2",
  color: "#0b9444",
  fontSize: "12px",
  fontWeight: "bold",
};
const listItemContainerStyle = {
  height: "100%",
};
const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;
export default function CourtStatusCard(props) {
  const {
    actions,
    allNotify,
    cardType,
    cardStyle = "simple",
    getFields,
    refresh,
  } = props;
  // const [selectedCard, setSelectedCard] = useState(null);
  const [fields, setFields] = useState([]);
  const classes = useStyles();
  const [mcparr, setmcparr] = useState([]);
  const [color, setColor] = React.useState("#ffffff");
  const [newFields, setNewFields] = useState([]);
  // const [getresval, setresval] = useState([]);
  const [status, setStatus] = useState("");
  const selectedCard = useSelector((state) =>
    fieldDetailsByType_(state, cardType)
  );
  const dispatch = useDispatch();
  const [closeoverlay, setcloseoverlay] = React.useState(true);
  const [getNotification, setNotification] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [getNotificationRefresh, setNotificationRefresh] =
    React.useState(false);
  const [getNotificationMessage, setNotificationMessage] = React.useState("");
  const [getnotifyWeblink, setnotifyWeblink] = React.useState("");
  const [getnotifyWebname, setnotifyWebname] = React.useState("");
  const parkrealid =
    cardType === "cfdp"
      ? "c7a1d85f-8168-4e64-8e38-116a0624bfea"
      : cardType === "churchstreettenniscourts"
        ? "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944"
        : cardType === "shiloh"
          ? "c7a1d85f-8168-4e64-8e38-116a0624bfec"
          : cardType === "ccnp"
            ? "c7a1d85f-8168-4e64-8e38-116a0624bfed"
            : cardType === "mafctenniscourts"
              ? "91474e36-76c5-4d59-a8da-2998ab14a4bf"
              : "c7a1d85f-8168-4e64-8e38-116a0624bfeb";

  useEffect(() => {
    openfn();
  }, []);

  useEffect(() => {
    notificationFunction();
  }, [parkrealid, getNotificationRefresh]);

  function simpledate(date) {
    try {
      const parsedDate = new Date(date);
      const simpleDate = parsedDate.toISOString().split("T")[0];
      return simpleDate;
    } catch {
      return date;
    }
  }

  const notificationFunction = () => {
    if (parkrealid !== "" && parkrealid !== undefined && parkrealid !== null) {
      let url = `api/notification/detail/${parkrealid}`;
      smartParkService
        .get(url)
        .then((res) => {
          if (res.status == 200) {
            setNotification(false);
            if (res.data.status == 200) {
              let allParkdetails = res.data.data;
              if (allParkdetails !== null) {
                let nstartDate = simpledate(allParkdetails.startDate);
                let nendDate = simpledate(allParkdetails.endDate);
                let newdate = simpledate(new Date());
                if (allParkdetails.parkId == parkrealid) {
                  if (newdate >= nstartDate && newdate <= nendDate) {
                    // console.log("sdlfjdsfsl=======");
                    // console.log(allParkdetails);
                    if (allParkdetails.parkId == parkrealid) {
                      setNotificationMessage(allParkdetails.message);
                      setnotifyWeblink(allParkdetails.webLink);
                      setnotifyWebname(allParkdetails.webName);
                      setNotification(true);
                    }
                  } else {
                    setNotification(false);
                  }
                }
              }
            }
          }
        })

        .catch((error) => {
          console.error("Notification error: ", error);
        });
    } else {
      console.log("please Enter all details");
    }
  };

  // console.log(getAllParknotifi);

  function openfn() {
    if (!selectedCard) {
      return;
    }
    smartParkService
      .get("api/park/" + parkrealid + "/facilitiesmanagement")
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          if (res.data.message === false) {
            setcloseoverlay(false);
          } else {
            setcloseoverlay(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getNewFields = async (parkid) => {
    let url =
      parkid === "91474e36-76c5-4d59-a8da-2998ab14a4bf"
        ? `api/courts/list/${parkid}`
        : "api/citizen/fielddata6";
    return await smartParkService.get(url).then((res) => {
      if (res.status === 200 && res.data.data.length > 0) {
        let fields = res.data.data.map((field) => {
          return {
            FieldGroup: field.FieldGroup,
            className: field.courtoccupancy
              ? field.courtoccupancy === "Occupied"
                ? "closed"
                : "open"
              : field.FieldStatus
                ? field.FieldStatus === "Closed"
                  ? "closed"
                  : "open"
                : "closed",
            court_api_id: field.courtid || field.FieldID,
            court_name: field.courtname || field.FieldName,
            fieldId: field.courtid || field.FieldID,
            label: field.courtname || field.FieldName,
            status: field.courtoccupancy || field.FieldStatus,
            parkID: field.parkid || field.parkID,
          };
        });
        return fields;
      }
    });
  };
  async function funcdata(carddata) {
    const { url, cardColumns, id } = carddata;
    // console.log("url", url);
    var restoken = GenerateEncryptedToken();
    const res = await smartParkService.get(url, {
      headers: {
        "x-access-token": restoken,
      },
    });
    if (
      carddata.key === "mafctenniscourts" ||
      carddata.key === "churchstreettenniscourts"
    ) {
      setNewFields(await getNewFields(carddata.parkid));
    }

    if (res.status >= 200 && res.status < 300) {
      // console.log(res);
      var fields = res.data.data;
      const statusOFFields =
        fields &&
        fields.length > 0 &&
        fields.filter((obj) => {
          let status = obj[cardColumns[1]];
          return status === "Open" || status === 0;
        });
      let typeOfField = fields[0][cardColumns[1]];
      if (typeof typeOfField === "string")
        statusOFFields.length > 0 ? setStatus("Open") : setStatus("Closed");
      else {
        statusOFFields.length > 0
          ? setStatus("Unoccupied")
          : setStatus("Occupied");
      }

      fields.map((field, index) => {
        if (!field._orginalName) {
          field._orginalName = `${cardColumns[0] === "court_name"
              ? "Court " + (index + 1)
              : field[cardColumns[0]]
            }`;
        }
        let status = field[cardColumns[1]];
        let className = status === "Closed" ? "closed" : "open";
        if (typeof status != "string") {
          className = status ? "closed" : "open";
          status = status ? "Occupied" : "Unoccupied";
        } else {
          status = status;
        }
        field._status = status;
        field.fieldId = field[id];
        field.className = className;
        field.label = field[cardColumns[0]];
        field.value = field[id];
        return field;
      });

      if (fields && fields.length) {
        fields.forEach((field) => {
          field._orginalName === "Fields 1 & 2" && (field.displayOrder = 1);
          field._orginalName === "Field 3" && (field.displayOrder = 2);
          field._orginalName === "Field 4" && (field.displayOrder = 3);
          field._orginalName === "Field 5" && (field.displayOrder = 4);
          field._orginalName === "Fields 6-8" && (field.displayOrder = 5);
        });
      }

      fields.sort((a, b) => a.displayOrder - b.displayOrder);
      // console.log(fields);
      setFields(fields);
      getFields && getFields(fields);
    }

    // myCallback(carddata);
    if (cardType === "morrisvillecommunitypark") {
      const urldata = "/api/mcpcourts";
      const mcpres = await smartParkService.get(urldata, {
        headers: {
          "x-access-token": restoken,
        },
      });
      if (mcpres.status >= 200 && mcpres.status < 300) {
        var fielddta = mcpres.data.data;
        fielddta.map((field, index) => {
          field._orginalName = `${cardColumns[0] === "court_name"
              ? "Court " + (index + 1)
              : field[cardColumns[0]]
            }`;
          let status = field[cardColumns[1]];
          let className = status === "Closed" ? "closed" : "open";
          if (typeof status != "string") {
            className = field.value ? "closed" : "open";
            status = field.value ? "Occupied" : "Unoccupied";
          } else {
            status = status;
          }
          field._status = status;
          field.fieldId = field[id];
          field.className = className;
          field.label = field[cardColumns[0]];
          field.value = field[id];
          return field;
        });
        fielddta.sort((a, b) => a.displayOrder - b.displayOrder);
        setmcparr(fielddta);
        var fielddtaString = JSON.stringify(fielddta);
        localStorage.setItem("mcpcourtsdta", fielddtaString);
      }
    }
  }

  // async function funcgetpark(carddata) {
  //   const mcpurl = smartParkService.defaults.baseURL;
  //   const urldata = mcpurl + "/api/mcpcourts";
  //   const { cardColumns, id } = carddata;
  //   var restoken = GenerateEncryptedToken();
  //   const res = await smartParkService.get(urldata, {
  //     headers: {
  //       "x-access-token": restoken,
  //     },
  //   });
  //   if (res.status >= 200 && res.status < 300) {
  //     var fielddta = res.data.data;
  //     fielddta.map((field, index) => {
  //       field._orginalName = `${
  //         cardColumns[0] === "court_name"
  //           ? "Court " + (index + 1)
  //           : field[cardColumns[0]]
  //       }`;
  //       let status = field[cardColumns[1]];
  //       let className = status === "Closed" ? "closed" : "open";
  //       if (typeof status != "string") {
  //         className = field.value ? "closed" : "open";
  //         status = field.value ? "Occupied" : "Unoccupied";
  //       } else {
  //         status = status;
  //       }
  //       field._status = status;
  //       field.fieldId = field[id];
  //       field.className = className;
  //       field.label = field[cardColumns[0]];
  //       field.value = field[id];
  //       return field;
  //     });
  //     fielddta.sort((a, b) => a.displayOrder - b.displayOrder);
  //     setmcparr(fielddta);
  //     var fielddtaString = JSON.stringify(fielddta);
  //     localStorage.setItem("mcpcourtsdta", fielddtaString);
  //   }
  // }

  useEffect(() => {
    // console.log("hello", selectedCard);
    funcdata(selectedCard);
    // funcdata(selectedCard, funcgetpark);
    // funcgetpark(selectedCard);
    dispatch(setSelectedField(selectedCard));
  }, [selectedCard, refresh]);

  // MT-58 issue

  let cardHeights = [];

  // Get all the card elements with the same class
  let cards = document.getElementsByClassName("MuiGrid-grid-md-6");
  // Initialize a variable to store the highest height
  let maxHeight = 0;
  // Loop through each card element
  for (let i = 0; i < cards.length; i++) {
    let card = cards[i];
    // Get the height of the current card
    let height = card.clientHeight;
    // Push the height to the array
    cardHeights.push(height);
    // Update the maxHeight if the current height is greater
    if (height > maxHeight) {
      maxHeight = height;
    }
  }

  for (let i = 0; i < cards.length; i++) {
    let card = cards[i];
    let innerDiv = card.querySelector(".MuiPaper-rounded");
    innerDiv.style.minheight = `${maxHeight}px`;
  }

  // MT-58 issue
  const fieldsByGroup = fields.reduce((acc, field) => {
    const group = field.FieldGroup;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(field);
    return acc;
  }, {});

  useEffect(() => {
    if (props.dispatchEvent === true) {
      dispatch(setFieldCourts(fields));
    }
  }, [fields]);

  const openDirections = useCallback(() => {
    window.open(selectedCard.mapUrl, "_blank");
  }, [selectedCard]);

  function funcLinkAdd(webname, weblink) {
    try {
      if (webname !== "" && weblink !== "") {
        return (
          <a href={weblink} className="btn-link weblinkcss" target="_blank">
            {webname}
          </a>
        );
      }
      if (webname !== "" && weblink == "") {
        return <span> {webname} </span>;
      }
      if (webname == "" && weblink !== "") {
        return (
          <a href={weblink} className="btn-link weblinkcss" target="_blank">
            {weblink}
          </a>
        );
      }
    } catch {
      return (
        <span>
          {webname},{weblink}
        </span>
      );
    }
  }

  return (
    <>
      {selectedCard ? (
        cardStyle === "simple" ? (
          <Card elevation={8} className={`${classes.card} sameheight`}>
            <CardActionArea className={closeoverlay === false ? "parkbtn" : ""}>
              <CardMedia
                className={classes.media}
                image={selectedCard.imgURL}
                onClick={() => {
                  props.handleOpen(cardType);
                }}
              />
              {closeoverlay === false ? (
                <div className={classes.closetext}>Closed</div>
              ) : (
                ""
              )}

              {getNotification ? (
                <div className="notificationicon">
                  <div className="notify">
                    <BsFillBellFill className="text-danger notifybellicon" />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </CardActionArea>
            <div
              className={classes.cardTitle}
              onClick={() => {
                props.handleOpen(cardType);
              }}
            >
              <h5 className={classes.h5}>{selectedCard.header}</h5>
            </div>
            <CardContent className="conheight">
              {Object.entries(fieldsByGroup).map(([group, groupFields]) => (
                <div key={group}>
                  <div>
                    <h6 className="title1 samesize">{group} Status</h6>
                    <Grid
                      container
                      spacing={2}
                      className="status-list listcontainer"
                    >
                      {groupFields.map((field) => (
                        <Grid item xs={4} key={field.fieldId}>
                          <div
                            className={`list-item ${field.className}`}
                            style={listItemContainerStyle}
                          >
                            <span className="listItemSpanStyle">
                              <div className="listItemSpanDivStyle">
                                {field._orginalName}
                              </div>
                            </span>
                            <label className="listItemLabelStyle">
                              {field._status}
                            </label>
                          </div>
                        </Grid>
                      ))}
                      {cardType === "mafctenniscourts" ||
                        cardType === "churchstreettenniscourts" ? (
                        <>
                          <div className="w-100 fieldgrouppad">
                            <h6 className="title1 samesize">
                              {newFields[0].FieldGroup} status
                            </h6>

                            <Grid
                              container
                              spacing={2}
                              className="status-list listcontainer"
                            >
                              {newFields.map((field, idx) => {
                                return (
                                  <Grid item xs={4} key={idx}>
                                    <div
                                      className={`list-item openclosegrid ${field.className} mousepointer`}
                                      style={listItemContainerStyle}
                                      data-id={field.court_api_id}
                                      data-name={field.court_name}
                                      data-status={field._status}
                                    >
                                      <span className="listItemSpanStyle">
                                        <div className="listItemSpanDivStyle">
                                          {field.court_name}
                                        </div>
                                      </span>
                                      <label className="listItemLabelStyle mousepointer">
                                        <span>{field.status}</span>
                                      </label>
                                    </div>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {cardType === "morrisvillecommunitypark" ? (
                        <>
                          {Array.from(
                            new Set(mcparr.map((field) => field.FieldGroup))
                          ).map((group) => (
                            <div key={group} className="w-100 fieldgrouppad">
                              <h6 className="title1 samesize">
                                {group} status
                              </h6>
                              <Grid
                                container
                                spacing={2}
                                className="status-list listcontainer"
                              >
                                {mcparr
                                  .filter((field) => field.FieldGroup === group)
                                  .map((field, idx) => {
                                    const status = field._status;
                                    return (
                                      <Grid item xs={4} key={idx}>
                                        <div
                                          className={`list-item ${field.className}`}
                                          style={listItemContainerStyle}
                                        >
                                          <span className="listItemSpanStyle">
                                            <div className="listItemSpanDivStyle">
                                              {field.court_name}
                                            </div>
                                          </span>
                                          <label className="listItemLabelStyle">
                                            <span>{status}</span>
                                          </label>
                                        </div>
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </div>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <Grid item xs={12}>
                        {getNotification ? (
                          <div className="notebox mcpnotebook">
                            <div className="notification">
                              <p
                                className={`text-danger notifiystyle ${classes.notifycsp}`}
                              >
                                <div className="text-danger font-weight-bold">
                                  Notification :{" "}
                                </div>
                                <div className="notifymessageweblink">
                                  <div>{getNotificationMessage} </div>
                                  <div>
                                    {funcLinkAdd(
                                      getnotifyWebname,
                                      getnotifyWeblink
                                    )}
                                  </div>
                                </div>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                    {!groupFields.length ? (
                      <ClipLoader
                        color={color}
                        loading={loading}
                        css={override}
                        size={60}
                      />
                    ) : null}
                  </div>
                </div>
              ))}
            </CardContent>
            <CardActions className={classes.actionbtn}>
              {actions ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.customButton}
                    onClick={() => {
                      props.handleOpen(cardType);
                    }}
                  >
                    More Details
                  </Button>
                  <Button
                    color="primary"
                    startIcon={<DirectionsOutlined />}
                    className={classes.customButton}
                    onClick={openDirections}
                  >
                    Get Directions
                  </Button>
                </>
              ) : (
                ""
              )}
              {allNotify ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.customButton}
                >
                  All Notifications
                </Button>
              ) : (
                ""
              )}
            </CardActions>
          </Card>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={4}>
              <Card elevation={8} className="sameheightcard">
                <CardHeader
                  title={selectedCard.header}
                  className="text-center"
                ></CardHeader>
                <CardContent className={classes.ht364}>
                  {Object.entries(fieldsByGroup).map(([group, groupFields]) => (
                    <div key={group}>
                      <h6 className="title1 samesize">{group} Status</h6>
                      <ul className="status-list">
                        {groupFields.map((field) => (
                          // <li key={field.fieldId}>
                          <li key={field._id}>
                            <span>{field._orginalName}</span>
                            <label
                              className={field.className}
                              style={{ textTransform: "uppercase" }}
                            >
                              {field._status}
                            </label>
                          </li>
                        ))}
                        {cardType === "morrisvillecommunitypark" ? (
                          <>
                            {Array.from(
                              new Set(mcparr.map((field) => field.FieldGroup))
                            ).map((group) => (
                              <div key={group}>
                                <h6 className="title1 samesize">
                                  {group} status
                                </h6>
                                <ul className="padleft">
                                  {mcparr
                                    .filter(
                                      (field) => field.FieldGroup === group
                                    )
                                    .map((field, idx) => {
                                      const status = field._status;
                                      // const value = getFieldValue(field); // Add this line to get the value
                                      return (
                                        <li key={field._id}>
                                          <span>{field.court_name}</span>
                                          <label
                                            className={field.className}
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            {status}
                                          </label>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                        {cardType === "mafctenniscourts" ||
                          cardType === "churchstreettenniscourts" ? (
                          <>
                            <div key={newFields[0].FieldGroup}>
                              <h6 className="title1 samesize">
                                {newFields[0].FieldGroup} status
                              </h6>
                              <ul className="padleft">
                                {newFields.map((field) => {
                                  return (
                                    <li key={field.fieldId}>
                                      <span>{field.court_name}</span>
                                      <label
                                        className={field.className}
                                        style={{
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {field.status}
                                      </label>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {!groupFields.length ? (
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <CircularProgress />
                          </Box>
                        ) : null}
                      </ul>
                    </div>
                  ))}
                </CardContent>
                <CardActions className="directionbtnposition">
                  <Button
                    color="primary"
                    startIcon={<DirectionsOutlined />}
                    className={classes.customButton}
                    onClick={openDirections}
                  >
                    Get Directions
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Card elevation={8} className={classes.imgCard}>
                <CardContent>
                  <img
                    src={selectedCard.fullImgURL}
                    alt="map"
                    width="100%"
                    height="455px"
                    className="parkimg"
                  />
                </CardContent>
              </Card>
            </Grid>
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
}
CourtStatusCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  actions: PropTypes.bool,
  allNotify: PropTypes.bool,
  refresh: PropTypes.bool,
};
