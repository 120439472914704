import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import { fieldsService } from '../services/fieldsService'

export const initialState = {
    isLoading: false,
    hasLoaded: false,
    data: []
}

export const fieldsServiceThunk = createAsyncThunk(
    "get/fields",
    async (parkid) => {
        return await fieldsService(parkid)
    }
);

const fields = createSlice({
    name: 'fields',
    initialState,
    reducers: {
        unmountFields: () => {
            return { ...initialState }
        }
    },
    extraReducers: builder => {
        builder.addCase(fieldsServiceThunk.pending, (state, action) => {
            state.isLoading = true;
            state.hasLoaded = false;
        });
        builder.addCase(fieldsServiceThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.hasLoaded = true
            state.data = action.payload;
        });
        builder.addCase(fieldsServiceThunk.rejected, (state, action) => {
            //TODO
        });
    }
})

export const stateSelector_ = ({ fields }) => fields;

export const fields_ = createSelector(stateSelector_, (fields) => fields.data.data)

export const fieldsHasLoaded_ = createSelector(stateSelector_, (fields) => fields.hasLoaded)

export const { unmountFields } = fields.actions

export default fields.reducer
