import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Modal } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Link } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import { RiDeleteBin5Line } from "react-icons/ri";
import Checkbox from "@material-ui/core/Checkbox";
import HoleFormDialog from "./HolesClose.js";
import Spinner from "react-bootstrap/Spinner";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../../views/Common";
import styles from "../../components/components.js";
import IconButton from "@material-ui/core/IconButton";
import { Clear, OpenInNewRounded } from "@material-ui/icons";
import { login_ } from "../../reducers/login";
import { makeStyles } from "@material-ui/core/styles";
import Table from "react-bootstrap/Table";
import { BiSolidCheckCircle } from "react-icons/bi";
import AddHoleStatus from "./AddHoleStatus.js";
import { ToastContainer, toast } from "react-toastify";
import {
    permissions_,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import {
    sensors_,
    sensorsHasLoaded_,
    sensorServiceThunk,
} from "../../reducers/sensors";
import _ from "lodash";
import "../../assets/scss/App.scss";
import { updateCurrentFieldStatus } from "../../reducers/userDashboard";

const ButtonLink = withStyles((theme) => ({
    root: {
        display: "inline !important",
        color: "blue !important",
        marginRight: "10px !important",
        padding: "0 !important",
        textDecoration: "underline",
        border: "none !important",
        fontSize: "13px !important",
        [theme.breakpoints.down("xl")]: {
            fontSize: "14px !important",
        },
    },
}))(Link);

const useStyles = makeStyles(styles);

export default function GolfHolestatuswidget(props) {
    const [showdelete, setdeleteShow] = useState(false);
    const dispatch = useDispatch();
    const [yes, setYes] = React.useState("");
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const sensors = useSelector(sensors_);
    const sensorHasLoaded = useSelector(sensorsHasLoaded_);
    const [sensorstatus, setsensorstatus] = React.useState({});
    const [status, setstatus] = React.useState([]);
    const [open, setopen] = React.useState(false);
    const [permission, setpermission] = React.useState({});
    const [Openorclose, setOpenorclose] = React.useState("");
    const [fieldNames, setfieldNames] = React.useState([]);
    const [Iselectall, setIselectall] = React.useState(false);
    const [allholeChecked, setallholeChecked] = React.useState(false);
    const [btnopenactive, setopenbtnactive] = React.useState(false);
    const [btncloseactive, setclosebtnactive] = React.useState(false);
    const [refresh, setrefresh] = React.useState(false);
    const [allChecked, setallChecked] = React.useState(false);
    const [statusarray, setstatusarray] = React.useState([]);
    const [Allstatusarray, setAllstatusarray] = React.useState([]);
    const [golfstatusarray, setgolfstatusarray] = React.useState([]);
    const [gethandleHoleId, sethandleHoleId] = React.useState("");
    const [getDeletestatusID, setDeletestatusID] = React.useState("");
    const [getDeletestatusMax, setDeletestatusMax] = React.useState("");
    const [getDeletestatusMin, setDeletestatusMin] = React.useState("");
    const [getDeletestatusname, setDeletestatusname] = React.useState("");
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);
    const [isValid, setIsvalid] = React.useState(true);
    const [showNoRecordsMessage, setShowNoRecordsMessage] = useState(false);
    const classes = useStyles();
    const GolfCoursedata = props?.GolfCoursedata;

    useEffect(() => {
        setgolfstatusarray(GolfCoursedata.hole_data);
        const firstboj = GolfCoursedata.hole_data.find(
            (item) => item.DisplayOrder == 1
        );
        sethandleHoleId(firstboj.HoleID);
    }, [GolfCoursedata]);

    useEffect(() => {
        // Set a timeout to show "No Records" after 10 seconds if data is still not loaded
        const timeoutId = setTimeout(() => {
            if (!Allstatusarray || Allstatusarray.length === 0) {
                setShowNoRecordsMessage(true);
            }
        }, 10000); // 10 seconds

        return () => clearTimeout(timeoutId); // Clear the timeout if the component unmounts or when data is loaded
    }, [Allstatusarray]);

    const getGolfFields = () => {
        var url = "api/events/golf/" + gethandleHoleId + "/status";
        console.log(url);
        smartParkService
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    try {
                        var data = res?.data?.data;
                        console.log(data);
                        setAllstatusarray(data);
                    } catch {
                        console.log("Some error in golf status api ");
                    }
                }
            })
            .catch((error) => { });
    };

    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "FieldDisplayStatus";
        });
        setpermission(Dpermissions[0]);
    };
    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);

    useEffect(() => {
        if (gethandleHoleId !== "") {
            getGolfFields();
        }
    }, [gethandleHoleId, refresh]);

    const handleHoleId = (elem) => {
        sethandleHoleId("");
        if (elem.target.value !== "") {
            console.log(elem.target.value);
            sethandleHoleId(elem.target.value);
        } else {
            sethandleHoleId("");
        }
    };
    const funcrefresh = () => {
        setrefresh(!refresh);
    };

    function handleSubmit(sid) {
        //   console.log(popenclose);

        var requestBody = {
            StatusID: [sid],
        };
        console.log(requestBody);
        if (yes.trim().toLowerCase() == "yes") {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            // if (params.get('adminmode') === "1")
            var url = `api/events/golf/holes/status/delete`;
            var restoken = GenerateEncryptedToken();
            smartParkService
                .post(url, requestBody)
                .then((res) => {
                    if (res.status === 200) {
                        const respData = res.data;
                        const mes = (
                            <div className="toastsuccess">
                                <div className="toasticon">
                                    <BiSolidCheckCircle />
                                </div>
                                <div className="toastmessage">
                                    <b>Success</b>
                                    <div>Delete {res.data.message}</div>
                                </div>
                            </div>
                        );
                        toast.success(mes, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            className: "toastcontainer",
                        });
                    }
                    setrefresh(!refresh);
                    setYes("");
                    setIsvalid(true);
                    setdeleteShow(false);
                })
                .catch((err) => {
                    setYes("");
                    setdeleteShow(false);
                    console.log(err);
                });
        } else {
            setIsvalid(false);
        }
    }

    function funcDelete(statusid) {
        setDeletestatusname(statusid.StatusName);
        setDeletestatusID(statusid.StatusID);
        setDeletestatusMin(statusid.minVal);
        setDeletestatusMax(statusid.maxVal);
        setdeleteShow(true);
    }
    function funclearStatus() {
        setYes("");
        setIsvalid(true);
        setdeleteShow(false);
    }

    return (
        <div>
            <div class="box">
                <div class="box-header  text-center">
                    <h6 class="box-title mb-0">Hole Moisture Status</h6>
                </div>
                <div className="golfselectbox">
                    <div className="w-50 pr-2">
                        <Form.Control
                            as="select"
                            onChange={(e) => handleHoleId(e)}
                            value={gethandleHoleId}
                            style={{ width: "100%", marginRight: "5px" }}
                        >
                            <option value="" disabled>
                                Please Select Hole
                            </option>
                            {golfstatusarray?.map((data, idx) => (
                                <option key={idx} value={data.HoleID}>
                                    {data.HoleName}
                                </option>
                            ))}
                        </Form.Control>
                    </div>
                    <div>
                        <AddHoleStatus
                            holedetails={GolfCoursedata}
                            AllStatuslist={Allstatusarray}
                            propsrefresh={funcrefresh}
                            addhole={"Add"}
                        />
                    </div>
                </div>
                <div
                    className="box-body"
                    style={{ maxHeight: "145px", overflowY: "auto" }}
                >
                    <Table striped bordered className="mb-0" size="sm">
                        <thead>
                            <tr>
                                <th>StatusName</th>
                                <th>Min</th>
                                <th>Max</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Allstatusarray?.length > 0 ? (
                                Allstatusarray?.map((data, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{data.StatusName}</td>
                                            <td>{data.minVal}</td>
                                            <td>{data.maxVal}</td>
                                            <td>
                                                <div className="golfactionbtn">
                                                    <AddHoleStatus
                                                        holedetails={GolfCoursedata}
                                                        AllStatuslist={Allstatusarray}
                                                        propsrefresh={funcrefresh}
                                                        addhole={"update"}
                                                        holestatusid={data.StatusID}
                                                        holestatusname={data.StatusName}
                                                        holestatusmin={data.minVal}
                                                        holestatusmax={data.maxVal}
                                                        holeid={data.HoleID}
                                                    />
                                                    <button
                                                        className="btn golfupdatebtn deletebtncolor"
                                                        onClick={() => funcDelete(data)}
                                                    >
                                                        <RiDeleteBin5Line />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : showNoRecordsMessage ? (
                                <tr>
                                    <td colSpan={4}>
                                        <div className="text-center">No Records</div>
                                    </td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan={4}>
                                        <div className="text-center">
                                            <Spinner animation="border" />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>

            <Modal show={showdelete} onHide={funclearStatus} centered size="xl">
                <Modal.Body>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <div>
                                <h5 className="mb-0">
                                    Delete The Hole Status with Min-Max value from Hole1
                                </h5>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div class="plainGrid fc-width50">
                                <div class="plainGridHeader">
                                    <div class="plainGridRow">
                                        <div class="plainGridHead" style={{ width: "700px" }}>
                                            Status Name
                                        </div>
                                        <div class="plainGridHead" style={{ width: "700px" }}>
                                            Min Value
                                        </div>
                                        <div class="plainGridHead" style={{ width: "700px" }}>
                                            Max Value
                                        </div>
                                    </div>
                                </div>
                                <div class="plainGridBody">
                                    <div class="plainGridRow">
                                        <div class="plainGridCell">{getDeletestatusname}</div>
                                        <div class="plainGridCell">{getDeletestatusMin}</div>
                                        <div class="plainGridCell">{getDeletestatusMax}</div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <p style={{ fontSize: "0.9rem" }}>
                                Please type "YES" below to confirm Delete the selected Status
                            </p>
                            <br />
                            <TextField
                                placeholder="Type YES for confirmation"
                                variant="outlined"
                                size="small"
                                color="primary"
                                value={yes}
                                onChange={(e) => setYes(e.target.value)}
                            />
                            {isValid ? "" : <div>Please Type YES for confirmation</div>}
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer className="border-0 filterfooter pt-0 pb-3 px-4 mobilegolffooter">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit(getDeletestatusID)}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={funclearStatus}
                        className="filtercancelbtn notificlear btn ml-2"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
