import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import { getDataNotifyUsersEmail, getDataNotifyUsersMobile, notifyMeServicePost, getCourts } from '../services/notifyMeService'

export const initialState = {
    isLoading: false,
    hasLoaded: false,
    data: [],
    saveResponse:false,
    dataByMobilePhone:[],
    dataByEmailAddress:[],
    courts:[]
}

export const notifyMeMobilePhoneServiceThunk = createAsyncThunk(
    "Get/notifyMeByMobilePhone",
    async (mobilePhone) => {
        return await getDataNotifyUsersMobile(mobilePhone)
    }
);

export const notifyMeByEmailServiceThunk = createAsyncThunk(
    "Get/notifyMeByEmail",
    async (emailAddress) => {
        return await getDataNotifyUsersEmail(emailAddress)
    }
);


export const notifyMeServicePostThunk = createAsyncThunk(
    "Post/notifyusers",
    async (payloadObj) => {
        return await notifyMeServicePost(payloadObj)
    }
);


export const notifyMeGetCourtsThunk = createAsyncThunk(
    "Get/courts",
    async () => {
        return await getCourts()
    }
);

const notifyMe = createSlice({
    name: 'notifyMe',
    initialState,
    reducers: {
        unmountnotifyMe: () => {
            return { ...initialState }
        }
    },
    extraReducers: builder => {
        builder.addCase(notifyMeMobilePhoneServiceThunk.fulfilled, (state, action) => {
            state.dataByMobilePhone = action.payload;
        });
        builder.addCase(notifyMeMobilePhoneServiceThunk.rejected, (state, action) => {
            state.dataByMobilePhone = [];
        });
        builder.addCase(notifyMeByEmailServiceThunk.fulfilled, (state, action) => {
            state.dataByEmailAddress = action.payload;
        });
        builder.addCase(notifyMeByEmailServiceThunk.rejected, (state, action) => {
            state.dataByEmailAddress = [];
        });
        builder.addCase(notifyMeServicePostThunk.pending, (state, action) => {
            state.isLoading = true;
            state.saveResponse=false;
        });
        builder.addCase(notifyMeServicePostThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.saveResponse =  true;
        });
        builder.addCase(notifyMeServicePostThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.saveResponse = false;
        });

        builder.addCase(notifyMeGetCourtsThunk.pending, (state, action) => {
            state.isLoading = true;
            state.courts=[];
        });
        builder.addCase(notifyMeGetCourtsThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.courts =  action.payload.data;
        });
        builder.addCase(notifyMeGetCourtsThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.courts = [];
        });

    }
})

export const stateSelector_ = ({ notifyMe }) => notifyMe;

export const notifyMe_ = createSelector(stateSelector_, (notifyMe) => notifyMe.data.data)
export const saveResponse_ = createSelector(stateSelector_, (notifyMe) => notifyMe.saveResponse)
export const dataByMobilePhone_ = createSelector(stateSelector_, (notifyMe) => notifyMe.dataByMobilePhone)
export const dataByEmailAddress_ = createSelector(stateSelector_, (notifyMe) => notifyMe.dataByEmailAddress)
export const notifyCourts_= createSelector(stateSelector_, (notifyMe) => notifyMe.courts)
export const notifyMeHasLoaded_ = createSelector(stateSelector_, (notifyMe) => notifyMe.hasLoaded)

export const { unmountnotifyMe } = notifyMe.actions

export default notifyMe.reducer
