import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import smartParkService from "../../../axios";
import { login_, token_ } from "../../../reducers/login";
import _ from "lodash";
import "../../../assets/scss/App.scss";
import { makeStyles } from "@material-ui/core";
import { BiLinkAlt } from "react-icons/bi";
import { GiBattery75 } from "react-icons/gi";
import { IoIosFlash } from "react-icons/io";
import { BiWater } from "react-icons/bi";
import { BsFillCloudRainHeavyFill } from "react-icons/bs";
import { GiRaining } from "react-icons/gi";
import moment from "moment";
import { BsFillCloudRainFill } from "react-icons/bs";
import {
    selectedFieldDetails_,
    selectedFieldCourts_,
    getFirstCourt_,
} from "../../../reducers/userDashboard";
import { useState } from "react";
import { getParkId } from "../../../utils/GetParkId";
const localTimeZoneOffset =
    (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;

const useStyles = makeStyles((theme) => ({
    "@global": {
        ".adminfloodsensor .divGridBody .divGridRow:nth-child(odd)": {
            color: "white",
            background: "#7d97a1",
        },
        ".adminfloodsensor .divGridBody .divGridRow:nth-child(even)": {
            color: "white",
            background: "#03364b",
        },
        ".gridwidth": {
            width: "33%",
            textAlign: "center !important",
        },
        ".sensoricon svg": {
            fontSize: 18,
        },
    },
}));
const millisecond_in_a_day = 86400000;
export default function FloodSensorStatus({ value }) {
    const classes = useStyles();
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const [refresh, setRefresh] = React.useState(false);
    const [refreshDateString, setRefreshDateString] = React.useState();
    const token = useSelector(token_);
    smartParkService.defaults.headers.common["x-access-token"] = token;
    const [FloodSensorValues, setFloodSensorValues] = React.useState({});
    const [selectop, setSelectop] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const selectedField = useSelector(selectedFieldDetails_);
    const fieldsoption = useSelector(selectedFieldCourts_);
    const firstFieldCourt = useSelector(getFirstCourt_);
    const [precipitationdata, setprecipitationdata] = React.useState([]);
    const [temperaturedata, settemperaturedata] = React.useState([]);
    const [getallparkid, setallparkid] = React.useState("");
    const [lasthrUpdatedValue, setLasthrUpdatedValue] = React.useState([]);
    const [csplastUpdatedValue, setcspLastUpdatedValue] = React.useState("");
    const [nullvalue, setNullvalue] = React.useState("-");
    const [raininches, setraininches] = React.useState("-");
    const [lastHourRain, setlastHourRain] = useState(null);
    const [rollingHourRain, setrollingHourRain] = useState(null);

    const [state, setState] = React.useState({
        start: moment().subtract(30, "days"),
        end: moment(),
    });
    const { start, end } = state;
    const handleCallback = (start, end, rangeType) => {
        if (
            new Date(end._d).getTime() - new Date(start._d).getTime() <=
            millisecond_in_a_day
        ) {
            var endDate = new Date().getTime();
            end = moment(endDate);
            start = moment(endDate - millisecond_in_a_day);
        }
        setState({ start, end });
    };
    const label =
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
    const getDateBasedOnRange = (rangeType) => {
        switch (rangeType) {
            case "Last 24 Hours":
                return [moment().subtract(1, "days").toDate(), moment().toDate()];
            case "LastWeek":
                return [moment().subtract(6, "days").toDate(), moment().toDate()];
            case "Last Month":
                return [
                    moment().subtract(1, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                ];
            case "Last 3 Month":
                return [
                    moment().subtract(3, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                ];
            case "Last 6 Month":
                return [
                    moment().subtract(6, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                ];
            case "LastYear":
                return [
                    moment().subtract(12, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                ];
            default:
                return [];
        }
    };

    React.useEffect(() => {
        if (value) {
            setallparkid(getParkId({ parkCode: value }));
        }
    }, [value]);

    const refreshDate = useCallback(() => {
        const date = new Date();
        const time = date.toLocaleString("en-US", {
            hour: "numeric",
            hour12: true,
        });
        const month_short = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const month = month_short[date.getMonth()];
        const year = date.getFullYear();
        const str = `Updated at ${time} on ${month} ${year}`;
        setRefreshDateString(str);
    }, []);
    const refreshHandler = useCallback(() => {
        setRefresh(true);
        setTimeout(() => {
            setRefresh(false);
            refreshDate();
        }, 1000);
    }, [refreshDate]);
    React.useEffect(() => {
        refreshDate();
    }, []);

    const getGreenStreamDetails = () => {
        smartParkService
            .get("api/park/" + getallparkid + "/flood/sensorvalue/Morrisville")
            .then((res) => {
                if (res.status === 200) {
                    // console.log(res);
                    setFloodSensorValues(res.data);
                }
            })
            .catch((error) => { });
    };
    const startDate = moment().tz("America/New_York").subtract(1, "day").unix();
    const endDate = moment().tz("America/New_York").unix();

    const [floodValue, setfloodValue] = useState([]);
    const getFloodData = ({ parkid, start, end }) => {
        let url;
        if (parkid === "c7a1d85f-8168-4e64-8e38-116a0624bfea") {
            url = `/api/park/${parkid}/flood/region?startDate=${start}&endDate=${end}`;
        }
        if (parkid === "c7a1d85f-8168-4e64-8e38-116a0624bfeb") {
            url = `api/citizen/ubidots/${parkid}/flood?startDate=${start}&endDate=${end}`;
        }

        smartParkService
            .get(url)
            .then((res) => {
                if (res?.status === 200) {
                    if (res?.data?.data) {
                        setfloodValue(res.data.data);
                    }
                }
            })
            .catch((error) => { });
    };

    React.useEffect(() => {
        // console.log("MT", [firstFieldCourt, selected, fieldsoption, selectedField])
        if (fieldsoption.length > 0 && firstFieldCourt && selected.length == 0)
            setSelected([fieldsoption[0]]);
        setSelectop(fieldsoption);
    }, [firstFieldCourt, selected, fieldsoption, selectedField]);

    React.useEffect(() => {
        if (getallparkid) {
            if (
                getallparkid === "c7a1d85f-8168-4e64-8e38-116a0624bfeb" ||
                getallparkid === "c7a1d85f-8168-4e64-8e38-116a0624bfea"
            ) {
                getFloodData({
                    parkid: getallparkid,
                    start: startDate * 1000,
                    end: endDate * 1000,
                });
            }
            // getGreenStreamDetails();
        }
    }, [getallparkid]);

    useEffect(() => {
        if (start._d && end._d && getallparkid) {
            if (
                getallparkid === "c7a1d85f-8168-4e64-8e38-116a0624bfea" ||
                getallparkid === "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944" ||
                getallparkid === "c7a1d85f-8168-4e64-8e38-116a0624bfeb"
            ) {
                cspdata();
            }
        }
    }, [start._d, end._d, getallparkid]);

    const cspdata = async () => {
        let startDate = new Date("01/01/2020").toISOString();
        let endDate = new Date().toISOString();

        if (start._d && end._d) {
            // var dates = props.selectedDateRange;
            startDate = new Date(start._d).getTime();
            endDate = new Date(end._d).getTime();
        }

        // Get the current timestamp
        const currentTimestamp = Date.now();
        // Calculate the timestamp for 24 hours ago (in milliseconds)
        const twentyFourHoursAgoTimestamp = currentTimestamp - 24 * 60 * 60 * 1000;
        // console.log(getallparkid);
        await smartParkService
            .get(
                "api/citizen/getweather4?startDate=" +
                twentyFourHoursAgoTimestamp +
                "&endDate=" +
                currentTimestamp +
                "&parkid=" +
                getallparkid +
                "&dataType=HourlyRain"
            )
            .then(async (res) => {
                if (res && res.status === 200) {
                    if (res.data?.data.length > 0) {
                        setlastHourRain(res.data.data[res.data.data.length - 1].value);
                    }
                }
            });
        await smartParkService
            .get(
                "api/citizen/getweather4?startDate=" +
                twentyFourHoursAgoTimestamp +
                "&endDate=" +
                currentTimestamp +
                "&parkid=" +
                getallparkid +
                "&dataType=RollingRain"
            )
            .then(async (res) => {
                if (res && res.status === 200) {
                    if (res.data?.data.length > 0) {
                        setrollingHourRain(res.data.data[res.data.data.length - 1].value);
                    }
                }
            });

        return;
        await smartParkService
            .get(
                "api/citizen/getweather4?startDate=" +
                twentyFourHoursAgoTimestamp +
                "&endDate=" +
                currentTimestamp +
                "&parkid=" +
                getallparkid +
                "&dataType=HourlyRain"
            )
            .then((res) => {
                if (res) {
                    console.log("weather4", res);
                    // weatherdatafn(res.data.data, props);
                    return;
                    const twentyfourhrdata = res.data.data.precipitation;
                    var precipitation = res.data.data.hourly_precipitation;
                    var temperature = res.data.data.temperature;
                    let rollingRain = res.data.data.rolling_precipitation;
                    var precipitationArray = precipitation.map(function (pre) {
                        return [Number(pre[0]) - localTimeZoneOffset, pre[1]];
                    });
                    var temperatureArray = temperature.map(function (pre) {
                        return [Number(pre[0]) - localTimeZoneOffset, pre[1]];
                    });
                    setprecipitationdata(precipitationArray);
                    settemperaturedata(temperatureArray);

                    // Use a Set to keep track of unique timestamps
                    const uniqueTimestamps = new Set();

                    // Filter the precipitation array to remove duplicates based on timestamps
                    const uniquePrecipitationData = precipitation.filter((data) => {
                        const timestamp = data[0];
                        if (!uniqueTimestamps.has(timestamp)) {
                            uniqueTimestamps.add(timestamp);
                            return true;
                        }
                        return false;
                    });

                    // Find the last updated value within the last 24 hours
                    // console.log(uniquePrecipitationData);
                    // console.log("-==========");

                    const num_precipitation_points = uniquePrecipitationData.length;
                    // console.log(num_precipitation_points);
                    // const sumPrecipitation = twentyfourhrdata.reduce((sum, precip) => sum + precip[1], 0).toFixed(3);

                    const sumPrecipitation =
                        num_precipitation_points > 0 && uniquePrecipitationData !== ""
                            ? uniquePrecipitationData
                                .reduce((sum, precip) => sum + precip[1], 0)
                                .toFixed(3)
                            : "";

                    if (rollingRain.length > 0) {
                        setcspLastUpdatedValue(rollingRain[rollingRain.length - 1]["1"]);
                    }
                    // console.log(sumPrecipitation);
                    // Calculate the timestamp for one hour ago
                    const oneHourAgo = Date.now() - 3600000; // 3600000 milliseconds = 1 hour

                    // Filter the precipitation data for the last one hour
                    const lastOneHourPrecipitation = precipitationArray.filter(
                        ([timestamp, precipitation]) => timestamp >= oneHourAgo
                    );

                    // console.log(precipitation[precipitation.length - 1]["1"]);
                    // Print the last one hour precipitation data
                    if (precipitation?.length > 0) {
                        setLasthrUpdatedValue(precipitation[precipitation.length - 1]);
                    }
                    // console.log("Last one hour of precipitation data:", lastOneHourPrecipitation);
                    //   if(csplastUpdatedValue && lasthrUpdatedValue && lasthrUpdatedValue=="" && csplastUpdatedValue =="" ){
                    //     setNullvalue("-");
                    //     setraininches(" in. ")
                    //   }else{
                    //     setNullvalue("NA");
                    //     setraininches("-");
                    //   }
                } else {
                    // setNullvalue("NA");
                    // setraininches("-");
                }
            });
    };

    return (
        <div
            className={`box adminfloodsensor ${classes["@global"]}`}
            style={{
                border: "0px",
                margin: "0px",
                flexGrow: "0",
            }}
        >
            <div
                className="box-header"
                style={{
                    textAlign: "center",
                    background: "#0076bb",
                }}
            >
                <h6
                    style={{
                        color: "white",
                        margin: "0px",
                    }}
                >
                    Flood & Rain Sensor
                </h6>
            </div>
            <div
                className="box-body"
                style={{
                    padding: "0px",
                    border: "0px",
                }}
            >
                <div className="divGrid">
                    <div className="divGridBody">
                        <div className="divGridRow">
                            <div className="divGridCell gridwidth">
                                Flood Sensor - Water Level{" "}
                            </div>
                            <div className="divGridCell gridwidth sensoricon">
                                <BiWater />
                            </div>
                            <div className="divGridCell gridwidth">
                                {floodValue.length > 0
                                    ? `${floodValue[floodValue.length - 1]["distance"] ||
                                    floodValue[floodValue.length - 1]["level"]
                                    } ft.`
                                    : "N. A"}
                            </div>
                        </div>
                        <div className="divGridRow">
                            <div className="divGridCell gridwidth">Rain Gauge - Prev. Hr</div>
                            <div className="divGridCell gridwidth sensoricon">
                                <GiRaining />
                            </div>
                            <div className="divGridCell gridwidth">
                                {lastHourRain !== null ? lastHourRain + " in." : "NA"}
                            </div>
                        </div>

                        <div className="divGridRow">
                            <div className="divGridCell gridwidth">
                                Rain Gauge - Total in last 24 hrs
                            </div>
                            <div className="divGridCell gridwidth sensoricon">
                                <BsFillCloudRainHeavyFill />
                            </div>
                            <div className="divGridCell gridwidth">
                                {rollingHourRain !== null ? rollingHourRain + " in." : "NA"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
