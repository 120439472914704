import React from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  Chip,
  Grid,
  Paper,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import treadmill from "../../../src/assets/img/treadmill.png";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  image: {
    padding: "20px",
    height: "175px",
    display: "block",
    margin: "0 auto",
  },

  listItem: {
    padding: theme.spacing(1, 0),
  },
  listItemHeading: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  listItemIcon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  chipContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  chip: {
    flex: 1,
    margin: theme.spacing(0.25),
    [theme.breakpoints.up("sm")]: {
      flexGrow: 1,
    },
  },
}));

const EquipmentCard = ({ fieldsList, icon, heading }) => {
  const classes = useStyles();

  return (
    <Hidden smDown>
      <Paper elevation={7}>
        <Card>
          <CardContent>
            {icon && <img src={icon} alt="Icon" className={classes.image} />}
            <Typography
              className={classes.heading}
              color="textPrimary"
              gutterBottom
            >
              {heading}
            </Typography>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} className={classes.chipContainer}>
                    {fieldsList.map((chip) => (
                      <Chip
                        className={classes.chip}
                        style={{
                          backgroundColor: chip.color || "#12d958de",
                          color: "#FFFFFF",
                        }}
                        key={chip.value}
                        label={chip.name + chip.value}
                        size="small"
                      />
                    ))}
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Hidden>
  );
};

export default EquipmentCard;
