import React, { useEffect, useState, useCallback } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, styled } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import "./index.css";

import {
  Button,
  Card,
  CardHeader,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Box,
  CircularProgress,
  Grid,
  IconButton,
} from "@material-ui/core";
import { DirectionsOutlined, MoreVert } from "@material-ui/icons";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../../views/Common";
import {
  setSelectedField,
  fieldDetailsByType_,
  setFieldCourts,
} from "../../reducers/userDashboard";

const useStyles = makeStyles((theme) => ({
  card: {},
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    marginTop: "-70px",
    position: "relative",
    padding: theme.spacing(0, 2),
    height: 60,
  },
  cardContent: {
    paddingTop: 32,
  },
  ht364: {
    height: 373,
  },
  h5: {
    color: "#fff",
    fontWeight: 600,
    width: "50%",
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    textShadow: "-2px 2px 2px #000",
  },
  imgCard: {
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 16,
    },
    "& .MuiCardContent-root": {
      [theme.breakpoints.up("lg")]: {
        height: 487,
      },
    },
  },
  media: {
    height: 200,
    cursor: "default",
    cursor: "pointer",
  },
  customButton: {
    textTransform: "capitalize",
    fontSize: "0.95em",
    fontWeight: 500,
    boxShadow: "none !important",
  },
  titleBtn: {
    background: "#53de21 !important",
    color: "#fff",
    fontWeight: "bold",
    textTransform: "capitalize",
    boxShadow: "none !important",
    borderRadius: 20,
    fontSize: 16,
    width: 100,
  },
  columnClass: {
    textAlign: "center",
    marginTop: "25px",
    width: "100%",
    height: "100px",
  },

  h2Text: {
    paddingTop: "7px",
    textAlign: "center",
    color: "#fff",
  },

  pText: {
    backgroundColor: "#b8f0d2",
    borderRadius: "4px",
    color: "#0b9444",
    fontSize: "12px",
    fontWeight: 500,
    margin: "0px 20px 0px 20px!important",
    padding: "7px 5px 7px 5px",
    textTransform: "uppercase",
    textAlign: "center",
  },
  h6Text: {
    backgroundColor: "#fec6c6",
    borderRadius: "4px",
    color: "#ff0000",
    fontSize: "12px",
    fontWeight: 500,
    margin: "0px 20px 0px 20px!important",
    padding: "7px 5px 7px 5px",
    textTransform: "uppercase",
    textAlign: "center",
  },
}));

const occupiedDiv = {
  color: "#fff",
  backgroundColor: "#ff0000",
};

const unoccupiedDiv = {
  color: "#fff",
  backgroundColor: "#0b9444",
};

export default function CspStatus(props) {
  const {
    actions,
    allNotify,
    cardType,
    cardStyle = "simple",
    getFields,
    refresh,
  } = props;
  // const [selectedCard, setSelectedCard] = useState(null);
  const [fields, setFields] = useState([]);
  const classes = useStyles();
  const [status, setStatus] = useState("");
  const selectedCard = useSelector((state) =>
    fieldDetailsByType_(state, cardType)
  );
  const dispatch = useDispatch();

  const getParkFields = useCallback(
    async (cardType) => {
      const { url, cardColumns, id } = cardType;
      var restoken = await GenerateEncryptedToken();
      await smartParkService
        .get(url, {
          headers: { "x-access-token": restoken },
        })
        .then((res) => {
          if (res.status === 200) {
            var fields = res.data.data;
            console.log(444);
            // console.log(fields);
            const statusOFFields =
              fields &&
              fields.length > 0 &&
              fields.filter((obj) => {
                let status = obj[cardColumns[1]];
                return status === "Open" || status === 0;
              });
            let typeOfField = fields[0][cardColumns[1]];
            if (typeof typeOfField === "string")
              statusOFFields.length > 0
                ? setStatus("Open")
                : setStatus("Closed");
            else {
              statusOFFields.length > 0
                ? setStatus("Unoccupied")
                : setStatus("Occupied");
            }

            fields.map((field, index) => {
              field._orginalName = `${cardColumns[0] === "court_name"
                  ? "Court " + (index + 1)
                  : field[cardColumns[0]]
                }`;
              let status = field[cardColumns[1]];
              let className = status === "Closed" ? "closed" : "open";
              if (typeof status != "string") {
                className = status ? "closed" : "open";
                status = status ? "Occupied" : "Unoccupied";
              } else {
                status = status;
              }
              field._status = status;
              field.fieldId = field[id];
              field.className = className;
              field.label = field[cardColumns[0]];
              field.value = field[id];
              return field;
            });
            if (fields && fields.length) {
              fields.forEach((field) => {
                field._orginalName === "Fields 1 & 2" &&
                  (field.displayOrder = 1);
                field._orginalName === "Field 3" && (field.displayOrder = 2);
                field._orginalName === "Field 4" && (field.displayOrder = 3);
                field._orginalName === "Field 5" && (field.displayOrder = 4);
                field._orginalName === "Fields 6-8" && (field.displayOrder = 5);
              });
            }
            fields.sort((a, b) => a.displayOrder - b.displayOrder);
            setFields(fields);
            getFields && getFields(fields);
            console.log("MT", "CspStatus");
            dispatch(setFieldCourts(fields));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [getFields]
  );

  useEffect(() => {
    if (selectedCard) {
      // const card = morrisvilleDashboard[cardType];
      // setSelectedCard(card);
      dispatch(setSelectedField(selectedCard));
      getParkFields(selectedCard);
    }
  }, [selectedCard]);

  useEffect(() => {
    if (refresh) {
      getParkFields(selectedCard);
    }
  }, [refresh]);

  const openDirections = useCallback(() => {
    window.open(selectedCard.mapUrl, "_blank");
  }, [selectedCard]);

  return (
    <>
      {
        <div>
          <div className="checking-csp-status">
            {fields.map((field) => (
              <div
                className={classes.columnClass}
                style={
                  field._status === "Unoccupied" ? unoccupiedDiv : occupiedDiv
                }
              >
                <h2 className={classes.h2Text}>{field._orginalName}</h2>
                <p
                  className={
                    field._status === "Unoccupied"
                      ? classes.pText
                      : classes.h6Text
                  }
                >
                  {field._status === "Unoccupied" ? "Unoccupied" : "Occupied"}
                </p>
              </div>
            ))}
          </div>
        </div>
      }
    </>
  );
}

CspStatus.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  actions: PropTypes.bool,
  allNotify: PropTypes.bool,
  refresh: PropTypes.bool,
};
