import React from "react";
import { Grid, Card, CardContent, Typography, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
    padding: theme.spacing(2),
  },
  card: {
    height: "100%",
  },
  cardGrid: {
    height: "auto",
    padding: theme.spacing(1),
  },
  cardContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  titleStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  occupancy: {
    marginTop: theme.spacing(2),
  },
}));

function WorkoutStatusCard({ data }) {
  const getStatus = ({ occupancy }) => {
    if (occupancy === null) {
      return "N.A";
    }
    if (occupancy === "") {
      return "Not Available";
    }
    if (occupancy > 0) {
      return "Occupied";
    }
    if (occupancy === 0) {
      return "Unoccupied";
    }
  };
  const status = ({ occupancy }) => {
    if (occupancy === null) {
      return "N.A";
    }
    if (occupancy === "") {
      return "Not Available";
    }
    if (occupancy === 0) {
      return "0 People";
    }
    if (occupancy > 0) {
      if (occupancy === 1) {
        return `${occupancy} Person`;
      }
      return `${occupancy} People`;
    }
  };
  const getColor = ({ occupancy }) => {
    if (occupancy !== "") {
      if (occupancy === 0) {
        return "green";
      } else if (occupancy > 0) {
        return "red";
      }
    } else {
      return "blue";
    }
  };
  const { title, sensor_value, color } = data;

  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} className={classes.cardGrid}>
      <Card
        style={{ backgroundColor: color }}
        elevation={8}
        className={classes.card}
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.titleStatus}>
            <Typography variant="h5" component="div">
              {title}
            </Typography>
            <Chip
              size="small"
              style={{
                backgroundColor: getColor({ occupancy: sensor_value }),
                color: "white",
                borderRadius: "5px",
              }}
              label={getStatus({ occupancy: sensor_value })}
              className={classes.chip}
            />
          </div>
          <Typography
            variant="h6"
            component="div"
            className={classes.occupancy}
          >
            {status({ occupancy: sensor_value })}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default WorkoutStatusCard;
