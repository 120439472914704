import { store } from "../reducers/store";

export const checkPermissions = ({ permission, permissionType }) => {
  try {
    const state = store.getState();

    if (!state.permissions || !state.permissions.hasLoadedpermissions) {
      throw new Error("Permissions have not been loaded yet.");
    }

    const permissionsobj = state.permissions.data;

    if (!permissionsobj) {
      throw new Error(`No permissions found.`);
    }

    // Find the specific permission object by feature name
    const requiredPermission = permissionsobj.find(
      (perm) => perm.feature === permission
    );

    if (!requiredPermission) {
      throw new Error(`Permission "${permission}" not found.`);
    }

    // Check if the permission type exists in the found permission object
    const permissionValue = requiredPermission[permissionType];

    if (permissionValue === undefined) {
      throw new Error(
        `Permission type "${permissionType}" not found for permission "${permission}".`
      );
    }

    return Boolean(Number(permissionValue));
  } catch (error) {
    console.error("Error checking permission:", error.message);
    return false;
  }
};

export const checkDomain = ({ email }) => {
  const allowedDomains = ["varidx.io", "samanalytic.com", "samitsolutions.com"];
  return allowedDomains.some((domain) => email.includes(domain));
};
