import smartParkService from "../axios";

export const loginService = async (payloadObj) => {
    try {
        const response = await smartParkService.post("api/auth/login", {
            ...payloadObj,
        });
        const { data } = response;

        if (data.data.IsEnable === "false") {
            throw new Error("User is not enabled. Authentication failed.");
        }

        return data;
    } catch (error) {
        alert(error);
        console.error(error);
        throw error;
    }
};
