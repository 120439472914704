import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import MaskedInput from "react-maskedinput";
import { todosAction } from "../../reducers/reducers.js";
import Grid from "@material-ui/core/Grid";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Slide from "@material-ui/core/Slide";
import { useLocation } from "react-router-dom";
import { BiSolidXCircle } from "react-icons/bi";
import { BiSolidCheckCircle } from "react-icons/bi";
import Table from "react-bootstrap/Table";
import "../../assets/scss/App.scss";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import { initialState, rules_, updateRulesState } from "../../reducers/rules";
import { useSelector, useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import DatePicker from "react-datepicker";
import Button from "../../components/CustomButtons/Button.js";
import { GenerateEncryptedToken } from "../../views/Common";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import {
    permissions_,
    Getaccesspermissions,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import { login_ } from "../../reducers/login";
import _ from "lodash";
import { paperAutoAdjustIntervalInMS } from "../../config/index";
import { useTheme } from "@material-ui/core/styles";
import calendar from "../../assets/img/calendar-icon.png";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import { UnixTimeStamp } from "../../utils/UnixTimeStamp.js";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;
const weekdayclass = makeStyles((theme) => ({
    weekdayborder: {
        border: "1px solid #ced4da",
        borderRadius: "0.25rem",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" position="top" ref={ref} {...props} />;
});
const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        "&$checked": {
            transform: "translateX(12px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: "none",
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

const names = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
];

const Park = ["Gates", "Fields", "Moisture"];

const laptopBreakpoint = "1024px";
const tabletBreakpoint = "768px";

const LaptopColumn = styled.div`
  width: 22%; /* Laptop width */
`;

const TabletColumn = styled.div`
  width: 20%; /* Tablet width */
`;

const PhoneColumn = styled.div`
  width: 30%; /* Phone width */
`;

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const useStyles = makeStyles(styles);

const localTimeZoneOffset =
    (new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000;

// Get the current time
const currentTime = new Date();

// Set the minimum time to the current time
let minTime = new Date(currentTime);
let maxTime = new Date(currentTime);
maxTime.setHours(23, 59, 0, 0);

export default function Notification(props) {
    // const moment = require("moment-timezone");
    const [rowHeights, setRowHeights] = React.useState({});
    const loginDetails = useSelector(login_);
    const location = useLocation();
    const parkid = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");
    const [loading, setLoading] = React.useState(true);
    const [permission, setpermission] = React.useState({});
    const classes = useStyles();
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);
    const myclass = weekdayclass();
    const [gethandleParkId, sethandleParkId] = React.useState("");
    const [getNotificationmessage, setNotificationmessage] = React.useState("");
    const [gethandleParkDeviceType, sethandleParkDeviceType] = React.useState("");
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [personName, setPersonName] = React.useState([]);
    const [getSearchfilter, setSearchfilter] = React.useState([]);
    const theme = useTheme();
    const [gethandleParkDevice, sethandleParkDevice] = React.useState("");
    const [getparks, setparks] = React.useState([]);
    const [getfinaldevice, setfinaldevice] = React.useState([]);
    const [getfinaldevicetype, setfinaldevicetype] = React.useState([]);
    const [getableRuleid, setableRuleid] = React.useState([]);
    const [getapiRuleID, setapiRuleID] = React.useState("");
    const [getapiRuleName, setapiRuleName] = React.useState("");
    const [getSearchruleparkname, setSearchruleparkname] = React.useState("");
    const [getSearchruleDevicetype, setSearchruleDevicetype] = React.useState("");
    const [getSearchruleDevice, setSearchruleDevice] = React.useState("");
    const [getSearchpark, setSearchpark] = React.useState("");
    const [getdname, setdname] = React.useState("");
    const [getnotifyupdateId, setnotifyupdateId] = React.useState("");
    const [getWebname, setWebname] = React.useState("");
    const [getWeblinkname, setWeblinkname] = React.useState("");
    const numRows = getableRuleid.length;
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showEndDatePicker, setshowEndDatePicker] = useState(false);
    const [getParkError, setParkError] = useState(false);
    const [getStartParkError, setStartParkError] = useState(false);
    const [getEndDateError, setEndDateError] = useState(false);
    const [overlappingError, setOverlappingError] = useState(false);
    const [getMessageError, setMessageError] = useState(false);
    const [getUpdatenotify, setUpdatenotify] = useState(false);
    const [getHttperror, setHttperror] = useState(false);

    useEffect(() => {
        if (
            props.notifyid !== undefined &&
            props.notifypid !== undefined &&
            props.notifypname !== undefined &&
            props.notifySDate !== undefined &&
            props.notifyEDate !== undefined &&
            props.notifymess
        ) {
            const startMoment = new Date(props.notifySDate);
            const endMoment = new Date(props.notifyEDate);
            //   const adjustedSDate = moment(startMoment)
            //     .subtract(5, "hours")
            //     .subtract(30, "minutes")
            //     .toDate();
            //   const adjustedEDate = moment(endMoment)
            //     .subtract(5, "hours")
            //     .subtract(30, "minutes")
            //     .toDate();
            setnotifyupdateId(props.notifyid);
            sethandleParkId(props.notifypid);
            setSearchpark(props.notifypname);
            setStartDate(startMoment);
            setEndDate(endMoment);
            setNotificationmessage(props.notifymess);
            setWebname(props.notifywebName);
            setWeblinkname(props.notifyweblink);
            setUpdatenotify(true);
        } else {
            setStartDate(new Date(timeInEST));
        }
    }, [props]);

    useEffect(() => {
        funcSearchoptions();
    }, []);

    function funcSearchoptions() {
        try {
            let url = "api/park/announcement/announcementoptions";
            var restoken = GenerateEncryptedToken();
            smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        try {
                            if (res.status === 200) {
                                if (res.data.status == 200) {
                                    var dataa = [];
                                    var devices = {};
                                    res.data.options?.forEach((data, i) => {
                                        dataa.push({
                                            id: data.ID,
                                            name: data.Name,
                                            shortname: data.ShortName,
                                            device: data.Devices,
                                        });
                                        devices[data.ID] = data.Devices;
                                    });
                                    setparks(dataa);
                                    setSearchfilter(devices);
                                } else {
                                    funcSearchoptions();
                                }
                            } else {
                                funcSearchoptions();
                            }
                        } catch {
                            console.log("some error in search Dropdown api ");
                        }
                    } else {
                        console.log("Search else error: ");
                    }
                })
                .catch((error) => {
                    console.error("Search error: ", error);
                });
        } catch {
            console.log("Search Option Error");
        }
    }

    useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);

    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "RuleEngine";
        });
        setpermission(Dpermissions[0]);
    };

    const validate = () => {
        let isValid = true;
        // Validate Park ID
        if (gethandleParkId === "") {
            setParkError(true);
            isValid = false;
        } else {
            setParkError(false);
        }
        // Validate Start Date
        if (startDate === "" || startDate === null || startDate === undefined) {
            setStartParkError(true);
            isValid = false;
        } else {
            setStartParkError(false);
        }
        // Validate End Date
        if (endDate === "" || endDate === null || endDate === undefined) {
            setEndDateError(true);
            isValid = false;
        } else {
            setEndDateError(false);
        }
        // Validate Notification Message
        if (getNotificationmessage === "") {
            setMessageError(true);
            isValid = false;
        } else {
            setMessageError(false);
        }

        // Validate Web Link
        if (getWeblinkname !== "" && !getWeblinkname.startsWith("https")) {
            console.log("Invalid URL format");
            setHttperror(true);
            isValid = false;
        } else {
            setHttperror(false);
        }
        // Add more API-specific conditions if needed...

        return isValid;
    };
    const checkOverlappingNotification = async (parkid, funcType) => {
        let overlap = false;
        let currentStart = new Date(startDate).getTime();
        let currentEnd = new Date(endDate).getTime();
        if (parkid) {
            let url = `api/notification/list`;
            let payload = {
                Limit: 100,
                Page: 1,
                parkId: parkid,
            };
            await smartParkService.post(url, payload).then((res) => {
                if (res?.data?.data) {
                    res.data.data.forEach((notification, idx) => {
                        if (getnotifyupdateId) {
                            if (getnotifyupdateId !== notification._id) {
                                let start = new Date(notification.startDate).getTime();
                                let end = new Date(notification.endDate).getTime();
                                if (
                                    (currentStart < start && currentEnd < start) ||
                                    (currentStart > end && currentEnd > end)
                                ) {
                                    console.log("no overlap");
                                } else {
                                    overlap = true;
                                    setOverlappingError(true);
                                    setTimeout(() => {
                                        setOverlappingError(false);
                                    }, 3000);
                                }
                            }
                        } else {
                            let start = new Date(notification.startDate).getTime();
                            let end = new Date(notification.endDate).getTime();
                            if (
                                (currentStart < start && currentEnd < start) ||
                                (currentStart > end && currentEnd > end)
                            ) {
                                console.log("no overlap");
                            } else {
                                overlap = true;
                                setOverlappingError(true);
                                setTimeout(() => {
                                    setOverlappingError(false);
                                }, 3000);
                            }
                        }
                    });
                }
            });
        }
        return overlap;
    };

    const AddnewNotification = async () => {
        let overlap = false;
        let unixStartTimestamp = "";
        let unixEndTimestamp = "";
        if (startDate !== "" && startDate !== undefined && startDate !== null) {
            //   startDate.setHours(0, 0, 0, 0);
            unixStartTimestamp = startDate;
        } else {
            unixStartTimestamp = "";
        }
        if (endDate !== "" && endDate !== undefined && endDate !== null) {
            //   endDate.setHours(23, 59, 0, 0);
            unixEndTimestamp = endDate;
        } else {
            unixEndTimestamp = "";
        }
        // const formattedStartDate = moment(unixStartTimestamp).format(
        //   "ddd, D MMM YYYY HH:mm:ss [GMT]"
        // );
        // const formattedEndDate = moment(unixEndTimestamp).format(
        //   "ddd, D MMM YYYY HH:mm:ss [GMT]"
        // );
        const sdate = new Date(unixStartTimestamp).getTime();
        const edate = new Date(unixEndTimestamp).getTime();
        const isValid = validate();
        if (isValid) {
            overlap = await checkOverlappingNotification(gethandleParkId, "add");
        }
        if (getUpdatenotify == false && isValid && overlap === false) {
            if (
                gethandleParkId !== "" &&
                unixStartTimestamp !== "" &&
                unixEndTimestamp !== "" &&
                getNotificationmessage !== ""
            ) {
                let url = "api/notification/add";
                var restoken = GenerateEncryptedToken();
                var requestBody = {
                    parkId: gethandleParkId,
                    message: getNotificationmessage,
                    parkName: getSearchpark,
                    startDate: UnixTimeStamp(sdate),
                    endDate: UnixTimeStamp(edate),
                    webLink: getWeblinkname,
                    webName: getWebname,
                };
                smartParkService
                    .post(url, requestBody, {
                        headers: {
                            "x-access-token": restoken,
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (res) {
                            props.onHide();
                            if (res.data.status == 200) {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="toasticon">
                                            <BiSolidCheckCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Success</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.success(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "toastcontainer",
                                });
                            }
                            if (res.data.status == 500) {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="erroricon">
                                            <BiSolidXCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Error</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.error(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "errorcontainer",
                                });
                                console.log("duplicate notification");
                            }
                            funclearfilter();
                            setUpdatenotify(false);
                        } else {
                            console.log("Notification Error: ");
                        }
                    })
                    .catch((error) => {
                        console.error("Notification error: ", error);
                    });
            } else {
                console.log("please Enter all details");
            }
        } else {
        }
        if (getUpdatenotify == true && isValid) {
            overlap = await checkOverlappingNotification(gethandleParkId, "update");
            if (
                gethandleParkId !== "" &&
                unixStartTimestamp !== "" &&
                unixEndTimestamp !== "" &&
                getNotificationmessage !== "" &&
                !overlap
            ) {
                let url = "api/notification/update";
                var restoken = GenerateEncryptedToken();
                var requestBody = {
                    id: getnotifyupdateId,
                    parkId: gethandleParkId,
                    message: getNotificationmessage,
                    parkName: getSearchpark,
                    startDate: UnixTimeStamp(sdate),
                    endDate: UnixTimeStamp(edate),
                    webLink: getWeblinkname,
                    webName: getWebname,
                };
                smartParkService
                    .post(url, requestBody, {
                        headers: {
                            "x-access-token": restoken,
                            userid: userid,
                        },
                    })
                    .then((res) => {
                        if (res) {
                            // console.log(res);
                            props.onHide();
                            if (res.data.status == 200) {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="toasticon">
                                            <BiSolidCheckCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Success</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.success(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "toastcontainer",
                                });
                            }
                            if (res.data.status == 500) {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="erroricon">
                                            <BiSolidXCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Error</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.error(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "errorcontainer",
                                });
                                console.log("duplicate notification");
                            }
                            funclearfilter();
                            setUpdatenotify(false);
                        } else {
                            console.log("Notification Error: ");
                        }
                    })
                    .catch((error) => {
                        console.error("Notification error: ", error);
                    });
            } else {
                console.log("please Enter all details");
            }
        }
    };

    const funclearfilter = () => {
        sethandleParkId("");
        setNotificationmessage("");
        setStartDate("");
        setEndDate("");
        setWebname("");
        setWeblinkname("");
        setParkError(false);
        setStartParkError(false);
        setEndDateError(false);
        setMessageError(false);
        setHttperror(false);
    };
    function funcStartDate(e) {
        setEndDate("");
        console.log(e);
        if (e !== null && e !== undefined) {
            setStartDate(e);
            setShowDatePicker(false);
            setStartParkError(false);
        } else {
            setStartDate("");
            setEndDate("");
            setShowDatePicker(false);
        }
    }
    function funcEndDate(e) {
        if (e !== null && e !== undefined) {
            if (e > startDate) {
                setEndDate(e);
                setshowEndDatePicker(false);
                setEndDateError(false);
            }
        } else {
            setEndDate("");
            setshowEndDatePicker(false);
        }
    }
    function handleWebName(e) {
        const inputweb = e.target.value;
        if (inputweb !== "" && inputweb !== undefined) {
            setWebname(inputweb);
        } else {
            setWebname("");
        }
    }
    function handleWeblinkName(e) {
        const inputValue = e.target.value;
        if (inputValue === "") {
            setHttperror(false);
        }
        if (inputValue !== "" && inputValue !== undefined) {
            setWeblinkname(inputValue);
        } else {
            setHttperror(false);
            setWeblinkname("");
        }
    }

    const handleParkId = (elem) => {
        setfinaldevice([]);
        setfinaldevicetype([]);
        sethandleParkDeviceType("");
        sethandleParkDevice("");
        setSearchpark("");
        setSearchruleparkname("");
        setSearchruleDevicetype("");
        setSearchruleDevice("");
        setdname("");
        setapiRuleName("");
        setapiRuleID("");
        sethandleParkId("");
        // Get the selected option element
        const selectedOption = elem.target.options[elem.target.selectedIndex];
        // Access the data-name attribute value
        const dataName = selectedOption.getAttribute("data-name");
        console.log(dataName);
        setSearchpark(dataName);
        if (elem.target.value !== "All Parks") {
            console.log(elem.target.value);
            sethandleParkId(elem.target.value);
            setParkError(false);
            setParkError(false);
        } else {
            setfinaldevice([]);
            setfinaldevicetype([]);
            sethandleParkDeviceType("");
            sethandleParkDevice("");
            setSearchpark("");
            setSearchruleparkname("");
            setSearchruleDevicetype("");
            setSearchruleDevice("");
            setdname("");
            setapiRuleName("");
            setapiRuleID("");
            sethandleParkId("");
        }
    };
    const handleImgClick = () => {
        setShowDatePicker(true);
        setshowEndDatePicker(false);
    };

    const handleDatePickerBlur = () => {
        setShowDatePicker(false);
    };
    const handleEndImgClick = () => {
        setshowEndDatePicker(true);
        setShowDatePicker(false);
    };

    const handleEndDatePickerBlur = () => {
        setshowEndDatePicker(false);
    };
    const funcMessage = (e) => {
        if (e.target.value !== "") {
            setNotificationmessage(e.target.value);
            setMessageError(false);
        } else {
            setNotificationmessage("");
        }
    };
    const closeNotification = () => {
        sethandleParkId("");
        setNotificationmessage("");
        setStartDate(null);
        setEndDate(null);
        setWebname("");
        setWeblinkname("");
        setParkError(false);
        setStartParkError(false);
        setEndDateError(false);
        setMessageError(false);
        setHttperror(false);
        props.onHide();
    };
    const timezone = "America/New_York";
    const timeInEST = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss");

    const filterPassedTime = (time) => {
        const currentDate = new Date(timeInEST);
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const filterEndDate = (time) => {
        const currentDate = new Date(startDate);
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modalzidnex notifymodal"
        >
            <Modal.Header className="py-1 notificationheader">
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="notificationtitle"
                >
                    Add Notification
                </Modal.Title>
                <Button
                    className="btn closebtnnotification p-0"
                    onClick={closeNotification}
                >
                    X
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} className="p-0">
                        <div className="container-fluid ">
                            <div className="row datepickerwidth">
                                <div className="col-12">
                                    <div className="notificationlabel">Select Park :</div>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => handleParkId(e)}
                                        value={gethandleParkId}
                                        style={{ width: "100%", marginRight: "5px" }}
                                    >
                                        <option>All Parks</option>
                                        {getparks?.map((data, idx) => (
                                            <option
                                                key={idx}
                                                value={data.id}
                                                data-idx={idx}
                                                data-name={data.name}
                                            >
                                                {data.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    {getParkError && (
                                        <p style={{ color: "red" }}>* Please Select Park</p>
                                    )}
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="notificationlabel">Start Date :</div>
                                    <div
                                        className={`notifydatepicker ${classes.startdatecss}`}
                                        style={{ position: "relative" }}
                                    >
                                        <DatePicker
                                            showTimeSelect
                                            dateFormat="MM/dd/yyyy hh:mm aa"
                                            selected={startDate}
                                            selectsStart
                                            startDate={startDate}
                                            onChange={(e) => funcStartDate(e)}
                                            minDate={new Date(timeInEST)}
                                            filterTime={filterPassedTime}
                                            className="form-control"
                                            placeholderText="mm/dd/yyyy hh:mm (AM/PM)"
                                            onFocus={handleImgClick}
                                            timezone="GMT"
                                            onBlur={handleDatePickerBlur}
                                            open={showDatePicker}
                                            customInput={<MaskedInput mask="11/11/1111 11:11 aa" />}
                                        />
                                        <img
                                            src={calendar}
                                            className="img-fluid calenderimg"
                                            alt="calender image"
                                            onClick={handleImgClick}
                                        />
                                    </div>
                                    {getStartParkError && (
                                        <p style={{ color: "red" }}>* Please Select Start Date</p>
                                    )}
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="notificationlabel">End Date :</div>
                                    <div
                                        className={`notifydatepicker ${classes.startdatecss}`}
                                        style={{ position: "relative" }}
                                    >
                                        <DatePicker
                                            showTimeSelect
                                            dateFormat="MM/dd/yyyy hh:mm aa"
                                            selected={endDate}
                                            selectsStart
                                            placeholderText="mm/dd/yyyy hh:mm (AM/PM)"
                                            disabled={!startDate}
                                            filterTime={filterEndDate}
                                            minDate={startDate}
                                            // endDate={endDate}
                                            // minTime={
                                            //   startDate !== null &&
                                            //   startDate !== undefined &&
                                            //   startDate !== ""
                                            //     ? startDate
                                            //     : minTime
                                            // } // Set minimum time
                                            // maxTime={maxTime} // Set maximum time
                                            onChange={(e) => funcEndDate(e)}
                                            timezone="GMT"
                                            className="form-control"
                                            onFocus={handleEndImgClick}
                                            onBlur={handleEndDatePickerBlur}
                                            open={showEndDatePicker}
                                            customInput={<MaskedInput mask="11/11/1111 11:11 aa" />}
                                        />
                                        <img
                                            src={calendar}
                                            className="img-fluid calenderimg"
                                            alt="calender image"
                                            onClick={handleEndImgClick}
                                        />
                                    </div>
                                    {getEndDateError && (
                                        <p style={{ color: "red" }}>* Please Select End Date</p>
                                    )}
                                </div>
                                <div className="col-12">
                                    {overlappingError && (
                                        <p style={{ color: "red" }}>
                                            *Selected notification timing overlaps with the existing
                                            Hours.
                                        </p>
                                    )}
                                    <div className="notificationlabel">
                                        Timezone for Start date and End date :{" "}
                                        <strong>{timezone}</strong>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="notificationlabel">
                                        Notification Message :
                                    </div>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Message"
                                            value={getNotificationmessage}
                                            onChange={funcMessage}
                                        />
                                    </Form.Group>
                                    {getMessageError && (
                                        <p style={{ color: "red" }}>* Please Enter You Message</p>
                                    )}
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="notificationlabel">
                                        Web Name (Optional) :{" "}
                                    </div>
                                    <input
                                        type="text"
                                        value={getWebname}
                                        placeholder="www.example.com"
                                        onChange={(e) => handleWebName(e)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="notificationlabel">
                                        Web Link (Optional) :{" "}
                                    </div>
                                    <input
                                        type="text"
                                        value={getWeblinkname}
                                        placeholder="https://www.example.com/"
                                        onChange={(e) => handleWeblinkName(e)}
                                        className="form-control"
                                    />
                                    {getHttperror && (
                                        <p style={{ color: "red" }}>
                                            * Please Enter the Link With <strong> https:// </strong>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Modal.Body>
            <Modal.Footer className="border-0 filterfooter pt-0">
                <Button
                    variant="primary"
                    onClick={AddnewNotification}
                    className="filterapplybtn btn"
                >
                    Apply
                </Button>
                <Button
                    variant="secondary"
                    onClick={funclearfilter}
                    className="filtercancelbtn notificlear"
                >
                    Clear All
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
