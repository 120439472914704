import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { KeyboardBackspace } from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import TextField from "@material-ui/core/TextField";
import { login_, token_ } from "../../reducers/login";
import { useSelector } from "react-redux";
import _ from "lodash";
const useStyles = makeStyles(styles);
const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "red",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white,
    },
  },
}));

export default function Dashboard() {
  const helperTestClasses = helperTextStyles();
  const location = useLocation();
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const name = _.get(loginDetails, ["FirstName"], "");
  const classes = useStyles();
  const history = useHistory();
  const [RoleName, setRoleName] = useState("");
  const [existingRows, setExistingRows] = useState([]);
  const [roleErrorMessage, setroleErrorMessage] = useState("");

  useEffect(() => {
    if (!location.state) {
      history.push("/AccessManagment");
    } else {
      setExistingRows(location?.state?.data);
    }
  }, [location]);
  const existingName = (roleName) => {
    const processedName = roleName.trim().replace(/\s+/g, " ").toLowerCase();
    setRoleName(processedName);

    return existingRows.some(
      (role) => role.Role && role.Role.trim("").toLowerCase() === processedName
    );
  };
  var Saveroles = async () => {
    let error = false;
    if (!RoleName) {
      setroleErrorMessage("Please enter role name");
      error = true;
      return;
    }
    if (RoleName.trim("") === "") {
      setRoleName("");
      setroleErrorMessage("Please enter a valid role.");
      error = true;
    }
    if (existingName(RoleName)) {
      setroleErrorMessage("Role already exists.");
      error = true;
    }
    if (!error) {
      var RoleObject = {};
      RoleObject.Role = RoleName.replace(/ /g, "");
      RoleObject.RoleDescription = RoleName;
      RoleObject.parkID = parkid;
      // RoleObject.InsertedBy= JSON.parse(localStorage.getItem('user')).name;
      RoleObject.InsertedBy = name;

      await smartParkService
        .post("api/park/" + parkid + "/roles", RoleObject, {
          headers: {
            userid: userid,
          },
        })
        .then(async (res, err) => {
          if (res.data.status === "200") {
            await preparefeatureobject(res.data.data.RoleID);

            history.push("/AccessManagment");
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };
  var preparefeatureobject = async (data) => {
    await smartParkService
      .get("api/park/" + parkid + "/features")
      .then(async (res) => {
        if (res.data.status == 200) {
          await SaveRolepermissions(res.data.data, data);
        }
      });
  };
  var SaveRolepermissions = (data, RoleID) => {
    var rolepermissionarray = [];
    for (var i = 0; i < data.length; i++) {
      var obj = {};
      obj.featureID = data[i].featureID;
      obj.feature = data[i].name;
      obj.create = "0";
      obj.read = "0";
      obj.update = "0";
      obj.delete = "0";
      rolepermissionarray.push(obj);
    }
    var permissionobj = {};
    permissionobj.RoleID = RoleID;
    permissionobj.Role = RoleName;
    permissionobj.RolePermission = rolepermissionarray;
    permissionobj.parkID = parkid;
    permissionobj.InsertedBy = name;
    smartParkService
      .post("api/park/" + parkid + "/rolepermission", permissionobj)
      .then(async (res) => {
        if (res.data.status == 200) {
          alert("Added in RolePermission");
        }
      });
  };
  return (
    <div className={classes.root}>
      <div className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Access Management</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                  Access Management
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <div class="content-middle">
            <div class="content-sub-header">
              <div>
                <Link
                  component="button"
                  onClick={() => history.push("./AccessManagment")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
              <div>
                <h3>Add Role</h3>
              </div>
              <div class="display-xs-0">
                <Link
                  style={{ visibility: "hidden" }}
                  component="button"
                  onClick={() => history.push("/AccessManagement")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
            </div>

            <Paper className={classes.paper}>
              <div class="form-body pad20">
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="RoleName"
                  value={RoleName}
                  name="RoleName"
                  onClick={() => {
                    setroleErrorMessage("");
                  }}
                  onBlur={() => {
                    if (!RoleName) {
                      setroleErrorMessage("Please Enter Role Name");
                    }
                  }}
                  helperText={roleErrorMessage}
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  onChange={(e) => {
                    setRoleName(e.target.value);
                  }}
                  label="Role Name"
                  autoComplete="Role-name"
                  className={classes.w80p}
                />
              </div>
              <div class="form-footer">
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  className={classes.btnRound}
                  onClick={Saveroles}
                >
                  SAVE
                </Button>
                &nbsp;&nbsp;
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  className={classes.btnRound}
                >
                  CANCEL
                </Button>
              </div>
            </Paper>
          </div>
        </section>
      </div>
    </div>
  );
}
