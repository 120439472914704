import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Modal } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Link } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import { RiDeleteBin5Line } from "react-icons/ri";
import Checkbox from "@material-ui/core/Checkbox";
import HoleFormDialog from "./HolesClose.js";
import Spinner from "react-bootstrap/Spinner";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../../views/Common";
import styles from "../../components/components.js";
import IconButton from "@material-ui/core/IconButton";
import { Clear, OpenInNewRounded } from "@material-ui/icons";
import { login_ } from "../../reducers/login";
import { makeStyles } from "@material-ui/core/styles";
import Table from "react-bootstrap/Table";
import { BiSolidCheckCircle } from "react-icons/bi";
import AddHoleStatus from "./AddHoleStatus.js";
import { ToastContainer, toast } from "react-toastify";
import {
  permissions_,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import {
  sensors_,
  sensorsHasLoaded_,
  sensorServiceThunk,
} from "../../reducers/sensors";
import _ from "lodash";
import "../../assets/scss/App.scss";
import { updateCurrentFieldStatus } from "../../reducers/userDashboard";

const ButtonLink = withStyles((theme) => ({
  root: {
    display: "inline !important",
    color: "blue !important",
    marginRight: "10px !important",
    padding: "0 !important",
    textDecoration: "underline",
    border: "none !important",
    fontSize: "13px !important",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
  },
}))(Link);

const useStyles = makeStyles(styles);

export default function GolfGraphAverage(props) {
  const [showdelete, setdeleteShow] = useState(false);
  const dispatch = useDispatch();
  const [yes, setYes] = React.useState("");
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const sensors = useSelector(sensors_);
  const sensorHasLoaded = useSelector(sensorsHasLoaded_);
  const [sensorstatus, setsensorstatus] = React.useState({});
  const [status, setstatus] = React.useState([]);
  const [open, setopen] = React.useState(false);
  const [permission, setpermission] = React.useState({});
  const [refresh, setrefresh] = React.useState(false);
  const [Allstatusarray, setAllstatusarray] = React.useState([]);
  const [golfstatusarray, setgolfstatusarray] = React.useState([]);
  const [golfstatusarrayone, setgolfstatusarrayone] = React.useState([]);
  const [gethandleHoleId, sethandleHoleId] = React.useState("");
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);
  const [showNoRecordsMessage, setShowNoRecordsMessage] = useState(false);
  const GolfCoursedata = props?.GolfCoursedata;

  useEffect(() => {
    setgolfstatusarray(GolfCoursedata.hole_data);
    const firstboj = GolfCoursedata.hole_data.find(
      (item) => item.DisplayOrder == 1
    );
    setgolfstatusarrayone([firstboj]);
    sethandleHoleId(firstboj.HoleID);
  }, [GolfCoursedata]);

  useEffect(() => {
    // Set a timeout to show "No Records" after 10 seconds if data is still not loaded
    const timeoutId = setTimeout(() => {
      setShowNoRecordsMessage(false);
      if (
        !golfstatusarrayone ||
        (!golfstatusarrayone.totalSalinity &&
          !golfstatusarrayone.totalTemperature &&
          !golfstatusarrayone.totalMoisture) ||
        (golfstatusarrayone.totalSalinity === "" &&
          golfstatusarrayone.totalTemperature === "" &&
          golfstatusarrayone.totalMoisture === "")
      ) {
        setShowNoRecordsMessage(true);
      }
    }, 2000); // 2 seconds

    return () => clearTimeout(timeoutId); // Clear the timeout if the component unmounts or when data is loaded
  }, [golfstatusarrayone]);

  const getGolfFields = () => {
    try {
      if (GolfCoursedata?.hole_data.length > 0) {
        let holedata = GolfCoursedata?.hole_data;
        let gethole_data = holedata.filter(
          (item) => item.HoleID === gethandleHoleId
        );
        setgolfstatusarrayone(gethole_data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "FieldDisplayStatus";
    });
    setpermission(Dpermissions[0]);
  };
  React.useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);

  useEffect(() => {
    if (gethandleHoleId !== "") {
      getGolfFields();
    }
  }, [gethandleHoleId, refresh, GolfCoursedata]);

  const handleHoleId = (elem) => {
    sethandleHoleId("");
    if (elem.target.value !== "") {
      sethandleHoleId(elem.target.value);
    } else {
      sethandleHoleId("");
    }
  };

  return (
    <div>
      <div class="box">
        <div class="box-header  text-center">
          <h6 class="box-title mb-0">Graph Average </h6>
        </div>
        <div className="golfselectbox">
          <div className="fontweight600">Filter By Hole</div>
          <div className="w-50 pl-2">
            <Form.Control
              as="select"
              onChange={(e) => handleHoleId(e)}
              value={gethandleHoleId}
              style={{ width: "100%", marginRight: "5px" }}
            >
              <option value="" disabled>
                Please Select Hole
              </option>
              {golfstatusarray?.map((data, idx) => (
                <option key={idx} value={data.HoleID}>
                  {data.HoleName}
                </option>
              ))}
            </Form.Control>
          </div>
        </div>
        <div
          className="box-body"
          style={{ maxHeight: "145px", overflowY: "auto" }}
        >
          <Table striped bordered className="mb-0" size="sm">
            <thead>
              <tr>
                <th>GraphName</th>
                <th>Average</th>
              </tr>
            </thead>
            <tbody>
              {golfstatusarrayone?.length > 0 && showNoRecordsMessage ? (
                golfstatusarrayone?.map((data, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {data.totalMoisture ? (
                        <tr>
                          <td>Moisture</td>
                          <td>{data.totalMoisture}</td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {data.totalTemperature ? (
                        <tr>
                          <td>Temperature</td>
                          <td>{data.totalTemperature}</td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {data.totalSalinity ? (
                        <tr>
                          <td>Salinity</td>
                          <td>{data.totalSalinity}</td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })
              ) : !showNoRecordsMessage ? (
                <tr>
                  <td colSpan={2}>
                    <div className="text-center">
                      <Spinner animation="border" />
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={2}>
                    <div className="text-center">No Records</div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
